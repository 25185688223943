<div class="header-pane uf-app-bar transparent">
	<uc-builder-header />
</div>
<uf-panel class="content-pane">
	<div class="uf-container-lg">
		<div class="uf-grid col-12 pad-top">
			@if (!error && !root) {
				<uf-skeleton class="gap-none col-12" />
			}

			@if (error) {
				<uf-message [content]="error.message" icon="error" class="error gap-bottom col-12" />
			}

			@if (root) {
				<ng-container [formGroup]="root">
					<div class="uf-box col-12">
						<div class="uf-app-bar flat accent">
							<div class="title">Details</div>
						</div>
						<div class="uf-grid pad">
							<uf-text [formControlName]="controlKeys.ConsoleName" [label]="consoleNameLabel" class="col-12">
								<span class="suffix">*</span>
							</uf-text>
							@if (root.get(controlKeys.Bucket)?.enabled) {
								<uf-autocomplete [formControlName]="controlKeys.Bucket" [options]="bucketsResult" (valueChange)="bucketChange($event)" (searchChange)="searchBuckets($event)" label="Form Data Repository" placeholder="Search form data repository" nameProperty="name" class="col-12" />
							}
						</div>
					</div>
					<div class="uf-box col-12">
						<div class="uf-app-bar flat accent">
							<div class="title">Recipients</div>
						</div>
						@for (recipient of recipients.controls | asUfControlsGroups; track recipient; let i = $index) {
							<div class="uf-box">
								<uf-expander [dragDisabled]="true" [isExpanded]="true" dragItem>
									<div expanderHeader class="uf-app-bar flat">
										<div class="title">
											{{ recipientLabel(i) }}
										</div>
										<button (click)="removeRecipient(i)" title="Delete" class="uf-action" type="button">
											<uf-icon name="delete" />
										</button>
									</div>
									<div expanderBody class="uf-grid pad">
										<!-- User Hierarchy -->
										@if (recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.UserHierarchy) {
											<uc-workflow-notification-hierarchy-field [fields]="hierarchyFields" [controlGroup]="recipient" class="col-12" />
										}
										<!-- Form Data -->
										@if (recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.FormData) {
											<!-- Form Data User / User Manager - DataSources -->
											@if (isFormDataUserDataSource(recipient)) {
												@if (recipient.get(controlKeys.FormData)?.get(controlKeys.Value); as formDataControl) {
													<uf-autocomplete [options]="filteredUserDataSources" [control]="formDataControl | asUfControl" (searchChange)="searchUserDataSource($event)" label="User DataSource" placeholder="Select a DataSource" class="col-12" />
												}
											}
											<!-- Form Data Email Field -->
											@if (recipient.get(controlKeys.FormData)?.get(controlKeys.Type)?.value === workflowNotificationRecipientFormDataType.Email) {
												@if (recipient.get(controlKeys.FormData)?.get(controlKeys.Value); as emailFieldControl) {
													<uf-autocomplete [options]="filteredEmailFields" [control]="emailFieldControl | asUfControl" (searchChange)="searchEmailFields($event)" label="Email Fields" nameProperty="name" placeholder="Select a Field" class="col-12" />
												}
											}
										}
										<!-- Email -->
										@if (recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.Email) {
											@if (recipient.get(controlKeys.Email); as emailControl) {
												<uf-email [control]="emailControl | asUfControl" label="Email" class="col-12" />
											}
										}
										<!-- Role -->
										@if (recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.Role) {
											@if (recipient.get(controlKeys.Role); as roleControl) {
												<uf-autocomplete [options]="filteredRoles" [control]="roleControl | asUfControl" (searchChange)="searchRole($event)" label="Role" placeholder="Select a role" class="col-12" />
											}
										}
										<!-- User -->
										@if (recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.User) {
											@if (recipient.get(controlKeys.User); as userControl) {
												<uf-autocomplete [options]="users" [control]="userControl | asUfControl" (searchChange)="searchUsers($event)" label="User" nameProperty="username" valueProperty="username" class="col-12" />
											}
										}
										<!-- Claim -->
										@if (recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.Claim) {
											@if (recipient.get(controlKeys.Claim)?.get(controlKeys.Type); as claimControl) {
												<uf-autocomplete [options]="filteredUserClaims" [control]="claimControl | asUfControl" [allowCustom]="false" (valueChange)="changeClaim($event, recipientClaimControl(i))" (searchChange)="searchUserClaims($event)" label="Claim" placeholder="Search Claims" class="col-6" />
											}
											@if (recipient.get(controlKeys.Claim)?.get(controlKeys.MatchType); as matchTypeControl) {
												<uf-select [options]="claimMatchOptions" [control]="matchTypeControl | asUfControl" label="Match Type" valueProperty="identifier" nameProperty="name" placeholder="Select a match type" class="col-6" />
											}
											<!-- Match type = Value - Claim Editor -->
											@if (!!recipient.get(controlKeys.Claim)?.get(controlKeys.ClaimConfig)?.value && isValueClaim(recipient)) {
												@if (recipient.get(controlKeys.Claim)?.get(controlKeys.ClaimConfig); as claimEditorControl) {
													<uf-claim-field [control]="claimEditorControl | asUfControl" [claimConfig]="recipient.get(controlKeys.Claim)?.get(controlKeys.ClaimConfig)?.value" class="col-6" />
												}
											}
											<!-- Match type = User -->
											@if (recipient.get(controlKeys.Claim)?.get(controlKeys.MatchAgainst); as matchAgainstControl) {
												@if (isMatchAgainst(recipient)) {
													<uf-autocomplete [allowCustom]="true" [options]="filteredUserClaims" [control]="matchAgainstControl | asUfControl" (searchChange)="searchUserClaims($event)" label="User Claim" placeholder="Search Claims" class="col-6" />
												}
											}
											<!-- Match type = Form Data -->
											@if (recipient.get(controlKeys.Claim)?.get(controlKeys.MatchType)?.value === workflowNotificationRecipientClaimMatchType.FormData) {
												@if (recipient.get(controlKeys.Claim)?.get(controlKeys.Value); as claimValueControl) {
													<uf-autocomplete [allowCustom]="false" [options]="filteredClaimDataSources" [control]="claimValueControl | asUfControl" (searchChange)="searchClaim($event)" label="Claim DataSource" placeholder="Search Claims" class="col-6" />
												}
											}
										}
										<!-- Combo -->
										@if (recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.Combo) {
											<div class="col-12 uf-grid">
												@if (recipient.get(controlKeys.Conditions); as recipientConditions) {
													@for (conditionGroup of (recipientConditions | asUfControlArray).controls; track conditionGroup; let ci = $index) {
														<div class="uf-box flat col-12 pad uf-grid">
															<!-- Condition Role -->
															@if ($any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Role) {
																<uf-autocomplete [options]="filteredRoles" [control]="$any(conditionGroup).get(controlKeys.Role)" (searchChange)="searchRole($event)" label="Role" placeholder="Select a role" class="col-11" />
															}
															<!-- Condition Company -->
															@if ($any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Company && !isUserManagementNotification) {
																<uf-autocomplete [options]="filteredCompanyDataSources" [control]="$any(conditionGroup).get(controlKeys.Company)" (searchChange)="searchCompanyDataSource($event)" label="Company" placeholder="Select a company" class="col-11" />
															}
															<!-- Condition Hierarchy -->
															@if ($any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Hierarchy) {
																@if (!isUserManagementNotification) {
																	<uf-autocomplete [options]="filteredHierarchyFields" [control]="$any(conditionGroup).get(controlKeys.FormData)" (searchChange)="searchHierarchyField($event)" valueProperty="identifier" nameProperty="name" label="Hierarchy Field" placeholder="Select a Field" class="col-12" />
																}
																<uf-hierarchy-unit [control]="$any(conditionGroup).get(controlKeys.HierarchyUnit)" [isRequired]="true" label="Hierarchy Unit" placeholder="Root" class="col-11" />
															}
															<!-- User Hierarchy -->
															@if ($any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.UserHierarchy) {
																<uc-workflow-notification-hierarchy-field [fields]="hierarchyFields" [controlGroup]="conditionGroup | asUfControlGroup" class="col-11" />
															}
															<!-- Condition Claim -->
															@if ($any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Claim) {
																<div class="col-11 uf-grid">
																	<uf-autocomplete [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.Type)" [allowCustom]="true" [options]="filteredUserClaims" (searchChange)="searchUserClaims($event)" (valueChange)="changeClaim($event, $any(conditionGroup).get(controlKeys.Claim))" label="Claim" placeholder="Search Claims" class="col-5" />
																	@if (!!$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.MatchType)) {
																		<uf-select [options]="claimMatchOptions" [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.MatchType)" valueProperty="identifier" nameProperty="name" placeholder="Select a match type" label="Match Type" class="col-6" />
																	}
																	<!-- Match type = Form Data -->
																	@if ($any(conditionGroup).get(controlKeys.Claim)?.get(controlKeys.MatchType)?.value === workflowNotificationRecipientClaimMatchType.FormData) {
																		<uf-autocomplete [allowCustom]="false" [options]="filteredFormDataClaimDataSources" [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.Value)" (searchChange)="searchFormDataClaim($event)" label="Claim DataSource" placeholder="Search Claims" class="col-6" />
																	}
																	<!-- Match type = Value - Claim Editor -->
																	@if (isValueClaim($any(conditionGroup))) {
																		<uf-claim-field [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.Value)" [claimConfig]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.ClaimConfig).value" class="col-5" />
																	}
																	<!-- Match Type = CreatedBy/LastModifiedBy -->
																	@if (isMatchAgainst($any(conditionGroup))) {
																		<uf-autocomplete [allowCustom]="true" [options]="filteredUserClaims" [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.MatchAgainst)" (searchChange)="searchUserClaims($event)" label=" User Claim" placeholder="Search Claims" class="col-5" />
																	}
																</div>
															}
															<button (click)="recipientConditionsControls(i).removeAt(ci)" title="Remove Condition" class="col-1 uf-action tertiary center grow right" type="button">
																<uf-icon name="delete" />
															</button>
														</div>
													}
												}
												@if (recipientTypeControl(i).value === workflowNotificationRecipientType.Combo) {
													<div class="col-12 right row">
														@if (recipient.get(controlKeys.TwoConditions); as twoConditionsControl) {
															<uf-error [control]="twoConditionsControl | asUfControl" class="gap-right" />
														}
														@if (recipient.get(controlKeys.Conditions); as recipientConditions) {
															<button [options]="conditionTypes" (optionSelected)="addCondition($event, $any(recipientConditions))" type="button" listBox nameProperty="name" class="uf-button x-small right gap-sm-top">
																Add Condition
																<uf-icon name="more" class="gap-none" />
															</button>
														}
													</div>
												}
											</div>
										}
										<!-- End of Combo -->
										@if (recipient.get(controlKeys.LiveOnly); as liveOnlyControl) {
											<uf-checkbox [control]="liveOnlyControl | asUfControl" label="LiveOnly" class="col-12" />
										}
									</div>
								</uf-expander>
							</div>
						}
						<div class="pad border-top">
							<button [options]="recipientTypes" [disabled]="!canAddRecipient" (optionSelected)="addRecipient($event)" listBox type="button" nameProperty="name" class="uf-button x-small right gap-sm-top">
								Add Recipient
								<uf-icon name="more" class="gap-none" />
							</button>
						</div>
					</div>
					<!-- Messages -->
					<div class="uf-box col-12">
						<div class="uf-app-bar flat accent">
							<div class="title">Email Notification</div>
							@if (hasEmail.value) {
								<button (click)="showGlossary(workflowNotificationDeliveryMethod.Email)" type="button" title="Glossary" class="uf-action tertiary">
									<uf-icon name="info" />
								</button>
							}
							@if (hasEmail.value) {
								<button (click)="previewMessage(workflowNotificationDeliveryMethod.Email)" type="button" title="Preview" class="uf-action tertiary">
									<uf-icon name="view" />
								</button>
							}
							@if (arePushNotificationsEnabled) {
								<button [title]="hasEmail.value ? 'Disable Email Notifications' : 'Enable Email Notifications'" (click)="hasEmail.setValue(!hasEmail.value)" type="button" class="uf-action tertiary">
									@if (!hasEmail.value) {
										<uf-icon name="checkbox" />
									}
									@if (hasEmail.value) {
										<uf-icon name="checkboxTick" />
									}
								</button>
							}
						</div>
						@if (hasEmail.value) {
							<div class="uf-grid notification-type pad">
								<uf-text [value]="email.title.value" [control]="email.title" label="Title" class="col-12" />
								<uf-markdown-editor #emailMarkdownEditor [control]="email.body" label="Message" class="col-12" />
								@if (!isUserManagementNotification) {
									<ng-template ufFeatureFlag="pdfRendering">
										<uf-checkbox [control]="email.attachFormAsPdf" label="Attach form as PDF" class="col-12" />
									</ng-template>
								}
								<uf-text [control]="email.replyTo" label="Reply to" class="col-12">
									<uf-help content="Comma separate multiple email addresses." />
								</uf-text>
							</div>
						}
					</div>
					@if (arePushNotificationsEnabled) {
						<div class="uf-box col-12">
							<div class="uf-app-bar flat accent">
								<div class="title">Push Notification</div>
								@if (hasPushNotification.value) {
									<button (click)="showGlossary(workflowNotificationDeliveryMethod.Push)" type="button" class="uf-action tertiary" title="Glossary">
										<uf-icon name="info" />
									</button>
								}
								@if (hasPushNotification.value) {
									<button (click)="previewMessage(workflowNotificationDeliveryMethod.Push)" type="button" title="Preview" class="uf-action tertiary">
										<uf-icon name="view" />
									</button>
								}
								<button [title]="hasPushNotification.value ? 'Disable Push Notification Notifications' : 'Enable Push Notification Notifications'" (click)="hasPushNotification.setValue(!hasPushNotification.value)" type="button" class="uf-action tertiary">
									@if (!hasPushNotification.value) {
										<uf-icon name="checkbox" />
									}
									@if (hasPushNotification.value) {
										<uf-icon name="checkboxTick" />
									}
								</button>
							</div>
							@if (hasPushNotification.value) {
								<div class="uf-grid notification-type pad">
									<uf-text [control]="pushNotification.title" label="Title" class="col-12" />
									<uf-textarea #pushTextArea [control]="pushNotification.body" label="Message" class="col-12" />
								</div>
							}
						</div>
					}
					@if (root.get(controlKeys.AtLeastOneNotification); as atLeastOneNotification) {
						<uf-error [control]="atLeastOneNotification | asUfControl" class="col-12" />
					}
				</ng-container>
			}
		</div>
	</div>
</uf-panel>
