import { AfterViewInit, ChangeDetectorRef, Component, inject } from '@angular/core';
import { CompoundType } from '@unifii/sdk';

import { UcProject } from 'client';

import { ContentList, ModalSearchType } from './content-list';
import { LinkSearchComponent, LinkSearchConfig, LinkSearchType } from './modals/link-search.component';

@Component({
	selector: 'uc-link',
	templateUrl: './link.html',
	styleUrls: ['./group-input.less'],
	providers: [{ provide: ModalSearchType, useValue: LinkSearchComponent }],
	standalone: false,
})
export class LinkComponent extends ContentList implements AfterViewInit {

	protected title: string;
	protected icon: string;

	private searchConfig: LinkSearchConfig;
	private linkSegments: string[] | null;
	private ucProject = inject(UcProject);
	private cdr = inject(ChangeDetectorRef);

	override ngAfterViewInit() {
		super.ngAfterViewInit();

		switch (this.configuredField.compoundType) {

			case CompoundType.Collection:
				this.title = 'Collection Link';
				this.linkSegments = ['content', 'collections'];
				this.searchConfig = {
					title: 'Select Collection',
					multiSelect: false,
					ucProject: this.ucProject,
					type: LinkSearchType.Compound,
					compoundType: CompoundType.Collection,
					types: this.configuredField.types,
				};
				break;

			case CompoundType.Page:
				this.title = 'Page Link';
				this.linkSegments = ['content', 'pages'];
				this.searchConfig = {
					title: 'Select Page',
					multiSelect: false,
					ucProject: this.ucProject,
					type: LinkSearchType.Compound,
					compoundType: CompoundType.Page,
				};
				break;

			case CompoundType.View:
				this.title = 'View Link';
				this.linkSegments = ['content', 'views'];
				this.searchConfig = {
					title: 'Select View',
					multiSelect: false,
					ucProject: this.ucProject,
					type: LinkSearchType.Compound,
					compoundType: CompoundType.View,
				};
				break;

			case CompoundType.Form:
			default:
				this.title = 'Form Link';
				this.linkSegments = ['forms'];
				this.searchConfig = {
					title: 'Select Form',
					multiSelect: false,
					ucProject: this.ucProject,
					type: LinkSearchType.Form,
				};
				break;
		}

		this.icon = this.builderService.builder.getFieldRef(this.configuredField).icon;
		this.cdr.detectChanges();
	}

	protected getLink(link: any) {
		const segments = ['/projects', this.ucProject.options.projectId, ...(this.linkSegments ?? [])];

		if (this.configuredField.compoundType === CompoundType.Collection) {
			segments.push(link.definitionIdentifier);
		}
		segments.push(link.id);

		return segments;
	}

	protected getSearchConfig(): LinkSearchConfig {
		return this.searchConfig;
	}

	protected override convertResultToContent(item: any): any {
		switch (this.configuredField.compoundType) {
			case CompoundType.Form:
				return { id: item.id, name: item.name, consoleName: item.consoleName };
			case CompoundType.Collection:
				return { id: item.id, compoundType: item.compoundType, definitionIdentifier: item.definitionIdentifier, name: item.name, _consoleName: item._consoleName };
			default:
				return { id: item.id, compoundType: item.compoundType, name: item.name ?? item._title, _consoleName: item._consoleName };
		}
	}

}
