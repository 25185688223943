import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UfError } from '@unifii/sdk';

import { Integration, UcClient, UcIntegrations } from 'client';
import { useDefaultErrorMessage } from 'components';

export const integrationResolver = async(route: ActivatedRouteSnapshot): Promise<Integration | UfError> => {
	const ucClient = inject(UcClient);
	const ucIntegrations = inject(UcIntegrations);

	const integrationId = route.params.id;
	const providerId = route.queryParams.provider;

	try {
		if (integrationId === 'new') {
			const provider = await ucClient.getAvailableIntegration(providerId);

			const name = '';
			const config = {};

			return { provider, name, config };
		}

		return await ucIntegrations.get(integrationId);
	} catch (e) {
		return useDefaultErrorMessage(e);
	}
};
