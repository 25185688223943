<div class="uf-box gap-top gap-sides">
	<div class="uf-app-bar flat accent">
		<div class="title">Settings</div>
		@if (!isNew) {
			<button (click)="deleteClicked.emit()" type="button" class="uf-button small right">Delete</button>
			@if (form.get(controlKeys.IsActive)?.value) {
				<button (click)="deactivateClicked.emit()" type="button" class="uf-button small">Deactivate</button>
			}
			@if (!form.get(controlKeys.IsActive)?.value) {
				<button (click)="activateClicked.emit()" type="button" class="uf-button small">Activate</button>
			}
			<button (click)="editDetails()" type="button" class="uf-button small">Edit</button>
		}
	</div>

	@if (!isNew && !edit) {
		<uf-data-display-list [items]="dataDisplayListItems" class="pad" />
	} @else {
		<div [formGroup]="form" class="uf-grid pad">
			<uf-text [formControlName]="controlKeys.Tenant" label="Tenant Name" class="col-12" />
			@if (visible.clientId) {
				<uf-text [formControlName]="controlKeys.ClientId" label="Client Id" class="col-12" />
			}
			@if (visible.providerLoginLabel) {
				<uf-text [formControlName]="controlKeys.ProviderLoginLabel" label="Provider Login Label" class="col-12" />
			}
			<ng-container [formGroup]="extrasControl">
				@if (visible.authorizationServerId) {
					<uf-text [formControlName]="controlKeys.AuthorizationServer" label="Authorization Server ID" class="col-12" />
				}
				@if (visible.audience) {
					<uf-text [formControlName]="controlKeys.Audience" label="Management API Audience" class="col-12" />
				}
				@if (visible.sswsSecret) {
					<uc-secret-input [formControlName]="controlKeys.SswsSecret" label="SSWS Secret" class="col-12" />
				}
			</ng-container>
			@if (visible.clientSecret) {
				<uc-secret-input [formControlName]="controlKeys.ClientSecret" label="Client Secret" class="col-12" />
			}
			@if (visible.scimToken) {
				<uc-secret-input [formControlName]="controlKeys.ScimToken" label="SCIM Token" class="col-12" />
			}
			@if (visible.supportProvider) {
				<uf-checkbox [formControlName]="controlKeys.SupportProvider" label="Support Provider" class="col-12" />
			}
			@if (form.get(controlKeys.AllowInvitations)?.enabled) {
				<uf-checkbox [formControlName]="controlKeys.AllowInvitations" label="Allow Invitations" class="col-12" />
			}
			@if (form.get(controlKeys.AllowEditing)?.enabled) {
				<uf-checkbox [formControlName]="controlKeys.AllowEditing" label="Allow Editing" class="col-12" />
			}
			@if (form.get(controlKeys.AllowDeletePending)?.enabled) {
				<uf-checkbox [formControlName]="controlKeys.AllowDeletePending" label="Allow Delete Pending" class="col-12" />
			}
		</div>
		@if (!isNew) {
			<div class="uf-form-actions">
				<button (click)="cancelEdit()" type="button" class="uf-button tertiary right">Cancel</button>
				<button (click)="doneEditing()" type="button" class="uf-button primary">Done</button>
			</div>
		}
	}
</div>
