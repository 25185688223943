import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'uc-icons-library',
	template: '',
	standalone: false,
})
export class IconsLibraryComponent {

	@HostBinding('style.display') hostDisplay = 'none';

}
