import { Component, Input, OnInit, inject } from '@angular/core';
import { FieldTypeIcon, MenuOption, ModalService, UfControl, UfControlArray, UfFormBuilder } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { IdentifierMenuOption, SortIcons } from 'constant';
import { MappableField } from 'models';
import { FieldMapperComponent, FieldMappingData } from 'pages/form-editor/field-configuration/field-mapper.component';
import { isSortUp, sortByProperty } from 'utils';

import { reduceExpressionToSingleLine } from './workflow-functions';

const sourceKey = 'source';
const targetKey = 'target';
const sourceExpressionKey = 'sourceExpression';

const SortColumns: Record<keyof Pick<MappableField, 'label' | 'position'>, string> = {
	label: 'Label',
	position: 'Definition Order',
};

interface MappableFieldWithControl extends MappableField {
	control: UfControl | undefined;
}

@Component({
	selector: 'uc-field-mapping-table',
	templateUrl: 'field-mapping-table.html',
	styleUrls: ['field-mapping-table.less'],
	standalone: false,
})
export class FieldMappingTableComponent implements OnInit {

	@Input({ required: true }) control: UfControlArray;
	@Input({ required: true }) targetFields: MappableField[];
	@Input({ required: true }) sourceFields?: MappableField[];

	protected readonly menuOptions: IdentifierMenuOption<MappableField>[] = [
		{ label: SortColumns.label, icon: SortIcons.sortDown, identifier: 'label' },
		{ label: SortColumns.position, identifier: 'position' },
	];
	protected targetFieldControlMap: Dictionary<MappableFieldWithControl | undefined>;

	private sourceFieldsMap?: Dictionary<MappableField | undefined>;
	private targetFieldsMap: Dictionary<MappableField | undefined>;

	private modalService = inject(ModalService);
	private ufb = inject(UfFormBuilder);

	ngOnInit() {
		if (this.sourceFields) {
			this.sourceFieldsMap = Object.fromEntries(this.sourceFields.map((f) => [f.identifier, f]));
		}

		this.targetFieldsMap = Object.fromEntries(this.targetFields.map((f) => [f.identifier, f]));
		this.targetFieldControlMap = Object.fromEntries(this.targetFields.map((targetField) => {
			const control = this.control.controls.find((c) => c.value?.identifier === targetField.identifier) as UfControl | undefined;

			return [targetField.identifier, { ...targetField, control }];
		}));
	}

	protected async inputArgClick(control: UfControl) {

		const { identifier, expression: sourceExpression, field: sourceField } = control.value;
		const target = this.targetFieldsMap[identifier];

		if (!target || !this.sourceFieldsMap) {
			return;
		}

		const data: FieldMappingData = {
			sourceFields: this.sourceFields,
			mappingGroup: {
				control: this.ufb.group({
					sourceExpression,
					source: this.sourceFieldsMap[sourceField],
					target,
				}),
				sourceKey,
				targetKey,
				sourceExpressionKey,
			},
			targetFieldLabel: 'Target Data',
			sourceFieldLabel: 'Source Data Field',
			sourceExpressionLabel: 'Source Expression Field',
		};

		const result = await this.modalService.openMedium(FieldMapperComponent, data);

		control.markAsTouched();

		if (!result) {
			return;
		}

		const rawExpression = result.get(sourceExpressionKey)?.value;
		const expression = typeof rawExpression === 'string' ? reduceExpressionToSingleLine(rawExpression) : undefined;
		const field = result.get(sourceKey)?.value?.identifier;

		control.setValue({
			...control.value,
			field,
			expression,
		});
	}

	protected getSourceFieldLabel(identifier?: string): string {
		if (!this.sourceFieldsMap || !identifier) {
			return '';
		}

		return this.sourceFieldsMap[identifier]?.label ?? '';
	}

	protected getTargetFieldLabel(identifier: string): string {
		return this.targetFieldsMap[identifier]?.label ?? '';
	}

	protected getSourceFieldIcon(identifier?: string): string | undefined {

		if (!this.sourceFieldsMap || !identifier) {
			return;
		}

		const fieldType = this.sourceFieldsMap[identifier]?.type;

		if (!fieldType) {
			return;
		}

		// TODO - Replace with Library Pipe
		return FieldTypeIcon.get(fieldType);
	}

	protected getTargetFieldIcon(identifier: string): string | undefined {
		const fieldType = this.targetFieldsMap[identifier]?.type;

		if (!fieldType) {
			return;
		}

		// TODO - Replace with Library Pipe
		return FieldTypeIcon.get(fieldType);
	}

	protected onSort(option: MenuOption) {
		this.targetFields = [...this.targetFields].sort(sortByProperty<MappableField>(isSortUp(option, this.menuOptions), option.identifier).bind(this));
	}

	protected getMappableControl(identifier: string): UfControl | undefined {
		return this.targetFieldControlMap[identifier]?.control;
	}

	protected targetFieldTrackBy(_: number, { identifier }: MappableField) {
		return identifier;
	}

}
