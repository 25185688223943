<uf-expander [class.uc-group--draggable--no-shrink]="canDragField && !isSelected" [class.uc-group--draggable--no-shrink--accent]="canDragField && isSelected" class="uc-group">
	<div [class.accent]="isSelected" (click)="selectField($event)" expanderHeader dragHandle class="row uf-app-bar flat">
		<uf-icon [name]="icon" />
		<div class="title primary grow">{{ configuredField.label ?? configuredField.type | toDisplayName }}</div>
		@if (configuredField.help) {
			<uf-help [content]="configuredField.help" class="uf-action tertiary" />
		}
		@if (!isValid) {
			<uf-icon name="warning" class="icon-warning" />
		}
		@if (canRemoveField) {
			<a (click)="removeField($event)" title="Delete" class="uf-action tertiary">
				<uf-icon name="delete" />
			</a>
		}
	</div>
	<div #container [class.grid]="!isGroup && configuredField.type !== fieldTypes.MultiText" [class.content--accent]="isSelected" expanderBody class="uc-group-content">
		<uf-field [field]="field" [(content)]="content" (contentChange)="contentChanged($event)" class="col-1of1--no-padding" />
	</div>
</uf-expander>
