import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DownloadConfig, TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { DataDisplayIconValue, DataDisplayInfo, DataDisplayLozengeValue, DataDisplayService, FilterEntries, FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig, TableConfigColumn, ToastService, UserInfoIdentifiers, getDefaultTableConfig, pathToDisplay } from '@unifii/library/common';
import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';
import { ClaimConfig, Client, DataType, Dictionary, UserInfo, UserStatus, coerceDataToTarget, fieldTypeToDataType, getUserFullName, getUserStatus, isNotNull } from '@unifii/sdk';
import { UserProvisioningCache } from '@unifii/user-provisioning';
import { Subject } from 'rxjs';

import { UcUserInfo, UcUsers } from 'client';
import { TABLE_SEARCH_MIN_LENGTH, USER_STATUS_COLOUR } from 'constant';
import { UserDataSource } from 'pages/users/users-table-data-source';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { UsersTableData } from './users-table-data-resolver';

@Injectable()
export class UsersTableManager implements TableContainerManager<UcUserInfo, FilterValue, FilterEntry> {

	tableConfig: TableConfig<UcUserInfo>;
	defaultSort = UserInfoIdentifiers.Username;
	showSearch = true;
	searchMinLength = TABLE_SEARCH_MIN_LENGTH;
	addActionConfig = true;
	downloadConfig: DownloadConfig;
	tableChange = new Subject<TableInputs<FilterValue>>();
	reload = new Subject<void>();
	update = new Subject<TableInputs<FilterValue>>();
	updateItem = new Subject<UcUserInfo | { item: UcUserInfo; trackBy: keyof UcUserInfo }>();
	inputManager: TableInputManager<FilterValue, FilterEntry>;

	private dataSource: UserDataSource;

	private route = inject(ActivatedRoute);
	private client = inject(Client);
	private ucUsers = inject(UcUsers);
	private userProvisioningCache = inject(UserProvisioningCache);
	private entries = inject(FilterEntries);
	private dataDisplayService = inject(DataDisplayService);
	private dialogs = inject(DialogsService);
	private toastService = inject(ToastService);
	private contextService = inject(ContextService);

	constructor() {
		const { claimConfig, companiesEnabled } = this.route.snapshot.data.tableData as UsersTableData;
		const columns = this.getColumns(claimConfig, companiesEnabled);
		const tableConfig = getDefaultTableConfig(columns, 'users-table');

		tableConfig.row = { link: (element) => '' + element.id };
		tableConfig.actions = [{
			label: 'Delete',
			predicate: (row) => getUserStatus(row.$implicit) === UserStatus.Pending,
			action: (rows) => this.delete(rows.map((row) => row.$implicit)),
		}];
		tableConfig.selectable = true;

		this.tableConfig = tableConfig;

		this.downloadConfig = {
			name: 'users.csv',
			getUrl: this.getDownloadUrl.bind(this),
		};

		this.inputManager = new TableInputManager(this.entries, inject(HierarchyUnitProvider), null, null);
	}

	createDataSource(inputs: TableInputs<FilterValue> = {}) {
		const { status, ...params } = this.inputManager.serializeInputs(inputs);
		let extras: Dictionary<boolean> = {};

		if (status != null) {
			extras = this.getStatus(status as UserStatus);
		}

		this.dataSource = new UserDataSource(this.ucUsers, { ...params, ...extras });

		return this.dataSource;
	}

	async getDownloadUrl(): Promise<string | null> {
		const url = this.dataSource.getDownloadUrl();

		if (url) {
			const downloadToken: { token: string } = await this.client.getDownloadToken(url);

			return url + '&_dlt=' + downloadToken.token;
		}

		return null;
	}

	private getColumns(claimConfig: ClaimConfig[], companiesEnabled: boolean): TableConfigColumn<UcUserInfo>[] {
		const columns = [{
			name: 'username',
			label: 'Username',
			sortable: true,
		}, {
			name: 'firstName',
			label: 'First Name',
			sortable: true,
		}, {
			name: 'lastName',
			label: 'Last Name',
			sortable: true,
		}, {
			name: 'email',
			label: 'Email',
			hidden: true,
			sortable: true,
		}, {
			name: 'manager',
			label: 'Manager',
			hidden: true,
			value: (user: UcUserInfo) => user.manager ? getUserFullName(user.manager) : undefined,
		}, {
			name: 'roles',
			label: 'Roles',
			hidden: true,
			value: (user: UcUserInfo) => user.roles
			// UNIFII-7298 sort to be kept by name only
				?.sort(stringsCaseInsensitiveLocalCompare)
				.map((roleName) => this.userProvisioningCache.rolesByName[roleName]?.display ?? roleName)
				.join(', '),
		}, {
			name: 'systemRoles',
			label: 'System Roles',
			hidden: true,
			value: (user: UcUserInfo) => user.systemRoles?.sort(stringsCaseInsensitiveLocalCompare).join(', '),
		}, {
			name: 'lastModified',
			label: 'Last Modified',
			hidden: true,
			value: (user: UcUserInfo) => this.dataDisplayService.displayAsString(user.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
		}, {
			name: 'status',
			label: 'Status',
			value: (user: UcUserInfo) => {
				const status = getUserStatus(user);

				return {
					label: status,
					colour: USER_STATUS_COLOUR[status],
				} satisfies DataDisplayLozengeValue;
			},
		}, {
			name: 'logins',
			label: 'Identity Provider',
			value: (user: UcUserInfo) => user.logins?.map((login) => login.authProvider).filter(isNotNull).join(', '),
		}, {
			name: 'isExternal',
			label: 'Authentication',
			value: (user: UcUserInfo) => user.isExternal ? 'External' : 'Internal',
		}, {
			name: 'isMfaEnabled',
			label: 'MFA Active',
			value: (user: UcUserInfo) => {
				if (!user.isMfaEnabled) {
					return;
				}

				return {
					icon: 'radioTick',
					colour: 'success',
				} satisfies DataDisplayIconValue;
			},
		}, {
			name: 'isMfaOptional',
			label: 'MFA Optional',
			hidden: !this.contextService.tenantSettings?.isMfaEnforced,
			value: (user: UcUserInfo) => {
				if (!user.isMfaOptional) {
					return;
				}

				return {
					icon: 'radioTick',
					colour: 'success',
				} satisfies DataDisplayIconValue;
			},
		}, {
			name: 'isTester',
			label: 'Tester',
			value: (user: UcUserInfo) => {
				if (!user.isTester) {
					return;
				}

				return {
					icon: 'radioTick',
					colour: 'success',
				} satisfies DataDisplayIconValue;
			},
		}, {
			name: 'unitPaths',
			label: 'Hierarchies',
			value: (user: UcUserInfo) => user.unitPaths?.map((path) => pathToDisplay(path)).join(', '),
		},
		...claimConfig.map((c) => this.claimConfigToColumn(c)),
		];

		if (companiesEnabled) {
			columns.push({
				name: 'company.name',
				label: 'Company',
			});
		}

		return columns;
	}

	private claimConfigToColumn(config: ClaimConfig): TableConfigColumn<UserInfo> {
		return {
			name: config.type,
			label: config.label || config.type,
			hidden: true,
			value: (user: UcUserInfo) => {
				const type = fieldTypeToDataType(config.valueType);

				if (!type) {
					return;
				}

				let values = user.claims?.filter((claim) => claim.type === config.type).map((claim) => {

					if (type === DataType.Boolean || type === DataType.Number) {
						return coerceDataToTarget(claim.value, { type });
					}

					return claim.value;
				});

				if (!values?.length) {
					values = undefined;
				}

				const displayValues = values?.length === 1 && ![DataType.MultiChoice, DataType.StringArray].includes(type) ? values[0] : values;

				return this.dataDisplayService.displayAsString(displayValues, { type } as DataDisplayInfo);
			},
		};
	}

	private async delete(users: UcUserInfo[]): Promise<void> {
		if (!await this.dialogs.confirmDelete()) {
			return;
		}

		const errors: string[] = [];

		for (const { id, username } of users) {
			try {
				if (id) {
					await this.ucUsers.delete(id);
				}
			} catch (e) {
				errors.push(username);
			}
		}

		if (errors.length) {
			this.toastService.error(`Error: could not delete users: ${errors.join(', ')}`);
		} else {
			this.toastService.success('User/s successfully deleted');
		}
	}

	private getStatus(status: UserStatus): { hasPassword?: boolean; isExternal?: boolean; isActive?: boolean } {
		switch (status) {
			case UserStatus.Pending: return {
				hasPassword: false,
				isActive: false,
				isExternal: false,
			};
			case UserStatus.Active: return {
				isActive: true,
			};
			case UserStatus.Inactive: return {
				hasPassword: true,
				isExternal: false,
				isActive: false,
			};
		}
	}

}
