@if (form && !error) {
	<div class="header-pane uf-app-bar transparent">
		<uc-builder-header />
	</div>
}

@if (!error) {
	<uf-panel class="content-pane">
		<div class="uf-container">
			@if (!form) {
				<uf-skeleton />
			}
			@if (form) {
				<ng-container [formGroup]="form">
					<div class="uf-box gap-top">
						<div class="uf-app-bar flat accent">
							<div class="title">Details</div>
						</div>
						<div class="uf-grid pad">
							<uf-text [formControlName]="controlKeys.ConsoleName" [label]="consoleNameLabel" class="col-12">
								<span class="suffix">*</span>
							</uf-text>
						</div>
					</div>
					<div class="uf-box gap-top">
						<div class="uf-app-bar flat accent">
							<div class="title">Source</div>
						</div>
						<div class="uf-grid pad">
							<uf-select [options]="sourceTypes" [formControlName]="controlKeys.SourceType" label="Source" placeholder="Select Source" valueProperty="identifier" class="col-12">
								<span class="suffix">*</span>
							</uf-select>
							@if (form.get(controlKeys.Bucket)?.enabled) {
								<uf-autocomplete [formControlName]="controlKeys.Bucket" [options]="buckets" (searchChange)="searchBuckets($event)" (valueChange)="bucketChange($event)" label="Form Data Repository" placeholder="Search form data repository" nameProperty="name" class="col-12">
									<span class="suffix">*</span>
								</uf-autocomplete>
							}
						</div>
					</div>
					<div class="uf-box gap-top">
						<div class="uf-app-bar flat accent">
							<div class="title">Target</div>
						</div>
						<div class="uf-grid pad">
							<uf-autocomplete [options]="formOptions" [formControlName]="controlKeys.TargetForm" (searchChange)="searchForms($event)" (valueChange)="targetFormChange($event)" label="Form" placeholder="Search Form" nameProperty="name" class="col-12">
								<span class="suffix">*</span>
							</uf-autocomplete>
							@if (form.get(controlKeys.TargetTransition)?.enabled) {
								<uf-autocomplete [formControlName]="controlKeys.TargetTransition" [nameProperty]="transitionName" [options]="transitions" (searchChange)="searchTransitions($event)" (valueChange)="transitionChange($event)" label="Workflow Transition" placeholder="Search workflow transition" class="col-12">
									<span class="suffix">*</span>
								</uf-autocomplete>
							}
						</div>
					</div>
				</ng-container>
			}
		</div>
		@if (form) {
			<div>
				@if (metaFields?.length) {
					<table class="small uf-table fixed">
						<thead>
							<tr>
								<th colspan="2" class="table-header">
									<div class="uf-app-bar flat accent">
										<div class="title">Meta Fields</div>
										<uf-expander-button [isExpanded]="isExpanded" (click)="toggle()" />
									</div>
								</th>
							</tr>
							@if (isExpanded) {
								<tr>
									<th>Source Data</th>
									<th>Target Data</th>
								</tr>
							}
						</thead>
						@if (isExpanded) {
							<tbody>
								@for (field of metaFields; track field) {
									<tr>
										<td>
											<div class="row space-children--small wrap">
												<ng-container *ngTemplateOutlet="metaField; context: { field: field.source }" />
											</div>
										</td>
										<td>
											<div class="row space-children--small wrap">
												<ng-container *ngTemplateOutlet="metaField; context: { field: field.target }" />
											</div>
										</td>
									</tr>
								}
							</tbody>
						}
					</table>
				}
				@if (inputMapControl && targetFields) {
					<uc-field-mapping-table [control]="inputMapControl" [targetFields]="targetFields" [sourceFields]="sourceFields" class="gap-xl-bottom" />
				}
			</div>
		}
	</uf-panel>
}

<uc-error-message [error]="error" />

<ng-template #metaField let-field="field">
	@if (field.icon) {
		<uf-icon [name]="field.icon" class="light" />
	}
	@if (field.label) {
		<span class="light">{{ field.label }}</span>
	}
	@if (field.identifier) {
		<span class="light"
			>(<code>{{ field.identifier }}</code
			>)</span
		>
	}
	@if (field.expression) {
		<span class="light"
			><code>{{ field.expression }}</code></span
		>
	}
</ng-template>
