import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterComponentRegistry, FilterEntries, SortStatus, TableColumnPreferences, TableConfig, TableConfigColumn, TablePreferences, TablePreferencesProvider } from '@unifii/library/common';

import { Media } from 'client';

import { MediaFilterEntries } from './media-filter-entries';
import { MediaFilterRegistry } from './media-filter-regsitry';
import { MediaMetadataIdentifiers, MediaTableManager } from './media-table-manager';

export interface State {
	q?: string | null;
	sortBy?: string | null;
	typeIds?: string | null;
	tagIds?: string | null;
	pending?: boolean | null;
	mediaId?: string | null;
	viewType?: string | null;
}

interface ViewMode {
	className: string[] | string;
	actionIconName: string; // icon used in action button when in this mode
	visibleProperties: MediaMetadataIdentifiers[];
}

const CardViewConfig: ViewMode = {
	className: 'cards',
	actionIconName: 'bulletList',
	visibleProperties: [MediaMetadataIdentifiers.FileType, MediaMetadataIdentifiers.UploadedAt],
};

const TableViewConfig: ViewMode = {
	className: 'accent',
	actionIconName: 'grid',
	visibleProperties: [MediaMetadataIdentifiers.Url, MediaMetadataIdentifiers.Title, MediaMetadataIdentifiers.ConsoleName, MediaMetadataIdentifiers.Filename, MediaMetadataIdentifiers.UploadedAt],
};

@Component({
	selector: 'uc-media',
	template: `
            <div ufMasterDetail>
                <uf-table-container [ngClass]="viewMode.className" (addItem)="addItem()" ufSyncRoute class="pad-none">
                    <ng-container actions>
                        <button (click)="toggleView()" class="uf-action" type="button">
                            <uf-icon [name]="viewMode.actionIconName" />
                        </button>
                    </ng-container>
                </uf-table-container>
            </div>
            <router-outlet />
    `,
	providers: [
		{ provide: FilterComponentRegistry, useClass: MediaFilterRegistry },
		{ provide: FilterEntries, useValue: MediaFilterEntries },
		{ provide: TableContainerManager, useClass: MediaTableManager },
	],
	styleUrls: ['./media.less'],
	standalone: false,
})
export class MediaComponent implements OnInit {

	@HostBinding('class.stretch-component') class = true;
	viewMode: ViewMode;

	private route = inject(ActivatedRoute);
	private router = inject(Router);
	private tableManager = inject<MediaTableManager>(TableContainerManager);
	private tablePreferences = inject(TablePreferencesProvider);

	ngOnInit() {
		this.setViewMode(CardViewConfig, this.tableManager.tableConfig);
	}

	addItem() {
		void this.router.navigate(['new'], { relativeTo: this.route });
	}

	toggleView() {
		const viewConfig = this.viewMode === CardViewConfig ? TableViewConfig : CardViewConfig;

		this.setViewMode(viewConfig, this.tableManager.tableConfig);
	}

	private columnPreferenceMap(columns: TableConfigColumn<Media>[], whiteList: MediaMetadataIdentifiers[]): TableColumnPreferences[] {
		return columns.map(({ name }) => ({ name, visible: whiteList.includes(name as MediaMetadataIdentifiers) }));
	}

	private setViewMode(viewConfig: ViewMode, tableConfig: TableConfig<Media>) {
		const userTablePreferences: TablePreferences = {
			sort: new SortStatus(this.tableManager.defaultSort),
			columns: this.columnPreferenceMap(tableConfig.columns, viewConfig.visibleProperties),
		};

		if (tableConfig.id) {
			this.tablePreferences.saveTablePreferences(tableConfig.id, userTablePreferences);
		}

		for (const columnConfig of tableConfig.columns) {
			columnConfig.hidden = !viewConfig.visibleProperties.includes(columnConfig.name as MediaMetadataIdentifiers);
		}

		this.viewMode = viewConfig;
	}

}
