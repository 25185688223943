import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'uc-drag-test',
	templateUrl: './drag-test.html',
	standalone: false,
})
export class DragTestComponent {

	@HostBinding('class.page') class = true;

	items: any[] = [
		{ name: 'one' },
		{ name: 'two' },
		{
			name: 'three',
			children: [
				{ name: 'chair', colour: '#ffffff' },
				{ name: 'table', colour: '#ffffff' },
				{ name: 'computer', colour: '#ffffff' },
				{ name: 'nebula', colour: '#ffffff' },
			],
		},
		{ name: 'four' },
		{ name: 'five' },
		{
			name: 'six',
			children: [
				{ name: 'computer', colour: '#ffffff' },
				{ name: 'whiteboard', colour: '#ffffff' },
			],
		},
		{ name: 'seven' },
		{ name: 'eight' },
	];

}
