import { Injectable, OnDestroy, inject } from '@angular/core';
import { Definition, Field, FieldTemplate } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { BuilderService } from './builder.service';
import { TableTemplateManager } from './table-config-manager';

/**
 * Stateful class for managing field template configurations
 * required for mapping dependencies been parent fields and children
 * eg: if child identifier changes parent template config must be notified
 */
@Injectable()
export class TemplateConfigManager implements OnDestroy {

	readonly tableTemplates = [
		FieldTemplate.HorizontalTable,
		FieldTemplate.HorizontalTableMobile,
		FieldTemplate.VerticalTable,
		FieldTemplate.VerticalTableMobile,
	];

	private builderService = inject(BuilderService);
	private horizontalTableManager = new TableTemplateManager();
	private subscriptions = new Subscription();

	constructor() {
		this.subscriptions.add(this.builderService.cleanDefinitionStart.subscribe(() => this.update()));
	}

	init(definition: Definition) {

		for (const field of this.fieldIterable(definition.fields || [])) {
			this.set(field);
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	set(field: Field) {

		if (field.template == null) {
			return;
		}

		if (this.tableTemplates.includes(field.template as FieldTemplate)) {
			this.horizontalTableManager.set(field);
		}
	}

	update() {
		this.horizontalTableManager.update();
	}

	private *fieldIterable(fields: Field[]): Iterable<Field> {

		for (const field of fields) {

			if (field.fields) {
				yield *this.fieldIterable(field.fields);
			}
			yield field;
		}
	}

}
