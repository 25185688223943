"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MeClient = void 0;
const constants_1 = require("../constants");
const functions_1 = require("../functions");
/** Client for v0/me APIs */
class MeClient {
  constructor(client) {
    this.client = client;
  }
  get() {
    return this.client.get(this.url());
  }
  /** Update password (authenticated context) */
  updatePassword(passwordDetails) {
    /** Patch method is being used exclusively for updating password details */
    return this.client.patch(this.url(), passwordDetails);
  }
  /** Update password (anonymous context) */
  resetPassword(passwordDetails) {
    return this.client.patch(this.url(constants_1.APIPath.ResetPassword), passwordDetails);
  }
  /** Set user virtual MFA token secret */
  setVirtualMfaCode(secret) {
    return this.client.put(this.url(constants_1.APIPath.MeVirtualMfaCode), {
      secret
    });
  }
  /** Set user MFA Recovery Codes */
  setRecoveryCodes(recoveryCodes) {
    return this.client.put(this.url(constants_1.APIPath.MeRecoveryCodes), recoveryCodes);
  }
  /** Set user MFA Sms Enabled */
  setSmsMfaEnabled() {
    return this.client.patch(this.url(constants_1.APIPath.MeSmsMfaEnabled), undefined);
  }
  /** issue user MFA SMS Challenge */
  getSmsChallenges() {
    return this.client.put(this.url(constants_1.APIPath.MeSmsChallenges), undefined, {
      limitedAccessToken: true
    });
  }
  /** issue user Setup Device MFA Challenge */
  async setupDeviceMfa(origin) {
    const result = await this.client.put(this.url(constants_1.APIPath.MeSetupDeviceMfa), {
      origin
    }, {
      limitedAccessToken: true
    });
    // Convert backend response to DeviceMfaSetupChallenge
    return (0, functions_1.BackendToDeviceMfaSetupChallenge)(result);
  }
  /** issue user Device MFA Challenge */
  async getDeviceMfaChallenge(origin) {
    const result = await this.client.put(this.url(constants_1.APIPath.MeDeviceMfaChallenge), {
      origin
    }, {
      limitedAccessToken: true
    });
    // Convert backend response to CredentialRequestOptions
    return (0, functions_1.BackendToCredentialRequestOptions)(result);
  }
  /** issue user Device MFA Challenge */
  completeDeviceMfaSetup(attestationKey, deviceName) {
    return this.client.put(this.url(constants_1.APIPath.MeCompleteDeviceMfaSetup), {
      attestationKey,
      deviceName
    }, {
      limitedAccessToken: true
    });
  }
  getPermissions() {
    return this.client.get(this.url(constants_1.APIPath.MePermissions));
  }
  update(user) {
    return this.client.put(this.url(), user);
  }
  url(...extra) {
    return this.client.buildUrl(constants_1.APIPath.Me, ...extra);
  }
}
exports.MeClient = MeClient;
