<div class="row">
	@if (!edit) {
		<uf-password [disabled]="true" [showReveal]="edit" [control]="control" [label]="label" [value]="value" class="grow">
			@if (help) {
				<uf-help [content]="help" />
			}
		</uf-password>
	}
	@if (edit) {
		<uf-password [showReveal]="true" [control]="control" [label]="label" [(value)]="value" class="grow">
			@if (help) {
				<uf-help [content]="help" />
			}
		</uf-password>
	}
	@if (initialValue) {
		<div class="edit">
			<button (click)="toggleEdit()" type="button" class="uf-button">
				@if (!edit) {
					Edit
				}
				@if (edit) {
					Cancel Edit
				}
			</button>
		</div>
	}
</div>
