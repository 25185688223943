import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonTranslationKey, ModalService, SharedTermsTranslationKey } from '@unifii/library/common';
import { ErrorType, MfaChallengeType, MfaStatus, OAuthWithMfaDevice, OAuthWithMfaDeviceSetup, OAuthWithMfaRecoveryCode, OAuthWithMfaSms, OAuthWithVirtualMfa, SMSChallenge, TenantClient, UfRequestError, arrayBufferToBase64Url, ensureUfRequestError, isDictionary, isOptionalType, isPasswordChangeRequiredErrorData, isString, isValueOfStringEnumType } from '@unifii/sdk';
import { DeviceMfaNameModalComponent, isAuthenticatorAssertionResponse, isAuthenticatorAttestationResponse } from '@unifii/user-provisioning';

import { Config, ConsoleOptions } from 'app-config';
import { UcClient } from 'client';
import { UrlSegments } from 'constant';
import { AuthenticationService } from 'services/authentication.service';
import { ContextService } from 'services/context.service';

import { PasswordChangeComponentNavigationState } from './password-change.component';

export interface MfaComponentNavigationState {
	mfaStatus: MfaStatus;
	challenge?: `${MfaChallengeType}`;
	acceptedChallenges?: string;
	password?: string;
}

export const isMfaComponentNavigationState = (data: unknown): data is MfaComponentNavigationState =>
	isDictionary(data) &&
    isValueOfStringEnumType(MfaStatus)(data.mfaStatus) &&
    isOptionalType(data.challenge, isValueOfStringEnumType(MfaChallengeType)) &&
    isOptionalType(data.acceptedChallenges, isString) &&
    isOptionalType(data.password, isString);

@Component({
	selector: 'uc-mfa',
	templateUrl: 'mfa.html',
	styleUrls: ['mfa.less'],
	standalone: false,
})
export class MFAComponent implements OnInit {

	protected readonly mfaChallengeType = MfaChallengeType;
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly mfaStatuses = MfaStatus;
	protected label: string | undefined;
	protected mfaStatus: MfaStatus | undefined;
	protected inProgress = false;
	protected error: string | undefined;
	protected challenge: `${MfaChallengeType}` | undefined;
	protected acceptedChallenges: `${MfaChallengeType}`[] = [];

	private client = inject(UcClient);
	private router = inject(Router);
	private context = inject(ContextService);
	private authService = inject(AuthenticationService);
	private route = inject(ActivatedRoute);
	private tenantClient = inject(TenantClient);
	private modalService = inject(ModalService);
	private config = inject<ConsoleOptions>(Config);
	private cdr = inject(ChangeDetectorRef);
	private state: MfaComponentNavigationState = history.state; // type assumed by mfa-guard
	private deviceMfaChallengeKey: string | undefined;

	async ngOnInit() {
		this.challenge = this.state.challenge;
		this.acceptedChallenges = this.createAcceptedChallenges();

		if (this.state.mfaStatus === MfaStatus.MfaSetupRequired) {
			await this.setUpMfa();
		}

		this.mfaStatus = this.state.mfaStatus;
	}

	protected async verifyCredential(credential: PublicKeyCredential): Promise<void> {

		if (this.inProgress || !isAuthenticatorAssertionResponse(credential.response)) {
			return;
		}

		this.inProgress = true;
		this.error = undefined;

		const params: OAuthWithMfaDevice = {
			id: credential.id,
			raw_id: arrayBufferToBase64Url(credential.rawId),
			type: credential.type,
			client_data_json: arrayBufferToBase64Url(credential.response.clientDataJSON),
			authenticator_data: arrayBufferToBase64Url(credential.response.authenticatorData),
			signature: arrayBufferToBase64Url(credential.response.signature),
		};

		try {
			await this.authService.login(params);
			await this.handleVerifyAccepted();
		} catch (e) {
			this.handleVerifyError(ensureUfRequestError(e));
		} finally {
			this.inProgress = false;
		}
	}

	protected async setupCredential(credential: PublicKeyCredential): Promise<void> {

		if (this.inProgress || !this.deviceMfaChallengeKey || !isAuthenticatorAttestationResponse(credential.response)) {
			return;
		}

		this.inProgress = true;
		this.error = undefined;

		const params: OAuthWithMfaDeviceSetup = {
			id: credential.id,
			raw_id: arrayBufferToBase64Url(credential.rawId),
			type: credential.type,
			challenge_key: this.deviceMfaChallengeKey,
			client_data_json: arrayBufferToBase64Url(credential.response.clientDataJSON),
			attestation_object: arrayBufferToBase64Url(credential.response.attestationObject),
		};

		try {
			await this.authService.login(params);
			const name = await this.modalService.openMedium(DeviceMfaNameModalComponent) ?? '';

			await this.client.completeDeviceMfaSetup(this.deviceMfaChallengeKey, name);
			await this.handleVerifyAccepted();
		} catch (e) {
			this.handleVerifyError(ensureUfRequestError(e));
		} finally {
			this.inProgress = false;
		}

	}

	protected async getSetupChallenge(): Promise<CredentialCreationOptions> {
		try {
			const { publicKey, challengeKey } = await this.client.setupDeviceMfa(this.config.baseUrl);

			this.deviceMfaChallengeKey = challengeKey;

			return { publicKey };
		} catch (e) {
			throw this.handleChallengeError(ensureUfRequestError(e));
		}
	}

	protected async getVerifyChallenge(): Promise<CredentialRequestOptions> {
		try {
			return await this.client.getDeviceMfaChallenge(this.config.baseUrl);
		} catch (e) {
			throw this.handleChallengeError(ensureUfRequestError(e));
		}
	}

	protected selectProvider(provider: MfaChallengeType) {
		this.error = undefined;
		this.challenge = provider;
	}

	protected async setRecoveryCodes(recoveryCodes: string[]): Promise<void> {
		await this.client.setRecoveryCodes(recoveryCodes);
		void this.router.navigateByUrl(this.route.snapshot.params.next || '/');
	}

	protected async setVirtualMfaCode(secret: string): Promise<void> {
		await this.client.setVirtualMfaCode(secret);
	}

	protected async getSmsChallenges(): Promise<SMSChallenge> {
		try {
			return await this.client.getSmsChallenges();
		} catch (e) {
			throw this.handleChallengeError(ensureUfRequestError(e));
		}
	}

	protected async verifyRecoveryCode(recovery_code: string): Promise<void> {
		if (this.inProgress) {
			return;
		}
		this.error = undefined;
		this.inProgress = true;
		try {
			await this.authService.login({ recovery_code } satisfies OAuthWithMfaRecoveryCode);
			await this.handleVerifyAccepted();
		} catch (e) {
			this.handleVerifyError(ensureUfRequestError(e));
		} finally {
			this.inProgress = false;
		}
	}

	protected async verifySmsCode(code: string, challenge: string): Promise<void> {
		if (this.inProgress) {
			return;
		}
		this.error = undefined;
		this.inProgress = true;
		try {
			await this.authService.login({ code, challenge } satisfies OAuthWithMfaSms);

			if (this.state.mfaStatus === MfaStatus.MfaSetupRequired) {
				await this.client.setSmsMfaEnabled();
			}

			await this.handleVerifyAccepted();
		} catch (e) {
			this.handleVerifyError(ensureUfRequestError(e));
		} finally {
			this.inProgress = false;
		}
	}

	protected async verifyVirtualMfaToken(token: string): Promise<void> {
		if (this.inProgress) {
			return;
		}
		this.error = undefined;
		this.inProgress = true;
		try {
			await this.authService.login({ mfa_token: token } satisfies OAuthWithVirtualMfa);
			await this.handleVerifyAccepted();
		} catch (e) {
			this.handleVerifyError(ensureUfRequestError(e));
		} finally {
			this.inProgress = false;
		}
	}

	protected logout() {
		if (this.inProgress) {
			return;
		}
		void this.authService.logout();
	}

	private async handleVerifyAccepted() {

		if (this.context.account?.mfa?.hasRecoveryCodes === false) {

			// reset recovery code component is already used
			if (this.challenge === MfaChallengeType.RecoveryCode) {
				this.challenge = undefined;
				this.cdr.detectChanges();
			}

			this.mfaStatus = MfaStatus.MfaSetupRequired;
			this.acceptedChallenges = [MfaChallengeType.RecoveryCode];
			this.challenge = MfaChallengeType.RecoveryCode;

			return;
		}

		await this.router.navigateByUrl(this.route.snapshot.params.next || '/');
	}

	private handleVerifyError(error: UfRequestError): void {

		if (isPasswordChangeRequiredErrorData(error.data) && this.state.password) {
			const params = this.route.snapshot.params.next ? { next: this.route.snapshot.params.next }: {};

			void this.router.navigate(['/', UrlSegments.PasswordChange, params], { state: { oldPassword: this.state.password } satisfies PasswordChangeComponentNavigationState });

			return;
		}

		// Display Mfa Timeout Message if token is invalid.
		if (isDictionary(error.data) && error.data.error === 'invalid_grant' && error.data.error_description === 'Invalid code') {
			this.error = 'Your Multi-Factor Authentication Verification has timed out';

			return;
		}

		this.error = error.message;
	}

	private handleChallengeError(error: UfRequestError): UfRequestError {
		// Display Mfa Timeout Message if token is invalid.
		if (error.type === ErrorType.Unauthorized) {
			error.message = 'Your Multi-Factor Authentication Verification has timed out';
		}

		return error;
	}

	private async setUpMfa() {
		try {
			const tenantName = (await this.tenantClient.getSettings()).name;

			if (!this.context.account?.username) {
				throw new Error('No username');
			}

			this.label = `(${tenantName}) ${this.context.account.username}`;

		} catch (e) {
			const error = ensureUfRequestError(e);

			this.error = error.message;
		}
	}

	private createAcceptedChallenges(): `${MfaChallengeType}`[] {

		let acceptedChallenges: `${MfaChallengeType}`[] = [];

		if (this.state.acceptedChallenges) {
			acceptedChallenges = this.state.acceptedChallenges.split(',').filter((challenge): challenge is `${MfaChallengeType}` => isValueOfStringEnumType(MfaChallengeType)(challenge));
		} else {
			acceptedChallenges = Object.values(MfaChallengeType);

			/* remove setup sms if tenant doesn't support sms
             * tenant settings are only available for setup, not for verify
            */
			if (!this.context.tenantSettings?.isSmsMfaEnabled) {
				acceptedChallenges = acceptedChallenges.filter((challenge) => challenge !== MfaChallengeType.Sms);
			}

			// remove device if tenant doesn't support device mfa or device is not supported on the platform
			if (!this.context.tenantSettings?.isDeviceMfaEnabled) {
				acceptedChallenges = acceptedChallenges.filter((challenge) => challenge !== MfaChallengeType.Device);
			}
		}

		return acceptedChallenges;
	}

}
