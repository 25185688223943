<uf-autocomplete [options]="options" [control]="control" [autofocus]="autofocus" [(value)]="value" (searchChange)="filter($event)" (valueChange)="valueChange.emit($event)" nameProperty="title">
	<ng-template #listBox let-options="options" let-select="select" let-active="active">
		<ul class="uf-list">
			@for (option of options; track option; let i = $index) {
				<li [class.active]="i === active" (click)="select(option)" class="uf-list-button">
					@if (option.url && option.type !== "File" && option.type !== "Audio" && option.type !== "Video") {
						<div class="image">
							<img [src]="option?.url + '?w=48&h=48&mode=contain'" alt="option.title" />
						</div>
					}
					<span class="content">
						<h4>{{ option.consoleName }}</h4>
						<p>{{ option.description }}</p>
					</span>
				</li>
			}
		</ul>
	</ng-template>
</uf-autocomplete>
