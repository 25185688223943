@if (heading) {
	<h4>{{ heading }}</h4>
}

@if (invalid) {
	<uf-blockquote class="col-12 info gap-top" icon="info" content="Filter configuration is invalid" />
	<br />
	<button (click)="reset()" type="button" class="uf-button right">Remove Filter</button>
}

@if (control) {
	@for (node of control.controls | asUfControlsGroups; track node; let i = $index) {
		<div class="uc-list-item">
			<button (click)="remove(i)" type="button" style="float: right" title="Delete" class="uf-action tertiary">
				<uf-icon name="delete" />
			</button>
			<uc-filter-entry [control]="node" [dataProperties]="dataProperties" />
		</div>
	}
	<br />
	<button (click)="add()" type="button" class="uf-button right">Add Filter</button>
}
