import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Modal, ModalRuntime, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';

import { ApiKey } from 'client';
import { ConsoleNameLabel } from 'constant';

type NewApiKey = Pick<ApiKey, 'consoleName' | 'enableOAuth'>;

enum ControlKeys {
	ConsoleName = 'consoleName',
	EnableOAuth = 'enableOAuth',
}

@Component({
	selector: 'uc-new-api-key-modal',
	templateUrl: './new-api-key-modal.html',
	standalone: false,
})
export class NewApiKeyModalComponent implements Modal<void, NewApiKey>, OnInit {

	@HostBinding('class.uc-form-card') formCardClass = true;

	runtime = inject<ModalRuntime<void, NewApiKey>>(ModalRuntime);

	protected readonly controlKeys = ControlKeys;
	protected readonly consoleNameLabel = ConsoleNameLabel;
	protected form: UfControlGroup;

	private ufb = inject(UfFormBuilder);

	ngOnInit() {
		this.form = this.ufb.group({
			[ControlKeys.ConsoleName]: [
				null,
				ValidatorFunctions.compose([
					ValidatorFunctions.required('Field is required'),
					ValidatorFunctions.pattern(`^[A-Za-z][A-Za-z0-9_ ]*$`, `${ConsoleNameLabel} contains an invalid character`),
				]),
			],
			[ControlKeys.EnableOAuth]: null,
		});
	}

	protected save() {
		this.form.setSubmitted();

		if (this.form.invalid) {
			return;
		}

		this.runtime.close(this.form.getRawValue());
	}

}
