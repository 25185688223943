<div [formGroup]="control" class="grid--no-padding">
	<!-- <pre class="col-1of1">{{filterEntry | json}}</pre> -->

	<!-- Field -->
	<uf-autocomplete [options]="fieldOptions" [formControlName]="controlKeys.Field" [ngClass]="cssClass" (searchChange)="search($event)" label="Field" placeholder="Search..." nameProperty="display" class="col-1of2" />

	<!-- Operator -->
	@if (filterEntry.field) {
		<uf-select [options]="operatorOptions" [formControlName]="controlKeys.Operator" [ngClass]="cssClass" label="Operator" valueProperty="identifier" class="col-1of2" />
	}

	<!-- ValueType -->
	@if (filterEntry.operator) {
		<uf-select [options]="valueTypeOptions" [formControlName]="controlKeys.ValueType" [ngClass]="cssClass" label="Value Type" valueProperty="identifier" class="col-1of2" />
	}

	@if (filterEntry.field && filterEntry.operator) {
		<!-- Value -->
		@if (filterEntry.valueType === nodeTypes.Value) {
			<uc-filter-value [field]="filterEntry.field" [operator]="filterEntry.operator" [control]="valueControl" cssClass="small" class="col-1of2 small" />
		}
		<!-- Expression -->
		@if (filterEntry.valueType === nodeTypes.Expression) {
			<uf-text [formControlName]="controlKeys.Value" [ngClass]="cssClass" label="Expression" class="col-1of2" />
		}
	}

	<uf-error [control]="control" class="col-1of1" />
</div>
