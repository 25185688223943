<uf-panel class="container">
	@if (!loading) {
		<div class="header-pane uf-app-bar transparent">
			<uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
				@if (edited) {
					<span> *</span>
				}
			</uf-breadcrumbs>
			<div class="row header-buttons right">
				<a [routerLink]="['../']" class="uf-button tertiary">Cancel</a>
				<button (click)="delete()" restrict="AssetManager" type="button" class="uf-button">Delete</button>
				<uc-save-options [options]="saveOptions" (onSave)="save($event)" restrict="AssetManager" />
			</div>
		</div>
	}

	<uf-panel class="content-pane">
		@if (!loading) {
			<div [formGroup]="detailsForm" class="uf-container-lg">
				<div class="uf-grid row-gap-lg pad-top">
					<div class="uf-box col-12">
						<div class="uf-app-bar flat accent">
							<div class="title">Details</div>
						</div>
						<div class="uf-grid pad">
							@if (error) {
								<div class="col-12">
									<uf-blockquote [content]="error.message || 'Unknown Error'" icon="errorSolid" class="error-text" />
								</div>
							}
							<div [class.checkboard-bg]="media.type === mediaType.Image" class="col-12 media-detail-preview">
								@if (media.type === mediaType.Image) {
									<img src="{{ getUrl(640, 320) }}" (click)="viewFullSizeImage()" />
								}
								@if (media.type === mediaType.Video) {
									<video poster="{{ getUrl(640, 320) }}" width="640" controls>
										<source src="{{ media.url }}" type="video/mp4" />
										Your browser doesn't support HTML5 video.
									</video>
								}
								@if (media.type === "Audio") {
									<audio controls="controls">
										Your browser does not support the
										<code>audio</code> element.
										<source src="{{ media.url }}" type="audio/mp3" />
									</audio>
								}
							</div>
							<uf-data-display-list class="col-12">
								<dt>Filename</dt>
								<dd>{{ media.filename }}</dd>
								<dt>Actual Image size</dt>
								<dd>{{ media.width }} x {{ media.height }} px</dd>
								<dt>Upload Date</dt>
								<dd>{{ media.uploadedAt | dataDisplay }}</dd>
							</uf-data-display-list>
							<div class="col-12">
								<button (click)="download()" type="button" class="uf-button primary">Download</button>
							</div>
							@if (detailsForm.controls.titleControl) {
								<uf-text [control]="detailsForm.controls.titleControl | asUfControl" [(value)]="media.title" label="Title" name="title" class="col-12" />
							}
							@if (detailsForm.controls.consoleNameControl) {
								<uf-text [control]="detailsForm.controls.consoleNameControl | asUfControl" [label]="consoleNameLabel" [(value)]="media.consoleName" class="col-12" />
							}
							@if (detailsForm.controls.descriptionControl) {
								<uf-textarea [control]="detailsForm.controls.descriptionControl | asUfControl" [(value)]="media.description" label="Description" name="description" class="col-12" />
							}
							@if (detailsForm.controls.authorControl) {
								<uf-text [control]="detailsForm.controls.authorControl | asUfControl" [(value)]="media.author" label="Author" name="author" class="col-12" />
							}
							@if (detailsForm.controls.creditsControl) {
								<uf-text [control]="detailsForm.controls.creditsControl | asUfControl" [(value)]="media.credits" name="credits" label="Credits" class="col-12" />
							}
						</div>
					</div>
				</div>
			</div>
		}
	</uf-panel>
</uf-panel>

<uc-error-message [error]="error" />
