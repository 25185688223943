"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LinkContentType = exports.TableDetailModuleType = exports.TableSourceType = exports.HierarchyUnitSelectionMode = exports.DataSourceInputType = exports.DataSourceType = exports.FieldWidth = exports.LayoutDirection = exports.FieldTemplate = exports.DefinitionPublishState = exports.ContentType = exports.StructureNodeType = exports.ValidatorType = exports.CompoundType = exports.FieldType = void 0;
var FieldType;
(function (FieldType) {
  FieldType["Text"] = "Text";
  FieldType["MultiText"] = "MultiText";
  FieldType["Cost"] = "Cost";
  FieldType["Date"] = "Date";
  FieldType["Time"] = "Time";
  FieldType["DateTime"] = "DateTime";
  FieldType["ZonedDateTime"] = "ZonedDateTime";
  // eslint-disable-next-line id-denylist
  FieldType["Number"] = "Number";
  FieldType["Phone"] = "Phone";
  FieldType["Email"] = "Email";
  FieldType["Website"] = "Website";
  FieldType["Bool"] = "Bool";
  FieldType["Choice"] = "Choice";
  FieldType["MultiChoice"] = "MultiChoice";
  FieldType["ImageList"] = "ImageList";
  FieldType["SoundList"] = "SoundList";
  FieldType["VideoList"] = "VideoList";
  FieldType["FileList"] = "FileList";
  FieldType["GeoLocation"] = "GeoLocation";
  FieldType["Content"] = "Content";
  FieldType["Address"] = "Address";
  FieldType["Signature"] = "Signature";
  FieldType["Separator"] = "Separator";
  FieldType["Stepper"] = "Stepper";
  FieldType["Step"] = "Step";
  FieldType["Survey"] = "Survey";
  FieldType["Group"] = "Group";
  FieldType["Repeat"] = "Repeat";
  FieldType["Section"] = "Section";
  FieldType["Carousel"] = "Carousel";
  FieldType["ActionGroup"] = "ActionGroup";
  FieldType["Lookup"] = "Lookup";
  FieldType["Link"] = "Link";
  FieldType["LinkList"] = "LinkList";
  FieldType["DefinitionLink"] = "DefinitionLink";
  FieldType["TextArray"] = "TextArray";
  FieldType["Hierarchy"] = "Hierarchy";
})(FieldType || (exports.FieldType = FieldType = {}));
var CompoundType;
(function (CompoundType) {
  CompoundType["Collection"] = "Collection";
  CompoundType["View"] = "View";
  CompoundType["Page"] = "Page";
  CompoundType["Form"] = "Form";
  CompoundType["Structure"] = "Structure";
})(CompoundType || (exports.CompoundType = CompoundType = {}));
var ValidatorType;
(function (ValidatorType) {
  ValidatorType["Required"] = "Required";
  ValidatorType["Pattern"] = "Pattern";
  ValidatorType["MinLength"] = "MinLength";
  ValidatorType["MaxLength"] = "MaxLength";
  ValidatorType["Min"] = "Min";
  ValidatorType["Max"] = "Max";
  ValidatorType["Expression"] = "Expression";
  ValidatorType["ItemExpression"] = "ItemExpression";
  ValidatorType["LettersOnly"] = "LettersOnly";
  ValidatorType["Alphanumeric"] = "Alphanumeric";
  ValidatorType["BeforeNow"] = "BeforeNow";
  ValidatorType["AfterNow"] = "AfterNow";
  ValidatorType["Email"] = "Email";
  ValidatorType["Website"] = "Website";
})(ValidatorType || (exports.ValidatorType = ValidatorType = {}));
var StructureNodeType;
(function (StructureNodeType) {
  StructureNodeType["CollectionItem"] = "CollectionItem";
  StructureNodeType["Collection"] = "Collection";
  StructureNodeType["View"] = "View";
  StructureNodeType["Page"] = "Page";
  StructureNodeType["Form"] = "Form";
  StructureNodeType["FormBucket"] = "FormBucket";
  StructureNodeType["Empty"] = "Empty";
  StructureNodeType["Link"] = "Link";
  StructureNodeType["Dashboard"] = "Dashboard";
  StructureNodeType["IFrame"] = "IFrame";
  StructureNodeType["PdfViewer"] = "PdfViewer";
  StructureNodeType["Custom"] = "Custom";
})(StructureNodeType || (exports.StructureNodeType = StructureNodeType = {}));
var ContentType;
(function (ContentType) {
  ContentType["Collection"] = "Collection";
  ContentType["CollectionItem"] = "CollectionItem";
  ContentType["View"] = "View";
  ContentType["Page"] = "Page";
  ContentType["Form"] = "Form";
  ContentType["Table"] = "Table";
  ContentType["Detail"] = "Detail";
})(ContentType || (exports.ContentType = ContentType = {}));
var DefinitionPublishState;
(function (DefinitionPublishState) {
  DefinitionPublishState["ArchivePending"] = "ArchivePending";
  DefinitionPublishState["Archived"] = "Archived";
  DefinitionPublishState["Draft"] = "Draft";
  DefinitionPublishState["Approved"] = "Approved";
  DefinitionPublishState["Published"] = "Published";
})(DefinitionPublishState || (exports.DefinitionPublishState = DefinitionPublishState = {}));
/** TODO: does this belong here */
var FieldTemplate;
(function (FieldTemplate) {
  FieldTemplate["HorizontalTable"] = "HorizontalTable";
  FieldTemplate["HorizontalTableMobile"] = "HorizontalTableMobile";
  FieldTemplate["VerticalTable"] = "VerticalTable";
  FieldTemplate["VerticalTableMobile"] = "VerticalTableMobile";
  FieldTemplate["Form"] = "Form";
  FieldTemplate["DropDown"] = "DropDown";
  FieldTemplate["Checkbox"] = "CheckBox";
  FieldTemplate["List"] = "list";
  FieldTemplate["Radio"] = "Radio";
  FieldTemplate["Table"] = "table";
  FieldTemplate["RadioWithContent"] = "RadioWithContent";
  FieldTemplate["CheckboxWithContent"] = "CheckBoxWithContent";
  FieldTemplate["OptionWithContent"] = "OptionWithContent";
  FieldTemplate["Chips"] = "Chips";
  FieldTemplate["BoolTickCross"] = "BoolTickCross";
  FieldTemplate["Survey"] = "Survey";
  FieldTemplate["Left"] = "left";
  FieldTemplate["Right"] = "right";
  FieldTemplate["Stretch"] = "stretch";
  FieldTemplate["Banner"] = "banner";
  FieldTemplate["Content"] = "content";
  FieldTemplate["Callout"] = "callout";
  FieldTemplate["Success"] = "success";
  FieldTemplate["Alert"] = "alert";
  FieldTemplate["Info"] = "info";
  FieldTemplate["Warning"] = "warning";
  FieldTemplate["DoubleDivider"] = "doubleDivider";
  FieldTemplate["DashedDivider"] = "dashedDivider";
  FieldTemplate["DottedDivider"] = "dottedDivider";
  FieldTemplate["Pdf"] = "pdf";
  FieldTemplate["Space"] = "space";
  FieldTemplate["Buttons"] = "Buttons";
  FieldTemplate["Hidden"] = "hidden";
})(FieldTemplate || (exports.FieldTemplate = FieldTemplate = {}));
var LayoutDirection;
(function (LayoutDirection) {
  LayoutDirection["Row"] = "Row";
  LayoutDirection["Column"] = "Column";
})(LayoutDirection || (exports.LayoutDirection = LayoutDirection = {}));
var FieldWidth;
(function (FieldWidth) {
  FieldWidth["Default"] = "";
  FieldWidth["Quarter"] = "Quarter";
  FieldWidth["Third"] = "Third";
  FieldWidth["Half"] = "Half";
  FieldWidth["TwoThirds"] = "TwoThirds";
  FieldWidth["Stretch"] = "Stretch";
})(FieldWidth || (exports.FieldWidth = FieldWidth = {}));
var DataSourceType;
(function (DataSourceType) {
  DataSourceType["Collection"] = "Collection";
  DataSourceType["Bucket"] = "Bucket";
  DataSourceType["Users"] = "Users";
  DataSourceType["UserClaims"] = "UserClaims";
  DataSourceType["Company"] = "Company";
  DataSourceType["External"] = "External";
  DataSourceType["Named"] = "Named";
})(DataSourceType || (exports.DataSourceType = DataSourceType = {}));
var DataSourceInputType;
(function (DataSourceInputType) {
  DataSourceInputType["Text"] = "Text";
  // eslint-disable-next-line id-denylist
  DataSourceInputType["Number"] = "Number";
})(DataSourceInputType || (exports.DataSourceInputType = DataSourceInputType = {}));
var HierarchyUnitSelectionMode;
(function (HierarchyUnitSelectionMode) {
  HierarchyUnitSelectionMode["Any"] = "Any";
  HierarchyUnitSelectionMode["Leaf"] = "Leaf";
  // Specifics = 'Specifics' v2
})(HierarchyUnitSelectionMode || (exports.HierarchyUnitSelectionMode = HierarchyUnitSelectionMode = {}));
var TableSourceType;
(function (TableSourceType) {
  TableSourceType["Bucket"] = "Bucket";
  TableSourceType["Users"] = "Users";
  TableSourceType["Company"] = "Company";
})(TableSourceType || (exports.TableSourceType = TableSourceType = {}));
var TableDetailModuleType;
(function (TableDetailModuleType) {
  TableDetailModuleType["Table"] = "Table";
  // Custom = 'Custom',
  // External = 'External',
  // Collection = 'Collection',
  // View = 'View',
  // Page = 'Page'
})(TableDetailModuleType || (exports.TableDetailModuleType = TableDetailModuleType = {}));
/**
 * Used by Content Link Form Field
 */
var LinkContentType;
(function (LinkContentType) {
  LinkContentType["Page"] = "Page";
  LinkContentType["Asset"] = "Asset";
  LinkContentType["Attachment"] = "Attachment";
  LinkContentType["Url"] = "Url";
  LinkContentType["Form"] = "Form";
})(LinkContentType || (exports.LinkContentType = LinkContentType = {}));
