import { Component, HostBinding, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';

import { EmailTemplate } from 'client';

interface EmailPreviewData extends EmailTemplate {
	emailColour: string;
}

@Component({
	selector: 'uc-system-email-template-preview-modal',
	templateUrl: 'system-email-template-preview-modal.html',
	standalone: false,
})
export class SystemEmailPreviewModalComponent implements Modal<EmailPreviewData, null> {

	@HostBinding('class.uf-form-card') cardClass = true;
	runtime = inject<ModalRuntime<EmailPreviewData, null>>(ModalRuntime);
	data = inject<EmailPreviewData>(ModalData);

	close() {
		this.runtime.close();
	}

}
