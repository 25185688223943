@if (userInfo.isExternal || (authProviders.length && isMyAccount)) {
	<div [ngClass]="cssClasses" class="uf-box">
		<div class="uf-app-bar flat accent">
			<div class="title">External Authentication</div>
		</div>
		<div class="uc-group-content pad-sides pad-bottom">
			@for (provider of userAuthProviders; track provider) {
				@if (authProviderInfo[provider.id]; as providerInfo) {
					<div class="body-copy">
						<h5>{{ provider.type }} - {{ provider.tenant }}</h5>
						<uf-data-display-list>
							@if (providerInfo.systemRoles.length) {
								<dt>System Roles</dt>
								<dd>{{ providerInfo.systemRoles.join(", ") }}</dd>
							}
							@if (providerInfo.roles.length) {
								<dt>Tenant Roles</dt>
								<dd>{{ getRolesDisplays(providerInfo.roles).join(", ") }}</dd>
							}
							@if (providerInfo.claims.length) {
								<dt>Claims</dt>
								<dd>
									@for (claim of providerInfo.claims; track claim) {
										<span> {{ claim }}<br /> </span>
									}
								</dd>
							}
							@if (providerInfo.units?.length) {
								<dt>Units</dt>
								<dd>
									@for (unit of providerInfo.units; track unit) {
										<span> {{ unit._display }}<br /> </span>
									}
								</dd>
							}
						</uf-data-display-list>
					</div>
				}
			}
			@if (authProviders.length && isMyAccount && !userInfo.isExternal) {
				@if (error) {
					<uf-message [content]="error.message || 'Failed to link to provider'" icon="error" class="error col-12" />
				}
				<uf-checkbox [(value)]="connectToExternal" label="Connect your Unifii account to an Identity Provider" class="col-12" />
				@if (connectToExternal) {
					<uf-blockquote icon="infoSolid" heading="Connect your Unifii account to an Identity Provider" content="If you’re currently logged in to the selected provider in the browser, this account will be selected automatically. Otherwise you will be required to log in." class="info col-12" />
					<div class="col-12">
						@for (provider of authProviders; track provider) {
							@if (provider.providerLoginLabel) {
								<label class="small">{{ provider.providerLoginLabel }}</label>
							}
							<button (click)="linkToProvider(provider)" type="button" class="authentication-provider gap-md-bottom">
								<img [src]="provider.loginIcon" />
								<p class="small">{{ provider.loginLabel }}</p>
							</button>
						}
					</div>
				}
			}
		</div>
	</div>
}
