<uf-panel class="container">
	<div class="grid--fixed">
		<div class="col-1of1">
			<h1>TODO change these to reflect UNIFII-2695</h1>

			<h2>Lists</h2>
			<div class="section-label">List Items</div>

			<h3>Draggable Group List</h3>
			<div>
				<div class="uc-group">
					<div class="uf-app-bar flat draggable">
						<div class="title primary">Title</div>
						<a class="uf-action" title="Edit">
							<uf-icon name="edit" />
						</a>
						<a class="uf-action tertiary" title="Locate">
							<uf-icon name="address" />
						</a>
					</div>
					<div class="uc-group-content grid">Content</div>
				</div>
			</div>

			<h3>Basic List</h3>
			<ul class="uc-list">
				<li class="uc-list-item">
					<span>Regular</span>
				</li>

				<li class="uc-list-item--small">
					<span>Small</span>
				</li>

				<li class="uc-list-item--large">
					<span>Large</span>
				</li>

				<li class="uc-list-item--bordered">
					<span>Bordered</span>
				</li>

				<li class="uc-list-item--draggable">
					<span>Draggable</span>
				</li>

				<li class="uc-list-item--draggable--no-shrink">
					<span>Draggable no shrink</span>
				</li>

				<li class="uc-list-item--clickable">
					<span>clickable</span>
				</li>
			</ul>

			<h3>Field Picker</h3>
			<ul class="uc-list">
				<li class="uc-list-item--draggable--small row center-all">
					<uf-icon name="text" />
					<span class="gap-left">Text</span>
				</li>

				<li class="uc-list-item--draggable--small row center-all">
					<uf-icon name="multiText" />
					<span class="gap-left">Multi Text</span>
				</li>

				<li class="uc-list-item--draggable--small row center-all">
					<uf-icon name="number" />
					<span class="gap-left grow">Number</span>
					<button class="uf-action tertiary" type="button" title="Delete">
						<uf-icon name="delete" />
					</button>
				</li>

				<li class="uc-list-item--draggable--small row center-all">
					<uf-icon name="date" />
					<span class="gap-left grow">Date</span>
					<button class="uf-action tertiary" type="button" title="Delete">
						<uf-icon name="delete" />
					</button>
				</li>
			</ul>
			<pre>
                &lt;li class="uc-list-item--draggable--small row center-all">
                        &lt;uf-icon name="date">&lt;/uf-icon>
                        &lt;span class="gap-left">Date&lt;/span>
                    &lt;/li></pre
			>

			<h3>File</h3>
			<ul class="uc-list">
				<li class="uc-list-item--draggable--small--no-shrink row center-all">
					<div class="list-content shrink">
						<div class="list-context">
							<a class="uf-action tertiary" title="Open">
								<uf-icon name="open" title="Open" />
							</a>
							<button type="button" class="uf-action tertiary" title="Delete">
								<uf-icon name="delete" />
							</button>
						</div>
						<h4>test.pdf</h4>
						<div class="fieldset-item--x-small">
							<span>File name: </span>
							<span>testytestytesttest-final.pdf</span>
						</div>
						<div class="fieldset-item--x-small">
							<span>Description: </span>
							<span>test pdf</span>
						</div>
						<div class="fieldset-item--x-small">
							<span>Uploaded at: </span>
							<span>22/03/2018, 1:41 pm</span>
						</div>
					</div>
				</li>
			</ul>
			<br />
			<pre>
                &lt;li class="uc-list-item--draggable--small--no-shrink row center-all">
                        &lt;div class="list-content shrink">
                            &lt;div class="list-context">
                                &lt;a class="uf-action tertiary" title="Open">
                                &lt;uf-icon name="open">&lt;/uf-icon>
                                    &lt;/a>
                                &lt;button class="uf-action tertiary" title="Remove">
                                    &lt;uf-icon name="delete">&lt;/uf-icon>
                                &lt;/button>
                            &lt;/div>
                            &lt;h4>test.pdf&lt;/h4>
                            &lt;div class="fieldset-item--x-small">
                                &lt;span>File name: &lt;/span>
                                &lt;span>testytestytesttest-final.pdf&lt;/span>
                            &lt;/div>
                            &lt;div class="fieldset-item--x-small">
                                &lt;span>Description: &lt;/span>
                                &lt;span>test pdf&lt;/span>
                            &lt;/div>
                            &lt;div class="fieldset-item--x-small">
                                &lt;span>Uploaded at: &lt;/span>
                                &lt;span>22/03/2018, 1:41 pm&lt;/span>
                            &lt;/div>
                        &lt;/div>
                    &lt;/li></pre
			>

			<h3>Image File</h3>
			<ul class="uc-list">
				<li class="uc-list-item--draggable--small--no-shrink row center-all">
					<div class="shrink">
						<img class="thumb" src="/assets/img/product-background.jpg" />
					</div>
					<div class="list-content shrink">
						<div class="list-context">
							<a class="uf-action tertiary" title="Open">
								<uf-icon name="open" class="" />
							</a>
							<button type="button" class="uf-action tertiary" title="Crop">
								<uf-icon name="crop" />
							</button>
							<button type="button" class="uf-action tertiary" title="Delete">
								<uf-icon name="delete" />
							</button>
						</div>
						<h4>test.jpg</h4>
						<div class="fieldset-item--x-small">
							<span>File name: </span>
							<span>testytestytesttest-final.jpg</span>
						</div>
						<div class="fieldset-item--x-small">
							<span>Description: </span>
							<span>test pdf</span>
						</div>
						<div class="fieldset-item--x-small">
							<span>Uploaded at: </span>
							<span>22/03/2018, 1:41 pm</span>
						</div>
					</div>
				</li>
			</ul>
			<br />
			<pre>
    &lt;li class="uc-list-item--draggable--small--no-shrink row center-all">
            &lt;div class="shrink">
                &lt;img class="thumb" src="/assets/img/product-background.jpg">
            &lt;/div>
            &lt;div class="list-content shrink">
                &lt;div class="list-context">
                    &lt;a class="uf-action tertiary" title="Open">
                    &lt;uf-icon name="open">&lt;/uf-icon>
                        &lt;/a>
                    &lt;button class="uf-action tertiary" title="Crop">
                        &lt;uf-icon name="crop">&lt;/uf-icon>
                    &lt;/button>
                    &lt;button class="uf-action tertiary" title="Delete">
                        &lt;uf-icon name="delete">&lt;/uf-icon>
                    &lt;/button>
                &lt;/div>
                &lt;h4>test.jpg&lt;/h4>
                &lt;div class="fieldset-item--x-small">
                    &lt;span>File name: &lt;/span>
                    &lt;span>testytestytesttest-final.jpg&lt;/span>
                &lt;/div>
                &lt;div class="fieldset-item--x-small">
                    &lt;span>Description: &lt;/span>
                    &lt;span>test pdf&lt;/span>
                &lt;/div>
                &lt;div class="fieldset-item--x-small">
                    &lt;span>Uploaded at: &lt;/span>
                    &lt;span>22/03/2018, 1:41 pm&lt;/span>
                &lt;/div>
            &lt;/div>
        &lt;/li></pre
			>

			<h3>Link List</h3>
			<ul class="uc-list">
				<li class="uc-list-item--draggable--small--no-shrink row center-all">
					<div class="list-content">
						<div class="list-context">
							<button class="uf-action tertiary" type="button" title="Open">
								<uf-icon name="open" />
							</button>
							<button class="uf-action tertiary" type="button" title="Delete">
								<uf-icon name="delete" />
							</button>
						</div>
						<h4>Record Name</h4>
					</div>
				</li>
			</ul>
			<br />
			<pre>
    &lt;li class="uc-list-item--draggable--small--no-shrink row center-all">
            &lt;div class="list-content">
                &lt;div class="list-context">
                    &lt;button class="uf-action tertiary" title="Open">
                        &lt;uf-icon name="open">&lt;/uf-icon>
                    &lt;/button>
                    &lt;button class="uf-action tertiary" title="Delete">
                        &lt;uf-icon name="delete">&lt;/uf-icon>
                    &lt;/button>
                &lt;/div>
                &lt;h4>Record Name&lt;/h4>
            &lt;/div>
        &lt;/li>
                </pre
			>

			<h3>Link</h3>
			<ul class="uc-list">
				<li class="uc-list-item--small row center-all">
					<div class="list-content">
						<div class="list-context">
							<button type="button" class="uf-action tertiary" title="Open">
								<uf-icon name="open" />
							</button>
							<button type="button" class="uf-action tertiary" title="Delete">
								<uf-icon name="delete" />
							</button>
						</div>
						<h4>Record Name</h4>
					</div>
				</li>
			</ul>
			<br />
			<pre>
    &lt;li class="uc-list-item--small row center-all">
            &lt;div class="list-content">
                &lt;div class="list-context">
                    &lt;button class="uf-action tertiary" title="Open">
                        &lt;uf-icon name="open">&lt;/uf-icon>
                    &lt;/button>
                    &lt;button class="uf-action tertiary" title="Delete">
                        &lt;uf-icon name="delete">&lt;/uf-icon>
                    &lt;/button>
                &lt;/div>
                &lt;h4>Record Name&lt;/h4>
            &lt;/div>
        &lt;/li>
                </pre
			>
		</div>
	</div>
</uf-panel>
