import { Component, HostBinding, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';

import { Media } from 'client';

@Component({
	selector: 'uc-image-preview',
	templateUrl: './image-preview.html',
	standalone: false,
})
export class ImagePreviewComponent implements Modal<Media, void> {

	@HostBinding('class.uc-form-card') class = true;
	runtime = inject<ModalRuntime<Media, void>>(ModalRuntime);
	data = inject<Media>(ModalData);

	close() {
		this.runtime.close();
	}

}
