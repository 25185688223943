@if (ready) {
	<uf-expander class="uf-box flat">
		<div expanderHeader class="uf-app-bar flat">
			<div class="title primary">Details</div>
			@if (descriptionControl) {
				<uc-content-description [control]="descriptionControl" mode="icon" />
			}
			@if (isInvalid) {
				<uf-icon name="error" class="error" />
			} @else {
				@if (meta.customFields) {
					<a (click)="save()" title="Save as Custom Field" class="uf-action tertiary">
						<uf-icon name="save" />
					</a>
				}
			}
			@if (control.valid) {
				<a (click)="copy()" title="Copy field" class="uf-action tertiary">
					<uf-icon name="copy" />
				</a>
				@if (meta.isContainer) {
					<a (click)="paste()" title="Paste fields" class="uf-action tertiary">
						<uf-icon name="paste" />
					</a>
				}
			}
		</div>
		<div [formGroup]="control" expanderBody class="uc-group-content grid">
			@if (descriptionControl) {
				<uc-content-description [control]="descriptionControl" mode="view" class="col-1of1" />
			}
			@if (meta.label) {
				<uf-textarea [formControlName]="fieldKeys.Label" label="Label" class="col-1of1" />
			}
			@if (meta.shortLabel) {
				<uf-text [formControlName]="fieldKeys.ShortLabel" label="Short label" class="col-1of1" />
			}
			@if (meta.identifier) {
				<uf-text [formControlName]="fieldKeys.Identifier" [maxLength]="identifierMaxLength" label="Identifier" class="col-1of1" />
			}
			@if (showIdentifierWarningLength) {
				<uf-message class="col-1of1 x-small warning" icon="warningSolid">
					<p>Identifier is too long</p>
				</uf-message>
			}
			@if (showContent) {
				<uc-markdown [formControlName]="fieldKeys.Help" [showAlignmentOptions]="true" label="Content" class="col-1of1" />
			}
			@if (meta.template) {
				<uf-select [formControlName]="fieldKeys.Template" [options]="templateOptions" [placeholder]="templatePlaceholder" valueProperty="identifier" nameProperty="name" label="Template" class="col-1of1" />
			}
			<div class="col-1of1 col">
				@if (meta.alwaysExpanded) {
					<uf-checkbox [formControlName]="fieldKeys.AlwaysExpanded" label="Always Expanded" />
				}
				@if (meta.expandWhenInactive) {
					<uf-checkbox [formControlName]="fieldKeys.ExpandWhenInactive" label="Expand in Inactive Sections" />
				}
				@if (meta.hideWhenInactive) {
					<uf-checkbox [formControlName]="fieldKeys.HideWhenInactive" label="Hide in Inactive Sections" />
				}
				@if (meta.activeBackgroundTinted) {
					<uf-checkbox [formControlName]="fieldKeys.ActiveBackgroundTinted" label="Background Tinted in Active Sections" />
				}
			</div>
			@if (meta.role) {
				<uf-chips [options]="rolesResult" [formControlName]="fieldKeys.Roles" (searchChange)="findRoles($event)" label="Roles" class="col-1of1" />
			}
		</div>
	</uf-expander>
}
