@if (ready && notifyEnabled) {
	<div class="grid padded--vert">
		@if (field.type === fieldType.Address) {
			<uf-checkbox [(value)]="autocompleteEnabled" (valueChange)="autocompleteEnabledChange($event)" label="Enable search" class="col-1of1--no-margin" />
		}
		@for (name of $any(fields)[field.type]; track name) {
			<div class="col-1of1--no-margin">
				@if (name !== "lat" && name !== "lng") {
					<div class="fieldset-item--x-small">
						<span>{{ getLabel(name) | toDisplayName }}</span>
					</div>
					<div class="row space-children">
						<uf-checkbox [(value)]="values.visible[name]" (valueChange)="updateValues(name)" label="Visible" />
						<uf-checkbox [(value)]="values.readOnly[name]" (valueChange)="updateValues(name)" label="Read Only" />
						<uf-checkbox [(value)]="values.required[name]" (valueChange)="updateValues(name)" label="Required" />
					</div>
					@let namedControl = form.controls[name];
					@if (namedControl) {
						<uf-error [control]="namedControl | asUfControl" />
					}
				}
			</div>
		}
	</div>
}
