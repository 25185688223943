<div class="header">
	<button (click)="close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>{{ data.title }}</h3>
</div>

<uf-panel class="container">
	<div [formGroup]="root" class="grid">
		<uf-autocomplete [label]="data.searchLabel" [placeholder]="data.searchPlaceHolder" [options]="contentsResult" [nameProperty]="mapDescription" [formControlName]="searchControlKey" [autofocus]="true" (searchChange)="search($event)" (valueChange)="selected($event)" class="col-1of1" />

		@if (ucCollection != null) {
			<uf-autocomplete [label]="data.searchSecondLabel" [placeholder]="data.searchSecondPlaceHolder" [options]="contentsSecondResult" [nameProperty]="data.searchSecondNameProperty" [formControlName]="searchSecondControlKey" [autofocus]="true" (searchChange)="search($event)" (valueChange)="selectedSecond($event)" class="col-1of1" />
		}
	</div>
</uf-panel>

<div class="row space-children pad">
	<button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
	<button (click)="confirm()" type="button" class="uf-button primary">Confirm</button>
</div>
