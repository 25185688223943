import { DataSourceEditorComponent } from './data-source-editor.component';
import { DataSourceExternalInputEditorComponent } from './data-source-external-input-editor.component';
import { DataSourceExternalInputsComponent } from './data-source-external-inputs.component';
import { DataSourceMappingEditorComponent } from './data-source-mapping-editor.component';
import { DataSourceMappingsReorderComponent } from './data-source-mappings-reorder.component';
import { DataSourceMappingsComponent } from './data-source-mappings.component';
import { FindByPropertyInputComponent } from './find-by-property-input.component';

const DATA_SOURCE_EDITOR_COMPONENTS = [
	DataSourceEditorComponent,
	DataSourceMappingsComponent, DataSourceMappingEditorComponent, DataSourceMappingsReorderComponent,
	DataSourceExternalInputsComponent, DataSourceExternalInputEditorComponent, FindByPropertyInputComponent,
];

export {
	DATA_SOURCE_EDITOR_COMPONENTS,
	DataSourceEditorComponent,
	DataSourceMappingsComponent, DataSourceMappingEditorComponent, DataSourceMappingsReorderComponent,
	DataSourceExternalInputsComponent, DataSourceExternalInputEditorComponent, FindByPropertyInputComponent,
};
