@if (!error) {
	<uf-panel class="container">
		<div class="header-pane uf-app-bar transparent">
			<uc-builder-header />
		</div>
		<div class="content-pane scrollable">
			<uf-tabs>
				<uf-tab label="Integration" path="" class="scrollable" />
				@if (showFeaturesTab) {
					<uf-tab label="Features" path="features" class="scrollable" />
				}
			</uf-tabs>
		</div>
	</uf-panel>
}

<uc-error-message [error]="error" />
