import { Option, StructureNodeType, TableDetailTemplate } from '@unifii/sdk';

import { ItemPickerGroup } from 'components';

export const STRUCTURE_NODES_PICKER_GROUPS: ItemPickerGroup<void>[] = [{
	title: 'Structure Nodes',
	items: [
		{ id: StructureNodeType.CollectionItem, label: 'Collection Item', icon: 'collectionItem' },
		{ id: StructureNodeType.Collection, label: 'Collection', icon: 'collections' },
		{ id: StructureNodeType.View, label: 'View', icon: 'views' },
		{ id: StructureNodeType.Page, label: 'Page', icon: 'pages' },
		{ id: StructureNodeType.Form, label: 'Form', icon: 'forms' },
		{ id: StructureNodeType.FormBucket, label: 'Table', icon: 'table' },
		{ id: StructureNodeType.Empty, label: 'Menu Heading', icon: 'emptyNode' },
		{ id: StructureNodeType.Link, label: 'External URL Link', icon: 'earth' },
		{ id: StructureNodeType.Dashboard, label: 'Dashboard', icon: 'dashboard' },
		{ id: StructureNodeType.PdfViewer, label: 'PDF Viewer', icon: 'pdf' },
		{ id: StructureNodeType.IFrame, label: 'External View', icon: 'externalView' },
		{ id: StructureNodeType.Custom, label: 'Custom', icon: 'custom' },
	],
}];

export const defaultBucketPageSize = 5;

export const TablesContainerTemplateOptions: Option[] = [
	{ name: 'Page View', identifier: TableDetailTemplate.PageView },
	{ name: 'Page View - Hide Empty Tables', identifier: TableDetailTemplate.PageViewHideEmptyTables },
	{ name: 'Menu View', identifier: TableDetailTemplate.MenuView },
];
