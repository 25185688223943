import { Component, HostListener, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';

import { MenuConfig } from './nav-models';

@Component({
	selector: 'uc-nav-context-menu',
	templateUrl: './nav-context-menu.html',
	styleUrls: ['./nav-context-menu.less'],
	standalone: false,
})
export class NavContextMenuComponent implements Modal<MenuConfig, void> {

	data = inject<MenuConfig>(ModalData);
	runtime = inject<ModalRuntime<MenuConfig, void>>(ModalRuntime);

	@HostListener('mouseleave') onLeave = () => this.runtime.close();
	@HostListener('click') onClick = () => this.runtime.close();

}
