import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ModalService, ToastService, UfControl, UfControlGroup } from '@unifii/library/common';
import { FileType, Language, hasLengthAtLeast } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Media, UcClient } from 'client';
import { ModalSearchData, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { EditData } from 'components/common/edit-data';
import { MediaSearchComponent } from 'components/content/modals/media-search.component';
import { ContextService } from 'services/context.service';
import { TitleService } from 'services/title.service';
import { translatedLanguages } from 'services/translate-loader';

import { DurationControlKeys, SystemControlKeys, SystemGeneralController } from './system-general-controller';
import { TenantSettingsComponent } from './tenant-settings.component';

@Component({
	selector: 'uc-system-general',
	providers: [SystemGeneralController],
	templateUrl: './system-general.html',
	styleUrls: ['./system-general.less'],
	standalone: false,
})
export class SystemGeneralComponent implements OnInit, OnDestroy, EditData {

	protected readonly systemControlKeys = SystemControlKeys;
	protected readonly durationControlKeys = DurationControlKeys;

	protected form: UfControlGroup;
	protected error?: Error;
	protected langOptions: Language[] = [];
	protected fileTypeOptions: FileType[] = [];
	protected fileTypes?: Promise<FileType[]>;

	private subscriptions = new Subscription();
	private availableLanguages?: Promise<Language[]>;

	private client = inject(UcClient);
	private context = inject(ContextService);
	private toast = inject(ToastService);
	private ucClient = inject(UcClient);
	private titleService = inject(TitleService);
	private modalService = inject(ModalService);
	private parent = inject(TenantSettingsComponent);
	private builderHeaderService = inject(BuilderHeaderService);
	private formController = inject(SystemGeneralController);

	set edited(v: boolean) {
		this.builderHeaderService.config.edited = v;
	}

	get edited() {
		return this.builderHeaderService.config.edited ?? false;
	}

	protected get bypassAssetFileTypesWhitelistControl() {
		return this.form.get(SystemControlKeys.BypassAssetFileTypesWhitelist) as UfControl;
	}

	protected get bypassAttachmentFileTypesWhitelistControl() {
		return this.form.get(SystemControlKeys.BypassAttachmentFileTypesWhitelist) as UfControl;
	}

	protected get logoControl() {
		return this.form.get(SystemControlKeys.Logo) as UfControl;
	}

	protected get rememberMeExpiryDaysControl() {
		return this.form.get(SystemControlKeys.RememberMeExpiryDays) as UfControl;
	}

	protected get sessionDurationControl() {
		return this.form.get(SystemControlKeys.SessionDuration) as UfControl;
	}

	protected get tokenDurationControl() {
		return this.form.get(SystemControlKeys.TokenDuration) as UfControl;
	}

	private get allowedAssetFileTypesControl() {
		return this.form.get(SystemControlKeys.AllowedAssetFileTypes) as UfControl;
	}

	private get allowedAttachmentFileTypesControl() {
		return this.form.get(SystemControlKeys.AllowedAttachmentFileTypes) as UfControl;
	}

	ngOnInit() {
		try {
			if (!this.context.tenantSettings) {
				throw new Error('No tenant settings found');
			}

			this.titleService.updateTitle('General');

			this.formController.reset();
			this.subscriptions.unsubscribe();
			this.subscriptions = new Subscription();

			this.form = this.formController.buildRoot(this.formController.toFormModel(this.context.tenantSettings));
			this.subscriptions.add(this.form.valueChanges.subscribe(() => { this.edited = true; }));

			this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(() => void this.save() ));
			this.buildHeaderConfig();
		} catch (error) {
			this.error = useDefaultErrorMessage(error);
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.parent.edited = false;
	}

	protected async findLogo() {
		const result = await (this.modalService.openMedium<ModalSearchData, Media[]>(MediaSearchComponent, {
			title: 'Find Logo',
			type: 'Image',
		})) ?? [];

		if (!hasLengthAtLeast(result, 1)) {
			return;
		}

		this.logoControl.setValue({
			id: result[0].id,
			url: result[0].url,
		});
	}

	protected deleteLogo() {
		this.logoControl.reset();
	}

	protected async searchLang(query: string) {
		if (!this.availableLanguages) {
			this.availableLanguages = this.ucClient.getLanguages().then((all) =>
				all.filter((language) => translatedLanguages.includes(language.code)),
			);
		}

		if (!query.trim().length) {
			this.langOptions = await this.availableLanguages;

			return;
		}

		const lowerCaseQuery = query.toLowerCase();

		this.langOptions = (await this.availableLanguages).filter((l) =>
			l.code.toLowerCase().includes(lowerCaseQuery) || l.name.toLowerCase().includes(lowerCaseQuery),
		);
	}

	protected async searchAssetFileTypes(query: string | null) {
		if (!this.fileTypes) {
			this.fileTypes = this.client.getFileTypes().then((all) =>
				all.map((fileType) => Object.assign(fileType, { label: fileType.id + (fileType.extensions.length > 1 ? ' (' + fileType.extensions.join(', ') + ')' : '') })),
			);
		}

		let options;

		if (query?.trim().length) {
			options = (await this.fileTypes).filter((fileType) => fileType.id.includes(query));
		} else {
			options = await this.fileTypes;
		}
		const allowedAssetFileTypes = this.allowedAssetFileTypesControl.value as FileType[] | undefined;

		this.fileTypeOptions = allowedAssetFileTypes?.length ? options.filter((fileType) => !allowedAssetFileTypes.map((type) => type.id).includes(fileType.id)) : options;
	}

	protected async searchAttachmentFileTypes(query: string | null) {
		if (!this.fileTypes) {
			this.fileTypes = this.client.getFileTypes().then((all) =>
				all.map((fileType) => Object.assign(fileType, { label: fileType.id + (fileType.extensions.length > 1 ? ' (' + fileType.extensions.join(', ') + ')' : '') })),
			);
		}

		let options;

		if (query?.trim().length) {
			options = (await this.fileTypes).filter((fileType) => fileType.id.includes(query));
		} else {
			options = await this.fileTypes;
		}

		const allowedAttachmentFileTypes = this.allowedAttachmentFileTypesControl.value as FileType[] | undefined;

		this.fileTypeOptions = allowedAttachmentFileTypes?.length ? options.filter((fileType) => !allowedAttachmentFileTypes.map((type) => type.id).includes(fileType.id)) : options;
	}

	private async save() {
		this.form.setSubmitted();

		if (this.form.invalid) {
			return;
		}

		this.error = undefined;

		const data = this.formController.toDataModel(this.form.getRawValue());

		try {
			this.context.tenantSettings = await this.client.updateSettings(data);
			this.edited = false;
			this.toast.success('Changes saved!');

			this.buildHeaderConfig();
		} catch (error) {
			this.error = useDefaultErrorMessage(error);
			this.toast.error(this.error.message);
		}
	}

	private buildHeaderConfig() {
		if (!this.context.tenantSettings) {
			return;
		}

		this.builderHeaderService.updateConfig({
			lastModifiedAt: this.context.tenantSettings.lastModifiedAt,
			lastModifiedBy: this.context.tenantSettings.lastModifiedBy,
			hideSaveButton: false,
			cancelRoute: ['..'],
		});
	}

}
