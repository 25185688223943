import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { ExpanderComponent, ExpandersService, Scope } from '@unifii/library/common';
import { ComponentRegistry } from '@unifii/library/smart-forms';
import { FieldType } from '@unifii/sdk';

import { MarkdownComponentRegistry } from '../markdown-component-registry';

import { ContentValue } from './content-value';

@Component({
	selector: 'uc-group-input',
	templateUrl: './group-input.html',
	providers: [{ provide: ComponentRegistry, useClass: MarkdownComponentRegistry }],
	styleUrls: ['./group-input.less'],
	standalone: false,
})
export class GroupInputComponent extends ContentValue implements AfterViewInit {

	@ViewChild('container', { static: true }) container: ElementRef;

	override scope: Scope;
	icon: string;

	protected readonly fieldTypes = FieldType;

	private _expander: ExpanderComponent;
	private cdr = inject(ChangeDetectorRef);
	private expanderService = inject(ExpandersService, { optional: true });

	override ngAfterViewInit() {
		super.ngAfterViewInit();
		if (this.editorField) {
			this.builderService.fieldReady.next({ subject: this.editorField, atomic: true });
		}
	}

	init() {
		this.icon = this.builderService.getFieldRef(this.configuredField).icon;
		this.cdr.detectChanges();
	}

	@ViewChild(ExpanderComponent, { static: true }) set expander(v: ExpanderComponent) {

		if (!v) {
			return;
		}

		this._expander = v;
		this._expander.toggle = this.toggle;
	}

	get expander(): ExpanderComponent {
		return this._expander;
	}

	private toggle = () => {

		if (this.expander.isExpanded) {
			this.expander.collapse();

			return;
		}

		this.expander.expand();
		this.expanderService?.expandAll(this.container.nativeElement);
	};

}
