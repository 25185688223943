import { Component, Input, OnInit, inject } from '@angular/core';
import { UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';

import { DialogsService } from 'services/dialogs.service';

import { FieldControlKeys, VariationControlKeys } from '../form-editor-control-keys';
import { FormEditorFormCtrl } from '../form-editor-form-ctrl';
import { FormEditorField, FormFieldMetadata } from '../form-editor-model';

@Component({
	selector: 'uc-form-field-variations',
	templateUrl: './form-field-variations.html',
	standalone: false,
})
export class FormFieldVariationsComponent implements OnInit {

	@Input({ required: true }) control: UfControlGroup;
	@Input({ required: true }) meta: FormFieldMetadata;

	expandeds: boolean[];

	protected readonly fieldTypes = FieldType;
	protected readonly variationKeys = VariationControlKeys;

	private fb = inject(FormEditorFormCtrl);
	private dialogs = inject(DialogsService);

	get field(): FormEditorField {
		return this.control.getRawValue() as FormEditorField;
	}

	get variations(): UfControlArray {
		return this.control.get(FieldControlKeys.Variations) as UfControlArray;
	}

	ngOnInit() {
		this.expandeds = Array(this.variations.length).fill(false);
	}

	addVariation() {
		this.expandeds.push(true);
		const field = this.field;

		this.variations.push(this.fb.buildVariationControl(this.meta, {
			name: '',
			condition: undefined,
			label: field.label,
			placeholder: field.placeholder,
			help: field.help,
			options: field.options ? JSON.parse(JSON.stringify(field.options)) : undefined,
			validators: field.validators ? JSON.parse(JSON.stringify(field.validators)) : undefined,
		}));
	}

	async removeVariation(index: number) {
		if (!await this.dialogs.confirmDelete()) {
			return;
		}

		this.expandeds.splice(index, 1);
		this.variations.removeAt(index);
	}

	showVariationError(variation: UfControlGroup): boolean {
		if (variation.valid) {
			return false;
		}

		const name = variation.get(VariationControlKeys.Name) as UfControl;
		const condition = variation.get(VariationControlKeys.Condition) as UfControl;

		if (name.valid && condition.valid) {
			return true;
		}

		return false;
	}

}
