import { InjectionToken } from '@angular/core';
import { DataPropertyDescriptor } from '@unifii/library/common';
import { DataSourceInputType, DataSourceType, FieldType, Option, Schema } from '@unifii/sdk';

import { ConsoleDataSource, DataSourceInputValueSource, Integration, IntegrationFeature, UcClaimConfig, UcDefinition, UcDefinitionDataSource } from 'client';

export interface DataSourceEditorCache {
	getSchema(bucket?: string | null): Promise<Schema | null>;
	getCollectionDefinition(identifier: string): Promise<UcDefinition | null>;
	getUserClaimConfig(id: string): Promise<UcClaimConfig | null>;
	getExternalDataSource(id: string): Promise<ConsoleDataSource | null>;
	getIntegration(id: string): Promise<Integration | null>;
	reset(): void;
}
export const DataSourceEditorCache = new InjectionToken<DataSourceEditorCache>('DataSourceEditorCache');

export interface ExternalInfo {
	dataSource: ConsoleDataSource;
	feature: IntegrationFeature;
}

export interface DataSourceMapping {
	uuid: string;
	isFromExpression?: boolean; // for _display an expression can be used instead of a source field
	from?: DataPropertyDescriptor; // value
	fromExpression?: string;
	type: FieldType;
	to: string; // attribute
	label: string;
	isVisible?: boolean;
	hideEmpty?: boolean;
	itemTemplate?: string; // expression to display each item of the mapped Repeat field
}

export interface DataSourceExternalInfo {
	parameter: string;
	source: DataSourceInputValueSource;
	type: DataSourceInputType;
	required: boolean;
	placeholder?: string;
}

export interface DataSourceExternalInput {
	info: DataSourceExternalInfo;
	value?: string;
}

export interface DataSourceFormModel extends Omit<UcDefinitionDataSource, 'type' | 'id' | 'outputs' | 'outputFields' | 'outputDescriptors' | 'inputs' | 'inputArgs' | 'sortDirection'> {
	type: DataSourceType | null;
	resource: Option | null;
	mappings: DataSourceMapping[];
	externalInputs: DataSourceExternalInput[];
}

export enum DataSourceControlKeys {
	Type = 'type',
	Resource = 'resource', // id for Collection, Bucket, UserClaims, External
	Mappings = 'mappings',
	ExternalInputs = 'externalInputs',
	Sort = 'sort',
	FindBy = 'findBy',
	VisibleFilters = 'visibleFilters',
	Filter = 'filter',
	AdvancedFilter = 'advancedFilter',
}

export enum DataSourceMappingControlKeys {
	Uuid = 'uuid',
	IsFromExpression = 'isFromExpression',
	From = 'from',
	FromExpression = 'fromExpression',
	Type = 'type',
	To = 'to',
	Label = 'label',
	IsVisible = 'isVisible',
	HideEmpty = 'hideEmpty',
	ItemTemplate = 'itemTemplate',
}

export enum DataSourceExternalInputControlKey {
	Info = 'info',
	Value = 'value',
}

export const descriptorBasedDSTypes = [
	DataSourceType.Collection,
	DataSourceType.Bucket,
	DataSourceType.Users,
	DataSourceType.Company,
	DataSourceType.UserClaims,
];

export const idBasedDSTypes = [
	DataSourceType.Collection,
	DataSourceType.Bucket,
	DataSourceType.UserClaims,
	DataSourceType.External,
];
