@if (form && !error) {
	<div class="header-pane uf-app-bar transparent">
		<uc-builder-header />
	</div>
}

@if (!error) {
	<uf-panel class="content-pane">
		<div class="uf-container">
			@if (!form) {
				<uf-skeleton />
			}
			@if (form) {
				<ng-container [formGroup]="form">
					<div class="uf-box gap-top">
						<div class="uf-app-bar flat accent">
							<div class="title">Details</div>
						</div>
						<div class="uf-grid pad">
							<uf-text [formControlName]="controlKeys.ConsoleName" [label]="consoleNameLabel" class="col-12">
								<span class="suffix">*</span>
							</uf-text>
						</div>
					</div>
					<div class="uf-box gap-top">
						<div class="uf-app-bar flat accent">
							<div class="title">Source</div>
						</div>
						<div class="uf-grid pad">
							<uf-select [options]="sourceTypes" [formControlName]="controlKeys.SourceType" label="Source" placeholder="Select Source" valueProperty="identifier" class="col-12">
								<span class="suffix">*</span>
							</uf-select>
							@if (form.get(controlKeys.Bucket)?.enabled) {
								<uf-autocomplete [formControlName]="controlKeys.Bucket" [options]="buckets" (searchChange)="searchBuckets($event)" (valueChange)="bucketChange($event)" label="Form Data Repository" placeholder="Search form data repository" nameProperty="name" class="col-12">
									<span class="suffix">*</span>
								</uf-autocomplete>
							}
						</div>
					</div>
					<div class="uf-box gap-top">
						<div class="uf-app-bar flat accent">
							<div class="title">Target</div>
							@if (featureLink) {
								<a [routerLink]="featureLink" target="_blank" title="Open" class="uf-action tertiary">
									<uf-icon name="open" />
								</a>
							}
						</div>
						<div class="uf-grid pad">
							<uf-autocomplete [options]="integrations" [formControlName]="controlKeys.Integration" (searchChange)="searchIntegration($event)" (valueChange)="integrationChange($event)" placeholder="Select an integration" nameProperty="name" label="Integration" class="col-12">
								<span class="suffix">*</span>
							</uf-autocomplete>
							@if (form.get(controlKeys.Feature)?.enabled) {
								<uf-autocomplete [options]="filteredFeatures" [formControlName]="controlKeys.Feature" (searchChange)="searchFeature($event)" (valueChange)="featureChange($event)" placeholder="Select a feature" nameProperty="name" label="Feature" class="col-12">
									<span class="suffix">*</span>
								</uf-autocomplete>
							}
						</div>
					</div>
					@if (targetFields) {
						<div class="uf-box gap-top">
							<div class="uf-app-bar flat accent">
								<div class="title">Data Mapping</div>
							</div>
							<div class="uf-grid pad">
								<uf-radio [formControlName]="controlKeys.ForwardAllData" [options]="dataMappingOptions" columns="1" valueProperty="value" class="col-12" />
							</div>
						</div>
					}
				</ng-container>
			}
		</div>
		@if (form) {
			<div class="gap-xl-bottom">
				@if (inputMapControl && targetFields) {
					<uc-field-mapping-table [control]="inputMapControl" [targetFields]="targetFields" [sourceFields]="sourceFields" />
				}
			</div>
		}
	</uf-panel>
}

<uc-error-message [error]="error" />
