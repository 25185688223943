<div class="header">
	<button (click)="close()" type="button" class="uf-action tertiary" ttitle="Close">
		<uf-icon name="close" />
	</button>
	<h3>{{ data.title }}</h3>
</div>

<uf-panel class="container">
	<div class="pad-sides">
		@for (entry of data.glossary; track entry) {
			<div class="gap--bottom">
				<strong (click)="close(entry)" (mousedown)="onMouseDown()" class="select">{{ entry.key }}</strong>
				<span>{{ entry.value }}</span>
			</div>
		}
	</div>
</uf-panel>
