import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { AstNode, Claim, NodeType, SchemaField, hasLengthAtLeast, isNotNull } from '@unifii/sdk';

import { UcRoles, UcUserClaims } from 'client';

import { getUserQueryBuilderEmptyAstNode } from './users-query-builder-functions';

export interface QueryBuilderData {
	fields: SchemaField[];
	filter: AstNode;
}

export interface QueryBuilderResult {
	edited: boolean;
	filter: AstNode;
}

@Component({
	selector: 'uc-users-query-builder',
	templateUrl: './users-query-builder.html',
	styleUrls: ['./users-query-builder.less'],
	standalone: false,
})
export class UsersQueryBuilderComponent implements Modal<QueryBuilderData, QueryBuilderResult>, OnInit {

	@HostBinding('class.uc-form-card') class = true;

	runtime = inject<ModalRuntime<QueryBuilderData, QueryBuilderResult>>(ModalRuntime);
	data = inject<QueryBuilderData>(ModalData);

	protected ready = false;
	protected roleResults: string[] = [];
	protected roles: string[] = [];
	protected claims: Claim[] = [];
	protected claimOptions: string[] = [];
	protected claimsControl = new UfControlArray([]);
	protected rolesControl = new UfControl();

	private ucRoles = inject(UcRoles);
	private ucUserClaims = inject(UcUserClaims);
	private filter: AstNode;
	private form = new UfControlGroup({});

	ngOnInit() {

		this.filter = this.data.filter;
		this.form.addControl('roles', this.rolesControl);
		this.form.addControl('claims', this.claimsControl);

		const dataFilterArgs = this.data.filter.args;

		// Map filter to UI model
		if (dataFilterArgs && hasLengthAtLeast(dataFilterArgs, 1)) {

			if (dataFilterArgs[0].op === 'contains') {
				if (dataFilterArgs[0].args && hasLengthAtLeast(dataFilterArgs[0].args, 2)) {
					this.roles = dataFilterArgs[0].args[1].value;
				} else {
					this.roles = [];
				}
			}

			for (const arg of dataFilterArgs) {
				if (arg.op === 'eq' && arg.args && hasLengthAtLeast(arg.args, 2)) {
					this.claims.push({
						type: arg.args[0].value,
						value: arg.args[1].value,
					});
				}
			}
		}

		// Create controls
		this.claims.forEach(() => this.claimsControl.push(this.getClaimControl()));

		// Apply filter rows associated configuration and controls, after mark the builder as ready to work
		this.ready = true;
	}

	close() {
		this.runtime.close({ edited: false, filter: this.filter });
	}

	protected async findRoles(query: string | null) {
		this.roleResults = (await this.ucRoles.get(query ?? undefined)).map((r) => r.name);
	}

	protected async searchClaims(q: string | null = null): Promise<string[]> {
		return (await this.ucUserClaims.list({ params: { q } }))
			.map((claim) => claim.type)
			.filter(isNotNull);
	}

	protected addClaim() {
		// Control
		this.claimsControl.push(this.getClaimControl());
		// Data
		// TODO UNIFII-4818
		this.claims.push({ type: '', value: '' });
	}

	protected removeClaim(i: number) {
		this.claims.splice(i, 1);
		this.claimsControl.removeAt(i);
	}

	protected save() {

		this.form.setSubmitted();

		if (this.form.valid) {
			// Empty
			const filter = getUserQueryBuilderEmptyAstNode();

			// Add roles
			if (filter.args && hasLengthAtLeast(filter.args, 1) && filter.args[0].args && hasLengthAtLeast(filter.args[0].args, 2)) {
				filter.args[0].args[1].value = this.roles;
			}

			// Add claims
			for (const c of this.claims) {
				(filter.args as AstNode[]).push({
					type: NodeType.Operator,
					op: 'eq',
					args: [{
						type: NodeType.Identifier,
						value: c.type,
					}, {
						type: NodeType.Value,
						value: c.value,
					}],
				});
			}

			// Return filter
			this.runtime.close({ edited: this.form.dirty, filter });
		}
	}

	private getClaimControl(): UfControlGroup {
		const claim = new UfControlGroup({});

		claim.addControl('type', new UfControl(ValidatorFunctions.required('A type is required')));
		claim.addControl('value', new UfControl(ValidatorFunctions.required('A value is required')));

		return claim;
	}

}
