<div [formGroup]="control" class="grid">
	<uf-text [formControlName]="controlKeys.Label" [(value)]="definition.label" label="Title" class="col-1of1" />

	<uf-text [formControlName]="controlKeys.ConsoleName" [label]="consoleNameLabel" [(value)]="definition.consoleName" class="col-1of1" />

	<uf-text [formControlName]="controlKeys.Identifier" [maxLength]="maxLength" [(value)]="definition.identifier" label="Identifier" class="col-1of1" />

	<div restrict="FormDesigner" class="row space-children col-1of1">
		<button (click)="copyFields()" type="button" class="uf-button right">Copy Fields</button>
		<button (click)="pasteFields()" type="button" class="uf-button">Paste Fields</button>
	</div>
</div>
