import { Injectable, inject } from '@angular/core';
import { DataDescriptor, DataPropertyDescriptor, DataSourceMappingDisplayAllowedDataTypes, FeatureFlagService, UfControlGroup } from '@unifii/library/common';

import { DefinitionInfo, UcClaimConfig } from 'client';

import { DataSourceFormModel, ExternalInfo } from './data-source-model';

@Injectable()
export class DataSourceEditorStatus {

	// Info
	companiesFeatureEnabled = false;

	// Status
	root: UfControlGroup;
	mappableProperties: DataPropertyDescriptor[] = [];
	sortableProperties: DataPropertyDescriptor[] = [];
	findByProperties: DataPropertyDescriptor[] = [];
	staticFilterProperties: DataPropertyDescriptor[] = [];
	inputFilterProperties: DataPropertyDescriptor[] = [];
	externalInfo?: ExternalInfo;

	// Cache
	collections: Promise<DefinitionInfo[]> | undefined;
	userClaims: Promise<UcClaimConfig[]> | undefined;

	private featureFlagService = inject(FeatureFlagService);
	private _descriptor: DataDescriptor | undefined;

	get dataSource(): DataSourceFormModel {
		return this.root.getRawValue() as DataSourceFormModel;
	}

	get descriptor(): DataDescriptor | undefined {
		return this._descriptor;
	}

	set descriptor(v: DataDescriptor | undefined) {
		this._descriptor = v;

		this.mappableProperties = !this.descriptor ? [] : this.descriptor.propertyDescriptors.filter((pd) => pd.asDisplay);
		this.sortableProperties = !this.descriptor ? [] : this.descriptor.propertyDescriptors.filter((pd) => pd.asSort);
		this.staticFilterProperties = !this.descriptor ? [] : this.descriptor.propertyDescriptors.filter((pd) => pd.asStaticFilter);
		this.inputFilterProperties = !this.descriptor ? [] : this.descriptor.propertyDescriptors.filter((pd) => pd.asInputFilter);
		// TODO review supported descriptors
		this.findByProperties = !this.descriptor ? [] : this.descriptor.propertyDescriptors.filter((pd) => DataSourceMappingDisplayAllowedDataTypes.includes(pd.type));
	}

	async init() {
		this.companiesFeatureEnabled = await this.featureFlagService.isEnabled('companies');
	}

}
