import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'fileType',
	standalone: false,
})
export class FileTypePipe implements PipeTransform {

	transform(value: string): string {
		if (value) {
			const dotsParts = value.split('.');

			return dotsParts[dotsParts.length - 1] ?? '';
		}

		return '';
	}

}
