<uf-expander class="uf-box flat">
	<div expanderHeader class="uf-app-bar accent flat">
		<div class="title primary">{{ title }}</div>
		@if (items.length) {
			<uc-expander-controls [container]="itemsContainer" />
		}
	</div>
	<div #itemsContainer expanderBody class="pad-sm">
		@if (items.length) {
			@for (item of items; track item; let i = $index) {
				<uf-expander [dragDisabled]="true" dragItem class="uf-box flat draggable gap-sm-bottom">
					<div expanderHeader dragHandle class="uf-app-bar flat">
						<div class="title primary">{{ item.title }}</div>
					</div>
					<div expanderBody class="uf-grid pad">
						<uf-data-display-list [items]="item.items" class="col-12" />
					</div>
				</uf-expander>
			}
		} @else {
			<span>No items to display</span>
		}
	</div>
</uf-expander>
