import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataPropertyDescriptor, UfControl, UfControlGroup } from '@unifii/library/common';
import { NodeType, OperatorComparison, Option } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { FilterEditorNode, FilterNodeControlKeys } from './filter-editor-form-ctrl';

@Component({
	selector: 'uc-filter-entry',
	templateUrl: './filter-entry.html',
	standalone: false,
})
export class FilterEntryComponent implements OnInit, OnDestroy {

	@Input({ required: true }) control: UfControlGroup;
	@Input({ required: true }) dataProperties: DataPropertyDescriptor[];
	@Input() cssClass: string | string[] = 'small';

	protected readonly controlKeys = FilterNodeControlKeys;
	protected readonly nodeTypes = NodeType;
	protected fieldOptions: DataPropertyDescriptor[] = [];
	protected operatorOptions: Option[] = [];
	protected valueTypeOptions: Option[] = [
		{ identifier: NodeType.Expression as any as string, name: 'Expression' },
		{ identifier: NodeType.Value as any as string, name: 'Value' },
	];

	private subscriptions = new Subscription();

	protected get filterEntry(): FilterEditorNode {
		return this.control.getRawValue() as FilterEditorNode;
	}

	protected get valueControl(): UfControl {
		return this.control.get(FilterNodeControlKeys.Value) as UfControl;
	}

	private get fieldControl(): UfControl {
		return this.control.get(FilterNodeControlKeys.Field) as UfControl;
	}

	private get operatorControl(): UfControl {
		return this.control.get(FilterNodeControlKeys.Operator) as UfControl;
	}

	private get valueTypeControl(): UfControl {
		return this.control.get(FilterNodeControlKeys.ValueType) as UfControl;
	}

	ngOnInit() {
		this.operatorOptions = (this.filterEntry.field?.operators ?? []).map((o) => this.getOperatorOption(o));

		// Left change
		this.subscriptions.add(this.fieldControl.valueChanges.subscribe((v) => {
			const property = v as DataPropertyDescriptor | null;

			this.operatorOptions = [...(property?.operators ?? [])].map((o) => this.getOperatorOption(o));
			const nextOperator = this.operatorOptions.length === 1 ? this.operatorOptions[0]?.identifier : null;

			this.operatorControl.setValue(nextOperator);
		}));

		// Operator change
		this.subscriptions.add(this.operatorControl.valueChanges.subscribe(() => {
			this.valueTypeControl.setValue(null);
		}));

		// ValueType change
		this.subscriptions.add(this.valueTypeControl.valueChanges.subscribe(() => {
			this.valueControl.setValue(null);
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected search(query: string) {

		if (!query.trim().length) {
			this.fieldOptions = [...this.dataProperties];

			return;
		}

		const lowerCaseQ = query.toLowerCase().trim();

		this.fieldOptions = this.dataProperties.filter((entry) => {
			const label = entry.display ?? entry.label;

			return label.toLowerCase().includes(lowerCaseQ);
		});
	}

	private getOperatorOption(operator: OperatorComparison): Option {
		let name;

		switch (operator) {
			case 'eq': name = 'Equal'; break;
			case 'ne': name = 'Not equal'; break;
			case 'lt': name = 'Lower than'; break;
			case 'le': name = 'Lower equal'; break;
			case 'gt': name = 'Greater than'; break;
			case 'ge': name = 'Greater equal'; break;
			case 'in': name = 'In'; break;
			case 'contains': name = 'Contains'; break;
			case 'descs': name = 'Descendants'; break;
			default: name = ''; break;
		}

		return { identifier: operator, name };
	}

}
