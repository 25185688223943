@if (form) {
	<div [formGroup]="form" class="uf-box flat">
		<uf-expander [dragDisabled]="true" [isExpanded]="true" dragItem>
			<div expanderHeader class="uf-app-bar flat">
				<div class="title">
					{{ typeDescription[type] }}
				</div>
				<button title="Delete" type="button" class="uf-action">
					<uf-icon (click)="deleteClick.emit({ position: identifier, parent })" name="delete" />
				</button>
			</div>
			<div expanderBody class="uf-grid pad">
				@switch (type) {
					<!-- Role Assigned -->
					@case (types.RoleAssignment) {
						<uf-autocomplete [formControlName]="controlKeys.Identifier" [allowCustom]="allowCustomRoles" [options]="filteredRoles" (searchChange)="findRoles($event)" placeholder="Select a Role" label="Role" class="col-12" />
					}
					<!-- GroupMembership -->
					@case (types.GroupMembership) {
						@if (groupControl) {
							<uf-autocomplete [formControlName]="controlKeys.Group" [options]="filteredSourceGroups" [allowCustom]="true" (searchChange)="findSourceGroups($event)" (valueChange)="changeGroup(groupControl, $event)" placeholder="Select a source" label="Group" nameProperty="name" class="col-12" />
						}
					}
					<!-- ClaimValue -->
					@case (types.ClaimValue) {
						<uf-autocomplete [formControlName]="controlKeys.Identifier" [options]="filteredSourceClaims" [allowCustom]="true" (searchChange)="findSourceClaims($event)" label="Claim" placeholder="Select a source" class="col-6" />
						<uf-text [formControlName]="controlKeys.Value" label="Claim Value" class="col-6" />
					}
					<!-- ClaimFrom -->
					@case (types.ClaimFrom) {
						<uf-autocomplete [formControlName]="controlKeys.Identifier" [options]="filteredSourceClaims" [allowCustom]="true" (searchChange)="findSourceClaims($event)" label="Claim" placeholder="Select a source" class="col-12" />
					}
					<!-- And / Or -->
					@default {
						<div class="col-12">
							@for (childrenMapping of childrenControls.controls | asUfControlsGroups; track childrenMapping; let i = $index) {
								<uc-auth-provider-mapping-unit [parent]="form" [form]="childrenMapping" [sourceClaims]="sourceClaims" [identifier]="i" [authProviderId]="authProviderId" [config]="config" (deleteClick)="deleteClick.emit($event)" />
							}
							<div class="uf-form-actions">
								<button [options]="conditionOptions" (optionSelected)="addCondition($event)" type="button" class="uf-button x-small" listBox nameProperty="name">
									Add
									<uf-icon name="more" class="gap-none" />
								</button>
							</div>
						</div>
					}
				}
			</div>
		</uf-expander>
	</div>
}
