"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MfaStatus = exports.GrantType = exports.MfaChallengeType = void 0;
var MfaChallengeType;
(function (MfaChallengeType) {
  MfaChallengeType["Virtual"] = "virtual";
  MfaChallengeType["RecoveryCode"] = "recovery_code";
  MfaChallengeType["Sms"] = "sms";
  MfaChallengeType["Device"] = "device";
})(MfaChallengeType || (exports.MfaChallengeType = MfaChallengeType = {}));
var GrantType;
(function (GrantType) {
  GrantType["RefreshToken"] = "refresh_token";
  GrantType["AuthorizationCode"] = "authorization_code";
  GrantType["InvitationToken"] = "invitation";
  GrantType["MfaVirtual"] = "mfa_virtual";
  GrantType["MfaDevice"] = "mfa_device";
  GrantType["MfaDeviceSetup"] = "mfa_device_setup";
  GrantType["MfaRecoveryCode"] = "mfa_recovery_code";
  GrantType["Password"] = "password";
  GrantType["ResetPassword"] = "reset_password";
  GrantType["MfaSms"] = "mfa_sms";
})(GrantType || (exports.GrantType = GrantType = {}));
var MfaStatus;
(function (MfaStatus) {
  MfaStatus["MfaSetupRequired"] = "MfaSetupRequired";
  MfaStatus["MfaVerifyRequired"] = "MfaVerifyRequired";
  MfaStatus["MfaSetupInAccount"] = "MfaSetupInAccount";
})(MfaStatus || (exports.MfaStatus = MfaStatus = {}));
