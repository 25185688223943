import { AfterViewInit, Component, HostBinding, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { ImageCropperComponent } from '../../components/image-cropper/image-cropper.component';
import { Dimension, ImageCrop } from '../../components/image-cropper/imageCropper';

@Component({
	selector: 'uc-image-cropper-test',
	templateUrl: './image-cropper-test.html',
	standalone: false,
})
export class ImageCropperTestComponent implements AfterViewInit, OnDestroy {

	@HostBinding('style.padding') stylePadding = '20px';
	@HostBinding('style.width') styleWidth = '100%';

	@ViewChild(ImageCropperComponent, { static: true }) c: ImageCropperComponent;

	imageURL: string;
	size: Dimension | null;
	_scale = '0.75';
	cropRatio = {
		x: 0.2, y: 0.3,
	};

	// JSON data
	imageWidth = 1440;
	imageHeight = 900;
	minWidth: number;
	minHeight: number;
	enabled: boolean;
	value: ImageCrop | null;
	loaded: boolean;

	private subscriptions = new Subscription();

	constructor() {
		this.size = null;
		this.imageURL = 'https://static.dev.unifii.net/tilt/724a85a8-170b-4228-8a1c-10b3fa1b0dfc.jpg';
		this.minWidth = this.imageWidth * this.cropRatio.x;
		this.minHeight = this.imageHeight * this.cropRatio.y;
		this.enabled = this.imageWidth >= this.minWidth && this.imageHeight >= this.minHeight;

		this.scale = this._scale;
	}

	ngAfterViewInit() {
		if (this.c) {
			this.subscriptions.add(this.c.ready.subscribe(() => {
				this.loaded = true;
			}));
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	set scale(val: string) {
		this._scale = val;
		this.size = {
			width: this.imageWidth * parseFloat(this._scale),
			height: this.imageHeight * parseFloat(this._scale),
		};
	}

	get scale() {
		return this._scale;
	}

	removeCrop(event: MouseEvent) {
		this.value = null;
	}

}
