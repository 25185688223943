import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ToastService, UfControlGroup } from '@unifii/library/common';
import { UfError, ensureUfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ActivityType, SchemaInfo, UcFormBucketClient, UcWorkflow, WorkflowActivityTimer } from 'client';
import { EditData, SaveOption, SaveOptionType, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { ConsoleNameLabel } from 'constant';
import { appendSuffixCopy, reloadCurrentRoute } from 'pages/utils';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { TitleService } from 'services/title.service';

import { WorkflowActivityTableManager } from './workflow-activity-table-manager';
import { ControlKeys, WorkflowTimerFormController, WorkflowTimerModel } from './workflow-timers-form.controller';
import { buildHeaderConfig } from './workflow-utils';

@Component({
	selector: 'uc-workflow-timers-form',
	templateUrl: 'workflow-timers-form.html',
	providers: [WorkflowTimerFormController],
	standalone: false,
})
export class WorkflowTimersFormComponent implements EditData, OnInit, OnDestroy {

	protected readonly controlKeys = ControlKeys;
	protected readonly consoleNameLabel = ConsoleNameLabel;

	protected error?: UfError;
	protected form: UfControlGroup;
	protected bucketsResult: SchemaInfo[] = [];
	protected filteredWorkflowRelatedActivities: WorkflowActivityTimer[] = [];
	protected showReplaceTimersContainer = false;

	private subscriptions = new Subscription();
	private hasSaveAndNextButton: boolean;
	private workflowRelatedActivities: WorkflowActivityTimer[] = [];

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private ucWorkflow = inject(UcWorkflow);
	private toastService = inject(ToastService);
	private breadcrumbService = inject(BreadcrumbService);
	private ucFormBucketClient = inject(UcFormBucketClient);
	private builderHeaderService = inject(BuilderHeaderService);
	private formController = inject(WorkflowTimerFormController);
	private titleService = inject(TitleService);
	private tableManager = inject<WorkflowActivityTableManager>(TableContainerManager);

	get edited(): boolean {
		return !!this.builderHeaderService.config.edited;
	}

	set edited(v: boolean) {
		this.builderHeaderService.config.edited = v;
	}

	async ngOnInit() {
		this.builderHeaderService.init();

		const { id, duplicate } = this.route.snapshot.params;

		this.hasSaveAndNextButton = id !== 'new' && !duplicate;

		try {
			this.filteredWorkflowRelatedActivities = this.workflowRelatedActivities = await this.ucWorkflow.getActivities({
				params: { type: ActivityType.Timer },
			});

			const timer = await this.getTimer(id);
			let formModel: WorkflowTimerModel | undefined;

			if (timer.id) {
				formModel = this.formController.toModel(timer);

				if (duplicate) {
					formModel.id = null as any as string;
					formModel.consoleName = appendSuffixCopy({ label: formModel.consoleName });
				}
			}

			this.titleService.updateTitle(timer.consoleName);

			this.form = this.formController.buildRoot(formModel);

			this.showReplaceTimersContainer = !!(formModel?.replacePreviousJob || formModel?.relatedWorkflowActivities?.length);

			this.subscriptions.add(this.form.valueChanges.subscribe(() => { this.edited = true; }));

			// Set breadcrumbs
			this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => {
				void this.save(saveOption);
			}));

			this.buildHeaderConfig(timer);
		} catch (e) {
			this.error = useDefaultErrorMessage(e);
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected async searchBuckets(q: string) {
		this.bucketsResult = await this.ucFormBucketClient.list({ params: { q } });
	}

	protected filterRelatedActivities(query?: string) {
		const lowerCaseQuery = query?.toLocaleLowerCase();
		const id = this.form.get(ControlKeys.Id)?.value;

		this.filteredWorkflowRelatedActivities = this.workflowRelatedActivities.filter((relatedActivity) =>
			(!lowerCaseQuery || relatedActivity.consoleName?.toLocaleLowerCase().includes(lowerCaseQuery)) && relatedActivity.id !== id,
		);
	}

	private async save(saveOption?: SaveOption) {
		this.form.setSubmitted();

		if (!this.form.valid) {
			return;
		}

		const wfTimerModel = this.form.getRawValue() as WorkflowTimerModel;

		try {
			let updatedTimer: WorkflowActivityTimer;

			if (!wfTimerModel.id) {
				updatedTimer = await this.ucWorkflow.addActivity<WorkflowActivityTimer>(this.formController.toData(wfTimerModel));
				this.tableManager.reload.next();
			} else {
				updatedTimer = await this.ucWorkflow.updateActivity<WorkflowActivityTimer>(this.formController.toData(wfTimerModel));
				this.tableManager.updateItem.next(updatedTimer);
			}

			this.toastService.success(`Timer ${wfTimerModel.id ? 'updated' : 'added'} successfully`);
			const formModel = this.formController.toModel(updatedTimer);

			this.form.reset(formModel);
			this.edited = false;

			if (!saveOption) {
				if (!wfTimerModel.id) {
					void this.router.navigate(['..', updatedTimer.id], { relativeTo: this.route });
				} else {
					this.builderHeaderService.updateConfig({
						breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [updatedTimer.consoleName]),
						lastModifiedAt: updatedTimer.lastModifiedAt,
						lastModifiedBy: updatedTimer.lastModifiedBy,
					});
				}

				this.titleService.updateTitle(updatedTimer.consoleName);

				return;
			}

			switch (saveOption.id) {
				case SaveOptionType.New:
					if (this.router.url.endsWith('/new')) {
						reloadCurrentRoute(this.router);

						return;
					} else {
						void this.router.navigate(['../', 'new'], { relativeTo: this.route });

						return;
					}
				case SaveOptionType.Next: {
					const nextId = this.tableManager.getNextItem(updatedTimer.id)?.id;

					if (nextId) {
						void this.router.navigate(['..', nextId], { relativeTo: this.route });

						return;
					}
					break;
				}
			}

			void this.router.navigate(['..'], { relativeTo: this.route });
		} catch (e) {
			const error = ensureUfError(e);

			this.toastService.error(error.message);
		}
	}

	private getTimer(id: string): Promise<WorkflowActivityTimer> {
		if (id === 'new') {
			return Promise.resolve({
				consoleName: 'New',
			} as WorkflowActivityTimer);
		}

		return this.ucWorkflow.getActivity<WorkflowActivityTimer>(id);
	}

	private buildHeaderConfig(timer: WorkflowActivityTimer) {
		const headerConfig = buildHeaderConfig(timer, this.hasSaveAndNextButton);

		headerConfig.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [headerConfig.title]);
		this.builderHeaderService.buildConfig(headerConfig);
	}

}
