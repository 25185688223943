import { AfterViewInit, Component } from '@angular/core';

import { ContentList, ModalSearchType } from 'components/content/content-list';
import { MediaSearchComponent } from 'components/content/modals/media-search.component';

import { LinkSearchConfig, LinkSearchType } from './modals/link-search.component';

@Component({
	selector: 'uc-video-list',
	templateUrl: './video-list.html',
	styleUrls: ['./group-input.less'],
	providers: [{ provide: ModalSearchType, useValue: MediaSearchComponent }],
	standalone: false,
})
export class VideoListComponent extends ContentList implements AfterViewInit {

	protected getSearchConfig(): LinkSearchConfig {
		return {
			type: LinkSearchType.Video,
			title: 'Add Video(s)',
			multiSelect: true,
		};
	}

}
