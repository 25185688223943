import { Injectable, inject } from '@angular/core';
import { ClientGetOptions, ProjectContentOptions, ProjectContentOptionsInterface, amendOptionsParams, mergeParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { ConsoleDataSource, DataSourceInfo } from './models';
import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class UcDataSources {

	private client = inject(UcClient);
	private options = inject(ProjectContentOptions) as ProjectContentOptionsInterface;

	list(q: string | null = null, options?: ClientGetOptions): Promise<DataSourceInfo[]> {

		const defaultedParams = mergeParams(DefaultPaginationParams, { q });

		return this.client.get(this.url(), amendOptionsParams(defaultedParams, options));
	}

	get(id: string, options?: ClientGetOptions): Promise<ConsoleDataSource> {
		return this.client.get(this.url(id), options);
	}

	save(dataSource: ConsoleDataSource): Promise<ConsoleDataSource> {
		if (dataSource.id) {
			return this.client.put(this.url(dataSource.id), dataSource);
		} else {
			return this.client.post(this.url(), { body: dataSource });
		}
	}

	private url(...extra: string[]): string {
		const urlParts = ['projects', this.options.projectId, 'data-sources', ...extra];

		return this.client.buildUrl(urlParts);
	}

}
