import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'uc-template-tabs',
	templateUrl: './template-tabs.html',
	standalone: false,
})
export class TemplateTabsComponent {

	@HostBinding('class.stretch-component') stretchComponentClass = true;

}

