<uf-expander class="uf-box flat">
	<div expanderHeader class="uf-app-bar flat">
		<div class="title primary">Validators</div>
		@if (validators.invalid) {
			<uf-icon name="error" class="error" />
		}
		@if (validators.length) {
			<uc-expander-controls [container]="validatorsWrap" />
		}
	</div>

	<div #validatorsWrap expanderBody class="pad-sm">
		<uc-drag-list [items]="validators.controls" canDrop="false" canReorder="true">
			@for (validator of validators.controls | asUfControlsGroups; track validator; let i = $index) {
				@if (validatorsInfo[i]; as validatorInfo) {
					<uf-expander [dragDisabled]="validators.disabled" [isExpanded]="expandeds[i]" dragItem class="uf-box flat draggable gap-sm-bottom">
						<div expanderHeader dragHandle class="uf-app-bar flat">
							<div class="title primary">{{ validatorInfo.typeLabel }}</div>
							@if (validator.invalid) {
								<uf-icon name="error" class="error" />
							}
							@if (!validator.disabled) {
								<button (click)="removeValidator(i)" type="button" title="Delete" class="uf-action">
									<uf-icon name="delete" />
								</button>
							}
						</div>
						<div [formGroup]="validator" expanderBody class="uf-grid pad">
							@if (!validator.get(validatorKeys.Value)?.disabled) {
								@if (validatorInfo.type !== validatorTypes.Expression) {
									<uf-text [label]="validatorInfo.valueLabel" [placeholder]="validatorInfo.valuePlaceholder" [formControlName]="validatorKeys.Value" class="col-12" />
								}
								@if (validatorInfo.type === validatorTypes.Expression) {
									<uf-textarea [label]="validatorInfo.valueLabel" [placeholder]="validatorInfo.valuePlaceholder" [formControlName]="validatorKeys.Value" class="col-12" />
								}
							}
							<uf-text [placeholder]="validatorInfo.messagePlaceholder" [formControlName]="validatorKeys.Message" label="Message" class="col-12" />
						</div>
					</uf-expander>
				}
			}
		</uc-drag-list>

		<button [options]="validatorOptions" (optionSelected)="addValidator($event)" type="button" class="uf-button x-small right gap-sm-top" listBox nameProperty="name">
			Add Validator
			<uf-icon name="more" class="gap-none" />
		</button>
	</div>
</uf-expander>
