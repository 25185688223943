import { Injectable, OnDestroy, inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { UfControl, UfControlArray, UfControlGroup, UfFormBuilder, UfFormControl, ValidatorFunctions, controlIterator } from '@unifii/library/common';
import { StructureNodeBucketOptions, StructureNodeType, TableDetailTemplate, hasLengthAtLeast, isNotNull, isValueOfStringEnumType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcStructure, UcStructureNode } from 'client';
import { PageViewEmptyTablesMessage } from 'constant';

import { StructureControlKeys, StructureNodeArgControlKeys, StructureNodeBucketOptionsControlKeys, StructureNodeControlKeys, StructureNodeVariationControlKeys, UserReferenceControlKeys } from './structure-control-keys';
import { StructureEditorCache } from './structure-editor-cache';
import { StructureEditorStructure, StructureEditorStructureNode, StructureEditorStructureNodeVariation, StructureNodeArgControlValue } from './structure-model';

// TODO Adopt ControlAccessor
interface DependantInfo {
	dependant: string; // control key
	dependencies: string[]; // list of control keys
}

@Injectable()
export class StructureFormCtrl implements OnDestroy {

	private dependantConfig: DependantInfo[] = [{
		dependant: StructureNodeControlKeys.Name,
		dependencies: [StructureNodeControlKeys.Type, StructureNodeControlKeys.NodeId],
	}];
	private subscriptions = new Subscription();
	private formBuilder = inject(UfFormBuilder);
	private cache = inject(StructureEditorCache);

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	mapDataToControlValue(structure: UcStructure): StructureEditorStructure {
		const result = this.mapDataNodeToControlNode(structuredClone(structure) as unknown as UcStructureNode) as unknown as StructureEditorStructure;

		const variations = (result as unknown as UcStructure).variations ?? [];

		for (const variation of variations) {
			this.dashboardTagsToTemplateAndEmptyMessage(variation);
		}

		result.variations = variations;
		result.isHidden = false;

		return result;
	}

	mapControlValueToData(structure: StructureEditorStructure): UcStructure {
		const copy = structuredClone(structure);

		const result = this.mapControlNodeToDataNode(copy) as unknown as UcStructure;

		for (const variation of result.variations ?? []) {
			this.dashboardTemplateToTags(variation);
		}

		delete (result as any).isHidden;

		return result;
	}

	buildRoot(structure: UcStructure): UfControlGroup {
		const editorStructure = this.mapDataToControlValue(structure);
		const group = this.buildNodeControl(editorStructure as StructureEditorStructureNode);

		group.addControl(StructureControlKeys.Rev, this.formBuilder.control(editorStructure.rev));
		group.addControl(StructureControlKeys.LastNodeId, this.formBuilder.control(editorStructure.lastNodeId));
		group.addControl(StructureControlKeys.Variations, this.formBuilder.array(editorStructure.variations.map((vr) => this.buildNodeVariationControl(vr))));
		group.addControl(StructureControlKeys.StructurePublishState, this.formBuilder.control(editorStructure.structurePublishState));
		group.addControl(StructureControlKeys.Revision, this.formBuilder.control(editorStructure.revision));
		group.addControl(StructureControlKeys.IsHidden, editorStructure.isHidden);

		return group;
	}

	buildNodeControl(node: StructureEditorStructureNode): UfControlGroup {

		const typeControl = this.formBuilder.control(node.type, ValidatorFunctions.required('Type is required'));
		const emptyMessageControl = this.buildEmptyMessageControl(node.emptyMessage, node.template);

		const group = this.formBuilder.group({
			[StructureNodeControlKeys.Type]: typeControl,
			[StructureNodeControlKeys.Id]: node.id,
			[StructureNodeControlKeys.NodeId]: [node.nodeId, ValidatorFunctions.required('NodeId is required')],
			[StructureNodeControlKeys.Name]: [node.name, ValidatorFunctions.custom((v, c) => {
				const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;
				const nodeId = c?.parent?.get(StructureNodeControlKeys.NodeId)?.value as string;

				let result: boolean;

				if (nodeId === '0' && type === StructureNodeType.Empty) {
					result = true;
				} else {
					result = !ValidatorFunctions.isEmpty(v);
				}

				return result;

			}, 'Menu name is required')],
			[StructureNodeControlKeys.DefinitionIdentifier]: [node.definitionIdentifier, ValidatorFunctions.custom((v, c) => {
				const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;

				return type !== StructureNodeType.Custom || !ValidatorFunctions.isEmpty(v);
			}, 'Identifier is required')],
			[StructureNodeControlKeys.DefinitionLabel]: node.definitionLabel,
			[StructureNodeControlKeys.PublishState]: node.publishState,
			[StructureNodeControlKeys.LastPublishedAt]: node.lastPublishedAt,
			[StructureNodeControlKeys.LastModifiedAt]: node.lastModifiedAt,
			[StructureNodeControlKeys.LastModifiedBy]: this.formBuilder.group({
				[UserReferenceControlKeys.Id]: node.lastModifiedBy?.id,
				[UserReferenceControlKeys.Username]: node.lastModifiedBy?.username,
			}),
			[StructureNodeControlKeys.LastPublishedBy]: this.formBuilder.group({
				[UserReferenceControlKeys.Id]: node.lastPublishedBy?.id,
				[UserReferenceControlKeys.Username]: node.lastPublishedBy?.username,
			}),
			[StructureNodeControlKeys.Url]: [node.url, ValidatorFunctions.custom((v, c) => {
				const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;

				return ![StructureNodeType.IFrame, StructureNodeType.Link].includes(type)
                    || !ValidatorFunctions.isEmpty(v);
			}, 'Url is required')],
			[StructureNodeControlKeys.Tags]: [node.tags],
			[StructureNodeControlKeys.Roles]: [node.roles],
			[StructureNodeControlKeys.IsHidden]: node.isHidden,
			[StructureNodeControlKeys.BucketOptions]: this.formBuilder.array(node.bucketOptions?.map((bo) => this.buildBucketOptionControl(bo)) ?? []),
			[StructureNodeControlKeys.Args]: this.formBuilder.array(node.args.map((arg) => this.buildArgControl(arg))),
			[StructureNodeControlKeys.Children]: this.buildNodeChildrenControl(node.children),
			[StructureNodeControlKeys.Template]: this.buildTemplateControl(node.template, emptyMessageControl),
			[StructureNodeControlKeys.EmptyMessage]: emptyMessageControl,
		});

		this.setDependencies(this.dependantConfig, group);

		return group;
	}

	buildNodeChildrenControl(children: StructureEditorStructureNode[]): UfControlArray {
		return this.formBuilder.array(children.map((child) => this.buildNodeControl(child)));
	}

	buildNodeVariationControl(variation: StructureEditorStructureNodeVariation): UfControlGroup {

		const emptyMessageControl = this.buildEmptyMessageControl(variation.emptyMessage, variation.template);

		return this.formBuilder.group({
			[StructureNodeVariationControlKeys.Type]: [variation.type, ValidatorFunctions.required('Variation type is required')],
			[StructureNodeVariationControlKeys.Name]: [variation.name, ValidatorFunctions.required('Variation name is required')],
			[StructureNodeVariationControlKeys.Id]: variation.id,
			[StructureNodeVariationControlKeys.PublishState]: variation.publishState,
			[StructureNodeVariationControlKeys.DefinitionIdentifier]: [variation.definitionIdentifier, ValidatorFunctions.custom((v, c) => {
				const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;

				return type !== StructureNodeType.Custom || !ValidatorFunctions.isEmpty(v);
			}, 'Variation identifier is required')],
			[StructureNodeVariationControlKeys.DefinitionLabel]: variation.definitionLabel,
			[StructureNodeVariationControlKeys.Roles]: [variation.roles],
			[StructureNodeVariationControlKeys.Tags]: [variation.tags],
			[StructureNodeVariationControlKeys.BucketOptions]: this.formBuilder.array(variation.bucketOptions?.map((bo) => this.buildBucketOptionControl(bo)) ?? []),
			[StructureNodeVariationControlKeys.Template]: this.buildTemplateControl(variation.template, emptyMessageControl),
			[StructureNodeVariationControlKeys.EmptyMessage]: emptyMessageControl,
		});
	}

	buildBucketOptionControl(bucketOption: StructureNodeBucketOptions): UfControlGroup {
		return this.formBuilder.group({
			[StructureNodeBucketOptionsControlKeys.Identifier]: bucketOption.identifier,
			[StructureNodeBucketOptionsControlKeys.PageSize]: [bucketOption.pageSize, ValidatorFunctions.custom((v) => v >= 1 && v <= 100, 'Between 1 and 100 rows')],
			[StructureNodeBucketOptionsControlKeys.Roles]: [bucketOption.roles],
		}, { asyncValidators: this.createBucketOptionAsyncValidator() });
	}

	buildArgControl(nodeArg: StructureNodeArgControlValue): UfControlGroup {
		return this.formBuilder.group({
			[StructureNodeArgControlKeys.Key]: [nodeArg.key, ValidatorFunctions.required('A key is required')],
			[StructureNodeArgControlKeys.Value]: nodeArg.value,
		});
	}

	mapDataNodeToControlNode(originalNode: UcStructureNode): StructureEditorStructureNode {
		const resultNode = originalNode as unknown as StructureEditorStructureNode;

		this.dashboardTagsToTemplateAndEmptyMessage(resultNode);

		if (originalNode.type === StructureNodeType.IFrame) {
			resultNode.args = originalNode.args?.map((arg) => ({
				key: arg.key,
				value: { value: arg.value ?? null, isExpression: !!arg.isExpression },
			})) ?? [];
		}

		resultNode.args = (resultNode as any).args ?? [];
		resultNode.children = originalNode.children?.map((child) => this.mapDataNodeToControlNode(child)) ?? [];

		return resultNode;
	}

	private mapControlNodeToDataNode(node: StructureEditorStructureNode): UcStructureNode {
		const result = node as unknown as UcStructureNode;

		this.dashboardTemplateToTags(result);

		if (node.type === StructureNodeType.IFrame) {
			result.args = node.args.map((arg) => ({
				key: arg.key,
				value: arg.value.value ?? undefined,
				isExpression: arg.value.isExpression,
			}));
		}

		result.children = node.children.map((child) => this.mapControlNodeToDataNode(child));

		return result;
	}

	private dashboardTemplateToTags(node: StructureEditorStructureNodeVariation) {
		const template = node.template;

		delete (node as any).template;

		if (node.type !== StructureNodeType.Dashboard) {
			delete node.emptyMessage;

			return;
		}

		node.tags = template ? [template] : undefined;

		if (template !== TableDetailTemplate.PageViewHideEmptyTables) {
			delete node.emptyMessage;
		}
	}

	private dashboardTagsToTemplateAndEmptyMessage(node: StructureEditorStructureNodeVariation) {
		if (node.type !== StructureNodeType.Dashboard) {
			return;
		}

		if (node.tags && hasLengthAtLeast(node.tags, 1) && isValueOfStringEnumType(TableDetailTemplate)(node.tags[0])) {
			node.template = node.tags[0];
		} else {
			node.template = TableDetailTemplate.PageView;
		}

		if (node.template !== TableDetailTemplate.PageViewHideEmptyTables) {
			node.emptyMessage = undefined;
		} else {
			node.emptyMessage = node.emptyMessage ?? PageViewEmptyTablesMessage;
		}
	}

	private buildTemplateControl(template: `${TableDetailTemplate}` | undefined, emptyMessageControl: UfControl): UfControl {
		const templateControl = this.formBuilder.control(template, ValidatorFunctions.custom((value, control) => {
			const type = control?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType | undefined;

			return type !== StructureNodeType.Dashboard || !ValidatorFunctions.isEmpty(value);

		}, 'A value is required'));

		this.subscriptions.add(templateControl.valueChanges.subscribe((value: `${TableDetailTemplate}` | null) => {
			if (value === TableDetailTemplate.PageViewHideEmptyTables) {
				emptyMessageControl.enable();
			} else {
				emptyMessageControl.disable();
			}
		}));

		return templateControl;
	}

	private buildEmptyMessageControl(emptyMessage: string | undefined, template: `${TableDetailTemplate}` | undefined): UfControl {
		return this.formBuilder.control(
			{ value: emptyMessage, disabled: template !== TableDetailTemplate.PageViewHideEmptyTables },
			ValidatorFunctions.custom((value, control) => {
				const templateValue = control?.parent?.get(StructureNodeControlKeys.Template)?.value as TableDetailTemplate | undefined;

				return templateValue !== TableDetailTemplate.PageViewHideEmptyTables || !ValidatorFunctions.isEmpty(value);

			}, 'A value is required'),
		);
	}

	private createBucketOptionAsyncValidator(): AsyncValidatorFn {
		return async(control: AbstractControl): Promise<ValidationErrors | null> => {

			const bucketOptions = control.value as StructureNodeBucketOptions;

			if (!bucketOptions.identifier) {
				return null;
			}

			const table = await this.cache.getTable(bucketOptions.identifier);

			if (!table) {
				return { table: 'Table not found' };
			}

			return null;
		};
	}

	private setDependencies(info: DependantInfo[], rootControl: UfControlGroup) {
		/**
         * limited to scope
         */
		for (const groupControl of controlIterator(rootControl)) {
			if (!(groupControl instanceof UfControlGroup)) {
				continue;
			}
			for (const { dependant, dependencies } of info) {
				const control = groupControl.get(dependant) as UfFormControl | undefined;

				if (control) {
					const deps = dependencies.map((n) => groupControl.get(n) as UfFormControl | null).filter(isNotNull);

					control.addDependencies(deps);
					// Refresh validators now all controls are initialized
					control.updateValueAndValidity();
				}
			}
		}

	}

}
