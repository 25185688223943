<uf-expander class="uf-box flat">
	<div expanderHeader class="uf-app-bar flat">
		<div class="title primary">Workflow</div>
		@if (transitions.invalid) {
			<uf-icon name="error" class="error" />
		}
		@if (transitions.valid) {
			<a (click)="pasteTransition()" title="Paste transition" class="uf-action tertiary">
				<uf-icon name="paste" />
			</a>
		}
	</div>

	<div expanderBody class="pad-sm">
		@if (!hasBucket) {
			<uf-blockquote class="warning col-1of1" icon="warningSolid" content="The Form Data Repository is necessary in order to configure the workflow" />
		}

		@if (transitions.length) {
			<uc-drag-list [parent]="transitions" [items]="transitions.controls" canDrop="false" canReorder="true">
				@for (transition of transitions.controls | asUfControlsGroups; track transition; let i = $index) {
					<div [dragDisabled]="transition.disabled" dragItem class="uf-app-bar uf-box flat draggable gap-sm-bottom">
						<div class="row grow center-all">
							<div class="col grow">
								<div class="grow pad-sm-left">
									<div>{{ getTransitionLabel(transition) }}</div>
									<!-- TODO Wait for 2.1 release to actually show the deep error -->
									<!-- <uf-error-deep [control]="transition"></uf-error-deep> -->
								</div>
								<div class="grow pad-sm-left">
									{{ getResultAndRolesLabel(transition) }}
								</div>
							</div>
							@if (transition.invalid) {
								<uf-icon name="error" class="error" />
							}
							@if (hasValidate(transition)) {
								<uf-icon name="required" title="validate required fields" class="gap-sm-sides primary" />
							}
							@if (hasShowIf(transition)) {
								<uf-icon name="showIf" title="show if" class="gap-sm-sides primary" />
							}
							@if (hasTags(transition)) {
								<uf-icon name="tagged" title="tags" class="gap-sm-sides primary" />
							}
							@if (hasDescription(transition)) {
								<uf-icon name="notes" title="description" class="gap-sm-sides primary" />
							}
							@if (transition.get(transitionKeys.HasPersistentVisibility)?.value) {
								<uf-icon name="view" title="always visible" class="gap-sm-sides primary" />
							}
							@if (transition.get(transitionKeys.KeepOpen)?.value) {
								<uf-icon name="next" title="keep open" class="gap-sm-sides primary" />
							}
							@if (!transition.invalid) {
								<button [disabled]="!hasBucket" (click)="copyTransition(i)" type="button" title="Copy" class="uf-action">
									<uf-icon name="copy" />
								</button>
							}
							@if (!transition.disabled) {
								<button [disabled]="!hasBucket" (click)="editTransition(i)" type="button" title="Edit" class="uf-action">
									<uf-icon name="edit" />
								</button>
							}
							@if (!transition.disabled) {
								<button (click)="removeTransition(i)" title="Delete" type="button" class="uf-action">
									<uf-icon name="delete" />
								</button>
							}
						</div>
					</div>
				}
			</uc-drag-list>
		}

		<uf-error [control]="transitions" class="wrap-text" />

		<div class="row">
			@if (!transitions.disabled) {
				<button [disabled]="!hasBucket" (click)="addTransition()" type="button" class="uf-button x-small right">Add Transition</button>
			}
		</div>
	</div>
</uf-expander>
