import { Component, Input, inject } from '@angular/core';
import { ClipboardService, DataPropertyDescriptor, ModalService, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { generateUUID } from '@unifii/sdk';

import { DialogsService } from 'services/dialogs.service';

import { DataSourceDisplayTo, DataSourceIdTo, getAttribute } from './data-source-editor-functions';
import { DataSourceFormCtrl } from './data-source-form-ctrl';
import { DataSourceMappingEditorComponent } from './data-source-mapping-editor.component';
import { DataSourceMappingsReorderComponent } from './data-source-mappings-reorder.component';
import { DataSourceMapping, DataSourceMappingControlKeys } from './data-source-model';

@Component({
	selector: 'uc-data-source-mappings',
	templateUrl: './data-source-mappings.html',
	standalone: false,
})
export class DataSourceMappingsComponent {

	@Input({ required: true }) mappings: UfControlArray;
	@Input({ required: true }) properties: DataPropertyDescriptor[];
	@Input() fieldIdentifier: string | null | undefined;

	protected readonly mappingKeys = DataSourceMappingControlKeys;

	private formCtrl = inject(DataSourceFormCtrl);
	private modalService = inject(ModalService);
	private clipboard = inject(ClipboardService);
	private dialogs = inject(DialogsService);

	protected async add() {
		const control = this.formCtrl.buildMappingControl({
			uuid: generateUUID(),
			type: null as any,
			to: null as any,
			label: null as any,
		});

		this.mappings.push(control);
		control.updateDependencies();
		this.mappings.updateDependencies();

		const result = await this.openEditor(control);

		if (!result) {
			this.mappings.removeAt(this.mappings.length - 1);
			this.mappings.updateDependencies();
			this.mappings.updateValueAndValidity();
		}
	}

	protected async edit(index: number) {
		const originalControl = this.mappings.at(index) as UfControlGroup;
		const editControl = this.formCtrl.buildMappingControl(originalControl.getRawValue() as DataSourceMapping);

		this.mappings.setControl(index, editControl);
		this.mappings.updateDependencies();
		this.mappings.updateValueAndValidity();

		const result = await this.openEditor(editControl);

		if (!result) {
			this.mappings.setControl(index, originalControl);
			this.mappings.updateDependencies();
			this.mappings.updateValueAndValidity();
		}
	}

	protected async remove(index: number, event: Event) {

		event.stopPropagation();

		if (!await this.dialogs.confirmDelete()) {
			return;
		}

		this.mappings.removeAt(index);
		this.mappings.updateDependencies();
	}

	protected async openReorder() {

		const sortableControls = (this.mappings.controls as UfControlGroup[])
			.filter((c) => ![DataSourceIdTo, DataSourceDisplayTo].includes(c.get(DataSourceMappingControlKeys.To)?.value));

		const result = await this.modalService.openMedium(
			DataSourceMappingsReorderComponent,
			sortableControls.map((c) => ({
				identifier: c.get(DataSourceMappingControlKeys.Uuid)?.value as string,
				name: c.get(DataSourceMappingControlKeys.Label)?.value as string,
			})),
		);

		if (!result) {
			return;
		}

		// Remove original controls
		while (this.mappings.length > 2) {
			this.mappings.removeAt(2);
		}

		// Add reordered controls
		for (const uuid of result) {
			this.mappings.push(sortableControls.find((c) => c.value.uuid === uuid));
		}
	}

	protected getFromCellValue(mapping: UfControlGroup): string {
		const mappingValue = mapping.getRawValue() as DataSourceMapping;

		if (mappingValue.isFromExpression) {
			return mappingValue.fromExpression as string;
		} else {
			return mappingValue.from?.display as string;
		}
	}

	protected getFromCellError(mapping: UfControlGroup): string {
		if (mapping.get(DataSourceMappingControlKeys.IsFromExpression)?.value === true) {
			return mapping.get(DataSourceMappingControlKeys.FromExpression)?.errors?.message as string;
		}

		return mapping.get(DataSourceMappingControlKeys.From)?.errors?.message as string;
	}

	protected getAttributeDisplay(mapping: UfControlGroup) {
		return getAttribute(mapping, this.fieldIdentifier ?? undefined);
	}

	protected copyAttribute(mapping: UfControlGroup, event: Event) {
		event.stopPropagation();
		void this.clipboard.setText(getAttribute(mapping, this.fieldIdentifier ?? undefined));
	}

	private openEditor(control: UfControlGroup) {

		return this.modalService.openLarge(
			DataSourceMappingEditorComponent,
			{ properties: this.properties, mapping: control, fieldIdentifier: this.fieldIdentifier ?? undefined },
		);
	}

}
