import { Component, EventEmitter, Input, NgZone, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { DataPropertyDescriptor, ExpandersService, UfControlGroup } from '@unifii/library/common';
import { VisibleFilterDescriptor } from '@unifii/sdk';

import { FilterInfo } from 'components';
import { DataSourceEditorComponent } from 'components/field-builder/data-source-editor';
import { TableComponent } from 'pages/tables/table.component';

@Component({
	selector: 'uc-visible-filters',
	templateUrl: 'visible-filters.html',
	providers: [ExpandersService],
	standalone: false,
})
export class VisibleFiltersComponent implements OnChanges {

	@Input() parentControl: UfControlGroup;
	@Input() availableFilters: DataPropertyDescriptor[];
	@Input() filters: VisibleFilterDescriptor[] | undefined;
	@Output() filtersChange = new EventEmitter<VisibleFilterDescriptor[] | undefined>();

	protected filterResults: DataPropertyDescriptor[];
	protected items: FilterInfo[];

	private _items: VisibleFilterDescriptor[] = [];

	private tableComponent = inject(TableComponent, { optional: true });
	private dataSourceEditor = inject(DataSourceEditorComponent, { optional: true });
	private zone = inject(NgZone);

	// determine if component need to render as card or flat
	get showAsCard(): boolean {
		return !!this.tableComponent;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.filters?.currentValue === this._items) {
			return;
		}
		this.items = (this.filters ?? []).map((f) => this.getFilterInfo(f));
	}

	protected addFilter(filter: VisibleFilterDescriptor) {
		this.zone.run(() => {
			this.items.push(this.getFilterInfo(filter));
			this.emit();
		});
	}

	protected removeFilter(index: number) {
		this.zone.run(() => {
			this.items.splice(index, 1);
			this.emit();
		});
	}

	protected filterFilters(q?: string) {
		this.filterResults = this.availableFilters.filter((descriptor) => {
			let accepted = true;

			// Apply search
			if (q?.trim().length) {
				accepted = (descriptor.display ?? '').toLowerCase().includes(q.toLowerCase());
			}
			// Apply excluded
			if (accepted) {
				accepted = !this.items.find((i) => i.filter.identifier === descriptor.identifier);
			}

			return accepted;
		});
	}

	protected emit() {
		this.syncLabels();
		this._items = this.items.map((i) => i.filter);
		this.filtersChange.emit(this._items);
	}

	private getFilterInfo(filter: VisibleFilterDescriptor): FilterInfo {

		const dataPropertyDescriptor = this.availableFilters.find((f) => f.identifier === filter.identifier);

		const dataDescriptorType =
            this.tableComponent?.info.dataDescriptor.type ??
            this.dataSourceEditor?.status.descriptor?.type;

		return {
			filter,
			dataDescriptorType,
			dataPropertyDescriptor,
			icon: dataPropertyDescriptor?.icon ?? 'query',
			label: this.getDisplayLabelValue(filter, dataPropertyDescriptor),
		};
	}

	private syncLabels() {
		for (const item of this.items) {
			item.label = this.getDisplayLabelValue(item.filter, item.dataPropertyDescriptor);
		}
	}

	private getDisplayLabelValue(filter: VisibleFilterDescriptor, dataPropertyDescriptor?: DataPropertyDescriptor): string {
		if (filter.label) {
			return `${filter.label} (${filter.identifier})`;
		}

		return dataPropertyDescriptor?.display ?? filter.identifier;
	}

}
