"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatInTimeZone = void 0;
const index_js_1 = require("../format/index.js");
const index_js_2 = require("../toZonedTime/index.js");
/**
 * @name formatInTimeZone
 * @category Time Zone Helpers
 * @summary Gets the offset in milliseconds between the time zone and Universal Coordinated Time (UTC)
 *
 * @param date the date representing the local time / real UTC time
 * @param timeZone the time zone this date should be formatted for; can be an offset or IANA time zone
 * @param formatStr the string of tokens
 * @param options the object with options. See [Options]{@link https://date-fns.org/docs/Options}
 * @param {0|1|2} [options.additionalDigits=2] - passed to `toDate`. See [toDate]{@link
 *   https://date-fns.org/docs/toDate}
 * @param {0|1|2|3|4|5|6} [options.weekStartsOn=0] - the index of the first day of the week (0 - Sunday)
 * @param {Number} [options.firstWeekContainsDate=1] - the day of January, which is
 * @param {Locale} [options.locale=defaultLocale] - the locale object. See
 *   [Locale]{@link https://date-fns.org/docs/Locale}
 * @param {Boolean} [options.awareOfUnicodeTokens=false] - if true, allows usage of Unicode tokens causes confusion:
 *   - Some of the day of year tokens (`D`, `DD`) that are confused with the day of month tokens (`d`, `dd`).
 *   - Some of the local week-numbering year tokens (`YY`, `YYYY`) that are confused with the calendar year tokens
 *   (`yy`, `yyyy`). See: https://git.io/fxCyr
 * @param {String} [options.timeZone=''] - used to specify the IANA time zone offset of a date String.
 */
function formatInTimeZone(date, timeZone, formatStr, options) {
  options = Object.assign(Object.assign({}, options), {
    timeZone,
    originalDate: date
  });
  return (0, index_js_1.format)((0, index_js_2.toZonedTime)(date, timeZone, {
    timeZone: options.timeZone
  }), formatStr, options);
}
exports.formatInTimeZone = formatInTimeZone;