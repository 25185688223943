import { IntegrationDetailComponent } from './integration-detail.component';
import { IntegrationFeatureArgModalComponent } from './integration-feature-arg-modal.component';
import { IntegrationFeatureModalComponent } from './integration-feature-modal.component';
import { IntegrationFeatureComponent } from './integration-feature.component';
import { IntegrationFeaturesComponent } from './integration-features.component';
import { IntegrationComponent } from './integration.component';

export * from './integration-detail.component';
export * from './integration.component';
export * from './integration-features.component';
export * from './integration-feature.component';
export * from './integration-feature-arg-modal.component';
export * from './integration-feature-modal.component';

export const INTEGRATION_COMPONENTS = [
	IntegrationComponent, IntegrationDetailComponent, IntegrationFeaturesComponent, IntegrationFeatureComponent, IntegrationFeatureModalComponent, IntegrationFeatureArgModalComponent,
];
