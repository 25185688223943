import { Component, HostBinding, inject } from '@angular/core';
import { ModalData, ModalRuntime } from '@unifii/library/common';

import { Media, MediaQueryParams, UcMedia } from 'client';
import { ModalSearchAbstractComponent, ModalSearchData } from 'components/content/modals/modal-search-abstract.component';

@Component({
	templateUrl: './media-search.html',
	styleUrls: ['./media-list.less'],
	standalone: false,
})
export class MediaSearchComponent extends ModalSearchAbstractComponent {

	@HostBinding('class.uc-form-card') classes = true;

	runtime = inject<ModalRuntime<ModalSearchData, Media[]>>(ModalRuntime);
	data = inject<ModalSearchData>(ModalData);

	private ucMedia = inject(UcMedia);

	search(q: string | undefined = undefined, offset: number): Promise<Media[]> {
		const params: MediaQueryParams = {
			q: q ?? undefined,
			types: this.data.type,
			limit: this.limit,
			offset,
		};

		return this.ucMedia.get(params);
	}

}
