<h4>Results Mappings</h4>
<table class="uf-table inputs accent clickable">
	<thead>
		<tr>
			<th>From</th>
			<!-- mapping.value -->
			<th>Data Type</th>
			<!-- mapping.type -->
			<th>To</th>
			<!-- mapping.attribute -->
			<th>Attribute</th>
			<!-- <field.identifier>.<mapping.attribute> -->
			<th>
				<div class="row">
					Label
					<uf-help content="Label used when the value is displayed" />
				</div>
			</th>
			<th>
				<div class="row">
					Visible
					<uf-help content="Enable to display this data in the form. Some data types may not be supported." />
				</div>
			</th>
			<th>Hide Empty</th>
			<!--  TODO - Update to return from schema settings
      <ng-template ufFeatureFlag="reporting">
        <th>
          <div class="row">
            Reportable
            <uf-help content="Enable to add this data to reports">
            </uf-help>
          </div>
        </th>
      </ng-template> -->
			<th></th>
		</tr>
	</thead>
	<tbody>
		@for (mapping of mappings.controls | asUfControlsGroups; track mapping; let i = $index) {
			<tr [formGroup]="mapping" (click)="edit(i)" class="body-row">
				<td>
					{{ getFromCellValue(mapping) }}
					@if (getFromCellError(mapping)) {
						<span class="uf-lozenge error">{{ getFromCellError(mapping) }}</span>
					}
				</td>
				<td>
					{{ mapping.get(mappingKeys.Type)?.value }}
					@if (mapping.get(mappingKeys.Type)?.invalid) {
						<span class="uf-lozenge error">Required</span>
					}
				</td>
				<td>
					{{ mapping.get(mappingKeys.To)?.value }}
					@if (mapping.get(mappingKeys.To)?.invalid) {
						<span class="uf-lozenge error">Invalid</span>
					}
				</td>
				<td class="row center-all">
					<button (click)="copyAttribute(mapping, $event)" type="button" title="Copy" class="uf-action tertiary gap-sm-right">
						<uf-icon name="copy" />
					</button>
					<span>{{ getAttributeDisplay(mapping) }}</span>
				</td>
				<td>{{ mapping.get(mappingKeys.Label)?.value }}</td>
				<td lass="input-cell">
					@if (mapping.get(mappingKeys.IsVisible)?.value) {
						<uf-icon name="tick" />
					}
				</td>
				<td lass="input-cell">
					@if (mapping.get(mappingKeys.HideEmpty)?.value) {
						<uf-icon name="tick" />
					}
				</td>
				<!-- TODO - Update to return from schema settings
        <ng-template ufFeatureFlag="reporting">
          <td lass="input-cell">
            <uf-icon *ngIf="mapping.get(mappingKeys.IsReportable)?.value" name="tick"></uf-icon>
          </td>
        </ng-template> -->
				<td class="input-cell">
					@if (i > 1) {
						<button (click)="remove(i, $event)" type="button" title="Delete" class="uf-action tertiary right gap-sm-right">
							<uf-icon name="delete" />
						</button>
					}
				</td>
			</tr>
		}
	</tbody>
</table>
<div class="col-12 row gap-top">
	<button [disabled]="mappings.invalid" (click)="openReorder()" type="button" class="uf-button right">Reorder Fields</button>
	<button (click)="add()" type="button" class="uf-button gap-sm-left">Add Mapping</button>
</div>
