<uf-expander class="uf-box flat">
	<div expanderHeader class="uf-app-bar flat">
		<div class="title primary">Options</div>
		@if (isInvalid) {
			<uf-icon name="error" class="error" />
		}
		@if (options.length) {
			<uc-expander-controls [container]="optionsWrap" />
		}
	</div>

	<div #optionsWrap expanderBody class="pad-sm">
		@if (options.length > 0 && dataSource) {
			<uf-message class="x-small warning" icon="warningSolid">
				<p>Unsupported configuration. Please configure either a Data Source or Options.</p>
			</uf-message>
		}

		@if (options.length === 0 && dataSource == null && options.valid) {
			<uf-message class="x-small warning" icon="warningSolid">
				<p>Default has no options - please ensure variations cover all scenarios.</p>
			</uf-message>
		}

		<!-- Options -->
		@if (meta.options) {
			<uc-drag-list [items]="options.controls" canDrop="false" canReorder="true">
				@for (option of options.controls | asUfControlsGroups; track option; let i = $index) {
					<uf-expander [dragDisabled]="options.disabled" [isExpanded]="expandeds[i]" dragItem class="uf-box flat draggable gap-sm-bottom">
						<div expanderHeader dragHandle class="uf-app-bar flat">
							<div class="title primary">
								{{ getOptionLabel(option) }}
							</div>
							@if (option.invalid) {
								<uf-icon name="error" class="error" />
							}
							@if (!option.disabled && type !== fieldTypes.Bool) {
								<button (click)="removeOption(i)" type="button" title="Delete" class="uf-action">
									<uf-icon name="delete" />
								</button>
							}
						</div>
						<div [formGroup]="option" expanderBody class="uf-grid pad">
							<uf-text [formControlName]="optionKeys.Name" label="Name" placeholder="Option name" class="col-12" />
							@if (type !== fieldTypes.Bool) {
								<uf-text [formControlName]="optionKeys.Identifier" [maxLength]="identifierMaxLength" label="Identifier" placeholder="Option identifier" class="col-12" />
							}
							@if (showIdentifierWarningLength(option)) {
								<uf-message class="x-small warning col-12" icon="warningSolid">
									<p>Identifier is too long</p>
								</uf-message>
							}
							@if (showContent) {
								<uc-markdown [formControlName]="optionKeys.Content" [showAlignmentOptions]="showAlignmentOptions" label="Content" class="col-12" />
							}
						</div>
					</uf-expander>
				}
			</uc-drag-list>
		}

		<!-- Options validators error sit on the ArrayControl -->
		@if (meta.options) {
			<uf-error [control]="options" />
		}

		<!-- DataSourceConfig -->
		@if (dataSourceConfig && meta.dataSourceConfig) {
			<div>
				@if (dataSource) {
					<div class="uf-box flat">
						<div class="uf-app-bar flat">
							<div class="title">Data Source</div>
							@if (canEditDataSource) {
								<button (click)="editDataSource()" title="Edit" type="button" class="uf-action">
									<uf-icon name="edit" />
								</button>
							}
							<button (click)="deleteDataSource()" type="button" title="Delete" class="uf-action">
								<uf-icon name="delete" />
							</button>
						</div>
						@if (meta.avoidDuplicates && avoidDuplicates) {
							<uf-checkbox [control]="avoidDuplicates" label="Avoid duplicates" class="gap-sides" />
						}
						<uf-data-display-list class="small-label pad">
							<dt>Type</dt>
							<dd>{{ dataSource.type | dataSourceTypeLabel }}</dd>
							@if (dataSourceName) {
								<dt>Name</dt>
								<dd>{{ dataSourceName }}</dd>
							}
						</uf-data-display-list>
						@if (dataSourceConfig.showError) {
							<uf-message [content]="dataSourceConfig.errors?.message" [showExpander]="false" icon="error" class="error gap-sides gap-bottom" />
						}
					</div>
				}
			</div>
		}

		<div class="row x-small gap-sm-top">
			<div class="right"></div>
			@if (meta.dataSourceConfig && !dataSource && !dataSourceConfig.disabled && (options.value == null || options.length === 0)) {
				<button (click)="editDataSource()" type="button" class="uf-button gap-sm-right">Add Data Source</button>
			}
			@if (!options.disabled && meta.options && type !== fieldTypes.Bool && !dataSource) {
				<button (click)="addOption()" type="button" class="uf-button">Add Option</button>
			}
		</div>
	</div>
</uf-expander>
