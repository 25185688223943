import { Component, Input } from '@angular/core';
import { UfError } from '@unifii/sdk';

@Component({
	selector: 'uc-error-message',
	templateUrl: './error-message.html',
	standalone: false,
})

export class ErrorMessageComponent {

	@Input() error?: UfError;

}
