import { DataDisplayColour } from '@unifii/library/common';

import { ActivityType, WorkflowEventType, WorkflowState } from 'client';

export const WorkflowEventLabel = {
	[WorkflowEventType.FormSubmitted]: 'Workflow Transition',
	[WorkflowEventType.Timer]: 'Timer',
	[WorkflowEventType.ApiEvent]: 'API Event',
	[WorkflowEventType.RoleAdded]: 'Role Added',
};

export const WorkflowSourceTypeLabel = {
	[WorkflowEventType.FormSubmitted]: 'Form Data',
	[WorkflowEventType.ApiEvent]: 'Integration',
	[WorkflowEventType.RoleAdded]: 'User',
	[WorkflowEventType.Timer]: undefined, // unused source type
};

export const WorkflowActivityLabel = {
	[ActivityType.DataForwarder]: 'Data Forwarder',
	[ActivityType.Notification]: 'Notification',
	[ActivityType.Timer]: 'Timer',
	[ActivityType.FormData]: 'Form Data',
};

export const WorkflowStateLabel = {
	[WorkflowState.DryRun]: 'Dry Run',
	[WorkflowState.Active]: WorkflowState.Active,
	[WorkflowState.Inactive]: WorkflowState.Inactive,
	[WorkflowState.Preview]: WorkflowState.Preview,
};

export const WorkflowStateColour: Record<WorkflowState, DataDisplayColour> = {
	[WorkflowState.DryRun]: 'info',
	[WorkflowState.Active]: 'success',
	[WorkflowState.Inactive]: 'default',
	[WorkflowState.Preview]: 'default',
};
