import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StorageWrapper } from '@unifii/library/common';

import { TokenService } from 'client';
import { PREVIEW_FORM_STORAGE_KEY } from 'constant';
import { ContextService } from 'services/context.service';
import { TenantSettingsService } from 'services/tenant-settings.service';

export const canActivateTenant: CanActivateFn = async() => {

	const service = inject(TenantSettingsService);
	const router = inject(Router);
	const context = inject(ContextService);
	const storage = inject(StorageWrapper);
	const tokenService = inject(TokenService);
	const location = inject(Location);

	try {
		await service.sync();

		// successfully synced tenant settings
		return true;
	} catch (err) {
		// Not enough tenant info provided
		const currentUrl = location.path();

		context.clear();
		tokenService.clear();
		storage.removeItem(PREVIEW_FORM_STORAGE_KEY);

		void router.navigate([`/login`, { return: currentUrl }]);

		return false;
	}
};
