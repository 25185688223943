<uf-panel class="container">
	<div class="header-pane uf-app-bar transparent">
		<uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large" />
	</div>

	<div class="content-pane scrollable">
		<uf-tabs>
			<uf-tab label="Collections" path="collections" />
			<uf-tab label="Pages" path="pages" />
			<uf-tab label="Views" path="views" />
		</uf-tabs>
	</div>
</uf-panel>
