<div class="pad-sides uf-grid">
	<div class="col-6">
		<label class="x-small">Show on</label>
		<div class="row gap-sm-top">
			@if (control.controls.showOnMobile) {
				<uf-checkbox [control]="control.controls.showOnMobile | asUfControl" label="Mobile" class="grow" />
			}
			@if (control.controls.showOnDesktop) {
				<uf-checkbox [control]="control.controls.showOnDesktop | asUfControl" label="Desktop" class="grow" />
			}
		</div>
	</div>
	<div class="col-6 gap-top uf-grid center-all">
		@if (!isCustomColumn) {
			<a (click)="copyIdentifier()" title="Copy" class="uf-action tertiary">
				<uf-icon name="copy" />
			</a>
			<uf-data-display-list [items]="[{ term: 'Identifier', data: column.identifier }]" class="col-10" />
		}
	</div>

	@if (headingControl.enabled) {
		<uf-text [control]="headingControl" label="Column Heading" class="col-6" />
	}
	@if (defaultCellValueControl.enabled) {
		<uf-text [control]="defaultCellValueControl" label="Default Cell Value" class="col-6" />
	}
	@if (control.controls.defaultTemplate) {
		<uf-select [control]="control.controls.defaultTemplate | asUfControl" [options]="templateOptions" label="Cell Template" nameProperty="_display" valueProperty="_id" class="col-6" />
	}

	@if (!!control.get("defaultTemplate")?.value) {
		@if (control.controls.colour) {
			<uf-select [control]="control.controls.colour | asUfControl" [options]="colourOptions" label="Colour" nameProperty="_display" valueProperty="_id" placeholder="Select template" class="col-6" />
		}
	}

	@if (formatOptions && control.controls.defaultFormat) {
		<uf-autocomplete [control]="control.controls.defaultFormat | asUfControl" [allowCustom]="true" [options]="formatOptions" [placeholder]="formatPlaceholder" label="Format" class="col-6" />
	}

	@if (itemTemplateControl.enabled) {
		<uf-textarea [control]="itemTemplateControl" label="Item Template" class="col-12" />
	}

	<div class="col-12">
		@if (control.controls.variations) {
			<uc-drag-list [items]="(control.controls.variations | asUfControlArray).controls" (moved)="emit()" canDrop="false" canReorder="true">
				@for (control of (control.controls.variations | asUfControlArray).controls | asUfControlsGroups; track control; let i = $index) {
					<uf-expander [isExpanded]="true" dragItem class="uf-box draggable flat gap-sm-top">
						<div expanderHeader dragHandle class="uf-app-bar">
							<div class="title primary">
								<div class="ellipsis">{{ control.get("condition")?.value }}</div>
							</div>
							<div>
								<button (click)="copyVariation(i)" type="button" title="Copy" class="uf-action tertiary">
									<uf-icon name="copy" />
								</button>
							</div>
							<div>
								<button (click)="removeVariation(i)" type="button" title="Delete" class="uf-action tertiary right">
									<uf-icon name="delete" />
								</button>
							</div>
						</div>
						<ng-container expanderBody>
							<div class="grow uf-grid pad">
								@if (control.controls.condition) {
									<uf-textarea [control]="control.controls.condition | asUfControl" label="Condition" class="col-12" />
								}
								@if (control.controls.value) {
									<uf-text [control]="control.controls.value | asUfControl" label="Value" class="col-12" />
								}
								@if (control.controls.template) {
									<uf-select [options]="templateOptions" [control]="control.controls.template | asUfControl" label="Template" nameProperty="_display" valueProperty="_id" class="col-6" />
								}
								@if (!!control.controls.template?.value && control.controls.colour) {
									<uf-select [control]="control.controls.colour | asUfControl" [options]="colourOptions" label="Colour" placeholder="Select colour" nameProperty="_display" valueProperty="_id" class="col-6" />
								}
							</div>
						</ng-container>
					</uf-expander>
				}
			</uc-drag-list>
		}
	</div>

	<div class="col-12 pad-bottom space-children row">
		<button (click)="pasteVariation()" type="button" class="uf-button x-small right">Paste Variation</button>
		<button (click)="addVariation()" type="button" class="uf-button x-small">Add Variation</button>
	</div>
</div>
