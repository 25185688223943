import { Component, HostBinding, ViewChild, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { Option } from '@unifii/sdk';

import { DragListComponent } from 'components/dragdrop/drag-list.component';

@Component({
	selector: 'uc-data-source-mappings-reorder',
	templateUrl: './data-source-mappings-reorder.html',
	standalone: false,
})
export class DataSourceMappingsReorderComponent implements Modal<Option[], string[]> {

	@HostBinding('class.uc-form-card') classes = true;
	@ViewChild(DragListComponent, { static: true }) dragListComponent: DragListComponent;
	runtime = inject<ModalRuntime<Option[], string[]>>(ModalRuntime);
	items = inject<Option[]>(ModalData);

	close() {
		this.runtime.close();
	}

	protected save() {
		this.runtime.close(this.dragListComponent.items.map((item) => item.identifier));
	}

}

