import { Inject } from '@angular/core';
import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin, mergeParams } from '@unifii/sdk';

import { Info, InfoLoader, InfoQueryParams } from 'services/table/models';

export class InfoTableDataSource extends TableDataSource<Info> {

	constructor(
		@Inject(InfoLoader) private loader: InfoLoader,
		private params?: InfoQueryParams,
	) {
		super();
	}

	get filtered() {
		return this.params?.q != null && this.params?.q?.trim().length > 0;
	}

	get sorted() {
		return this.params?.sort != null;
	}

	async load(options?: TableDataSourceQueryOptions) {
		try {
			const data = await this.loader.list(
				mergeParams(this.params as Record<string, unknown>, { offset: options?.offset, limit: options?.limit }),
				{ analytics: { origin: RequestAnalyticsOrigin.Table } },
			);

			this.stream.next({ data });
		} catch (error) {
			this.stream.next({ error });
		}
	}

}
