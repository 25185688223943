import { Injectable, OnDestroy, inject } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { UfControlGroup, UfFormBuilder, ValidatorFunctions, durationToSeconds, secondsToDuration } from '@unifii/library/common';
import { Duration } from 'date-fns';
import { Subscription } from 'rxjs';

import { TenantSettings } from 'client';

export enum SystemControlKeys {
	AllowedAssetFileTypes = 'allowedAssetFileTypes',
	AllowedAttachmentFileTypes = 'allowedAttachmentFileTypes',
	BaseUrl = 'baseUrl',
	BypassAssetFileTypesWhitelist = 'bypassAssetFileTypesWhitelist',
	BypassAttachmentFileTypesWhitelist = 'bypassAttachmentFileTypesWhitelist',
	ContactEmail = 'contactEmail',
	ContactName = 'contactName',
	ContactPhone = 'contactPhone',
	DisabledSessionTimeout = 'disabledSessionTimeout',
	EmailRequired = 'isUserEmailRequired',
	GoogleMapsApiKey = 'googleMapsApiKey',
	IsPasswordAuthSupported = 'isPasswordAuthSupported',
	IsMfaEnforced = 'isMfaEnforced',
	PrimaryLanguage = 'language',
	Logo = 'logo',
	Name = 'name',
	PrivacyPolicyUrl = 'privacyPolicyUrl',
	RememberMe = 'rememberMe',
	RememberMeExpiryDays = 'rememberMeExpiryDays',
	SessionDuration = 'sessionDuration',
	TokenDuration = 'tokenDuration',
	UsernameLoginLabel = 'usernameLoginLabel',
	Integration = 'integration',
}

export enum DurationControlKeys {
	Days = 'days',
	Hours = 'hours',
	Minutes = 'minutes',
	Seconds = 'seconds',
}

export interface TenantSettingsFormModel extends Omit<TenantSettings, 'id' | 'isAccessControlEnabled' | 'features'> {
	bypassAssetFileTypesWhitelist: boolean;
	bypassAttachmentFileTypesWhitelist: boolean;
	disabledSessionTimeout: boolean;
	tokenDuration?: Duration; // refreshTokenExpiry
	sessionDuration?: Duration; // maxSessionLength
}

@Injectable()
export class SystemGeneralController implements OnDestroy {

	private ufb = inject(UfFormBuilder);
	private subscriptions = new Subscription();
	private originalData: TenantSettingsFormModel;

	ngOnDestroy() {
		this.reset();
	}

	reset() {
		this.subscriptions.unsubscribe();
		this.subscriptions = new Subscription();
	}

	buildRoot(data: TenantSettingsFormModel): UfControlGroup {
		const disabledSessionTimeoutControl = this.ufb.control(data.disabledSessionTimeout);
		const rememberMeControl = this.ufb.control(data.rememberMe);
		const rememberMeExpiryDaysControl = this.ufb.control({ value: data.rememberMeExpiryDays, disabled: !data.rememberMe }, ValidatorFunctions.min(1, 'Must be at least 1 day'));
		const sessionDurationControl = this.buildSessionDurationControl(data.sessionDuration);
		const allowedAssetFileTypesControl = this.ufb.control(data.allowedAssetFileTypes);
		const allowedAttachmentFileTypesControl = this.ufb.control(data.allowedAttachmentFileTypes);
		const bypassAssetFileTypesWhitelistControl = this.ufb.control(data.bypassAssetFileTypesWhitelist);
		const bypassAttachmentFileTypesWhitelistControl = this.ufb.control(data.bypassAttachmentFileTypesWhitelist);

		const root = this.ufb.group({
			[SystemControlKeys.AllowedAssetFileTypes]: allowedAssetFileTypesControl,
			[SystemControlKeys.BypassAssetFileTypesWhitelist]: bypassAssetFileTypesWhitelistControl,
			[SystemControlKeys.AllowedAttachmentFileTypes]: allowedAttachmentFileTypesControl,
			[SystemControlKeys.BypassAttachmentFileTypesWhitelist]: bypassAttachmentFileTypesWhitelistControl,
			[SystemControlKeys.BaseUrl]: [data.baseUrl, ValidatorFunctions.required('A value is required')],
			[SystemControlKeys.ContactEmail]: [data.contactEmail, ValidatorFunctions.email('Must be a valid email')],
			[SystemControlKeys.ContactName]: data.contactName,
			[SystemControlKeys.ContactPhone]: data.contactPhone,
			[SystemControlKeys.DisabledSessionTimeout]: disabledSessionTimeoutControl,
			[SystemControlKeys.EmailRequired]: data.isUserEmailRequired,
			[SystemControlKeys.GoogleMapsApiKey]: data.googleMapsApiKey,
			[SystemControlKeys.IsMfaEnforced]: data.isMfaEnforced,
			[SystemControlKeys.IsPasswordAuthSupported]: data.isPasswordAuthSupported,
			[SystemControlKeys.Logo]: data.logo,
			[SystemControlKeys.Name]: [data.name, ValidatorFunctions.required('A value is required')],
			[SystemControlKeys.PrimaryLanguage]: data.language,
			[SystemControlKeys.PrivacyPolicyUrl]: data.privacyPolicyUrl,
			[SystemControlKeys.RememberMe]: rememberMeControl,
			[SystemControlKeys.RememberMeExpiryDays]: rememberMeExpiryDaysControl,
			[SystemControlKeys.SessionDuration]: sessionDurationControl,
			[SystemControlKeys.TokenDuration]: this.buildTokenDurationControl(data.tokenDuration),
			[SystemControlKeys.Integration]: data.integration,
			[SystemControlKeys.UsernameLoginLabel]: data.usernameLoginLabel,
		});

		this.subscriptions.add(bypassAssetFileTypesWhitelistControl.valueChanges.subscribe((bypass) => {
			if (bypass) {
				allowedAssetFileTypesControl.setValue([], { emitEvent: false });
			}
		}));

		this.subscriptions.add(bypassAttachmentFileTypesWhitelistControl.valueChanges.subscribe((bypass) => {
			if (bypass) {
				allowedAttachmentFileTypesControl.setValue([], { emitEvent: false });
			}
		}));

		this.subscriptions.add(rememberMeControl.valueChanges.subscribe((value) => {
			value ? rememberMeExpiryDaysControl.enable() : rememberMeExpiryDaysControl.disable();
		}));

		this.subscriptions.add(disabledSessionTimeoutControl.valueChanges.subscribe((value) => {
			if (value) {
				sessionDurationControl.disable();
				sessionDurationControl.reset();
			} else {
				sessionDurationControl.enable();
				sessionDurationControl.setValue({
					days: 0,
					hours: 0,
					minutes: 0,
					seconds: 0,
				} as Duration);
			}
		}));

		this.originalData = data;

		return root;
	}

	toDataModel(formModel: TenantSettingsFormModel): TenantSettings {
		const allowedAssetFileTypes = formModel.bypassAssetFileTypesWhitelist ? null: formModel.allowedAssetFileTypes;
		const allowedAttachmentFileTypes = formModel.bypassAttachmentFileTypesWhitelist ? null : formModel.allowedAttachmentFileTypes;

		const refreshTokenExpiry = durationToSeconds(formModel.tokenDuration);
		const maxSessionLength = formModel.disabledSessionTimeout ? undefined : durationToSeconds(formModel.sessionDuration);

		const cleanedFormModel = Object.assign({}, formModel);
		const cleanedOriginalData = Object.assign({}, this.originalData);

		const excludedKeys = [SystemControlKeys.BypassAssetFileTypesWhitelist, SystemControlKeys.BypassAttachmentFileTypesWhitelist, SystemControlKeys.DisabledSessionTimeout,
			SystemControlKeys.SessionDuration, SystemControlKeys.TokenDuration];

		for (const key of excludedKeys) {
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete cleanedFormModel[key];
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete cleanedOriginalData[key];
		}

		return Object.assign({}, cleanedOriginalData, cleanedFormModel, {
			allowedAssetFileTypes, allowedAttachmentFileTypes, refreshTokenExpiry, maxSessionLength,
		}) as any as TenantSettings;
	}

	toFormModel(dataModel: TenantSettings): TenantSettingsFormModel {
		const sessionDuration = secondsToDuration(dataModel.maxSessionLength);
		const tokenDuration = secondsToDuration(dataModel.refreshTokenExpiry);

		return {
			bypassAssetFileTypesWhitelist: !dataModel.allowedAssetFileTypes,
			bypassAttachmentFileTypesWhitelist: !dataModel.allowedAttachmentFileTypes,
			disabledSessionTimeout: dataModel.maxSessionLength == null,
			sessionDuration,
			tokenDuration,
			...dataModel,
		};

	}

	private buildSessionDurationControl(sessionDuration: Duration | undefined): UfControlGroup {
		const disabled = sessionDuration == null;

		return this.ufb.group({
			[DurationControlKeys.Days]: { value: sessionDuration?.days ?? 0, disabled },
			[DurationControlKeys.Hours]: { value: sessionDuration?.hours ?? 0, disabled },
			[DurationControlKeys.Minutes]: { value: sessionDuration?.minutes ?? 0, disabled },
			[DurationControlKeys.Seconds]: { value: sessionDuration?.seconds ?? 0, disabled },
		}, { validators: this.createDurationValidator(300, 'Must be over 5 minutes') });
	}

	private buildTokenDurationControl(tokenDuration: Duration | undefined): UfControlGroup {
		return this.ufb.group({
			[DurationControlKeys.Days]: tokenDuration?.days ?? 0,
			[DurationControlKeys.Hours]: tokenDuration?.hours ?? 0,
			[DurationControlKeys.Minutes]: tokenDuration?.minutes ?? 0,
			[DurationControlKeys.Seconds]: tokenDuration?.seconds ?? 0,
		}, { validators: this.createDurationValidator(300, 'Must be over 5 minutes') });
	}

	private createDurationValidator(minSeconds: number, message: string): (control: AbstractControl) => ValidationErrors | null {
		return (control: AbstractControl) : ValidationErrors | null => {
			const durationInSeconds = durationToSeconds(control.getRawValue() as Duration);

			return durationInSeconds <= minSeconds ? { message } : null;
		};
	}

}
