import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { ModalData, ModalRuntime } from '@unifii/library/common';
import { CompoundType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcProject } from 'client';
import { BuilderService } from 'components/compound-builder/builder.service';
import { ModalSearchAbstractComponent, ModalSearchData } from 'components/content/modals/modal-search-abstract.component';

export enum LinkSearchType {
	Audio = 'Audio',
	Video = 'Video',
	Image = 'Image',
	Form = 'Form',
	Collection = 'Collection',
	Compound = 'Compound',
}

export interface LinkSearchConfig extends ModalSearchData {
	type: LinkSearchType;
	ucProject?: UcProject;
	compoundType?: CompoundType; // For Compounds ('View', 'Page', 'Collection')
	types?: string[]; // For Collection/CollectionItem (['<definitionIdentifier>', ...])
	ignore?: string[]; // Used this list to filter out entries already added to list
}

@Component({
	selector: 'uc-link-search',
	templateUrl: './link-search.html',
	styleUrls: ['./media-list.less'],
	standalone: false,
})
export class LinkSearchComponent extends ModalSearchAbstractComponent implements OnInit, OnDestroy {

	@HostBinding('class.uc-form-card') classes = true;

	data = inject<LinkSearchConfig>(ModalData);
	runtime = inject<ModalRuntime<LinkSearchConfig, any[]>>(ModalRuntime);
	ready = false;

	private builderService = inject(BuilderService, { optional: true });
	private collections: any = [];
	private subscriptions = new Subscription();
	private linkSearchTypes = LinkSearchType;

	async ngOnInit() {

		this.ignoreList = this.data.ignore ?? [];

		if (this.data.type !== this.linkSearchTypes.Collection) {
			this.ready = true;

			return;
		}

		if (this.builderService) {
			this.subscriptions.add(this.builderService.ready.subscribe(() => {
				this.collections = this.builderService?.collections ?? [];
				this.ready = true;
				this.filter();
			}));
		} else if (this.searchConfig.ucProject) {
			this.collections = await this.searchConfig.ucProject.getCollections();
			this.ready = true;
			this.filter();
		}
	}

	get searchConfig(): LinkSearchConfig {
		return this.data;
	}

	search(query: string | null = null, offset: number): Promise<any[]> {
		// This has to handle all type of link search for the CMS
		if (this.searchConfig.type === LinkSearchType.Form) {
			return this.searchConfig.ucProject?.getForms({ params: { q: query, offset, limit: this.limit } }) ?? Promise.resolve([]);
		}

		if (this.searchConfig.type === LinkSearchType.Collection) {
			return this.collections.filter(
				(collection: any) =>
					!query ||
                    (collection.name.toLowerCase().includes(query.toLowerCase()) ||
                    collection.consoleName.toLowerCase().includes(query.toLowerCase())
                    ));
		}

		// 'Compound'
		const types = this.searchConfig.types ?? [];

		return this.searchConfig.ucProject?.getCompounds({
			params: {
				qx: query,
				compoundType: this.searchConfig.compoundType,
				types,
				offset,
				limit: this.limit,
			},
		}) ?? Promise.resolve([]);

	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}
