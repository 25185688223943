import { Injectable, inject } from '@angular/core';
import { ClientDeleteOptions, ClientGetOptions, amendOptionsParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { AuthProviderDetails, AuthProviderListDetails, AuthProviderSourceGroup, NewAuthProviderDetails } from './models';
import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class UcAuthProviders {

	private client = inject(UcClient);

	list(options?: ClientGetOptions): Promise<AuthProviderListDetails[]> {
		return this.client.get(this.url(), amendOptionsParams(DefaultPaginationParams, options));
	}

	get(id: string, options?: ClientGetOptions): Promise<AuthProviderDetails> {
		return this.client.get(this.url(id), options);
	}

	save(provider: AuthProviderDetails | NewAuthProviderDetails): Promise<AuthProviderDetails> {
		if ((provider as AuthProviderDetails).id) {
			return this.client.put(this.url((provider as AuthProviderDetails).id), provider);
		}

		return this.client.post(this.url(), { body: provider });
	}

	delete(id: string, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url(id), options);
	}

	getAuthProviderGroups(id: string, q?: string, options?: ClientGetOptions): Promise<AuthProviderSourceGroup[]> {
		return this.client.get(this.url(id, 'groups'), amendOptionsParams({ q }, options));
	}

	getAuthProviderGroup(authProviderId: string, groupId: string, options?: ClientGetOptions): Promise<AuthProviderSourceGroup> {
		return this.client.get(this.url(authProviderId, 'groups', groupId), options);
	}

	getAuthProviderClaims(id: string, options?: ClientGetOptions): Promise<string[]> {
		return this.client.get(this.url(id, 'claims'), options);
	}

	getAuthProviderRoles(id: string, options?: ClientGetOptions): Promise<{ id: string; name: string }[]> { // Auth0 only
		return this.client.get(this.url(id, 'roles'), options);
	}

	getMicrosoftConfig(options?: ClientGetOptions): Promise<{ clientId: string }> {
		return this.client.get(this.url('azure-config'), options);
	}

	getMappingFields(id: string, field: string, options?: ClientGetOptions): Promise<{ source: string; label: string }[]> {
		return this.client.get(this.url(id, 'user-map-fields', field), options);
	}

	changeActivationStatus(id: string, isActive: boolean) {
		return this.client.patch(this.url(id), { isActive });
	}

	private url(...extra: string[]): string {
		const urlParts = ['auth-providers', ...extra];

		return this.client.buildUrl(urlParts);
	}

}
