import { Component, HostBinding, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';

export interface GlossaryEntry {
	key: string;
	value: string;
}

export interface GlossaryConfig {
	title: string;
	glossary: GlossaryEntry[];
}

@Component({
	selector: 'uc-glossary',
	templateUrl: 'glossary.html',
	styleUrls: ['./glossary.less'],
	standalone: false,
})
export class GlossaryComponent implements Modal<GlossaryConfig, GlossaryEntry> {

	@HostBinding('class.uc-form-card') classes = true;

	runtime = inject<ModalRuntime<GlossaryConfig, GlossaryEntry>>(ModalRuntime);
	data = inject<GlossaryConfig>(ModalData);

	close(entry?: GlossaryEntry) {
		this.runtime.close(entry);
	}

	// Block this component to get focus
	protected onMouseDown() {
		return false;
	}

}
