import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterEntries } from '@unifii/library/common';

import { WorkflowRulesTableManager } from './workflow-rules-table-manager';
import { workflowRuleFilterFactory } from './workflows-filters';

@Component({
	selector: 'uc-workflow-rules',
	providers: [
		{ provide: FilterEntries, useFactory: workflowRuleFilterFactory },
		{ provide: TableContainerManager, useClass: WorkflowRulesTableManager },
	],
	template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="add()" ufSyncRoute class="accent pad-none list-md" />
        </div>
        <router-outlet />
    `,
	standalone: false,
})
export class WorkflowRulesComponent {

	private router = inject(Router);
	private route = inject(ActivatedRoute);

	add() {
		void this.router.navigate(['new'], { relativeTo: this.route });
	}

}
