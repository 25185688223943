import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, inject } from '@angular/core';

import { ContextService } from 'services/context.service';

/** Hides host element if context.account does not have the required roles */
@Directive({
	selector: '[restrict]',
	standalone: false,
})
export class RestrictDirective implements OnChanges {

	@Input() restrict: string | string[] | null | undefined;

	private elementRef = inject(ElementRef);
	private renderer = inject(Renderer2);
	private context = inject(ContextService);

	ngOnChanges(changes: SimpleChanges) {

		if (changes.restrict) {
			const next: string | string[] | null | undefined = changes.restrict.currentValue;

			if (!next) {
				return;
			}

			const list = !Array.isArray(next) ?
				next.split(',').map((r) => r.trim()) :
				next;

			const hasAccess = this.context.checkRoles(...list);

			// TODO come up with a way to guarantee [hidden] bindings do not mess with this
			if (!hasAccess) {
				this.renderer.setProperty(this.elementRef.nativeElement, 'hidden', true);
			}
		}
	}
    
}
