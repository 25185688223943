<div class="col grow">
	<div class="content-pane">
		<div class="uf-container">
			<div class="row-gap-lg pad-top">
				<div class="uf-box">
					<div class="uf-app-bar flat accent">
						<div class="title">Details</div>
						@if (isPublisher && schemaSetting.publishState !== publishStates.Draft) {
							<button (click)="updateSettingsPublishState(publishStates.Draft)" class="uf-button secondary" type="button">Unapprove</button>
						}
						@if (isPublisher && schemaSetting.publishState === publishStates.Draft) {
							<button (click)="updateSettingsPublishState(publishStates.Approved)" class="uf-button secondary" type="button">Approve</button>
						}
					</div>

					<div class="pad">
						<uf-data-display-list class="col-12">
							<ng-container>
								<dt>Form Data Repository</dt>
								<dd>{{ schema.bucket }}</dd>
							</ng-container>
							<ng-container>
								<dt>Forms</dt>
								<dd>{{ forms }}</dd>
							</ng-container>
							<ng-container>
								<dt>Has Rolling Version</dt>
								<dd>{{ schema.hasRollingVersion ? "True" : "False" }}</dd>
							</ng-container>
						</uf-data-display-list>
					</div>
				</div>
			</div>
		</div>
	</div>
	<uf-panel class="grow gap-sides scroll-all">
		<table [class.clickable]="!readOnly" class="small uf-table outline">
			<thead>
				<tr>
					<th colspan="6" class="table-header">
						<div class="uf-app-bar accent flat">
							<div class="title">Schema</div>
							<uf-drop-menu [options]="menuOptions" (selected)="onSort($event)" title="Sort By" class="uf-action gap-sm-bottom gap-sm-top" icon="sort" />
						</div>
					</th>
				</tr>
				<tr>
					<th>Identifier</th>
					<th>Label</th>
					<th>Short Label</th>
					<th>Type</th>
					<th>Searchable</th>
					<ng-template ufFeatureFlag="reporting">
						<th>Reportable</th>
					</ng-template>
				</tr>
			</thead>
			<tbody [root]="entries" ufDirectoryRoot childrenProperty="fields">
				@for (entry of entries | flatten: "fields"; track entry) {
					<tr [item]="entry" (click)="!readOnly && edit(entry)" ufDirectory>
						<td class="no-padding">
							<uf-directory-indicator [icon]="entry.icon" class="light"
								><code>{{ entry.identifier }}</code></uf-directory-indicator
							>
						</td>
						<td>{{ entry.label }}</td>
						<td>{{ entry.shortLabel }}</td>
						<td>
							{{ entry.type }}
						</td>
						<td>
							@if (formControl.get([entry.path])?.get(formKeys.IsSearchable); as control) {
								<span [class.success]="control.value" class="uf-lozenge">{{ control.value ? "True" : "False" }}</span>
							} @else {
								<span class="light">-</span>
							}
						</td>
						<ng-template ufFeatureFlag="reporting">
							<td>
								@if (formControl.get([entry.path])?.get(formKeys.IsReportable); as control) {
									<span [class.success]="control.value" class="uf-lozenge">{{ control.value ? "True" : "False" }} </span>
								} @else {
									<span class="light">-</span>
								}
							</td>
						</ng-template>
					</tr>
				}
			</tbody>
		</table>
	</uf-panel>
	@if (error) {
		<uf-blockquote [content]="error.message" class="error" icon="errorSolid" />
	}
</div>
