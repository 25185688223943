@if (control) {
	<div class="uf-box flat pad-sm-sides uf-grid row-gap-sm pad-sm-top pad-sm-bottom">
		@if (hasVariations) {
			<uf-select [options]="variationOptions" [(value)]="selectedVariation" (valueChange)="selectVariation($event)" label="Variation" placeholder="Default" class="col-12 pad-sm-sides" />
		}
		@if (!variation) {
			@if (hasContentLinked) {
				<uc-node-content-info [control]="control" class="col-12" />
			}
			<uc-node-settings [control]="control" class="col-12" />
			@if (!isHome) {
				<uc-node-roles [control]="control" class="col-12" />
			}
			@if (isHome) {
				<uc-node-variations [control]="variations" class="col-12" />
			}
			@if (node.type === nodeTypes.Dashboard) {
				<uc-node-dashboard-tables [control]="bucketOptions" class="col-12" />
			}
			@if (node.type === nodeTypes.IFrame) {
				<uc-node-args [control]="args" class="col-12" />
			}
		}
		@if (variation) {
			@if (hasVariationContentLinked) {
				<uc-node-content-info [control]="variation" class="col-12" />
			}
			<uc-node-settings [control]="variation" class="col-12" />
			<uc-node-roles [control]="variation" class="col-12" />
			@if (variation.get(variationControlKeys.Type)?.value === nodeTypes.Dashboard && variation.get(variationControlKeys.BucketOptions); as bucketOptionsControl) {
				<uc-node-dashboard-tables [control]="bucketOptionsControl | asUfControlArray" class="col-12" />
			}
		}
	</div>
}
