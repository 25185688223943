"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QueryIdentifierArgsOnlyOperators = exports.QueryComparisonOperators = exports.QueryOperatorOperators = exports.QueryCombinatorOperators = void 0;
const models_1 = require("../models");
/** Operators for Combinator AstNode  */
exports.QueryCombinatorOperators = [models_1.QueryOperators.And, models_1.QueryOperators.Or, models_1.QueryOperators.Not];
/** Operators for Operator AstNode */
exports.QueryOperatorOperators = [models_1.QueryOperators.Equal, models_1.QueryOperators.NotEqual, models_1.QueryOperators.LowerThan, models_1.QueryOperators.LowerEqual, models_1.QueryOperators.GreaterThan, models_1.QueryOperators.GreaterEqual, models_1.QueryOperators.In, models_1.QueryOperators.Contains, models_1.QueryOperators.Descendants, models_1.QueryOperators.Has, models_1.QueryOperators.Hasnt, models_1.QueryOperators.Search, models_1.QueryOperators.Sort, models_1.QueryOperators.Offset, models_1.QueryOperators.Limit, models_1.QueryOperators.Include];
exports.QueryComparisonOperators = [models_1.QueryOperators.Equal, models_1.QueryOperators.NotEqual, models_1.QueryOperators.LowerThan, models_1.QueryOperators.LowerEqual, models_1.QueryOperators.GreaterThan, models_1.QueryOperators.GreaterEqual, models_1.QueryOperators.In, models_1.QueryOperators.Contains, models_1.QueryOperators.Descendants];
/** Operators that requires only args of type Identifier */
exports.QueryIdentifierArgsOnlyOperators = [models_1.QueryOperators.Has, models_1.QueryOperators.Hasnt, models_1.QueryOperators.Include];
