import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { DataDisplayListItem, Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { CompoundType, isNotNull } from '@unifii/sdk';

export interface FileConflictError {
	links: FileConflictLink[];
	projectName: string;
	projectId: number;
}

interface FileConflictLink {
	id: string | number;
	name: string;
	compoundType: CompoundType;
	title?: string;
}
@Component({
	selector: 'uc-file-conflict-modal',
	templateUrl: './file-conflict-modal.html',
	standalone: false,
})
export class FileConflictModalComponent implements Modal<FileConflictError[], void>, OnInit {

	@HostBinding('class.uf-form-card') classes = true;
	runtime = inject<ModalRuntime<FileConflictError[], void>>(ModalRuntime);
	data = inject<FileConflictError[]>(ModalData);
	content: {
		name: string;
		data: DataDisplayListItem[];
	}[];

	ngOnInit() {
		this.content = this.data.map((error) => ({
			name: error.projectName,
			data: error.links
				.filter(isNotNull)
				.sort((link1, link2) => link1.compoundType > link2.compoundType ? 1 : -1)
				.map((link) => ({
					term: link.compoundType === 'Collection' ? `${link.name} Collection` : link.compoundType,
					data: link.compoundType === 'Collection' ? link.title : `${link.title} (${link.name})`,
				})),
		}));
	}

	close() {
		this.runtime.close();
	}

}
