import { Injectable, inject } from '@angular/core';
import { TableContainerManager, TableInputManager } from '@unifii/components';
import { ActionMultiplicity, FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig } from '@unifii/library/common';
import { Subject } from 'rxjs';

import { IntegrationFeature, UcIntegrations } from 'client';
import { DialogsService } from 'services/dialogs.service';

import { IntegrationFeaturesDataSource } from './integration-features-datasource';

@Injectable()
export class IntegrationFeaturesTableManager implements TableContainerManager<IntegrationFeature, FilterValue, FilterEntry> {

	tableConfig: TableConfig<IntegrationFeature>;
	reload = new Subject<void>();
	selectRow = new Subject<IntegrationFeature>();
	updateItem = new Subject<IntegrationFeature | { item: IntegrationFeature; trackBy: keyof IntegrationFeature }>();
	tableManager = new TableInputManager<FilterValue, FilterEntry>([], inject(HierarchyUnitProvider), null, null);
	integrationFeatures: IntegrationFeature[];
	integrationId: string;
	addActionConfig = true;

	private dialogs = inject(DialogsService);
	private ucIntegration = inject(UcIntegrations);

	constructor() {
		this.tableConfig = {
			row: { link: (integrationFeature) => [integrationFeature.id] },
			columns: [{
				name: 'name',
				label: 'Feature',
			}, {
				name: 'type',
				label: 'Type',
			}],
			pageSize: -1,
		};
		this.tableConfig.actions = [{
			label: 'Delete',
			multiplicity: ActionMultiplicity.Single,
			action: (integrationFeature) => this.delete(integrationFeature.$implicit.id),
		}];
	}

	createDataSource() {
		return new IntegrationFeaturesDataSource(this.integrationFeatures);
	}

	private async delete(id: string) {
		if (!await this.dialogs.confirmDelete()) {
			return;
		}

		this.integrationFeatures = this.integrationFeatures.filter((integrationFeature) => integrationFeature.id !== id);

		if (this.integrationId) {
			await this.ucIntegration.deleteFeature(id, this.integrationId);
		}

		this.reload.next();
	}

}
