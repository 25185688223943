<div class="header">
	<button (click)="close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>Select Devices</h3>
</div>

<div class="content">
	<uf-search [autofocus]="true" (searchChange)="filter($event)" />
</div>

<uf-panel class="container">
	<ul class="uc-list--joined list">
		@if (!filteredDevices.length) {
			<li class="uc-list-item--small padded--vertical">Sorry, there was no result for your search.</li>
		}

		@for (device of filteredDevices; track device) {
			<li [class.selected]="selectedMap[device.id]" (click)="select(device.id)" class="uc-list-item--bottom-border--small row center-all list-item">
				<span class="grow"> {{ device.name }} - {{ device.model }} - {{ device.id }} </span>
				@if (selectedMap[device.id]) {
					<uf-icon name="tick" />
				}
			</li>
		}
	</ul>
</uf-panel>

<div class="row space-children pad">
	<button (click)="close()" type="button" class="uf-button tertiary right">Close</button>
	<button (click)="save()" type="button" class="uf-button primary">Add</button>
</div>
