import { Injectable, inject } from '@angular/core';
import { CompanyIdentifiers, DataLoaderFactory, DataPropertyDescriptor, DataSourceIdTo, HierarchyUnitProvider, SourceConfig, UfControlArray, UfControlGroup, UfFormBuilder, UserInfoIdentifiers, ValidatorFunctions } from '@unifii/library/common';
import { AstNode, DataSeed, DataSourceType, FieldType, HierarchyUnit, NodeType, OperatorComparison, QueryOperators, UserInfo, hasLengthAtLeast, Operator as sdkOperator } from '@unifii/sdk';

import { UcUsers } from 'client';

import { FilterEditorSupportedSourceConfig, filterEditorSupportSourceConfig } from './filter-editor-functions';

export enum FilterNodeControlKeys {
	Field = 'field',
	Type = 'type',
	Operator = 'operator',
	ValueType = 'valueType',
	Value = 'value',
}

export interface FilterEditorNode {
	field?: DataPropertyDescriptor;
	type: NodeType;
	operator?: OperatorComparison;
	valueType?: NodeType;
	value?: any;
}

@Injectable()
export class FilterEditorFormCtrl {

	readonly queryComparisonOperators: sdkOperator[] = [
		QueryOperators.Equal,
		QueryOperators.NotEqual,
		QueryOperators.LowerThan,
		QueryOperators.LowerEqual,
		QueryOperators.GreaterThan,
		QueryOperators.GreaterEqual,
		QueryOperators.In,
		QueryOperators.Contains,
		QueryOperators.Descendants,
	];

	private fb = inject(UfFormBuilder);
	private dataLoaderFactory = inject(DataLoaderFactory);
	private usersClient = inject(UcUsers);
	private hierarchyProvider = inject(HierarchyUnitProvider);

	mapFilterToFilterNodes(filter: AstNode, dataProperties: DataPropertyDescriptor[]): Promise<FilterEditorNode[]> {
		return Promise.all((filter.args ?? []).map((arg) => this.mapAstNodeToFilterNode(arg, dataProperties)));
	}

	async mapAstNodeToFilterNode(ast: AstNode, dataProperties: DataPropertyDescriptor[]): Promise<FilterEditorNode> {

		const leftNode = (ast.args && hasLengthAtLeast(ast.args, 1)) ? ast.args[0] : null;
		const rightNode = (ast.args && hasLengthAtLeast(ast.args, 2)) ? ast.args[1] : null;
		const fieldIdentifier = leftNode?.value as string | undefined;

		// Field
		const matchField = !fieldIdentifier ? undefined : dataProperties.find((dp) => {
			// AstNode for a ZonedDateTime is save with <identifier>.value
			if (dp.type === FieldType.ZonedDateTime) {
				return `${dp.identifier}.value` === fieldIdentifier;
			}

			if (dp.type === FieldType.Hierarchy) {
				return `${dp.identifier}.id` === fieldIdentifier;
			}

			// Exclude the 'artificial' datasource like _lastModifiedBy that are of type FieldType.Text
			if ([FieldType.Choice, FieldType.Lookup].includes(dp.type) && dp.sourceConfig) {
				// AstNode for a DS based field is saved with <identifier>._id
				if (fieldIdentifier === dp.identifier && !fieldIdentifier.endsWith(`.${DataSourceIdTo}`)) {
					// The direct <identifier> entry is not valid
					return false;
				}
				if (fieldIdentifier === `${dp.identifier}.${DataSourceIdTo}`) {
					// The entry <identifier>._id match the DS based field data property
					return true;
				}
			}

			// User's Manager
			if (dp.type === FieldType.Text &&
                // distinguish between a mapped Manager and a DS User mapped id (it's bad bad it's the only discerning attribute)
                dp.asSort === false &&
                dp.sourceConfig?.type === DataSourceType.Users &&
                dp.sourceConfig.mappingsTo[DataSourceIdTo]?.from === UserInfoIdentifiers.Id
			) {
				return fieldIdentifier === `${dp.identifier}.${UserInfoIdentifiers.Id}`;
			}

			// User's Company
			if (dp.type === FieldType.Text &&
                dp.sourceConfig?.type === DataSourceType.Company &&
                dp.sourceConfig.mappingsTo[DataSourceIdTo]?.from === CompanyIdentifiers.Id
			) {
				return fieldIdentifier === `${dp.identifier}.${CompanyIdentifiers.Id}`;
			}

			// Standard
			return fieldIdentifier === dp.identifier;

		});

		const field = !fieldIdentifier ? undefined : matchField ?? {
			identifier: fieldIdentifier,
			type: FieldType.Text,
			label: fieldIdentifier,
			display: fieldIdentifier,
		} as DataPropertyDescriptor;

		// Operator
		const operator = ast.op as OperatorComparison | undefined;

		// ValueType
		const valueType = rightNode?.type ?? NodeType.Value;

		// Value
		let value = rightNode?.value; // Any transformation here?

		if (valueType === NodeType.Value && field && value) {
			if (filterEditorSupportSourceConfig(field.sourceConfig)) {
				value = await this.getSeeds(value, field.sourceConfig);
			}
			if (field.type === FieldType.Hierarchy) {
				value = await this.hierarchyProvider.getUnit(value);
			}
		}

		return { field, type: ast.type, operator, valueType, value };
	}

	mapFilterNodesToFilter(nodes: FilterEditorNode[]): AstNode | undefined {

		if (!nodes.length) {
			return;
		}

		const args = nodes.map((node) => this.mapFilterNodeToAstNode(node)).filter((e) => e != null) as AstNode[];

		return { type: NodeType.Combinator, op: QueryOperators.And, args };
	}

	// eslint-disable-next-line complexity
	mapFilterNodeToAstNode(node: FilterEditorNode): AstNode | undefined {

		if (!node.field || node.value == null) {
			return;
		}

		let identifier = node.field.identifier;

		// AstNode for a ZonedDateTime is save with <identifier>.value
		if (node.field.type === FieldType.ZonedDateTime) {
			identifier = `${node.field.identifier}.value`;
		}

		// AstNode for a DS based field is saved with <identifier>._id
		if (node.field.sourceConfig && [FieldType.Choice, FieldType.Lookup].includes(node.field.type)) {
			identifier = `${node.field.identifier}._id`;
		}

		// FieldType.Text with SourceConfig are special properties
		if (node.field.type === FieldType.Text && node.field.sourceConfig) {

			// user.company
			if (node.field.sourceConfig.type === DataSourceType.Company &&
                node.field.sourceConfig.mappingsTo[DataSourceIdTo]?.from === CompanyIdentifiers.Id
			) {
				identifier = `${identifier}.${CompanyIdentifiers.Id}`;
			}

			if (node.field.sourceConfig.type === DataSourceType.Users) {
				// _lastModifiedBy, _lastModifiedAt
				if (node.field.sourceConfig.mappingsTo[DataSourceIdTo]?.from === UserInfoIdentifiers.Username) {
					// identifier doesn't need alteration
				}

				// manager
				if (node.field.sourceConfig.mappingsTo[DataSourceIdTo]?.from === UserInfoIdentifiers.Id &&
                    // distinguish between a mapped Manager and a DS User mapped id (it's bad bad it's the only discerning attribute)
                    node.field.asSort === false
				) {
					identifier = `${identifier}.${UserInfoIdentifiers.Id}`;
				}
			}
		}

		let value = node.value;

		if (node.field.sourceConfig && node.field.sourceConfig.type !== DataSourceType.Named) {
			value = Array.isArray(value) ? value.map((v) => v._id ?? v) : value._id ?? value;
		}

		if (node.field.type === FieldType.Hierarchy) {
			identifier = `${node.field.identifier}.id`;

			if (node.valueType === NodeType.Value) {
				value = (value as HierarchyUnit).id;
			}
		}

		return {
			type: node.type,
			op: node.operator,
			args: [
				{
					type: NodeType.Identifier,
					value: identifier,
				},
				{
					type: node.valueType ?? NodeType.Value,
					value,
				},
			],
		};
	}

	buildRootControl(nodes: FilterEditorNode[]): UfControlArray {
		return this.fb.array(nodes.map((arg) => this.buildNodeControl(arg)));
	}

	buildNodeControl(node: FilterEditorNode): UfControlGroup {

		const typeControl = this.fb.control(node.type, ValidatorFunctions.compose([
			ValidatorFunctions.required('A type is required'),
			// To extends to NodeType.Combinator when editor will manage deeper AstNode levels
			ValidatorFunctions.custom((v) => v === NodeType.Operator, 'Only Operator node are allowed'),
		]));

		const control = this.fb.group({
			[FilterNodeControlKeys.Field]: [node.field, ValidatorFunctions.required('A field is required')],
			[FilterNodeControlKeys.Type]: typeControl,
			[FilterNodeControlKeys.Operator]: [node.operator, ValidatorFunctions.compose([
				ValidatorFunctions.required('An operator is required'),
				// To extends to NodeType.Combinator and Combinators check when editor will manager deeper AstNode levels
				ValidatorFunctions.custom((v) => this.queryComparisonOperators.includes(v), 'Only Comparison operators are allowed for a Comparison node'),
			])],
			[FilterNodeControlKeys.ValueType]: [node.valueType, ValidatorFunctions.compose([
				ValidatorFunctions.required('A value type is required'),
				ValidatorFunctions.custom((v) => [NodeType.Value, NodeType.Expression].includes(v), `Only 'Value' or 'Expression' are allowed`),
			])],
			[FilterNodeControlKeys.Value]: [node.value, ValidatorFunctions.required('A value is required')],
		}, {
			// Not a staticFilter field validation and flag ad touched
			validators: ValidatorFunctions.custom((v) => {
				const controlNode = v as FilterEditorNode;

				if (!controlNode.field) {
					return true;
				}

				return controlNode.field.asStaticFilter === true;
			}, `Field '${node.field?.identifier ?? ''}' not available`),
		});

		// Force error to be visible for non mapped fields
		if (node.field && !node.field.asStaticFilter) {
			control.markAsTouched();
		}

		return control;
	}

	isValid(filter: AstNode): boolean {

		// First level attributes check
		if (!filter.args || !filter.op || filter.type == null) {
			return false;
		}

		// Check args are only 2 and valid
		for (const node of filter.args) {
			if (!node.args) {
				return false;
			}

			return node.args.filter((arg) => arg.type != null && arg.value != null).length === 2;
		}

		return true;
	}

	// TODO Swap v0 DataSourceLoader from Console /api services
	private async getSeeds(value: string | string[], sourceConfig: FilterEditorSupportedSourceConfig): Promise<DataSeed | DataSeed[] | null> {

		const getSeed = async(key: string, sc: SourceConfig): Promise<DataSeed | null> => {
			try {
				switch (sc.type) {
					case DataSourceType.Users: {

						let user: UserInfo | undefined;

						if (sc.mappingsTo[DataSourceIdTo]?.from === UserInfoIdentifiers.Id) {
							user = await this.usersClient.getDetails(key);
						} else {
							user = await this.usersClient.getByUsername(key);
						}

						if (user.lastName == null && user.firstName == null) {
							return { _id: key, _display: 'First and Last name undefined' };
						} else {
							return { _id: key, _display: `${user.firstName ?? ''} ${user.lastName ?? ''}` };
						}
					}
					default: {
						const dataSourceLoader = this.dataLoaderFactory.create(sourceConfig);

						if (!dataSourceLoader) {
							return null;
						}

						return dataSourceLoader.get(key);
					}
				}
			} catch (e) {
				return null;
			}
		};

		if (Array.isArray(value)) {
			const seeds: DataSeed[] = [];

			for (const v of value) {
				const seed = await getSeed(v, sourceConfig);

				if (seed) {
					seeds.push(seed);
				}
			}

			return seeds;
		}

		return getSeed(value, sourceConfig);
	}

}
