<uf-expander [class.uc-group--draggable--no-shrink]="canDragField && !isSelected" [class.uc-group--draggable--no-shrink--accent]="canDragField && isSelected" class="uc-group">
	<div [class.accent]="isSelected" (click)="selectField($event)" expanderHeader dragHandle class="uf-app-bar flat">
		<uf-icon [name]="icon" />
		<div class="title primary">
			{{ title }}
			@if (configuredField.label) {
				<span> | {{ configuredField.label }}</span>
			}
		</div>
		@if (!isValid) {
			<uf-icon name="warning" class="icon-warning" />
		}
		@if (configuredField.help) {
			<uf-help [content]="configuredField.help" class="uf-action tertiary" />
		}
		@if (canRemoveField) {
			<a (click)="removeField($event)" title="Delete" class="uf-action tertiary">
				<uf-icon name="delete" />
			</a>
		}
	</div>
	<div [class.content--accent]="isSelected" expanderBody class="uc-group-content">
		@if (content) {
			<ul class="uc-list">
				<li class="uc-list-item--small--no-shrink row center-all">
					<div class="list-content">
						<div class="list-context">
							<a [routerLink]="getLink(content)" target="_blank" title="Open" class="uf-action tertiary">
								<uf-icon name="open" />
							</a>
							@if (canEditField && content) {
								<a (click)="delete(0)" title="Delete" class="uf-action tertiary">
									<uf-icon name="delete" />
								</a>
							}
						</div>
						<!-- It's necessary to add both _consoleName and consoleName, because form has the value in consoleName all the others are loaded from _consoleName -->
						<div>{{ this.content._consoleName ?? this.content.consoleName ?? this.content.name ?? this.content._title }}</div>
					</div>
				</li>
			</ul>
		}
		@if (canEditField && !content) {
			<div class="row padded">
				<button (click)="add()" type="button" class="uf-button x-small right">Select {{ title }}</button>
			</div>
		}
	</div>
</uf-expander>
<uf-error [control]="control" />
