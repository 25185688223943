import { CollectionItemMetadataIdentifiers, DataPropertyDescriptor, DataSourceUserFullNameExpression, FormDefinitionMetadataIdentifiers, UfControlGroup, UserInfoIdentifiers } from '@unifii/library/common';
import { AstNode, DataSourceInputType, DataSourceType, FieldType, NodeType, generateUUID } from '@unifii/sdk';

import { DataSourceInputValueSource, IntegrationFeatureArg, IntegrationFeatureArgType, UcDefinitionDataSource } from 'client';

import { DataSourceExternalInput, DataSourceMapping, DataSourceMappingControlKeys, ExternalInfo, idBasedDSTypes } from './data-source-model';

export const DataSourceIdTo = '_id';
export const DataSourceIdLabel = 'Id';

export const DataSourceDisplayTo = '_display';
export const DataSourceDisplayLabel = 'Display';

export type DataSourceEditorDefaults = {
	sort: string | null;
	filter: AstNode | null;
	mappings: DataSourceMapping[] | null;
	externalInputs: DataSourceExternalInput[] | null;
};

export const hasMappings = (dataSourceType?: DataSourceType | null): boolean =>
	dataSourceType != null && [
		DataSourceType.Collection,
		DataSourceType.Bucket,
		DataSourceType.Users,
		DataSourceType.UserClaims,
		DataSourceType.Company,
		DataSourceType.External,
	].includes(dataSourceType);

export const hasSort = (dataSourceType?: DataSourceType | null): boolean =>
	dataSourceType != null && [
		DataSourceType.Collection,
		DataSourceType.Bucket,
		DataSourceType.Users,
		DataSourceType.Company,
	].includes(dataSourceType);

export const hasFilter = (dataSourceType?: DataSourceType | null): boolean =>
	dataSourceType != null && [
		DataSourceType.Collection,
		DataSourceType.Bucket,
		DataSourceType.Users,
		DataSourceType.Company,
	].includes(dataSourceType);

export const hasVisibleFilters = (dataSourceType?: DataSourceType | null, fieldType?: FieldType | null): boolean =>
	hasFilter(dataSourceType) && fieldType === FieldType.Lookup;

export const hasFindBy = (dataSourceType?: DataSourceType | null): boolean =>
	dataSourceType != null && [
		DataSourceType.Collection,
		DataSourceType.Bucket,
		DataSourceType.Users,
	].includes(dataSourceType);

export const isValidTypeAndIdConfiguration = (dataSourceType?: DataSourceType | null, id?: string | null): boolean =>
	dataSourceType != null &&
    (!idBasedDSTypes.includes(dataSourceType) || id != null);

export const isValueExpression = (value?: string | null): boolean =>
	value ? /{{.*}}*/g.test(value) : false;

export const getAttribute = (mapping: UfControlGroup, fieldIdentifier?: string): string =>
	`${fieldIdentifier ? fieldIdentifier + '.' : ''}${mapping.get(DataSourceMappingControlKeys.To)?.value}`;

export const getDataSourceDefaults = (fieldType: FieldType, type: DataSourceType | null, id: string | null, mappableProperties: DataPropertyDescriptor[], externalInfo?: ExternalInfo): DataSourceEditorDefaults => {

	const mappableMap = new Map<string, DataPropertyDescriptor>();

	for (const prop of mappableProperties) {
		mappableMap.set(prop.identifier, prop);
	}

	const defaults: DataSourceEditorDefaults = {
		sort: null,
		filter: null,
		mappings: null,
		externalInputs: null,
	};

	if (!isValidTypeAndIdConfiguration(type, id)) {
		return defaults;
	}

	switch (type) {

		case DataSourceType.Bucket: {
			defaults.sort = FormDefinitionMetadataIdentifiers.SeqId;

			const formSeqIdDProperty = mappableMap.get(FormDefinitionMetadataIdentifiers.SeqId);
			const formIdProperty = mappableMap.get(FormDefinitionMetadataIdentifiers.Id);

			if (formSeqIdDProperty && formIdProperty) {
				defaults.mappings = [{
					uuid: generateUUID(),
					from: formIdProperty,
					type: formIdProperty.type,
					to: DataSourceIdTo,
					label: DataSourceIdLabel,
				}, {
					uuid: generateUUID(),
					from: formSeqIdDProperty,
					type: formSeqIdDProperty.type,
					to: DataSourceDisplayTo,
					label: DataSourceDisplayLabel,
					isVisible: true,
				}, {
					uuid: generateUUID(),
					from: formSeqIdDProperty,
					type: formSeqIdDProperty.type,
					to: formSeqIdDProperty.identifier,
					label: formSeqIdDProperty.label,
					isVisible: true,
				}];
			}
			break;
		}

		case DataSourceType.UserClaims: {
			const claimIdProperty: DataPropertyDescriptor = {
				identifier: 'id',
				type: FieldType.Text,
				label: DataSourceIdLabel,
				display: `${DataSourceIdLabel} (id)`,
				asDisplay: true,
				asSearch: false,
				asSort: false,
				asInputFilter: false,
				asStaticFilter: false,
			};

			const claimDisplayProperty: DataPropertyDescriptor = {
				identifier: 'display',
				type: FieldType.Text,
				label: DataSourceDisplayLabel,
				display: `${DataSourceDisplayLabel} (display)`,
				asDisplay: true,
				asSearch: false,
				asSort: false,
				asInputFilter: false,
				asStaticFilter: false,
			};

			defaults.mappings = [{
				uuid: generateUUID(),
				from: claimIdProperty,
				type: claimIdProperty.type,
				to: DataSourceIdTo,
				label: claimIdProperty.label,
			}, {
				uuid: generateUUID(),
				from: claimDisplayProperty,
				type: claimDisplayProperty.type,
				to: DataSourceDisplayTo,
				label: claimDisplayProperty.label,
			}];
			break;
		}

		case DataSourceType.Collection: {
			const collectionIdProperty = mappableMap.get(CollectionItemMetadataIdentifiers.Id);
			const collectionTitleProperty = mappableMap.get(CollectionItemMetadataIdentifiers.Title);

			if (collectionIdProperty && collectionTitleProperty) {
				defaults.sort = collectionTitleProperty.identifier;

				defaults.mappings = [{
					uuid: generateUUID(),
					from: collectionIdProperty,
					type: collectionIdProperty.type,
					to: DataSourceIdTo,
					label: collectionIdProperty.label,
				}, {
					uuid: generateUUID(),
					from: collectionTitleProperty,
					type: collectionTitleProperty.type,
					to: DataSourceDisplayTo,
					label: collectionTitleProperty.label,
				}];
			}
			break;
		}

		case DataSourceType.Users: {
			const userIdProperty = mappableMap.get(UserInfoIdentifiers.Id);
			const userUsernameProperty = mappableMap.get(UserInfoIdentifiers.Username);

			if (userIdProperty && userUsernameProperty) {
				defaults.mappings = [{
					uuid: generateUUID(),
					from: userIdProperty,
					type: userIdProperty.type,
					to: DataSourceIdTo,
					label: DataSourceIdLabel,
				}, {
					uuid: generateUUID(),
					isFromExpression: true,
					fromExpression: DataSourceUserFullNameExpression,
					type: userIdProperty.type,
					to: DataSourceDisplayTo,
					label: DataSourceDisplayLabel,
				}, {
					uuid: generateUUID(),
					from: userUsernameProperty,
					type: userUsernameProperty.type,
					to: userUsernameProperty.identifier,
					label: userUsernameProperty.label,
				}];

				defaults.filter = {
					type: NodeType.Combinator,
					op: 'and',
					args: [{
						op: 'eq',
						type: NodeType.Operator,
						args: [{
							type: NodeType.Identifier,
							value: 'isActive',
						}, {
							type: NodeType.Value,
							value: true,
						}],
					}],
				};

				defaults.sort = UserInfoIdentifiers.Username;
			}
			break;
		}

		case DataSourceType.External:
			if (externalInfo != null) {
				defaults.mappings = [];
				const fromId = mappableMap.get(DataSourceIdTo);

				if (fromId) {
					defaults.mappings.push({
						to: DataSourceIdTo, label: fromId.label, from: fromId, type: fromId.type, uuid: generateUUID(),
					});
				}

				const fromDisplay = mappableMap.get(DataSourceDisplayTo);

				if (fromDisplay) {
					defaults.mappings.push({
						to: DataSourceDisplayTo, label: fromDisplay.label, from: fromDisplay, type: fromDisplay.type, uuid: generateUUID(),
					});
				}

				defaults.externalInputs = buildInputArgumentsToArray(externalInfo);
			}
			break;
	}

	// _display mapping is defaulted true for Repeat field
	const displayMapping = defaults.mappings?.find((m) => m.to === DataSourceDisplayTo);

	if (displayMapping && fieldType === FieldType.Repeat) {
		displayMapping.isVisible = true;
	}

	return defaults;
};

export const buildInputArgumentsToArray = (externalInfo: ExternalInfo, dataSource?: UcDefinitionDataSource): DataSourceExternalInput[] => {
	const input = externalInfo.feature.input;

	if (!input?.attributes) {
		return [];
	}

	const attributes = input.attributes;

	return Object.keys(input.attributes).reduce<DataSourceExternalInput[]>((externalInputs, key) => {
		const inputArg = attributes[key] as IntegrationFeatureArg;
		const inputMap = externalInfo.dataSource.inputMap[key];

		if (![IntegrationFeatureArgType.Object, IntegrationFeatureArgType.List].includes(inputArg.kind)) {
			const type = mapArgType(inputArg.kind);

			if (!type) {
				return externalInputs;
			}

			externalInputs.push({
				info: {
					type,
					parameter: key,
					source: inputMap?.source as DataSourceInputValueSource,
					required: false,
					placeholder: inputMap?.source === DataSourceInputValueSource.Default && inputMap?.value ? `\\${inputMap?.value}\\` : undefined,
				},
				value: dataSource?.inputs && canChangeInputValue(key, externalInfo) ? dataSource.inputs[key] : undefined,
			});
		}

		return externalInputs;

	}, []);
};

export const mapArgType = (kind: IntegrationFeatureArgType): DataSourceInputType | undefined => {
	switch (kind) {
		case IntegrationFeatureArgType.String:
			return DataSourceInputType.Text;
		case IntegrationFeatureArgType.Number:
			return DataSourceInputType.Number;
		default:
			return undefined;
	}
};

const canChangeInputValue = (key: string, externalInfo: ExternalInfo) => {
	const inputMap = externalInfo.dataSource.inputMap[key];

	return !inputMap || [DataSourceInputValueSource.Form, DataSourceInputValueSource.Default].includes(inputMap.source);
};
