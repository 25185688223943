import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { DataDescriptor, DataDisplayListItem, DataLookupService, DataPropertyInfoService, FormDefinitionMetadataIdentifiers, Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { FormData, isNotNull } from '@unifii/sdk';

export interface MetadataModalData {
	formData: FormData;
	bucketDataDescriptor: DataDescriptor;
}

@Component({
	selector: 'uc-metadata-modal',
	templateUrl: './metadata-modal.html',
	standalone: false,
})
export class MetadataModalComponent implements Modal<MetadataModalData, void>, OnInit {

	@HostBinding('class.uc-form-card') class = true;

	runtime = inject(ModalRuntime<MetadataModalData, void>);
	data = inject(ModalData) as MetadataModalData;

	protected items: DataDisplayListItem[];

	private dataLookupService = inject(DataLookupService);
	private metadataProperties = Object.values(inject(DataPropertyInfoService).formDefinitionReferences);

	ngOnInit() {

		this.items = (this.metadataProperties
			.filter((property) => ![FormDefinitionMetadataIdentifiers.Parent, FormDefinitionMetadataIdentifiers.History].includes(property.identifier as FormDefinitionMetadataIdentifiers))
			.map((property) => this.data.bucketDataDescriptor.propertyDescriptorsMap.get(property.identifier))
			.filter(isNotNull))
			.map((descriptor) => ({
				term: descriptor.label,
				data: this.dataLookupService.lookupData(this.data.formData, descriptor.identifier),
			}));
	}

	close() {
		this.runtime.close();
	}

}
