import { Component, Injector, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ModalService, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { DataSource, DataSourceType, FieldTemplate, FieldType, generateUUID } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { DataSourceEditorComponent } from 'components/field-builder/data-source-editor/data-source-editor.component';
import { DataSourceEditorCache } from 'components/field-builder/data-source-editor/data-source-model';
import { DialogsService } from 'services/dialogs.service';

import { FormEditorCache } from '../form-editor-cache';
import { FORM_EDITOR_CONSTANTS } from '../form-editor-constants';
import { FieldControlKeys, OptionControlKeys } from '../form-editor-control-keys';
import { FormEditorFormCtrl } from '../form-editor-form-ctrl';
import { FormEditorFunctions } from '../form-editor-functions';
import { AttributeParentType, FormFieldMetadata } from '../form-editor-model';
import { FormEditorStatus } from '../form-editor-status';

import { FormFieldVariationComponent } from './form-field-variation.component';

@Component({
	selector: 'uc-form-field-options',
	templateUrl: './form-field-options.html',
	standalone: false,
})
export class FormFieldOptionsComponent implements OnInit, OnDestroy {

	@Input({ required: true }) type: FieldType;
	@Input({ required: true }) meta: FormFieldMetadata;
	@Input({ required: true }) options: UfControlArray;
	@Input({ required: true }) dataSourceConfig: UfControl;
	@Input({ required: true }) template: UfControl;
	@Input({ required: true }) id: UfControl;
	@Input({ required: true }) identifier: UfControl;
	@Input({ required: true }) dataCaptures: UfControl;
	@Input() avoidDuplicates: UfControl | null;

	protected readonly fieldTypes = FieldType;
	protected readonly fieldKeys = FieldControlKeys;
	protected readonly optionKeys = OptionControlKeys;
	protected readonly identifierWarningLength = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH;
	protected readonly identifierMaxLength = inject(FormEditorStatus).identifiersMaxLength.option;
	protected expandeds: boolean[] = [];
	protected dataSourceName: string | undefined; // cached computation value
	protected showContent = false;
	protected showAlignmentOptions = false;

	private subscriptions = new Subscription();
	private optionType: AttributeParentType = inject(FormFieldVariationComponent, { optional: true }) ? AttributeParentType.VariationOptionType : AttributeParentType.FieldOptionType;
	private fb = inject(FormEditorFormCtrl);
	private modalService = inject(ModalService);
	private injector = inject(Injector);
	private dialogs = inject(DialogsService);
	private cache = inject(FormEditorCache);

	get dataSource(): DataSource | undefined {
		return this.dataSourceConfig?.value as DataSource;
	}

	get isInvalid(): boolean {
		return this.options.invalid || !!this.dataSourceConfig?.invalid;
	}

	get canEditDataSource(): boolean {
		return !!this.dataSource?.type && ![DataSourceType.Company, DataSourceType.Named].includes(this.dataSource.type);
	}

	async ngOnInit() {
		// Options
		this.expandeds = Array(this.options.length).fill(false);
		if ([FieldType.MultiChoice, FieldType.Choice].includes(this.type)) {
			this.subscriptions.add(this.template.valueChanges.subscribe((v) => this.updateShowFlags(v)));
			this.updateShowFlags(this.template.value);
		}

		// DataSource
		this.dataSourceName = await FormEditorFunctions.getDataSourceName(this.cache, this.dataSource);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected async deleteDataSource() {

		if (this.dataSourceConfig == null) {
			return;
		}

		if (!await this.dialogs.confirmDelete()) {
			return;
		}

		this.dataSourceConfig.setValue(null, { emitEvent: true });
		this.dataSourceConfig.markAsTouched();
	}

	protected async editDataSource() {

		const dataSource = await this.modalService.openFullScreen(
			DataSourceEditorComponent, {
				fieldType: this.type,
				fieldIdentifier: this.identifier.value,
				fieldId: this.id.value,
				dataSource: this.dataSource ?? null,
				dataCaptures: this.dataCaptures.getRawValue(),
			},
			{ guard: true },
			[{ provide: DataSourceEditorCache, useValue: this.cache }],
			this.injector,
		);

		if (!dataSource) {
			return;
		}

		this.dataSourceConfig.setValue(dataSource);
		this.dataSourceConfig.markAsTouched();
		this.dataSourceName = await FormEditorFunctions.getDataSourceName(this.cache, this.dataSource);
	}

	protected getOptionLabel(option: UfControlGroup): string {

		if (this.type !== FieldType.Bool) {
			return option.get(OptionControlKeys.Name)?.value;
		}

		const identifier = option.get(OptionControlKeys.Identifier)?.value as string;
		const labelPrefix = identifier.charAt(0).toUpperCase() + identifier.substring(1, identifier.length);

		return `${labelPrefix} - ${option.get(OptionControlKeys.Name)?.value}`;
	}

	protected showIdentifierWarningLength(option: UfControlGroup): boolean {
		const identifierControl = option.get(OptionControlKeys.Identifier) as UfControl;
		const length = ((identifierControl.value) ?? '').length;

		return length > this.identifierWarningLength && !identifierControl.showError;
	}

	protected addOption() {
		this.expandeds.push(true);
		this.options.push(this.fb.buildOptionControl(this.meta, this.optionType, {
			uuid: generateUUID(),
			identifier: null as any,
			name: null as any,
		}));
	}

	protected async removeOption(index: number) {
		if (!await this.dialogs.confirmDelete()) {
			return;
		}

		this.expandeds.splice(index, 1);
		this.options.removeAt(index);
	}

	private updateShowFlags(template: string | null) {

		if (!template) {
			this.showContent = false;
			this.showAlignmentOptions = false;

			return;
		}

		this.showContent = [FieldTemplate.OptionWithContent, FieldTemplate.CheckboxWithContent, FieldTemplate.RadioWithContent].includes(template as FieldTemplate);
		this.showAlignmentOptions = [FieldTemplate.OptionWithContent, FieldTemplate.CheckboxWithContent, FieldTemplate.RadioWithContent].includes(template as FieldTemplate);
	}

}
