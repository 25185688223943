import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { TokenService } from 'client';
import { MfaComponentNavigationState, isMfaComponentNavigationState } from 'pages/user-access/mfa.component';

/**
 * Ensure token and state set for Mfa Route
 */
export const mfaGuard: CanActivateFn = () => {

	const router = inject(Router);
	const tokenService = inject(TokenService);
	const state = router.getCurrentNavigation()?.extras.state as MfaComponentNavigationState | undefined;

	if ((!tokenService.limitedToken && !tokenService.token) || !isMfaComponentNavigationState(state)) {
		void router.navigate(['/']);

		return false;
	}

	return true;
};
