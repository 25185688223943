<div class="uf-box col-12">
	<div class="uf-app-bar flat accent">
		<div class="title">Columns</div>
		<uc-expander-controls [container]="expandAllContainer" class="primary" />
	</div>

	<div #expandAllContainer class="uc-group-content pad-sides pad-bottom pad-sm-top">
		@if (cols.length) {
			<uc-drag-list [items]="cols" (moved)="emit()" canDrop="false" canReorder="true">
				@for (colInfo of cols; track colInfo; let i = $index) {
					<uf-expander [isExpanded]="false" dragItem class="uf-box draggable flat gap-sm-top">
						<div expanderHeader dragHandle class="uf-app-bar">
							<uf-icon [name]="colInfo.icon" />
							<div class="grow title primary">{{ colInfo.label }}</div>
							@if (!!parentControl.get(i + "")?.invalid) {
								<uf-icon name="error" title="Column configuration is invalid" class="error" />
							}
							@if (!colInfo.exists) {
								<span class="uf-lozenge error">Missing</span>
							}
							@if (hasVariations(colInfo)) {
								<uf-icon name="variation" title="This node has variations" />
							}
							<button (click)="removeColumn(i)" title="Delete" type="button" class="uf-action tertiary">
								<uf-icon name="delete" />
							</button>
						</div>
						<ng-container expanderBody>
							@if (colInfo.exists) {
								<uc-column-descriptor-editor [descriptor]="colInfo.dataPropertyDescriptor" [index]="i" [parentControl]="parentControl" [(column)]="colInfo.descriptor" (columnChange)="emit()" />
							}
						</ng-container>
					</uf-expander>
				}
			</uc-drag-list>
		}

		<uf-search-result [options]="columnResults" (valueChange)="addColumn({ identifier: $event.identifier })" (searchChange)="filterColumns($event)" placeholder="Search column" nameProperty="display" class="gap-top" />

		<div class="row gap-top">
			<button (click)="addCustom()" type="button" class="uf-button right">Add Custom</button>
		</div>
	</div>
</div>
