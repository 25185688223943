import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouteReuseStrategy } from '@angular/router';

import { WorkflowDataForwardersFormComponent, WorkflowFormDataFormComponent, WorkflowNotificationComponent, WorkflowRuleFormComponent, WorkflowTimersFormComponent } from 'pages';
import { AppDetailsComponent } from 'pages/apps/app-details.component';
import { CollectionBuilderComponent } from 'pages/content/collections/collection-builder.component';
import { CollectionItemBuilderComponent } from 'pages/content/collections/collection-item-builder.component';
import { PageBuilderComponent } from 'pages/content/pages/page-builder.component';
import { ViewBuilderComponent } from 'pages/content/views/view-builder.component';
import { BucketComponent } from 'pages/form-data/bucket.component';
import { FormEditorComponent } from 'pages/form-editor';
import { ExternalCallDetailsComponent } from 'pages/project-settings';
import { AuthProviderComponent } from 'pages/system-settings/identity';
import { IntegrationDetailComponent } from 'pages/system-settings/integrations/integration-detail.component';
import { IntegrationFeatureComponent } from 'pages/system-settings/integrations/integration-feature.component';
import { TableConfigurationComponent } from 'pages/tables/tables';
import { MyAccountComponent } from 'pages/users';
import { UserComponent } from 'pages/users/user.component';

@Injectable()
export class ConsoleRouteReuseStrategy extends RouteReuseStrategy {

	shouldDetach() {
		return false;
	}

	store() {
		/* */
	}

	shouldAttach() {
		return false;
	}

	retrieve() {
		return null;
	}

	// eslint-disable-next-line complexity
	shouldReuseRoute(active: ActivatedRouteSnapshot, next: ActivatedRouteSnapshot): boolean {
		switch (next.component) {
			case BucketComponent:
			case AppDetailsComponent:
			case PageBuilderComponent:
			case CollectionBuilderComponent:
			case ViewBuilderComponent:
			case FormEditorComponent:
			case CollectionItemBuilderComponent:
			case WorkflowRuleFormComponent:
			case WorkflowTimersFormComponent:
			case WorkflowNotificationComponent:
			case WorkflowDataForwardersFormComponent:
			case ExternalCallDetailsComponent:
			case UserComponent:
			case WorkflowFormDataFormComponent:
			case TableConfigurationComponent:
				return next.params.id === active.params.id;
			case IntegrationDetailComponent:
			case IntegrationFeatureComponent:
			case AuthProviderComponent:
			case MyAccountComponent:
				return false;
			default:
				return active.params.id === next.params.id && active.routeConfig === next.routeConfig;
		}
	}

}
