<div class="stretch-component col">
	<div [ngClass]="config.env" class="tenant-nav row space-children center-all pad-sm-sides">
		<a [routerLink]="['/']" class="logo">
			<img src="/assets/svg/menu-logo.svg" alt="Unifii" class="unifii-logo" />
		</a>

		<div restrict="ProjectManager,ContentEditor,FormDesigner,Publisher,FormDataViewer,WorkflowDesigner" class="row center-all">
			<div>
				<a [routerLink]="['/projects']" restrict="ProjectManager,ContentEditor,FormDesigner,Publisher,FormDataViewer,WorkflowDesigner" class="uf-button tertiary"> Projects </a>
			</div>
			@if ((projectMenuConfig.contextMenuConfig?.length || 0) > 1) {
				<a (click)="openContextMenu($event, projectMenuConfig)" label="Select Project" class="uf-action tertiary">
					<uf-icon name="arrowDownSolid" />
				</a>
			}
		</div>

		<div class="grow"></div>

		<div class="row">
			@for (config of menuConfigs; track config) {
				@if (config.contextMenuConfig?.length) {
					<uc-menu-item [restrict]="config.restrict" [label]="config.label" [icon]="config.icon" (click)="openContextMenu($event, config)" />
				} @else {
					<uc-menu-item [restrict]="config.restrict" [label]="config.label" [icon]="config.icon" [routerLink]="config.link" />
				}
			}
		</div>
	</div>
	<div class="row grow">
		<uc-project-nav />
		<div class="grow console-content">
			<router-outlet />
		</div>
	</div>
</div>
