<uf-panel class="container">
	<div class="header-pane uf-app-bar transparent">
		<uc-builder-header />
	</div>

	<div class="content-pane">
		<uf-tabs (selectedChange)="updateTitle()">
			<uf-tab [label]="tabLabels.General" path="general" />
			<uf-tab [label]="tabLabels.Theme" path="theming" />
			<uf-tab [label]="tabLabels.DataSources" path="data-sources" />
			<uf-tab [label]="tabLabels.DataTransactions" path="data-transactions" />
		</uf-tabs>
	</div>
</uf-panel>
