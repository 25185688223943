@if (loaded && c.cropper) {
	<div>
		<span>minWidth: {{ c.cropper.minWidth }} minHeight: {{ c.cropper.minHeight }} ratio:{{ c.cropper.ratio }}</span
		><br />
		<span>{{ c.cropper.measures | json }}</span>
		<span>Image Cropper Test {{ c.cropper.overlay | json }}</span>
		@if (c.cropper.overlay) {
			<span> ratio > {{ c.cropper.overlay.height / c.cropper.overlay.width }}</span>
		}
	</div>
}

<button (click)="removeCrop($event)" type="button">Remove Crop</button>
<div style="padding: 20px"></div>
<input [(ngModel)]="scale" type="text" />
@if (!enabled) {
	<h2>Crop disabled</h2>
}
<div style="padding: 20px"></div>
<uc-image-cropper [src]="imageURL" [minWidth]="minWidth" [minHeight]="minHeight" [enabled]="enabled" [size]="size" [(value)]="value" />
