import { Component, HostBinding, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { AppInfo } from '@unifii/sdk';

@Component({
	selector: 'uc-required-update-modal',
	templateUrl: './required-update-modal.html',
	standalone: false,
})
export class RequiredUpdateModalComponent implements Modal<AppInfo, null> {

	@HostBinding('class.uf-form-card') cardClass = true;

	runtime = inject<ModalRuntime<AppInfo, null>>(ModalRuntime);
	data = inject<AppInfo>(ModalData);

}
