import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UcCollection, UcDefinition, UcProject } from 'client';

// TODO any better way to provide collection service to components?
export const collectionServiceFactory = (route: ActivatedRoute, ucProject: UcProject) => {
	const id = route.snapshot.params.id;

	if (id === 'new') {
		return;
	}

	const service = new CollectionService();

	service.ucCollection = ucProject.collection(id);
	service.definitionLoadPromise = service.ucCollection.getDefinition();
	service.definitionLoadPromise.then((d) => service.definition = d);

	return service;
};

/**
 * Provided at CollectionComponent level
 * Centralize and share the collection status across sub-routes components
 */
@Injectable()
export class CollectionService {

	edited: boolean;
	ucCollection: UcCollection;
	definitionLoadPromise: Promise<UcDefinition>;

	private _definition: UcDefinition;
	private _copy: UcDefinition;

	set definition(v: UcDefinition) {
		this._definition = v;
		this._copy = this._definition ? JSON.parse(JSON.stringify(this._definition)) : null;
	}

	get definition(): UcDefinition {
		return this._definition;
	}

	restore() {
		this.definition = this._copy;
		this.edited = false;
	}

}
