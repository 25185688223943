<h4>Inputs</h4>
<table class="uf-table inputs accent clickable">
	<thead>
		<tr>
			<th>Parameter</th>
			<th>Source</th>
			<th>Type</th>
			<!-- TODO Required field is gonna be added later on next releases -->
			<!-- <th>
      <div class="row">
        Required
        <uf-help content="This parameter is required, please provide a value"></uf-help>
      </div>
    </th> -->
			<th>Value or Expression</th>
		</tr>
	</thead>
	<tbody>
		@for (externalInput of externalInputs.controls | asUfControlsGroups; track externalInput; let i = $index) {
			<tr [formGroup]="externalInput" (click)="edit(i)" class="body-row">
				@if (getInfo(i); as info) {
					<td>
						@if (info.required === true) {
							<span>* </span>
						}
						{{ info.parameter }}
					</td>
					<td>{{ info.source }}</td>
					<td>{{ info.type }}</td>
					<!-- TODO Required field is gonna be added later on next releases -->
					<!-- <td>
        <uf-icon *ngIf="info.required" name="tick"></uf-icon>
      </td> -->
					<td>
						{{ externalInput.get(externalInputsKeys.Value)?.value }}
						@if (externalInput.get(externalInputsKeys.Value)?.invalid) {
							<span class="uf-lozenge error">Required</span>
						}
					</td>
				}
			</tr>
		}
	</tbody>
</table>
