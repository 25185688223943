@if (!error && ready && definition && compound) {
	<uc-builder-header />
	<div class="content">
		<div class="data-container uf-box flat">
			<div class="uf-app-bar flat">
				<span class="title">Data</span>
				@if (definition.fields?.length) {
					<uc-expander-controls />
				}
			</div>
			<uf-panel class="container">
				<uf-form #form [definition]="displayDefinition" [config]="config" [(formData)]="compound">
					<div header class="uf-grid pad-sides pad-top">
						@if (rootControl) {
							@if (rootControl.controls._title; as titleControl) {
								<uf-text [control]="titleControl | asUfControl" [(value)]="compound._title" label="Title" class="col-12" />
							}
							@if (rootControl.controls.consoleName; as consoleNameControl) {
								<uf-text [control]="consoleNameControl | asUfControl" [label]="consoleNameLabel" [(value)]="compound._consoleName" class="col-12" />
							}
						}
					</div>
				</uf-form>
			</uf-panel>
		</div>
		<uf-panel class="preview-container uf-box flat">
			<div class="uf-app-bar flat accent">
				<span class="title">Preview</span>
			</div>
			@if (displayDefinition && displayCompound) {
				<uf-panel class="container">
					<uf-collection-item [definition]="displayDefinition" [compound]="displayCompound" />
				</uf-panel>
			}
		</uf-panel>
	</div>
}

<uc-error-message [error]="error" />
