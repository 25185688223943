import { InjectionToken } from '@angular/core';
import { Dictionary } from '@unifii/sdk';

export interface Config {
	title: string;
	version: string;
	env: 'localhost' | 'dev' | 'uat' | 'prod';
	appId: string;
	appSecret: string;
	baseUrl: string;
	operationsCloudUrl: string;
	sentryDSN: string;
	translationsUrl: string;
	debug?: boolean;
	compatibility?: string;
	flags?: Dictionary<boolean>; // enable features
}

export const Config = new InjectionToken<Config>('Config');

export class ConsoleOptions implements Config {

	readonly platform = 'web';

	constructor(private config: Config) { }

	get title(): string {
		return this.config.title;
	}

	get appVersion(): string {
		return this.config.version;
	}

	get version(): string {
		return this.config.version;
	}

	get env(): 'localhost' | 'dev' | 'uat' | 'prod' {
		return this.config.env;
	}

	get appId(): string {
		return this.config.appId;
	}

	get appSecret(): string {
		return this.config.appSecret;
	}

	get baseUrl(): string {
		return this.config.baseUrl;
	}

	get operationsCloudUrl(): string {
		return this.config.operationsCloudUrl;
	}

	get sentryDSN(): string {
		return this.config.sentryDSN;
	}

	get translationsUrl(): string {
		return this.config.translationsUrl;
	}

	get debug(): boolean | undefined {
		return this.config.debug;
	}

	get compatibility(): string | undefined {
		return this.config.compatibility;
	}

	get flags(): Dictionary<boolean> {
		return this.config.flags ?? {};
	}

}
