import { Component, HostBinding } from '@angular/core';
import { DisplayService } from '@unifii/library/smart-forms/display';
import { AttachmentsClient, Client, ExternalDataSourcesClient, ProjectContentOptions, ProjectContentOptionsInterface, PublishedContent } from '@unifii/sdk';

import { UcContentClient } from 'services/console-content.service';

export const createExternalDataSource = (client: Client, projectContentOptions: ProjectContentOptionsInterface) =>
	new ExternalDataSourcesClient(client, projectContentOptions);

@Component({
	selector: 'uc-project',
	templateUrl: './project.html',
	providers: [
		DisplayService,
		{ provide: PublishedContent, useClass: UcContentClient },
		{ provide: ExternalDataSourcesClient, useFactory: createExternalDataSource, deps: [Client, ProjectContentOptions] },
		{
			provide: AttachmentsClient,
			useFactory: (client: Client, projectContentOptions: ProjectContentOptionsInterface) => new AttachmentsClient(client, projectContentOptions),
			deps: [Client, ProjectContentOptions],
		},
	],
	standalone: false,
})
export class ProjectComponent {

	@HostBinding('class.stretch-component') class = true;

}
