import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UfControl, UfControlArray, UfControlGroup, UfFormControl } from '@unifii/library/common';
import { FieldTemplate, FieldType, FieldWidth, Option } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ArrayHelper } from 'helpers/array-helper';
import { sortGroupControlValue } from 'pages/utils';
import { ContextService } from 'services/context.service';

import { FormEditorCache } from '../form-editor-cache';
import { FORM_EDITOR_CONSTANTS } from '../form-editor-constants';
import { FieldControlKeys, OptionControlKeys } from '../form-editor-control-keys';
import { FormEditorFunctions } from '../form-editor-functions';
import { FormEditorColumnVisibility, FormEditorField, FormFieldMetadata } from '../form-editor-model';

@Component({
	selector: 'uc-form-field-display',
	templateUrl: './form-field-display.html',
	standalone: false,
})
export class FormFieldDisplayComponent implements OnInit, OnDestroy {

	@Input({ required: true }) control: UfControlGroup;

	protected readonly fieldKeys = FieldControlKeys;
	protected readonly fieldTypes = FieldType;
	protected readonly fieldWidths = FieldWidth;
	protected readonly columnCountOptions = FORM_EDITOR_CONSTANTS.COLUMN_COUNT_OPTIONS;
	protected readonly layoutDirectionOptions = FORM_EDITOR_CONSTANTS.LAYOUT_DIRECTION_OPTIONS;
	protected meta: FormFieldMetadata;
	protected ready: boolean;
	protected subscriptions = new Subscription();
	protected controls: UfFormControl[];
	protected rolesResult: string[] = [];
	protected showHelpText: boolean;
	protected showPlaceholder: boolean;
	protected showColumnCount: boolean;
	protected showLayoutDirection: boolean;
	protected showColumnVisibility: boolean;
	protected showItemLabel: boolean;
	protected widthOptions: Option[];

	private context = inject(ContextService);
	private cache = inject(FormEditorCache);

	protected get field(): FormEditorField {
		return this.control.getRawValue() as FormEditorField;
	}

	protected get isInvalid(): boolean {
		return this.controls.find((c) => c.invalid) != null;
	}

	protected get fields(): UfControlGroup[] {
		if (!this.meta.isContainer) {
			return [];
		}

		return (this.control.get(FieldControlKeys.Fields) as UfControlArray).controls as UfControlGroup[];
	}

	ngOnInit() {
		this.meta = FormEditorFunctions.controlMetadata(this.control, this.context);
		this.showHelpText = this.meta.help && this.field.type !== FieldType.Content;
		this.widthOptions = FormEditorFunctions.fieldWidthOptions(this.meta);
		this.controls = FORM_EDITOR_CONSTANTS.SECTION_DISPLAY_FIELDS.map((k) => this.control.get(k) as UfFormControl).filter((c) => c != null);

		this.subscriptions.add(this.control.get(FieldControlKeys.Template)?.valueChanges.subscribe(() => this.onAttributesChange()));
		this.subscriptions.add(this.control.get(FieldControlKeys.ColumnCount)?.valueChanges.subscribe(() => this.onAttributesChange()));
		this.onAttributesChange();

		/* Enable once TemplateConfigManager is added
        if (this.meta.columnVisibility && this.templateConfigManager != null) {
            this.templateConfigManager.set(this.field);
        }

        if(this.field.type === FieldType.Bool) {
            if (this.templateConfigManager != null) {
                this.templateConfigManager.set(this.field);
            }
        }
        */

		sortGroupControlValue(this.control, FieldControlKeys.VisibleTo);

		this.ready = true;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected async findRoles(query: string | null) {
		const roles = (await this.cache.getRoles()).map((r) => r.name);

		this.rolesResult = ArrayHelper.filterList(roles, query ?? undefined);
	}

	// eslint-disable-next-line complexity
	protected onAttributesChange() {

		this.showPlaceholder = true;

		if (this.field.template == null) {
			this.showColumnCount = false;
			this.showLayoutDirection = false;
			this.showColumnVisibility = false;
			this.showItemLabel = true;

			return;
		}

		switch (this.field.type) {
			case FieldType.Bool:
				this.showColumnCount = [FieldTemplate.Radio, FieldTemplate.BoolTickCross].includes(this.field.template);
				this.showPlaceholder = this.field.template === FieldTemplate.DropDown;
				break;
			case FieldType.Choice:
				this.showColumnCount = [FieldTemplate.Radio, FieldTemplate.RadioWithContent, FieldTemplate.OptionWithContent].includes(this.field.template);
				this.showLayoutDirection = this.showColumnCount && (this.field.columnCount ?? 0) > 1;
				this.showPlaceholder = this.field.template === FieldTemplate.DropDown;
				break;
			case FieldType.MultiChoice:
				this.showColumnCount = this.field.template !== FieldTemplate.Chips;
				this.showLayoutDirection = (this.field.columnCount ?? 0) > 1;
				this.showPlaceholder = this.field.template === FieldTemplate.Chips;
				if (!this.showPlaceholder) {
					this.control.get(FieldControlKeys.Placeholder)?.setValue(null, { onlySelf: true, emitEvent: true });
				}
				break;
			case FieldType.Repeat:
				this.showColumnVisibility = [FieldTemplate.HorizontalTable, FieldTemplate.HorizontalTableMobile].includes(this.field.template);
				if (this.showColumnVisibility && !this.field.columnVisibility) {
					this.control.get(FieldControlKeys.ColumnVisibility)?.setValue({
						hideFromColumnsOnDesktop: [],
						hideFromColumnsOnMobile: [],
					}, { onlySelf: false, emitEvent: false });
				}
				this.showItemLabel = this.field.template === FieldTemplate.Form;
				break;
		}

		if ([FieldType.Choice, FieldType.MultiChoice].includes(this.field.type)) {
			const options = this.control.get(FieldControlKeys.Options) as UfControlArray;

			if (options.length) {
				if ([FieldTemplate.Radio, FieldTemplate.Checkbox, FieldTemplate.DropDown].includes(this.field.template)) {
					for (const c of options.controls) {
						c.get(OptionControlKeys.Content)?.setValue(null, { onlySelf: false, emitEvent: false });
					}
				}
			}
		}

		if (this.field.type === FieldType.Repeat && this.field.template !== FieldTemplate.Form && this.field.fields) {
			const fields = (this.control.get(FieldControlKeys.Fields) as UfControlArray).controls as UfControlGroup[];

			for (const field of fields) {
				field.get(FieldControlKeys.Width)?.setValue(null, { onlySelf: false, emitEvent: false });
			}
		}
	}

	/** uf-checkbox [value]="!xxx" reflect a wrong (valueChange) $event
     * that's why here value is update via !currentValue
     */
	protected updateShowFlag(onMobile: boolean, childIdentifier: string) {

		const control = this.control.get(FieldControlKeys.ColumnVisibility) as UfControl | null;

		if (control) {
			const columnVisibility = control.value as FormEditorColumnVisibility;

			if (onMobile) {
				columnVisibility.hideOnMobile[childIdentifier] = !columnVisibility.hideOnMobile[childIdentifier];
			} else {
				columnVisibility.hideOnDesktop[childIdentifier] = !columnVisibility.hideOnDesktop[childIdentifier];
			}

			control.setValue(columnVisibility, { emitEvent: true, onlySelf: false });
		}
	}

}
