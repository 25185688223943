import { Injectable, inject } from '@angular/core';
import { CollectionDataDescriptorAdapterLoader } from '@unifii/library/common';
import { Definition } from '@unifii/sdk';

import { UcProject } from 'client';

@Injectable()
export class UcCollectionDataDescriptorAdapterLoader implements CollectionDataDescriptorAdapterLoader {

	private ucProject = inject(UcProject);

	loadCollectionDefinition(identifier: string): Promise<Definition> {
		return this.ucProject.collection(identifier).getDefinition() as Promise<Definition>;
	}

}
