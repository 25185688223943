<div class="row">
	@if (control.invalid) {
		<uf-icon [class.error]="!selected" name="error" title="This field configuration is invalid" />
	}
	@if (hasShowIf) {
		<uf-icon [ngClass]="{ white: selected, primary: !selected }" name="showIf" title="show if" class="gap-sm-sides" />
	}
	@if (isRequired) {
		<uf-icon [ngClass]="{ white: selected, primary: !selected }" name="required" title="required" class="gap-sm-sides" />
	}
	@if (isHidden) {
		<uf-icon [ngClass]="{ white: selected, primary: !selected }" name="viewHidden" title="hidden" class="gap-sm-sides" />
	}
	@if (hasTags) {
		<uf-icon [ngClass]="{ white: selected, primary: !selected }" name="tagged" title="tags" class="gap-sm-sides" />
	}
	@if (hasAutofill) {
		<uf-icon [ngClass]="{ white: selected, primary: !selected }" name="autofill" title="autofill" class="gap-sm-sides" />
	}
	@if (hasBindTo) {
		<uf-icon [ngClass]="{ white: selected, primary: !selected }" name="link" title="bind to" class="gap-sm-sides" />
	}
	@if (hasVariations) {
		<uf-icon [ngClass]="{ white: selected, primary: !selected }" name="variation" title="variations" class="gap-sm-sides" />
	}
	@if (isReportable) {
		<uf-icon [ngClass]="{ white: selected, primary: !selected }" name="reportable" title="reportable" class="gap-sm-sides" />
	}
	@if (isSearchable) {
		<uf-icon [ngClass]="{ white: selected, primary: !selected }" name="searchable" title="searchable" class="gap-sm-sides" />
	}
	@if (hasDescription) {
		<uf-icon [ngClass]="{ white: selected, primary: !selected }" name="notes" title="description" class="gap-sm-sides" />
	}
</div>
