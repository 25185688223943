import { Injectable, inject } from '@angular/core';
import { DataDisplayLozengeValue, DataDisplayService, TableConfigColumn } from '@unifii/library/common';
import { DataType, TableSourceType } from '@unifii/sdk';

import { DefinitionInfo, FormDefinitionInfo, TableInfo } from 'client';
import { ConsoleNameContentProperty, ConsoleNameLabel, ConsoleNameProperty } from 'constant';
import { PublishedStatusColour } from 'pipes';
import { TableSourceTypeLabelPipe } from 'pipes/table-source-type-label.pipe';
import { Info, InfoType } from 'services/table/models';

@Injectable({ providedIn: 'root' })
export class InfoColumnFactory {

	private tableSourceTypePipe = inject(TableSourceTypeLabelPipe);
	private dataDisplayService = inject(DataDisplayService);
	private publishedStatusColourPipe = inject(PublishedStatusColour);

	create(type: InfoType): TableConfigColumn<Info>[] {
		switch (type) {
			case InfoType.Form:
				return [...this.createFormColumns() as unknown as TableConfigColumn<Info>[], ...this.createStatusColumns()];
			case InfoType.Table:
				return [...this.createTableColumns() as unknown as TableConfigColumn<Info>[], ...this.createStatusColumns()];
			case InfoType.Collection:
				return [...this.createCollectionColumns() as unknown as TableConfigColumn<Info>[]];
			case InfoType.CollectionData:
				return [...this.createCollectionDataColumns() as unknown as TableConfigColumn<Info>[], ...this.createStatusColumns()];
			case InfoType.Page:
			case InfoType.View:
				return [...this.createViewOrPageColumns(), ...this.createStatusColumns()];
		}
	}

	private createTableColumns(): TableConfigColumn<TableInfo>[] {
		return [{
			name: 'id',
			label: 'Id',
			sortable: true,
			hidden: true,
		}, {
			name: 'identifier',
			label: 'Identifier',
			sortable: true,
			hidden: true,
		}, {
			name: 'title',
			label: 'Title',
			sortable: true,
		}, {
			name: ConsoleNameProperty,
			label: ConsoleNameLabel,
			sortable: true,
		}, {
			name: 'description',
			label: 'Description',
		}, {
			name: 'sourceType',
			label: 'Type',
			value: (item) => this.tableSourceTypePipe.transform(item.sourceType),
		}, {
			name: 'source',
			label: 'Source',
			sortable: true,
		}, {
			name: 'hasDetail',
			label: 'Detail',
			value: (item) => {
				if (item.hasDetail === true) {
					return 'Details Page';
				}
				switch (item.sourceType) {
					case TableSourceType.Bucket: return 'Form';
					case TableSourceType.Company: return 'Company';
					default: return 'User';
				}
			},
		},
		];
	}

	private createFormColumns(): TableConfigColumn<FormDefinitionInfo>[] {
		return [{
			name: 'identifier',
			label: 'Identifier',
			sortable: true,
			hidden: true,
		}, {
			name: 'name',
			label: 'Title',
			sortable: true,
		}, {
			name: 'bucket',
			label: 'Form Data Repository',
			sortable: true,
		}, {
			name: ConsoleNameProperty,
			label: ConsoleNameLabel,
			sortable: true,
		}, {
			name: 'sequenceNumberFormat',
			label: 'Form Number Format',
			sortable: false,
			hidden: true,
		}];
	}

	private createCollectionColumns(): TableConfigColumn<DefinitionInfo>[] {
		return [{
			name: 'identifier',
			label: 'Identifier',
			sortable: false,
			hidden: true,
		}, {
			name: 'name',
			label: 'Title',
			sortable: false,
		}, {
			name: ConsoleNameProperty,
			label: ConsoleNameLabel,
			sortable: false,
		}];
	}

	private createCollectionDataColumns(): TableConfigColumn<DefinitionInfo>[] {
		return [{
			name: 'id',
			label: 'Id',
			sortable: false,
			hidden: true,
		}, {
			name: '_title',
			label: 'Title',
			sortable: true,
		}, {
			name: ConsoleNameContentProperty,
			label: ConsoleNameLabel,
			sortable: true,
		}];
	}

	private createViewOrPageColumns(): TableConfigColumn<Info>[] {
		return [{
			name: 'definitionIdentifier',
			label: 'Identifier',
			sortable: true,
			hidden: true,
		}, {
			name: '_title',
			label: 'Title',
			sortable: true,
		}, {
			name: ConsoleNameContentProperty,
			label: ConsoleNameLabel,
			sortable: true,
		}];
	}

	private createStatusColumns(): TableConfigColumn<Info>[] {
		return [{
			name: 'lastModifiedAt',
			label: 'Last Modified',
			sortable: true,
			value: (item) =>
				this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
		}, {
			name: 'lastModifiedBy',
			label: 'Modified By',
			sortable: true,
			hidden: true,
			value: (item) => item.lastModifiedBy?.username,
		}, {
			name: 'lastPublishedAt',
			label: 'Last Published',
			sortable: true,
			value: (item) =>
				this.dataDisplayService.displayAsString(item.lastPublishedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
		}, {
			name: 'lastPublishedBy',
			label: 'Published By',
			sortable: true,
			hidden: true,
			value: (item) => item.lastPublishedBy?.username,
		}, {
			name: 'publishState',
			label: 'State',
			value: (item) => ({
				label: item.publishState,
				colour: this.publishedStatusColourPipe.transform(item.publishState),
			} satisfies DataDisplayLozengeValue),
		}];
	}

}

