import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UfControl, UfControlGroup } from '@unifii/library/common';

import { IntegrationFeature, IntegrationInfo } from 'client';

import { DataSourceControlKeys } from '../../model';

@Component({
	selector: 'uc-data-feature-selector',
	templateUrl: './data-feature-selector.html',
	standalone: false,
})
export class DataFeatureSelectorComponent implements OnInit {

	@Input({ required: true }) control: UfControlGroup;
	@Input({ required: true }) isNew: boolean;
	@Input({ required: true }) integrations: IntegrationInfo[];
	@Input() features: IntegrationFeature[] | undefined;
    
	@Output() integrationChanged = new EventEmitter<IntegrationInfo>();
	@Output() featureChanged = new EventEmitter<IntegrationFeature>();
    
	protected featureControl: UfControl;
	protected integrationControl: UfControl;
	protected integration: IntegrationInfo | null;

	ngOnInit() {
		this.featureControl = this.control.get(DataSourceControlKeys.Feature) as UfControl;
		this.integrationControl = this.control.get(DataSourceControlKeys.Integration) as UfControl;
		this.integration = this.integrationControl.getRawValue();
	}

	protected changeIntegration(integration: IntegrationInfo) {
		this.integration = integration;
		this.integrationChanged.emit(integration);
	}

}
