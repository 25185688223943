import { Injectable, inject } from '@angular/core';
import { PermissionAction } from '@unifii/sdk';
import { ProvisioningFormProviderPermissionInfo, UserFormContextActionType, UserFormProvider, UserKeys } from '@unifii/user-provisioning';

import { SystemRole } from 'client';
import { ContextService } from 'services/context.service';

@Injectable()
export class UcUserFormProvider implements UserFormProvider {

	private readonly tenantRequiredFields: string[] = inject(ContextService).tenantSettings?.isUserEmailRequired ? [UserKeys.Email] : [];
	private readonly updateUserRequiredFields = [...this.tenantRequiredFields, UserKeys.FirstName, UserKeys.LastName] as string[];
	private readonly addUserRequiredFields = [...this.tenantRequiredFields, UserKeys.Username, UserKeys.FirstName, UserKeys.LastName, UserKeys.Password] as string[];
	private readonly updateMeRequiredFields = [...this.tenantRequiredFields, UserKeys.FirstName, UserKeys.LastName] as string[];
	private readonly userMfaFields = [UserKeys.IsMfaOptional, UserKeys.Mfa] as string[];

	private context = inject(ContextService);

	getReadUserPermissionInfo(): ProvisioningFormProviderPermissionInfo {
		return { granted: true };
	}

	canReadUserField(): boolean { return true; }

	getEditUserPermissionInfo(): ProvisioningFormProviderPermissionInfo {
		return { granted: true };
	}

	canEditUserField(field: string): boolean {

		// restrict mfa if user is not a system manager
		// eslint-disable-next-line sonarjs/prefer-single-boolean-return
		if (this.userMfaFields.includes(field) && !this.context.checkRoles(SystemRole.SystemManager)) {
			return false;
		}

		return true;
	}

	isEditUserFieldRequired(field: string, action: UserFormContextActionType): boolean {
		return action === PermissionAction.Update ?
			this.updateUserRequiredFields.includes(field) :
			this.addUserRequiredFields.includes(field);
	}

	getReadMePermissionInfo(): ProvisioningFormProviderPermissionInfo {
		return { granted: true };
	}

	canReadMeField(): boolean { return true; }

	getUpdateMePermissionInfo(): ProvisioningFormProviderPermissionInfo {
		return {
			granted: true,
		};
	}

	isUpdateMeFieldRequired(field: string): boolean {
		return this.updateMeRequiredFields.includes(field);
	}

	canUpdateMeField(): boolean {
		return true;
	}

	canListUsers(): boolean { return true; }

	canListRoles(): boolean { return true; }

	canListCompanies(): boolean { return true; }

	canListClaims(): boolean { return true; }

	canListHierarchies(): boolean { return true; }

}
