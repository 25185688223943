<uf-panel class="container">
	<div class="header-pane uf-app-bar transparent">
		<uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large" />
	</div>

	<div class="content-pane">
		<uf-tabs>
			@for (tab of tabs; track tab) {
				<uf-tab [label]="tab.label" [path]="tab.path" />
			}
		</uf-tabs>
	</div>
</uf-panel>
