import { Component, Input, OnInit, inject } from '@angular/core';
import { DataDisplayListItem, UfControlGroup } from '@unifii/library/common';
import { TableSourceType } from '@unifii/sdk';

import { UcRoles, UcTable } from 'client';
import { ConsoleNameLabel } from 'constant';
import { ArrayHelper } from 'helpers/array-helper';
import { TableSourceTypeLabelPipe } from 'pipes';

import { TableConfigurationKeys } from '../table-configuration.controller';

@Component({
	selector: 'uc-table-configuration-details',
	templateUrl: 'table-configuration-details.html',
	standalone: false,
})
export class TableConfigurationDetailsComponent implements OnInit {

	@Input({ required: true }) form: UfControlGroup;

	protected readonly controlKeys = TableConfigurationKeys;
	protected readonly consoleNameLabel = ConsoleNameLabel;
	protected filteredRoles: string[];
	protected detailsKeyValue: DataDisplayListItem[];

	private roles: string[];
	private tableSourceTypeLabelPipe = inject(TableSourceTypeLabelPipe);
	private ucRoles = inject(UcRoles);

	ngOnInit() {
		const table = this.form.getRawValue() as UcTable;

		this.detailsKeyValue = [{ term: 'Source Type', data: this.tableSourceTypeLabelPipe.transform(table.sourceType) }];
		if (table.sourceType === TableSourceType.Bucket) {
			this.detailsKeyValue.push({ term: 'Source', data: table.source });
		}

		void this.loadRoles();
	}

	protected filterRoles(query: string | null) {
		this.filteredRoles = ArrayHelper.filterList(this.roles, query ?? undefined);
	}

	private async loadRoles() {
		this.roles = (await this.ucRoles.get(undefined, undefined, { params: { limit: 1000, offset: 0 } })).map((role) => role.name);
	}

}
