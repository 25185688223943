@if (control) {
	<!-- Home -->
	@if (isHome) {
		<div [class.accent]="isSelected" (click)="select($event)" class="uf-app-bar flat clickable">
			<uf-icon [name]="icon" />
			@if (isHomeEmpty) {
				<div class="title primary">Select a home page</div>
			} @else {
				<div class="title primary">{{ node.name || "Home" }}</div>
				@if (control.invalid) {
					<uf-icon name="error" class="error" title="This node configuration is invalid" />
				}
				@if (!isPublished) {
					<uf-icon [title]="notPublishedMessage" name="unpublished" class="icon-warning" />
				}
				@if (isAccessRestricted) {
					<uf-icon name="lock" class="icon-warning" title="This node access is restricted" />
				}
				@if (hasVariations) {
					<uf-icon name="variation" title="This node has variations" />
				}
				@if (control.enabled) {
					<a [title]="status.showHiddenNodes ? 'Show hidden nodes' : 'Hide hidden nodes'" (click)="status.showHiddenNodes = !status.showHiddenNodes" restrict="SuperUser" class="uf-action">
						<uf-icon [name]="status.showHiddenNodes ? 'view' : 'viewHidden'" />
					</a>
				}
			}
			<button [class.tertiary]="!isSelected" [class.primary]="isSelected" (click)="expand($event)" type="button" title="Expand All" class="uf-action">
				<uf-icon name="arrowDown" />
			</button>
			<button [class.tertiary]="!isSelected" [class.primary]="isSelected" (click)="collapse($event)" type="button" class="uf-action" title="Collapse All">
				<uf-icon name="arrowUp" />
			</button>
			@if (!isHomeEmpty && !control.disabled) {
				<button (click)="remove($event)" type="button" class="uf-action" title="Delete">
					<uf-icon name="delete" />
				</button>
			}
		</div>
	}
	<!-- Leaf node -->
	@if (!isHome && !children.length) {
		<div [class.uf-box]="!isSelected" [class.accent]="isSelected" (click)="select($event)" class="uf-app-bar flat draggable gap-sm-bottom">
			<uf-icon [name]="icon" />
			<div class="title primary">{{ node.name }}</div>
			@if (control.invalid) {
				<uf-icon name="error" class="error" title="This node configuration is invalid" />
			}
			@if (!isPublished) {
				<uf-icon [title]="notPublishedMessage" name="unpublished" class="icon-warning margin--horizontal" />
			}
			@if (isAccessRestricted) {
				<uf-icon name="lock" class="icon-warning" title="This node access is restricted" />
			}
			@if (node.isHidden) {
				<uf-icon name="viewHidden" class="icon-warning" title="This node is hidden" />
			}
			@if (control.disabled) {
				<uf-icon [name]="node.isHidden ? 'viewHidden' : 'view'" [title]="node.isHidden ? 'Hidden' : 'Visible'" restrict="SuperUser" />
			}
			@if (!control.disabled) {
				<button (click)="remove($event)" type="button" class="uf-action" title="Delete">
					<uf-icon name="delete" />
				</button>
			}
		</div>
	}
	<!-- Parent node -->
	@if (!isHome && !!children.length) {
		<uf-expander class="uf-box flat draggable gap-sm-bottom">
			<div [class.accent]="isSelected" (click)="select($event)" expanderHeader class="uf-app-bar flat draggable">
				<uf-icon [name]="icon" />
				<div class="title primary">{{ node.name }}</div>
				@if (control.invalid) {
					<uf-icon name="error" class="error" title="This node configuration is invalid" />
				}
				@if (!isPublished) {
					<uf-icon [title]="notPublishedMessage" name="unpublished" class="icon-warning margin--horizontal" />
				}
				@if (isAccessRestricted) {
					<uf-icon name="lock" class="icon-warning" />
				}
				@if (node.isHidden) {
					<uf-icon name="viewHidden" class="icon-warning" title="This node is hidden" />
				}
				@if (control.disabled) {
					<uf-icon [name]="node.isHidden ? 'viewHidden' : 'view'" [title]="node.isHidden ? 'Hidden' : 'Visible'" restrict="SuperUser" />
				}
				@if (!control.disabled) {
					<button (click)="remove($event)" title="Delete" type="button" class="uf-action">
						<uf-icon name="delete" />
					</button>
				}
			</div>
			<uc-drag-list [parent]="children" [items]="children.controls" [canDrop]="service.canDrop" [convertAdded]="service.addConverter" [drop]="service.drop" expanderBody class="gap-left gap-sm-top">
				@for (child of children.controls | asUfControlsGroups; track child) {
					<uc-node-field [dragDisabled]="child.disabled" [nestable]="true" [control]="child" [isParentHidden]="showAsHidden" class="gap-sm-bottom" dragItem />
				}
			</uc-drag-list>
		</uf-expander>
	}
}
