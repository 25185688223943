@if (fm.variations && field.variations?.length) {
	<div class="grid">
		<uf-select [options]="field.variations" [value]="variation" (valueChange)="variationChange($event)" class="col-1of1" label="Variation" nameProperty="name" placeholder="Default" />
	</div>
}

@if (variation || field) {
	<uf-expander [isExpanded]="expanded" class="uf-box flat gap--bottom-small">
		<div expanderHeader class="uf-app-bar flat">
			<span class="title primary">Settings</span>
			@if (invalids.settings) {
				<uf-icon [class.icon-warning-submitted]="field.isSubmitted" name="warning" class="icon-warning" />
			}
		</div>
		<div expanderBody class="uc-group-content">
			<uc-field-settings [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)" />
		</div>
	</uf-expander>
	@if (visibleGroups.display) {
		<uf-expander class="uf-box flat gap--bottom-small">
			<div expanderHeader class="uf-app-bar flat">
				<span class="title primary">Display</span>
				@if (invalids.display) {
					<uf-icon [class.icon-warning-submitted]="field.isSubmitted" name="warning" class="icon-warning" />
				}
			</div>
			<div expanderBody class="uc-group-content">
				<uc-field-display [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)" />
			</div>
		</uf-expander>
	}
	@if (visibleGroups.visibility) {
		<uf-expander class="uf-box flat gap--bottom-small">
			<div expanderHeader class="uf-app-bar flat">
				<span class="title primary">Visibility</span>
				@if (invalids.visibility) {
					<uf-icon [class.icon-warning-submitted]="field.isSubmitted" name="warning" class="icon-warning" />
				}
			</div>
			<div expanderBody class="uc-group-content">
				<uc-field-visibility [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)" />
			</div>
		</uf-expander>
	}
	@if (visibleGroups.nested) {
		<uf-expander class="uf-box flat gap--bottom-small">
			<div expanderHeader class="uf-app-bar flat">
				<span class="title primary">Visible Fields</span>
				@if (invalids.nested) {
					<uf-icon [class.icon-warning-submitted]="field.isSubmitted" name="warning" class="icon-warning" />
				}
			</div>
			<div expanderBody class="uc-group-content">
				<uc-field-nested [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)" />
			</div>
		</uf-expander>
	}
	@if (visibleGroups.options) {
		<uf-expander class="uf-box flat gap-sm-bottom small">
			<div expanderHeader class="uf-app-bar flat">
				<span class="title primary">Options</span>
				@if (invalids.options) {
					<uf-icon [class.icon-warning-submitted]="field.isSubmitted" name="warning" class="icon-warning" />
				}
				@if ((variation || field).options?.length) {
					<uc-expander-controls [container]="optionsWrap" />
				}
			</div>
			<div #optionsWrap expanderBody class="col pad-sm-top">
				<uc-field-options [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)" />
				@if (field.type !== fieldType.Bool) {
					<div class="row pad-sm">
						<button (click)="addOption()" type="button" class="uf-button x-small right">Add Option</button>
					</div>
				}
			</div>
		</uf-expander>
	}
	@if (visibleGroups.validators) {
		<uf-expander class="uf-box flat gap--bottom-small">
			<div expanderHeader class="uf-app-bar flat">
				<span class="title primary">Validators</span>
				@if (invalids.validators) {
					<uf-icon [class.icon-warning-submitted]="field.isSubmitted" name="warning" class="icon-warning" />
				}
				@if (field.validators?.length) {
					<uc-expander-controls [container]="validatorsWrap" />
				}
			</div>
			<div #validatorsWrap expanderBody class="uc-group-content grid">
				<uc-field-validators [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)" class="col-1of1" />
				<div class="col-1of1 row">
					<button (click)="addValidator()" type="button" class="uf-button x-small right">Add Validator</button>
				</div>
			</div>
		</uf-expander>
	}
}
