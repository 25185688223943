"use strict";

exports.getRoundingMethod = getRoundingMethod;
function getRoundingMethod(method) {
  return number => {
    const round = method ? Math[method] : Math.trunc;
    const result = round(number);
    // Prevent negative zero
    return result === 0 ? 0 : result;
  };
}