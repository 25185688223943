@if (!control.disabled) {
	<uf-autocomplete [label]="label" [options]="findByResults" [(value)]="findByProperty" (valueChange)="encodeFindBy()" (searchChange)="search($event)" nameProperty="display" placeholder="Search..." class="col-12 gap-top-sm" />
}
<uf-error [control]="control" />

@if (control.disabled && findByProperty) {
	<uf-message icon="errorSolid" class="col-12 grow small error">
		<h3>Search Target Error</h3>
		<p>Search Target requires Data Capture</p>
		<button (click)="reset()" type="button" class="uf-button primary small">Clear</button>
	</uf-message>
}
