import { Injectable, inject } from '@angular/core';

import { UcProject, UcTable } from 'client';

@Injectable()
export class StructureEditorCache {

	private tables = new Map<string, Promise<UcTable | null>>();
	private ucProject = inject(UcProject);

	getTable(identifier: string): Promise<UcTable | null> {

		return new Promise((resolve) => {

			const stored = this.tables.get(identifier);

			if (stored !== undefined) {
				resolve(stored);

				return;
			}

			try {
				const loaded = this.ucProject.getTable(identifier).catch(() => null);

				this.tables.set(identifier, loaded);
			} catch (e) {
				console.warn('StructureEditorCache table not found for identifier', identifier, e);
				this.tables.set(identifier, Promise.resolve(null));
			} finally {
				resolve(this.tables.get(identifier) ?? null);
			}

			resolve(null);
		});
	}

}
