import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UfRequestError, ensureUfRequestError } from '@unifii/sdk';
import { StatusCodes } from 'http-status-codes';

import { TokenService, UcClient } from 'client';

import { ContextService } from '../services/context.service';

export const canActivateAccount: CanActivateFn = async(_, state: RouterStateSnapshot) => {

	const client = inject(UcClient);
	const tokenService = inject(TokenService);
	const router = inject(Router);
	const context = inject(ContextService);

	const params = state.url !== '/' ? { next: state.url } : {};

	if (tokenService.token == null) {
		void router.navigate(['/login', params]);

		return false;
	}

	const isUpdate = context.account != null;
	let getAccountError: UfRequestError | undefined;
	let result: boolean | undefined;
	let raceTimeoutId: NodeJS.Timeout | undefined;

	try {
		const promises = [client.getMyAccount()];

		if (isUpdate) {
			promises.push(new Promise((_resolve, rej) => { raceTimeoutId = setTimeout(() => rej(), 500); }));
		}

		const getAccountPromise = Promise.race(promises);

		context.account = await getAccountPromise;

		clearTimeout(raceTimeoutId);

	} catch (error) {
		getAccountError = ensureUfRequestError(error);
	} finally {
		// When you try to access a root url or children of root, with a expired token, the redirect to password-reset below supersedes the previous redirect to login.
		// hence we redirect to login to avoid this edge case.
		if (getAccountError?.code === StatusCodes.UNAUTHORIZED) {
			void router.navigate(['/login', params]);
			result = false;
		} else if (
			(!isUpdate && getAccountError) ||
            result != null && context.account?.changePasswordOnNextLogin
		) {
			void router.navigate(['/password-reset', params]);
			result = false;
		}

		if (result == null) {
			result = true;
		}
	}

	return result;
};
