import { AstNode, NodeType, hasLengthAtLeast } from '@unifii/sdk';

export const getUserQueryBuilderEmptyAstNode = (): AstNode => {
	return {
		type: NodeType.Combinator,
		op: 'and',
		args: [{
			type: NodeType.Operator,
			op: 'contains',
			args: [{
				type: NodeType.Identifier,
				value: 'roles',
			}, {
				type: NodeType.Value,
				value: [],
			}],
		}],
	};
};

export const isUserQueryBuilderEmptyAstNode = (astNode: AstNode): boolean => {
	return !!(astNode.args &&
        hasLengthAtLeast(astNode.args, 1) &&
        astNode.args[0].args &&
        hasLengthAtLeast(astNode.args[0].args, 2) &&
        !astNode.args[0].args[1].value?.length);
};
