import { Component, inject } from '@angular/core';

import { SystemRole } from 'client';
import { TabConfig } from 'pages/page-models';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

@Component({
	selector: 'uc-assets',
	templateUrl: './assets.html',
	standalone: false,
})
export class AssetsComponent extends TabsPage {

	edited = false;

	protected breadcrumbs = inject(BreadcrumbService).getBreadcrumbs(this.route);
	protected tabs: TabConfig[] = [];

	private context = inject(ContextService);

	constructor() {
		super();
		if (this.context.checkRoles(SystemRole.AssetManager, SystemRole.ContentEditor, SystemRole.Publisher, SystemRole.ProjectManager)) {
			this.tabs.push({
				label: 'Media',
				path: 'media',
			});
		}
	}

}
