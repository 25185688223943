@if (ready) {
	<uf-expander class="uf-box flat">
		<div expanderHeader class="uf-app-bar flat">
			<div class="title primary">Display</div>
			@if (isInvalid) {
				<uf-icon name="error" class="error" />
			}
		</div>
		<div [formGroup]="control" expanderBody class="uf-grid pad-sides pad-bottom pad-sm-top">
			@if (showHelpText) {
				<uc-markdown [formControlName]="fieldKeys.Help" [showAlignmentOptions]="true" label="Help text" class="col-12" />
			}
			@if (meta.placeholder && showPlaceholder) {
				<uf-text [formControlName]="fieldKeys.Placeholder" label="Placeholder" class="col-12" />
			}
			@if (meta.itemLabel && showItemLabel) {
				<uf-text [formControlName]="fieldKeys.ItemLabel" label="Item label" class="col-12" />
			}
			@if (meta.addButtonLabel) {
				<uf-text [formControlName]="fieldKeys.AddButtonLabel" label="Add button label" class="col-12" />
			}
			@if (meta.columnVisibility && showColumnVisibility) {
				<div class="col-12">
					<label class="x-small">Column visibility</label>
					<div class="pad-sm-top">
						<ul class="uf-list uf-box flat column-toggles-list">
							@for (child of fields; track child) {
								@if (child.get(fieldKeys.Identifier)?.value; as childIdentifier) {
									<li class="row center-all space-children pad-sm-sides">
										<div class="grow">
											<p class="small">{{ child.get(fieldKeys.Label)?.value }}</p>
										</div>
										<uf-checkbox [value]="!$any(field.columnVisibility?.hideOnMobile)[childIdentifier]" (valueChange)="updateShowFlag(true, childIdentifier)" label="Mobile" />
										<uf-checkbox [value]="!$any(field.columnVisibility?.hideOnDesktop)[childIdentifier]" (valueChange)="updateShowFlag(false, childIdentifier)" label="Desktop" />
									</li>
								}
							}
						</ul>
					</div>
				</div>
			}
			<!-- TODO [disabled]="field.template === fieldTemplate.RadioWithContent" -->
			@if (meta.width) {
				<uf-select [formControlName]="fieldKeys.Width" [options]="widthOptions" label="Field width" valueProperty="identifier" nameProperty="name" class="col-12" />
			}
			<!-- TODO [disabled]="!field.width || field.width === fieldWidth.Stretch" -->
			@if (meta.breakAfter && field.width !== fieldWidths.Stretch) {
				<uf-checkbox [formControlName]="fieldKeys.BreakAfter" label="Break after" class="col-12" />
			}
			@if (meta.columnCount && showColumnCount) {
				<uf-select [formControlName]="fieldKeys.ColumnCount" [options]="columnCountOptions" label="Columns" valueProperty="identifier" nameProperty="name" class="col-12" />
			}
			@if (meta.layoutDirection && showLayoutDirection) {
				<uf-select [formControlName]="fieldKeys.LayoutDirection" [options]="layoutDirectionOptions" label="Direction" valueProperty="identifier" nameProperty="name" class="col-12" />
			}
			@if (meta.visibleTo) {
				<uf-chips [options]="rolesResult" [formControlName]="fieldKeys.VisibleTo" (searchChange)="findRoles($event)" label="Visible to" class="col-12" />
			}
		</div>
	</uf-expander>
}
