import { Component, Input, OnInit, inject } from '@angular/core';
import { UfControl, UfControlGroup } from '@unifii/library/common';
import { LockedConfig, UserFieldLabelService, UserKeys } from '@unifii/user-provisioning';

@Component({
	selector: 'uc-user-hierarchy',
	templateUrl: 'user-hierarchy.html',
	standalone: false,
})

export class UserHierarchyComponent implements OnInit {

	@Input({ required: true }) form: UfControlGroup;
	@Input({ required: true }) lockedConfig: LockedConfig | undefined;
	@Input() cssClasses: string | string[] | undefined | null;

	protected unitsControl: UfControl;
	protected label: string;

	private userFieldLabelService = inject(UserFieldLabelService);

	ngOnInit() {
		this.label = this.userFieldLabelService.labelDictionary[UserKeys.Units];
		this.unitsControl = this.form.get(UserKeys.Units) as UfControl;
	}

}
