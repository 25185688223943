@if (isNew && !error) {
	<div class="header-pane uf-app-bar transparent">
		<uf-breadcrumbs [breadcrumbs]="parent.breadcrumbs" class="large">
			@if (edited) {
				<span>*</span>
			}
		</uf-breadcrumbs>
		<div class="row space-children right">
			<a [routerLink]="['../']" type="button" class="uf-button tertiary"> Cancel </a>
			<uc-save-options [options]="saveOptions" (onSave)="save($event)" />
		</div>
	</div>
}

@if (app && form && !error) {
	<uf-panel class="content-pane container">
		<div class="uf-container-lg">
			<div class="uf-grid row-gap-lg pad-top">
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Details</div>
					</div>
					<div class="uf-grid pad">
						@if (form.controls.id) {
							<uf-text [control]="form.controls.id | asUfControl" [disabled]="!isNew" [(value)]="app.id" label="Id" class="col-6" />
						}
						@if (form.controls.name) {
							<uf-text [control]="form.controls.name | asUfControl" [(value)]="app.name" label="Name" class="col-6" />
						}
						<uf-data-display-list class="col-12">
							@if (app.createdBy?.username) {
								<dt>Created by</dt>
								<dd>{{ app.createdBy?.username }}</dd>
							}
							@if (app.createdAt) {
								<dt>Created at</dt>
								<dd>{{ app.createdAt | dataDisplay }}</dd>
							}
							@if (app.lastModifiedBy?.username) {
								<dt>Last modified by</dt>
								<dd>{{ app.lastModifiedBy?.username }}</dd>
							}
							@if (app.lastModifiedAt) {
								<dt>Last modified at</dt>
								<dd>{{ app.lastModifiedAt | dataDisplay }}</dd>
							}
						</uf-data-display-list>
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Authentication</div>
					</div>
					<div class="uf-grid pad">
						<uc-secret-input [(value)]="app.secret" (valueChange)="changeSecret($event)" label="API Key" class="col-12" />
					</div>
				</div>
				@if (arePushNotificationsEnabled) {
					<div class="uf-box col-12">
						<div class="uf-app-bar flat accent">
							<div class="title">Android Notification Settings</div>
							@if (app.googleKey) {
								<button (click)="downloadGoogleKey()" type="button" title="Download" class="uf-action">
									<uf-icon name="import" />
								</button>
							}
							@if (app.googleKey) {
								<button (click)="deleteGoogleKey()" type="button" title="Delete" class="uf-action">
									<uf-icon name="delete" />
								</button>
							}
						</div>
						<div class="uf-grid pad">
							<div class="col-12">
								@if (app.googleKey) {
									<uf-blockquote content="Key Uploaded" class="success" icon="successSolid" />
								}
								<uf-file [label]="app.googleKey ? 'Select a new key' : 'Select a key'" (valueChange)="updateGoogleKey($event)" class="padded--vertical" />
							</div>
						</div>
					</div>
					<div class="uf-box col-12">
						<div class="uf-app-bar flat accent">
							<div class="title">iOS Notification Settings</div>
							@if (showAppleCertDetails) {
								<button (click)="downloadAppleCertificate()" type="button" title="Download" class="uf-action">
									<uf-icon name="import" />
								</button>
							}
							@if (app.appleCert) {
								<button (click)="deleteAppleCert()" type="button" title="Delete" class="uf-action">
									<uf-icon name="delete" />
								</button>
							}
						</div>
						<div class="uf-grid pad">
							@if (showAppleCertDetails) {
								<uf-text [value]="app.appleCertName" disabled="true" label="Name" class="col-12" />
							}
							@if (showAppleCertDetails) {
								<uf-checkbox [(value)]="app.appleIsSandbox" label="Sandbox" class="col-12" />
							}
							@if (showAppleCertDetails && isAppleCertExpired) {
								<div class="col-12">
									<uf-blockquote content="Your certificate is expired on {{ app.appleCertNotAfter | date: 'medium' }}" class="error" icon="errorSolid" />
								</div>
							}
							@if (showAppleCertDetails && !isAppleCertExpired) {
								<div class="col-12">
									<uf-blockquote content="Your certificate is valid until {{ app.appleCertNotAfter | date: 'medium' }}" class="success" icon="successSolid" />
								</div>
							}
							<div class="col-12">
								<uf-file [label]="app.appleCertName ? 'Select a new certificate' : 'Select a certificate'" (valueChange)="updateAppleCertificate($event)" class="padded--vertical" />
							</div>
							@if (showAppleCertPreview) {
								<div class="col-12">
									<uf-blockquote icon="warning" content="Certificate selected, please provide its password." class="warning" />
								</div>
							}
							@if (uploadedAppleCert) {
								<uf-password [showReveal]="true" [(value)]="app.applePassword" label="Apple password" class="col-12" />
							}
							@if (uploadedAppleCert) {
								<uf-checkbox [(value)]="app.appleIsSandbox" label="Sandbox" class="col-12" />
							}
						</div>
					</div>
				}
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Cross-Origin Resource Sharing (CORS)</div>
					</div>
					@if (form.controls.corsOrigins) {
						<div class="uf-grid pad">
							@for (control of (form.controls.corsOrigins | asUfControlArray).controls; track control; let i = $index) {
								<div class="row col-12">
									<uf-text [label]="'Origin ' + (i + 1)" [control]="control | asUfControl" [(value)]="$any(app.corsOrigins)[i]" class="grow" />
									<button (click)="deleteCorsOrigin(i)" title="Delete" type="button" class="uf-action tertiary">
										<uf-icon name="delete" />
									</button>
								</div>
							}
							<div class="col-12 row">
								<button (click)="addCorsOrigin()" type="button" class="uf-button right">Add</button>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	</uf-panel>
}

<uc-error-message [error]="error" />
