import { AfterViewInit, Component } from '@angular/core';

import { ContentList, ModalSearchType } from 'components/content/content-list';
import { MediaSearchComponent } from 'components/content/modals/media-search.component';

@Component({
	selector: 'uc-file-list',
	templateUrl: './file-list.html',
	styleUrls: ['./group-input.less'],
	providers: [{ provide: ModalSearchType, useValue: MediaSearchComponent }],
	standalone: false,
})
export class FileListComponent extends ContentList implements AfterViewInit {

	protected getSearchConfig(): any {
		return {
			type: 'File',
			title: 'Add File(s)',
			multiSelect: true,
		};
	}

}
