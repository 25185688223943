import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Option } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ContextService } from 'services/context.service';

import { FieldControlKeys, VariationControlKeys } from '../form-editor-control-keys';
import { FormEditorFunctions } from '../form-editor-functions';
import { FormEditorField, FormFieldDetailSections, FormFieldMetadata } from '../form-editor-model';

@Component({
	selector: 'uc-form-field-configuration',
	templateUrl: './form-field-configuration.html',
	standalone: false,
})
export class FormFieldConfigurationComponent implements OnInit, OnDestroy {

	meta: FormFieldMetadata;
	visibles: FormFieldDetailSections;
	variationOptions: Option[] | null;
	selectedVariationOption: Option | null;
	variation: UfControlGroup | null;
	ready: boolean;

	private cdr = inject(ChangeDetectorRef);
	private context = inject(ContextService);
	private _control: UfControlGroup;
	private subscriptions = new Subscription();

	@Input({ required: true }) set control(v: UfControlGroup) {

		this.ready = false;
		this.cdr.detectChanges();

		this._control = v;
		this.meta = FormEditorFunctions.controlMetadata(this.control, this.context);
		this.visibles = FormEditorFunctions.fieldVisibleSections(this.meta);
		this.variation = null;
		this.selectedVariationOption = null;
		this.updateVariationOptions();
		this.ready = true;
	}

	get control(): UfControlGroup {
		return this._control;
	}

	get field(): FormEditorField {
		return this.control.getRawValue() as FormEditorField;
	}

	get dataSourceConfig(): UfControl {
		return this.control.get(FieldControlKeys.DataSourceConfig) as UfControl;
	}

	get dataCaptures(): UfControl {
		return this.control.get(FieldControlKeys.DataCaptures) as UfControl;
	}

	get avoidDuplicates(): UfControl {
		return this.control.get(FieldControlKeys.AvoidDuplicates) as UfControl;
	}

	get template(): UfControl {
		return this.control.get(FieldControlKeys.Template) as UfControl;
	}

	get id(): UfControl {
		return this.control.get(FieldControlKeys.Id) as UfControl;
	}

	get identifier(): UfControl {
		return this.control.get(FieldControlKeys.Identifier) as UfControl;
	}

	get options(): UfControlArray {
		return this.control.get(FieldControlKeys.Options) as UfControlArray;
	}

	get transitions(): UfControlArray {
		return this.control.get(FieldControlKeys.Transitions) as UfControlArray;
	}

	get variations(): UfControlArray {
		return this.control.get(FieldControlKeys.Variations) as UfControlArray;
	}

	get validators(): UfControlArray {
		return this.control.get(FieldControlKeys.Validators) as UfControlArray;
	}

	ngOnInit() {
		this.subscriptions.add(this.variations.valueChanges.subscribe(() => { this.updateVariationOptions(); }));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	selectVariation(variationOption?: Option) {
		if (!variationOption) {
			this.variation = null;

			return;
		}

		this.variation = this.variations.at(parseInt(variationOption.identifier)) as UfControlGroup;
	}

	private updateVariationOptions() {

		if (!this.meta.variations) {
			return;
		}

		this.variationOptions = this.variations.controls.map((c, i) => ({
			name: c.get(VariationControlKeys.Name)?.value as string,
			identifier: `${i}`,
		})).filter((option) => !ValidatorFunctions.isEmpty(option.name));
	}

}
