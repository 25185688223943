<div class="header">
	<button (click)="close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>{{ data.fieldType }} Data Source ({{ data.fieldIdentifier }})</h3>
</div>

@if (form) {
	<uf-panel class="container">
		<div [formGroup]="form" class="uf-grid pad">
			<ng-container class="col-12 uf-grid">
				@if (data.fieldId) {
					<uf-blockquote icon="warningSolid" content="Changing this datasource could cause a mismatch in any previously submitted forms." class="warning col-12" />
				}
				<uf-select [formControlName]="dataSourceControlKeys.Type" [options]="types" (valueChange)="typeChanged()" label="Type" placeholder="Select a type" valueProperty="identifier" nameProperty="name" class="col-6" />
				@if (resourceLabel) {
					<div class="col-6 row">
						<uf-autocomplete [label]="resourceLabel" [formControlName]="dataSourceControlKeys.Resource" [options]="resourceOptions" (valueChange)="resourceChanged()" (searchChange)="searchResource($event)" placeholder="Search..." nameProperty="name" class="grow" />
						<div class="gap-sm-top pad-md-top gap-left">
							<a [routerLink]="resourceLink" [style.visibility]="resourceLink ? 'visible' : 'hidden'" title="Open" target="_blank" class="uf-action tertiary">
								<uf-icon name="open" />
							</a>
						</div>
					</div>
				}
			</ng-container>
			@if (isSourceSelected) {
				<!-- External -->
				@if (status.externalInfo; as ext) {
					@if (ext.dataSource.description) {
						<uf-message [content]="ext.dataSource.description" icon="infoSolid" class="col-12 info" />
					}
					<uc-data-source-external-inputs [externalInputs]="externalInputsControl" class="col-12" />
				}
				@if (showMappings) {
					<uc-data-source-mappings [mappings]="mappingsControl" [properties]="status.mappableProperties" [fieldIdentifier]="data.fieldIdentifier" class="col-12" />
				}
				@if (showSort) {
					<uc-sort-property-input [properties]="status.sortableProperties" [formControlName]="dataSourceControlKeys.Sort" label="Sort" class="col-12 gap-top-sm" />
				}
				<uc-find-by-property-input [properties]="status.findByProperties" [formControlName]="dataSourceControlKeys.FindBy" label="Find By Field" class="col-12 gap-top-sm" />
				@if (showFilter) {
					@if (showVisibleFilters) {
						<uc-visible-filters [availableFilters]="status.inputFilterProperties" [filters]="visibleFilters" [parentControl]="form" (filtersChange)="updateVisibleFilters($event)" class="col-12" />
					}
					<div class="col-12 row">
						<div class="left compact">
							<h4 class="left">Hidden Filters</h4>
						</div>
						@if (isAdvancedFilter) {
							<a (click)="switchFilter()" title="Switch to standard" class="uf-action tertiary right">
								<uf-icon name="filter" />
							</a>
						}
						@if (!isAdvancedFilter) {
							<a (click)="switchFilter()" title="Switch to advanced" class="uf-action tertiary" right>
								<uf-icon name="custom" />
							</a>
						}
					</div>
					@if (!isAdvancedFilter) {
						<uc-filter-editor [dataProperties]="status.staticFilterProperties" [filter]="filter" [parentControl]="form" (filterChange)="updateFilter($event)" class="col-12" />
					}
					@if (isAdvancedFilter) {
						<uf-textarea [formControlName]="dataSourceControlKeys.AdvancedFilter" class="col-12" />
					}
				}
			}
		</div>
	</uf-panel>
}

<div class="row space-children pad">
	<button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
	<button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>
