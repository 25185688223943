import { Component, Input, inject } from '@angular/core';
import { DataDisplayListItem, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { CompoundType, StructureNodeVariation } from '@unifii/sdk';

import { FieldReferenceHelper } from 'helpers/helpers';
import { DialogsService } from 'services/dialogs.service';

import { StructureNodeVariationControlKeys } from '../structure-control-keys';
import { StructureEditorService } from '../structure-editor.service';
import { StructureFormCtrl } from '../structure-form-ctrl';
import { isNodeContentPublished } from '../structure-functions';

@Component({
	selector: 'uc-node-variations',
	templateUrl: './node-variations.html',
	standalone: false,
})
export class NodeVariationsComponent {

	@Input({ required: true }) control: UfControlArray;

	protected readonly variationControlKeys = StructureNodeVariationControlKeys;

	private service = inject(StructureEditorService);
	private sfb = inject(StructureFormCtrl);
	private dialogs = inject(DialogsService);

	protected async add() {

		// Select an HomePage node
		const node = await this.service.chooseHomePage();

		if (!node) {
			return;
		}

		delete node.children;

		const variationControl = this.sfb.buildNodeVariationControl(node);

		this.control.push(variationControl);
	}

	protected async remove(index: number) {
		if (await this.dialogs.confirmDelete()) {
			this.control.removeAt(index);
		}
	}

	protected getIcon(variation: UfControlGroup): string {
		return FieldReferenceHelper.getFieldReference(variation.value, CompoundType.Structure).icon;
	}

	protected getRoles(variation: UfControlGroup): string[] {
		return (variation.getRawValue() as StructureNodeVariation).roles ?? [];
	}

	protected getIsPublished(variation: UfControlGroup): boolean {
		return isNodeContentPublished(variation.getRawValue() as StructureNodeVariation);
	}

	protected getVariationRolesDescriptionItem(control: UfControlGroup): DataDisplayListItem[] | undefined {

		const roles = this.getRoles(control);

		if (!roles.length) {
			return;
		}

		return [{ term: 'Roles', data: roles.join(', ') }];
	}

}
