import { DataSourceLoader, SourceConfig } from '@unifii/library/common';
import { DataSeed } from '@unifii/sdk';

import { SchemaInfo, UcFormBucketClient } from 'client';

export class BucketFilterLoader implements DataSourceLoader {

	// TODO: Remove this after UNIFII-7371 - as this is not the ideal solution
	sourceConfig: SourceConfig = {} as unknown as SourceConfig;

	constructor(private ucFormBucketClient: UcFormBucketClient) { }

	getOptions(): never {
		throw new Error('method not implemented');
	}

	findAllBy(match: string): Promise<DataSeed[]> {
		console.warn('datasource does not support findByAll, falling back on search');

		return this.search(match);
	}

	async search(q?: string) {
		return (await this.ucFormBucketClient.list({ params: { q } })).map((info) => this.mapToSeed(info));
	}

	async get(id: string): Promise<DataSeed> {
		const schema = await this.ucFormBucketClient.get(id);

		return { _id: schema.bucket, _display: schema.bucket };
	}

	mapToSeed(info: SchemaInfo): DataSeed {
		return { _id: info.id, _display: info.id };
	}

}
