import { Injectable, inject } from '@angular/core';
import { ClientDeleteOptions, ClientGetOptions, ClientHeadOptions, ClientPostOptions, ClientPutOptions, HeaderKeys, ProjectContentOptions, ProjectContentOptionsInterface, TableDetail, amendOptionsParams, mergeParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';
import { PublishItem } from 'services/project-publisher';

import { UcCollection } from './collection';
import { BuilderField, CompoundInfo, DefinitionInfo, FormDefinitionInfo, TableInfo, UcDefinition, UcField, UcPage, UcTable, UcView } from './content-models';
import { UcDataForwarders } from './data-forwarders';
import { ApiKey, PublishStatus, UcProjectInfo } from './models';
import { UcClient } from './uc-client';
import { UcWorkflowNotifications } from './workflow-notification';

@Injectable({ providedIn: 'root' })
export class UcProject {

	// TODO make private
	client = inject(UcClient);
	// TODO make private
	options = inject(ProjectContentOptions) as ProjectContentOptionsInterface;

	collection(identifier: string): UcCollection {
		this.guardProjectId();

		return new UcCollection(this.client, +this.options.projectId, identifier);
	}

	workflowNotifications(bucket: string): UcWorkflowNotifications {
		this.guardProjectId();

		return new UcWorkflowNotifications(this.client, +this.options.projectId, bucket);
	}

	dataForwarders(bucket: string): UcDataForwarders {
		this.guardProjectId();

		return new UcDataForwarders(this.client, +this.options.projectId, bucket);
	}

	get(options?: ClientGetOptions): Promise<UcProjectInfo> {
		return this.client.get(this.url(), options) as Promise<UcProjectInfo>;
	}

	save(project: UcProjectInfo, options?: ClientPutOptions): Promise<UcProjectInfo> {
		return this.client.put(this.url(), project, options) as Promise<UcProjectInfo>;
	}

	async getCollections(options?: ClientGetOptions): Promise<DefinitionInfo[]> {
		const collections: DefinitionInfo[] = await this.client.get(this.url('collections'), options);

		// Remove local filter once the API support state
		if (options?.params?.state) {
			return collections.filter((collection) => (collection.publishState === options.params?.state));
		}

		return collections;
	}

	// TODO maybe combine with addForm
	saveCollection(collection: UcDefinition): Promise<UcDefinition> {
		if (!collection.id) {
			return this.client.post(this.url('collections'), { body: collection }) as Promise<UcDefinition>;
		}

		return this.client.put(this.url('collections', '' + collection.id), collection) as Promise<UcDefinition>;
	}

	/** qx, sort, status, offset, limit */
	getViews(options?: ClientGetOptions): Promise<CompoundInfo[]> {

		const params = mergeParams(DefaultPaginationParams, options?.params);

		if (params.q && !params.qx) {
			params.qx = params.q;
		}
		delete params.q;

		return this.client.get(this.url('views'), { ...options, params }) as Promise<CompoundInfo[]>;
	}

	/** qx, compoundType, types?: string[], offset, limit*/
	getCompounds(options?: ClientGetOptions): Promise<CompoundInfo[]> {

		const params = mergeParams(DefaultPaginationParams, options?.params);

		if (params.q && !params.qx) {
			params.qx = params.q;
		}
		delete params.q;

		if (params.types) {
			params.types = (params.types as string[]).join();
		}

		return this.client.get(this.url('compounds'), { ...options, params }) as Promise<CompoundInfo[]>;
	}

	getView(id: number, options?: ClientGetOptions): Promise<UcView> {
		return this.client.get(this.url('views', '' + id), options) as Promise<UcView>;
	}

	getViewDefinition(id: string, options?: ClientGetOptions): Promise<UcDefinition> {
		return this.client.get(this.url('views', id, 'definition'), options) as Promise<UcDefinition>;
	}

	saveView(view: UcView): Promise<UcView> {
		if (!view.id) {
			return this.client.post(this.url('views'), { body: view }) as Promise<UcView>;
		}

		return this.client.put(this.url('views', view.id), view) as Promise<UcView>;
	}

	approveView(id: number, options?: ClientPostOptions): Promise<UcView> {
		return this.approveViews([id], options).then((views) => views[0]) as Promise<UcView>;
	}

	approveViews(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
		return this.client.post(this.url('views', 'approved'), amendOptionsParams({ ids }, options)) as Promise<CompoundInfo[]>;
	}

	revertView(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
		return this.client.post(this.url('views', 'unapproved'), amendOptionsParams({ ids }, options)) as Promise<CompoundInfo[]>;
	}

	archiveView(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
		return this.client.post(this.url('views', 'archived'), amendOptionsParams({ ids }, options)) as Promise<CompoundInfo[]>;
	}

	deleteView(id: number, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url('views', '' + id), options) as Promise<void>;
	}

	/** q, sort, status, offset, limit*/
	getPages(options?: ClientGetOptions): Promise<CompoundInfo[]> {
		const params = mergeParams(DefaultPaginationParams, options?.params);

		return this.client.get(this.url('pages'), { ...options, params }) as Promise<CompoundInfo[]>;
	}

	getPage(id: number, options?: ClientGetOptions): Promise<UcPage> {
		return this.client.get(this.url('pages', '' + id), options) as Promise<UcPage>;
	}

	savePage(page: UcPage): Promise<UcPage> {
		if (!page.id) {
			return this.client.post(this.url('pages'), { body: page }) as Promise<UcPage>;
		}

		return this.client.put(this.url('pages', page.id), page) as Promise<UcPage>;
	}

	approvePage(id: number, options?: ClientPostOptions): Promise<UcPage> {
		return this.approvePages([id], options).then((pages) => pages[0]) as Promise<UcPage>;
	}

	approvePages(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
		return this.client.post(this.url('pages', 'approved'), amendOptionsParams({ ids }, options)) as Promise<CompoundInfo[]>;
	}

	revertPages(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
		return this.client.post(this.url('pages', 'unapproved'), amendOptionsParams({ ids }, options)) as Promise<CompoundInfo[]>;
	}

	archivePages(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
		return this.client.post(this.url('pages', 'archived'), amendOptionsParams({ ids }, options)) as Promise<CompoundInfo[]>;
	}

	deletePage(id: number, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url('pages', '' + id), options) as Promise<void>;
	}

	/** q, sort, status, bucket, offset, limit */
	getForms(options?: ClientGetOptions): Promise<FormDefinitionInfo[]> {
		return this.client.get(this.url('forms'), amendOptionsParams(DefaultPaginationParams, options)) as Promise<FormDefinitionInfo[]>;
	}

	getForm(id: string, options?: ClientGetOptions): Promise<UcDefinition> {
		return this.client.get(this.url('forms', id), options) as Promise<UcDefinition>;
	}

	/** Head the Form by Identifier to check its existence */
	headFormByIdentifier(identifier: string, options?: ClientHeadOptions): Promise<Headers> {
		return this.client.head(this.url('forms', identifier), options);
	}

	deleteForm(id: string, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url('forms', id), options) as Promise<void>;
	}

	saveForm(form: UcDefinition, force?: boolean): Promise<UcDefinition> {

		if (!form.id) {
			return this.client.post(this.url('forms'), { body: form, params: { force } }) as Promise<UcDefinition>;
		}

		const headers = new Headers();

		if (form.revision) {
			headers.set(HeaderKeys.IfMatch, `"${form.revision}"`);
		}

		return this.client.put(this.url('forms', '' + form.id), form, { params: { force }, headers }) as Promise<UcDefinition>;
	}

	approveForms(ids: string[], options?: ClientPostOptions): Promise<FormDefinitionInfo[]> {
		return this.client.post(this.url('forms', 'approved'), amendOptionsParams({ ids }, options)) as Promise<FormDefinitionInfo[]>;
	}

	revertForms(ids: string[], options?: ClientPostOptions): Promise<FormDefinitionInfo[]> {
		return this.client.post(this.url('forms', 'unapproved'), amendOptionsParams({ ids }, options)) as Promise<FormDefinitionInfo[]>;
	}

	archiveForms(ids: string[], options?: ClientPostOptions): Promise<FormDefinitionInfo[]> {
		return this.client.post(this.url('forms', 'archived'), amendOptionsParams({ ids }, options)) as Promise<FormDefinitionInfo[]>;
	}

	getFormRevision(id: string): Promise<string | undefined> {
		return this.client.getRevisionHeader(this.url('forms', id));
	}

	/** q, sort, status, source, offset, limit */
	getTables(options?: ClientGetOptions): Promise<TableInfo[]> {
		return this.client.get(this.url('tables'), amendOptionsParams(DefaultPaginationParams, options)) as Promise<TableInfo[]>;
	}

	async getTable(id: string, options?: ClientGetOptions): Promise<UcTable> {
		const table: UcTable = await this.client.get(this.url('tables', id), options);

		// SchemaField '_parent' was an alias for '_parent.seqId' and since 1.32.0 '_parent.seqId' is fully supported in Discover
		table.columns?.filter((c) => c.identifier === '_parent').forEach((c) => { c.identifier = '_parent.seqId'; });

		return table;
	}

	saveTable(table: UcTable): Promise<UcTable> {
		// New Table
		if (!table.id) {
			return this.client.post(this.url('tables'), { body: table }) as Promise<UcTable>;
		}

		// Update table
		return this.client.put(this.url('tables', table.id), table) as Promise<UcTable>;
	}

	approveTable(id: number, options?: ClientPostOptions): Promise<TableInfo> {
		return this.approveTables([id], options).then((tables) => tables[0]) as Promise<TableInfo>;
	}

	approveTables(ids: number[], options?: ClientPostOptions): Promise<TableInfo[]> {
		return this.client.post(this.url('tables', 'approved'), amendOptionsParams({ ids }, options)) as Promise<TableInfo[]>;
	}

	revertTables(ids: string[], options?: ClientPostOptions): Promise<TableInfo[]> {
		return this.client.post(this.url('tables', 'unapproved'), amendOptionsParams({ ids }, options)) as Promise<TableInfo[]>;
	}

	archiveTables(ids: string[], options?: ClientPostOptions): Promise<TableInfo[]> {
		return this.client.post(this.url('tables', 'archived'), amendOptionsParams({ ids }, options)) as Promise<TableInfo[]>;
	}

	deleteTable(id: string, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url('tables', id), options) as Promise<void>;
	}

	getTableDetail(tableId: string, options?: ClientGetOptions): Promise<TableDetail> {
		return this.client.get(this.url('tables', tableId, 'detail'), options) as Promise<TableDetail>;
	}

	saveTableDetail(tableId: string, tableDetail: TableDetail, options?: ClientPutOptions): Promise<TableDetail> {
		return this.client.put(this.url('tables', tableId, 'detail'), tableDetail, options) as Promise<TableDetail>;
	}

	deleteTableDetail(tableId: string, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url('tables', tableId, 'detail'), options) as Promise<void>;
	}

	async getFieldTemplates(options?: ClientGetOptions): Promise<UcField[]> {
		const templates = await this.client.get(this.url('field-templates'), options) as UcField[];

		for (const template of templates) {
			// TODO Define type UcFieldOption with `id?: string`
			template.options?.forEach((option) => delete (option as any).id);
		}

		return templates;
	}

	saveFieldTemplate(field: BuilderField | UcField): Promise<BuilderField> {
		if (!field.id) {
			return this.client.post(this.url('field-templates'), { body: field }) as Promise<BuilderField>;
		}

		return this.client.put(this.url('field-templates', `${field.id}`), field) as Promise<BuilderField>;
	}

	deleteFieldTemplate(id: number, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url('field-templates', `${id}`), options) as Promise<void>;
	}

	getPublishStatus(options?: ClientGetOptions): Promise<PublishStatus> {
		return this.client.get(this.url('versions', 'status'), options) as Promise<PublishStatus>;
	}

	getPublishItems(options?: ClientGetOptions): Promise<PublishItem[]> {
		return this.client.get(this.url('versions', 'publishable'), options) as Promise<PublishItem[]>;
	}

	publishPreview(items?: PublishItem[], options?: ClientPostOptions): Promise<void> {
		return this.client.post(this.url('versions', 'preview'), { ...options, body: items }) as Promise<void>;
	}

	publishStable(items?: PublishItem[], options?: ClientPostOptions): Promise<void> {
		return this.client.post(this.url('versions'), { ...options, body: items }) as Promise<void>;
	}

	getApiKeys(options?: ClientGetOptions): Promise<ApiKey[]> {
		return this.client.get(this.url('api-keys'), options) as Promise<ApiKey[]>;
	}

	saveApiKey(apiKey: ApiKey, options?: ClientPostOptions): Promise<ApiKey> {
		return this.client.post(this.url('api-keys'), { ...options, body: apiKey }) as Promise<ApiKey>;
	}

	revokeApiKey(apiKey: ApiKey, options?: ClientDeleteOptions): Promise<void> {
		if (!apiKey.key) {
			return Promise.resolve();
		}

		return this.client.delete(this.url('api-keys', apiKey.key), options) as Promise<void>;
	}

	private url(...extra: string[]): string {
		this.guardProjectId();

		return this.client.buildUrl(['projects', this.options.projectId].concat(extra));
	}

	private guardProjectId() {
		if (!this.options.projectId) {
			throw new Error('Project id not provided');
		}
	}

}
