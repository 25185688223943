import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UfControlGroup } from '@unifii/library/common';
import { FieldTemplate } from '@unifii/sdk';
import { Subscription, debounceTime } from 'rxjs';

import { FormEditorCache } from './form-editor-cache';
import { DefinitionControlKeys, FieldControlKeys } from './form-editor-control-keys';
import { FormEditorField, FormEditorVariation } from './form-editor-model';

@Component({
	selector: 'uc-form-field-icons',
	templateUrl: './form-field-icons.html',
	standalone: false,
})
export class FormFieldIconsComponent implements OnInit, OnDestroy {

	@Input({ required: true }) control: UfControlGroup;
	@Input({ required: true }) selected: boolean;

	protected hasVariations: boolean;
	protected hasTags: boolean;
	protected hasShowIf: boolean;
	protected hasAutofill: boolean;
	protected hasBindTo: boolean;
	protected isRequired: boolean;
	protected isHidden: boolean;
	protected hasDescription: boolean;
	protected isReportable: boolean;
	protected isSearchable: boolean;

	private subscriptions = new Subscription();
	private cache = inject(FormEditorCache);

	ngOnInit() {

		// update path on bucket change after bucket has been set in cache
		this.subscriptions.add(this.control.root.get(DefinitionControlKeys.Bucket)?.valueChanges.pipe(debounceTime(0)).subscribe(() => {
			const path = this.control.get(FieldControlKeys.Path)?.getRawValue() as string[] | undefined;

			void this.updateSchemaFlags(path);
		}));

		this.subscriptions.add(this.control.get(FieldControlKeys.Path)?.valueChanges.subscribe((path) => void this.updateSchemaFlags(path)));
		this.subscriptions.add(this.control.get(FieldControlKeys.Variations)?.valueChanges.subscribe((variations) => this.updateHasVariations(variations)));
		this.subscriptions.add(this.control.get(FieldControlKeys.Tags)?.valueChanges.subscribe((tags) => { this.updateHasTags(tags); }));
		this.subscriptions.add(this.control.get(FieldControlKeys.ShowIf)?.valueChanges.subscribe((showIf) => this.updateHasShowIf(showIf)));
		this.subscriptions.add(this.control.get(FieldControlKeys.Autofill)?.valueChanges.subscribe((autofill) => this.updateHasAutofill(autofill)));
		this.subscriptions.add(this.control.get(FieldControlKeys.BindTo)?.valueChanges.subscribe((bindTo) => this.updateHasBindTo(bindTo)));
		this.subscriptions.add(this.control.get(FieldControlKeys.IsRequired)?.valueChanges.subscribe((isRequired) => this.updateIsRequired(isRequired)));
		this.subscriptions.add(this.control.get(FieldControlKeys.Template)?.valueChanges.subscribe((template) => this.updateIsHidden(template)));
		this.subscriptions.add(this.control.get(FieldControlKeys.Description)?.valueChanges.subscribe((description) => this.updateHasDescription(description)));
		this.setup(this.control.getRawValue());
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private setup(field: FormEditorField) {
		void this.updateSchemaFlags(field.path);
		this.updateHasVariations(field.variations);
		this.updateHasTags(field.tags);
		this.updateHasShowIf(field.showIf);
		this.updateHasAutofill(field.autofill);
		this.updateHasBindTo(field.bindTo);
		this.updateIsRequired(field.isRequired);
		this.updateIsHidden(field.template);
		this.updateHasDescription(field.description);
	}

	private updateHasVariations(variations?: FormEditorVariation[]) {
		this.hasVariations = !!variations?.length;
	}

	private updateHasTags(tags?: string[]) {
		this.hasTags = !!tags?.length;
	}

	private updateHasShowIf(showIf?: string) {
		this.hasShowIf = !!showIf;
	}

	private updateHasAutofill(autofill?: string) {
		this.hasAutofill = !!autofill;
	}

	private updateHasBindTo(bindTo?: string) {
		this.hasBindTo = !!bindTo;
	}

	private updateIsRequired(isRequired?: boolean) {
		this.isRequired = !!isRequired;
	}

	private updateIsHidden(template?: FieldTemplate) {
		this.isHidden = template === FieldTemplate.Hidden;
	}

	private updateHasDescription(description?: string) {
		this.hasDescription = !!description;
	}

	private async updateSchemaFlags(path?: string[]) {

		if (!path) {
			this.isReportable = false;
			this.isSearchable = false;

			return;
		}

		const schemaField = await this.cache.getSchemaFieldLookup(path);

		this.isReportable = schemaField?.isReportable ?? false;
		this.isSearchable = schemaField?.isSearchable ?? false;
	}

}
