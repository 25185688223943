"use strict";

exports.getDayOfYear = getDayOfYear;
var _index = require("./differenceInCalendarDays.cjs");
var _index2 = require("./startOfYear.cjs");
var _index3 = require("./toDate.cjs");

/**
 * The {@link getDayOfYear} function options.
 */

/**
 * @name getDayOfYear
 * @category Day Helpers
 * @summary Get the day of the year of the given date.
 *
 * @description
 * Get the day of the year of the given date.
 *
 * @param date - The given date
 * @param options - The options
 *
 * @returns The day of year
 *
 * @example
 * // Which day of the year is 2 July 2014?
 * const result = getDayOfYear(new Date(2014, 6, 2))
 * //=> 183
 */
function getDayOfYear(date, options) {
  const _date = (0, _index3.toDate)(date, options?.in);
  const diff = (0, _index.differenceInCalendarDays)(_date, (0, _index2.startOfYear)(_date));
  const dayOfYear = diff + 1;
  return dayOfYear;
}