import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DownloadConfig, TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { DataDisplayInfo, DataDisplayService, DataPropertyDescriptor, FilterEntries, FilterEntry, FilterValue, HierarchyUnitProvider, FormDefinitionMetadataIdentifiers as MetadataKeys, TableConfig, TableConfigColumn, getDefaultTableConfig } from '@unifii/library/common';
import { Client, ContentClient, FormData, FormDataClient, ProjectInfo, Query, TenantClient, fieldTypeToDataType, isNotNull } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { BucketDataSource } from 'pages/form-data/bucket-datasource';
import { FormBucketService } from 'pages/form-data/bucket-service';
import { ContextService } from 'services/context.service';

const ColumnKeys = [
	MetadataKeys.Id,
	MetadataKeys.DefinitionIdentifier,
	MetadataKeys.SeqId,
	MetadataKeys.CreatedAt,
	MetadataKeys.CreatedBy,
	MetadataKeys.LastModifiedAt,
	MetadataKeys.LastModifiedBy,
	MetadataKeys.State,
	MetadataKeys.Result,
] as string[];

const hiddenColumnKeys = [
	MetadataKeys.CreatedAt,
	MetadataKeys.CreatedBy,
	MetadataKeys.LastModifiedBy,
	MetadataKeys.Result,
] as string[];

@Injectable()
export class BucketDataTableManager implements TableContainerManager<FormData, FilterValue, FilterEntry> {

	bucketService = inject(FormBucketService);
	tableConfig: TableConfig<FormData>;
	downloadConfig: DownloadConfig | undefined;
	inputManager: TableInputManager<FilterValue, FilterEntry>;
	update = new Subject<TableInputs<FilterValue>>();

	private route = inject(ActivatedRoute);
	private dataDisplayService = inject(DataDisplayService);
	private context = inject(ContextService);
	private client = inject(Client);
	private tenantClient = inject(TenantClient);
	private dataSource: BucketDataSource;

	constructor() {
		const formBucketService = inject(FormBucketService);
		const entries = inject(FilterEntries);
		const hierarchyUnitProvider = inject<HierarchyUnitProvider>(HierarchyUnitProvider);

		this.inputManager = new TableInputManager(entries, hierarchyUnitProvider, null, null);

		// Use SDK v0 endpoint that is ACL exceptions mitigated for Console users
		// Set Bucket to Live or Preview accordingly
		this.bucketService.formDataClient = new FormDataClient(this.client, {
			projectId: (this.context.project as ProjectInfo).id,
			bucket: this.bucketService.schema.bucket,
			preview: this.route.snapshot.data.preview,
		});

		// Set Bucket to Live or Preview accordingly
		this.bucketService.content = new ContentClient(this.client, this.tenantClient, {
			projectId: (this.context.project as ProjectInfo).id,
			preview: this.route.snapshot.data.preview,
		});

		this.downloadConfig = {
			name: `${this.bucketService.schema.bucket}.csv`,
			getUrl: this.getDownloadUrl.bind(this),
		};

		const preview = this.route.snapshot.data.preview === true;
		const tableId = `${preview ? 'preview' : 'live'}-form-data`;
		const columns = this.createColumns(formBucketService.dataDescriptor?.propertyDescriptors);
		const tableConfig = getDefaultTableConfig(columns, tableId);

		tableConfig.row = {
			link: (item) => item.id ?? [],
		};
		this.tableConfig = tableConfig;
	}

	createDataSource(inputs: TableInputs<FilterValue>) {
		let query = new Query();

		if (inputs != null) {
			query = this.inputManager.createQuery(inputs);
		}

		this.dataSource = new BucketDataSource(this.bucketService.formDataClient, query, inputs.sort != null);

		return this.dataSource;
	}

	async getDownloadUrl(): Promise<string | null> {
		const url = this.dataSource.getDownloadUrl();

		if (url) {
			const downloadToken: { token: string } = await this.client.getDownloadToken(url);

			return url + '&_dlt=' + downloadToken.token;
		}

		return null;
	}

	private createColumns(propertyDescriptors: DataPropertyDescriptor[] = []): TableConfigColumn<FormData>[] {
		return ColumnKeys.map((key) => {
			const descriptor = propertyDescriptors.find((sf) => sf.identifier === key);

			return descriptor ? this.mapToColumn(descriptor) : undefined;
		}).filter(isNotNull);
	}

	private mapToColumn(descriptor: DataPropertyDescriptor): TableConfigColumn<FormData> {
		return {
			name: descriptor.identifier,
			label: descriptor.label,
			sortable: descriptor.asSort,
			hidden: hiddenColumnKeys.includes(descriptor.identifier),
			value: (item: FormData) => {
				if (descriptor.identifier === `${MetadataKeys.DefinitionIdentifier}`) {
					const label = descriptor.options?.find((option) => option.identifier === item._definitionIdentifier)?.name;

					return label ?? descriptor.identifier;
				}

				return this.dataDisplayService.displayAsString(item[descriptor.identifier as keyof FormData], { type: fieldTypeToDataType(descriptor.type) } as DataDisplayInfo);
			},
		};
	}

}
