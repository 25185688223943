import { Injectable, OnDestroy, inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ControlAccessor, DataPropertyDescriptor, DataPropertyInfoService, ExpressionParser, SortStatus, ToastService, UfControl, UfControlArray, UfControlGroup, UfFormBuilder, UfFormControl, ValidatorFunctions } from '@unifii/library/common';
import { WorkflowStartState } from '@unifii/library/smart-forms';
import { DataSource, DataSourceType, ErrorType, FieldTemplate, FieldType, FieldValidator, FieldWidth, LinkContentType, ValidatorType, ensureUfError, isBoolean, isNotNull, isStringNotEmpty, objectKeys } from '@unifii/sdk';
import { Observable, Subscription, from, of, timer } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { UcDefinitionDataSource, UcProject } from 'client';
import { ConsoleNameRequiredMessage } from 'constant';
import { FieldIdentifierValidCharactersValidator, IdentifierFunctions, IdentifierNoEmptySpacesValidator } from 'helpers/helpers';
import { ContextService } from 'services/context.service';
import { LimitService } from 'services/limit.service';

import { DataSourceValidator } from './data-source-validator';
import { FormEditorCache } from './form-editor-cache';
import { FORM_EDITOR_CONSTANTS } from './form-editor-constants';
import { DefinitionControlKeys, FieldControlKeys, HierarchyConfigControlKeys, NestedControlKey, OptionControlKeys, TransitionControlKeys, ValidatorControlKeys, VariationControlKeys } from './form-editor-control-keys';
import { FormEditorFieldScopeManager } from './form-editor-field-scope-manager';
import { FormEditorFunctions } from './form-editor-functions';
import { AttributeParentType, FormAddressNestedFields, FormEditorDefinition, FormEditorField, FormEditorHierarchyConfiguration, FormEditorOption, FormEditorTransition, FormEditorVariation, FormFieldMetadata, FormGeoLocationNestedFields, FormNestedField } from './form-editor-model';
import { FormEditorStatus } from './form-editor-status';

const identifierIsRequiredErrorMessage = 'Identifier is required';
const labelIsRequiredErrorMessage = 'Label is required';
const invalidExpressionErrorMessage = 'Invalid expression';

@Injectable()
export class FormEditorFormCtrl implements OnDestroy {

	private readonly setSubmitted: boolean = true;
	private fieldsSubscriptions = new Map<string, Subscription>();
	private formBuilder = inject(UfFormBuilder);
	private status = inject(FormEditorStatus);
	private fieldsScopeManager = inject(FormEditorFieldScopeManager);
	private limitService = inject(LimitService);
	private toast = inject(ToastService);
	private context = inject(ContextService);
	private expressionParser = inject(ExpressionParser);
	private cache = inject(FormEditorCache);
	private dataPropertyInfoService = inject(DataPropertyInfoService);
	private ucProject = inject(UcProject);

	constructor() {
		this.initFieldSubscriptions();
	}

	ngOnDestroy() {
		this.clearFieldSubscriptions();
	}

	onFieldRemoved(control: UfControlGroup) {
		const uuid = control.get(FieldControlKeys.Uuid)?.value as string;

		this.fieldsSubscriptions.get(uuid)?.unsubscribe();
		this.fieldsSubscriptions.delete(uuid);

		const fields = (control.get(FieldControlKeys.Fields) as UfControlArray | undefined)?.controls as UfControlGroup[] | undefined;

		if (fields) {
			for (const field of fields) {
				this.onFieldRemoved(field);
			}
		}
	}

	buildRoot(definition: FormEditorDefinition): UfControlGroup {

		this.clearFieldSubscriptions();
		this.initFieldSubscriptions();

		const lastPublishedAtControl = this.buildDefinitionLastPublishedControl(definition.lastPublishedAt);
		const identifierControl = this.buildDefinitionIdentifierControl(lastPublishedAtControl, definition.identifier);
		const bucketControl = this.buildDefinitionBucketControl(lastPublishedAtControl, definition.bucket);
		const consoleNameControl = this.buildDefinitionConsoleNameControl(definition.consoleName);
		const labelControl = this.buildDefinitionLabelControl(lastPublishedAtControl, identifierControl, bucketControl, consoleNameControl, definition.label);
		const hasRollingVersionControl = this.buildDefinitionHasRollingVersionControl(definition.hasRollingVersion);

		const rootControl = this.formBuilder.group({
			[DefinitionControlKeys.Id]: definition.id,
			[DefinitionControlKeys.CompoundType]: definition.compoundType,
			[DefinitionControlKeys.Label]: labelControl,
			[DefinitionControlKeys.Identifier]: identifierControl,
			[DefinitionControlKeys.Bucket]: bucketControl,
			[DefinitionControlKeys.SequenceNumberFormat]: definition.sequenceNumberFormat,
			[DefinitionControlKeys.State]: definition.state,
			[DefinitionControlKeys.Description]: definition.description,
			[DefinitionControlKeys.PublishState]: definition.publishState,
			[DefinitionControlKeys.LastPublishedAt]: lastPublishedAtControl,
			[DefinitionControlKeys.LastPublishedBy]: this.formBuilder.control(definition.lastPublishedBy),
			[DefinitionControlKeys.LastModifiedAt]: definition.lastModifiedAt,
			[DefinitionControlKeys.LastModifiedBy]: this.formBuilder.control(definition.lastModifiedBy),
			[FieldControlKeys.Fields]: this.buildFieldsControl(definition.fields),
			[DefinitionControlKeys.Settings]: this.formBuilder.group({
				optionalSuffix: definition.settings?.optionalSuffix,
				requiredSuffix: definition.settings?.requiredSuffix,
				scrollToActiveSection: definition.settings?.scrollToActiveSection,
				isNavigationEnabled: definition.settings?.isNavigationEnabled,
			}),
			[DefinitionControlKeys.Tags]: this.buildTagsControl(FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID, definition.tags),
			[DefinitionControlKeys.ReportableMetaFields]: this.formBuilder.control(definition.reportableMetaFields),
			[DefinitionControlKeys.Version]: definition.version,
			[DefinitionControlKeys.HasRollingVersion]: hasRollingVersionControl,
			[DefinitionControlKeys.Revision]: definition.revision,
			[DefinitionControlKeys.ConsoleName]: consoleNameControl,
		});

		rootControl.updateDependencies();

		if (this.setSubmitted) {
			rootControl.setSubmitted(true);
		}

		return rootControl;
	}

	buildDefinitionLastPublishedControl(lastPublishedAt?: string): UfControl {
		return this.formBuilder.control(lastPublishedAt);
	}

	buildDefinitionIdentifierControl(lastPublishedAtControl: UfControl, identifier?: string): UfControl {
		const control = this.formBuilder.control(identifier, ValidatorFunctions.compose([
			ValidatorFunctions.required(identifierIsRequiredErrorMessage),
			ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.DEFINITION_IDENTIFIER_REGEX, 'Identifier contains invalid characters'),
			IdentifierNoEmptySpacesValidator,
			ValidatorFunctions.maxLength(this.status.identifiersMaxLength.definition, `Identifier must be ${this.status.identifiersMaxLength.definition} or less`),
		]), this.createFormIdentifierAsyncValidator(identifier));

		if (lastPublishedAtControl.value != null) {
			control.disable();
		}

		this.fieldsSubscriptions.get(FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID)?.add(
			control.valueChanges.subscribe(() => { this.checkIdentifierEditWarning(lastPublishedAtControl); }),
		);

		return control;
	}

	buildDefinitionBucketControl(lastPublishedAtControl: UfControl, bucket?: string): UfControl {
		const control = this.formBuilder.control(bucket, ValidatorFunctions.compose([
			ValidatorFunctions.required('Form Data Repository is required'),
			ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.DEFINITION_BUCKET_REGEX, 'Form Data Repository contains invalid characters'),
			ValidatorFunctions.maxLength(this.status.identifiersMaxLength.bucket, `Form Data Repository must be ${this.status.identifiersMaxLength.bucket} or less`),
		]));

		this.fieldsSubscriptions.get(FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID)?.add(
			control.valueChanges.subscribe((bucketIdentifier) => {
				this.cache.bucketIdentifier = bucketIdentifier;
				this.checkBucketEditWarning(lastPublishedAtControl);
			}));

		return control;
	}

	// eslint-disable-next-line better-max-params/better-max-params
	buildDefinitionLabelControl(lastPublishedAtControl: UfControl, identifierControl: UfControl, bucketControl: UfControl, consoleNameControl: UfControl, label?: string, generateValues?: boolean): UfControl {
		const control = this.formBuilder.control(label, ValidatorFunctions.required(labelIsRequiredErrorMessage));

		if (generateValues) {
			this.fieldsSubscriptions.get(FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID)?.add(
				control.valueChanges.subscribe((v) => {
					const kebabizedLabel = IdentifierFunctions.kebabize(v).substring(0, IdentifierFunctions.WARNING_IDENTIFIER_MAX_LENGTH);

					if (!identifierControl.dirty) {
						identifierControl.setValue(kebabizedLabel);
						this.checkIdentifierEditWarning(lastPublishedAtControl);
					}

					if (!bucketControl.dirty) {
						bucketControl.setValue(kebabizedLabel);
						this.checkBucketEditWarning(lastPublishedAtControl);
					}

					if (!consoleNameControl.dirty) {
						consoleNameControl.setValue(v);
					}
				}),
			);
		}

		return control;
	}

	buildDefinitionConsoleNameControl(consoleName?: string) {
		return this.formBuilder.control(consoleName, ValidatorFunctions.required(ConsoleNameRequiredMessage));
	}

	buildDefinitionHasRollingVersionControl(hasRollingVersion?: boolean) {
		return this.formBuilder.control(hasRollingVersion, ValidatorFunctions.required('Has rolling version is required'));
	}

	buildFieldControl(field: FormEditorField, parent?: FormEditorField, parentPath?: string[]): UfControlGroup {

		// Here meta and field.id and field.type are guaranteed to be updated value thanks to:
		// field.type not changing
		// fieldControl rebuilt when form saved or special cases of field moved => meta and field.id up to date
		const meta = FormEditorFunctions.fieldMetadata(field.uuid, field.scopeUuid, field.type, this.context, parent?.type);

		parentPath = parentPath ?? [];
		const fieldPath = field.identifier ? [...parentPath, field.identifier] : undefined;
		const childrenPathPrefix = (field.type === FieldType.Repeat && field.identifier) ? [...parentPath, field.identifier] : parentPath;

		this.fieldsSubscriptions.set(field.uuid, new Subscription());

		const repeatSortablePropertiesCtrl = this.formBuilder.control(FormEditorFunctions.calculateRepeatFieldSortableDescriptors(field));

		const fieldControl = this.formBuilder.group({
			[FieldControlKeys.Uuid]: field.uuid,
			[FieldControlKeys.ScopeUuid]: field.scopeUuid,
			[FieldControlKeys.Path]: { value: fieldPath, disabled: true },
			[FieldControlKeys.RepeatSortableProperties]: repeatSortablePropertiesCtrl,
			[FieldControlKeys.Id]: field.id,
			[FieldControlKeys.Type]: [field.type, ValidatorFunctions.required('Type is required')],
			[FieldControlKeys.Fields]: meta.isContainer ? this.buildFieldsControl(field.fields, field, childrenPathPrefix) : null,
			[FieldControlKeys.Identifier]: this.buildFieldIdentifierControl(meta, field.id, field.identifier),
			[FieldControlKeys.Label]: this.buildFieldLabelControl(meta, field.id, field.label),
			[FieldControlKeys.ShortLabel]: this.buildFieldShortLabelControl(meta, field.id, field.shortLabel),
			[FieldControlKeys.Description]: field.description,
			[FieldControlKeys.Help]: meta.help ? field.help : null,
			[FieldControlKeys.Currency]: this.buildCurrencyControl(meta, field.currency),
			[FieldControlKeys.Placeholder]: meta.placeholder ? field.placeholder : null,
			[FieldControlKeys.Step]: this.buildStepControl(meta, field.step),
			[FieldControlKeys.Format]: meta.format ? field.format : null,
			[FieldControlKeys.HierarchyConfig]: meta.hierarchy ? this.buildHierarchyConfigControl(field.hierarchyConfig) : null,
			[FieldControlKeys.Sort]: meta.sort ? this.buildSortControl(meta, repeatSortablePropertiesCtrl, field.sort) : null,
			[FieldControlKeys.Tags]: this.buildTagsControl(meta.uuid, field.tags, meta),
			[FieldControlKeys.DataSourceConfig]: this.buildDatasourceConfigControl(meta, AttributeParentType.FieldOptionType, field.dataSourceConfig),
			[FieldControlKeys.AvoidDuplicates]: meta.avoidDuplicates ? field.avoidDuplicates : null,
			[FieldControlKeys.Autofill]: this.buildAutofillControl(meta, field.autofill),
			[FieldControlKeys.IsReadOnly]: meta.isReadOnly ? field.isReadOnly : null,
			[FieldControlKeys.IsRequired]: this.buildIsRequiredControl(meta, field.isRequired, field.scrollTime),
			[FieldControlKeys.DataCaptures]: meta.dataCaptures ? { value: field.dataCaptures, disabled: this.isDataCaptureDisabled(meta, field.dataSourceConfig) } : [[]],
			[FieldControlKeys.AutoDetect]: this.buildAutoDetectControl(meta, field),
			[FieldControlKeys.MaxLength]: meta.maxLength ? field.maxLength : null,
			[FieldControlKeys.Precision]: meta.precision ? field.precision : null,
			[FieldControlKeys.ItemLabel]: meta.itemLabel ? field.itemLabel : null,
			[FieldControlKeys.AddButtonLabel]: meta.addButtonLabel ? field.addButtonLabel : null,
			[FieldControlKeys.Width]: this.buildFieldWidthControl(meta, field.width),
			[FieldControlKeys.BreakAfter]: meta.breakAfter ? field.breakAfter : null,
			[FieldControlKeys.BindTo]: this.buildBindToControl(meta, field.bindTo),
			[FieldControlKeys.Template]: this.buildFieldTemplateControl(meta, field.template),
			[FieldControlKeys.ColumnCount]: meta.columnCount ? field.columnCount : null,
			[FieldControlKeys.ScrollTime]: this.buildScrollTimeControl(meta, field.scrollTime),
			[FieldControlKeys.ActiveBackgroundTinted]: meta.activeBackgroundTinted ? { value: field.template ? field.activeBackgroundTinted : false, disabled: this.isActiveBackgroundTintedDisabled(field.template) } : null,
			[FieldControlKeys.AlwaysExpanded]: this.buildFieldAlwaysExpandedControl(meta, field.alwaysExpanded),
			[FieldControlKeys.ExpandWhenInactive]: this.buildFieldExpandWhenInactiveControl(meta, field.expandWhenInactive, field.alwaysExpanded),
			[FieldControlKeys.HideWhenInactive]: this.buildFieldHideWhenInactiveControl(meta, field.hideWhenInactive),
			[FieldControlKeys.AllowedTypes]: meta.allowedTypes ? this.buildFieldAllowedTypesControl(field.allowedTypes) : null,
			[FieldControlKeys.LayoutDirection]: meta.layoutDirection ? field.layoutDirection : null,
			[FieldControlKeys.ColumnVisibility]: meta.columnVisibility ? field.columnVisibility : null,
			[FieldControlKeys.Roles]: this.buildFieldRolesControl(meta.role, field.roles),
			[FieldControlKeys.VisibleTo]: this.buildFieldRolesControl(meta.visibleTo, field.visibleTo),
			[FieldControlKeys.ShowIf]: this.buildShowIfControl(meta, field.showIf),
			[FieldControlKeys.ShowOn]: this.buildShowOnControl(meta, field.showOn),
			[FieldControlKeys.AddressAutocomplete]: meta.addressAutocomplete ? field.addressAutocomplete : null,
			[FieldControlKeys.AddressNested]: this.buildAddressNestedControl(meta, field.addressNested),
			[FieldControlKeys.GeoLocationNested]: this.buildGeoLocationNestedControl(meta, field.geoLocationNested),
			[FieldControlKeys.Variations]: meta.variations ? this.buildVariationsControl(meta, field.variations) : null,
			[FieldControlKeys.Options]: meta.options ? this.buildOptionsControl(meta, AttributeParentType.FieldOptionType, field.options) : null,
			[FieldControlKeys.Transitions]: meta.transitions ? this.buildFieldTransitions(meta, field.transitions) : null,
			[VariationControlKeys.Validators]: meta.validators ? this.buildValidatorsControl(meta, field.validators) : null,
		});

		fieldControl.addValidators(this.fieldPositionValidator.bind(this));

		this.fieldsScopeManager.onAddedField(fieldControl, true);
		// fieldControl.updateDependencies();

		if (this.setSubmitted) {
			fieldControl.setSubmitted(true);
		}

		return fieldControl;
	}

	buildVariationControl(meta: FormFieldMetadata, variation: FormEditorVariation): UfControlGroup {

		const conditionControl = this.formBuilder.control(
			variation.condition,
			ValidatorFunctions.compose([
				ValidatorFunctions.required('Condition is required'),
				ValidatorFunctions.isValidExpression(this.expressionParser, invalidExpressionErrorMessage),
				// TODO Restore once expression identifiers validation is implemented
				// (c) => this.expressionIdentifiersValidator(c, meta),
			]), undefined, { deps: [this.status.fieldsIdentifier] },
		);

		const control = this.formBuilder.group({
			[VariationControlKeys.Name]: [variation.name, ValidatorFunctions.required('Name is required')],
			[VariationControlKeys.Condition]: conditionControl,
			[VariationControlKeys.Label]: variation.label,
			[VariationControlKeys.Placeholder]: variation.placeholder,
			[VariationControlKeys.Help]: variation.help,
			[VariationControlKeys.Options]: this.buildOptionsControl(meta, AttributeParentType.VariationOptionType, variation.options),
			[FieldControlKeys.DataSourceConfig]: this.buildDatasourceConfigControl(meta, AttributeParentType.VariationOptionType, variation.dataSourceConfig),
			[VariationControlKeys.Validators]: this.buildValidatorsControl(meta, variation.validators),
		});

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	buildOptionControl(meta: FormFieldMetadata, optionType: AttributeParentType, option: FormEditorOption): UfControlGroup {

		const fieldControlPath = optionType === AttributeParentType.FieldOptionType ? `../../../` : `../../../../../`; // from option.*
		const optionsPath = `../../[*]`; // from option.*
		const optionsIdentifiersPath = `${optionsPath}.${OptionControlKeys.Identifier}`; // from option.*

		// Identifier control
		const identifierControlAccessor = new ControlAccessor();
		const identifierControl = this.formBuilder.control(option.identifier, ValidatorFunctions.compose([
			ValidatorFunctions.required(identifierIsRequiredErrorMessage),
			ValidatorFunctions.custom((v: string) => {

				if (!v) {
					return true;
				}

				if (!identifierControlAccessor.control) {
					return true;
				}

				const identifiers = identifierControlAccessor.get(optionsIdentifiersPath)
					.filter((c) => c !== identifierControlAccessor.control)
					.map((c) => c.value as string | undefined)
					.filter(isNotNull);

				return !identifiers.find((i) => i.toLowerCase() === v.toLowerCase());

			}, 'Identifier needs to be unique'),
			ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_OPTION_IDENTIFIER_REGEX, 'Identifier contains invalid characters'),
			IdentifierNoEmptySpacesValidator,
			ValidatorFunctions.maxLength(this.status.identifiersMaxLength.option, `Identifier can't be longer than ${this.status.identifiersMaxLength.option} characters`),
		]), undefined, { deps: [optionsIdentifiersPath] });

		identifierControlAccessor.control = identifierControl;

		this.fieldsSubscriptions.get(meta.uuid)?.add(identifierControl.valueChanges.subscribe(() => {
			if (!identifierControl.pristine && this.status.hasBeenPublished && option.id) {
				this.toast.warning('Editing the identifier after the option is published may cause errors with your Form');
			}
		}));

		// Name control
		const optionIdControlPath = `../${OptionControlKeys.Id}`; // from option.*
		const nameControl = this.formBuilder.control(option.name, ValidatorFunctions.required('Name is required'));
		const nameControlAccessor = new ControlAccessor(nameControl);

		if (meta.type !== FieldType.Bool) {
			this.fieldsSubscriptions.get(meta.uuid)?.add(nameControl.valueChanges.subscribe((v) => {

				const id = nameControlAccessor.get(optionIdControlPath)[0]?.value as string | undefined;

				if (id == null) {
					const otherOptionsIdentifiers = nameControlAccessor.get(optionsPath)
						.filter((c) => c.get(OptionControlKeys.Uuid)?.value !== option.uuid)
						.map((c) => c.get(OptionControlKeys.Identifier)?.value as string);
					const generatedIdentifier = FormEditorFunctions.generateSafeIdentifier(IdentifierFunctions.pascalize(v), otherOptionsIdentifiers);

					identifierControl.setValue(generatedIdentifier, { onlySelf: true, emitEvent: false });
				}
			}));
		}

		// Content control
		const fieldTemplateControlPath = `${fieldControlPath}${FieldControlKeys.Template}`; // from option.*
		const contentControlAccessor = new ControlAccessor();
		const contentControl = this.formBuilder.control(option.content, ValidatorFunctions.custom((v) => {

			if (!contentControlAccessor.control) {
				return true;
			}

			const templateValue = contentControlAccessor.get(fieldTemplateControlPath)[0]?.value as FieldTemplate | null;

			return ![FieldType.MultiChoice, FieldType.Choice].includes(meta.type) ||
                (!templateValue || ![FieldTemplate.OptionWithContent, FieldTemplate.CheckboxWithContent, FieldTemplate.RadioWithContent].includes(templateValue)) ||
                !ValidatorFunctions.isEmpty(v);
		}, 'Content is required'), undefined, { deps: [fieldTemplateControlPath] });

		contentControlAccessor.control = contentControl;

		// Group control
		const control = this.formBuilder.group({
			[OptionControlKeys.Uuid]: option.uuid,
			[OptionControlKeys.Id]: option.id,
			[OptionControlKeys.Identifier]: identifierControl,
			[OptionControlKeys.Name]: nameControl,
			[OptionControlKeys.Content]: contentControl,
		});

		// control.updateDependencies();

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	buildValidatorControl(validator: FieldValidator): UfControlGroup {

		const valueTypes = [ValidatorType.Expression,
			ValidatorType.ItemExpression,
			ValidatorType.Pattern,
			ValidatorType.Min,
			ValidatorType.Max,
			ValidatorType.MinLength,
		];
		const expressionTypes = [ValidatorType.Expression, ValidatorType.ItemExpression];

		const control = this.formBuilder.group({
			[ValidatorControlKeys.Type]: [validator.type, ValidatorFunctions.required('Type is required')],
			[ValidatorControlKeys.Message]: [validator.message, ValidatorFunctions.required('Message is required')],
			[ValidatorControlKeys.Value]: [{ value: validator.value, disabled: !valueTypes.includes(validator.type) }, ValidatorFunctions.compose([
				ValidatorFunctions.custom((v) => !valueTypes.includes(validator.type) || !ValidatorFunctions.isEmpty(v), 'A value is required'),
				ValidatorFunctions.custom((v) => !v || !expressionTypes.includes(validator.type) || this.expressionParser.validate(v), invalidExpressionErrorMessage),
			])],
		});

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	buildTransitionControl(meta: FormFieldMetadata, transition: FormEditorTransition, skipSubmitted = false): UfControlGroup {

		const actionControl = this.formBuilder.control(transition.action, ValidatorFunctions.compose([
			ValidatorFunctions.required('Action is required'),
			ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_TRANSITION_STATUS_REGEX, 'Action contains invalid characters'),
		]));

		const actionLabelControl = this.formBuilder.control(transition.actionLabel, ValidatorFunctions.required(labelIsRequiredErrorMessage));

		if (transition.isNew) {
			this.fieldsSubscriptions.get(meta.uuid)?.add(actionLabelControl.valueChanges.subscribe((v) => {
				actionControl.setValue(IdentifierFunctions.pascalize(v), { emitEvent: false });
			}));
		}

		const sectionsRolesControlPath = `../../../${FieldControlKeys.Roles}`; // from transition.*
		const rolesAccessor = new ControlAccessor();
		const rolesControl = this.formBuilder.control(
			transition.roles,
			ValidatorFunctions.custom((roles: string[] | null) => {
				if (!rolesAccessor.control) {
					return true;
				}
				const sectionRoles = rolesAccessor.get(sectionsRolesControlPath)[0]?.value as string[] | undefined;

				if (!roles || !sectionRoles?.length) {
					return true;
				}

				return roles.filter((r) => !sectionRoles.includes(r)).length === 0;
			}, 'Role(s) must be used in this section'),
			this.asyncRolesValidator,
			{ deps: [sectionsRolesControlPath] },
		);

		rolesAccessor.control = rolesControl;

		const showIfControl = this.formBuilder.control(transition.showIf, ValidatorFunctions.compose([
			ValidatorFunctions.isValidExpression(this.expressionParser, invalidExpressionErrorMessage),
			// TODO Restore once expression identifiers validation is implemented
			// (c) => this.expressionIdentifiersValidator(c, meta),
		]), undefined, { deps: [this.status.fieldsIdentifier] });

		const transitionControl = this.formBuilder.group({
			[TransitionControlKeys.Source]: [transition.source, ValidatorFunctions.compose([
				ValidatorFunctions.required('Start status is required'),
				ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_TRANSITION_STATUS_REGEX, 'Start status contains invalid characters'),
			])],
			[TransitionControlKeys.Target]: [transition.target, ValidatorFunctions.compose([
				ValidatorFunctions.required('Target status is required'),
				ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_TRANSITION_STATUS_REGEX, 'Target contains invalid characters'),
			])],
			[TransitionControlKeys.Action]: actionControl,
			[TransitionControlKeys.ActionLabel]: actionLabelControl,
			[TransitionControlKeys.Result]: [transition.result, ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_TRANSITION_STATUS_REGEX, 'Result contains invalid characters')],
			[TransitionControlKeys.Validate]: transition.validate,
			[TransitionControlKeys.Roles]: rolesControl,
			[TransitionControlKeys.ShowIf]: showIfControl,
			[TransitionControlKeys.Tags]: this.buildTagsControl(meta.uuid, transition.tags),
			[TransitionControlKeys.HasPersistentVisibility]: transition.hasPersistentVisibility,
			[TransitionControlKeys.KeepOpen]: transition.keepOpen,
			[TransitionControlKeys.Description]: transition.description,
			[TransitionControlKeys.IsNew]: transition.isNew,
		}, {
			options: {
				deps: [
					`$.${FieldControlKeys.Fields}[*].${FieldControlKeys.Transitions}[*].${TransitionControlKeys.Action}`,
					`$.${FieldControlKeys.Fields}[*].${FieldControlKeys.Transitions}[*].${TransitionControlKeys.Source}`,
					`$.${FieldControlKeys.Fields}[*].${FieldControlKeys.Transitions}[*].${TransitionControlKeys.Target}`,
				],
			},
		});

		// TODO Remove this code once verified that Transition.notify is not used anywhere across Unifii
		// START - Maintain the former attribute 'notify' when it's value is true
		const notify = (transition as any).notify;

		if (isBoolean(notify) && notify) {
			transitionControl.addControl('notify', this.formBuilder.control(notify));
		}
		// END - Maintain the former attribute 'notify' when it's value is true

		const transitionsAccessor = new ControlAccessor(transitionControl);
		const transitionsPath = `$.${FieldControlKeys.Fields}[*].${FieldControlKeys.Transitions}[*]`;

		// Transitions are "similar" when they have same Source and Action but different Target, this is not allowed
		transitionControl.addValidators([ValidatorFunctions.custom(() => {

			if (!transitionsAccessor.control) {
				return true;
			}

			const myAction = transitionControl.get(TransitionControlKeys.Action)?.value;
			const mySource = transitionControl.get(TransitionControlKeys.Source)?.value;
			const myTarget = transitionControl.get(TransitionControlKeys.Target)?.value;

			if (!myAction || !mySource || !myTarget) {
				return true;
			}

			const otherTransitionsControl = transitionsAccessor.get(transitionsPath) as UfControlGroup[];
			// console.log('transitionControls', otherTransitionsControl);

			const match = otherTransitionsControl.find((tc) => {
				const action = tc.get(TransitionControlKeys.Action)?.value;
				const source = tc.get(TransitionControlKeys.Source)?.value;
				const target = tc.get(TransitionControlKeys.Target)?.value;

				// console.log(`sources ${source}|${mySource} actions ${action}|${myAction} targets ${target}|${myTarget} => isSimilar ${isSimilar}`);
				return source === mySource && action === myAction && target !== myTarget;
			});

			return match == null;
		}, 'A transition with same source and action but different target already exists')]);

		if (this.setSubmitted && !skipSubmitted) {
			transitionControl.setSubmitted(true);
		}

		return transitionControl;
	}

	private buildHierarchyConfigControl(hierarchyConfig?: FormEditorHierarchyConfiguration): UfControlGroup | null {

		const control = this.formBuilder.group({
			[HierarchyConfigControlKeys.Ceiling]: hierarchyConfig?.ceiling,
			[HierarchyConfigControlKeys.SelectionMode]: [hierarchyConfig?.selectionMode, ValidatorFunctions.required('A value is required')],
		});

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildSortControl(meta: FormFieldMetadata, repeatSortablePropertiesCtrl: UfControl, sort?: string): UfControl | null {
		const control = this.formBuilder.control(
			sort,
			ValidatorFunctions.custom((v) => {
				if (ValidatorFunctions.isEmpty(v)) {
					return true;
				}

				const sortName = SortStatus.fromString(v)?.name;

				if (!sortName) {
					return false;
				}

				const properties = repeatSortablePropertiesCtrl.getRawValue() as DataPropertyDescriptor[];

				return properties.find((dpd) => dpd.identifier === sortName) != null;
			}, 'Sort field not found'),
			undefined,
			{ deps: [repeatSortablePropertiesCtrl] },
		);

		this.fieldsSubscriptions.get(meta.uuid)?.add(
			repeatSortablePropertiesCtrl.valueChanges.subscribe((properties: DataPropertyDescriptor[]) => {
				const sortValue = SortStatus.fromString(control.value)?.name;

				if (!sortValue) {
					return;
				}
				if (!properties.find((p) => sortValue === p.identifier)) {
					control.setValue(null);
				}
			}));

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildOptionsControl(meta: FormFieldMetadata, optionType: AttributeParentType, options?: FormEditorOption[]): UfControlArray {

		if (!meta.options) {
			return this.formBuilder.array([]);
		}

		const dataSourcePath = `../${FieldControlKeys.DataSourceConfig}`;
		const variationsPath = `../${FieldControlKeys.Variations}`;

		const controlDependencies = [dataSourcePath];

		if (optionType === AttributeParentType.FieldOptionType) {
			controlDependencies.push(variationsPath);
		}

		const optionsControl = this.formBuilder.array(
			(options ?? []).map((o) => this.buildOptionControl(meta, optionType, o)),
			undefined, undefined, { deps: controlDependencies },
		);

		const controlAccessor = new ControlAccessor(optionsControl);

		const hasVariations = (): boolean => {
			const variationsControl = controlAccessor.get(variationsPath)[0] as UfControlArray | undefined;

			return !!variationsControl?.length;
		};

		if (meta.type === FieldType.Bool) {
			optionsControl.addValidators(ValidatorFunctions.compose([
				ValidatorFunctions.custom((v) => hasVariations() || v.length === 2, 'Must have two options'),
				ValidatorFunctions.custom((v: FormEditorOption[]) =>
					hasVariations() || v.length !== 2 ||
                    (v.filter((o) => o.identifier === 'true').length === 1 && v.filter((o) => o.identifier === 'false').length === 1),
				`Must have a 'true' option and a 'false' option`,
				),
			]) as ValidatorFn);
		}

		if ([FieldType.MultiChoice, FieldType.Survey].includes(meta.type)) {
			optionsControl.addValidators(ValidatorFunctions.custom((v) => hasVariations() || v.length !== 0, 'Must have at least one option'));
		}

		if (meta.type === FieldType.Choice) {
			optionsControl.addValidators(ValidatorFunctions.custom((v: any[]) => {

				if (hasVariations()) {
					return true;
				}

				const dataSourceCtrl = controlAccessor.get(dataSourcePath)[0] as UfFormControl | undefined;

				if (!dataSourceCtrl) {
					return true;
				}

				return !!v.length || !!dataSourceCtrl.value;
			}, 'Options or Data Source are required'));
		}

		if (this.setSubmitted) {
			optionsControl.setSubmitted(true);
		}

		return optionsControl;
	}

	private buildVariationsControl(meta: FormFieldMetadata, variations?: FormEditorVariation[]): UfControlArray {

		if (!meta.variations || variations == null) {
			return this.formBuilder.array([]);
		}

		const control = this.formBuilder.array(variations.map((v) => this.buildVariationControl(meta, v)));

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildValidatorsControl(meta: FormFieldMetadata, validators?: FieldValidator[]): UfControlArray {

		if (!meta.validators || validators == null) {
			return this.formBuilder.array([]);
		}

		const control = this.formBuilder.array(validators.map((v) => this.buildValidatorControl(v)));

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildFieldsControl(fields: FormEditorField[], parent?: FormEditorField, parentPath?: string[] ): UfControlArray {

		const controlArray = this.formBuilder.array(fields.map((f) => this.buildFieldControl(f, parent, parentPath)));

		// Root's fields
		if (!parent) {
			if (this.limitService.sectionsLimit != null) {
				controlArray.addValidators(ValidatorFunctions.maxLength(this.limitService.sectionsLimit, 'Sections limit exceeded'));
			}

			controlArray.addValidators(ValidatorFunctions.compose([
				ValidatorFunctions.custom((v) => {
					const sections = ((v ?? []) as FormEditorField[]).filter((field) => field.type === FieldType.Section);

					return sections.length > 0;
				}, 'At least one section is required'),
				ValidatorFunctions.custom((v) => {

					const sections = ((v ?? []) as FormEditorField[]).filter((field) => field.type === FieldType.Section);

					if (sections.length === 0) {
						return true; // Covered by required at least one section
					}

					for (const section of sections) {
						const match = section.transitions?.find((transition) => transition.source === WorkflowStartState);

						if (match) {
							return true;
						}
					}

					return false;
				}, `A workflow transition with Start Status "${WorkflowStartState}" is required`),
			]) as ValidatorFn);
		}

		if (parent?.type === FieldType.Survey) {
			controlArray.addValidators(ValidatorFunctions.compose([
				ValidatorFunctions.minLength(1, 'At least one question is required'),
				ValidatorFunctions.custom((v: FormEditorField[]) => {
					const count = v.filter((field) => field.type === FieldType.Choice).length;

					return count === 0 || count === v.length;
				}, 'A mix of Choice and Multi Choice fields is not allowed'),
				ValidatorFunctions.custom((v: FormEditorField[]) =>
					v.filter((field) => [FieldType.Choice, FieldType.MultiChoice].includes(field.type)).length === v.length
				, 'Only Choice and Multi Choice fields are allowed here'),
			]) as ValidatorFn);

			controlArray.markAsTouched();
		}

		if (parent?.type === FieldType.Carousel) {
			controlArray.addValidators(ValidatorFunctions.compose([
				ValidatorFunctions.minLength(1, 'At least one item is required'),
				ValidatorFunctions.custom((v: FormEditorField[]) =>
					!v.find((field) => field.type !== FieldType.Content)
				, 'Only Content fields are allowed here'),
			]) as ValidatorFn);

			controlArray.markAsTouched();
		}

		if (this.setSubmitted) {
			controlArray.setSubmitted(true);
		}

		return controlArray;
	}

	private buildFieldTransitions(meta: FormFieldMetadata, transitions?: FormEditorTransition[]): UfControlArray {

		if (!meta.transitions) {
			return this.formBuilder.array([]);
		}

		const controlArray = this.formBuilder.array(transitions?.map((t) => this.buildTransitionControl(meta, t)) ?? []);

		controlArray.addValidators(ValidatorFunctions.minLength(1, 'At least one workflow is required'));
		controlArray.markAsTouched();

		if (this.setSubmitted) {
			controlArray.setSubmitted(true);
		}

		return controlArray;
	}

	private buildCurrencyControl(meta: FormFieldMetadata, currency?: string): UfControl {

		if (!meta.currency) {
			return this.formBuilder.control(null);
		}

		const control = this.formBuilder.control(currency, ValidatorFunctions.custom((v) => !ValidatorFunctions.isEmpty(v), 'Currency is required.'));

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildStepControl(meta: FormFieldMetadata, step?: string): UfControl {

		let control;

		if (!meta.step) {
			control = this.formBuilder.control(null);
		} else {
			control = this.formBuilder.control(step, ValidatorFunctions.custom((v: string) =>
				!v || FORM_EDITOR_CONSTANTS.TIME_STEP_VALUES.findIndex((o) => o.identifier === v) >= 0, 'Interval needs to be a valid step.'),
			);
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildDatasourceConfigControl(meta: FormFieldMetadata, parentType: AttributeParentType, dataSourceConfig?: UcDefinitionDataSource): UfControl {

		let control: UfControl;
		const controlAccessor = new ControlAccessor();
		const dataCapturesPath = parentType === AttributeParentType.FieldOptionType ? `../${FieldControlKeys.DataCaptures}` : `../../../${FieldControlKeys.DataCaptures}`;

		if (!meta.dataSourceConfig) {
			control = this.formBuilder.control(null);
		} else {

			const validators = [ValidatorFunctions.custom((v) => meta.type !== FieldType.Lookup || !ValidatorFunctions.isEmpty(v), 'Data Source is required.')];

			if (parentType === AttributeParentType.FieldOptionType) {
				validators.push(
					ValidatorFunctions.custom((v) => {
						if (!controlAccessor.control) {
							return true;
						}
						const dataCapturesControl = controlAccessor.get(dataCapturesPath)[0];

						// if data capture, but no target identifier
						return !((dataCapturesControl?.getRawValue().length && !v?.findBy));
					}, 'FindBy required for Data Capture'),
					ValidatorFunctions.custom((v) => {
						if (!controlAccessor.control) {
							return true;
						}
						const dataCapturesControl = controlAccessor.get(dataCapturesPath)[0];

						// if no data capture, but target identifier
						return !((dataCapturesControl && !dataCapturesControl.getRawValue().length && v?.findBy));
					}, 'FindBy requires Data Capture'),
				);
			}

			control = this.formBuilder.control(
				dataSourceConfig,
				ValidatorFunctions.compose(validators),
				this.asyncDataSourceValidator,
				{ deps: parentType === AttributeParentType.FieldOptionType ? [dataCapturesPath] : undefined },
			);

			if (meta.type === FieldType.Repeat) {
				// A change to the Repeat DS can lead to duplicated identifier against it's scoped Fields vs the DS mappings
				this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.subscribe(() => {
					this.fieldsScopeManager.notifyScopeIdentifiersStatusChange(meta.uuid);
				}));
			}

			controlAccessor.control = control;
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.subscribe((v) => {
			// Set avoidDuplicates to false when DataSource is deleted
			if (v == null) {
				const avoidDuplicatesCtrl = controlAccessor.get(`../${FieldControlKeys.AvoidDuplicates}`)[0];

				// Exists only for Repeat field type
				if (avoidDuplicatesCtrl) {
					avoidDuplicatesCtrl.setValue(null);
				}
			}

			const dataCapturesControl = controlAccessor.get(`../${FieldControlKeys.DataCaptures}`)[0];

			if (dataCapturesControl) {
				const toDisable = this.isDataCaptureDisabled(meta, v);

				if (toDisable && !dataCapturesControl.disabled) {
					dataCapturesControl.disable();
					dataCapturesControl.reset([]);
				}
				if (!toDisable && dataCapturesControl.disabled) {
					dataCapturesControl.enable();
				}
			}

			// Find this DS scopeUuid associated uuid control (a Repeat) to update its RepeatSortableProperties control value
			this.fieldsScopeManager.updateRepeatSortableProperties(meta.scopeUuid);
		}));

		return control;
	}

	private isDataCaptureDisabled(meta: FormFieldMetadata, dataSourceConfig: UcDefinitionDataSource | undefined): boolean {

		if (!meta.dataSourceConfig) {
			return false;
		}

		if (!dataSourceConfig) {
			return true;
		}

		return ![DataSourceType.Bucket, DataSourceType.Collection, DataSourceType.Users].includes(dataSourceConfig.type);
	}

	private isActiveBackgroundTintedDisabled(template?: FieldTemplate): boolean {
		return !template;
	}

	private isExpandWhenInactiveDisabled(alwaysExpanded?: boolean): boolean {
		return !!alwaysExpanded;
	}

	private asyncRolesValidator = async(control: AbstractControl): Promise<ValidationErrors | null> => {

		const message = await FormEditorFunctions.missingRoleError(this.cache, control.value);

		if (message) {
			return { message };
		}

		return null;
	};

	private asyncDataSourceValidator = async(control: AbstractControl): Promise<ValidationErrors | null> => {
		const dataSource: DataSource | null = control.value;

		if (dataSource == null) {
			return null;
		}

		const validator = new DataSourceValidator(this.cache, this.dataPropertyInfoService);
		const message = await validator.validate(dataSource);

		if (message != null) {
			return { message };
		}

		return null;
	};

	private buildFieldIdentifierControl(meta: FormFieldMetadata, id?: number, identifier?: string): UfControl {

		let control: UfControl;

		if (!meta.identifier) {
			control = this.formBuilder.control(null);
		} else {
			// Build control
			const controlAccessor = new ControlAccessor();

			control = this.formBuilder.control(identifier, ValidatorFunctions.compose([
				ValidatorFunctions.custom((v) => !meta.identifier || !ValidatorFunctions.isEmpty(v), identifierIsRequiredErrorMessage),
				FieldIdentifierValidCharactersValidator,
				IdentifierNoEmptySpacesValidator,
				ValidatorFunctions.maxLength(this.status.identifiersMaxLength.field, `Identifier can't be longer than ${this.status.identifiersMaxLength.field} characters`),
				ValidatorFunctions.custom((v) => !/^toDate$|^add$|^toTime$|^id$/.test(v), `This is a reserved term in the system`),
				ValidatorFunctions.custom((v: string | null) => {

					if (!controlAccessor.control) {
						return true;
					}

					const scopeUuidCtrl = controlAccessor.get(`../${FieldControlKeys.ScopeUuid}`)[0] as UfControl | undefined;

					if (!scopeUuidCtrl) {
						return true;
					}

					const scopeUuid = scopeUuidCtrl.value as string;
					const scope = this.fieldsScopeManager.getScope(scopeUuid);

					// Use scopeUuid to try to retrieve the Repeat container control's DataSource
					const scopedDataSource = this.status.fieldByUuid.get(scopeUuid)?.get(FieldControlKeys.DataSourceConfig)?.value as DataSource | undefined;

					// A match between one of the scoped DataSource 'to' identifiers with the field identifier would lead to an identifier duplication
					if (v && scopedDataSource?.outputs?.[v]) {
						return false;
					}

					return Array.from(scope.values()).find((i) => {
						// Different field
						if (i.uuid === meta.uuid) {
							return false; // same field
						}
						if (i.identifier == null || v == null) {
							return false; // field identifier required validator will cover this case
						}

						return i.identifier.toLowerCase() === v.toLowerCase();
					}) == null;
				}, 'Identifier needs to be unique'),
			]));

			if (this.status.hasBeenPublished && id) {
				this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.subscribe(() => {
					// Warning unsafe identifier changes
					this.toast.warning('Editing your identifier after your field is published may cause errors with your Form');
				}));
			}

			this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.pipe(debounceTime(FORM_EDITOR_CONSTANTS.INPUT_DEBOUNCE_SHORT)).subscribe(() => {

				const fieldControl = controlAccessor.get(`../`)[0] as UfControlGroup | undefined;

				if (!fieldControl) {
					return;
				}

				const pathControl = controlAccessor.get(`../${FieldControlKeys.Path}`)[0] as UfControl | undefined;

				if (!pathControl) {
					return;
				}

				const formEditorField = fieldControl.value as FormEditorField;

				pathControl.setValue(FormEditorFunctions.formEditorFieldPath(formEditorField, this.status));
			}));

			controlAccessor.control = control;
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildFieldShortLabelControl(meta: FormFieldMetadata, fieldId?: number, shortLabel?: string): UfControl {

		const control = this.formBuilder.control(shortLabel);
		const controlAccessor = new ControlAccessor(control);

		if (fieldId == null && meta.identifier) {
			this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.pipe(debounceTime(FORM_EDITOR_CONSTANTS.INPUT_DEBOUNCE_SHORT)).subscribe((v) => {

				const identifierControl = controlAccessor.get(`../${FieldControlKeys.Identifier}`)[0] as UfControl | undefined;
				const scopeUuidControl = controlAccessor.get(`../${FieldControlKeys.ScopeUuid}`)[0];

				if (identifierControl != null && scopeUuidControl != null) {
					const otherFieldsIdentifiers = Array
						.from(this.fieldsScopeManager.getScope(scopeUuidControl.value as string).values())
						.filter((i) => i.uuid !== meta.uuid)
						.map((i) => i.identifier as string);

					identifierControl.setValue(FormEditorFunctions.generateSafeIdentifier(IdentifierFunctions.camelize(v), otherFieldsIdentifiers), { emitEvent: true });
					identifierControl.markAsTouched();
				}
			}));
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildFieldLabelControl(meta: FormFieldMetadata, fieldId?: number, label?: string): UfControl {

		const control = this.formBuilder.control(label, ValidatorFunctions.compose([
			ValidatorFunctions.custom((v) => !meta.label || !ValidatorFunctions.isEmpty(v), labelIsRequiredErrorMessage),
			ValidatorFunctions.custom((v) => ValidatorFunctions.isEmpty(v) || (v as string).split('\n').length < 2, 'New lines are invalid in labels'),
		]));

		const controlAccessor = new ControlAccessor(control);

		if (meta.identifier && fieldId == null) { // a new field
			this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.pipe(debounceTime(FORM_EDITOR_CONSTANTS.INPUT_DEBOUNCE_SHORT)).subscribe((v) => {

				const shortLabelValue = (controlAccessor.get(`../${FieldControlKeys.ShortLabel}`)[0]?.value ?? '') as string;
				const identifierControl = controlAccessor.get(`../${FieldControlKeys.Identifier}`)[0];
				const scopeUuidControl = controlAccessor.get(`../${FieldControlKeys.ScopeUuid}`)[0];

				if (!shortLabelValue.trim().length && identifierControl && scopeUuidControl) {
					const otherFieldsIdentifiers = Array
						.from(this.fieldsScopeManager.getScope(scopeUuidControl.value as string).values())
						.filter((i) => i.uuid !== meta.uuid)
						.map((i) => i.identifier as string);

					identifierControl.setValue(FormEditorFunctions.generateSafeIdentifier(IdentifierFunctions.camelize(v), otherFieldsIdentifiers), { emitEvent: true });
					identifierControl.markAsTouched();
				}
			}));
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildFieldAllowedTypesControl(allowedTypes?: LinkContentType[]): UfControl {

		const control = this.formBuilder.control(allowedTypes, ValidatorFunctions.custom((v) => !ValidatorFunctions.isEmpty(v), `Allowed Types is required`));

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	/** Strange inputs as it is used by Definition.tags, Field.tags, Transition.tags */
	private buildTagsControl(uuid: string, tags?: string[], meta?: FormFieldMetadata): UfControl {

		let control;

		if (meta?.tags === false) {
			return this.formBuilder.control(null);
		} else {

			control = this.formBuilder.control(tags, ValidatorFunctions.custom((v: string[] | undefined) => !((v ?? [])).find((tag) => tag.includes(' ')), `Tags can't contain spaces`));

			this.fieldsSubscriptions.get(uuid)?.add(control.valueChanges.subscribe((v) => {
				for (const tag of (v ?? [])) {
					this.status.tags.add(tag);
				}
			}));
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildIsRequiredControl(meta: FormFieldMetadata, isRequired: boolean | undefined, scrollTime: number | ''): UfControl {

		let control;

		if (!meta.isRequired) {
			control = this.formBuilder.control(null);
		} else {
			control = this.formBuilder.control(isRequired);
		}

		if (meta.type === FieldType.Carousel && scrollTime) {
			control.setValue(false);
			control.disable();
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildScrollTimeControl(meta: FormFieldMetadata, scrollTime: number | ''): UfControl {

		let control;

		if (!meta.scrollTime) {
			control = this.formBuilder.control(null);
		} else {

			control = this.formBuilder.control(scrollTime);
			const controlAccessor = new ControlAccessor(control);

			this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.subscribe((v) => {

				const isRequiredControl = controlAccessor.get(`../${FieldControlKeys.IsRequired}`)[0];

				if (!isRequiredControl) {
					return;
				}

				if (v) {
					isRequiredControl.setValue(false);
					isRequiredControl.disable();
				} else {
					isRequiredControl.enable();
				}

			}));
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildFieldWidthControl(meta: FormFieldMetadata, width?: FieldWidth): UfControl {

		let control;

		if (!meta.width) {
			control = this.formBuilder.control(null);
		} else {

			control = this.formBuilder.control(width);
			const controlAccessor = new ControlAccessor(control);

			this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.subscribe((v) => {

				if (meta.breakAfter && v === FieldWidth.Quarter) {
					this.toast.warning('All inputs require a minimum width and may not display as a full quarter');
				}

				const columnCountValue = controlAccessor.get(`../${FieldControlKeys.ColumnCount}`)[0]?.value as number | undefined ?? 0;

				if (v !== FieldWidth.Stretch && (columnCountValue > 1) && [FieldType.Choice, FieldType.MultiChoice].includes(meta.type)) {
					this.toast.warning('Inputs with columns should be set to full width for better readability');
				}

				const breakAfterControl = controlAccessor.get(`../${FieldControlKeys.BreakAfter}`)[0] as UfControl | undefined;

				if (breakAfterControl != null && v === FieldWidth.Stretch) {
					breakAfterControl.setValue(false, { emitEvent: false });
				}
			}));
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildAutofillControl(meta: FormFieldMetadata, autofill?: string): UfControl | null {
		if (!meta.autofill) {
			return null;
		}

		const control = this.formBuilder.control(autofill, ValidatorFunctions.compose([
			ValidatorFunctions.isValidExpression(this.expressionParser, invalidExpressionErrorMessage),
			// TODO Restore once expression identifiers validation is implemented
			// (c) => this.expressionIdentifiersValidator(c, meta),
		]), undefined, { deps: [this.status.fieldsIdentifier] });

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildFieldTemplateControl(meta: FormFieldMetadata, template?: FieldTemplate): UfControl {

		let control;

		if (!meta.template) {
			control = this.formBuilder.control(null);
		} else {

			const isReadOnlyControlPath = `../${FieldControlKeys.IsReadOnly}`;
			const bindToControlPath = `../${FieldControlKeys.BindTo}`;
			const autofillControlPath = `../${FieldControlKeys.Autofill}`;
			const controlAccessor = new ControlAccessor();

			control = this.formBuilder.control(
				template,
				ValidatorFunctions.compose([
					ValidatorFunctions.custom((v) => {

						if (v !== FieldTemplate.Hidden || !controlAccessor.control) {
							return true;
						}

						return !!controlAccessor.get(isReadOnlyControlPath)[0]?.value;
					}, `Hidden template requires 'Read only'`),
					ValidatorFunctions.custom((v) => {

						if (v !== FieldTemplate.Hidden || !controlAccessor.control) {
							return true;
						}

						const autofillValue = controlAccessor.get(autofillControlPath)[0]?.value as string | undefined;
						const bindToValue = controlAccessor.get(bindToControlPath)[0]?.value as string | undefined;

						return !ValidatorFunctions.isEmpty(autofillValue) || !ValidatorFunctions.isEmpty(bindToValue);
					}, `Hidden template requires 'Autofill' or 'Bind to'`),
				]),
				undefined,
				{ deps: [isReadOnlyControlPath, bindToControlPath, autofillControlPath] },
			);

			controlAccessor.control = control;

			this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.subscribe((v) => {

				if (meta.type === FieldType.Repeat && v === FieldTemplate.Table) {
					this.toast.warning('This template will restrict how its fields are displayed');
				}

				const activeBackgroundTintedControl = controlAccessor.get(`../${FieldControlKeys.ActiveBackgroundTinted}`)[0];
				const expandWhenInactiveControl = controlAccessor.get(`../${FieldControlKeys.ExpandWhenInactive}`)[0];
				const hideWhenInactiveControl = controlAccessor.get(`../${FieldControlKeys.HideWhenInactive}`)[0];

				if (activeBackgroundTintedControl) {
					const toDisable = this.isActiveBackgroundTintedDisabled(v);

					if (toDisable && activeBackgroundTintedControl.enabled) {
						activeBackgroundTintedControl.disable();
						activeBackgroundTintedControl.reset(undefined);
					}
					if (!toDisable && activeBackgroundTintedControl.disabled) {
						activeBackgroundTintedControl.enable();
					}

					if (v && v !== FieldTemplate.Content) {
						activeBackgroundTintedControl.reset(true);
					}
				}

				if (v && expandWhenInactiveControl && !expandWhenInactiveControl.disabled && !hideWhenInactiveControl?.value) {
					expandWhenInactiveControl.reset(true);
				}

			}));
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildFieldAlwaysExpandedControl(meta: FormFieldMetadata, alwaysExpanded?: boolean): UfControl {

		let control;

		if (!meta.alwaysExpanded) {
			control = this.formBuilder.control(null);
		} else {

			control = this.formBuilder.control(alwaysExpanded);

			const controlAccessor = new ControlAccessor(control);

			this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.subscribe((alwaysExpandedValue) => {

				const expandWhenInactiveControl = controlAccessor.get(`../${FieldControlKeys.ExpandWhenInactive}`)[0];

				if (expandWhenInactiveControl) {
					const toDisable = this.isExpandWhenInactiveDisabled(!!alwaysExpandedValue);

					if (toDisable && !expandWhenInactiveControl.disabled) {
						expandWhenInactiveControl.disable();
						expandWhenInactiveControl.reset(undefined);
					}
					if (!toDisable && expandWhenInactiveControl.disabled) {
						expandWhenInactiveControl.enable();
					}
				}
			}));
		}

		return control;
	}

	private buildFieldExpandWhenInactiveControl(meta: FormFieldMetadata, expandWhenInactive?: boolean, alwaysExpanded?: boolean): UfControl {

		let control;

		if (!meta.expandWhenInactive) {
			control = this.formBuilder.control(null);
		} else {

			control = this.formBuilder.control(expandWhenInactive);

			const controlAccessor = new ControlAccessor(control);

			this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.subscribe((expandWhenInactiveValue) => {

				if (!expandWhenInactiveValue) {
					return;
				}

				const hideWhenInactiveControl = controlAccessor.get(`../${FieldControlKeys.HideWhenInactive}`)[0];

				if (hideWhenInactiveControl) {
					hideWhenInactiveControl.reset(undefined);
				}
			}));
		}

		if (this.isExpandWhenInactiveDisabled(alwaysExpanded)) {
			control.disable();
		}

		return control;
	}

	private buildFieldHideWhenInactiveControl(meta: FormFieldMetadata, hideWhenInactive?: boolean): UfControl {

		let control;

		if (!meta.hideWhenInactive) {
			control = this.formBuilder.control(null);
		} else {

			control = this.formBuilder.control(hideWhenInactive);

			const controlAccessor = new ControlAccessor(control);

			this.fieldsSubscriptions.get(meta.uuid)?.add(control.valueChanges.subscribe((hideWhenInactiveValue) => {

				if (!hideWhenInactiveValue) {
					return;
				}

				const expandWhenInactiveControl = controlAccessor.get(`../${FieldControlKeys.ExpandWhenInactive}`)[0];

				if (expandWhenInactiveControl) {
					expandWhenInactiveControl.reset(undefined);
				}
			}));
		}

		return control;
	}

	private buildFieldRolesControl(show: boolean, roles: string[]): UfControl {

		let control;

		if (!show) {
			control = this.formBuilder.control(null);
		} else {
			control = this.formBuilder.control(
				roles,
				undefined,
				this.asyncRolesValidator,
			);
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildShowIfControl(meta: FormFieldMetadata, showIf?: string): UfControl {

		let control;

		if (!meta.showIf) {
			control = this.formBuilder.control(null);
		} else {
			control = this.formBuilder.control(showIf, ValidatorFunctions.compose([
				ValidatorFunctions.isValidExpression(this.expressionParser, invalidExpressionErrorMessage),
				// TODO Restore once expression identifiers validation is implemented
				// (c) => this.expressionIdentifiersValidator(c, meta),
			]), undefined, { deps: [this.status.fieldsIdentifier] });
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	/* TODO Complete implementation of this method
    private expressionIdentifiersValidator(control: AbstractControl, meta: FormFieldMetadata): ValidationErrors | null {

        return null;

        const expression = control.value as string | null;
        if (!expression) {
            return null;
        }

        const fieldControl = this.status.fieldByUuid.get(meta.uuid);
        if (!fieldControl) {
            return null;
        }

        const identifiersMap = FormEditorFunctions.getFieldIdentifiersScopes(this.fieldsScopeManager, fieldControl);
        const missingIdentifiers = FormEditorFunctions.getNonAvailableIdentifiers(expression, identifiersMap);
        return missingIdentifiers.length ? { message: `Undefined identifiers: ${missingIdentifiers.join(', ')}` } : null;
    }
    */

	private buildShowOnControl(meta: FormFieldMetadata, showOn?: string): UfControl {
		let control;

		if (!meta.showOn) {
			control = this.formBuilder.control(null);
		} else {

			const transitionsControlPath = `../../../${FieldControlKeys.Transitions}`;
			const controlAccessor = new ControlAccessor();

			control = this.formBuilder.control(showOn, ValidatorFunctions.compose([
				ValidatorFunctions.custom((v) => !ValidatorFunctions.isEmpty(v), 'Show On is required'),
				ValidatorFunctions.noWhiteSpaces(`Can't contains white spaces`),
				ValidatorFunctions.custom((v) => {

					// console.log('ShowOn custom validator', v, controlAccessor.control);

					if (!v || controlAccessor.control == null) {
						return true;
					}

					const deps = controlAccessor.get(`${transitionsControlPath}[*].${TransitionControlKeys.Action}`);

					return deps.map((c) => c.value as unknown).includes(v);

				}, 'Show on must match a workflow action'),
			]), undefined, { deps: [`${transitionsControlPath}`] }); /** [*] */

			controlAccessor.control = control;
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildAutoDetectControl(meta: FormFieldMetadata, field: FormEditorField): UfControl {

		let control;

		if (!meta.autoDetect) {
			control = this.formBuilder.control(null);
		} else {
			const fieldNestedControlPath = field.type === FieldType.GeoLocation ? `../${FieldControlKeys.GeoLocationNested}` : `../${FieldControlKeys.AddressNested}`;
			const controlAccessor = new ControlAccessor();

			control = this.formBuilder.control(
				field.autoDetect,
				ValidatorFunctions.custom((value: boolean) => {

					if (value) {
						return true;
					}

					const nestedFields = (controlAccessor.get(fieldNestedControlPath)[0]?.value ?? {}) as Record<string, FormNestedField>;

					return Object.values(nestedFields).some((nestedField) => nestedField.visible);
				}, 'Auto Detect is required when nested fields are not visible'),
				undefined,
				{ deps: [fieldNestedControlPath] },
			);

			controlAccessor.control = control;
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;

	}

	private buildAddressNestedControl(meta: FormFieldMetadata, addressNested?: FormAddressNestedFields): UfControlGroup {

		const control = this.formBuilder.group({});

		if (meta.addressNested && addressNested) {

			const nested = addressNested;

			for (const key of objectKeys(nested)) {
				control.addControl(key, this.buildNestedFieldControl(nested[key]));
			}
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildGeoLocationNestedControl(meta: FormFieldMetadata, geoLocationNested?: FormGeoLocationNestedFields): UfControlGroup {

		const control = this.formBuilder.group({});

		if (meta.geoLocationNested && geoLocationNested) {

			const nested = geoLocationNested;

			for (const key of objectKeys(nested)) {
				control.addControl(key, this.buildNestedFieldControl(nested[key]));
			}
		}

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildNestedFieldControl(nestedField: FormNestedField): UfControlGroup {

		const visibleControl = this.buildNestedVisibleFieldControl(nestedField.visible);
		const control = this.formBuilder.group({
			[NestedControlKey.Visible]: visibleControl,
			[NestedControlKey.Required]: [nestedField.required, ValidatorFunctions.custom((v) =>
				!v || visibleControl.value === true, 'A required field must be visible too')],
			[NestedControlKey.ReadOnly]: nestedField.readOnly,
		});

		// visibleControl.updateDependencies();

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildNestedVisibleFieldControl(visible: boolean): UfControl {

		const controlAccessor = new ControlAccessor();
		const requiredControlPath = `../${NestedControlKey.Required}`;

		const control = this.formBuilder.control(
			visible,
			ValidatorFunctions.custom((isVisible: boolean | null) => {

				if (controlAccessor.control == null) {
					return true;
				}

				const isRequired = controlAccessor.get(requiredControlPath)[0]?.value as boolean | undefined ?? false;

				return isVisible || !isRequired;
			}, 'A required field must be visible'),
			undefined,
			{ deps: [requiredControlPath] });

		controlAccessor.control = control;

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private buildBindToControl(meta: FormFieldMetadata, bindTo?: string): UfControl {

		let control;

		if (!meta.bindTo) {
			control = this.formBuilder.control(null);
		} else {

			const isReadOnlyControlPath = `../${FieldControlKeys.IsReadOnly}`;
			const controlAccessor = new ControlAccessor();

			control = this.formBuilder.control(
				bindTo,
				ValidatorFunctions.compose([
					ValidatorFunctions.isValidExpression(this.expressionParser, invalidExpressionErrorMessage),
					ValidatorFunctions.custom((v) => {
						if (!controlAccessor.control || meta.type === FieldType.Hierarchy) {
							return true;
						}

						const isReadOnlyValue = controlAccessor.get(isReadOnlyControlPath)[0]?.value as boolean | undefined ?? false;

						return ValidatorFunctions.isEmpty(v) || isReadOnlyValue;
					}, 'bindTo only supported for isReadOnly field'),
				]),
				undefined,
				{ deps: [isReadOnlyControlPath] },
			);

			controlAccessor.control = control;
		}

		control.addDependencies([this.status.fieldsIdentifier]);

		if (this.setSubmitted) {
			control.setSubmitted(true);
		}

		return control;
	}

	private fieldPositionValidator(control: AbstractControl): ValidationErrors {
		if (!(control instanceof UfControlGroup)) {
			return {};
		}
		if (!control.root.value.bucket) {
			return {};
		}
		if (!control.parent) {
			return {};
		}

		const fieldType = control.get(FieldControlKeys.Type)?.value as FieldType | null;

		if (!fieldType) {
			return {};
		}

		const innerDepth = FormEditorFunctions.getFieldControlInnerDept(control);
		const parentControl = control.parent.parent as UfControlGroup | null;
		const parentDepth = parentControl ? FormEditorFunctions.getFieldControlDepth(parentControl) : undefined;
		const grandparentControl = parentControl?.parent?.parent as UfControlGroup | null;

		const error = FormEditorFunctions.getFieldPositionError(control, innerDepth, parentControl ?? undefined, parentDepth, grandparentControl ?? undefined);

		return error ? { message: error } : {};
	}

	private checkIdentifierEditWarning(lastPublishedAtControl: UfControl) {
		if (lastPublishedAtControl.value) {
			this.toast.warning('Editing the identifier after publishing may cause problems.');
		}
	}

	private checkBucketEditWarning(lastPublishedAtControl: UfControl) {
		if (lastPublishedAtControl.value) {
			this.toast.warning('Editing the form data repository after publishing may cause problems.');
		}
	}

	private clearFieldSubscriptions() {
		for (const subscriptions of Array.from(this.fieldsSubscriptions.values())) {
			subscriptions.unsubscribe();
		}
		this.fieldsSubscriptions.clear();
	}

	private initFieldSubscriptions() {
		// Necessary for external usage of Definition controls builderFunctions
		this.fieldsSubscriptions.set(FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID, new Subscription());
	}

	/**
     * @description Creates async validator - which validates if identifier already exists, it returns null if it doesn't
     * it returns an object with the message in case it does, the backend will throw a 404 if the identifier doesn't exist
     * hence why we have a catchError and a validation there.
     * @returns null or { message: string }
     */
	private createFormIdentifierAsyncValidator(identifier?: string): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> =>
			timer(FORM_EDITOR_CONSTANTS.INPUT_DEBOUNCE_NETWORK_CALL).pipe(
				switchMap(() => {
					// if value is the same as initial control value, it's the same record, so no need to validate
					if (isStringNotEmpty(identifier) && control.value === identifier) {
						return of(null);
					}

					return from(this.ucProject.headFormByIdentifier(control.value)).pipe(
						catchError((err) => {
							if (ensureUfError(err).type === ErrorType.NotFound) {
								return of(null);
							}
							throw err;
						}),
						map((forms: Headers | null) => forms ? { message: 'Identifier already exists' } : null),
					);
				}),
			);
	}

}
