@if (control) {
	<uf-expander class="uf-box flat">
		<div expanderHeader class="uf-app-bar flat">
			<div class="title primary">Variations</div>
			@if (control.invalid) {
				<uf-icon name="error" class="error" />
			}
			@if (control.length) {
				<uc-expander-controls [container]="variationsWrap" />
			}
		</div>
		<div #variationsWrap expanderBody class="pad small">
			@if (control.length) {
				<uc-drag-list [parent]="control" [items]="control.controls" canDrop="false" canReorder="true">
					@for (variation of control.controls | asUfControlsGroups; track variation; let i = $index) {
						<uf-expander dragItem class="uf-box flat draggable gap-sm-bottom">
							<div expanderHeader dragHandle class="uf-app-bar flat">
								<uf-icon [name]="getIcon(variation)" />
								<div class="title primary">{{ variation.get(variationControlKeys.Name)?.value }}</div>
								@if (!getIsPublished(variation)) {
									<uf-icon name="unpublished" title="This content is unpublished" class="icon-warning" />
								}
								@if (variation.invalid) {
									<uf-icon name="error" class="error" />
								}
								@if (!variation.disabled) {
									<button (click)="remove(i)" type="button" title="Delete" class="uf-action">
										<uf-icon name="delete" />
									</button>
								}
							</div>
							<div expanderBody class="uf-grid pad">
								@if (getVariationRolesDescriptionItem(variation)) {
									<uf-data-display-list [items]="getVariationRolesDescriptionItem(variation)" class="small-label col-12" />
								}
							</div>
						</uf-expander>
					}
				</uc-drag-list>
			}
			<div class="row">
				@if (!control.disabled) {
					<button (click)="add()" type="button" class="uf-button x-small right">Add Variation</button>
				}
			</div>
		</div>
	</uf-expander>
}
