import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, Output, inject } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { hasLengthAtLeast } from '@unifii/sdk';
import { Observable, lastValueFrom } from 'rxjs';

import { DragService } from './drag.service';

export interface DragListEvent<T> {
	item: T;
	target: DragListComponent;
	to: number;
	source?: DragListComponent;
	from?: number;
}

export enum DropPosition {
	Before = 'Before',
	After = 'After',
	Inside = 'Inside',
}

@Component({
	selector: 'uc-drag-list',
	templateUrl: './drag-list.html',
	styleUrls: ['drag-list.less'],
	standalone: false,
})
export class DragListComponent implements AfterViewInit, OnDestroy {

	@Input() parent: any;
	@Input() childrenProperty = 'children';

	// Config/Callback to decide if an item can de dropped (added, movedUp, movedDown, reordered)
	@Input() canDrop: undefined | null | boolean | string | ((item: any, parent: any, dropIndex?: number) => boolean | Promise<boolean> | Observable<boolean>);
	// Config/Callback to decide if an item can be reordered withing its original list (this override the canDrop for the reordering case)
	@Input() canReorder: undefined | null | boolean | string | ((item: any, parent: any, dropIndex?: number) => boolean | Promise<boolean> | Observable<boolean>);

	// Callback function can be a Promise
	@Input() convertAdded: undefined | null | ((...args: any) => unknown);
	@Input() drop: undefined | null | ((item: any, parent: any, position: DropPosition, index?: number) => void);

	@Output() insert = new EventEmitter<DragListEvent<any>>();
	@Output() moved = new EventEmitter<DragListEvent<any>>();

	protected hideIndicator = true;
	protected indicatorX = '0px';
	protected indicatorY = '0px';
	protected indicatorWidth = '100%';

	private service = inject(DragService);
	private zone = inject(NgZone);
	private nativeElement = inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;
	private cdr = inject(ChangeDetectorRef);
	private enterCount = 0;
	private dropIndex: number | null;
	private dropPosition = DropPosition.Inside;
	private _items: any[];
	private onDragEnterReference: (event: DragEvent) => void;
	private onDragOverReference: (event: DragEvent) => void;
	private onDragLeaveReference: (event: DragEvent) => void;

	@Input({ required: true }) set items(v: any[]) {
		this._items = v;
	}

	get items(): any[] {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return this._items;
	}

	ngAfterViewInit() {
		this.onDragEnterReference = this.onDragEnter.bind(this);
		this.onDragOverReference = this.onDragOver.bind(this);
		this.onDragLeaveReference = this.onDragLeave.bind(this);

		// TODO Add options.runOutsideAngular to DOMEventHandler.options
		this.zone.runOutsideAngular(() => {
			this.nativeElement.addEventListener('dragenter', this.onDragEnterReference);
			this.nativeElement.addEventListener('dragover', this.onDragOverReference);
			this.nativeElement.addEventListener('dragleave', this.onDragLeaveReference);
		});
	}

	ngOnDestroy() {
		this.nativeElement.removeEventListener('dragenter', this.onDragEnterReference);
		this.nativeElement.removeEventListener('dragover', this.onDragOverReference);
		this.nativeElement.removeEventListener('dragleave', this.onDragLeaveReference);
	}

	onDragEnter(event: DragEvent) {
		event.stopPropagation();
		this.enterCount++;
	}

	onDragOver(event: DragEvent) {
		event.stopPropagation();
		// TODO don't show indicators when drop is impossible
		// this.hideIndicator = !this.isDropLegal(this.getDropIndex());
		event.preventDefault();
	}

	onDragLeave(event: DragEvent) {
		event.stopPropagation();
		this.enterCount--;
		if (this.enterCount === 0) {
			this.hideIndicator = true;
		}
		this.cdr.detectChanges();
	}

	@HostListener('drop', ['$event'])
	// eslint-disable-next-line complexity
	async onDrop(event: DragEvent) {
		event.stopPropagation();

		const dropIndex = this.getDropIndex();
		const parent = this.dropPosition === DropPosition.Inside && dropIndex != null ? this.items[dropIndex] : this.parent;
		let item = this.getItemToAdd();

		// console.log('same list', this.service.sourceList === this);
		// console.log(`dropInternal dropPosition ${this.dropPosition} dropIndex ${dropIndex ?? 0} item`, item, 'parent', parent);

		const can = await this.canDropInternal(dropIndex ?? 0, item, parent);

		if (!can) {
			this.endDrag();

			// console.warn('Cannot drop, aborting!');
			return;
		}

		// run conversion callback on new item
		if (!this.service.sourceList && this.convertAdded) {
			const result = this.convertAdded(item, parent);

			if (result instanceof Promise) {
				item = await result;
			} else {
				item = result;
			}
		}

		// Detect if drag-list is working with Control - Review: should it support a mix scenario?
		const useControls = item instanceof AbstractControl;

		// removed source item if it comes from sourceList
		if (this.service.sourceList && this.service.dragIndex != null) {

			if (useControls) {
				const parentControl = (this.service.sourceList.items[this.service.dragIndex] as AbstractControl | null)?.parent;

				if (parentControl instanceof UntypedFormArray) {
					parentControl.removeAt(this.service.dragIndex);
				} else if (parentControl instanceof UntypedFormGroup) {
					const name = Object.keys(parentControl.controls).find((c) => parentControl.controls[c] === item);

					if (name) {
						parentControl.removeControl(name);
					}
					console.warn('DragListComponent.drop - Remove dragged control from sourceList failed');
				}
			} else {
				this.service.sourceList.items.splice(this.service.dragIndex, 1);
			}
		}

		if (typeof this.drop === 'function') {
			this.drop(item, parent, this.dropPosition, dropIndex ?? undefined);
		} else {
			// insert item into the new position
			if (this.dropPosition === DropPosition.Inside && parent) {

				if (useControls) {
					if (parent instanceof UntypedFormArray) {
						(parent as UntypedFormArray).push(item);
					} else if (parent instanceof UntypedFormGroup && this.childrenProperty) {
						const childrenControl = parent.get(this.childrenProperty);

						if (childrenControl instanceof UntypedFormArray) {
							childrenControl.insert(0, item);
						}
					}

				} else {
					if (parent[this.childrenProperty] == null) {
						parent[this.childrenProperty] = [];
					}
					parent[this.childrenProperty].push(item);
				}
			} else {
				// Drop at the bottom if no dropIndex specified, at the index otherwise
				if (useControls) {
					const parentControl = hasLengthAtLeast(this.items, 1) ? this.items[0].parent as UntypedFormArray : this.parent;

					parentControl.insert(dropIndex ?? this.items.length, item);
				} else {
					this.items.splice(dropIndex ?? this.items.length, 0, item);
				}
			}
		}

		const dragEvent: DragListEvent<any> = {
			item,
			source: this.service.sourceList ?? undefined,
			from: this.service.sourceList ? this.service.dragIndex ?? undefined : undefined,
			target: this,
			to: dropIndex ?? this.items.length,
		};

		if (!this.service.sourceList) {
			// Fire insert for new item
			this.insert.emit(dragEvent);
		} else {
			// Fire drop for moved existing item
			this.moved.emit(dragEvent);
		}

		this.endDrag();
	}

	findIndex(item: any): number {
		return this.items.findIndex((i) => i === item);
	}

	// setPosition sets the indicator position and determines if it should be shown.
	setPosition(index: number, where: DropPosition, x: number, y: number, width: number): boolean {

		this.dropPosition = where;
		this.dropIndex = index;
		this.indicatorX = x + 'px';
		this.indicatorY = (y - 1) + 'px';
		this.indicatorWidth = width + 'px';

		const realDropIndex = this.getDropIndex();
		const canDrop = this.isDropLegal(realDropIndex ?? 0);

		// when inside we show outline
		this.hideIndicator = !canDrop || where === DropPosition.Inside;

		return canDrop;
	}

	private endDrag() {
		this.enterCount = 0;
		this.dropIndex = null;
		this.dropPosition = DropPosition.Inside;
		this.service.sourceList = null;
		this.service.dragIndex = null;
		this.hideIndicator = true;
	}

	private isChildOf(item: any) {

		if (item == null) {
			return false;
		}

		let children;

		if (item instanceof AbstractControl) {
			const childrenControl = (item as UntypedFormGroup).get(this.childrenProperty);

			if (childrenControl instanceof UntypedFormArray) {
				children = childrenControl.controls;
			}
		} else {
			children = item[this.childrenProperty];
		}

		if (!children) {
			return false;
		}

		if (children === this.items) {
			return true;
		}

		for (const child of children) {
			if (this.isChildOf(child)) {
				return true;
			}
		}

		return false;
	}

	private getDropIndex() {
		if (this.dropIndex == null) {
			return this.dropIndex;
		}

		let dropIndex = this.dropIndex;

		// placing after
		if (this.dropPosition === DropPosition.After) {
			dropIndex++;
		}

		// dragging down within same list
		if (this.service.sourceList === this && dropIndex > (this.service.dragIndex ?? 0) && this.dropPosition !== DropPosition.Inside) {
			dropIndex--;
		}

		return dropIndex;
	}

	private isDropLegal(dropIndex: number) {
		if (this.service.sourceList == null) {
			return this.service.data != null;
		}

		if (this.service.sourceList === this && dropIndex === this.service.dragIndex) {
			return false;
		}

		const item = this.service.dragIndex != null ? this.service.sourceList.items[this.service.dragIndex] : null;

		return !this.isChildOf(item);
	}

	private canDropInternal(dropIndex: number, item: any, parent: any): Promise<boolean> {

		// Can't drop
		if (!this.isDropLegal(dropIndex)) {
			return Promise.resolve(false);
		}

		const isReorderCase = this.service.sourceList === this;

		if (this.canReorder != null && isReorderCase) {
			return this.canReorderInternal(dropIndex, item, parent);
		}

		// canDrop not provided
		if (this.canDrop == null) {
			return Promise.resolve(true);
		}

		// canDrop as boolean
		if (typeof this.canDrop === 'boolean') {
			return Promise.resolve(this.canDrop);
		}

		// canDrop as string
		if (typeof this.canDrop === 'string') {
			return Promise.resolve(this.canDrop === 'true');
		}

		// CanDrop is a function
		if (typeof this.canDrop === 'function') {
			const result = this.canDrop(item, parent, dropIndex);

			if (typeof result === 'boolean' || result instanceof Promise) {
				return Promise.resolve(result);
			}

			return lastValueFrom(result);
		}

		return Promise.resolve(true);
	}

	private canReorderInternal(dropIndex: number, item: any, parent: any): Promise<boolean> {
		if (this.canReorder == null) {
			return Promise.resolve(true);
		}

		if (typeof this.canReorder === 'boolean') {
			return Promise.resolve(this.canReorder);
		}

		if (typeof this.canReorder === 'string') {
			return Promise.resolve(this.canReorder === 'true');
		}

		if (typeof this.canReorder === 'function') {
			const result = this.canReorder(item, parent, dropIndex);

			if (typeof result === 'boolean' || result instanceof Promise) {
				return Promise.resolve(result);
			}

			return lastValueFrom(result);
		}

		return Promise.resolve(true);
	}

	private getItemToAdd(): any {
		if (this.service.sourceList) {
			return this.service.dragIndex != null ? this.service.sourceList.items[this.service.dragIndex] : null;
		} else {
			return this.service.data;
		}
	}

}
