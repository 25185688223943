import { Injectable, inject } from '@angular/core';
import { DataDescriptorBucketType, DataLoaderFactory, FilterEntry, FilterEntryDataDescriptorAdapter, FormDefinitionMetadataIdentifiers } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { FormBucketService } from './bucket-service';

@Injectable()
export class BucketDataFilterFactory {

	private readonly filterIdentifiers = [
		FormDefinitionMetadataIdentifiers.State,
		FormDefinitionMetadataIdentifiers.Result,
		FormDefinitionMetadataIdentifiers.DefinitionIdentifier,
		FormDefinitionMetadataIdentifiers.CreatedBy,
		FormDefinitionMetadataIdentifiers.CreatedAt,
		FormDefinitionMetadataIdentifiers.LastModifiedBy,
		FormDefinitionMetadataIdentifiers.LastModifiedAt,
	];
	private dataLoaderFactory = inject(DataLoaderFactory);
	private bucketService = inject(FormBucketService);
	private filterEntryAdapter = inject<FilterEntryDataDescriptorAdapter>(FilterEntryDataDescriptorAdapter);

	create(): FilterEntry[] {

		const entries: FilterEntry[] = [];

		if (!this.bucketService.dataDescriptor) {
			return [];
		}

		for (const identifier of this.filterIdentifiers) {
			const descriptorProperty = this.bucketService.dataDescriptor.propertyDescriptors.find((p) => p.identifier === identifier);

			if (!descriptorProperty) {
				continue;
			}

			const filter = this.filterEntryAdapter.transform({
				descriptorType: DataDescriptorBucketType,
				descriptorProperty,
				dataLoaderFactory: this.dataLoaderFactory,
				requestAnalytics: { origin: RequestAnalyticsOrigin.Table, id: 'buckets-table' },
			});

			if (filter) {
				entries.push(filter);
			}
		}

		return entries;
	}

}
