import { UcAssetComponent } from './uc-asset.component';
import { UcColorComponent } from './uc-color.component';
import { UcMarkdownTokenizerComponent } from './uc-markdown-tokenizer.component';
import { UcMarkdownWrapperComponent } from './uc-markdown-wrapper.component';
import { UcMarkdownComponent } from './uc-markdown.component';

const FORM = [UcMarkdownComponent, UcMarkdownWrapperComponent, UcColorComponent, UcAssetComponent, UcMarkdownTokenizerComponent];

export {
	FORM, UcMarkdownComponent, UcMarkdownWrapperComponent, UcColorComponent, UcAssetComponent, UcMarkdownTokenizerComponent, 
};

