import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, ResolveFn } from '@angular/router';
import { ClipboardService, DataDescriptor, FormDefinitionMetadataIdentifiers, ModalService, UserInfoIdentifiers } from '@unifii/library/common';
import { FieldType, TableDetail, TableSourceType, UfError } from '@unifii/sdk';
import { Observable } from 'rxjs';

import { SchemaInfo, UcDataSources, UcFormBucketClient, UcProject, UcTable } from 'client';
import { EditMode, TypeSelectComponent, getEditMode, useDefaultErrorMessage } from 'components';
import { ContentSelectComponent, ContentSelectType, getContentSelectConfig } from 'components/content/modals/content-select.component';
import { appendSuffixCopy, cleanDefinitionToBeDuplicated } from 'pages/utils';
import { TableSourceTypeLabelPipe } from 'pipes/table-source-type-label.pipe';

import { TableEditorService } from './table-editor.service';

export interface TableInfo {
	table: UcTable;
	dataDescriptor: DataDescriptor;
	duplicateTableDetails?: TableDetail;
}

export const tableResolver: ResolveFn<TableInfo | UfError> = (route: ActivatedRouteSnapshot) => new TableResolver(
	inject(ModalService),
	inject(TableSourceTypeLabelPipe),
	inject(ClipboardService),
	inject(TableEditorService),
	inject(UcProject),
	inject(UcFormBucketClient),
).resolve(route);

class TableResolver {

	ucDataSources: UcDataSources;

	constructor(
		private modalService: ModalService,
		private tableSourceTypeLabelPipe: TableSourceTypeLabelPipe,
		private clipboard: ClipboardService,
		private tableEditorService: TableEditorService,
		private ucProject: UcProject,
		private ucFormBucketClient: UcFormBucketClient,
	) { }

	resolve(route: ActivatedRouteSnapshot): Observable<TableInfo | UfError > {

		return this.resolvePromise(this.getTableInfo(route.params));
	}

	private async getTableInfo(params: Params): Promise<TableInfo | null> {

		const editMode = getEditMode(params);
		let table: UcTable | undefined;
		let tableDetail: TableDetail | undefined;

		switch (editMode) {
			case EditMode.New:
				table = await this.getNewTable();
				break;
			case EditMode.Existing:
				table = await this.ucProject.getTable(params.id);
				break;
			case EditMode.Duplicate:
				table = await this.ucProject.getTable(params.id);
				try {
					tableDetail = await this.ucProject.getTableDetail(params.id);
				} catch (e) { /* empty */ }

				table = cleanDefinitionToBeDuplicated(table) as UcTable;

				table.title = appendSuffixCopy({ label: table.title });
				table.consoleName = appendSuffixCopy({ label: table.consoleName });
				table.identifier = appendSuffixCopy({ label: table.identifier, noWhiteSpace: true });

				break;
		}

		if (!table) {
			return null;
		}

		let tableInfo: TableInfo | null = null;

		switch (table.sourceType) {
			case TableSourceType.Bucket:
				tableInfo = await this.loadBucketInfo(table);
				break;
			case TableSourceType.Users:
				tableInfo = await this.loadUsersInfo(table);
				break;
			case TableSourceType.Company:
				tableInfo = await this.loadCompanyInfo(table);
				break;
			default:
				return null;

		}

		if (tableInfo != null) {
			tableInfo.duplicateTableDetails = tableDetail;
		}

		return tableInfo;
	}

	private async getNewTable(): Promise<UcTable | undefined> {

		const copiedTable = await this.getCopiedTable();

		if (copiedTable) {
			const pasteChoice = await this.askPaste();

			if (pasteChoice == null) {
				// Execution stopped
				return;
			}

			if (pasteChoice) {
				return copiedTable;
			}
		}

		const sourceType = await this.askSourceType();

		if (sourceType == null) {
			// Execution stopped
			return;
		}

		const table: UcTable = { identifier: null as any, sourceType, title: null as any, hideExport: true };

		switch (sourceType) {

			case TableSourceType.Users:
				table.columns = [
					{ identifier: UserInfoIdentifiers.Username },
					{ identifier: UserInfoIdentifiers.FirstName },
					{ identifier: UserInfoIdentifiers.LastName },
					{ identifier: UserInfoIdentifiers.Email },
					{ identifier: UserInfoIdentifiers.Phone },
					{ identifier: UserInfoIdentifiers.Roles },
				];
				table.defaultSort = UserInfoIdentifiers.Username;

				return table;

			case TableSourceType.Company:
				return;

			case TableSourceType.Bucket: {
				const bucket = await this.modalService.openMedium(
					ContentSelectComponent, getContentSelectConfig(ContentSelectType.Schema, this.ucProject, this.ucFormBucketClient)) as SchemaInfo | undefined;

				if (!bucket) {
					// Execution stopped
					return;
				}
				table.source = bucket.id;
				table.columns = [
					{ identifier: FormDefinitionMetadataIdentifiers.State },
				];

				return table;
			}
		}
	}

	private async getCopiedTable(): Promise<UcTable | undefined> {
		try {
			const text = await this.clipboard.getText();
			const copiedTable = text ? JSON.parse(text) as UcTable : undefined;

			if (!copiedTable?.sourceType) {
				// Wrong copied text/format
				throw new Error('Pasted Table.sourceType undefined');
			}

			copiedTable.identifier = undefined as any;
			copiedTable.title = undefined as any;
			delete copiedTable.id;
			delete copiedTable.lastModifiedAt;
			delete copiedTable.lastModifiedBy;
			delete copiedTable.lastPublishedAt;
			delete copiedTable.lastPublishedBy;
			delete copiedTable.publishState;

			return copiedTable;

		} catch (e) {
			return;
		}
	}

	private async askPaste(): Promise<boolean | undefined> {
		const choice = await this.modalService.openMedium(
			TypeSelectComponent, {
				title: 'Create table',
				types: [{
					type: 'paste',
					label: 'Paste copied table',
					icon: 'paste',
				}, {
					type: 'new',
					label: 'Define new table',
					icon: 'add',
				}],
			});

		if (!choice) {
			return;
		}

		return choice.type === 'paste';
	}

	private async askSourceType(): Promise<TableSourceType | undefined> {
		// Table sourceType
		const options = [
			{
				type: TableSourceType.Bucket,
				label: this.tableSourceTypeLabelPipe.transform(TableSourceType.Bucket),
			}, {
				type: TableSourceType.Users,
				label: this.tableSourceTypeLabelPipe.transform(TableSourceType.Users),
			},
		];

		const sourceTypeChoice = await this.modalService.openMedium(
			TypeSelectComponent, {
				title: 'Select a source type',
				types: options,
			});

		if (sourceTypeChoice == null) {
			return;
		}

		return sourceTypeChoice.type as TableSourceType;
	}

	private async loadBucketInfo(table: UcTable): Promise<TableInfo | null> {

		if (!table.source) {
			return null;
		}

		const dataDescriptor = await this.tableEditorService.getBucketDataDescriptor(table.source);

		if (!dataDescriptor) {
			return null;
		}

		return { table, dataDescriptor };
	}

	private async loadCompanyInfo(table: UcTable): Promise<TableInfo> {
		const dataDescriptor = await this.tableEditorService.getCompanyDataDescriptor();

		if (!dataDescriptor) {
			throw new Error('Company data descriptor undefined');
		}

		return { table, dataDescriptor };
	}

	private async loadUsersInfo(table: UcTable): Promise<TableInfo> {
		const dataDescriptor = await this.tableEditorService.getUserDataDescriptor();

		if (!dataDescriptor) {
			throw new Error('User data descriptor undefined');
		}

		// TODO - Should this be done in DDE ?
		const units = dataDescriptor.propertyDescriptors.find((dpd) => dpd.identifier === UserInfoIdentifiers.Units as string);

		if (units) {
			units.type = FieldType.Hierarchy;
			units.icon = 'hierarchy';
		}

		return { table, dataDescriptor };
	}

	private resolvePromise(promise: Promise<TableInfo | null>): Observable<TableInfo | UfError > {
		// Wraps promises with an observable that completes which will cancel navigation
		return new Observable((subscriber) => {
			promise.then((tableInfo) => {
				if (!tableInfo) {
					subscriber.complete();
				} else {
					subscriber.next(tableInfo);
				}
			}).catch((e) => {
				const error = useDefaultErrorMessage(e);

				subscriber.next(error);
			});
		});
	}

}
