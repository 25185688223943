import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager, TableInputs } from '@unifii/components';
import { ActionMultiplicity, ClipboardService, DataDisplayIconValue, DataDisplayService, FilterEntry, FilterValue, TableAction, TableConfig, TableConfigColumn, TableRowContext, ToastService } from '@unifii/library/common';
import { DataType } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { UcClaimConfig, UcUserClaims } from 'client';
import { DialogsService } from 'services/dialogs.service';

import { ClaimDataSource } from './claim-data-source';

@Injectable()
export class ClaimTableManager implements TableContainerManager<UcClaimConfig, FilterValue, FilterEntry> {

	tableConfig: TableConfig<UcClaimConfig>;
	addActionConfig = true;
	reload = new Subject<void>();
	update = new Subject<TableInputs<FilterValue>>();
	updateItem = new Subject<UcClaimConfig | { item: UcClaimConfig; trackBy: keyof UcClaimConfig }>();

	private route = inject(ActivatedRoute);
	private router = inject(Router);
	private dataDisplayService = inject(DataDisplayService);
	private toastService = inject(ToastService);
	private clipboard = inject(ClipboardService);
	private dialogs = inject(DialogsService);
	private userClaimsClient = inject(UcUserClaims);

	constructor() {

		this.tableConfig = {
			id: 'user-claims',
			columns: this.columns,
			actions: this.actions,
			pageSize: 50,
			selectable: true,
			columnToggles: true,
			rowLink: (element) => element.id,
		};
	}

	createDataSource() {
		return new ClaimDataSource(this.userClaimsClient);
	}

	private get columns(): TableConfigColumn<UcClaimConfig>[] {
		return [{
			name: 'label',
			label: 'Title',
		}, {
			name: 'type',
			label: 'Identifier',
		}, {
			name: 'valueType',
			label: 'Value Type',
		}, {
			name: 'isRequired',
			label: 'Required',
			value: (item) => item.isRequired ? {
				icon: 'radioTick',
				colour: 'success',
			} satisfies DataDisplayIconValue : null,
		}, {
			name: 'lastModifiedBy.username',
			label: 'Modified By',
		}, {
			name: 'lastModifiedAt',
			label: 'Modified At',
			value: (item) => this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime }),
		}];
	}

	private get actions(): TableAction<UcClaimConfig>[] {
		return [{
			label: 'Delete',
			action: (rows: TableRowContext<UcClaimConfig>[]) => this.delete(rows.map((row) => row.$implicit)),
		}, {
			label: 'Duplicate',
			multiplicity: ActionMultiplicity.Single,
			action: (row: TableRowContext<UcClaimConfig>) => this.duplicate(row.$implicit.id),
		}, {
			label: 'Copy',
			multiplicity: ActionMultiplicity.Single,
			action: (row: TableRowContext<UcClaimConfig>) => this.clipboard.setText(JSON.stringify(row.$implicit)),
		}];
	}

	private async delete(claimConfig: UcClaimConfig[]) {
		if (!(await this.dialogs.confirmDelete())) {
			return;
		}

		// Create new reference so array can be modified for printing a meaningful error message
		claimConfig = [...claimConfig];

		try {
			for (let i = (claimConfig.length - 1); i >= 0; i--) {
				const config = claimConfig[i];

				if (!config) {
					continue;
				}

				await this.userClaimsClient.delete(config.id);
				claimConfig.splice(i, 1);
			}
			this.toastService.success(`Claim(s) deleted`);
		} catch (e) {
			this.toastService.error(`Failed to delete claim: ${claimConfig.map((c) => c.label).join(', ')}`);
		} finally {
			this.reload.next();
		}
	}

	private duplicate(id: string) {
		void this.router.navigate([id, { duplicate: 'true' }], { relativeTo: this.route });
	}

}
