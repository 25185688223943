@if (option && control) {
	<uf-expander [isExpanded]="isExpanded" class="uf-box flat draggable">
		<div expanderHeader dragHandle class="uf-app-bar flat">
			<div class="title primary">{{ labelPrefix }}{{ option.name }}</div>
			@if (control.invalid) {
				<uf-icon name="warning" class="icon-warning" />
			}
			@if (field.type !== fieldType.Bool) {
				<button (click)="removeOption($event)" type="button" title="Delete" class="uf-action tertiary">
					<uf-icon name="delete" />
				</button>
			}
		</div>
		<div expanderBody class="uf-grid pad">
			@if (control.controls.name) {
				<uf-text [control]="control.controls.name | asUfControl" [(value)]="option.name" (valueChange)="nameChange($event)" label="Name" placeholder="Option name" class="col-12" />
			}
			@if (field.type !== fieldType.Bool && control.controls.identifier) {
				<uf-text [control]="control.controls.identifier | asUfControl" [maxLength]="identifierMaxLength" [(value)]="option.identifier" label="Identifier" placeholder="Option identifier" class="col-12" />
			}
			@if (showContentField && control.controls.content) {
				<uc-markdown [control]="control.controls.content | asUfControl" [showAlignmentOptions]="showAlignmentOptions" [(value)]="option.content" label="Content" class="col-12" />
			}
		</div>
	</uf-expander>
}
