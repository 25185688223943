import { Component, OnInit, inject } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';

import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';

@Component({
	selector: 'uc-workflow',
	templateUrl: 'workflow.html',
	standalone: false,
})
export class WorkflowComponent extends TabsPage implements OnInit {
    
	override edited: boolean;

	protected breadcrumbs: Breadcrumb[];

	private breadcrumbService = inject(BreadcrumbService);

	override ngOnInit() {
		this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);
		super.ngOnInit();
	}

}
