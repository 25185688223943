import { Component, HostBinding, inject } from '@angular/core';
import { ClipboardService, Modal, ModalData, ModalRuntime, ToastService } from '@unifii/library/common';
import { FormData } from '@unifii/sdk';

@Component({
	selector: 'uc-form-data-display-modal',
	templateUrl: './form-data-display-modal.html',
	standalone: false,
})
export class FormDataDisplayModalComponent implements Modal<FormData, void> {

	@HostBinding('class.uc-form-card') formCardClass = true;

	runtime = inject<ModalRuntime<FormData, void>>(ModalRuntime);

	protected formData = JSON.stringify(inject<FormData>(ModalData), null, 4);

	private clipboard = inject(ClipboardService);
	private toastService = inject(ToastService);

	close() {
		this.runtime.close();
	}

	protected copy() {
		void this.clipboard.setText(this.formData);
		this.toastService.success('Data copied');
	}

}
