"use strict";

exports.isExists = isExists; /**
                             * @name isExists
                             * @category Common Helpers
                             * @summary Is the given date exists?
                             *
                             * @description
                             * Checks if the given arguments convert to an existing date.
                             *
                             * @param year - The year of the date to check
                             * @param month - The month of the date to check
                             * @param day - The day of the date to check
                             *
                             * @returns `true` if the date exists
                             *
                             * @example
                             * // For the valid date:
                             * const result = isExists(2018, 0, 31)
                             * //=> true
                             *
                             * @example
                             * // For the invalid date:
                             * const result = isExists(2018, 1, 31)
                             * //=> false
                             */
function isExists(year, month, day) {
  const date = new Date(year, month, day);
  return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
}