<div class="header">
	<button (click)="close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>Field Order</h3>
</div>

<uf-panel class="container">
	<uc-drag-list [items]="items" canDrop="false" canReorder="true" class="col-1of1">
		<ul class="uc-list">
			@for (item of items; track item) {
				<li class="uc-list-item--draggable--small row center-all" dragItem>
					<div class="list-content">
						<div class="grow">{{ item.name }}</div>
					</div>
				</li>
			}
		</ul>
	</uc-drag-list>
</uf-panel>

<div class="row space-children pad">
	<button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
	<button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>
