import { TableDetailComponent } from './table-detail.component';
import { TableModuleTableComponent } from './table-module-table.component';

export * from './table-detail.component';
export * from './table-module-table.component';

export const TABLE_DETAIL = [
	TableDetailComponent,
	TableModuleTableComponent,
];
