@if (rolesControl.enabled) {
	<div [formGroup]="form" [ngClass]="cssClasses" class="uf-box">
		<div class="uf-app-bar accent">
			<div class="title">{{ name }} Roles</div>
		</div>
		<div class="pad">
			<uf-role-input [formControlName]="controlKeys.Roles" [lockedRoles]="lockedConfig?.roles ?? []" />
		</div>
	</div>
}
