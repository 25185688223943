<div class="header">
	<button (click)="runtime.close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>Field Info</h3>
</div>
<uf-panel>
	<div class="uf-grid pad gaps">
		<uc-form-summary-field-info [items]="options" title="Options" class="col-12" />
		<uc-form-summary-field-info [items]="validators" title="Validators" class="col-12" />
		<uc-form-summary-field-info [items]="variations" title="Variations" class="col-12" />
		<uf-expander class="uf-box flat col-12">
			<div expanderHeader class="uf-app-bar accent flat">
				<div class="title primary">Help</div>
			</div>
			<div #itemsContainer expanderBody class="pad-sm">
				@if (help) {
					<div [markdownRenderer]="help"></div>
				}
				@if (!help) {
					<span>No data to display</span>
				}
			</div>
		</uf-expander>
	</div>
</uf-panel>
