<div class="header">
	<button (click)="close()" title="Close" type="button" class="uf-action tertiary">
		<uf-icon name="close" />
	</button>
	<h3>Clone</h3>
</div>

@if (form) {
	<uf-panel class="container">
		<div [formGroup]="form" class="uf-grid pad-sides">
			<div class="col-12">
				<h4>Target</h4>
			</div>
			<uf-select [formControlName]="typeControlKey" [options]="types" (valueChange)="changeType(); reset()" placeholder="Select a type" nameProperty="name" class="col-6" />
			@if (typeControl.value) {
				<uf-autocomplete [formControlName]="targetsControlKey" [options]="targets" (valueChange)="reset()" (searchChange)="filterTarget($event)" placeholder="Select" nameProperty="name" class="col-6" />
			}
			<div class="col-12">
				<h4>Permissions</h4>
				<ul class="uf-list uf-box flat">
					@for (item of items; track item) {
						<li class="pad-sm">
							<div class="row center-all">
								@if (item.cloned) {
									<uf-icon name="successSolid" class="success" />
								}
								@if (item.error) {
									<uf-icon name="errorSolid" class="error" />
								}
								@if (!item.error && !item.cloned) {
									<uf-icon name="" />
								}
								<p class="small pad-sm-left">{{ item.pathLabel }}</p>
							</div>
							@if (item.error) {
								<span class="error-text">
									{{ item.error.message }}
								</span>
							}
						</li>
					}
				</ul>
			</div>
		</div>
	</uf-panel>
}

<div class="row space-children pad">
	<button [disabled]="busy" (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
	@if (!cloned) {
		<button [disabled]="busy" (click)="clone()" type="button" class="uf-button primary">Clone</button>
	}
	@if (cloned) {
		<button [disabled]="busy" (click)="close()" type="button" class="uf-button primary">Close</button>
	}
</div>
