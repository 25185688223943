import { Component, Input, OnInit, inject } from '@angular/core';
import { UfControl, UfControlGroup } from '@unifii/library/common';
import { LockedConfig, UserKeys } from '@unifii/user-provisioning';

import { SystemRole } from 'client';
import { ContextService } from 'services/context.service';

import { IsSuperUserControl } from '../user-utils';

@Component({
	selector: 'uc-user-system-roles',
	templateUrl: 'user-system-roles.html',
	standalone: false,
})

export class UserSystemRolesComponent implements OnInit {

	@Input({ required: true }) form: UfControlGroup;
	@Input({ required: true }) lockedConfig: LockedConfig | undefined;
	@Input() cssClasses: string | string[] | undefined | null;

	protected systemRoleOptions: string[] = [];
	protected superUserControl?: UfControl;
	protected systemRolesControl?: UfControl;

	private readonly isSuperUser = inject(ContextService).checkRoles(SystemRole.SuperUser);

	ngOnInit() {
		this.superUserControl = this.form.get(IsSuperUserControl) as UfControl | undefined;
		this.systemRolesControl = this.form.get(UserKeys.SystemRoles) as UfControl | undefined;
		this.systemRoleOptions = Object.keys(SystemRole).filter((key) =>
			key !== SystemRole.SuperUser && !this.lockedConfig?.systemRoles?.includes(key),
		);
	}

	protected toggleSuperUser(updateSuperUserTo: boolean) {
		if (updateSuperUserTo) {
			this.systemRolesControl?.setValue([...this.systemRoleOptions]);
			this.systemRolesControl?.disable();
		} else if (this.isSuperUser) {
			this.systemRolesControl?.enable();
		}
	}

}
