"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DataType = void 0;
var DataType;
(function (DataType) {
  // PRIMITIVES
  // eslint-disable-next-line id-denylist
  DataType["Number"] = "Number";
  // eslint-disable-next-line id-denylist
  DataType["Boolean"] = "Boolean";
  // eslint-disable-next-line id-denylist
  DataType["String"] = "String";
  DataType["Phone"] = "Phone";
  DataType["Email"] = "Email";
  DataType["Website"] = "Website";
  DataType["Date"] = "Date";
  DataType["Time"] = "Time";
  DataType["DateTime"] = "DateTime";
  DataType["OffsetDateTime"] = "OffsetDateTime";
  DataType["Signature"] = "Signature";
  // OBJECTS
  DataType["Choice"] = "Choice";
  DataType["ZonedDateTime"] = "ZonedDateTime";
  DataType["Cost"] = "Cost";
  DataType["GeoLocation"] = "GeoLocation";
  DataType["Address"] = "Address";
  DataType["Link"] = "Link";
  DataType["HierarchyStep"] = "HierarchyStep";
  DataType["HierarchyUnit"] = "HierarchyUnit";
  DataType["DataSeed"] = "DataSeed";
  DataType["Claim"] = "Claim";
  DataType["ClaimsRecord"] = "ClaimsRecord";
  DataType["CompanyInfo"] = "CompanyInfo";
  DataType["Manager"] = "Manager";
  // ARRAYS
  DataType["StringArray"] = "StringArray";
  DataType["MultiChoice"] = "MultiChoice";
  DataType["Repeat"] = "Repeat";
  DataType["FileList"] = "FileList";
  DataType["ImageList"] = "ImageList";
  DataType["SoundList"] = "SoundList";
  DataType["VideoList"] = "VideoList";
  DataType["HierarchyPath"] = "HierarchyPath";
  DataType["HierarchyPaths"] = "HierarchyPaths";
})(DataType || (exports.DataType = DataType = {}));
