import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'bypassSanitizeUrl',
	standalone: false,
})
export class BypassSanitizeUrlPipe implements PipeTransform {

	private sanitizer = inject(DomSanitizer);

	transform(value: string): any {
		if (value) {
			return this.sanitizer.bypassSecurityTrustResourceUrl(value);
		}

		return '';
	}

}
