import { Injectable, OnDestroy, inject } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { DataDisplayService, FilterEntry, FilterValue, TableConfig } from '@unifii/library/common';
import { DataType } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';

import { DataSourceInfo, UcDataSources } from 'client';
import { ConsoleNameLabel, ConsoleNameProperty, DefaultPaginationParams } from 'constant';

import { DataSourcesTableDataSource } from './data-sources-table-data-source';

@Injectable()
export class DataSourcesTableManager implements TableContainerManager<DataSourceInfo, FilterValue, FilterEntry>, OnDestroy {

	tableConfig: TableConfig<DataSourceInfo>;
	addActionConfig = true;
	reload = new Subject<void>();
	updateItem = new Subject<DataSourceInfo | { item: DataSourceInfo; trackBy: keyof DataSourceInfo }>();

	private ucDataSources = inject(UcDataSources);
	private dataDisplayService = inject(DataDisplayService);
	private connection: Subscription | undefined;
	private items: DataSourceInfo[] = [];

	constructor() {
		this.tableConfig = {
			id: 'data-sources',
			columnToggles: false,
			selectable: false,
			pageSize: DefaultPaginationParams.limit,
			columns: [{
				name: ConsoleNameProperty,
				label: ConsoleNameLabel,
			}, {
				name: 'integrationName',
				label: 'Integration',
			}, {
				name: 'integrationProviderName',
				label: 'Provider',
			}, {
				name: 'lastModifiedAt',
				label: 'Last Modified At',
				value: (item) => this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
			}],
			rowLink: (item) => '' + item.id,
		};
	}

	ngOnDestroy() {
		this.connection?.unsubscribe();
	}

	createDataSource() {
		const dataSource = new DataSourcesTableDataSource(this.ucDataSources);

		this.connection?.unsubscribe();

		this.items = [];
		this.connection = dataSource.connect().subscribe((items) => this.items.push(...(items.data ?? [])));

		return dataSource;
	}

	getNextItem(id?: string): DataSourceInfo | undefined {

		const itemIndex = this.items.findIndex((item) => item.id === id);

		if (itemIndex < 0) {
			return;
		}

		return this.items[itemIndex + 1];
	}

	getItem(id: string): DataSourceInfo | undefined {
		return this.items.find((item) => item.id === id);
	}

}
