import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin, UserInfo } from '@unifii/sdk';

import { UcUsers, UserQueryParams } from 'client';

export interface UserDataSourceQuery extends UserQueryParams {
	q?: string;
	sort?: string;
}

export class UserDataSource extends TableDataSource<UserInfo> {

	constructor(
		private ucUsers: UcUsers,
		private query?: UserDataSourceQuery,
	) {
		super();
	}

	get filtered(): boolean {
		return this.query?.q != null && this.query.q.trim().length > 0;
	}

	get sorted(): boolean {
		return this.query?.sort != null;
	}

	async load(options?: TableDataSourceQueryOptions) {
		const { q, sort, ...params } = this.query ?? {};

		if (!q && !sort) {
			this.stream.next({ data: [] });

			return;
		}

		try {
			const users = await this.ucUsers.get(q, sort, params, {
				params: { offset: options?.offset, limit: options?.limit },
				analytics: { origin: RequestAnalyticsOrigin.Table },
			});

			this.stream.next({ data: users });
		} catch (error) {
			this.stream.next({ error });
		}
	}

	override getDownloadUrl(options?: TableDataSourceQueryOptions): string | undefined {
		const { q, sort, ...params } = this.query ?? {};

		return this.ucUsers.getDownloadUrl(q, sort, params, undefined, options?.limit);
	}

}
