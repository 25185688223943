@for (group of groups; track group) {
	@if (group.title && group.items.length) {
		<uf-expander class="gap-bottom-sm" class="uf-box flat">
			<div expanderHeader class="uf-app-bar flat">
				<span class="title primary">{{ group.title }}</span>
			</div>
			<div expanderBody class="pad-none gap-none">
				@for (item of group.items; track item) {
					<div [dragItem]="item" class="uf-box draggable uf-app-bar flat gap-sm">
						<uf-icon [name]="item.icon" />
						<div class="title primary">{{ item.label }}</div>
						@if (item.deletable) {
							<a (click)="onDelete(item)" class="uf-action tertiary" title="Delete">
								<uf-icon name="delete" />
							</a>
						}
					</div>
				}
			</div>
		</uf-expander>
	}
	@if (group.title == null) {
		@for (item of group.items; track item) {
			<div [dragItem]="item" class="uf-box draggable uf-app-bar flat gap-sm">
				<uf-icon [name]="item.icon" />
				<div class="title primary">{{ item.label }}</div>
				@if (item.deletable) {
					<a (click)="onDelete(item)" class="uf-action tertiary" title="Delete">
						<uf-icon name="delete" />
					</a>
				}
			</div>
		}
	}
}
