<div class="grid">
	@if (control.controls.title; as titleControl) {
		<uf-text [control]="titleControl | asUfControl" [(value)]="builderService.compound._title" label="Title" class="col-1of1" />
	}

	@if (control.controls.consoleName; as consoleNameControl) {
		<uf-text [control]="consoleNameControl | asUfControl" [label]="consoleNameLabel" [(value)]="builderService.compound._consoleName" class="col-1of1" />
	}

	@if (control.controls.identifier; as identifierControl) {
		<uf-text [control]="identifierControl | asUfControl" [maxLength]="identifierMaxLength" [(value)]="builderService.definition.identifier" label="identifier" class="col-1of1" />
	}
</div>
