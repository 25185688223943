import { APP_BASE_HREF } from '@angular/common';
import { ErrorHandler, LOCALE_ID, NgModule, PLATFORM_ID, inject, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { BucketDataDescriptorAdapterLoader, CollectionDataDescriptorAdapterLoader, ContextProvider, DataDisplayTableComponentRegistry, FeatureFlagProvider, FileUploader, GoogleMaps, GoogleMapsApiKey, HierarchyUnitProvider, LocationProvider, StorageWrapper, TablePreferencesProvider, UfDataDisplayContentTableComponentRegistry, UserDataDescriptorAdapterLoader, WindowResizeEventHandler, WindowWrapper, getGMaps, getStorage, getWindow, googleLocationProviderFactory } from '@unifii/library/common';
import { Client, Interceptor, ProjectContentOptions, ProjectContentOptionsInterface, TokenStorage, TokenStorageInterface } from '@unifii/sdk';
import { CompanyFormProvider, UserFormProvider, UserProvisioningProvider } from '@unifii/user-provisioning';
import { firstValueFrom } from 'rxjs';

import { SdkInterceptor, TokenService, UcHierarchyUnitProvider } from 'client';
import { UcUserFormProvider, UcUserProvisioningProvider } from 'pages/users';
import { ReportingExceptionHandler } from 'reporting-exception-handler';
import { BrowserGuard } from 'services/browser-guard';
import { UcBucketDataDescriptorAdapterLoader } from 'services/console-bucket-data-descriptor-adapter-loader';
import { UcCollectionDataDescriptorAdapterLoader } from 'services/console-collection-data-descriptor-adapter-loader';
import { UcContextProvider } from 'services/console-context-provider';
import { UcFeatureFlagProvider } from 'services/console-feature-flag.provider';
import { UcCompanyFormProvider } from 'services/console-form-provider';
import { UcUserDataDescriptorAdapterLoader } from 'services/console-user-data-descriptor-adapter-loader';
import { ContextService } from 'services/context.service';
import { FileUploaderService } from 'services/file-uploader.service';
import { NavigationService } from 'services/navigation.service';
import { TitleService } from 'services/title.service';
import { ConsoleTranslateLoader } from 'services/translate-loader';
import { UserPreferencesService } from 'services/user-preferences.service';

import { Config } from './app-config';
import { AppComponent } from './app.component';
import { ConsoleModule } from './console.module';
import { ConsoleRouteReuseStrategy } from './route-reuse-strategy';
import { AppRoutes } from './routes';

const browserAllowed = (browserGuard: BrowserGuard) => () => browserGuard.allowed;

const initTranslations = (translate: TranslateService) => () => {
	translate.addLangs(['en']);

	return firstValueFrom(translate.use('en'));
};

const initTitleService = (title: TitleService) => () => title.init();

@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		RouterModule.forRoot(AppRoutes, { useHash: false }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (config: Config) => new ConsoleTranslateLoader(config.translationsUrl),
				deps: [Config],
			},
		}),
		ConsoleModule,
	],
	providers: [
		provideAppInitializer(() => {
			const initializerFn = (browserAllowed)(inject(BrowserGuard));

			return initializerFn();
		}),
		provideAppInitializer(() => {
			const initializerFn = (initTranslations)(inject(TranslateService));

			return initializerFn();
		}),
		provideAppInitializer(() => {
			const initializerFn = (initTitleService)(inject(TitleService));

			return initializerFn();
		}),
		provideAppInitializer(() => {
			const initializerFn = ((navigationService: NavigationService) => () => navigationService.init())(inject(NavigationService));

			return initializerFn();
		}),
		{ provide: LOCALE_ID, useValue: 'en-AU' },
		{ provide: APP_BASE_HREF, useValue: '/' },
		{ provide: WindowWrapper, useFactory: getWindow, deps: [PLATFORM_ID] },
		{ provide: StorageWrapper, useFactory: getStorage, deps: [PLATFORM_ID] },
		{ provide: GoogleMaps, useFactory: getGMaps, deps: [PLATFORM_ID] },
		{ provide: ProjectContentOptions, useValue: { projectId: null as any, id: '', preview: false } as ProjectContentOptionsInterface },
		{ provide: ContextProvider, useClass: UcContextProvider, deps: [ContextService] },
		{
			provide: GoogleMapsApiKey,
			useFactory: (contextService: ContextService) => contextService.tenantSettings?.googleMapsApiKey,
			deps: [ContextService],
		},
		{
			provide: LocationProvider,
			useFactory: googleLocationProviderFactory,
			deps: [WindowWrapper, TranslateService, ContextProvider, GoogleMapsApiKey],
		},
		{ provide: RouteReuseStrategy, useClass: ConsoleRouteReuseStrategy },
		{ provide: ErrorHandler, useClass: ReportingExceptionHandler },
		{ provide: Interceptor, useClass: SdkInterceptor },
		{ provide: TokenStorage, useClass: TokenService },
		{
			provide: Client,
			useFactory: (config: Config, tokenStorage: TokenStorageInterface, interceptor: Interceptor): Client =>
				new Client(config, tokenStorage, interceptor),
			deps: [Config, TokenService, Interceptor],
		},
		{ provide: FeatureFlagProvider, useClass: UcFeatureFlagProvider },
		{ provide: FileUploader, useClass: FileUploaderService },
		{ provide: TablePreferencesProvider, useClass: UserPreferencesService },
		{ provide: HierarchyUnitProvider, useClass: UcHierarchyUnitProvider },
		WindowResizeEventHandler,
		{ provide: BucketDataDescriptorAdapterLoader, useClass: UcBucketDataDescriptorAdapterLoader },
		{ provide: UserDataDescriptorAdapterLoader, useClass: UcUserDataDescriptorAdapterLoader },
		{ provide: CollectionDataDescriptorAdapterLoader, useClass: UcCollectionDataDescriptorAdapterLoader },
		BrowserGuard, // TODO export from library
		{ provide: UserProvisioningProvider, useClass: UcUserProvisioningProvider },
		{ provide: UserFormProvider, useClass: UcUserFormProvider },
		{ provide: CompanyFormProvider, useClass: UcCompanyFormProvider },
		// Data Display
		{ provide: DataDisplayTableComponentRegistry, useClass: UfDataDisplayContentTableComponentRegistry },
	],
})
export class AppModule { }
