@if (!error && definition && entries.length) {
	<div class="col grow">
		<div class="uf-app-bar header-pane transparent pad-sides">
			<uc-builder-header />
		</div>
		<div class="content-pane">
			<div class="uf-container pad-top">
				<div class="uf-box row-gap-lg">
					<div class="uf-app-bar flat accent">
						<div class="title">Details</div>
					</div>
					<div class="pad">
						<uf-data-display-list>
							<dt>Form</dt>
							<dd>{{ definition.label }}</dd>
							<dt>Identifier</dt>
							<dd>{{ definition.identifier }}</dd>
							<dt>Form Data Repository</dt>
							<dd>{{ definition.bucket }}</dd>
							<dt>Form Number Format</dt>
							<dd>{{ definition.sequenceNumberFormat }}</dd>
						</uf-data-display-list>
					</div>
				</div>
			</div>
		</div>
		<uf-panel class="grow gap-sides scroll-all">
			@if (entries) {
				<table class="small uf-table outline clickable">
					<thead>
						<tr>
							<th colspan="15" class="table-header">
								<div class="uf-app-bar flat accent">
									<div class="title">Definition</div>
								</div>
							</th>
						</tr>
						<tr>
							<th>Label</th>
							<th>Short Label</th>
							<th>Identifier</th>
							<th>Required</th>
							<th>ReadOnly</th>
							<th>Autofill</th>
							<th>Bind To</th>
							<th>Show If</th>
							<th>Template</th>
							<th>Field Width</th>
							<th>Visible To</th>
							<th>Variations</th>
							<th>Validators</th>
							<th>Help</th>
							<th>Tags</th>
						</tr>
					</thead>
					<tbody [root]="entries" ufDirectoryRoot childrenProperty="children">
						@for (entry of entries | flatten: "children"; track entry) {
							<tr [item]="entry" (click)="rowClick(entry)" ufDirectory>
								<td class="no-padding">
									<uf-directory-indicator [icon]="entry.icon" class="light">{{ entry.label }}</uf-directory-indicator>
								</td>
								<td>{{ entry.shortLabel }}</td>
								<td>
									@if (entry.identifier) {
										<code>{{ entry.identifier }}</code>
									}
								</td>
								<td>
									@if (entry.isRequired) {
										<uf-checkbox [value]="true" [disabled]="true" class="cell" />
									}
								</td>
								<td>
									@if (entry.isReadOnly) {
										<uf-checkbox [value]="true" [disabled]="true" class="cell" />
									}
								</td>
								<td>
									@if (entry.autofill) {
										<code>{{ entry.autofill }}</code>
									}
								</td>
								<td>
									@if (entry.bindTo) {
										<code>{{ entry.bindTo }}</code>
									}
								</td>
								<td>
									@if (entry.showIf) {
										<code>{{ entry.showIf }}</code>
									}
								</td>
								<td>{{ entry.template }}</td>
								<td>{{ entry.width }}</td>
								<td>{{ entry.visibleTo }}</td>
								<td>
									@if (entry.variations) {
										<uf-checkbox [value]="true" [disabled]="true" class="cell" />
									}
								</td>
								<td>
									@if (entry.validators) {
										<uf-checkbox [value]="true" [disabled]="true" class="cell" />
									}
								</td>
								<td>
									@if (entry.help) {
										<uf-checkbox [value]="true" [disabled]="true" class="cell" />
									}
								</td>
								<td>
									@for (tag of entry.tags; track tag) {
										<code>{{ tag }}</code
										>&nbsp;
									}
								</td>
							</tr>
						}
					</tbody>
				</table>
			}
		</uf-panel>
	</div>
}
<uc-error-message [error]="error" />
