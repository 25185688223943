import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, DataDisplayListItem, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { DataSeed, UfError, amendOptionsParams, ensureError, ensureUfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ActivityType, DataForwarder, FormSubmittedCondition, IntegrationFeature, IntegrationInfo, IntegrationProvider, UcClient, UcFormBucketClient, UcIntegrations, UcProject, UcRoles, UcWorkflow, WorkflowActivityInfo, WorkflowActivityTimer, WorkflowCondition, WorkflowEventType, WorkflowNotification, WorkflowNotificationRecipientFormData, WorkflowNotificationRecipientType, WorkflowRule, WorkflowRuleActivity } from 'client';
import { EditData, SaveOption, SaveOptionType, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { ConsoleNameLabel } from 'constant';
import { appendSuffixCopy, reloadCurrentRoute } from 'pages/utils';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { DialogsService } from 'services/dialogs.service';
import { TitleService } from 'services/title.service';

import { WorkflowActivityLabel, WorkflowEventLabel } from './constants';
import { WorkflowNotificationFormDataOptions, WorkflowNotificationRecipientOptions, WorkflowNotificationUserManagementRecipientOptions } from './workflow-notification/workflow-notification-constants';
import { ControlKeys, SchemaTransitionDescription, WorkflowRuleFormController, WorkflowRuleFormModel } from './workflow-rule-form.controller';
import { WorkflowRulesTableManager } from './workflow-rules-table-manager';
import { buildHeaderConfig } from './workflow-utils';

@Component({
	selector: 'uc-workflow-rule-form',
	templateUrl: './workflow-rule-form.html',
	providers: [WorkflowRuleFormController],
	standalone: false,
})
export class WorkflowRuleFormComponent implements EditData, OnInit, OnDestroy {

	protected readonly consoleNameLabel = ConsoleNameLabel;
	protected readonly controlKeys = ControlKeys;
	protected readonly activityType = ActivityType;
	protected readonly eventTypes = WorkflowEventType;
	protected readonly eventOptions: DataSeed[] = [
		{ _id: WorkflowEventType.FormSubmitted, _display: WorkflowEventLabel[WorkflowEventType.FormSubmitted] },
		{ _id: WorkflowEventType.Timer, _display: WorkflowEventLabel[WorkflowEventType.Timer] },
		{ _id: WorkflowEventType.ApiEvent, _display: WorkflowEventLabel[WorkflowEventType.ApiEvent] },
		{ _id: WorkflowEventType.RoleAdded, _display: WorkflowEventLabel[WorkflowEventType.RoleAdded] },
	];
	protected readonly activityOptions: DataSeed[] = [
		{ _id: ActivityType.Notification, _display: WorkflowActivityLabel[ActivityType.Notification] },
		{ _id: ActivityType.Timer, _display: WorkflowActivityLabel[ActivityType.Timer] },
		{ _id: ActivityType.DataForwarder, _display: WorkflowActivityLabel[ActivityType.DataForwarder] },
		{ _id: ActivityType.FormData, _display: WorkflowActivityLabel[ActivityType.FormData] },
	];

	protected root: UfControlGroup;
	protected breadcrumbs: Breadcrumb[];
	protected error?: UfError;
	protected bucketError: Error | null = null;
	protected activityData: DataDisplayListItem[][] = [];

	// Select inputs
	protected buckets: string[] = [];
	protected filteredFormOptions: DataSeed[] = [];
	protected formOptions: DataSeed[] = [];
	protected integrations: IntegrationInfo[] = [];

	// filtered options
	protected filteredTransitions: SchemaTransitionDescription[] = [];
	protected filteredFeatures: IntegrationFeature[] = [];
	protected filteredTimers: WorkflowActivityInfo[] = [];
	protected filteredDataForwarders: WorkflowActivityInfo[] = [];
	protected filteredFormDatas: WorkflowActivityInfo[] = [];
	protected filteredNotifications: WorkflowNotification[] = [];
	protected filteredRoles: string[];

	private features: IntegrationFeature[] = [];
	private _integrations?: IntegrationInfo[];
	private integrationCache = new Map<string, Promise<IntegrationProvider>>();
	private subscriptions = new Subscription();
	private recipientTypes = [...WorkflowNotificationRecipientOptions, ...WorkflowNotificationUserManagementRecipientOptions];
	private formDataTypes = WorkflowNotificationFormDataOptions;
	private prevEventType?: WorkflowEventType;
	private prevBucket: string | null;
	private hasSaveAndNextButton: boolean;

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private ucWorkflow = inject(UcWorkflow);
	private ucIntegrations = inject(UcIntegrations);
	private ucClient = inject(UcClient);
	private ucRoles = inject(UcRoles);
	private titleService = inject(TitleService);
	private toastService = inject(ToastService);
	private ucProject = inject(UcProject);
	private ucFormBucketClient = inject(UcFormBucketClient);
	private formController = inject(WorkflowRuleFormController);
	private modalService = inject(ModalService);
	private dialogs = inject(DialogsService);
	private builderHeaderService = inject(BuilderHeaderService);
	private breadcrumbService = inject(BreadcrumbService);
	private tableManager = inject<WorkflowRulesTableManager>(TableContainerManager);

	get edited() {
		return !!this.builderHeaderService.config.edited;
	}

	set edited(v: boolean) {
		this.builderHeaderService.config.edited = v;
	}

	async ngOnInit() {
		try {
			this.builderHeaderService.init();

			const { id, duplicate } = this.route.snapshot.params;

			this.hasSaveAndNextButton = id !== 'new' && !duplicate;

			const workflowRule = await this.loadWorkflowRule(id);
			let formModel: WorkflowRuleFormModel | null = null;

			try {
				if (id !== 'new') {
					formModel = await this.formController.toFormModel(workflowRule);

					if (duplicate) {
						formModel.id = null as any as string;
						formModel.consoleName = appendSuffixCopy({ label: formModel.consoleName });
					}
				}

				// Collect all require activities and set lists for inputs
				const eventActivityType = this.toActivityType(workflowRule.event?.type);
				const activityTypes = workflowRule.activities.map((a) => a.type);

				if (eventActivityType != null) {
					activityTypes.push(eventActivityType);
				}

				if (formModel?.integration) {
					this.integrations = await this.getIntegrations();
					this.features = await this.getFeatures(formModel.integration.provider.id);
					this.filteredFeatures = this.features;
				}

				if (formModel?.condition && (formModel.condition as FormSubmittedCondition).bucket) {
					await this.searchForms(null, 1000, (formModel.condition as FormSubmittedCondition).bucket);
				}

				if (workflowRule?.event?.type === WorkflowEventType.FormSubmitted && workflowRule.condition != null) {
					const { bucket } = workflowRule.condition as FormSubmittedCondition;
					const definitionInfo = await this.ucProject.getForms({ params: { bucket, limit: 2 } });

					this.formOptions = definitionInfo.map((d) => ({ _id: d.identifier, _display: d.name }));
				}

				this.root = this.formController.buildRoot(formModel);

				this.subscriptions.add(this.root.valueChanges.subscribe(() => {
					this.edited = true;
				}));

				for (const activity of (formModel?.activities ?? [])) {
					const activityInfo = await this.ucWorkflow.getActivity(activity.id);

					this.activityData.push(await this.addActivityData(activityInfo));
				}

				this.titleService.updateTitle(workflowRule.consoleName);
			} catch (e) {
				this.error = useDefaultErrorMessage(e);

				return;
			}

			// Set breadcrumbs
			this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => void this.save(saveOption)));
			this.buildHeaderConfig(workflowRule);
		} catch (e) {
			this.error = useDefaultErrorMessage(e);
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected get timer(): UfControl {
		return this.root.get(ControlKeys.Timer) as UfControl;
	}

	protected get integration(): UfControl {
		return this.root.get(ControlKeys.Integration) as UfControl;
	}

	protected get feature(): UfControl {
		return this.root.get(ControlKeys.Feature) as UfControl;
	}

	protected get condition(): UfControlGroup {
		return this.root.get(ControlKeys.Condition) as UfControlGroup;
	}

	protected get transitions(): UfControl {
		return this.condition.get(ControlKeys.Transitions) as UfControl;
	}

	protected get forms(): UfControl {
		return this.condition.get(ControlKeys.Forms) as UfControl;
	}

	protected get activities(): UfControlArray {
		return this.root.get(ControlKeys.Activities) as UfControlArray;
	}

	protected get eventType(): UfControl {
		return this.root.get(ControlKeys.EventType) as UfControl;
	}

	private get workflowRule(): WorkflowRuleFormModel {
		return this.root.getRawValue() as WorkflowRuleFormModel;
	}

	private get notificationsActivities(): WorkflowRuleActivity[] {
		const activities = this.workflowRule.activities;

		return activities.filter((activity) => activity.type === ActivityType.Notification);
	}

	private get timersActivities(): WorkflowRuleActivity[] {
		const activities = this.workflowRule.activities;

		return activities.filter((activity) => activity.type === ActivityType.Timer);
	}

	private get formDataItemsActivities(): WorkflowRuleActivity[] {
		const activities = this.workflowRule.activities;

		return activities.filter((activity) => activity.type === ActivityType.FormData);
	}

	private get dataForwardersActivities(): WorkflowRuleActivity[] {
		const activities = this.workflowRule.activities;

		return activities.filter((activity) => activity.type === ActivityType.DataForwarder);
	}

	protected async eventChange(type: WorkflowEventType) {
		if (type === this.prevEventType) {
			return;
		}

		if (this.prevEventType && this.activities.controls.length &&
            !await this.modalService.openConfirm({
            	title: 'Change Event Type',
            	message: 'Activities are going to be reset',
            	confirmLabel: 'Change',
            	cancelLabel: `Don't Change`,
            })) {
			this.eventType.setValue(this.prevEventType);

			return;
		}

		this.prevEventType = type;

		if (type === WorkflowEventType.ApiEvent) {
			this.integrations = await this.getIntegrations();
		}

		while (this.activities.controls.length) {
			this.activities.removeAt(0);
		}

		this.formController.resetEventForm({
			type,
			forms: this.forms,
			timer: this.timer,
			bucket: this.bucket,
			feature: this.feature,
			integration: this.integration,
			transitions: this.transitions,
			roles: this.condition.get(ControlKeys.Roles) as UfControl,
			includeNewUser: this.condition.get(ControlKeys.IncludeNewUser) as UfControl,
			includeExternal: this.condition.get(ControlKeys.IncludeExternal) as UfControl,
		});
	}

	protected async integrationChange(integration?: IntegrationInfo) {
		if (!integration) {
			return;
		}

		try {
			this.features = await this.loadFeatures(integration.provider.id);
		} catch (e) {
			this.error = useDefaultErrorMessage(e);
		}
	}

	protected async searchBuckets(q: string) {
		this.bucketError = null;

		try {
			const schemaInfo = await this.ucFormBucketClient.list({ params: { q } });

			this.buckets = schemaInfo.map((i) => (i.id));
		} catch (e) {
			this.bucketError = ensureError(e, 'Failed to load Form Data Repositories');
		}
	}

	protected async bucketChange(bucket: string | null) {

		if (bucket === this.prevBucket) {
			return;
		}

		this.bucketError = null;
		this.formOptions = [];

		if (this.prevBucket && this.activities.controls.length &&
            !await this.modalService.openConfirm({
            	title: 'Change Form Data Repository',
            	message: 'Notification Activities are going to be removed.',
            	confirmLabel: 'Change',
            	cancelLabel: `Don't Change`,
            })
		) {
			this.bucket.setValue(this.prevBucket);

			return;
		}

		this.prevBucket = bucket;
		this.transitions.reset({ value: [], disabled: false });
		this.removeNotificationActivities();

		if (bucket == null) {
			this.transitionOptions.reset([]);

			return;
		}

		try {
			const { transitions } = await this.ucFormBucketClient.get(bucket);

			this.transitionOptions.reset(transitions.map((transition) => this.formController.mapSchemaTransitionDescription(transition)));
			const definitionInfo = await this.ucProject.getForms({ params: { bucket, limit: 2 } });

			this.formOptions = definitionInfo.map((d) => ({ _id: d.identifier, _display: d.name }));
		} catch (e) {
			this.error = ensureUfError(e, 'Failed to load schema');
		}
	}

	protected async searchForms(q: string | null, limit?: number, bucket?: string) {
		const definitionInfo = await this.ucProject.getForms({ params: { q, bucket: bucket ?? this.bucket.value, limit } });

		this.filteredFormOptions = definitionInfo.map((d) => ({ _id: d.identifier, _display: d.name }));
	}

	protected openActivity(controlGroup: UfControlGroup) {
		const activity = controlGroup.getRawValue() as WorkflowActivityInfo;

		switch (activity.type) {
			case ActivityType.DataForwarder:
				return `../../data-forwarders/${activity.id}`;
			case ActivityType.FormData:
				return `../../form-data/${activity.id}`;
			case ActivityType.Notification:
				return `../../notifications/${activity.id}`;
			case ActivityType.Timer:
				return `../../timers/${activity.id}`;
		}
	}

	protected async searchTimers(q: string, currentActivity?: WorkflowActivityInfo) {
		const params = { type: ActivityType.Timer, q, bucket: undefined };

		if ((this.root.value as WorkflowRuleFormModel).eventType === WorkflowEventType.FormSubmitted && this.bucket.value) {
			params.bucket = this.bucket.value;
		}

		const timers = await this.ucWorkflow.getActivities({ params });

		this.filteredTimers = timers.filter((timer) => !this.timersActivities.some((t) => t.id === timer.id && t.id !== currentActivity?.id));
	}

	protected async searchIntegrations(q: string) {
		this.integrations = await this.ucIntegrations.list(amendOptionsParams({ q }));
	}

	protected searchFeatures(query: string) {
		this.filteredFeatures = this.features.filter((feature) => !query || feature.name.toLowerCase().includes(query.toLowerCase()));
	}

	protected async searchRoles(query: string | null) {
		this.filteredRoles = (await this.ucRoles.get(query ?? undefined)).map((r) => r.name);
	}

	protected async searchNotifications(q: string, currentActivity?: WorkflowActivityInfo) {

		const { eventType } = this.root.getRawValue() as WorkflowRuleFormModel;

		const params = { type: ActivityType.Notification, q, bucket: undefined };

		if (eventType === WorkflowEventType.FormSubmitted && this.bucket.value) {
			params.bucket = this.bucket.value;
		}

		this.filteredNotifications = (await this.ucWorkflow.getActivities<WorkflowNotification>({ params }))
			.filter((activity: WorkflowNotification) => {
				if (this.notificationsActivities.some((notification) => notification.id === activity.id && notification.id !== currentActivity?.id)) {
					return false;
				}

				return eventType !== WorkflowEventType.RoleAdded || !activity.bucket && (!q || activity.consoleName?.toLowerCase().includes(q.toLowerCase()));
			});
	}

	protected async searchDataForwarders(q: string, currentActivity?: WorkflowActivityInfo) {
		const params = { type: ActivityType.DataForwarder, q, bucket: undefined };

		if ((this.root.value as WorkflowRuleFormModel).eventType === WorkflowEventType.FormSubmitted && this.bucket.value) {
			params.bucket = this.bucket.value;
		}

		const dataForwarders = await this.ucWorkflow.getActivities({ params });

		this.filteredDataForwarders = dataForwarders.filter(
			(dataForwarder) => !this.dataForwardersActivities.some((df) => df.id === dataForwarder.id && df.id !== currentActivity?.id),
		);
	}

	protected async searchFormData(q: string, currentActivity?: WorkflowActivityInfo) {
		const formDataItems = await this.ucWorkflow.getActivities({ params: { q, type: ActivityType.FormData, bucket: this.bucket.value } });

		this.filteredFormDatas = formDataItems.filter((formData) => !this.formDataItemsActivities.some((fd) => fd.id === formData.id && fd.id !== currentActivity?.id));
	}

	protected searchTransitions(q: string | null) {
		const transitions = this.transitionOptions?.value;

		this.filteredTransitions = transitions.filter((t: SchemaTransitionDescription) => t.description.toLowerCase().includes((q ?? '').toLowerCase()));
	}

	protected addActivity({ _id: type }: { _id: ActivityType }) {
		this.activityData.push();
		this.activities.push(this.formController.buildActivityControl({ type } as any));
	}

	protected async activityChange(controlGroup: UfControlGroup, activity: WorkflowActivityInfo, index: number) {
		this.activityData[index] = await this.addActivityData(activity);
		controlGroup.get(ControlKeys.Id)?.setValue(activity?.id);
		// Reset the filtered options
		this.filteredDataForwarders = [];
		this.filteredNotifications = [];
		this.filteredTimers = [];
		this.filteredFormDatas = [];
	}

	protected async deleteActivity(index: number) {
		if (!await this.dialogs.confirmDelete()) {
			return;
		}
		this.activityData.splice(index, 1);
		this.activities.removeAt(index);
	}

	protected async searchTimer(q: string) {
		this.filteredTimers = await this.ucWorkflow.getActivities({ params: { type: ActivityType.Timer, q } });
	}

	protected async save(saveOption?: SaveOption) {
		if (this.root.invalid) {
			this.root.setSubmitted();

			return;
		}

		const workflowRule = this.formController.toDataModel(this.workflowRule);

		if (!workflowRule) {
			return;
		}

		this.error = undefined;
		try {
			let updatedWorkflowRule: WorkflowRule | undefined;

			if ((workflowRule as WorkflowRule).id != null) {
				updatedWorkflowRule = await this.ucWorkflow.updateRule(workflowRule as WorkflowRule);
				this.tableManager.updateItem.next(updatedWorkflowRule as WorkflowRule);
			} else {
				updatedWorkflowRule = await this.ucWorkflow.addRule(workflowRule);
				this.tableManager.reload.next();
			}

			this.toastService.success('Workflow rule saved');

			if (!saveOption) {
				if (!(workflowRule as WorkflowRule).id) {
					void this.router.navigate(['..', updatedWorkflowRule.id], { relativeTo: this.route });
				}

				this.patchWorkflowConditionExpression(this.workflowRule, updatedWorkflowRule.condition);

				this.builderHeaderService.updateConfig({
					breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [updatedWorkflowRule.consoleName]),
					lastModifiedAt: updatedWorkflowRule.lastModifiedAt,
					lastModifiedBy: updatedWorkflowRule.lastModifiedBy,
				});

				this.edited = false;

				this.titleService.updateTitle(updatedWorkflowRule.consoleName);

				return;
			}

			this.edited = false;
			switch (saveOption.id) {
				case SaveOptionType.New:
					if (this.router.url.endsWith('/new')) {
						reloadCurrentRoute(this.router);

						return;
					} else {
						void this.router.navigate(['../', 'new'], { relativeTo: this.route });

						return;
					}
				case SaveOptionType.Next: {
					const nextId = this.tableManager.getNextItem(updatedWorkflowRule.id)?.id;

					if (nextId) {
						void this.router.navigate(['..', nextId], { relativeTo: this.route });

						return;
					}
					break;
				}
			}

			void this.router.navigate(['..'], { relativeTo: this.route });
		} catch (e) {
			this.toastService.error(ensureUfError(e).message);
		}
	}

	private get transitionOptions(): UfControl {
		return this.root.get(ControlKeys.TransitionOptions) as UfControl;
	}

	private get bucket(): UfControl {
		return this.condition.get(ControlKeys.Bucket) as UfControl;
	}

	private async loadFeatures(providerId: string) {
		const { features } = await this.ucClient.getAvailableIntegration(providerId);

		return features;
	}

	private async addActivityData(activity?: WorkflowActivityInfo): Promise<DataDisplayListItem[]> {
		switch (activity?.type) {
			case ActivityType.Timer:
				return [{
					term: 'Run at expression',
					data: (activity as WorkflowActivityTimer).expression,
				},
				{
					term: 'Form Data Repository',
					data: activity.bucket,
				}];
			case ActivityType.Notification:
			{
				const recipients = (activity as WorkflowNotification).recipients
					.map((recipient) => {
						if (recipient.type === WorkflowNotificationRecipientType.FormData) {
							return this.formDataTypes.find((option) => option.identifier === (recipient as WorkflowNotificationRecipientFormData).formData.type)?.name;
						} else {
							return this.recipientTypes.find((option) => (option.identifier === recipient.type))?.name;
						}
					})
					.filter(Boolean)
					.join(', ');

				let deliveryMethod = '';

				if ((activity as WorkflowNotification).messages.email) {
					deliveryMethod = 'Email';
				}

				if ((activity as WorkflowNotification).messages.push) {
					deliveryMethod += (deliveryMethod ? ', ' : '') + 'Push';
				}

				return [{
					term: 'Recipient Type',
					data: recipients,
				},
				{
					term: 'Delivery Method',
					data: deliveryMethod,
				}];
			}
			case ActivityType.DataForwarder:
			{
				const details = [{
					term: 'Form Data Repository',
					data: activity.bucket,
				}];

				if ((activity as DataForwarder).integrationId) {
					const integration = await this.ucIntegrations.get((activity as DataForwarder).integrationId as string);

					details.push({
						term: 'Integration',
						data: integration.name,
					});
					const { features } = await this.ucClient.getAvailableIntegration(integration.provider.id);
					const feature = features.find((f) => f.id === (activity as DataForwarder).featureId);

					if (feature) {
						details.push({
							term: 'Feature',
							data: feature.name,
						});
					}
				}

				return details;
			}
			default:
				return [];
		}
	}

	private async getIntegrations() {
		if (this._integrations == null) {
			this._integrations = await this.ucIntegrations.list();
		}

		return this._integrations;
	}

	private async getFeatures(providerId: string): Promise<IntegrationFeature[]> {
		const cachedPromise = this.integrationCache.get(providerId);

		if (cachedPromise != null) {
			const { features } = await cachedPromise;

			return features;
		}
		const promise = this.ucClient.getAvailableIntegration(providerId);
		const { features } = await promise;

		this.integrationCache.set(providerId, promise);

		return features;
	}

	private toActivityType(type?: WorkflowEventType): ActivityType | null {
		switch (type) {
			case WorkflowEventType.ApiEvent: return ActivityType.DataForwarder;
			case WorkflowEventType.Timer: return ActivityType.Timer;
			default: return null;
		}
	}

	private removeNotificationActivities() {
		if (!this.activities.controls.length) {
			return;
		}

		this.activities.controls = this.activities.controls.filter((activity) => {
			const { value: { type } } = activity;

			return type !== ActivityType.Notification;
		});
	}

	private buildHeaderConfig(workflowRule: WorkflowRule) {
		const headerConfig = buildHeaderConfig(workflowRule, this.hasSaveAndNextButton);

		headerConfig.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [headerConfig.title]);
		this.builderHeaderService.buildConfig(headerConfig);
	}

	private loadWorkflowRule(id: string) {
		if (id !== 'new') {
			return this.ucWorkflow.getRule(id);
		}

		return {
			consoleName: 'New',
			activities: [] as WorkflowRuleActivity[],
		} as WorkflowRule;
	}

	private patchWorkflowConditionExpression(model: WorkflowRuleFormModel, updatedWorkflowRuleCondition?: WorkflowCondition) {
		if (!updatedWorkflowRuleCondition?.expression || !model.condition) {
			return;
		}

		model.condition.expression = updatedWorkflowRuleCondition.expression;

		this.condition.get(ControlKeys.Expression)?.setValue(model.condition.expression);
	}

}
