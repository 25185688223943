import { Injectable, OnDestroy, inject } from '@angular/core';
import { ToastService, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';
import { TableSourceType, isStringNotEmpty, isValueOfStringEnumType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcTable } from 'client';
import { ConsoleNameRequiredMessage } from 'constant';
import { ContentDefinitionIdentifierValidators, IdentifierFunctions } from 'helpers/helpers';
import { ContextService } from 'services/context.service';

export enum TableConfigurationKeys {
	Title = 'title',
	ConsoleName = 'consoleName',
	Identifier = 'identifier',
	Source = 'source',
	SourceType = 'sourceType',
	Description = 'description',
	Help = 'help',
	ShowCount = 'showCount',
	HideExport = 'hideExport',
	ExportVisibleTo = 'exportVisibleTo',
	DefaultSort = 'defaultSort',
	Columns = 'columns',
	VisibleFilters = 'visibleFilters',
	AdvancedFilter = 'advancedFilter',
	Filter = 'filter',
	Id = 'id',
	PublishState = 'publishState',
	LastModifiedAt = 'lastModifiedAt',
	LastModifiedBy = 'lastModifiedBy',
	LastPublishedAt = 'lastPublishedAt',
	LastPublishedBy = 'lastPublishedBy',
	HasDetail = 'hasDetail',
}

@Injectable()
export class TableConfigurationController implements OnDestroy {

	private subscriptions = new Subscription();
	private ufb = inject(UfFormBuilder);
	private context = inject(ContextService);
	private toast = inject(ToastService);

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	buildRoot(table: UcTable): UfControlGroup {

		const titleControl = this.ufb.control(table.title, ValidatorFunctions.required('Title is required'));
		const consoleNameControl = this.ufb.control(table.consoleName, ValidatorFunctions.required(ConsoleNameRequiredMessage));
		const identifierControl = this.ufb.control({ value: table.identifier, disabled: isStringNotEmpty(table.lastPublishedAt) }, ContentDefinitionIdentifierValidators);
		// it's an inverted logic, if it's false, it means the checkbox is checked
		// TODO: changes to a property called export - so at least it's more readable
		const hideExportControl = this.ufb.control({
			value: !table.hideExport,
			disabled: table.sourceType === TableSourceType.Company,
		});
		const exportVisibleToControl =this.ufb.control({
			value: table.exportVisibleTo?.sort(stringsCaseInsensitiveLocalCompare),
			disabled: table.sourceType === TableSourceType.Company,
		});

		if (!table.id) {
			this.subscriptions.add(titleControl.valueChanges.subscribe((value) => {
				let identifier = '';

				if (value != null && value !== '') {
					identifier = IdentifierFunctions.kebabize(value || '')
						.substring(0, IdentifierFunctions.IDENTIFIER_MAX_LENGTH);
				}

				if (!identifierControl.dirty) {
					identifierControl.setValue(identifier);
				}

				if (!consoleNameControl.dirty) {
					consoleNameControl.setValue(value);
				}
			}));
		} else if (!isStringNotEmpty(table.lastPublishedAt)) {
			this.subscriptions.add(identifierControl.valueChanges.subscribe(() => {
				this.toast.warning('Editing the identifier may cause problems, because the identifier could be linked elsewhere');
			}));
		}

		this.subscriptions.add(hideExportControl.valueChanges.subscribe(() => {
			exportVisibleToControl.setValue(null);
		}));

		return this.ufb.group({
			[TableConfigurationKeys.Id]: this.ufb.control({ value: table.id, disabled: true }),
			[TableConfigurationKeys.Title]: titleControl,
			[TableConfigurationKeys.ConsoleName]: consoleNameControl,
			[TableConfigurationKeys.Source]: this.ufb.control({ value: table.source, disabled: true }),
			[TableConfigurationKeys.SourceType]: this.ufb.control({ value: table.sourceType, disabled: true }),
			[TableConfigurationKeys.HasDetail]: this.ufb.control({ value: table.hasDetail, disabled: true }),
			[TableConfigurationKeys.PublishState]: this.ufb.control({ value: table.publishState, disabled: true }),
			[TableConfigurationKeys.LastModifiedAt]: this.ufb.control({ value: table.lastModifiedAt, disabled: true }),
			[TableConfigurationKeys.LastModifiedBy]: this.ufb.control({ value: table.lastModifiedBy, disabled: true }),
			[TableConfigurationKeys.LastPublishedAt]: this.ufb.control({ value: table.lastPublishedAt, disabled: true }),
			[TableConfigurationKeys.LastPublishedBy]: this.ufb.control({ value: table.lastPublishedBy, disabled: true }),
			[TableConfigurationKeys.Description]: this.ufb.control(table.description),
			[TableConfigurationKeys.DefaultSort]: this.ufb.control(table.defaultSort),
			[TableConfigurationKeys.Help]: this.ufb.control(table.help),
			[TableConfigurationKeys.Columns]: this.ufb.control(table.columns),
			[TableConfigurationKeys.VisibleFilters]: this.ufb.control(table.visibleFilters),
			[TableConfigurationKeys.AdvancedFilter]: this.ufb.control(table.advancedFilter),
			[TableConfigurationKeys.Filter]: this.ufb.control(table.filter),
			[TableConfigurationKeys.Identifier]: identifierControl,
			[TableConfigurationKeys.ShowCount]: this.ufb.control({
				value: table.showCount,
				disabled: !this.context.tenantSettings?.features.indexing || ![TableSourceType.Bucket, TableSourceType.Users].includes(table.sourceType),
			}),
			[TableConfigurationKeys.HideExport]: hideExportControl,
			[TableConfigurationKeys.ExportVisibleTo]: exportVisibleToControl,
		});
	}

	fromControlGroupToData(controlGroup: UfControlGroup): UcTable {
		const table = controlGroup.getRawValue() as UcTable;

		// it's an inverted logic, if it's false, it means the checkbox is checked
		table.hideExport = !table.hideExport;
		if (table.hideExport) {
			delete table.exportVisibleTo;
		}

		const valuesOfTableConfigurationKey = isValueOfStringEnumType(TableConfigurationKeys);

		for (const key of Object.keys(table)) {
			if (!valuesOfTableConfigurationKey(key)) {
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete table[key as keyof UcTable];
			}
		}

		return table;
	}

}
