import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Input, NgZone, OnDestroy, inject } from '@angular/core';

import { DragListComponent, DropPosition } from './drag-list.component';
import { DragService } from './drag.service';

@Directive({
	selector: '[dragItem]',
	standalone: false,
})
export class DragItemDirective implements AfterViewInit, OnDestroy {

	@HostBinding('class.disabled') @Input() dragDisabled: boolean | null | undefined;
	@HostBinding('class.hover') hover: boolean | null | undefined;

	@Input() nestable = false;
	@Input({ required: true }) dragItem: any;

	hasHandle = false;
	handleUsed = false;

	private service = inject(DragService);
	private nativeElement = inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;
	private zone = inject(NgZone);
	private parent = inject(DragListComponent, { optional: true });
	private index: number | undefined;
	private rect: DOMRect | null;
	private onDragEnterReference: (event: DragEvent) => void;
	private onDragOverReference: (event: DragEvent) => void;
	private onDragLeaveReference: (event: DragEvent) => void;

	@HostBinding('draggable') get isDraggable() {
		return !this.dragDisabled;
	}

	ngAfterViewInit() {
		this.onDragEnterReference = this.onDragEnter.bind(this);
		this.onDragOverReference = this.onDragOver.bind(this);
		this.onDragLeaveReference = this.onDragLeave.bind(this);

		this.zone.runOutsideAngular(() => {
			this.nativeElement.addEventListener('dragenter', this.onDragEnter.bind(this));
			this.nativeElement.addEventListener('dragover', this.onDragOver.bind(this));
			this.nativeElement.addEventListener('dragleave', this.onDragLeave.bind(this));
		});
	}

	ngOnDestroy() {
		this.nativeElement.removeEventListener('dragenter', this.onDragEnterReference);
		this.nativeElement.removeEventListener('dragover', this.onDragOverReference);
		this.nativeElement.removeEventListener('dragleave', this.onDragLeaveReference);
	}

	onDragEnter() {
		this.index = this.getIndex();
	}

	onDragOver(event: DragEvent) {
		if (!this.parent || this.index == null) {
			return;
		}

		if (!this.rect) {
			this.rect = this.nativeElement.getBoundingClientRect();
		}

		const threshold = this.nestable ? Math.min(30, (this.rect.height / 4)) : this.rect.height / 2;
		const dropPosition = this.getDropPosition(threshold, event.clientY - this.rect.top);

		const coords = {
			x: this.nativeElement.offsetLeft,
			y: this.calculateTop(dropPosition),
		};

		const success = this.parent.setPosition(this.index, dropPosition, coords.x, coords.y, this.rect.width);

		this.hover = success && dropPosition === DropPosition.Inside;
	}

	onDragLeave() {
		this.hover = false;
	}

	@HostListener('dragstart', ['$event'])
	onDragStart(event: DragEvent) {
		event.dataTransfer?.setData('text/plain', ''); // needed for Firefox to work with draggable items
		event.stopPropagation();

		if (this.dragDisabled) {
			return;
		}

		if (this.hasHandle && !this.handleUsed) {
			// console.warn('Canceling drag, use handle!');
			event.preventDefault();

			return;
		}

		this.handleUsed = false;
		this.service.data = this.dragItem;

		const { target } = event;

		if (target instanceof HTMLElement) {
			target.style.opacity = '.2';
		}

		if (this.parent) {
			this.service.dragIndex = this.getIndex();
			this.service.sourceList = this.parent;
		} else {
			this.service.dragIndex = null;
			this.service.sourceList = null;
		}
	}

	@HostListener('dragend', ['$event'])
	onDragEnd(event: DragEvent) {
		event.stopPropagation();

		const { target } = event;

		if (target instanceof HTMLElement) {
			target.style.opacity = 'unset';
		}
	}

	@HostListener('drop', ['$event'])
	onDrop() {
		this.hover = false;
	}

	private getIndex(): number {
		if (this.dragItem && this.parent) {
			return this.parent.findIndex(this.dragItem);
		}

		// we fall back to getting index using DOM position
		// this assumes no other elements on parent
		return Array.prototype.indexOf.call(this.nativeElement.parentElement?.children, this.nativeElement);
	}

	private getDropPosition(threshold: number, yPos: number): DropPosition {
		const itemHeight = this.nativeElement.offsetHeight;
		let dropPosition = DropPosition.Inside;

		if (yPos <= threshold) {
			dropPosition = DropPosition.Before;
		} else if (yPos > itemHeight - threshold) {
			dropPosition = DropPosition.After;
		}

		return dropPosition;
	}

	private calculateMiddle(a: HTMLElement, b: HTMLElement): number {
		const bottom = a.offsetTop + a.offsetHeight;

		return bottom + (b.offsetTop - bottom) / 2;
	}

	// search for siblings and move the indicator between the items
	private calculateTop(dropPosition: DropPosition): number {
		let nextEl = this.nativeElement.nextElementSibling as HTMLElement | null;

		if (nextEl?.classList.contains('drop-indicator')) {
			nextEl = null;
		}

		const previousEl = this.nativeElement.previousElementSibling as HTMLElement | null;

		if (dropPosition === DropPosition.After) {
			if (nextEl) {
				return this.calculateMiddle(this.nativeElement, nextEl);
			}

			return this.nativeElement.offsetTop + this.nativeElement.offsetHeight;
		}

		if (dropPosition === DropPosition.Before && previousEl) {
			return this.calculateMiddle(previousEl, this.nativeElement);
		}

		return this.nativeElement.offsetTop;
	}

}
