import { Injectable, inject } from '@angular/core';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { DataDisplayIconValue, DataDisplayLozengeValue, DataDisplayService, FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig, ToastService } from '@unifii/library/common';
import { DataType, DefinitionPublishState } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { SchemaInfo, SystemRole, UcFormBucketClient } from 'client';
import { PublishedStatusColour } from 'pipes';
import { ContextService } from 'services/context.service';

import { BucketsDataSource } from './buckets-datasource';

@Injectable()
export class BucketsTableManager implements TableContainerManager<SchemaInfo, FilterValue, FilterEntry> {

	tableConfig: TableConfig<SchemaInfo>;
	showSearch = true;
	reload = new Subject<void>();
	update = new Subject<TableInputs<FilterValue>>();
	inputManager: TableInputManager<FilterValue, FilterEntry>;
	updateItem = new Subject<SchemaInfo | { item: SchemaInfo; trackBy: keyof SchemaInfo }>();
	defaultSort = 'id';

	private ucFormBucketClient = inject(UcFormBucketClient);
	private context = inject(ContextService);
	private toastService = inject(ToastService);
	private dataDisplayService = inject(DataDisplayService);
	private publishedStatusColourPipe = inject(PublishedStatusColour);

	constructor() {
		this.tableConfig = {
			columns: [
				{
					name: 'id',
					label: 'Form Data Repository',
				},
				{
					name: 'forms',
					label: 'Forms',
					value: (item) => item.forms.map((f) => f.label).join(', '),
				},
				{
					name: 'hasConfiguredIndexes',
					label: 'Indexes Configured',
					value: (item) => item.hasConfiguredIndexes ? {
						icon: 'radioTick',
						colour: 'success',
					} satisfies DataDisplayIconValue : null,
				},
				{
					name: 'hasRollingVersion',
					label: 'Rolling Version',
					value: (item) => item.hasConfiguredIndexes ? {
						icon: 'radioTick',
						colour: 'success',
					} satisfies DataDisplayIconValue : null,
				},
				{
					name: 'lastModifiedAt',
					label: 'Last Modified',
					value: ({ lastModifiedAt }) => this.dataDisplayService.displayAsString(lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
				},
				{
					name: 'lastModifiedBy',
					label: 'Modified By',
					hidden: true,
					value: ({ lastModifiedBy }) => lastModifiedBy?.username,
				},
				{
					name: 'lastPublishedAt',
					label: 'Last Published At',
					value: ({ lastPublishedAt }) => this.dataDisplayService.displayAsString(lastPublishedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
				},
				{
					name: 'lastPublishedBy',
					label: 'Published By',
					hidden: true,
					value: ({ lastPublishedBy }) => lastPublishedBy?.username,
				},
				{
					name: 'publishState',
					label: 'State',
					value: (item) => ({
						label: item.publishState,
						colour: this.publishedStatusColourPipe.transform(item.publishState),
					} satisfies DataDisplayLozengeValue),
				},
			],
			row: {
				link: (item) => [item.id],
			},
			actions: [
				{
					label: 'Approve',
					predicate: (row) => this.context.checkRoles(SystemRole.Publisher) && row.$implicit.publishState !== DefinitionPublishState.Approved,
					action: (rows) => {
						const buckets = rows.map((r) => ({ ...r.$implicit }));

						void this.updateBucket(buckets, DefinitionPublishState.Approved);
					},
				}, {
					label: 'Unapprove',
					predicate: (row) => this.context.checkRoles(SystemRole.Publisher) && row.$implicit.publishState !== DefinitionPublishState.Draft,
					action: (rows) => {
						const buckets = rows.map((r) => ({ ...r.$implicit }));

						void this.updateBucket(buckets, DefinitionPublishState.Draft);
					},
				},
			],
			pageSize: 100,
			selectable: 100,
			columnToggles: true,
		};

		this.inputManager = new TableInputManager([], inject(HierarchyUnitProvider), null, null);
	}

	createDataSource({ q }: TableInputs<any> = {}) {
		return new BucketsDataSource(this.ucFormBucketClient, q);
	}

	private async updateBucket(buckets: SchemaInfo[], publishState: DefinitionPublishState): Promise<void> {
		try {

			const ids = buckets.map((bucket) => bucket.id);

			await this.ucFormBucketClient.updateSettingsPublishState(ids, publishState);

			for (const bucket of buckets) {
				bucket.publishState = publishState;
				this.updateItem.next(bucket);
			}

			this.toastService.success('Bucket/s updated');
		} catch (e) {
			this.toastService.error('Failed to update Bucket/s');
		}
	}

}
