import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UfControlGroup, UfFormBuilder, ValidatorFunctions, WindowWrapper } from '@unifii/library/common';
import { AppAuthProviderConfiguration, AuthProvider, AuthProviderConfiguration, OAuthWithCode, OAuthWithPassword, TenantClient, TenantSettings, UfError, decrypt, ensureError, ensureUfError, ensureUfRequestError, isDictionary, isMfaErrorData, isPasswordChangeRequiredErrorData, isStringNotEmpty, isUfRequestError } from '@unifii/sdk';

import { Config } from 'app-config';
import { mapProviderLoginLabel } from 'pages/users/provider-utils';
import { AuthenticationService } from 'services/authentication.service';
import { Auth0DirectoryURL, AzureDirectoryURL, SSOService } from 'services/sso.service';

import { MfaComponentNavigationState } from './mfa.component';
import { PasswordChangeComponentNavigationState } from './password-change.component';

enum LoginControlKeys {
	Username = 'username',
	Password = 'password',
}

@Component({
	selector: 'uc-login',
	templateUrl: './login.html',
	styleUrls: ['./login.less', '../../styles/external-branding.less'],
	standalone: false,
})
export class LoginComponent implements OnInit {

	@HostBinding('class.stretch-component') protected class = true;

	protected readonly controlKeys = LoginControlKeys;
	protected inProgress = true;
	protected error: string | null;
	protected settings: TenantSettings | undefined;
	protected authProviders: AppAuthProviderConfiguration[] = [];
	protected form: UfControlGroup;
	protected isUsernamePasswordAuthenticationEnabled: boolean;

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private ufb = inject(UfFormBuilder);
	private ssoService = inject(SSOService);
	private tenantClient = inject(TenantClient);
	private authService = inject(AuthenticationService);
	private window = inject(WindowWrapper);
	private config = inject(Config);
	private responseURL: string;

	async ngOnInit() {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { reason, code, provider_id, state } = this.route.snapshot.queryParams;

		// SSO sign on pass errors via the reason url param
		if (reason) {
			throw new UfError(reason);
		}

		this.form = this.ufb.group({
			[LoginControlKeys.Username]: [null, ValidatorFunctions.required('Username is required')],
			[LoginControlKeys.Password]: [null, ValidatorFunctions.required('Password is required')],
		});
		this.responseURL = `${this.window.location.origin}/sso`;

		try {
			this.settings = await this.tenantClient.getSettings();
			this.ssoService.authProviders = this.settings.authProviders ?? [];
			this.authProviders = this.ssoService.providerList.map((provider, _, providers) => mapProviderLoginLabel(provider, providers));
			this.isUsernamePasswordAuthenticationEnabled = !!this.settings.isPasswordAuthSupported;

			if (!this.ssoService.authProviders.length && !this.isUsernamePasswordAuthenticationEnabled) {
				throw new UfError('No login options configured, please contact your administrator');
			}

			if (code) {
				await this.authorizeWithCode(code, provider_id, state);
			}

		} catch (error) {
			this.error = ensureUfError(error, 'Tenant configuration not available').message;
		} finally {
			this.inProgress = false;
		}
	}

	protected async login() {

		this.error = null;

		const username = this.form.get(LoginControlKeys.Username)?.value as string | undefined;
		const password = this.form.get(LoginControlKeys.Password)?.value as string | undefined;

		if (this.form.invalid || !username || !password) {
			this.form.setSubmitted();

			return;
		}

		this.inProgress = true;

		try {
			await this.authService.login({ username, password } satisfies OAuthWithPassword);
			this.redirect();
		} catch (e) {

			const error = ensureUfRequestError(e);

			const params = this.route.snapshot.params.next ? { next: this.route.snapshot.params.next } : {};

			if (isPasswordChangeRequiredErrorData(error.data)) {
				void this.router.navigate(['/', 'password-change', params], { state: { oldPassword: password } satisfies PasswordChangeComponentNavigationState });

				return;
			}

			if (isMfaErrorData(error.data)) {

				const { mfaStatus, challenge, acceptedChallenges } = error.data;

				void this.router.navigate(['/', 'mfa', params], { state: { password, challenge, mfaStatus, acceptedChallenges } satisfies MfaComponentNavigationState });

				return;
			}

			this.error = error.message;
		} finally {
			this.inProgress = false;
		}
	}

	protected async providerSignIn(provider: AuthProviderConfiguration) {

		this.inProgress = true;
		this.error = null;

		try {
			const redirectUrl = await this.ssoService.getProviderUrl(provider, this.responseURL);

			if (!redirectUrl) {
				throw new UfError(`${provider.type} authentication failed`);
			}
			this.window.location.href = redirectUrl;

		} catch (e) {
			this.error = ensureUfError(e).message;
		} finally {
			this.inProgress = false;
		}
	}

	private async authorizeWithCode(code: string, provider_id?: string, state?: string): Promise<void> {
		try {
			const loginInfo: OAuthWithCode = { code, provider_id, redirect_uri: this.getRedirectUri(provider_id) };

			if (state) {
				const decoded = await this.decodeState(state);

				loginInfo.provider_id = decoded.providerId;
				loginInfo.redirect_uri = decoded.redirectUri;
			}

			await this.authService.login(loginInfo);

			this.redirect();

		} catch (error) {
			if (isUfRequestError(error) && isDictionary(error.data) && isStringNotEmpty(error.data.message)) {
				throw Error(error.data.message);
			}

			const providerType = this.authProviders.find((p) => `${p.id}` === provider_id)?.type ?? 'External provider';

			throw ensureError(error, `${providerType} authentication failed`);
		}
	}

	private async decodeState(state: string): Promise<{ providerId: string; redirectUri: string }> {

		const decrypted = await decrypt({ byteString: decodeURIComponent(state), key: this.config.appId });
		const searchParams = new URLSearchParams(decodeURIComponent(decrypted));
		const providerId = searchParams.get('providerId') ?? '';
		const redirectUri = searchParams.get('redirectUri') ?? '';

		return {
			providerId, redirectUri,
		};
	}

	private getRedirectUri(providerId?: string): string | undefined {

		if (!providerId) {
			return undefined;
		}

		const provider = this.authProviders.find((p) => '' + p.id === providerId);

		if (provider?.useDirectory === false) {
			return this.responseURL;
		}

		switch (provider?.type) {
			case AuthProvider.Azure: return AzureDirectoryURL;
			case AuthProvider.Auth0: return Auth0DirectoryURL;
			default: throw new UfError('Invalid URL, could not match provider id');
		}
	}

	private redirect() {
		void this.router.navigateByUrl(this.route.snapshot.params.next ?? '/');
	}

}

