import { Injectable, OnDestroy, inject } from '@angular/core';
import { UfControlGroup, UfFormBuilder, ValidatorFunctions, isIdentifiersPathExpression } from '@unifii/library/common';
import { Dictionary, FieldType, Option, Transition } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ActivityType, FormDataActivity, UcDefinition, UcProject, WorkflowEventType } from 'client';
import { MappableField } from 'models';

import { FieldMappingService } from './field-mapping.service';
import { WorkflowFormDataModel, WorkflowInputMap } from './workflow-types';

export enum FormDataControlKeys {
	Id = 'id',
	ConsoleName = 'consoleName',
	SourceType = 'sourceType',
	Bucket = 'bucket',
	Integration = 'integration',
	TargetForm = 'targetForm',
	TargetTransition = 'targetTransition',
	InputMap = 'inputMap',
}

@Injectable({
	providedIn: 'root',
})
export class WorkflowFormDataFormController implements OnDestroy {

	private readonly requiredMessage = 'Field is Required';
	private subscriptions = new Subscription();

	private ufb = inject(UfFormBuilder);
	private ucProject = inject(UcProject);
	private fieldMappingService = inject(FieldMappingService);

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	async buildRoot(wfDataFormDataModel?: WorkflowFormDataModel): Promise<UfControlGroup> {

		const groupControl = this.ufb.group({
			[FormDataControlKeys.Id]: [{ value: wfDataFormDataModel?.id, disabled: true }],
			[FormDataControlKeys.ConsoleName]: [wfDataFormDataModel?.consoleName, ValidatorFunctions.required(this.requiredMessage)],
			[FormDataControlKeys.SourceType]: [wfDataFormDataModel?.sourceType, ValidatorFunctions.required(this.requiredMessage)],
			[FormDataControlKeys.Bucket]: [wfDataFormDataModel?.bucket, ValidatorFunctions.required(this.requiredMessage)],
			[FormDataControlKeys.TargetForm]: [wfDataFormDataModel?.targetForm, ValidatorFunctions.required(this.requiredMessage)],
			[FormDataControlKeys.TargetTransition]: [wfDataFormDataModel?.targetTransition, ValidatorFunctions.required(this.requiredMessage)],
		});

		if (wfDataFormDataModel?.targetTransition?.source) {
			const targetFields = await this.fieldMappingService.mapDefinitionToMappableFields(wfDataFormDataModel.targetFormDefinition, wfDataFormDataModel.targetTransition);

			groupControl.addControl(FormDataControlKeys.InputMap, this.fieldMappingService.buildInputMapControl(targetFields, wfDataFormDataModel.inputMap));
		}

		this.setSourceType(groupControl, wfDataFormDataModel?.sourceType);

		if (!wfDataFormDataModel?.targetFormDefinition) {
			groupControl.get(FormDataControlKeys.TargetTransition)?.disable();
		}

		this.subscriptions.add(groupControl.get(FormDataControlKeys.TargetForm)?.valueChanges.subscribe((v) => {
			const targetActionControl = groupControl.get(FormDataControlKeys.TargetTransition);

			if (!targetActionControl) {
				return;
			}

			if (v) {
				targetActionControl.enable();
			} else {
				targetActionControl.disable();
			}

			targetActionControl.reset();
		}));

		this.subscriptions.add(groupControl.get(FormDataControlKeys.SourceType)?.valueChanges.subscribe((v) => {
			this.setSourceType(groupControl, v);
		}));

		return groupControl;
	}

	async toModel(data?: FormDataActivity | null): Promise<WorkflowFormDataModel | undefined> {

		if (!data?.id) {
			return;
		}

		const { id, bucket: bucketId, targetFormId, targetAction, targetState, inputMap: inputMapDictionary, consoleName, sourceType } = data;
		const targetFields: MappableField[] = [];
		let bucket: { id: string } | undefined;

		if (bucketId) {
			bucket = { id: bucketId };
		}

		const targetFormDefinition = await this.ucProject.getForm(`${targetFormId}`);
		const targetForm: Option = { name: targetFormDefinition.label, identifier: `${targetFormDefinition.id}` };
		const transitions = this.getFieldTransitions(targetFormDefinition, targetState);
		const targetTransition = transitions.find((t) => t.source === targetState && t.action === targetAction);

		targetFields.push(...await this.fieldMappingService.mapDefinitionToMappableFields(targetFormDefinition, targetTransition));

		const inputMap: WorkflowInputMap[] = [];

		for (const { identifier, isRequired } of targetFields) {
			let expression: string | undefined;
			let field: string | undefined;

			const source: string | undefined = inputMapDictionary[identifier]?.replace(/^(\$data.)/, '');

			if (!isIdentifiersPathExpression(source)) {
				expression = source;
			} else {
				field = source;
			}

			inputMap.push({
				identifier,
				isRequired: !!isRequired,
				field,
				expression,
			});
		}

		return {
			id,
			consoleName,
			sourceType,
			bucket,
			targetForm,
			targetFormDefinition,
			targetTransition,
			inputMap,
		};
	}

	toData(model: WorkflowFormDataModel): FormDataActivity {
		const { id, consoleName, sourceType, targetTransition, inputMap: inputMapInfo } = model;

		const bucket = model.bucket?.id;
		const targetFormId = model.targetFormDefinition.id;
		const targetBucket = model.targetFormDefinition.bucket;
		const targetAction = targetTransition?.action;
		const targetState = targetTransition?.source;
		const inputMap = inputMapInfo
			.filter((ip) => ip.expression ?? ip.field)
			.reduce((acc, ip) => ({ ...acc, [ip.identifier]: ip.expression ?? `$data.${ip.field}` }), {});

		return {
			id,
			consoleName,
			bucket,
			sourceType,
			targetFormId,
			targetBucket,
			targetAction,
			targetState,
			inputMap,
			type: ActivityType.FormData,
		} as FormDataActivity;
	}

	getFieldTransitions(definition?: UcDefinition, targetState?: string): Transition[] {

		const uniqueTransitions: Dictionary<Transition> = {};
		const transitions: Transition[] = [];

		for (const section of definition?.fields?.filter((f) => f.type === FieldType.Section) ?? []) {
			transitions.push(...section.transitions?.filter((t) => t.source === targetState) ?? []);
		}

		// De-Duplicate transitions
		for (const transition of transitions) {
			const { source, target, action, validate } = transition;
			const key = `${source}_${target}_${action}`;
			const transitionByKey = uniqueTransitions[key];

			if (!transitionByKey || (!transitionByKey.validate && !!validate)) {
				uniqueTransitions[key] = transition;
			}
		}

		return Object.values(uniqueTransitions);
	}

	private setSourceType(groupControl: UfControlGroup, type?: WorkflowEventType) {
		switch (type) {
			case WorkflowEventType.FormSubmitted:
				groupControl.get(FormDataControlKeys.Bucket)?.enable();
				break;
			default:
				groupControl.get(FormDataControlKeys.Bucket)?.disable();
				groupControl.get(FormDataControlKeys.Bucket)?.reset();
		}
	}

}
