"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRoleWithDisplay = exports.getRoleDisplay = void 0;
/**
 * Role is displayed as `role.name (role.label)` or just `role.name` when label is not defined
 * @param role
 * @returns role display value
 */
const getRoleDisplay = role => role.label ? `${role.name} (${role.label})` : role.name;
exports.getRoleDisplay = getRoleDisplay;
/**
 * Return a copy of the input T extended with a display attribute if not present in the input already
 * @param role
 * @returns extended copy of T including the display attribute
 */
const getRoleWithDisplay = role => role.display ? role : Object.assign({}, role, {
  display: (0, exports.getRoleDisplay)(role)
});
exports.getRoleWithDisplay = getRoleWithDisplay;
