import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { UcPermissionTableEntry, UcPermissionsClient } from 'client';

import { toUcPermissionTableEntry } from './permissions-functions';

export class PermissionsDataSource extends TableDataSource<UcPermissionTableEntry> {

	filtered: boolean;
	sorted: boolean;

	constructor(private ucPermissions: UcPermissionsClient) {
		super();
	}

	async load(options?: TableDataSourceQueryOptions) {
		try {
			const result = await this.ucPermissions.get({
				analytics: { origin: RequestAnalyticsOrigin.Table },
				params: { offset: options?.offset, limit: options?.limit },
			});

			this.stream.next({ data: result.map(toUcPermissionTableEntry) });
		} catch (error) {
			this.stream.next({ error });
		}
	}

}
