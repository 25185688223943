<uf-panel class="content-pane container">
	@if (!form) {
		<uf-skeleton />
	}
	@if (form) {
		<div class="uf-container-lg">
			<div class="uf-grid row-gap-lg gap-top">
				<uc-table-configuration-details [form]="form" class="col-12" />
				@if (sortControl) {
					<uc-table-configuration-sort [control]="sortControl" [sortableColumns]="sortableColumns" class="col-12" />
				}
				@if (columnsControl) {
					<uc-table-columns [availableColumns]="availableColumns" [parentControl]="form" [columns]="columnsControl.value" (columnsChange)="columnsControl.setValue($event)" class="col-12" />
				}
				@if (visibleFiltersControl) {
					<uc-visible-filters [availableFilters]="availableVisibleFilters" [parentControl]="form" [filters]="visibleFiltersControl.value" (filtersChange)="visibleFiltersControl.setValue($event)" class="col-12" />
				}
				<uc-table-configuration-hidden-filters [availableHiddenFilters]="availableHiddenFilters" [form]="form" class="col-12" />
			</div>
		</div>
	}
</uf-panel>
