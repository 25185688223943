<div #wrapper class="column grow container">
	@if (!errorMessage) {
		<div class="row wrapper">
			@for (column of columns; track column; let i = $index) {
				<uc-hierarchy-finder-column [label]="column.label" [units]="column.units" [selected]="column.selected" [parentId]="column.parentId" [hideEditButton]="i === 0" (selectUnit)="select($event, i)" (updateGroupLabel)="editColumnLabel(i)" (finderUnitChange)="finderUnitChange($event, i)" (add)="add(i)" />
			}
		</div>
	}
	@if (errorMessage) {
		<uf-message [showExpander]="false" [content]="errorMessage" class="error small" icon="alert">
			<button (click)="retry()" class="uf-button x-small right" type="button">Retry</button>
		</uf-message>
	}
</div>
