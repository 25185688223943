import { IntegrationFeatureArgType } from 'client';

export interface IntegrationFeatureArgData {
	identifier: string;
	kind: IntegrationFeatureArgType;
	children?: IntegrationFeatureArgData[];
	listCount?: number;
	kindDescription?: string;
	icon?: string;
}

export enum IntegrationFeatureControlKeys {
	Id = 'id',
	Name = 'name',
	IdExp = 'idExp',
	DisplayExp = 'displayExp',
	InputArgs = 'inputArgs',
	OutputArgs = 'outputArgs',
	EndpointPath = 'endpointPath',
	SecondaryEndpointPath = 'secondaryEndpointPath',
	PreviewEndpointPath = 'previewEndpointPath',
	CorrelationExp = 'correlationExp',
	Identifier = 'identifier',
	TypeKey = 'type',
	IsRequired = 'isRequired',
	Children = 'children',
}
