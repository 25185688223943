@if (form && !error) {
	<div class="header-pane uf-app-bar transparent">
		<uc-builder-header />
	</div>
}

@if (form && !error) {
	<uf-panel class="content-pane container">
		<div class="uf-container-lg">
			<div class="uf-grid row-gap-lg pad-top">
				@if (dataSource) {
					<uc-data-feature-selector [integrations]="integrations" [features]="features" [isNew]="isNew" [control]="form" (integrationChanged)="integrationChanged($event)" (featureChanged)="featureChanged($event)" class="col-12" />
					@if (feature) {
						<uc-data-feature-configuration [control]="form" [feature]="feature" [hideDataSource]="isDataTransaction" [(dataSource)]="dataSource" class="col-12" />
					}
				}
				@if (features && !features.length) {
					<div class="col-12">
						<uf-blockquote icon="warningSolid" content="No features available" class="warning" />
					</div>
				}
				@if (!dataSource) {
					<div class="col-12 gap-lg-top">
						<uf-spinner type="dots" />
					</div>
				}
			</div>
		</div>
	</uf-panel>
}

<uc-error-message [error]="error" />
