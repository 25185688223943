import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { AuthProvider, Option } from '@unifii/sdk';

import { AuthProviderMappingConditionType } from 'client';

import { AuthProviderMappingModel, ConditionOptions, GroupConditionOption, MappingConfig, MappingsControlKeys, RoleAssignedConditionOption } from '../models';

import { AuthProviderMappingsController } from './auth-provider-mapping.controller';

export interface AuthProviderMappingUnit {
	sourceClaims: string[];
	authProviderType: AuthProvider;
	mapping: AuthProviderMappingModel;
	config: MappingConfig;
	edit?: boolean;
}

@Component({
	selector: 'uc-auth-provider-mapping-modal',
	templateUrl: './auth-provider-mapping-modal.html',
	standalone: false,
})
export class AuthProviderMappingModalComponent implements Modal<AuthProviderMappingUnit, AuthProviderMappingModel>, OnInit {

	@HostBinding('class.uc-form-card') cardClass = true;
	data = inject<AuthProviderMappingUnit>(ModalData);
	runtime = inject<ModalRuntime<AuthProviderMappingUnit, AuthProviderMappingModel>>(ModalRuntime);

	protected readonly controlKeys = MappingsControlKeys;
	protected actions: UfControlArray;
	protected mappingForm: UfControlGroup;
	protected conditionOptions = ConditionOptions;
	protected authProviderId: string;
	protected sourceClaims: string[];
	protected config: MappingConfig;
	protected edit?: boolean;
	protected mappingData: AuthProviderMappingModel;

	private mappingsController = inject(AuthProviderMappingsController);

	ngOnInit() {
		if (this.data.authProviderType === AuthProvider.Auth0) {
			this.conditionOptions = [
				RoleAssignedConditionOption,
				...ConditionOptions,
			];
		} else if (this.data.authProviderType === AuthProvider.UnifiiIdentity) {
			this.conditionOptions = [
				RoleAssignedConditionOption,
			];
		} else {
			this.conditionOptions = [
				GroupConditionOption,
				...ConditionOptions,
			];
		}

		this.mappingData = this.data.mapping;
		this.mappingForm = this.mappingsController.buildMapping(this.mappingData);

		this.config = this.data.config;
		this.edit = this.data.edit;

		this.authProviderId = this.mappingForm.get(MappingsControlKeys.Id)?.value;
		this.sourceClaims = this.data.sourceClaims;

		this.actions = this.mappingForm.get(MappingsControlKeys.Actions) as UfControlArray;
	}

	protected get conditions() {
		return this.mappingForm.get(MappingsControlKeys.Condition) as UfControlArray;
	}

	protected addCondition(option: Option) {
		const type = option.identifier as AuthProviderMappingConditionType;
		const controlToAdd = this.mappingsController.buildCondition({ type });

		this.conditions.push(controlToAdd);
	}

	protected deleteCondition({ position, parent }: { position: number; parent?: UfControlGroup | null }) {
		if (!parent) {
			this.conditions.removeAt(position);

			return;
		}

		const childrenControl = parent.get(MappingsControlKeys.Children) as UfControlArray;

		childrenControl.removeAt(position);
	}

	protected save() {
		this.mappingForm.setSubmitted();
		this.mappingForm.updateValueAndValidity();

		if (this.mappingForm.invalid) {
			return;
		}

		this.runtime.close(this.mappingForm.getRawValue());
	}

}
