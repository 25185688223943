<div class="header-pane uf-app-bar transparent">
	<uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large" />
	<button (click)="back()" type="button" class="uf-button tertiary right">Cancel</button>
	<button [disabled]="assets.length === 0" (click)="continue()" type="button" class="uf-button primary">Continue</button>
</div>
<div class="content-pane">
	<uf-panel class="container">
		<uf-media-list [allowedFileTypes]="allowedAssets" (valueChange)="onContentChange($event)" label="Drag and drop the files you wish to upload" />
		@if (disabled) {
			<uf-blockquote class="warning" icon="warningSolid" heading="Upload Unavailable" content="No file types allowed in tenant settings" />
		}
	</uf-panel>
</div>
