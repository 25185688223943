import { Directive, ElementRef, HostListener, Input, OnDestroy, inject } from '@angular/core';

export enum ToolTipPosition {
	Right = 'right',
	Left = 'left',
	Top = 'top',
	Bottom = 'bottom',
}
@Directive({
	selector: '[tooltip]',
	standalone: false,
})
export class TooltipDirective implements OnDestroy {

	@Input({ required: true }) tooltip: string;
	@Input() tooltipPosition: ToolTipPosition;

	private el = inject(ElementRef);
	private container: any;
	private observer: IntersectionObserver = new IntersectionObserver((entries) => this.displayTooltip((entries.map((entry) => entry.boundingClientRect))[0]));

	constructor() {
		this.container = window.document.querySelector('.tooltip');
	}

	@HostListener('mouseenter', ['$event'])
	onMouseEnter(): void {
		this.observer.observe(this.el.nativeElement);
	}

	@HostListener('click')
	onClick() {
		this.disappear();
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.disappear();
	}

	ngOnDestroy() {
		this.disappear();
	}

	private displayTooltip(hoverElementRect: DOMRectReadOnly | undefined) {
		if (hoverElementRect == null) {
			return;
		}

		if (this.container && this.tooltip) {
			this.container.innerHTML = this.tooltip;
			this.show();
			switch (this.tooltipPosition) {
				case ToolTipPosition.Bottom:
					this.container.style.top = hoverElementRect.bottom + 'px';
					this.container.style.left = hoverElementRect.left + (hoverElementRect.width / 2) - (this.container.clientWidth / 2) + 'px';
					break;
				case ToolTipPosition.Right:
					this.container.style.top = hoverElementRect.top + 'px';
					this.container.style.left = hoverElementRect.right + 10 + 'px';
					break;
				case ToolTipPosition.Left:
					this.container.style.top = hoverElementRect.top + 'px';
					this.container.style.left = hoverElementRect.left - (this.container.clientWidth / 2) + 10 + 'px';
					break;
				default: // TOP
					this.container.style.top = hoverElementRect.top - this.container.clientHeight + 'px';
					this.container.style.left = hoverElementRect.left + (hoverElementRect.width / 2) - (this.container.clientWidth / 2) + 'px';
					break;
			}
		}
	}

	private disappear() {
		this.hide();
		this.observer.disconnect();
	}

	private hide() {
		if (this.container) {
			this.container.classList.remove('show');
		}
	}

	private show() {
		if (this.container) {
			this.container.classList.add('show');
		}
	}

}
