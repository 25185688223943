@if (ready && definition && definition.fields) {
	<uc-builder-header />
	<div class="builder-content scrollable">
		<uc-field-reference-picker />
		<div class="builder-growing-section">
			<div class="field-list uf-box flat">
				<uc-builder-fields-header />
				<div [entries]="definition.fields" [current]="selected" (next)="selectField({ subject: $event, atomic: false })" keySelect class="nodes-container scrollable grow">
					<uc-drag-list [items]="definition.fields" [canDrop]="canDrop" [convertAdded]="convertFieldRef" (insert)="inserted($event.item)" (moved)="moved($event.item)" class="scrollable">
						<ul class="uc-list">
							@for (field of definition.fields; track field; let i = $index) {
								<uc-collection-field [field]="field" dragItem />
							}
							@if (!definition.fields.length) {
								<div class="empty">
									<div>
										<img src="/assets/svg/icon-projects.svg" />
										<p>Drag your items here!</p>
									</div>
								</div>
							}
						</ul>
					</uc-drag-list>
				</div>
			</div>
			<div class="field-settings">
				<uf-tabs class="scrollable">
					<uf-tab label="Details">
						<div class="tab-content scrollable uf-box flat">
							@if (!selected) {
								<uc-collection-settings [definition]="definition" />
							}
							@if (selected) {
								<uc-field-builder [field]="selected" />
							}
						</div>
					</uf-tab>
					@if (!selected && previewDefinition) {
						<uf-tab label="Preview">
							<div class="tab-content scrollable uf-box flat">
								@for (field of previewDefinition.fields; track field) {
									<uf-field [scope]="previewScope" [field]="field" />
								}
							</div>
						</uf-tab>
					}
				</uf-tabs>
			</div>
		</div>
	</div>
}
