@if (managerControl.enabled || managerDisplayItems) {
	<div [ngClass]="cssClasses" class="uf-box">
		<div class="uf-app-bar flat accent">
			<div class="title">Manager</div>
			@if (managerControl.enabled && managerDisplayItems) {
				<button (click)="selectManager()" type="button" title="Delete" class="uf-action">
					<uf-icon name="delete" />
				</button>
			}
		</div>
		<div class="uf-grid pad">
			@if (managerControl.enabled && !managerDisplayItems) {
				<uf-search-result [options]="managerOptions" (searchChange)="searchUsers($event)" (valueChange)="selectManager($event)" placeholder="Select manager" nameProperty="_display" class="col-12 default" />
			}
			@if (managerDisplayItems) {
				<uf-data-display-list [items]="managerDisplayItems" class="col-12" />
			}
		</div>
	</div>
}
