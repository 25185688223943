<div class="grid">
	@if (control.controls.title; as titleControl) {
		<uf-text [control]="titleControl | asUfControl" [(value)]="page._title" label="Title" class="col-1of1" />
	}

	@if (control.controls.consoleName; as consoleNameControl) {
		<uf-text [control]="consoleNameControl | asUfControl" [label]="consoleNameLabel" [(value)]="page._consoleName" class="col-1of1" />
	}

	@if (control.controls.identifier; as identifierControl) {
		<uf-text [control]="identifierControl | asUfControl" [maxLength]="maxLength" [(value)]="page.identifier" label="Identifier" class="col-1of1" />
	}

	@if (control.controls.tags; as tagsControl) {
		<uf-chips [control]="tagsControl | asUfControl" [allowCustom]="true" [options]="filteredTags" [(value)]="page.tags" (searchChange)="filterTags($event)" (valueChange)="builderService.refreshTags()" label="Tags" class="col-1of1" />
	}
</div>
