import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'uc-hierarchy',
	templateUrl: './hierarchy.html',
	styleUrls: ['./hierarchy.less'],
	standalone: false,
})
export class HierarchyComponent {

	@HostBinding('class.stretch-component') class = true;

}
