@if (form) {
	<div class="uf-box gap">
		<div class="uf-app-bar flat accent">
			<div class="title">Field Mapping</div>
		</div>
		<table class="uf-table inputs">
			<thead>
				<tr>
					<th class="table-col-3">Unifii User Field</th>
					<th>Provider's User Field</th>
				</tr>
			</thead>
			<tbody>
				@for (key of userMappingsKeys; track key; let i = $index) {
					<tr>
						<td>{{ key }}</td>
						<td class="input-cell">
							@if (fieldMappings.get(key); as control) {
								<uf-autocomplete [control]="control | asUfControl" [options]="allMappingFieldsOptions[key]" [allowCustom]="allowCustom(key)" (searchChange)="findFieldMapping(key, $event)" placeholder="Select a source" nameProperty="display" class="table-cell" />
							}
						</td>
					</tr>
				}
			</tbody>
		</table>
		<uf-error [control]="fieldMappings" />
	</div>
}

<div class="uf-box gap">
	<div class="uf-app-bar accent flat">
		<div class="title">Data Mapping</div>
	</div>

	<uf-expander [dragDisabled]="true" [isExpanded]="false" dragItem>
		<div expanderHeader class="uf-app-bar flat">
			<div class="title">Filters</div>
		</div>
		<div expanderBody>
			@if (filters) {
				<uc-auth-provider-mapping-filters [filters]="filters" [customGroups]="customGroups" [sourceClaims]="sourceClaims" [type]="type" />
			}
		</div>
	</uf-expander>

	<table class="uf-table inputs clickable">
		<thead>
			<tr>
				<th>Provider Conditions</th>
				<th>Unifii User Actions</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			@for (row of filteredRows; track row; let i = $index) {
				<tr (click)="rowClick(row)" class="body-row">
					<td>{{ row.conditionDescription }}</td>
					<td>{{ row.actionDescription }}</td>
					<td class="input-cell">
						<button (click)="$event.stopPropagation(); deleteMapping(row)" type="button" class="uf-action tertiary right" title="Delete">
							<uf-icon name="delete" />
						</button>
					</td>
				</tr>
			}
		</tbody>
	</table>

	<button (click)="addProviderValue()" type="button" class="uf-button right gap-top gap-right gap-bottom">Add Mapping</button>

	@if (filteredRows.length === 0) {
		<div class="col center-all pad">
			<uf-icon name="big_empty" class="x-large accent" />
			<h3 class="gap-none-bottom gap-sm-top">{{ commonTK.TableFeedbackDataFilteredEmptyTitle | translate }}</h3>
			@if (rows.length === 0) {
				<p class="gap-sm-top">{{ commonTK.TableFeedbackDataEmptyDescription | translate }}</p>
			} @else {
				<p class="gap-sm-top">{{ commonTK.TableFeedbackDataFilteredEmptyDescription | translate }}</p>
			}
		</div>
	}
</div>
