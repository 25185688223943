import { Injectable, inject } from '@angular/core';
import { BackendToCredentialRequestOptions, BackendToDeviceMfaSetupChallenge, Client, ClientDeleteOptions, ClientGetOptions, ClientHeadOptions, ClientPatchOptions, ClientPostOptions, ClientPutOptions, Company, DeviceMfaSetupChallenge, FileType, Language, OAuthResponse, OAuthWithCode, OAuthWithMfaDevice, OAuthWithMfaDeviceSetup, OAuthWithMfaRecoveryCode, OAuthWithMfaSms, OAuthWithPassword, OAuthWithVirtualMfa, SMSChallenge, UpdatePasswordDetails, UserInfo, VirtualMfaSecret, getEtag, mergeParams } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { Config } from 'app-config';
import { IntegrationProvider, IntegrationProviderInfo, MyAccount, TenantSettings, UcProjectInfo } from 'client';
import { DefaultPaginationParams } from 'constant';

import { TokenService } from './token.service';

@Injectable({ providedIn: 'root' })
export class UcClient {

	inProgress = new Subject<boolean>();

	private config = inject(Config);
	private client = inject(Client);
	private tokenService = inject(TokenService);

	constructor() {
		this.client.start = () => this.inProgress.next(true);
		this.client.end = () => this.inProgress.next(false);
	}

	authenticateWithPassword(params: OAuthWithPassword): Promise<OAuthResponse> {
		return this.client.authenticateWithPassword(params);
	}

	authenticateWithVirtualMfa(params: OAuthWithVirtualMfa): Promise<OAuthResponse> {
		return this.client.authenticateWithVirtualMfa(params);
	}

	authenticateWithCode(params: OAuthWithCode): Promise<OAuthResponse> {
		return this.client.authenticateWithCode(params);
	}

	authenticateWithMfaSms(params: OAuthWithMfaSms): Promise<OAuthResponse> {
		return this.client.authenticateWithMfaSms(params);
	}

	authenticateWithMfaRecoveryCode(params: OAuthWithMfaRecoveryCode): Promise<OAuthResponse> {
		return this.client.authenticateWithMfaRecoveryCode(params);
	}

	authenticateWithMfaDeviceSetup(params: OAuthWithMfaDeviceSetup): Promise<OAuthResponse> {
		return this.client.authenticateWithMfaDeviceSetup(params);
	}

	authenticateWithMfaDevice(params: OAuthWithMfaDevice): Promise<OAuthResponse> {
		return this.client.authenticateWithMfaDevice(params);
	}

	deAuthenticate() {
		this.tokenService.clear();
	}

	getMyAccount(options?: ClientGetOptions): Promise<MyAccount> {
		return this.get(this.buildUrl(['my-account']), options) as Promise<MyAccount>;
	}

	/** Set user MFA Recovery Codes */
	setRecoveryCodes(recoveryCodes: string[]): Promise<void> {
		return this.put(this.buildUrl(['my-account', 'recovery-codes']), recoveryCodes) as Promise<void>;
	}

	/** Set user virtual MFA token secret */
	setVirtualMfaCode(secret: string): Promise<VirtualMfaSecret> {
		return this.put(this.buildUrl(['my-account', 'virtual-mfa-code']), { secret }) as Promise<VirtualMfaSecret>;
	}

	/** Set user MFA Sms Enabled */
	setSmsMfaEnabled(): Promise<void> {
		return this.patch(this.buildUrl(['my-account', 'sms-mfa-enabled']), undefined) as Promise<void>;
	}

	/** issue user MFA SMS Challenge */
	getSmsChallenges(): Promise<SMSChallenge> {
		return this.put(this.buildUrl(['my-account', 'sms-challenges']), undefined, { limitedAccessToken: true }) as Promise<SMSChallenge>;
	}

	/** issue user Setup Device MFA Challenge */
	async setupDeviceMfa(origin: string): Promise<DeviceMfaSetupChallenge> {
		const result = await (this.put(this.buildUrl(['my-account', 'setup-device-mfa']), { origin }, { limitedAccessToken: true }) as Promise<DeviceMfaSetupChallenge>);

		// Convert backend response to DeviceMfaSetupChallenge
		return BackendToDeviceMfaSetupChallenge(result);
	}

	/** issue user Device MFA Challenge */
	async getDeviceMfaChallenge(origin: string): Promise<CredentialRequestOptions> {
		const result = await (this.put(this.buildUrl(['my-account', 'device-mfa-challenge']), { origin }, { limitedAccessToken: true }) as Promise<{ publicKey: PublicKeyCredentialRequestOptions }>);

		// Convert backend response to CredentialRequestOptions
		return BackendToCredentialRequestOptions(result);
	}

	/** issue user Device MFA Challenge */
	completeDeviceMfaSetup(attestationKey: string, deviceName: string): Promise<void> {
		return this.put(this.buildUrl(['my-account', 'complete-device-mfa-setup']), { attestationKey, deviceName }, { limitedAccessToken: true }) as Promise<void>;
	}

	updateMyPassword(passwordDetails: UpdatePasswordDetails, options?: ClientPatchOptions): Promise<MyAccount> {
		return this.patch(this.buildUrl(['my-account']), passwordDetails, options) as Promise<MyAccount>;
	}

	getMe(options?: ClientGetOptions): Promise<UserInfo> {
		return this.get(this.buildUrl(['me']), options) as Promise<UserInfo>;
	}

	getSettings(options?: ClientGetOptions): Promise<TenantSettings> {
		return this.get(this.buildUrl(['settings']), { ...options, anonymous: true }) as Promise<TenantSettings>;
	}

	getFileTypes(options?: ClientGetOptions): Promise<FileType[]> {
		return this.get(this.buildUrl(['file-types']), options) as Promise<FileType[]>;
	}

	getProjects(options?: ClientGetOptions): Promise<UcProjectInfo[]> {
		return this.get(this.buildUrl(['projects']), options) as Promise<UcProjectInfo[]>;
	}

	addProject(name: string, options?: ClientPostOptions): Promise<UcProjectInfo> {
		return this.post(this.buildUrl(['projects']), { ...options, body: { name } }) as Promise<UcProjectInfo>;
	}

	getLanguages(options?: ClientGetOptions): Promise<Language[]> {
		return this.get(this.buildUrl(['languages']), options) as Promise<Language[]>;
	}

	updateSettings(settings: TenantSettings, options?: ClientPutOptions): Promise<TenantSettings> {
		return this.put(this.buildUrl(['settings']), settings, options) as Promise<TenantSettings>;
	}

	getClaims(q?: string, options?: ClientGetOptions): Promise<string[]> {
		return this.get(this.buildUrl(['claims']), { ...options, params: mergeParams(options?.params, { q }) }) as Promise<string[]>;
	}

	getAvailableIntegrations(q?: string, options?: ClientGetOptions): Promise<IntegrationProviderInfo[]> {
		const params = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.get(this.buildUrl(['available-integrations']), { ...options, params }) as Promise<IntegrationProviderInfo[]>;
	}

	getAvailableIntegration(id: string, options?: ClientGetOptions): Promise<IntegrationProvider> {
		return this.get(this.buildUrl(['available-integrations', id]), options) as Promise<IntegrationProvider>;
	}

	testAvailableIntegration(id: string, data: any, options?: ClientPostOptions): Promise<boolean> {
		return this.post(this.buildUrl(['available-integrations', id, 'connection-test']), { ...options, body: data }) as Promise<boolean>;
	}

	getCompanies(q?: string, options?: ClientGetOptions): Promise<Company[]> {
		const params = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.get(this.buildUrl(['resources', 'companies']), { ...options, params }) as Promise<Company[]>;
	}

	getCompany(id: string): Promise<Company> {
		return this.get(this.buildUrl(['resources', 'companies', id])) as Promise<Company>;
	}

	async getRevisionHeader(url: string) {
		const headers = await this.client.head(url, { skipExecutionEmit: true });

		return getEtag(headers)?.etag;
	}

	/**
     * SDK Extensions
     */

	get(url: string, options?: ClientGetOptions): Promise<any> {
		return this.client.get(url, options);
	}

	put(url: string, body: any, options?: ClientPutOptions): Promise<any> {
		return this.client.put(url, body, options);
	}

	post(url: string, options?: ClientPostOptions): Promise<any> {
		return this.client.post(url, options);
	}

	patch(url: string, body: any, options?: ClientPatchOptions): Promise<any> {
		return this.client.patch(url, body, options);
	}

	delete(url: string, options?: ClientDeleteOptions): Promise<any> {
		return this.client.delete(url, options);
	}

	head(url: string, options?: ClientHeadOptions): Promise<Headers> {
		return this.client.head(url, options);
	}

	buildUrl(parts: any[]): string {

		parts = parts.map(encodeURIComponent);
		parts.unshift(this.config.baseUrl, 'api');

		return parts.join('/');
	}

}
