<div class="uf-app-bar">
	<button (click)="runtime.close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>Unable to Delete Asset</h3>
</div>
<uf-panel class="grow">
	<div class="uf-grid pad-sides">
		<h5 class="col-12">We're unable to delete this asset as it's currently in use in the following places:</h5>
		@for (project of content; track project; let i = $index) {
			<div class="col-12">
				<h4 style="margin-top: 0">Project {{ project.name }}</h4>
				<uf-data-display-list [items]="project.data" class="col-10 row-gap-sm" />
			</div>
		}
	</div>
</uf-panel>
