"use strict";

exports.DayOfYearParser = void 0;
var _constants = require("../constants.cjs");
var _Parser = require("../Parser.cjs");
var _utils = require("../utils.cjs");
class DayOfYearParser extends _Parser.Parser {
  priority = 90;
  subpriority = 1;
  parse(dateString, token, match) {
    switch (token) {
      case "D":
      case "DD":
        return (0, _utils.parseNumericPattern)(_constants.numericPatterns.dayOfYear, dateString);
      case "Do":
        return match.ordinalNumber(dateString, {
          unit: "date"
        });
      default:
        return (0, _utils.parseNDigits)(token.length, dateString);
    }
  }
  validate(date, value) {
    const year = date.getFullYear();
    const isLeapYear = (0, _utils.isLeapYearIndex)(year);
    if (isLeapYear) {
      return value >= 1 && value <= 366;
    } else {
      return value >= 1 && value <= 365;
    }
  }
  set(date, _flags, value) {
    date.setMonth(0, value);
    date.setHours(0, 0, 0, 0);
    return date;
  }
  incompatibleTokens = ["Y", "R", "q", "Q", "M", "L", "w", "I", "d", "E", "i", "e", "c", "t", "T"];
}
exports.DayOfYearParser = DayOfYearParser;