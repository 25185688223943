import { AfterViewInit, Component } from '@angular/core';
import { Modal, ModalRuntime } from '@unifii/library/common';

export interface ModalSearchData {
	type: string;
	title: string;
	multiSelect?: boolean;
	minQuantitySelected?: number;
}

@Component({
	template: '',
})
export abstract class ModalSearchAbstractComponent implements Modal<ModalSearchData, any[]>, AfterViewInit {

	query: string | undefined | null;
	items: any[] = [];
	ignoreList: any = [];
	busy = false;
	offset = 0;
	limit = 30;
	limitReached = false;
	selectAll = false;
	error: string | null;
	abstract data: ModalSearchData;
	abstract runtime: ModalRuntime<ModalSearchData, any[]>;

	protected selectedItems: any = {};

	abstract search(query?: string, offset?: number): Promise<any[]>;

	ngAfterViewInit() {
		this.filter();
	}

	filter(query?: string | null) {
		this.query = query;

		if (query?.length) {
			this.offset = 0;
			this.items = [];
		}

		void this.getListItems();
	}

	select(i: number) {
		this.error = null;
		const item = this.items[i];

		if (!this.data.multiSelect) {
			this.items.forEach((n) => { n.selected = false; });
			this.selectedItems = {};
		}

		item.selected = !item.selected;

		if (item.selected) {
			this.selectedItems[item.id] = item;

			return;
		}

		delete this.selectedItems[item.id];
	}

	updateAll(v: boolean) {

		this.selectAll = v;
		this.error = null;

		for (const item of this.items) {
			item.selected = this.selectAll;
		}

		if (this.selectAll) {
			this.selectedItems = this.items.reduce((result, item) => {
				result[item.id] = item;

				return result;
			}, {});

			return;
		}

		this.selectedItems = {};
	}

	save() {
		if (Object.keys(this.selectedItems).length < (this.data.minQuantitySelected ?? 0)) {
			this.error = `Please select at least ${this.data.minQuantitySelected}`;

			return;
		}
		const result = Object.keys(this.selectedItems).map((key) => this.selectedItems[key]);

		this.runtime.close(result);
	}

	close() {
		this.runtime.close();
	}

	onScrolledToEnd() {
		if (this.busy || this.limitReached) {
			return;
		}

		this.offset += this.limit;
		void this.getListItems();
	}

	private async getListItems() {

		this.busy = true;
		let items: any[];

		try {
			items = await this.search(this.query ?? undefined, this.offset);
		} finally {
			this.busy = false;
		}

		this.busy = false;

		if (this.offset === 0) {
			this.items = [];
		}

		this.limitReached = !items.length;
		this.items = this.items.concat(this.removeIgnoredItems(items));
	}

	private removeIgnoredItems(items: any[] = []) {

		return items.reduce((result, item) => {
			if (!this.ignoreList.includes(item.id)) {
				item.selected = this.selectedItems[item.id] != null;
				result.push(item);
			}

			return result;
		}, []);
	}

}
