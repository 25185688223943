<uf-expander [class.uc-group--draggable--no-shrink]="canDragField && !isSelected" [class.uc-group--draggable--no-shrink--accent]="canDragField && isSelected" class="uc-group">
	<div [class.accent]="isSelected" (click)="selectField($event)" dragHandle expanderHeader class="uf-app-bar flat">
		<uf-icon name="imageList" />
		<div class="title primary">
			<span>Image List</span>
			@if (configuredField.label) {
				<span> | {{ configuredField.label }}</span>
			}
		</div>
		@if (!isValid) {
			<uf-icon name="warning" class="icon-warning" />
		}
		@if (configuredField.help) {
			<uf-help [content]="configuredField.help" class="uf-action tertiary" />
		}
		@if (canRemoveField) {
			<a (click)="removeField($event)" title="Delete" class="uf-action tertiary">
				<uf-icon name="delete" />
			</a>
		}
	</div>
	<div [class.content--accent]="isSelected" expanderBody class="uc-group-content">
		@if (content) {
			<uc-drag-list [canReorder]="canEditField" [items]="content" [parent]="content" (moved)="moved()" canDrop="false">
				<ul class="uc-list--gap">
					@for (item of content; track item; let i = $index) {
						<li [dragDisabled]="disabled" [class.uc-list-item--small--no-shrink--bordered]="disabled" [class.uc-list-item--draggable--small--no-shrink]="!disabled" dragItem class="row center-all">
							<div class="shrink">
								<img [src]="item.url + '?w=80&h=80&mode=contain'" alt="item.title" class="thumb" />
							</div>
							<div class="list-content">
								<div class="list-context">
									<a [routerLink]="['/assets', 'media', item.id]" target="_blank" title="Open" class="uf-action tertiary">
										<uf-icon name="open" class="" />
									</a>
									@if (canEditField && canCrop) {
										<button (click)="crop(item, i)" type="button" title="Crop" class="uf-action tertiary">
											<uf-icon name="crop" />
										</button>
									}
									@if (canEditField) {
										<button (click)="delete(i)" title="Delete" type="button" class="uf-action tertiary">
											<uf-icon name="delete" />
										</button>
									}
								</div>
								<div>{{ item.consoleName }}</div>
								<div class="fieldset-item--x-small">
									<span>Description: </span>
									<span>{{ item.description }}</span>
								</div>
								<div class="fieldset-item--x-small">
									<span>Original size: </span>
									<span>{{ item.width }}x{{ item.height }}</span>
								</div>
							</div>
						</li>
					}
				</ul>
			</uc-drag-list>
		}
		<div class="row padded">
			@if (canEditField) {
				<button (click)="add()" type="button" class="uf-button x-small right">Add Image</button>
			}
		</div>
	</div>
</uf-expander>
<uf-error [control]="control" />
