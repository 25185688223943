<uf-panel class="container">
	<div class="header-pane uf-app-bar transparent">
		<uf-breadcrumbs [breadcrumbs]="[{ urlSegments: ['/style-guide'], name: 'Style Guide' }, { name: 'Pages Inside Tabs' }]" class="large" />
	</div>
	<div class="content-pane">
		<uf-tabs>
			<uf-tab label="Form" path="" />
			<uf-tab label="Builder" path="builder" />
			<uf-tab label="Table" path="table" />
		</uf-tabs>
	</div>
</uf-panel>
