<uf-panel class="container">
	@for (group of groups; track group) {
		@if (group.title) {
			<uf-expander class="uc-group gap--bottom-small">
				<div expanderHeader class="uf-app-bar flat">
					<span class="title primary">{{ group.title }}</span>
				</div>
				<div expanderBody class="uc-group-content padded--small">
					<ul class="uc-list">
						<!-- TODO replace with library list -->
						@for (field of group.entries; track field) {
							@if (!field.disabled || config.debug) {
								<li [dragItem]="field" [dragDisabled]="field.disabled" class="uc-list-item--draggable--small row center-all">
									<uf-icon [name]="field.icon" />
									<span class="grow gap-left">{{ field.label }}</span>
									@if (field.customId) {
										<a (click)="deleteCustomField(field)" class="uf-action tertiary" title="Delete">
											<uf-icon name="delete" />
										</a>
									}
								</li>
							}
						}
					</ul>
				</div>
			</uf-expander>
		}
		@if (!group.title) {
			<div>
				<ul class="uc-list drag-blocks-no-heading">
					@for (field of group.entries; track field) {
						@if (!field.disabled || config.debug) {
							<li [dragItem]="field" [dragDisabled]="field.disabled" class="uc-list-item--draggable--small row center-all">
								<uf-icon [name]="field.icon" />
								<span class="grow gap-left">{{ field.label }}</span>
								@if (field.customId) {
									<a (click)="deleteCustomField(field)" class="uf-action tertiary" title="Delete">
										<uf-icon name="delete" />
									</a>
								}
							</li>
						}
					}
				</ul>
			</div>
		}
	}
</uf-panel>
