import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { PasswordChangeComponentNavigationState, isPasswordChangeComponentNavigationState } from 'pages/user-access/password-change.component';

/**
 * Ensure state set for password change Route
 */
export const passwordChangeGuard: CanActivateFn = () => {

	const router = inject(Router);
	const state = router.getCurrentNavigation()?.extras.state as PasswordChangeComponentNavigationState | undefined;

	if (!isPasswordChangeComponentNavigationState(state)) {
		void router.navigate(['/']);

		return false;
	}

	return true;
};
