import { ChangeDetectorRef, Injectable, inject } from '@angular/core';
import { ClipboardService, ControlAccessor, DataPropertyInfoService, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup, fieldIterator } from '@unifii/library/common';
import { WorkflowStartState } from '@unifii/library/smart-forms';
import { CompoundType, Field, FieldType, Option, ValidatorType, generateUUID, isStringNotEmpty } from '@unifii/sdk';

import { FormDefinitionInfo, SystemRole, UcField, UcProject } from 'client';
import { HeaderConfig } from 'components/common/builder-header/builder-header.component';
import { EditMode } from 'components/common/edit-data';
import { ItemPickerGroup, ItemPickerInfo } from 'components/common/item-picker/item-picker.component';
import { SaveAndApprove, SaveAndClose, SaveAndNew, SaveAndNext } from 'components/common/save-options/save-options.component';
import { DragListEvent } from 'components/dragdrop/drag-list.component';
import { ArrayHelper } from 'helpers/array-helper';
import { FieldReferenceHelper, IdentifierFunctions } from 'helpers/helpers';
import { ToDisplayNamePipe } from 'pipes/to-display-name.pipe';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { FormEditorCache } from './form-editor-cache';
import { FORM_EDITOR_CONSTANTS } from './form-editor-constants';
import { FieldControlKeys } from './form-editor-control-keys';
import { FormEditorFieldScopeManager } from './form-editor-field-scope-manager';
import { FormEditorFormCtrl } from './form-editor-form-ctrl';
import { FormEditorFunctions } from './form-editor-functions';
import { FormEditorDefinition, FormEditorField } from './form-editor-model';
import { FormEditorStatus } from './form-editor-status';
import { SaveAsCustomModalComponent } from './save-as-custom-modal.component';

@Injectable()
export class FormEditorService {

	private project = inject(UcProject);
	private toast = inject(ToastService);
	private cdr = inject(ChangeDetectorRef);
	private fb = inject(FormEditorFormCtrl);
	private cache = inject(FormEditorCache);
	private context = inject(ContextService);
	private status = inject(FormEditorStatus);
	private modalService = inject(ModalService);
	private clipboard = inject(ClipboardService);
	private displayPipe = inject(ToDisplayNamePipe);
	private breadcrumbService = inject(BreadcrumbService);
	private dataPropertyInfoService = inject(DataPropertyInfoService);
	private fieldScopeManager = inject(FormEditorFieldScopeManager);

	async loadItemPickerGroups() {

		const groups: ItemPickerGroup<Field>[] = JSON.parse(JSON.stringify(FORM_EDITOR_CONSTANTS.PICKER_GROUPS));

		for (const group of groups) {
			for (const item of group.items) {
				item.marker = FORM_EDITOR_CONSTANTS.ITEM_PICKER_INFO_MARKER;
			}
		}

		const fields = await this.project.getFieldTemplates();

		this.status.itemPickerGroups = [{
			title: 'Custom',
			items: fields.map((field) => {
				delete (field as any).uuid; // FormEditorField
				delete (field as any).scopeUuid; // FormEditorField

				return {
					id: `${field.id}`,
					label: field.label ?? '',
					icon: FieldReferenceHelper.getFieldReference(field, CompoundType.Form).icon,
					deletable: true,
					data: field as Field,
					marker: FORM_EDITOR_CONSTANTS.ITEM_PICKER_INFO_MARKER,
				};
			}),
		}, ...groups];
	}

	async deleteTemplateField(item: ItemPickerInfo<Field>) {

		// Guard for ItemPickerInfo with TemplateField data
		if (item.marker !== FORM_EDITOR_CONSTANTS.ITEM_PICKER_INFO_MARKER || item.data == null) {
			return;
		}

		await this.project.deleteFieldTemplate(parseInt(item.id));
		await this.loadItemPickerGroups();
		this.toast.success(`Custom field ${item.label} deleted`);
	}

	async saveTemplateField(formEditorField: FormEditorField) {
		const name = await this.modalService.openMedium(SaveAsCustomModalComponent, formEditorField.label);

		if (!name) {
			return;
		}

		const clean = FormEditorFunctions.getFieldCleanConfiguration(formEditorField);
		const field = FormEditorFunctions.mapFormEditorFieldToField(clean);

		delete field.identifier;
		field.label = name;

		await this.project.saveFieldTemplate(field);
		await this.loadItemPickerGroups();
		this.toast.success(`Custom field ${name} saved`);
	}

	async copyFields(fields: FormEditorField[]) {
		const copies = fields.map(FormEditorFunctions.getFieldCleanConfiguration).map(FormEditorFunctions.mapFormEditorFieldToField);

		await this.clipboard.setText(JSON.stringify(copies));
	}

	async pasteFields(targetControl: UfControlGroup) {

		try {
			const text = await this.clipboard.getText();

			if (!text) {
				return;
			}

			const fields = (JSON.parse(text) ?? []) as UcField[];

			if (!fields.length) {
				return;
			}

			const targetFields = targetControl.get(FieldControlKeys.Fields) as UfControlArray | null;

			if (targetFields == null) {
				throw new Error('Target has no fields');
			}

			// this.internalPaste(fields, targetControl);
			await this.internalPasteRebuild(fields, targetControl);
			this.status.edited = true;

		} catch (e) {
			this.toast.warning('Paste fields failed');
		}
	}

	canDrop = (element: any, target: any): Promise<boolean> => {

		// let innerDepth = 0;
		// let fieldType: FieldType;

		if (element instanceof UfControlGroup) {

			if (element.get(FieldControlKeys.ScopeUuid) == null) {
				// Another builder control (Option, Variation, Validator, ecc)
				console.warn('FormEditorService.canDrop - Drop element not a field control');

				return Promise.resolve(false);
			}

			// innerDepth = FormEditorFunctions.getFieldControlInnerDept(fieldControl);
			// fieldType = fieldControl.get(FieldControlKeys.Type)?.value as FieldType;

		} else {
			const pickerInfo = element as ItemPickerInfo<Field>;

			if (pickerInfo.marker !== FORM_EDITOR_CONSTANTS.ITEM_PICKER_INFO_MARKER) {
				console.warn('FormEditorService.canDrop - Drop element not a field picker reference');

				return Promise.resolve(false);
			}

			// innerDepth = 1;
			// fieldType = pickerInfo.data?.type ?? pickerInfo.id as FieldType;
		}

		if (!(target instanceof UfControlArray)) {
			return Promise.resolve(false);
		}

		return Promise.resolve(true);
	};

	/** Strict input types are guaranteed by caDrop function checks */
	addConverter = async(element: ItemPickerInfo<Field> | UfControlGroup, target: UfControlArray): Promise<UfControlGroup | null> => {

		if (element instanceof UfControlGroup) {
			// Existing field control, return it
			return element;
		}

		// Can be a FormEditorField or the Definition
		let parentField: FormEditorField | undefined;

		if (target.parent instanceof UfControlGroup && target.parent.get(FieldControlKeys.Uuid)?.value != null) {
			parentField = target.parent.getRawValue() as FormEditorField;
		}

		const scopeUuid = FormEditorFunctions.determineFieldScopeUuid(parentField?.type, parentField?.scopeUuid, parentField?.uuid);

		let definitionField: UcField;

		if (element.data) {
			// Drag of a custom field
			definitionField = JSON.parse(JSON.stringify(element.data));
			const customMeta = FormEditorFunctions.fieldMetadata('', scopeUuid, definitionField.type, this.context, parentField?.type);

			if (!customMeta.label) {
				// All custom fields have label value from the save dialog, delete label when not allowed
				delete definitionField.label;
			}
			delete definitionField.id;
		} else {
			// Drag of a field type
			const fieldType = element.id as FieldType;
			const temporaryMeta = FormEditorFunctions.fieldMetadata('', scopeUuid, fieldType, this.context, parentField?.type);

			definitionField = FormEditorFunctions.getFieldWithDefaults(temporaryMeta, element.label);
		}

		const editorField = await FormEditorFunctions.mapFieldToFormEditorField(definitionField, scopeUuid, this.cache, this.status.definition.bucket, this.dataPropertyInfoService);
		const control = this.fb.buildFieldControl(editorField, parentField, parentField?.path);

		const meta = FormEditorFunctions.fieldMetadata(editorField.uuid, editorField.scopeUuid, editorField.type, this.context, parentField?.type);

		if (meta.identifier && !editorField.identifier) {
			control.get(FieldControlKeys.Identifier)?.setValue(this.generateFieldIdentifier(editorField, scopeUuid));
		}

		target.updateValueAndValidity();

		return control;
	};

	generateFieldIdentifier(field: FormEditorField, scopeUuid: string): string {

		if (field.identifier) {
			return field.identifier;
		}

		const otherFieldsIdentifiers = Array.from(this.fieldScopeManager.getScope(scopeUuid).values()).map((i) => i.identifier as string);
		const source = field.shortLabel ?? field.label ?? field.type;

		return FormEditorFunctions.generateSafeIdentifier(IdentifierFunctions.camelize(source), otherFieldsIdentifiers);
	}

	fieldAdded(event: DragListEvent<UfControlGroup>) {
		this.status.selected = event.item;
		this.updateFieldPath(event.item);
		this.status.root.updateDependencies();
	}

	/**
     * When a field is moved, it's relative parent change and this under specific conditions may affects:
     * 1) its metadata when a Choice/MultiChoice is placed/removed under a Survey
     *      => metadata must be recomputed and field control and subfields controls rebuilt and replaced in the control tree
     * 2) its scope when the field is placed/removed under a Repeat
     *      => fieldScopeManager notified of the scope change
     */
	fieldMoved(event: DragListEvent<UfControlGroup>) {
		// Guard moved within the same fields list
		if (event.source === event.target) {
			return;
		}

		const control = event.item;
		const field = control.getRawValue() as FormEditorField;
		const sourceControl = event.source?.parent?.parent as UfControlGroup;
		const sourceField = sourceControl.getRawValue() as FormEditorField; // can be Definition
		const targetControl = event.target.parent.parent as UfControlGroup;
		const targetField = targetControl.getRawValue() as FormEditorField; // can be Definition

		let nextScopeUuid: string;

		if (!targetField.uuid) {
			// Field moved under form root, apply root scopeUuid
			nextScopeUuid = FORM_EDITOR_CONSTANTS.DEFINITION_SCOPE_UUID;
		} else {
			nextScopeUuid = targetField.type === FieldType.Repeat ? targetField.uuid : targetField.scopeUuid;
		}

		const changedScope = field.scopeUuid !== nextScopeUuid;
		const changedMeta = [FieldType.Choice, FieldType.MultiChoice].includes(field.type) &&
            (sourceField.type !== targetField.type && (sourceField.type === FieldType.Survey || targetField.type === FieldType.Survey));

		if (changedMeta) {

			const container = event.target?.parent as UfControlArray;
			const position = container.controls.indexOf(control);

			const selected = this.status.selected === control;

			if (selected) {
				this.status.selected = null;
				this.cdr.detectChanges();
			}

			this.fieldScopeManager.onRemovedField(control);
			this.fb.onFieldRemoved(control);

			field.uuid = generateUUID();
			field.scopeUuid = nextScopeUuid;
			const renewedControl = this.fb.buildFieldControl(field, targetField.uuid != null ? targetField : undefined, targetField.path);

			container.setControl(position, renewedControl);

			if (selected) {
				this.status.selected = renewedControl;
			}

			this.status.root.updateDependencies();

			return;
		}

		if (changedScope) {
			this.fieldScopeManager.onMovedField(nextScopeUuid, control);
			this.updateFieldPath(control);
			this.status.root.updateDependencies();
		}

		control.updateValueAndValidity({ emitEvent: false });
	}

	fieldRemoved(control: UfControlGroup) {
		if (control === this.status.selected) {
			this.status.selected = null;
		}
		this.fieldScopeManager.onRemovedField(control);
		this.fb.onFieldRemoved(control);

		if (control.get(FieldControlKeys.Type)?.value === FieldType.Section) {
			this.refreshTransitionStatuses();
		}

		this.status.root.updateDependencies();
	}

	fieldValidatorOptions(type: FieldType): Option[] {

		const matrix = new Map<ValidatorType, FieldType[]>();
		const result: Option[] = [];

		matrix.set(ValidatorType.Pattern, [FieldType.Text, FieldType.Phone, FieldType.Email]);
		matrix.set(ValidatorType.MinLength, [FieldType.Repeat, FieldType.Text, FieldType.MultiText, FieldType.MultiChoice, FieldType.Phone, FieldType.Website, FieldType.ImageList, FieldType.FileList]);
		matrix.set(ValidatorType.Min, [FieldType.Number]);
		matrix.set(ValidatorType.Max, [FieldType.Number]);
		matrix.set(ValidatorType.Expression, [FieldType.Text, FieldType.MultiText, FieldType.Cost, FieldType.Date, FieldType.Time, FieldType.DateTime, FieldType.Number, FieldType.Phone, FieldType.Email, FieldType.Website, FieldType.Bool, FieldType.Choice, FieldType.MultiChoice, FieldType.ImageList, FieldType.SoundList, FieldType.VideoList, FieldType.FileList, FieldType.GeoLocation, FieldType.Content, FieldType.Address, FieldType.Signature, FieldType.Separator, FieldType.Group, FieldType.Stepper, FieldType.Repeat, FieldType.Section, FieldType.ActionGroup, FieldType.Lookup, FieldType.Link, FieldType.LinkList, FieldType.DefinitionLink]);
		matrix.set(ValidatorType.ItemExpression, [FieldType.Repeat]);
		matrix.set(ValidatorType.LettersOnly, [FieldType.Text]);
		matrix.set(ValidatorType.Alphanumeric, [FieldType.Text]);
		matrix.set(ValidatorType.BeforeNow, [FieldType.Date, FieldType.DateTime, FieldType.ZonedDateTime]);
		matrix.set(ValidatorType.AfterNow, [FieldType.Date, FieldType.DateTime, FieldType.ZonedDateTime]);
		matrix.set(ValidatorType.Email, [FieldType.Email]);
		matrix.set(ValidatorType.Website, [FieldType.Website]);

		for (const validatorType of matrix.keys()) {
			if ((matrix.get(validatorType) as FieldType[]).includes(type)) {
				result.push({
					identifier: validatorType,
					name: FormEditorFunctions.validatorsInfoByType(validatorType, this.displayPipe).typeLabel ?? validatorType,
				});
			}
		}

		return result;
	}

	refreshTransitionStatuses() {

		this.status.statuses.clear();
		this.status.statuses.add(WorkflowStartState);

		for (const { field } of fieldIterator(this.status.definition.fields)) {
			if (field.transitions) {
				for (const transition of field.transitions) {
					if (transition.source) {
						this.status.statuses.add(transition.source);
					}
					if (transition.target) {
						this.status.statuses.add(transition.target);
					}
				}
			}
		}

	}

	refreshTags() {

		this.status.tags.clear();

		if (this.status.definition.tags) {
			for (const tag of this.status.definition.tags) {
				this.status.tags.add(tag);
			}
		}

		for (const { field } of fieldIterator(this.status.definition.fields)) {
			for (const tag of field.tags ?? []) {
				this.status.tags.add(tag);
			}
		}

		for (const tag of this.context.project?.tags ?? []) {
			if (!this.status.tags.has(tag)) {
				this.status.tags.add(tag);
			}
		}

	}

	applyDefinition(definition: FormEditorDefinition) {

		// Save existing selected path
		const selectedPath = new ControlAccessor(this.status.root).getPath(this.status.selected ?? undefined);

		// Reset fields scopes
		this.fieldScopeManager.reset();
		// Unsubscribe
		this.status.valueChangesSub?.unsubscribe();
		// Reset fieldUuid map
		this.status.fieldByUuid.clear();
		this.status.fieldsIdentifier = new UfControl();

		// Update Status flags and values
		this.status.hasBeenPublished = definition.lastPublishedAt != null;
		this.status.identifiersMaxLength = FormEditorFunctions.detectIdentifiersMaxLength(definition);

		this.cache.bucketIdentifier = definition.bucket;
		// Build root control
		this.status.root = this.fb.buildRoot(definition);

		// Register root control changes
		this.status.valueChangesSub = this.status.root.valueChanges.subscribe(() => {
			this.status.edited = true;
		});

		// Update headerService
		this.status.headerService.buildConfig(this.getHeaderServiceConfig(definition));

		// Based on status.root to retrieve Definition
		this.refreshTags();
		this.refreshTransitionStatuses();

		// Restore selected path or root (selected = null => root)
		let control: UfControlGroup | null = null;

		if (selectedPath) {
			control = new ControlAccessor(this.status.root).get(selectedPath)[0] as UfControlGroup ?? null;
		}

		this.status.selected = control;
	}

	async queryProjectRoles(q?: string): Promise<string[]> {
		const roles = (await this.cache.getRoles()).map((r) => r.name);

		return ArrayHelper.filterList(roles, q);
	}

	async approve(id: string): Promise<FormDefinitionInfo | undefined> {
		const forms = await this.project.approveForms([id]);

		return forms[0];
	}

	private getHeaderServiceConfig(definition: FormEditorDefinition): HeaderConfig {
		const { lastModifiedAt, lastModifiedBy, consoleName, lastPublishedAt, lastPublishedBy } = definition;
		const title = isStringNotEmpty(consoleName) ? consoleName : 'New form';

		const canApprove = this.status.editMode === EditMode.Existing && this.context.checkRoles(SystemRole.Publisher);
		const saveOptions = canApprove ? [SaveAndApprove] : [];

		if (this.status.editMode === EditMode.Existing) {
			saveOptions.push(...[SaveAndClose, SaveAndNext, SaveAndNew]);
		} else {
			saveOptions.push(...[SaveAndClose, SaveAndNew]);
		}

		return {
			title,
			lastModifiedAt,
			lastModifiedBy,
			lastPublishedAt,
			lastPublishedBy,
			saveOptions,
			cancelRoute: ['../'],
			publishState: definition.publishState,
			breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.status.route, [title]),
		};
	}

	private async internalPasteRebuild(fields: UcField[], targetControl: UfControlGroup) {

		const findField = (entries: FormEditorField[], uuid: string): FormEditorField | null => {
			for (const entry of entries) {
				if (entry.uuid === uuid) {
					return entry;
				}
				const match = findField(entry.fields ?? [], uuid);

				if (match) {
					return match;
				}
			}

			return null;
		};

		const definition = targetControl.root.getRawValue() as FormEditorDefinition;
		const targetUuid = targetControl.get(FieldControlKeys.Uuid)?.value as string | null;

		let targetField: FormEditorField | null = null;
		let targetArray: FormEditorField[] | null = null;

		if (targetUuid) {
			const field = findField(definition.fields, targetUuid);

			if (field) {
				targetField = field;
				targetArray = targetField.fields;
			}
		} else {
			targetArray = definition.fields;
		}

		if (!targetArray) {
			return;
		}

		const scopeUuid = FormEditorFunctions.determineFieldScopeUuid(targetField?.type, targetField?.scopeUuid, targetField?.uuid);

		const editorFields = await Promise.all(fields.map((field) => FormEditorFunctions.mapFieldToFormEditorField(
			field, scopeUuid, this.cache, this.status.definition.bucket, this.dataPropertyInfoService,
		)));

		targetArray.push(...editorFields);

		this.applyDefinition(definition);
	}

	private updateFieldPath(control: UfControlGroup ) {
		const pathControl = control.get(FieldControlKeys.Path) as UfControl | undefined;

		if (!pathControl) {
			return;
		}

		const field = control.getRawValue() as FormEditorField;

		pathControl.setValue( FormEditorFunctions.formEditorFieldPath(field, this.status));
	}

	/*
    private async internalPaste(fields: Field[], targetControl: UfControlGroup) {

        const targetArray = targetControl.get(FieldControlKeys.Fields) as UfControlArray;
        const targetField = targetControl.value as FormEditorField;

        const scopeUuid = FormEditorFunctions.determineFieldScopeUuid(targetField.type, targetField.scopeUuid, targetField.uuid);

        const editorFields = await Promise.all(fields.map(field => FormEditorFunctions.mapFieldToFormEditorField(
            field, scopeUuid, this.cache, this.status.definition.bucket, this.schemaFieldService
        )));

        const fieldControls = editorFields.map(field => this.fb.buildFieldControl(field, targetField));

        for (const fieldControl of fieldControls) {
            targetArray.push(fieldControl);
        }
        const updateFields = (entries: UfControlGroup[]) => {
            for (const field of entries) {
                field.updateValueAndValidity();
                console.log('Updated field', field.value.uuid);
                const children = field.get(FieldControlKeys.Fields) as UfControlArray;
                if (children) {
                    updateFields(children.controls as UfControlGroup[]);
                }
            }
        };

        updateFields(targetArray.controls as UfControlGroup[]);
    }
    */

}
