<ng-template #headerButtons>
	@if (smtpEnabled) {
		<button (click)="sendTest()" type="button" class="uf-button secondary">Test SMTP</button>
	}
</ng-template>

<uf-panel class="content-pane content scrollable">
	@if (!loading) {
		<div class="uf-container-lg">
			<div class="uf-grid row-gap-lg pad-top">
				@if (error?.message) {
					<uf-blockquote [content]="error?.message" icon="errorSolid" class="col-12 error" />
				}
				<div [formGroup]="generalForm" class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Providers</div>
					</div>
					<div class="content uf-grid pad">
						<uf-autocomplete [formControlName]="systemControlKeys.Integration" [options]="filteredSmsIntegrations" (searchChange)="filterSmsIntegrations($event)" label="Sms Provider" nameProperty="smsProviderName" class="col-12" />
					</div>
				</div>
				<div [formGroup]="smtpForm" class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">SMTP Set Up</div>
						<button (click)="smtpEnabled ? deleteSmtp() : enableSmtp()" type="button" class="uf-button secondary right">{{ smtpEnabled ? "Delete" : "Enable" }}</button>
					</div>
					@if (smtpEnabled) {
						<div class="content uf-grid pad">
							<uf-text [formControlName]="smtpFormControlKeys.SmtpHost" label="Host" class="col-9" />
							<uf-number [formControlName]="smtpFormControlKeys.SmtpPort" label="Port" class="col-3" />
							<uf-text [formControlName]="smtpFormControlKeys.SmtpUsername" label="Username" class="col-6" />
							<uc-secret-input [formControlName]="smtpFormControlKeys.SmtpPassword" label="Password" class="col-6" />
							<uf-text [formControlName]="smtpFormControlKeys.SmtpSystemName" label="System Name" class="col-12" />
							<uf-email [formControlName]="smtpFormControlKeys.SmtpSystemEmail" label="System Email" class="col-12" />
						</div>
					}
				</div>
			</div>
		</div>
	}
</uf-panel>
