import { Injectable, inject } from '@angular/core';
import { HeaderKeys, ProjectContentOptions, ProjectContentOptionsInterface } from '@unifii/sdk';

import { UcStructure } from './content-models';
import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class StructureService {

	private client = inject(UcClient);
	private options = inject(ProjectContentOptions) as ProjectContentOptionsInterface;

	get(): Promise<UcStructure> {
		return this.client.get(this.url()) as Promise<UcStructure>;
	}

	save(data: UcStructure, approve?: boolean): Promise<UcStructure> {

		const headers = new Headers();

		if (data.revision) {
			headers.set(HeaderKeys.IfMatch, `"${data.revision}"`);
		}

		const params = approve ? { approve: true } : undefined;

		return this.client.put(this.url(), data, { params, headers }) as Promise<UcStructure>;
	}

	getRevision() {
		return this.client.getRevisionHeader(this.url());
	}

	approve(): Promise<void> {
		return this.client.post(this.url('approved'), {}) as Promise<void>;
	}

	unapprove(): Promise<void> {
		return this.client.post(this.url('unapproved'), {}) as Promise<void>;
	}

	private url(...extra: string[]): string {
		if (!this.options.projectId) {
			throw new Error('Project id set');
		}
		const urlParts = ['projects', this.options.projectId, 'structure'].concat(extra);

		return this.client.buildUrl(urlParts);
	}

}
