@if (link) {
	<a [routerLink]="link" routerLinkActive="router-link-active">
		<ng-template *ngTemplateOutlet="menuItemContainer" />
	</a>
}
@if (!link) {
	<a>
		<ng-template *ngTemplateOutlet="menuItemContainer" />
	</a>
}

<ng-template #menuItemContainer>
	<div class="col center-all">
		@if (icon) {
			<img src="/assets/svg/new-icons/{{ icon }}.svg" />
		}
		@if (showLabel) {
			<div class="label">{{ label }}</div>
		}
		<ng-content />
	</div>
</ng-template>
