<div class="header">
	<button (click)="runtime.close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>{{ edit ? "Edit" : "Add" }} Field Mapping</h3>
</div>

<uf-panel>
	@if (mappingForm) {
		<div class="uf-box gap">
			<div class="uf-app-bar flat accent">
				<div class="title">Provider Conditions</div>
			</div>
			@for (condition of conditions.controls | asUfControlsGroups; track condition; let i = $index) {
				@if (condition) {
					<uc-auth-provider-mapping-unit [form]="condition" [sourceClaims]="sourceClaims" [authProviderId]="authProviderId" [config]="config" [identifier]="i" [authProviderType]="data.authProviderType" (deleteClick)="deleteCondition($event)" />
				}
			}
			<div class="uf-form-actions">
				<button [options]="conditionOptions" (optionSelected)="addCondition($event)" type="button" class="uf-button x-small" listBox nameProperty="name">
					Add
					<uf-icon name="more" class="gap-none" />
				</button>
			</div>
		</div>
	}

	<div class="uf-box gap-top gap">
		<div class="uf-app-bar flat accent">
			<div class="title">Unifii User Actions</div>
		</div>
		<uc-auth-provider-mapping-actions [actions]="actions" [config]="config" [authProviderId]="authProviderId" />
	</div>
</uf-panel>

<div class="uf-form-actions">
	<uf-error [control]="conditions" />
	@if (conditions.valid) {
		<uf-error [control]="actions" />
	}
	<button (click)="runtime.close()" type="button" class="uf-button tertiary right">Cancel</button>
	<button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>
