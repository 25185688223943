import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';

enum ControlKeys {
	Name = 'name',
}

@Component({
	selector: 'uc-save-as-custom-modal',
	templateUrl: './save-as-custom-modal.html',
	standalone: false,
})
export class SaveAsCustomModalComponent implements Modal<string | undefined, string>, OnInit {

	@HostBinding('class.uc-form-card') formCardClass = true;

	runtime = inject<ModalRuntime<string, string>>(ModalRuntime);
	data = inject<string | undefined>(ModalData);

	protected readonly controlKeys = ControlKeys;
	protected form: UfControlGroup;

	private ufb = inject(UfFormBuilder);

	ngOnInit() {
		this.form = this.ufb.group({
			[ControlKeys.Name]: [
				this.data,
				ValidatorFunctions.required('Field is required'),
			],
		});
	}

	protected save() {
		this.form.setSubmitted();

		if (this.form.invalid) {
			return;
		}

		this.runtime.close(this.form.get(ControlKeys.Name)?.value);
	}

}
