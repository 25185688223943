@if (configuredField) {
	<uf-expander [class.uc-group--draggable--no-shrink]="canDragField && !isSelected" [class.uc-group--draggable--no-shrink--accent]="canDragField && isSelected" class="uc-group">
		<div [class.accent]="isSelected" (click)="selectField($event)" dragHandle expanderHeader class="uf-app-bar flat">
			<uf-icon name="collectionLink" />
			<div class="title primary">Collection | {{ collectionLabel }}</div>
			@if (!isValid) {
				<uf-icon name="warning" class="icon-warning" />
			}
			@if (configuredField.help) {
				<uf-help [content]="configuredField.help" class="uf-action tertiary" />
			}
			@if (canRemoveField) {
				<a (click)="removeField($event)" title="Delete" class="uf-action tertiary">
					<uf-icon name="delete" />
				</a>
			}
		</div>
		<div expanderBody class="uc-group-content">
			<!-- to do replace with list from library -->
			@if (content) {
				<uc-drag-list [canReorder]="canEditField" [items]="content" [parent]="content" (moved)="moved()" canDrop="false">
					<ul class="uc-list--gap">
						@for (link of content; track link; let i = $index) {
							<li [class.uc-list-item--small--no-shrink--bordered]="disabled" [class.uc-list-item--draggable--small--no-shrink]="!disabled" [dragDisabled]="disabled" dragItem class="row center-all">
								<div class="list-content">
									<div class="list-context">
										<a [routerLink]="getLink(link)" target="_blank" title="Open" class="uf-action tertiary">
											<uf-icon name="open" />
										</a>
										@if (canEditField) {
											<button (click)="delete(i)" type="button" tabindex="-1" title="Delete" class="uf-action tertiary">
												<uf-icon name="delete" />
											</button>
										}
									</div>
									<div>{{ link._consoleName }}</div>
								</div>
							</li>
						}
					</ul>
				</uc-drag-list>
			}
			@if (searchConfig) {
				<div class="row padded">
					@if (canEditField) {
						<button (click)="add()" type="button" class="uf-button x-small right">{{ searchConfig.title }}</button>
					}
				</div>
			}
		</div>
	</uf-expander>
}
