import { ColumnDescriptorEditorComponent } from './column-descriptor-editor.component';
import { SortPropertyInputComponent } from './sort-property-input.component';
import { TableColumnsComponent } from './table-columns.component';
import { TableConfigurationDetailsComponent } from './table-configuration-details.component';
import { TableConfigurationHiddenFiltersComponent } from './table-configuration-hidden-filters.component';
import { TableConfigurationSortComponent } from './table-configuration-sort.component';

export * from './column-descriptor-editor.component';
export * from './sort-property-input.component';
export * from './table-columns.component';
export * from './table-configuration-details.component';
export * from './table-configuration-hidden-filters.component';
export * from './table-configuration-sort.component';

export const TABLE_CONFIGURATION_COMPONENTS = [
	SortPropertyInputComponent,
	ColumnDescriptorEditorComponent,
	TableColumnsComponent,
	TableConfigurationDetailsComponent,
	TableConfigurationHiddenFiltersComponent,
	TableConfigurationSortComponent,
];
