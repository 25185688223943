import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, ElementRef, InjectionToken, OnInit, RendererFactory2, TemplateRef, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContextProvider, DOMEventHandler, GoogleMapsApiKey, LocationProvider, ModalService, Repository, RuntimeDefinition, RuntimeDefinitionAdapter, ThemeProvider, ThemeService, ToastService, WindowWrapper, fieldIterator, googleLocationProviderFactory } from '@unifii/library/common';
import { DebugValidation, FieldHelperFunctions, FormConfiguration, FormDebugger, FormSettings, amendFormData } from '@unifii/library/smart-forms';
import { InputFormSettings, SubmitArgs } from '@unifii/library/smart-forms/input';
import { Client, ContentClient, Definition, ExternalDataSourcesClient, FieldType, FormData, Interceptor, Option, ProjectContentOptions, ProjectContentOptionsInterface, ProjectInfo, PublishedContent, TenantClient, isStringNotEmpty } from '@unifii/sdk';

import { Config } from 'app-config';
import { SdkInterceptor, UcProject } from 'client';
import { PREVIEW_FORM_STORAGE_KEY, PROJECT_STORAGE_KEY } from 'constant';
import { publishedContentFactory } from 'functions';
import { ContextService } from 'services/context.service';

import { FormDataDisplayModalComponent } from './form-data-display-modal.component';
import { FormFakeUploader } from './form-fake-uploader.service';
import { FormPreviewContentClient } from './form-preview-content.service';

const ProjectInfoInjectionToken = new InjectionToken<ProjectInfo | null>('ProjectInfo');

const projectInfoFactory = (repository: Repository): ProjectInfo | null =>
	repository.load<ProjectInfo>(PROJECT_STORAGE_KEY);

const projectContentOptionsFactory = (projectInfo: ProjectInfo | null): ProjectContentOptionsInterface => ({
	projectId: projectInfo?.id ?? '',
	preview: false,
});

const createThemeProvider = (projectInfo: ProjectInfo | null) => ({
	theme: {
		formStyle: projectInfo?.theme?.formStyle,
	},
});

const createExternalDataSource = (client: Client, projectContentOptions: ProjectContentOptionsInterface) =>
	new ExternalDataSourcesClient(client, projectContentOptions);

const FormDataInfo = {
	preview: 'preview',
	live: 'live',
};

@Component({
	selector: 'uc-form-preview',
	templateUrl: './form-preview.html',
	providers: [
		{ provide: ProjectInfoInjectionToken, useFactory: projectInfoFactory, deps: [Repository] },
		{ provide: ProjectContentOptions, useFactory: projectContentOptionsFactory, deps: [ProjectInfoInjectionToken] },
		UcProject,
		{ provide: ContentClient, useFactory: publishedContentFactory, deps: [Client, TenantClient, ProjectContentOptions] },
		{ provide: PublishedContent, useClass: FormPreviewContentClient },
		{ provide: ExternalDataSourcesClient, useFactory: createExternalDataSource, deps: [Client, ProjectContentOptions] },
		{ provide: FormSettings, useClass: InputFormSettings },
		FormDebugger,
		{ provide: ContextProvider, useExisting: FormDebugger },
		{
			provide: GoogleMapsApiKey,
			useFactory: (contextService: ContextService) => contextService.tenantSettings?.googleMapsApiKey,
			deps: [ContextService],
		},
		{
			provide: LocationProvider,
			useFactory: googleLocationProviderFactory,
			deps: [WindowWrapper, TranslateService, ContextProvider, GoogleMapsApiKey],
		},
		{ provide: ThemeProvider, useFactory: createThemeProvider, deps: [ProjectInfoInjectionToken] },
	],
	styleUrls: ['./form-preview.less'],
	standalone: false,
})
export class FormPreviewComponent implements OnInit, AfterViewInit {

	@ViewChild('formWrapper', { read: ElementRef }) private formWrapper: ElementRef<HTMLElement>;
	@ViewChild('formOutlet', { read: ViewContainerRef }) private formOutletRef: ViewContainerRef;
	@ViewChild('formTemplate', { read: TemplateRef }) private formTemplateRef: TemplateRef<HTMLElement>;

	protected readonly validationOptions = [
		{ label: 'On', value: DebugValidation.On },
		{ label: 'Off', value: DebugValidation.Off },
		{ label: 'Exclude required', value: DebugValidation.ExcludeRequired },
	];

	protected readonly formDataOptions: Option[] = [{
		identifier: FormDataInfo.live,
		name: 'Live',
	}, {
		identifier: FormDataInfo.preview,
		name: 'Preview',
	}];

	protected edited = false;
	protected showError = false;
	protected formConfig: FormConfiguration = { optionalCancelButtonLabel: 'Cancel', optionalSubmitButtonLabel: 'Emulate Submit' };
	protected definition?: RuntimeDefinition;
	protected formData?: FormData;
	protected showSettings = true;
	protected formDebugger = inject(FormDebugger);
	protected config = inject(Config);
	protected roles: string[] = [];
	protected formDataSource: string;

	private modalService = inject(ModalService);
	private toastService = inject(ToastService);
	private cd = inject(ChangeDetectorRef);
	private renderFactory = inject(RendererFactory2);
	private settings = inject(FormSettings);
	private repository = inject(Repository);
	private interceptor = inject(Interceptor) as SdkInterceptor;
	private projectInfo = inject(ProjectInfoInjectionToken);
	private runtimeDefinitionAdapter = inject(RuntimeDefinitionAdapter);
	private domEventHandler = inject(DOMEventHandler);
	private destroy = inject(DestroyRef);
	private projectContentOptions = inject(ProjectContentOptions) as ProjectContentOptionsInterface;
	private viewReady = false;
	private selectedRoles: string[] | undefined;

	ngOnInit() {
		this.settings.uploader = new FormFakeUploader();
		this.interceptor.disabled = true;
		this.formDebugger.validation = DebugValidation.ExcludeRequired;

		this.formDataSource = this.projectContentOptions.preview ? FormDataInfo.preview : FormDataInfo.live;
	}

	async ngAfterViewInit() {
		if (this.projectInfo?.theme) {
			const themeService = new ThemeService(this.formWrapper.nativeElement, this.renderFactory);

			themeService.theme = this.projectInfo.theme;
		}

		this.domEventHandler.register({
			event: 'storage',
			destroy: this.destroy,
			listener: (event) => {
				if (!this.isAFormEntryRelatedEvent(event.key) || !this.viewReady) {
					return;
				}

				void this.initialize();
			},
		});

		await this.initialize();

		this.viewReady = true;
	}

	protected toggleFormDataSource() {
		this.projectContentOptions.preview = this.formDataSource === FormDataInfo.preview;

		this.refresh();
	}

	protected refresh() {
		this.formData = undefined;

		void this.initialize();
	}

	protected async initialize(roles?: string[], keepState?: boolean) {
		this.edited = false;

		const definition = await this.getDefinition();

		this.selectedRoles = roles ?? definition?.roles;
		this.roles = definition?.roles ?? [];
		this.formDebugger.roles = roles ?? [...this.roles];

		const previousState = this.formDebugger.state;

		this.updateTemplate(definition);

		if (keepState && isStringNotEmpty(previousState)) {
			this.formDebugger.state = previousState;
		}
	}

	protected submit(args: SubmitArgs) {
		args.done(args.data);
		this.edited = false;
		this.toastService.success(`Transition to '${args.data._state} done'`);
	}

	protected async close() {
		if (this.edited && await this.modalService.openConfirm({
			title: 'Leave',
			message: 'You are leaving the Form Preview page.',
			confirmLabel: 'Leave',
			cancelLabel: `Don't Leave`,
		})) {
			window.close();
		}
	}

	protected showData() {
		void this.modalService.openMedium(FormDataDisplayModalComponent, this.formData);
	}

	private updateTemplate(definition?: RuntimeDefinition) {

		this.formOutletRef.clear();
		this.cd.detectChanges();

		this.definition = definition;

		if (!definition) {
			this.showError = true;
			this.formData = {};

			return;
		}

		const formData = this.removeDataFromFieldsWithNoPermission(definition);

		this.formData = amendFormData(formData, definition);

		this.formOutletRef.createEmbeddedView(this.formTemplateRef).detectChanges();
	}

	/** Determine if the key is of a LocalStorage entry related to the FormPreview data */
	private isAFormEntryRelatedEvent(storageEventKey: string | null) {
		return !storageEventKey || [PROJECT_STORAGE_KEY, PREVIEW_FORM_STORAGE_KEY].includes(storageEventKey);
	}

	/** Remove fields from form data that the user don't have roles anymore to edit */
	private removeDataFromFieldsWithNoPermission(definition: RuntimeDefinition): FormData | undefined {

		if (!this.formData) {
			return undefined;
		}

		for (const { field, parent } of fieldIterator(definition.fields)) {
			if (parent && field.identifier && parent.type === FieldType.Section
                && !FieldHelperFunctions.areRolesMatching(this.selectedRoles, parent.roles)) {
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete this.formData[field.identifier];
			}
		}

		return this.formData;
	}

	private async getDefinition(): Promise<RuntimeDefinition | undefined> {
		try {
			const definition = this.repository.load<Definition>(PREVIEW_FORM_STORAGE_KEY);

			return definition ? await this.runtimeDefinitionAdapter.transform(definition) : undefined;
		} catch {
			return undefined;
		}
	}

}
