@if (control) {
	<uf-expander class="uf-box flat gap-sm-bottom">
		<div expanderHeader class="uf-app-bar flat">
			<div class="title primary">Parameters</div>
			@if (control.invalid) {
				<uf-icon name="error" class="error" />
			}
			@if (control.length) {
				<uc-expander-controls [container]="argsWrap" />
			}
		</div>
		<div #argsWrap expanderBody class="uc-group-content grid">
			@if (control.length) {
				<ul class="uc-list--joined col-1of1">
					@for (arg of control.controls | asUfControlsGroups; track arg; let i = $index) {
						<uf-expander [formGroup]="arg" class="uc-group margin--vertical">
							<div expanderHeader class="uf-app-bar flat">
								<div class="title primary">
									{{ arg.get(formKeys.Key)?.value }}
								</div>
								@if (arg.invalid) {
									<uf-icon name="error" class="error" />
								}
								@if (!arg.disabled) {
									<button (click)="removeArg(i)" type="button" title="Delete" class="uf-action tertiary">
										<uf-icon name="delete" />
									</button>
								}
							</div>
							<div expanderBody class="uc-group-content grid">
								<uf-text [formControlName]="formKeys.Key" label="Key" class="col-1of1" />
								<uf-expression [formControlName]="formKeys.Value" label="Value" class="col-1of1" />
								<!-- <div class="col-1of1">{{arg.value | json}}</div> -->
							</div>
						</uf-expander>
					}
				</ul>
			}
			<div class="col-1of1">
				@if (!control.disabled) {
					<button (click)="addArg()" type="button" class="uf-button x-small right">Add Parameter</button>
				}
			</div>
		</div>
	</uf-expander>
}
