import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService, WindowWrapper } from '@unifii/library/common';
import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';
import { AppAuthProviderConfiguration, AuthProvider, DataSeed, Dictionary, ErrorType, HierarchyStep, UfError } from '@unifii/sdk';
import { LockedConfig, UserProvisioningCache, toLockedConfig } from '@unifii/user-provisioning';

import { ExternalLogin, IntegrationFeatureConfig, UcAuthProviders, UcUserAuthProvider, UcUserInfo, UcUsers } from 'client';
import { SSOService } from 'services/sso.service';

import { mapProviderLoginLabel } from '../provider-utils';

@Component({
	selector: 'uc-user-external-authentication',
	templateUrl: 'user-external-authentication.html',
	styleUrls: ['./../../../styles/external-branding.less'],
	standalone: false,
})

export class UserExternalAuthenticationComponent implements OnInit {

	@Input({ required: true }) userInfo: UcUserInfo;
	@Input({ required: true }) lockedConfig: LockedConfig | undefined;
	@Input() isMyAccount: boolean;
	@Input() edited: boolean;
	@Input() connectToExternal: boolean;
	@Input() cssClasses: string | string[] | undefined | null;

	@Output() connectToExternalChange = new EventEmitter<boolean>();

	protected error?: UfError;
	protected userAuthProviders: UcUserAuthProvider[] = [];
	protected authProviders: AppAuthProviderConfiguration[] = [];
	protected authProviderInfo: Dictionary<{ claims: string[]; roles: string[]; systemRoles: string[]; features?: IntegrationFeatureConfig; units?: DataSeed[] }> = {};

	private ucUsers = inject(UcUsers);
	private ssoService = inject(SSOService);
	private ucAuthProviders = inject(UcAuthProviders);
	private window = inject(WindowWrapper);
	private modalService = inject(ModalService);
	private route = inject(ActivatedRoute);
	private userProvisioningCache = inject(UserProvisioningCache);

	async ngOnInit() {
		this.authProviders = this.ssoService.providerList
			.filter((provider) => !this.hasProviderInLogins(provider, this.userInfo.logins)) // filter connected providers
			.filter((provider) => AuthProvider.Azure === provider.type)
			.map((provider, _, providers) => mapProviderLoginLabel(provider, providers));

		if (!this.userInfo.id) {
			return;
		}

		this.userAuthProviders = await this.ucUsers.getAuthProviders(this.userInfo.id);

		if (this.userInfo.isExternal) {
			this.lockedConfig = toLockedConfig(this.userAuthProviders);
			void this.setAuthProviderInfo(this.userInfo);
		}
	}

	protected async linkToProvider(provider: AppAuthProviderConfiguration) {

		if (!(await this.modalService.openConfirm({
			title: `Connect Account To ${provider.type}`,
			message: `This action can't be undone. Please confirm you wish to continue.`,
			confirmLabel: 'Connect',
			cancelLabel: `Don't Connect`,
		}))) {
			return;
		}

		try {
			if (this.edited) {
				await this.modalService.openAlert({
					title: 'Unsaved changes',
					message: 'Please save before connecting to a provider.',
					confirmLabel: 'Ok',
				});

				return;
			}

			const redirectUri = `${this.window.location.origin}/my-account/sso-link/${provider.id}`;
			const redirectUrl = await this.ssoService.getProviderUrl(provider, redirectUri);

			if (redirectUrl) {
				this.window.location.href = redirectUrl;
			}
		} catch (e) {
			this.error = new UfError(
				this.route.snapshot.params[provider.type.toLocaleLowerCase()],
				ErrorType.Unknown,
			);
		}
	}

	protected getRolesDisplays(roles: string[]): string[] {
		return roles.map((name) => this.userProvisioningCache.rolesByName[name]?.display ?? name);
	}

	private async setAuthProviderInfo(user: UcUserInfo) {
		for (const provider of this.userAuthProviders) {
			const providerDetails = await this.ucAuthProviders.get(provider.id);

			this.authProviderInfo[provider.id] = {
				claims: provider.lockedClaims.map((claim) => this.claimMapper(claim, user)),
				roles: provider.lockedRoles.filter((role) => user.roles?.includes(role)).sort(stringsCaseInsensitiveLocalCompare),
				systemRoles: provider.lockedSystemRoles.filter((role) => user.systemRoles?.includes(role)).sort(stringsCaseInsensitiveLocalCompare),
				features: providerDetails.featureConfig,
				units: this.getLockedUnits(user.unitPaths),
			};
		}
	}

	private hasProviderInLogins(provider: AppAuthProviderConfiguration, logins?: ExternalLogin[]) {
		// check if provider already exists in login list
		return (logins ?? []).find((login) => login.tenant === provider.tenant) != null;
	}

	private claimMapper(source: string, user: UcUserInfo) {
		const claims = user.claims?.filter((c) => c.type === source).map((v) => v.value).join(', ');

		return `${source}: ${claims ?? ''}`;
	}

	private getLockedUnits(userUnitsPaths?: HierarchyStep[][]) {

		if (!this.lockedConfig?.units || !userUnitsPaths) {
			return [];
		}

		return this.lockedConfig.units.map((unitId) => {
			const matchedUnit = userUnitsPaths.find((unit) => unit[unit.length - 1]?.id === unitId);

			if (!matchedUnit) {
				return;
			}

			return {
				_id: unitId,
				_display: matchedUnit.map((d) => d.label).join(' / '),
				paths: matchedUnit,
			};
		}).filter((dataSeed) => !!dataSeed) as DataSeed[];
	}

}
