import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';

import { Dimension, ImageCrop, ImageCropper } from './imageCropper';

@Component({
	selector: 'uc-image-cropper',
	template: '<canvas #canvas style="background-color: grey;"></canvas>',
	standalone: false,
})
export class ImageCropperComponent implements AfterViewInit, OnChanges, OnDestroy {

	@Input() size: Dimension | null;
	@Input() src: string;
	@Input() minWidth: number;
	@Input() minHeight: number;
	@Input() enabled: boolean;
	@Input() value: ImageCrop | null | undefined;

	@Output() valueChange = new EventEmitter<ImageCrop>();

	@ViewChild('canvas', { static: true }) private canvas: ElementRef<HTMLCanvasElement>;

	cropper: ImageCropper | null;
	ready = new ReplaySubject();

	private breakLoop: boolean;
	private subscriptions = new Subscription();

	ngAfterViewInit() {

		const image = new Image();

		image.onload = () => {
			this.cropper = new ImageCropper(this.canvas.nativeElement, image, this.enabled, this.minWidth, this.minHeight, this.value ?? undefined, this.size ?? undefined);

			if (this.enabled) {
				this.subscriptions.add(this.cropper.cropStream.subscribe((data) => {
					this.breakLoop = true;
					this.value = data;
					this.breakLoop = false;
					this.valueChange.emit(data);
				}));
				this.ready.next({});
			}
		};
		image.src = this.src;
	}

	ngOnChanges(changes: SimpleChanges) {

		if (changes.size) {
			if (this.cropper) {
				this.cropper.setSize(changes.size.currentValue);
			}
		}

		if (changes.value) {
			if (this.cropper && !this.breakLoop) {
				this.cropper.setCrop(changes.value.currentValue);
			}
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}
