import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';

import { ClaimTableManager } from './claim-table-manager';

@Component({
	selector: 'uc-claim-table',
	template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="add()" class="pad-none accent list-md" />
        </div>
        <router-outlet />
        `,
	providers: [
		{ provide: TableContainerManager, useClass: ClaimTableManager },
	],
	standalone: false,
})
export class ClaimTableComponent {

	private router = inject(Router);
	private route = inject(ActivatedRoute);

	protected add() {
		void this.router.navigate(['new'], { relativeTo: this.route });
	}

}
