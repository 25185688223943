import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Modal, ModalData, ModalRuntime, ToastService, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { ErrorType, ensureUfRequestError } from '@unifii/sdk';
import { Subscription, debounceTime, skip } from 'rxjs';

import { UcPermission } from 'client';
import { DialogsService } from 'services/dialogs.service';

import { PermissionActionControlKeys, PermissionControlKeys, PermissionStepControlKeys } from './permission-editor-control-keys';
import { PermissionEditorFormCtrl } from './permission-editor-form-ctrl';
import { PermissionEditorData, PermissionEditorModel, PermissionEditorStep } from './permission-editor-model';
import { PermissionEditorService } from './permission-editor-service';
import { PermissionEditorStepLoader } from './permission-editor-step-loader';

@Component({
	selector: 'uc-permission-editor',
	templateUrl: './permission-editor.html',
	providers: [PermissionEditorFormCtrl],
	standalone: false,
})
export class PermissionEditorComponent implements Modal<PermissionEditorData, UcPermission>, OnInit, OnDestroy {

	@HostBinding('class.uc-form-card') class = true;

	runtime: ModalRuntime<PermissionEditorData, UcPermission> = inject(ModalRuntime);
	data: PermissionEditorData = inject(ModalData);

	get guard() {
		return this.edited;
	}

	protected readonly permissionKeys = PermissionControlKeys;
	protected readonly stepKeys = PermissionStepControlKeys;
	protected readonly actionKeys = PermissionActionControlKeys;

	protected form: UfControlGroup;
	protected title: string;
	protected urlPath: string;
	protected fieldsResult: string[];
	protected saveValidationError?: string;

	protected get steps() {
		return this.form.get(PermissionControlKeys.Steps) as UfControlArray;
	}

	protected get actions() {
		return this.form.get(PermissionControlKeys.Actions) as UfControlArray;
	}

	protected get showEditFields() {
		return this.isFieldsInputVisible(this.form.get(PermissionControlKeys.EditFields));
	}
    
	protected get showReadFields() {
		return this.isFieldsInputVisible(this.form.get(PermissionControlKeys.ReadFields));
	}
    
	protected get showLockedFields() {
		return this.isFieldsInputVisible(this.form.get(PermissionControlKeys.LockedFields));
	}
    
	protected get showDeniedFields() {
		return this.isFieldsInputVisible(this.form.get(PermissionControlKeys.DeniedFields));
	}

	private dialogsService = inject(DialogsService);
	private editorService = inject(PermissionEditorService);
	private builder = inject(PermissionEditorFormCtrl);
	private toastService = inject(ToastService);
	private subscription = new Subscription();
	private mode: 'view' | 'add' | 'edit';
	private _edited: boolean;

	async ngOnInit() {

		this.mode = this.data.readonly ? 'view' : this.data.permission.id != null ? 'edit' : 'add';

		const formData = await this.builder.mapDataToControlValue(this.data.permission);

		this.form = this.builder.buildRoot(formData, this.mode === 'view', this.mode === 'edit');
        
		this.urlPath = this.editorService.getUrlPath(formData.steps);
		this.subscription.add(this.steps.valueChanges.pipe(debounceTime(0)).subscribe((steps: PermissionEditorStep[]) => {
			this.urlPath = this.editorService.getUrlPath(steps);
		}));

		// For new permission apply first segment option to the first step
		let formChanged = false;
		const firstStepControl = this.steps.controls[0] as UfControlGroup | undefined;
        
		if (this.mode === 'add' && firstStepControl) {
			const firstSegmentFirstOption = this.builder.getSegmentFirstOption(firstStepControl.getRawValue() as PermissionEditorStep);
            
			if (firstSegmentFirstOption) {
				firstStepControl.get(PermissionStepControlKeys.Segment)?.setValue(firstSegmentFirstOption);
				formChanged = true;
			}
		}

		// trigger the title generation
		this.edited = false;
		this.subscription.add(this.form.valueChanges.pipe(debounceTime(0), skip(formChanged ? 1 : 0)).subscribe(() => {
			this.edited = true;
			this.saveValidationError = undefined;
		}));
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	async close() {
		if (this.edited && !await this.dialogsService.confirmLeaveWithoutSaving()) {
			return;
		}

		this.runtime.close();
	}

	protected async save() {
		this.form.setSubmitted();
		if (!this.form.valid) {
			return;
		}

		const data = this.builder.mapControlValueToData(this.form.getRawValue() as PermissionEditorModel);

		try {
			await this.data.service.save(data);
		} catch (e) {
			const error = ensureUfRequestError(e);
            
			if (error.type === ErrorType.Validation) {
				this.saveValidationError = error.message;
			} else {
				this.toastService.error(`Permission ${this.mode} failed`);
			}
            
			return;
		}

		this.runtime.close(data);
	}

	protected getLoader(step: UfControlGroup) {
		return step.get(PermissionStepControlKeys.Loader)?.value as PermissionEditorStepLoader;
	}

	protected async searchFields(query: string | null) {
		this.fieldsResult = await this.editorService.findFields(this.steps.value as PermissionEditorStep[], query ?? undefined);
	}

	/**
     * Fields input is visible if the control is editable or if it the editor is readOnly and there is a valorized value
     * Same logic will be implemented for deniedFields lockedFields and readFields
    */
	private isFieldsInputVisible(control: AbstractControl | null): boolean {
		return control && (control.enabled || this.data.readonly && control.value.length);
	}

	private set edited(v: boolean) {
		if (v === this._edited) {
			return;
		}

		this._edited = v;
		this.title = this.getTitle();
	}

	private get edited(): boolean {
		return this._edited;
	}

	private getTitle(): string {
		switch (this.mode) {
			case 'view':
				return 'View Permission';
			case 'add':
				return `Add Permission${this.edited ? ' *' : ''}`;
			case 'edit':
				return `Edit Permission${this.edited ? ' *' : ''}`;
		}
	}

}
