// TODO convert to exported const functions
import { CompoundType, Dictionary, FieldType, StructureNodeType, hasLengthExact, isNotNull } from '@unifii/sdk';

export interface FieldReference {
	type: string;
	label: string;
	icon: string;
	reference?: boolean; // mark it as a fieldReference to discern it from a normal field
	compoundType?: string; // Specialize the type
	disabled?: boolean; // listed but not draggable
	customId?: number; // a custom template field
}

export interface FieldReferenceGroup {
	title?: string;
	entries: FieldReference[];
}

export class FieldReferenceHelper {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	static readonly NODE_REFERENCE_MAP: Dictionary<FieldReference> = {
		Structure: { type: 'Structure', label: 'Structure', icon: 'structure', reference: true },
		CollectionItem: { type: StructureNodeType.CollectionItem, label: 'Collection Item', icon: 'collectionItem', reference: true },
		Collection: { type: StructureNodeType.Collection, label: 'Collection', icon: 'collections', reference: true },
		View: { type: StructureNodeType.View, label: 'View', icon: 'views', reference: true },
		Page: { type: StructureNodeType.Page, label: 'Page', icon: 'pages', reference: true },
		Form: { type: StructureNodeType.Form, label: 'Form', icon: 'forms', reference: true },
		FormBucket: { type: StructureNodeType.FormBucket, label: 'Table', icon: 'table', reference: true },
		Empty: { type: StructureNodeType.Empty, label: 'Menu Heading', icon: 'emptyNode', reference: true },
		Link: { type: StructureNodeType.Link, label: 'External URL Link', icon: 'earth', reference: true },
		Dashboard: { type: StructureNodeType.Dashboard, label: 'Dashboard', icon: 'dashboard', reference: true },
		PdfViewer: { type: StructureNodeType.PdfViewer, label: 'PDF Viewer', icon: 'pdf', reference: true },
		IFrame: { type: StructureNodeType.IFrame, label: 'External View', icon: 'externalView', reference: true },
		Custom: { type: StructureNodeType.Custom, label: 'Custom', icon: 'custom', reference: true },
	};

	// eslint-disable-next-line @typescript-eslint/naming-convention
	static readonly FIELD_REFERENCE_MAP: Dictionary<FieldReference> = {
		ActionGroup: { type: FieldType.ActionGroup, label: 'Action Group', icon: 'groupAction', reference: true },
		Address: { type: FieldType.Address, label: 'Address', icon: 'address', reference: true },
		Bool: { type: FieldType.Bool, label: 'Bool', icon: 'bool', reference: true },
		Choice: { type: FieldType.Choice, label: 'Choice', icon: 'singleChoice', reference: true },
		Content: { type: FieldType.Content, label: 'Content', icon: 'content', reference: true },
		Cost: { type: FieldType.Cost, label: 'Currency', icon: 'cost', reference: true },
		Date: { type: FieldType.Date, label: 'Date', icon: 'date', reference: true },
		DateTime: { type: FieldType.DateTime, label: 'Date & Time', icon: 'dateTime', reference: true },
		DateTimeTZ: { type: FieldType.ZonedDateTime, label: 'Date, Time & Time Zone', icon: 'zonedDateTime', reference: true },
		Email: { type: FieldType.Email, label: 'Email', icon: 'mail', reference: true },
		GeoLocation: { type: FieldType.GeoLocation, label: 'Geo Location', icon: 'geoLocation', reference: true },
		Group: { type: FieldType.Group, label: 'Group', icon: 'group', reference: true },
		ImageList: { type: FieldType.ImageList, label: 'Image List', icon: 'imageList', reference: true },
		VideoList: { type: FieldType.VideoList, label: 'Video List', icon: 'video', reference: true },
		FileList: { type: FieldType.FileList, label: 'File List', icon: 'fileList', reference: true },
		SoundList: { type: FieldType.SoundList, label: 'Sound List', icon: 'audio', reference: true },
		MultiChoice: { type: FieldType.MultiChoice, label: 'Multi Choice', icon: 'multiChoice', reference: true },
		MultiText: { type: FieldType.MultiText, label: 'Multi Text', icon: 'multiText', reference: true },
		// eslint-disable-next-line id-denylist
		Number: { type: FieldType.Number, label: 'Number', icon: 'number', reference: true },
		Phone: { type: FieldType.Phone, label: 'Phone', icon: 'telephone', reference: true },
		Repeat: { type: FieldType.Repeat, label: 'Repeating Group', icon: 'groupRepeat', reference: true },
		Section: { type: FieldType.Section, label: 'Section', icon: 'section', reference: true },
		Carousel: { type: FieldType.Carousel, label: 'Content Carousel', icon: 'carousel', reference: true },
		Separator: { type: FieldType.Separator, label: 'Separator', icon: 'separator', reference: true },
		Signature: { type: FieldType.Signature, label: 'Signature', icon: 'signature', reference: true },
		Step: { type: FieldType.Step, label: 'Stepper Page', icon: 'pages', reference: true },
		Stepper: { type: FieldType.Stepper, label: 'Stepper', icon: 'stepper', reference: true },
		Survey: { type: FieldType.Survey, label: 'Survey Group', icon: 'survey', reference: true },
		Text: { type: FieldType.Text, label: 'Text', icon: 'text', reference: true },
		Time: { type: FieldType.Time, label: 'Time', icon: 'time', reference: true },
		Website: { type: FieldType.Website, label: 'Website', icon: 'earth', reference: true },
		// NfcTag: { type: FieldType.NfcTag, label: 'NFC Tag', icon: 'nfcScan', disabled: true, reference: true },
		ContentLink: { type: FieldType.Link, label: 'Content Link', icon: 'link', reference: true },
		CollectionItemLink: { type: FieldType.Link, label: 'Collection Item Link', compoundType: 'Collection', icon: 'collectionItem', reference: true },
		ViewLinkList: { type: FieldType.Link, label: 'View Link', icon: 'viewLink', compoundType: 'View', reference: true },
		PageLinkList: { type: FieldType.Link, label: 'Page Link', icon: 'pageLink', compoundType: 'Page', reference: true },
		CollectionLinkList: { type: FieldType.LinkList, label: 'Collection Link List', compoundType: 'Collection', icon: 'collectionLink', reference: true },
		FormLinkList: { type: FieldType.DefinitionLink, label: 'Form Link', icon: 'formLink', compoundType: 'Form', reference: true },
		Lookup: { type: FieldType.Lookup, label: 'Look Up', icon: 'lookup', reference: true },
		Hierarchy: { type: FieldType.Hierarchy, label: 'Hierarchy', icon: 'hierarchy', reference: true },
	};

	static getFieldReference(field?: any, builder: CompoundType = CompoundType.Collection): FieldReference {

		// Specific lookup
		if (field?.type) {

			switch (builder) {
				case CompoundType.Structure:
					return FieldReferenceHelper.NODE_REFERENCE_MAP[field.type] as FieldReference;
				default: {
					const matches: FieldReference[] = [];

					Object.keys(FieldReferenceHelper.FIELD_REFERENCE_MAP).forEach((k) => {
						const ref = FieldReferenceHelper.FIELD_REFERENCE_MAP[k];

						if (ref && ref.type === field.type) {
							matches.push(ref);
						}
					});
					if (hasLengthExact(matches, 1)) {
						return matches[0];
					}
					if (matches.length > 1) {
						if (field.type === FieldType.Link) {
							const found = matches.find((m) =>
								field.compoundType == null && m.compoundType == null ||
                                m.compoundType === field.compoundType,
							);

							if (found) {
								return found;
							}
						} else {
							console.warn('Multiple reference found for', field, builder, '>', matches);
						}
					}
				}
			}
		}

		const fallback = FieldReferenceHelper.NODE_REFERENCE_MAP.Form as FieldReference;

		switch (builder) {
			case CompoundType.Structure:
				return FieldReferenceHelper.NODE_REFERENCE_MAP.Structure ?? fallback;
			case CompoundType.View:
				return FieldReferenceHelper.NODE_REFERENCE_MAP.View ?? fallback;
			case CompoundType.Page:
				return FieldReferenceHelper.NODE_REFERENCE_MAP.Page ?? fallback;
			case CompoundType.Collection:
				return FieldReferenceHelper.NODE_REFERENCE_MAP.Collection ?? fallback;
			case CompoundType.Form:
				return FieldReferenceHelper.NODE_REFERENCE_MAP.Form ?? fallback;
			default:
				return FieldReferenceHelper.NODE_REFERENCE_MAP.Form ?? fallback;
		}
	}

	static getFieldReferenceGroups(type?: CompoundType): FieldReferenceGroup[] {
		switch (type) {
			case CompoundType.Structure:
				return [{
					title: 'Structure Nodes',
					entries: [
						FieldReferenceHelper.NODE_REFERENCE_MAP.CollectionItem,
						FieldReferenceHelper.NODE_REFERENCE_MAP.Collection,
						FieldReferenceHelper.NODE_REFERENCE_MAP.View,
						FieldReferenceHelper.NODE_REFERENCE_MAP.Page,
						FieldReferenceHelper.NODE_REFERENCE_MAP.Form,
						FieldReferenceHelper.NODE_REFERENCE_MAP.FormBucket,
						FieldReferenceHelper.NODE_REFERENCE_MAP.Dashboard,
						FieldReferenceHelper.NODE_REFERENCE_MAP.PdfViewer,
						FieldReferenceHelper.NODE_REFERENCE_MAP.Custom,
						FieldReferenceHelper.NODE_REFERENCE_MAP.IFrame,
						FieldReferenceHelper.NODE_REFERENCE_MAP.Empty,
						FieldReferenceHelper.NODE_REFERENCE_MAP.Link,
					].filter(isNotNull),
				}];

			case CompoundType.Page:
				return [{
					title: 'Fields',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.MultiText,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.ImageList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.FileList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.SoundList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.VideoList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.CollectionLinkList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.CollectionItemLink,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.ViewLinkList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.PageLinkList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.FormLinkList,
					].filter(isNotNull),
				}];

			case CompoundType.View:
				return [{
					title: 'Entry Fields',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Text,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.MultiText,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Number,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Date,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Time,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.DateTime,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Phone,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Email,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Website,
					].filter(isNotNull),
				}, {
					title: 'Import',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.ImageList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.FileList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.SoundList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.VideoList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.CollectionLinkList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.CollectionItemLink,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.ViewLinkList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.PageLinkList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.FormLinkList,
					].filter(isNotNull),
				},
				{
					title: 'Advanced Fields',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Address,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.GeoLocation,
						// FieldReferenceHelper.FIELD_REFERENCE_MAP.NfcTag
					].filter(isNotNull),
				},
				];

			case CompoundType.Collection:
				return [{
					title: 'Entry Fields',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Text,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.MultiText,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Number,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Date,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Time,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.DateTime,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Phone,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Email,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Website,
					].filter(isNotNull),
				}, {
					title: 'Choice Fields',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Bool,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Choice,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.MultiChoice,
					].filter(isNotNull),
				}, {
					title: 'Import',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.ImageList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.FileList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.SoundList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.VideoList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.CollectionLinkList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.CollectionItemLink,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.ViewLinkList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.PageLinkList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.FormLinkList,
					].filter(isNotNull),
				},
				{
					title: 'Advanced Fields',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Address,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.GeoLocation,
						// FieldReferenceHelper.FIELD_REFERENCE_MAP.NfcTag
					].filter(isNotNull),
				},
				];

			case CompoundType.Form:
				return [{
					title: 'Form Structure',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Section,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Group,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Stepper,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Repeat,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.ActionGroup,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Survey,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Content,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Separator,
					].filter(isNotNull),
				}, {
					title: 'Entry Fields',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Text,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.MultiText,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Number,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Date,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Time,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.DateTime,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.DateTimeTZ,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Phone,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Email,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Website,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Cost,
					].filter(isNotNull),
				}, {
					title: 'Choice Fields',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Lookup,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Bool,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Choice,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.MultiChoice,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Hierarchy,
					].filter(isNotNull),
				}, {
					title: 'Import',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.ImageList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.FileList,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Link,
					].filter(isNotNull),
				}, {
					title: 'Advanced Fields',
					entries: [
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Address,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.GeoLocation,
						FieldReferenceHelper.FIELD_REFERENCE_MAP.Signature,
					].filter(isNotNull),
				}];

			default:
				return [];
		}
	}

}
