import { Directive, HostListener, OnInit, inject } from '@angular/core';

import { DragItemDirective } from './drag-item.directive';

// This directive registers itself with DragItem as a valid target
// DragItem on dragstart checks for valid targets if any
@Directive({
	selector: '[dragHandle]',
	standalone: false,
})
export class DragHandleDirective implements OnInit {

	private dragItemDirective = inject(DragItemDirective);

	ngOnInit() {
		this.dragItemDirective.hasHandle = true;
	}

	@HostListener('mousedown', ['$event'])
	mouseDown() {
		this.dragItemDirective.handleUsed = true;
	}

}
