import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'uc-error-handling',
	templateUrl: './error-handling.html',
	standalone: false,
})
export class ErrorHandlingComponent {

	@HostBinding('class.stretch-component') class = true;

	error() {
		throw new Error('Sergio error test');
	}

}

