import { TranslateLoader } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey, TimezonesTranslationKey, TranslationEntry, loadDictionary } from '@unifii/library/common';
import { SmartFormsTranslationKey } from '@unifii/library/smart-forms';
import { DisplayTranslationKey } from '@unifii/library/smart-forms/display';
import { InputTranslationKey } from '@unifii/library/smart-forms/input';
import { Dictionary } from '@unifii/sdk';
import { UserProvisioningTranslationKey } from '@unifii/user-provisioning';
import { Observable, from } from 'rxjs';

export const translatedLanguages: string[] = ['en', 'it', 'ru', 'pt-BR'];

export class ConsoleTranslateLoader implements TranslateLoader {

	readonly entries: TranslationEntry[] = [
		{ identifier: 'library-shared-terms', dictionary: SharedTermsTranslationKey },
		{ identifier: 'library-common', dictionary: CommonTranslationKey },
		{ identifier: 'library-smart-forms', dictionary: SmartFormsTranslationKey },
		{ identifier: 'library-input', dictionary: InputTranslationKey },
		{ identifier: 'library-display', dictionary: DisplayTranslationKey },
		{ identifier: 'library-user-provisioning', dictionary: UserProvisioningTranslationKey },
		{ identifier: 'timezones', dictionary: TimezonesTranslationKey },
	];

	constructor(private translationsUrl: string) { }

	getTranslation(lang: string): Observable<Dictionary<string>> {
		return from(loadDictionary(lang, [this.translationsUrl], this.entries));
	}

}
