@if (ready) {
	<uf-expander class="uf-box flat">
		<div expanderHeader class="uf-app-bar flat">
			<div class="title primary">Nested Fields</div>
			@if (isInvalid) {
				<uf-icon name="error" class="error" />
			}
		</div>
		<div [formGroup]="control" expanderBody class="uc-group-content grid">
			<!-- Address autocomplete -->
			@if (field.type === fieldTypes.Address) {
				<uf-checkbox [formControlName]="fieldKeys.AddressAutocomplete" label="Enable search" class="col-1of1--no-margin" />
			}
			<!-- Nested Fields -->
			<div [formGroup]="nestedControlGroup">
				@for (controlName of nestedControlNames; track controlName) {
					<div [formGroupName]="controlName" class="col-1of1--no-margin">
						<div class="fieldset-item--x-small">
							<span>{{ getLabel(controlName) | toDisplayName }}</span>
						</div>
						<div class="row space-children">
							<uf-checkbox [formControlName]="nestedKeys.Visible" label="Visible" class="hideError" />
							<uf-checkbox [formControlName]="nestedKeys.ReadOnly" label="Read Only" />
							<uf-checkbox [formControlName]="nestedKeys.Required" label="Required" class="hideError" />
						</div>
						<uf-error [formControlName]="nestedKeys.Visible" />
						<uf-error [formControlName]="nestedKeys.Required" />
					</div>
				}
			</div>
		</div>
	</uf-expander>
}
