import { Component, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';

interface HierarchyFinderColumn {
	label: string;
}

enum ColumnKey {
	Label = 'label',
}

@Component({
	templateUrl: './hierarchy-finder-column-input.html',
	standalone: false,
})
export class HierarchyFinderColumnInputComponent implements Modal<HierarchyFinderColumn, string | undefined>, OnInit {

	runtime = inject<ModalRuntime<HierarchyFinderColumn, string | undefined>>(ModalRuntime);

	data = inject<HierarchyFinderColumn>(ModalData);
	protected readonly columnKey = ColumnKey;
	protected form: UfControlGroup;

	private ufb = inject(UfFormBuilder);

	ngOnInit() {
		this.form = this.ufb.group({
			[ColumnKey.Label]: this.ufb.control(this.data.label, ValidatorFunctions.required('Label is required')),
		});
	}

	protected add() {
		this.form.setSubmitted();
		if (this.form.invalid) {
			return;
		}

		const { label } = this.form.value;

		this.runtime.close(label);
	}

}
