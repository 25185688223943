import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { Device, RequestAnalyticsOrigin } from '@unifii/sdk';

import { UcApps } from 'client';

export class AppDevicesDataSource extends TableDataSource<Device> {

	filtered: boolean;
	sorted: boolean;

	constructor(
		private ucApps: UcApps,
		private appId: string,
		private query?: string,
	) {
		super();
	}

	load(options?: TableDataSourceQueryOptions) {
		this.ucApps.getDevices(this.appId, {
			params: { q: this.query, offset: options?.offset, limit: options?.limit },
			analytics: { origin: RequestAnalyticsOrigin.Table },
		}).then(
			(devices) => this.stream.next({ data: devices }),
			(error) => this.stream.next({ error }),
		);
	}

}
