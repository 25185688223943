import { Injectable, inject } from '@angular/core';
import { StorageWrapper } from '@unifii/library/common';
import { TokenStorage, decrypt, encrypt } from '@unifii/sdk';

import { Config } from 'app-config';

const TokenKey = 'UfToken';
const LimitedTokenKey = 'UfLimitedToken';
const RefreshTokenKey = 'UfRefreshToken';
const ExpiresAt = 'UfExpiresAt';

@Injectable({ providedIn: 'root' })
export class TokenService implements TokenStorage {

	private tokenCache: CryptoKey;
	private storage = inject(StorageWrapper);
	private config = inject(Config);

	get token(): string | null {
		return this.storage.getItem(TokenKey) as string | null;
	}

	set token(v: string | null) {
		this.setStorageItem(TokenKey, v);
	}

	get expiresAt(): string | null {
		return this.storage.getItem(ExpiresAt) as string | null;
	}

	set expiresAt(v: string | null) {
		this.setStorageItem(ExpiresAt, v);
	}

	get limitedToken(): string | null {
		return this.storage.getItem(LimitedTokenKey) as string | null;
	}

	set limitedToken(v: string | null) {
		this.setStorageItem(LimitedTokenKey, v);
	}

	async setRefreshToken(v: string | null) {

		if (v) {
			const { cryptoKey, byteString } = await encrypt(this.tokenEncryptionKey, v);

			this.tokenCache = cryptoKey;
			v = byteString;
		}

		this.setStorageItem(RefreshTokenKey, v);
	}

	getRefreshToken(): Promise<string | null> {

		const byteString = this.storage.getItem(RefreshTokenKey);

		if (!byteString) {
			return Promise.resolve(null);
		}

		return decrypt({ key: this.tokenEncryptionKey, byteString, cryptoKey: this.tokenCache });
	}

	clear() {
		this.token = null;
		this.expiresAt = null;
		void this.setRefreshToken(null);
	}

	private get tokenEncryptionKey(): string {
		return this.config.appSecret;
	}

	private setStorageItem(key: string, v: string | null) {

		if (!v) {
			this.storage.removeItem(key);
		} else {
			this.storage.setItem(key, v);
		}
	}

}
