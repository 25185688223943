import { TABLE_CONFIGURATION } from './configuration';
import { TABLE_DETAIL } from './detail';
import { TableComponent } from './table.component';
import { TablesComponent } from './tables.component';

export * from './tables.component';
export * from './table.component';
export * from './detail';
export * from './configuration';

const TABLES_COMPONENTS = [
	TablesComponent,
	TableComponent,
	TABLE_DETAIL,
	TABLE_CONFIGURATION,
];

export { TABLES_COMPONENTS };
