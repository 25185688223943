import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { SystemRole, UcPage } from 'client';
import { BuilderService } from 'components/compound-builder/builder.service';
import { ConsoleNameLabel, ConsoleNameRequiredMessage } from 'constant';
import { ArrayHelper, ContentDefinitionIdentifierValidators, IdentifierFunctions } from 'helpers/helpers';
import { ContextService } from 'services/context.service';

enum PageControlKeys {
	Title = 'title',
	Identifier = 'identifier',
	ConsoleName = 'consoleName',
	Tags = 'tags',
}

@Component({
	selector: 'uc-page-settings',
	templateUrl: './page-settings.html',
	standalone: false,
})
export class PageSettingsComponent implements OnInit, OnDestroy {

	@Input({ required: true }) page: UcPage;

	builderService = inject(BuilderService);

	protected readonly consoleNameLabel = ConsoleNameLabel;
	protected control = new UfControlGroup({
		[PageControlKeys.Title]: new UfControl(ValidatorFunctions.required('Title is required.')),
		[PageControlKeys.ConsoleName]: new UfControl(ValidatorFunctions.required(ConsoleNameRequiredMessage)),
		[PageControlKeys.Identifier]: new UfControl(ContentDefinitionIdentifierValidators),
		[PageControlKeys.Tags]: new UfControl(),
	});
	protected readonly maxLength = IdentifierFunctions.WARNING_IDENTIFIER_MAX_LENGTH;
	protected filteredTags: string[];

	private context = inject(ContextService);
	private subscriptions = new Subscription();

	ngOnInit() {

		if (!this.context.checkRoles(SystemRole.ProjectManager) || this.page.lastPublishedAt != null) {

			const identifierControl = (this.control.get(PageControlKeys.Identifier) as UfControl);

			identifierControl.disable();
		}

		this.subscriptions.add(this.control.valueChanges.subscribe(() => this.valueChange()));

		this.subscriptions.add(this.builderService.submitted.subscribe(() => {
			this.control.setSubmitted(true);
		}));

		// New definition
		if (this.page.lastModifiedAt == null && this.control.get(PageControlKeys.Title)) {
			this.subscriptions.add(this.control.get(PageControlKeys.Title)?.valueChanges.subscribe(this.autofillFields));
		}

		/**
         * // TODO remove when builder validation is refactored,
         * validation is backwards, now children control groups
         * should attach themselves to parent control which should validate on submit
         */
		setTimeout(() => this.valueChange(true), 0);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	filterTags(query: string | null) {
		this.filteredTags = ArrayHelper.filterList(this.builderService.tags, query ?? undefined);
	}

	private autofillFields = (value: string | undefined) => {

		const consoleName = value;
		let identifier = '';

		if (value != null && value !== '') {
			identifier = IdentifierFunctions.kebabize(value || '')
				.substr(0, IdentifierFunctions.IDENTIFIER_MAX_LENGTH);
		}

		if (!this.control.get(PageControlKeys.ConsoleName)?.dirty) {
			this.control.get(PageControlKeys.ConsoleName)?.setValue(consoleName, { onlySelf: false, emitResult: true });
		}

		if (!this.control.get(PageControlKeys.Identifier)?.dirty) {
			this.control.get(PageControlKeys.Identifier)?.setValue(identifier, { onlySelf: false, emitResult: true });
		}
	};

	private valueChange(skipEdit?: boolean) {

		/**
         * This is backwards submit should validate form then get errors
         */
		const errors = Object.keys(this.control.controls).map((key) => {
			const control = this.control.get(key) as UfControl;

			if (control.errors == null) {
				return null;
			}

			return control.errors.message;
		}).filter((err) => err != null).map((e) => e.message);

		this.builderService.setErrors(this.builderService.definition, errors, 'all');

		if (!skipEdit) {
			this.builderService.fieldEdit.next({ subject: this.builderService.definition, atomic: false });
		}
	}

}
