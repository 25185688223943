<div class="header">
	<button (click)="close()" title="Close" type="button" class="uf-action tertiary">
		<uf-icon name="close" />
	</button>
	<h3>{{ title }}</h3>
</div>

@if (form) {
	<uf-panel [formGroup]="form">
		<div class="uf-grid pad">
			<uf-textarea [formControlName]="permissionKeys.Description" label="Description" class="col-12" />
			<div class="col-12">
				<uf-data-display-list [items]="[{ term: 'Path', data: urlPath }]" class="columns" />
			</div>
			<div class="col-12">
				<h4>Path</h4>
			</div>
			@for (step of steps.controls | asUfControlsGroups; track step; let i = $index) {
				@if (getLoader(step); as loader) {
					<div class="col-6">
						<div [formGroup]="step">
							@if (loader.options) {
								<uf-select [formControlName]="stepKeys.Segment" [options]="loader.options" nameProperty="name" />
							}
							@if (!loader.options) {
								<uf-autocomplete [formControlName]="stepKeys.Segment" [options]="loader.resultOptions" (searchChange)="loader.load($event)" nameProperty="name" />
							}
						</div>
					</div>
				}
			}
			<div class="col-12">
				<h4>Actions</h4>
				@if (actions.length) {
					<ul class="uf-list uf-box flat">
						@for (action of actions.controls | asUfControlsGroups; track action; let i = $index) {
							<li [formGroup]="action" class="uf-grid pad-sides pad-sm-top pad-sm-bottom">
								<uf-checkbox [formControlName]="actionKeys.Selected" [label]="action.get(actionKeys.Name)?.value" class="col-2" />
								<div class="col-10">
									@for (description of action.get(actionKeys.Descriptions)?.value; track description) {
										<p class="small">
											{{ description }}
										</p>
									}
								</div>
								@if (action.get(actionKeys.Condition); as conditionControl) {
									@if (conditionControl.enabled || data.readonly) {
										<uf-textarea [formControlName]="actionKeys.Condition" label="Condition" class="col-12" />
									}
								}
							</li>
						}
					</ul>
				}
				<uf-error [control]="actions" />
			</div>
			@if (showEditFields) {
				<div class="uf-box flat col-12">
					<div class="uf-app-bar accent">
						<div class="title">Editable Fields</div>
					</div>
					<div class="uf-grid pad">
						<!-- TODO restore once blacklists are restored <uf-blockquote content="Leave both blank for full access, otherwise use either Editable Fields to create an Allow List OR use Non-Editable Fields to create a Block List."
              icon="infoSolid" class="info col-12"/> -->
						<uf-chips [formControlName]="permissionKeys.EditFields" [options]="fieldsResult" (searchChange)="searchFields($event)" label="Editable Fields" class="col-12" />
						<!-- TODO Reflect Discover temporarily ignore field blacklists -->
						<!-- <uf-chips label="Non-Editable Fields" [formControlName]="permissionKeys.LockedFields" (searchChange)="searchFields($event)" [options]="fieldsResult" class="col-12"/> -->
					</div>
				</div>
			}
			@if (showReadFields) {
				<div class="uf-box flat col-12">
					<div class="uf-app-bar accent">
						<div class="title">Visible Fields</div>
					</div>
					<div class="uf-grid pad">
						<!-- TODO restore once blacklists are restored <uf-blockquote content="Leave both blank for full visibility, otherwise use either Visible Fields to create an Allow List OR use Non-Visible Fields to create a Block List."
                icon="infoSolid" class="info col-12"/> -->
						<uf-chips [formControlName]="permissionKeys.ReadFields" [options]="fieldsResult" (searchChange)="searchFields($event)" label="Read fields" class="col-12" />
						<!-- TODO Reflect Discover temporarily ignore field blacklists -->
						<!-- <uf-chips label="Denied fields" [formControlName]="permissionKeys.DeniedFields" (searchChange)="searchFields($event)" [options]="fieldsResult" class="col-12"/> -->
					</div>
				</div>
			}
			@if (saveValidationError) {
				<uf-message [content]="saveValidationError" icon="errorSolid" class="small error col-12">
					<h3>Validation error</h3>
				</uf-message>
			}
		</div>
	</uf-panel>
}

@if (form) {
	<div class="row space-children pad">
		<button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
		@if (form.enabled) {
			<button (click)="save()" type="button" class="uf-button primary">Save</button>
		}
	</div>
}
