import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MessageAction, ModalService, UfControl } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { AddDescriptionModalComponent } from './add-description-modal.component';

@Component({
	selector: 'uc-content-description',
	templateUrl: './content-description.html',
	standalone: false,
})
export class ContentDescriptionComponent implements OnInit, OnDestroy {

	@Input({ required: true }) control: UfControl;
	@Input({ required: true }) mode: 'icon' | 'view';

	protected readonly actions: MessageAction[] = [{
		label: 'Edit',
		action: () => void this.edit(),
		icon: 'edit',
		type: 'secondary',
	}, {
		label: 'Delete',
		action: () => this.control.setValue(null),
		icon: 'delete',
		type: 'primary',
	}];

	protected description: string | null;

	private modalService = inject(ModalService);
	private subscriptions = new Subscription();

	ngOnInit() {
		this.subscriptions.add(this.control.valueChanges.subscribe((v: string) => {
			this.description = v;
		}));
		this.description = this.control.value;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected async edit() {
		const description = await this.modalService.openMedium(AddDescriptionModalComponent, this.control.value ?? '');

		if (!description) {
			return;
		}

		this.control.setValue(description);
	}

}
