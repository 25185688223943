import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { MessageLevel, ModalService, RuntimeDefinition, RuntimeDefinitionAdapter, Scope, ToastService } from '@unifii/library/common';
import { ComponentRegistry } from '@unifii/library/smart-forms';
import { CompoundType, Definition, Field, FieldType } from '@unifii/sdk';
import { Subject, debounceTime } from 'rxjs';

import { BuilderField, CompoundInfo, UcDefinition, UcProject } from 'client';
import { ContentSettings, EditMode, LinkSearchComponent, LinkSearchConfig, LinkSearchType, ModalSearchData, SaveAndClose, SaveOption } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { MarkdownEditorRegistry } from 'components/markdown-editor-registry';
import { IdentifierFunctions } from 'helpers/helpers';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { UcTableManager } from 'services/table/models';
import { TitleService } from 'services/title.service';

import { CollectionService, collectionServiceFactory } from './collection-service';

@Component({
	templateUrl: './collection-builder.html',
	styleUrls: ['./collection-builder.less', './../../../styles/pages/builder.less'],
	providers: [
		BuilderService,
		{ provide: CollectionService, useFactory: collectionServiceFactory, deps: [ActivatedRoute, UcProject] },
		{ provide: ComponentRegistry, useClass: MarkdownEditorRegistry },
		{ provide: ContentSettings, useValue: { canEditContent: true } },
	],
	standalone: false,
})
export class CollectionBuilderComponent extends BuilderBasic implements OnInit, OnDestroy {

	@HostBinding('class.stretch-component') get newClass() {
		return this.editMode === EditMode.New;
	}

	readonly type = CompoundType.Collection;
	readonly subject = BuilderCompoundSubjects.DEFINITION;

	protected override router = inject(Router);
	protected ready: boolean;
	protected definition: UcDefinition;
	protected selected: Field;
	protected previewDefinition: RuntimeDefinition | undefined;
	protected previewScope: Scope = {};

	private lastInserted: Field | undefined;
	private refreshPreviewDefinition = new Subject<void>();
	private titleService = inject(TitleService);
	private ucProject = inject(UcProject);
	private toastService = inject(ToastService);
	private breadcrumbService = inject(BreadcrumbService);
	private builderHeaderService = inject(BuilderHeaderService);
	private runtimeDefinitionAdapter = inject(RuntimeDefinitionAdapter);
	private collectionService = inject(CollectionService, { optional: true });

	constructor() {
		const builderService = inject(BuilderService);
		const modalService = inject(ModalService);
		const route = inject(ActivatedRoute);
		const tableManager = inject<UcTableManager<CompoundInfo> | null>(TableContainerManager, { optional: true });

		super(builderService, modalService, route, tableManager);

		this.editMode = route.snapshot.params.id === 'new' ?
			EditMode.New :
			EditMode.Existing;
	}

	async ngOnInit() {

		// Override save options logic
		this.saveOptions = [SaveAndClose];

		// Load data
		this.definition = await this.load();

		this.addSubscribers();

		this.builderService.init(this, this.definition);
		this.builderHeaderService.init();

		this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => void this.save(saveOption)));
		this.buildHeaderConfig(this.definition);

		this.subscriptions.add(this.refreshPreviewDefinition.pipe(debounceTime(500)).subscribe(() => void (async() => {
			this.previewDefinition = await this.runtimeDefinitionAdapter.transform(this.definition as Definition);
		})()));

		this.titleService.updateTitle(this.mapContentItemName(this.definition.consoleName));
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
		this.collectionService?.restore();
	}

	addSubscribers() {

		this.subscriptions.add(this.builderService.ready.subscribe(() => {
			this.saveStatus();
			if (this.collectionService) {
				this.collectionService.edited = false;
			}
			this.builderService.memento.edited = false;
			this.ready = true;
			this.builderService.fieldSelect.next(null);
		}));

		this.subscriptions.add(this.builderService.fieldAdded.subscribe((i) => {
			this.selectField(i);
			this.saveStatus(i);
		}));

		this.subscriptions.add(this.builderService.fieldMoved.subscribe((i) => {
			this.saveStatus(i);
		}));

		this.subscriptions.add(this.builderService.fieldRemoved.subscribe((i) => {
			this.selectField(undefined);
			this.saveStatus(i);
		}));

		this.subscriptions.add(this.builderService.fieldEdit.subscribe((i) => {
			this.saveStatus(i);
			this.builderService.fieldEdited.next(i);
		}));

		this.subscriptions.add(this.builderService.fieldSelected.subscribe(() => {
			this.builderService.memento.setLastAtomic();
		}));

		this.subscriptions.add(this.builderService.fieldReady.subscribe((i) => {
			if (this.lastInserted === i.subject) {
				this.selectField(i);
				this.lastInserted = undefined;
			}
		}));
	}

	override selectField(i: BuilderEventInfo = { subject: null, atomic: false }) {
		this.selected = i.subject;
		this.builderService.fieldSelected.next(i);
	}

	override removeField(i?: BuilderEventInfo) {
		// Apply a request to remove a field
		if (!i) {
			console.warn('removeField - BuilderEventInfo null!');

			return;
		}
		const position = this.getFieldPosition(i.subject);

		if (position) {
			position.parent[this.builderService.childrenProperty].splice(position.index, 1);
			this.builderService.fieldRemoved.next(i);
		}
	}

	canDrop = async(element: any): Promise<boolean> => {

		if (!element.type || (element.type && (FieldType as any)[element.type] == null)) {
			// Not a field (element from right panel like Validator, Option....)
			return false;
		}

		if (!element.reference) {
			// An actual field and not it's reference from the left tools list
			return true;
		}

		// LinkList (select a single collection)
		if (element.type === FieldType.LinkList) {

			const data: LinkSearchConfig = {
				title: 'Select Collection',
				multiSelect: false,
				minQuantitySelected: 1,
				ucProject: this.ucProject,
				type: LinkSearchType.Collection,
			};

			const result = await this.modalService.openMedium<ModalSearchData, any[]>(LinkSearchComponent, data);

			if (!result?.length) {
				return false;
			}

			element.field = {
				label: result[0].name,
				type: element.type,
				compoundType: CompoundType.Collection,
				definitionIdentifier: result[0].identifier,
			};

			return true;
		}

		// Link of type Collection (select a list of collections)
		if (element.type === FieldType.Link && element.compoundType === CompoundType.Collection) {

			const data: LinkSearchConfig = {
				title: 'Select Collections',
				multiSelect: true,
				ucProject: this.ucProject,
				type: LinkSearchType.Collection,
			};

			const result = await this.modalService.openMedium<ModalSearchData, any[]>(LinkSearchComponent, data);

			if (!result) {
				return false;
			}

			element.field = {
				label: element.label,
				type: element.type,
				compoundType: CompoundType.Collection,
			};

			if (result.length) {
				element.field.types = result.map((c) => c.identifier);
			}

			return true;
		}

		if ([FieldType.Text, FieldType.MultiText].includes(element.type)) {

			element.field = {
				type: element.type,
				isTranslatable: true,
			};

			return true;
		}

		// No dialog needed
		return true;
	};

	override back() {
		void this.router.navigate(['../../'], { relativeTo: this.route });
	}

	convertFieldRef(ref: any) {
		if (ref.field) {
			return ref.field;
		}

		const res: any = { type: ref.type };

		if (ref.compoundType) {
			res.compoundType = ref.compoundType;
			res.label = ref.label;
		}

		return res;
	}

	inserted(field: BuilderField) {
		// Update label
		field.isNew = true;
		if (!field.label) {
			field.label = IdentifierFunctions.generateDisplayLabel(field.type);
		}
		field.identifier = IdentifierFunctions.safeFieldIdentifier(
			field,
			this.builderService.definition.fields,
			field.definitionIdentifier ?? field.type,
		);
		this.lastInserted = field;
		this.builderService.fieldAdded.next({ subject: field, atomic: true });
	}

	moved(field: BuilderField) {
		this.builderService.fieldMoved.next({ subject: field, atomic: true });
	}

	restore(step: number) {
		const value = step < 0 ? this.builderService.memento.undo() : this.builderService.memento.redo();

		this.builderService.definition = value;
		this.builderService.fieldSelect.next(null);
	}

	async save(saveOption?: SaveOption) {

		this.builderService.submit.next(null);

		if (!this.builderService.isValid()) {
			this.builderService.markFieldsSubmitted();
			this.builderHeaderService.notify.next({ level: MessageLevel.Error, title: 'Error', message: 'Unable to save. There are errors in your Collection.' });

			return;
		}

		const cleanDefinition = this.builderService.cleanDefinition(this.builderService.definition);

		// Save the collection
		this.builderService.busy.next(true);

		try {
			const definition = await this.ucProject.saveCollection(cleanDefinition);

			this.builderService.memento.edited = false;

			if (this.collectionService) {
				// Update collection service if builder used in tabs
				this.collectionService.definition = definition;
				if (this.editMode === EditMode.Existing) {
					this.collectionService.edited = false;
				}
			}

			// Save the id for dirty check directive
			this.toastService.success('Collection saved!');

			if (saveOption?.id) {
				// Other builders have more than one option
				this.back();
			}

			if (!saveOption) {
				const paths = ['../../', definition.identifier];

				if (cleanDefinition.id) {
					paths.push('definition');
				}
				void this.router.navigate(paths, { relativeTo: this.route });
			}

			// Need to refresh CollectionsComponent list cause
			// a) a new collection has been added
			// b) a collection has been edited in its id and/or label
			// Need to refresh CollectionComponent list cause on definition changed
			// All the collection items are set back to Draft

			this.definition = definition as Definition;
			this.saved({ ...this.definition, name: this.definition.label }, saveOption);
			this.builderService.init(this, this.definition);
			this.buildHeaderConfig(definition);
			this.titleService.updateTitle(definition.consoleName);
		} catch (error) {
			this.builderHeaderService.notify.next({ level: MessageLevel.Error, title: 'Error', message: (error as any)?.data?.Message ?? (error as any).message });
			/*
            console.warn('Communication error custom handled');
            const message = err.message || (err.data && err.data.message) ? err.data.message : 'Oops... something went wrong with saving your form';
            */
		} finally {
			this.builderService.busy.next(false);
		}
	}

	private async load(): Promise<UcDefinition> {

		if (this.collectionService) {
			// TODO Move it to a resolver
			await this.collectionService.definitionLoadPromise;

			return this.collectionService.definition;
		}

		return {
			consoleName: '',
			label: '',
			identifier: '',
			compoundType: CompoundType.Collection,
			fields: [],
			version: 0,
			bucket: '',
			settings: {},
		};
	}

	private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
		this.builderService.memento.save(this.builderService.definition, i.atomic);
		this.builderService.memento.edited = true;
		if (this.editMode === EditMode.Existing && this.collectionService) {
			this.collectionService.edited = true;
		}

		if (this.ready) {
			this.builderHeaderService.config.edited = true;
		}

		this.refreshPreviewDefinition.next();
	}

	private buildHeaderConfig(definition: UcDefinition) {
		this.builderHeaderService.buildConfig({
			...this.removeFieldsNotUsed(definition),
			title: definition.consoleName,
			breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [this.mapContentItemName(definition.consoleName)]),
			saveOptions: this.saveOptions,
			cancelRoute: ['../../'],
		});
	}

	private removeFieldsNotUsed(definition: UcDefinition): UcDefinition {
		const amendedDefinition = JSON.parse(JSON.stringify(definition)) as UcDefinition;

		delete amendedDefinition.lastPublishedAt;
		delete amendedDefinition.lastPublishedBy;
		delete amendedDefinition.publishState;

		return amendedDefinition;
	}

}
