"use strict";

exports.getDefaultOptions = getDefaultOptions;
exports.setDefaultOptions = setDefaultOptions;
let defaultOptions = {};
function getDefaultOptions() {
  return defaultOptions;
}
function setDefaultOptions(newOptions) {
  defaultOptions = newOptions;
}