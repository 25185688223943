@if (revisionOptions && !error) {
	<uf-panel class="container gray-background">
		<div class="header-pane uf-app-bar uf-box flat">
			<uf-breadcrumbs [breadcrumbs]="breadcrumbs" />
			@if (revisionOptions.length > 1) {
				<div class="fieldset-item--small--stacked pad-sides right">
					<span>Revision</span>
					<uf-select [options]="revisionOptions" [(value)]="selectedOption" (valueChange)="onSelectedRevision($event.identifier)" />
				</div>
			}
			@if (runtimeDefinition && formData) {
				@if (revisionOptions.length < 2) {
					<div class="fieldset-item--small--stacked right">
						<span>Status:</span>
						<span>{{ formData._state }}</span>
					</div>
				}
				@if (formData._result) {
					<div class="fieldset-item--small--stacked">
						<span>Result:</span>
						<span>{{ formData._result }}</span>
					</div>
				}
				<button (click)="showMetadata()" title="Metadata" type="button" class="uf-action">
					<uf-icon name="info" />
				</button>
				<ng-template ufFeatureFlag="pdfRendering">
					<a (click)="openPDF()" title="Pdf" class="uf-action">
						<uf-icon name="pdf" />
					</a>
				</ng-template>
			}
		</div>
		<div class="content-pane">
			<uf-panel class="container">
				<div class="uf-container-lg">
					<div class="uf-box flat">
						<div class="uf-grid">
							@if (runtimeDefinition && formData) {
								<uf-form [definition]="runtimeDefinition" [formData]="formData" [disabled]="true" class="col-12" />
							}
						</div>
					</div>
				</div>
			</uf-panel>
		</div>
	</uf-panel>
}

<uc-error-message [error]="error" />
