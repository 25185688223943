import { Component, HostBinding, inject } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { ModalService, ToastService } from '@unifii/library/common';
import { ensureError } from '@unifii/sdk';

import { UcRole, UcRoles } from 'client';

import { RoleModalComponent } from './role-modal.component';
import { RolesTableManager } from './roles-table-manager';

export interface RoleModalConfig {
	isNewRole?: boolean;
	role?: UcRole;
}

@Component({
	selector: 'uc-roles',
	template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="addRole()" ufSyncRoute class="accent list-md pad-none"/>
        </div>
        <router-outlet/>
    `,
	providers: [
		{ provide: TableContainerManager, useClass: RolesTableManager },
	],
	standalone: false,
})
export class RolesComponent {

	@HostBinding('class.stretch-component') stretchComponentClass = true;

	private ucRoles = inject(UcRoles);
	private modalService = inject(ModalService);
	private toastService = inject(ToastService);
	private tableManager = inject<RolesTableManager>(TableContainerManager);

	async addRole() {
		const newRole = await this.modalService.openMedium(RoleModalComponent, {
			isNewRole: true,
		});

		if (!newRole) {
			return;
		}

		try {
			await this.ucRoles.save({ name: newRole.name, label: newRole.label, description: newRole.description });
			this.toastService.success('Role added');
			this.tableManager.reload.next();
		} catch (error) {
			this.toastService.error(ensureError(error).message);
		}
	}

}
