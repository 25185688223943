import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';
import { UfError, isUfError } from '@unifii/sdk';

import { SystemRole } from 'client';
import { DirectoryItem } from 'pages/page-models';
import { projectResolver } from 'resolvers/project-resolver';
import { BreadcrumbService } from 'services/breadcrumb.service';

@Component({
	selector: 'uc-project-overview',
	templateUrl: './project-overview.html',
	styleUrls: ['project-overview.less'],
	standalone: false,
})
export class ProjectOverviewComponent implements OnInit {

	@HostBinding('class.stretch-component') class = true;

	protected error: UfError | undefined;
	protected breadcrumbs: Breadcrumb[];
	protected items: DirectoryItem[];

	private breadcrumbService = inject(BreadcrumbService);
	private route = inject(ActivatedRoute);

	ngOnInit() {
		const resolverData = this.route.snapshot.data.project as Awaited<ReturnType<typeof projectResolver>>;

		if (isUfError(resolverData)) {
			this.error = resolverData;

			return;
		}

		this.items = [{
			label: 'Publish',
			route: ['publish'],
			roles: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.FormDesigner, SystemRole.Publisher],
			image: '/assets/svg/new-icons/publish.svg',
		}, {
			label: 'Project Settings',
			route: ['settings/general'],
			roles: [SystemRole.ProjectManager],
			image: '/assets/svg/new-icons/project-settings.svg',
		}, {
			label: 'Structure',
			route: ['structure'],
			roles: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.Publisher],
			image: '/assets/svg/new-icons/structure.svg',
		}, {
			label: 'Tables',
			route: ['tables'],
			roles: [SystemRole.ProjectManager, SystemRole.FormDesigner],
			image: '/assets/svg/new-icons/table.svg',
		}, {
			label: 'Forms',
			route: ['forms'],
			roles: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.FormDesigner, SystemRole.Publisher],
			image: '/assets/svg/new-icons/forms.svg',
		}, {
			label: 'Form Data Repositories',
			route: ['form-data'],
			roles: [SystemRole.FormDataViewer],
			image: '/assets/svg/new-icons/form-data-repo.svg',
		}, {
			label: 'Workflow Rules',
			route: ['workflows/rules'],
			roles: [SystemRole.WorkflowDesigner],
			image: '/assets/svg/new-icons/workflow-rules.svg',
		}, {
			label: 'Notifications',
			route: ['workflows/notifications'],
			roles: [SystemRole.WorkflowDesigner],
			image: '/assets/svg/new-icons/notifications.svg',
		}, {
			label: 'Timers',
			route: ['workflows/timers'],
			roles: [SystemRole.WorkflowDesigner],
			image: '/assets/svg/new-icons/timers.svg',
		},
		{
			label: 'Form Data',
			route: ['workflows/form-data'],
			roles: [SystemRole.WorkflowDesigner],
			image: '/assets/svg/new-icons/form-triggers.svg',
		},
		{
			label: 'Data Forwarders',
			route: ['workflows/data-forwarders'],
			roles: [SystemRole.WorkflowDesigner],
			image: '/assets/svg/new-icons/data-forwarders.svg',
		}, {
			label: 'Collections',
			route: ['content/collections'],
			roles: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.FormDesigner, SystemRole.Publisher],
			image: '/assets/svg/new-icons/collections.svg',
		}, {
			label: 'Views',
			route: ['content/views'],
			roles: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.FormDesigner, SystemRole.Publisher],
			image: '/assets/svg/new-icons/views.svg',
		}, {
			label: 'Pages',
			route: ['content/pages'],
			roles: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.FormDesigner, SystemRole.Publisher],
			image: '/assets/svg/new-icons/pages.svg',
		}, {
			label: 'Data Sources',
			route: ['settings/data-sources'],
			roles: [SystemRole.ProjectManager],
			image: '/assets/svg/new-icons/data-sources.svg',
		}, {
			label: 'Data Transactions',
			route: ['settings/data-transactions'],
			roles: [SystemRole.ProjectManager],
			image: '/assets/svg/new-icons/data-sources.svg',
		}, {
			label: 'Theming',
			route: ['settings/theming'],
			roles: [SystemRole.ProjectManager],
			image: '/assets/svg/new-icons/project-theming.svg',
		}];

		this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);
	}

}
