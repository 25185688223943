@if (ready && page && !error) {
	<uc-builder-header />
	<div class="builder-content scrollable">
		@if (contentSettings.canEditDefinition) {
			<uc-field-reference-picker />
		}
		<div class="builder-growing-section">
			<div #fieldlist class="field-list uf-box flat">
				<uc-builder-fields-header>
					@if (page.fields.length) {
						<uc-expander-controls [container]="fieldlist" [class.primary]="!selectedField" />
					}
				</uc-builder-fields-header>
				<div [entries]="page.fields" [current]="selectedField" (next)="selectField({ subject: $event })" keySelect class="nodes-container scrollable grow">
					<uc-drag-list [items]="page.fields" [canDrop]="canDrop" [convertAdded]="convertFieldRef" (insert)="inserted($event.item)" (moved)="moved($event.item)" class="scrollable">
						@for (field of page.fields; track field; let i = $index) {
							<uc-content-field [field]="field" [(content)]="field.value" dragItem class="col-1of1--no-margin--no-padding" />
						}
						@if (!page.fields.length) {
							<div class="empty">
								<div>
									<img src="/assets/svg/icon-projects.svg" />
									<p>Drag your items here!</p>
								</div>
							</div>
						}
					</uc-drag-list>
				</div>
			</div>
			<div class="field-settings">
				<uf-tabs class="scrollable">
					<uf-tab label="Details">
						<div class="tab-content scrollable uf-box flat">
							@if (!selectedField) {
								<uc-page-settings [page]="page" />
							}
							@if (selectedField) {
								<uc-field-builder [field]="selectedField" />
							}
						</div>
					</uf-tab>
					@if (!selectedField) {
						<uf-tab label="Preview">
							<div class="tab-content scrollable uf-box flat">
								@if (displayPage) {
									<uf-page [page]="displayPage" [classList]="['grid', 'body-copy']" />
								}
							</div>
						</uf-tab>
					}
				</uf-tabs>
			</div>
		</div>
	</div>
}

<uc-error-message [error]="error" />
