export enum PermissionControlKeys {
	Id = 'id',
	Description = 'description',
	Steps = 'steps',
	Actions = 'actions',
	EditFields = 'editFields',
	LockedFields = 'lockedFields',
	ReadFields = 'readFields',
	DeniedFields = 'deniedFields',
}

export enum PermissionActionControlKeys {
	Name = 'name',
	Selected = 'selected',
	Descriptions = 'descriptions',
	Condition = 'condition',
	AllowsCondition = 'allowsCondition',
	AllowsReadFields = 'allowsReadFields',
	AllowsEditFields = 'allowsEditFields',
}

export enum PermissionStepControlKeys {
	Segment = 'segment',
	Loader = 'loader',
	Resource = 'resource',
}
