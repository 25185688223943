import { Dictionary, Field, FieldType, HierarchyUnitSelectionMode, LayoutDirection, Option, ValidatorType, generateUUID } from '@unifii/sdk';

import { ItemPickerGroup } from 'components';

import { DefinitionControlKeys, FieldControlKeys } from './form-editor-control-keys';
import { ValidatorInfo } from './form-editor-model';

const DEFINITION_SCOPE_UUID = generateUUID();

const DEFINITION_IDENTIFIER_REGEX = /^[A-Za-z0-9_-]+$/;

const DEFINITION_BUCKET_REGEX = /^[A-Za-z0-9_-]+$/;

const FIELD_IDENTIFIER_MAX_LENGTH_LEGACY_LIMIT = 150;

const FIELD_IDENTIFIER_MAX_LENGTH = 60;

const FIELD_IDENTIFIER_WARNING_LENGTH = 32;

const FIELD_RENDERED_LABEL_MAX_LENGTH = 100;

const FIELD_IDENTIFIER_REGEX = /^(?!(false|true|and|not|or|in|null)$)[A-Za-z][A-Za-z0-9_]*$/;

const FIELD_OPTION_IDENTIFIER_REGEX = /^[A-Za-z0-9][A-Za-z0-9_-]*$/;

const FIELD_TRANSITION_STATUS_REGEX = /^[A-Za-z0-9][A-Za-z0-9_]*$/;

const INPUT_DEBOUNCE_SHORT = 100;

const INPUT_DEBOUNCE_NETWORK_CALL = 500;

const MAX_COLUMNS_FOR_IMAGE_CHOICE = 4;

const ITEM_PICKER_INFO_MARKER = 'FormEditorItemPickerInfo';

const PICKER_GROUPS: ItemPickerGroup<Field>[] = [{
	title: 'Form Structure',
	items: [
		{ id: FieldType.Section, label: 'Section', icon: 'section' },
		{ id: FieldType.Group, label: 'Group', icon: 'group' },
		{ id: FieldType.Stepper, label: 'Stepper', icon: 'stepper' },
		{ id: FieldType.Repeat, label: 'Repeating Group', icon: 'groupRepeat' },
		{ id: FieldType.ActionGroup, label: 'Action Group', icon: 'groupAction' },
		{ id: FieldType.Survey, label: 'Survey Group', icon: 'survey' },
		{ id: FieldType.Separator, label: 'Separator', icon: 'separator' },
		{ id: FieldType.Carousel, label: 'Content Carousel', icon: 'carousel' },
		{ id: FieldType.Content, label: 'Content', icon: 'content' },
	],
}, {
	title: 'Entry Fields',
	items: [
		{ id: FieldType.Text, label: 'Short Text', icon: 'text' },
		{ id: FieldType.MultiText, label: 'Long Text', icon: 'multiText' },
		{ id: FieldType.Number, label: 'Number', icon: 'number' },
		{ id: FieldType.Date, label: 'Date', icon: 'date' },
		{ id: FieldType.Time, label: 'Time', icon: 'time' },
		{ id: FieldType.DateTime, label: 'Date & Time', icon: 'dateTime' },
		{ id: FieldType.ZonedDateTime, label: 'Date, Time & Time Zone', icon: 'zonedDateTime' },
		{ id: FieldType.Phone, label: 'Phone', icon: 'telephone' },
		{ id: FieldType.Email, label: 'Email', icon: 'mail' },
		{ id: FieldType.Website, label: 'Website', icon: 'earth' },
		{ id: FieldType.Cost, label: 'Currency', icon: 'cost' },
	],
}, {
	title: 'Choice Fields',
	items: [
		{ id: FieldType.Lookup, label: 'Look Up', icon: 'lookup' },
		{ id: FieldType.Bool, label: 'Bool', icon: 'bool' },
		{ id: FieldType.Choice, label: 'Choice', icon: 'singleChoice' },
		{ id: FieldType.MultiChoice, label: 'Multi Choice', icon: 'multiChoice' },
		{ id: FieldType.Hierarchy, label: 'Hierarchy', icon: 'hierarchy' },
	],
}, {
	title: 'Import',
	items: [
		{ id: FieldType.ImageList, label: 'Image List', icon: 'imageList' },
		{ id: FieldType.FileList, label: 'File List', icon: 'fileList' },
		{ id: FieldType.Link, label: 'Content Link', icon: 'link' },
	],
}, {
	title: 'Advanced Fields',
	items: [
		{ id: FieldType.Address, label: 'Address', icon: 'address' },
		{ id: FieldType.GeoLocation, label: 'Geo Location', icon: 'geoLocation' },
		{ id: FieldType.Signature, label: 'Signature', icon: 'signature' },
	],
}];

const CURRENCIES: Option[] = [
	{ identifier: 'AUD', name: 'AUD - Australian dollar' },
	{ identifier: 'CAD', name: 'CAD - Canadian dollar' },
	{ identifier: 'HKD', name: 'HKD - Hong Kong dollar' },
	{ identifier: 'NZD', name: 'NZD - New Zealand dollar' },
	{ identifier: 'SGD', name: 'SGD - Singapore dollar' },
	{ identifier: 'USD', name: 'USD - United States dollar' },
];

const SCROLL_TIMES: { name: string; value: number | '' }[] = [
	{ name: 'Manual', value: '' },
	{ name: '3 seconds', value: 3000 },
	{ name: '5 seconds', value: 5000 },
	{ name: '10 seconds', value: 10000 },
	{ name: '15 seconds', value: 15000 },
	{ name: '30 seconds', value: 30000 },
];

const COLUMN_COUNT_OPTIONS: Option[] = [
	{ identifier: 1 as unknown as string, name: '1' },
	{ identifier: 2 as unknown as string, name: '2' },
	{ identifier: 3 as unknown as string, name: '3' },
	{ identifier: 4 as unknown as string, name: '4' },
	{ identifier: 5 as unknown as string, name: '5' },
	{ identifier: 8 as unknown as string, name: '8' },
];

const LAYOUT_DIRECTION_OPTIONS: Option[] = [
	{ identifier: LayoutDirection.Row, name: 'Row' },
	{ identifier: LayoutDirection.Column, name: 'Column' },
];

const DATE_FORMATS: string[] = [
	'DD/MM/YYYY', // Default
	'EEE DD/MM/YYYY',
	'EEEE DD/MM/YYYY',
	'D/M/YYYY',
	'MM/DD/YYYY',
	'M/D/YYYY',
	'D MMM YYYY',
	'D MMMM YYYY',
];

const TIME_FORMATS: string[] = [
	'h:mm a', // Default
	'hh:mm a',
	'HH:mm',
];

const TIME_STEP_VALUES: Option[] = [
	{ identifier: '60', name: '1 minute' },
	{ identifier: '120', name: '2 minutes' },
	{ identifier: '180', name: '3 minutes' },
	{ identifier: '240', name: '4 minutes' },
	{ identifier: '300', name: '5 minutes' },
	{ identifier: '600', name: '10 minutes' },
	{ identifier: '720', name: '12 minutes' },
	{ identifier: '900', name: '15 minutes' },
	{ identifier: '1200', name: '20 minutes' },
	{ identifier: '1800', name: '30 minutes' },
	{ identifier: '3600', name: '60 minutes' },
];

const HIERARCHY_SELECTION_MODE_OPTIONS: Option[] = [
	{ identifier: HierarchyUnitSelectionMode.Leaf, name: 'Leaf units only' },
	{ identifier: HierarchyUnitSelectionMode.Any, name: 'Any unit' },
];

const VALIDATORS_INFO: Dictionary<ValidatorInfo> = {
	[ValidatorType.Pattern]: { valueLabel: 'Pattern', valuePlaceholder: '^[A-Za-z]$', type: ValidatorType.Pattern },
	[ValidatorType.MinLength]: { typeLabel: 'Minimum Length', valueLabel: 'Minimum length', type: ValidatorType.MinLength },
	[ValidatorType.Min]: { typeLabel: 'Minimum Value', valueLabel: 'Minimum value', type: ValidatorType.Min },
	[ValidatorType.Max]: { typeLabel: 'Maximum Value', valueLabel: 'Maximum value', type: ValidatorType.Max },
	[ValidatorType.Expression]: { valueLabel: 'Expression', valuePlaceholder: '$self > 10', messagePlaceholder: 'Value needs to be greater than 10', type: ValidatorType.Expression },
	[ValidatorType.ItemExpression]: { valueLabel: 'Item expression', valuePlaceholder: '$item.value > 10', messagePlaceholder: 'Item value needs to be greater than 10', type: ValidatorType.ItemExpression },
};

const SECTION_FORM: DefinitionControlKeys[] = [
	DefinitionControlKeys.Label, DefinitionControlKeys.Identifier, DefinitionControlKeys.SequenceNumberFormat, DefinitionControlKeys.Settings,
	DefinitionControlKeys.Tags, DefinitionControlKeys.ReportableMetaFields,
];

const SECTION_FORM_DATA_REPOSITORY: DefinitionControlKeys[] = [
	DefinitionControlKeys.Bucket, DefinitionControlKeys.HasRollingVersion,
];

const SECTION_DETAILS_FIELDS: FieldControlKeys[] = [
	FieldControlKeys.Help, FieldControlKeys.Label, FieldControlKeys.ShortLabel, FieldControlKeys.Identifier, FieldControlKeys.Template, FieldControlKeys.ActiveBackgroundTinted, FieldControlKeys.AlwaysExpanded, FieldControlKeys.ScrollTime, FieldControlKeys.ExpandWhenInactive, FieldControlKeys.HideWhenInactive, FieldControlKeys.Roles,
];

const SECTION_WORKFLOW_FIELDS: FieldControlKeys[] = [
	FieldControlKeys.Transitions,
];

const SECTION_SETTINGS_FIELDS: FieldControlKeys[] = [
	FieldControlKeys.IsRequired, FieldControlKeys.IsReadOnly, FieldControlKeys.AutoDetect, FieldControlKeys.Currency,
	FieldControlKeys.Autofill, FieldControlKeys.BindTo, FieldControlKeys.ShowIf, FieldControlKeys.ShowOn, FieldControlKeys.Precision,
	FieldControlKeys.MaxLength, FieldControlKeys.Step, FieldControlKeys.Format, FieldControlKeys.HierarchyConfig,
];

const SECTION_OPTIONS_FIELDS: FieldControlKeys[] = [
	FieldControlKeys.Options, FieldControlKeys.DataSourceConfig,
];

const SECTION_NESTED_FIELDS_FIELDS: FieldControlKeys[] = [
	FieldControlKeys.AddressAutocomplete, FieldControlKeys.AddressNested, FieldControlKeys.GeoLocationNested,
];

const SECTION_DISPLAY_FIELDS: FieldControlKeys[] = [
	FieldControlKeys.Help, FieldControlKeys.Placeholder, FieldControlKeys.ItemLabel, FieldControlKeys.AddButtonLabel,
	FieldControlKeys.ColumnVisibility, FieldControlKeys.Width, FieldControlKeys.BreakAfter, FieldControlKeys.ColumnCount,
	FieldControlKeys.LayoutDirection, FieldControlKeys.VisibleTo,
];

const SECTION_VARIATIONS_FIELDS: FieldControlKeys[] = [
	FieldControlKeys.Variations,
];

const SECTION_VALIDATORS_FIELDS: FieldControlKeys[] = [
	FieldControlKeys.Validators,
];

const SECTION_ADVANCED_FIELDS: FieldControlKeys[] = [
	FieldControlKeys.DataCaptures, FieldControlKeys.Tags, FieldControlKeys.AllowedTypes,
];

const REPORTABLE_METADATA_FIELDS = [
	'_definitionIdentifier',
	'_definitionVersion',
	'_openedAt',
	'_completedAt',
	'_state',
	'_action',
	'_result',
	'_seqId',
	'_createdAt',
	'_createdBy',
	'_lastModifiedAt',
	'_lastModifiedBy',
	'_link',
	'_parent.bucket',
	'_parent.id',
];

const REPEAT_SORTABLE_FIELD_TYPES: FieldType[] = [
	FieldType.Number, FieldType.Date, FieldType.DateTime, FieldType.ZonedDateTime,
];

export const FORM_EDITOR_CONSTANTS = {
	DEFINITION_SCOPE_UUID,
	DEFINITION_IDENTIFIER_REGEX,
	DEFINITION_BUCKET_REGEX,
	FIELD_IDENTIFIER_MAX_LENGTH_LEGACY_LIMIT,
	FIELD_IDENTIFIER_MAX_LENGTH,
	FIELD_IDENTIFIER_WARNING_LENGTH,
	FIELD_RENDERED_LABEL_MAX_LENGTH,
	FIELD_IDENTIFIER_REGEX,
	FIELD_OPTION_IDENTIFIER_REGEX,
	FIELD_TRANSITION_STATUS_REGEX,
	INPUT_DEBOUNCE_SHORT,
	INPUT_DEBOUNCE_NETWORK_CALL,
	ITEM_PICKER_INFO_MARKER,
	MAX_COLUMNS_FOR_IMAGE_CHOICE,
	PICKER_GROUPS,
	CURRENCIES,
	SCROLL_TIMES,
	COLUMN_COUNT_OPTIONS,
	LAYOUT_DIRECTION_OPTIONS,
	DATE_FORMATS,
	TIME_FORMATS,
	TIME_STEP_VALUES,
	HIERARCHY_SELECTION_MODE_OPTIONS,
	VALIDATORS_INFO,
	SECTION_FORM,
	SECTION_FORM_DATA_REPOSITORY,
	SECTION_DETAILS_FIELDS,
	SECTION_WORKFLOW_FIELDS,
	SECTION_SETTINGS_FIELDS,
	SECTION_OPTIONS_FIELDS,
	SECTION_NESTED_FIELDS_FIELDS,
	SECTION_DISPLAY_FIELDS,
	SECTION_VARIATIONS_FIELDS,
	SECTION_VALIDATORS_FIELDS,
	SECTION_ADVANCED_FIELDS,
	REPORTABLE_METADATA_FIELDS,
	REPEAT_SORTABLE_FIELD_TYPES,
};
