import { ClientDeleteOptions, ClientGetOptions, ClientPostOptions, amendOptionsParams, mergeParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { CompoundInfo, UcCompound, UcDefinition } from './content-models';
import { UcClient } from './uc-client';

interface CollectionGetParams {
	q?: string;
	sort?: string;
	status?: string;
	offset?: number;
	limit?: number;
	full?: boolean;
	ids?: string;
}

export class UcCollection {

	constructor(
		private client: UcClient,
		private projectId: number,
		private collectionIdentifier: string,
	) { }

	get(params?: CollectionGetParams, options?: ClientGetOptions): Promise<CompoundInfo[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, params as Record<string, unknown>);

		return this.client.get(this.url(), amendOptionsParams(defaultedParams, options)) as Promise<CompoundInfo[]>;
	}

	getItem(id: number, options?: ClientGetOptions): Promise<UcCompound> {
		return this.client.get(this.url(id), options) as Promise<UcCompound>;
	}

	saveItem(compound: UcCompound): Promise<UcCompound> {
		if (compound.id) {
			return this.client.put(this.url(compound.id), compound) as Promise<UcCompound>;
		} else {
			return this.client.post(this.url(), { body: compound }) as Promise<UcCompound>;
		}
	}

	getDefinition(options?: ClientGetOptions): Promise<UcDefinition> {
		return this.client.get(this.url('definition'), options) as Promise<UcDefinition>;
	}

	approveCollectionItem(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
		return this.approveCollectionItems([id], options).then((collectionItems) => collectionItems[0]) as Promise<CompoundInfo>;
	}

	approveCollectionItems(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
		return this.client.post(this.url('approved'), amendOptionsParams({ ids }, options)) as Promise<CompoundInfo[]>;
	}

	revertCollectionItems(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
		return this.client.post(this.url('unapproved'), amendOptionsParams({ ids }, options)) as Promise<CompoundInfo[]>;
	}

	archiveCollectionItems(ids: number[], options?: ClientPostOptions): Promise<CompoundInfo[]> {
		return this.client.post(this.url('archived'), amendOptionsParams({ ids }, options)) as Promise<CompoundInfo[]>;
	}

	deleteCollectionItem(id: number, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url(id), options) as Promise<void>;
	}

	private url(...extra: (string | number)[]): string {
		const urlParts = ['projects', this.projectId, 'collections', this.collectionIdentifier].concat(extra);

		return this.client.buildUrl(urlParts);
	}

}
