@if (ready) {
	<uf-expander class="uf-box flat">
		<div expanderHeader class="uf-app-bar flat">
			<div class="title primary">Advanced</div>
			@if (isInvalid) {
				<uf-icon name="error" class="error" />
			}
		</div>
		<div [formGroup]="control" expanderBody class="uf-grid pad-sides pad-sm-top pad-bottom">
			@if (meta.dataCaptures && !control.get(fieldKeys.DataCaptures)?.disabled) {
				<uf-multi-choice [formControlName]="fieldKeys.DataCaptures" [options]="dataCaptureTypes" label="Data Capture" valueProperty="identifier" />
			}
			@if (meta.allowedTypes) {
				<uf-multi-choice [formControlName]="fieldKeys.AllowedTypes" [options]="linkAllowedTypes" label="Allowed Types" valueProperty="identifier" />
			}
			@if (meta.tags) {
				<uf-chips [formControlName]="fieldKeys.Tags" [allowCustom]="true" [options]="tagsResult" (searchChange)="searchTags($event)" label="Tags" class="col-12" />
			}
		</div>
	</uf-expander>
}
