<ng-template #headerButtons>
	<button (click)="resetForm()" type="button" class="uf-button tertiary right">Reset</button>
	<button (click)="send()" type="button" class="uf-button primary">Send</button>
</ng-template>
<uf-panel class="content-pane container">
	<div class="uf-container-lg">
		<div class="uf-grid row-gap-lg pad-top">
			<div class="uf-box col-12">
				<div class="uf-app-bar flat accent">
					<div class="title">Target Devices</div>
					@if (selectedDevices.length) {
						<button (click)="resetDevices()" title="Clear" type="button" class="uf-action tertiary">
							<uf-icon name="backspace" />
						</button>
					}
				</div>

				<div class="uf-grid pad">
					<ul class="uc-list col-12">
						@for (device of selectedDevices; track device; let i = $index) {
							<li class="uc-list-item--bottom-border--small row center-all">
								{{ device.name }} - {{ device.model }} - {{ device.id }}
								<button (click)="selectedDevices.splice(i, 1)" title="Delete" type="button" class="uf-action tertiary right">
									<uf-icon name="delete" />
								</button>
							</li>
						}
					</ul>
					<div class="col-12">
						<button (click)="addDevices()" type="button" class="uf-button right">Add Devices</button>
					</div>
				</div>
			</div>

			<div class="uf-box col-12">
				<div class="uf-app-bar flat accent">
					<div class="title">Target Users</div>
					@if (roles?.length || (userAstNode.args?.length ?? 0 > 1)) {
						<button (click)="resetUsers()" title="Clear" type="button" class="uf-action tertiary">
							<uf-icon name="backspace" />
						</button>
					}
				</div>

				<div class="uf-grid pad">
					<ul class="uc-list col-12">
						@for (role of roles; track role; let i = $index) {
							<li class="uc-list-item--bottom-border--small row center-all">
								<div class="fieldset-item--small grow">
									<span>Role:</span>
									<span>{{ role }}</span>
								</div>
								<button (click)="roles?.splice(i, 1)" title="Delete" type="button" class="uf-action tertiary right">
									<uf-icon name="delete" />
								</button>
							</li>
						}
						@for (claim of userAstNode.args; track claim; let i = $index) {
							@if (i > 0) {
								<li class="uc-list-item--bottom-border--small row center-all">
									<div class="fieldset-item--small grow">
										<span>Claim Type:</span>
										<span>{{ $any(claim.args)[0].value }}</span>
									</div>
									<div class="fieldset-item--small grow">
										<span>Claim Type:</span>
										<span>{{ $any(claim.args)[1].value }}</span>
									</div>
									<button (click)="userAstNode.args?.splice(i, 1)" title="Delete" type="button" class="uf-action tertiary right">
										<uf-icon name="delete" />
									</button>
								</li>
							}
						}
					</ul>
					<div class="col-12">
						<button (click)="openUserQueryBuilder()" type="button" class="uf-button right">Add Roles/Claims</button>
					</div>
				</div>
			</div>

			<!-- Message Data -->
			<div class="uf-box col-12">
				<div class="uf-app-bar flat accent">
					<div class="title">Message Data</div>
					<button (click)="messageControl.reset()" title="Clear" type="button" class="uf-action tertiary">
						<uf-icon name="backspace" />
					</button>
				</div>
				<div class="uf-grid pad">
					@if (messageControl.controls.title) {
						<uf-text [control]="messageControl.controls.title | asUfControl" [(value)]="notification.title" label="Title" class="col-12" />
					}
					@if (messageControl.controls.message) {
						<uf-textarea [control]="messageControl.controls.message | asUfControl" [(value)]="notification.message" label="Message" class="col-12" />
					}
				</div>
			</div>

			<!-- Routing -->
			<div class="uf-box col-12">
				<div class="uf-app-bar flat accent">
					<div class="title">Routing</div>
					<button (click)="routingControl.reset()" title="Clear" type="button" class="uf-action tertiary">
						<uf-icon name="backspace" />
					</button>
				</div>
				@if (notification.route) {
					<div class="uf-grid pad">
						@if (routingControl.controls.tenant) {
							<uf-text [control]="routingControl.controls.tenant | asUfControl" [(value)]="notification.route.tenant" label="Tenant" class="col-4" />
						}
						@if (routingControl.controls.projectId) {
							<uf-text [control]="routingControl.controls.projectId | asUfControl" [(value)]="notification.route.projectId" label="Project Id" class="col-4" />
						}
						@if (routingControl.controls.nodeId) {
							<uf-text [control]="routingControl.controls.nodeId | asUfControl" [(value)]="notification.route.nodeId" label="Node Id" class="col-4" />
						}
						@if (routingControl.controls.bucket) {
							<uf-text [control]="routingControl.controls.bucket | asUfControl" [(value)]="notification.route.bucket" label="Form Data Repository Id" class="col-6" />
						}
						@if (routingControl.controls.id) {
							<uf-text [control]="routingControl.controls.id | asUfControl" [(value)]="notification.route.id" label="Id" class="col-6" />
						}
					</div>
				}
			</div>
		</div>
	</div>
</uf-panel>
