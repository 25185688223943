"use strict";

exports.ISODayParser = void 0;
var _index = require("../../../setISODay.cjs");
var _Parser = require("../Parser.cjs");
var _utils = require("../utils.cjs");

// ISO day of week
class ISODayParser extends _Parser.Parser {
  priority = 90;
  parse(dateString, token, match) {
    const valueCallback = value => {
      if (value === 0) {
        return 7;
      }
      return value;
    };
    switch (token) {
      // 2
      case "i":
      case "ii":
        // 02
        return (0, _utils.parseNDigits)(token.length, dateString);
      // 2nd
      case "io":
        return match.ordinalNumber(dateString, {
          unit: "day"
        });
      // Tue
      case "iii":
        return (0, _utils.mapValue)(match.day(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.day(dateString, {
          width: "short",
          context: "formatting"
        }) || match.day(dateString, {
          width: "narrow",
          context: "formatting"
        }), valueCallback);
      // T
      case "iiiii":
        return (0, _utils.mapValue)(match.day(dateString, {
          width: "narrow",
          context: "formatting"
        }), valueCallback);
      // Tu
      case "iiiiii":
        return (0, _utils.mapValue)(match.day(dateString, {
          width: "short",
          context: "formatting"
        }) || match.day(dateString, {
          width: "narrow",
          context: "formatting"
        }), valueCallback);
      // Tuesday
      case "iiii":
      default:
        return (0, _utils.mapValue)(match.day(dateString, {
          width: "wide",
          context: "formatting"
        }) || match.day(dateString, {
          width: "abbreviated",
          context: "formatting"
        }) || match.day(dateString, {
          width: "short",
          context: "formatting"
        }) || match.day(dateString, {
          width: "narrow",
          context: "formatting"
        }), valueCallback);
    }
  }
  validate(_date, value) {
    return value >= 1 && value <= 7;
  }
  set(date, _flags, value) {
    date = (0, _index.setISODay)(date, value);
    date.setHours(0, 0, 0, 0);
    return date;
  }
  incompatibleTokens = ["y", "Y", "u", "q", "Q", "M", "L", "w", "d", "D", "E", "e", "c", "t", "T"];
}
exports.ISODayParser = ISODayParser;