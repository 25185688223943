export * from './data-forwarders.component';
export * from './workflow-notification';
export * from './workflow.component';
export * from './workflow-timers-form.component';
export * from './workflow-timers.component';
export * from './workflow-rules.component';
export * from './workflow-rule-form.component';
export * from './workflow-data-forwarders-form.component';
export * from './field-mapping-table.component';
export * from './workflow-form-data.component';
export * from './workflow-form-data-form.component';
export * from './workflow-functions';

import { DataForwardersComponent } from './data-forwarders.component';
import { FieldMappingTableComponent } from './field-mapping-table.component';
import { WorkflowDataForwardersFormComponent } from './workflow-data-forwarders-form.component';
import { WorkflowFormDataFormComponent } from './workflow-form-data-form.component';
import { WorkflowFormDataComponent } from './workflow-form-data.component';
import { WORKFLOW_NOTIFICATION_COMPONENTS } from './workflow-notification';
import { WorkflowRuleFormComponent } from './workflow-rule-form.component';
import { WorkflowRulesComponent } from './workflow-rules.component';
import { WorkflowTimersFormComponent } from './workflow-timers-form.component';
import { WorkflowTimersComponent } from './workflow-timers.component';
import { WorkflowComponent } from './workflow.component';

export const WORKFLOW_COMPONENTS = [
	WorkflowComponent,
	DataForwardersComponent,
	WorkflowDataForwardersFormComponent,
	WorkflowRuleFormComponent,
	WorkflowTimersFormComponent,
	WorkflowTimersComponent,
	WorkflowRulesComponent,
	FieldMappingTableComponent,
	WorkflowFormDataComponent,
	WorkflowFormDataFormComponent,
	WORKFLOW_NOTIFICATION_COMPONENTS,
];
