import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'uc-lab',
	templateUrl: './lab.html',
	standalone: false,
})
export class LabComponent {

	@HostBinding('class.stretch-component') class = true;

}
