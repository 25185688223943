@if (isNew && form) {
	<div class="header-pane uf-app-bar transparent">
		<uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
			@if (edited) {
				<span>*</span>
			}
		</uf-breadcrumbs>
		<div class="row space-children right">
			<a [routerLink]="['..']" type="button" class="uf-button tertiary"> Cancel </a>
			<button (click)="save()" type="button" class="uf-button primary small">Save</button>
		</div>
	</div>
}

@if (form) {
	<uf-panel class="content-pane container">
		@if (!loading) {
			<div [formGroup]="form" class="uf-container-lg">
				<div class="uf-grid row-gap-lg pad-top">
					<uc-user-details [form]="form" [lockedConfig]="lockedConfig" [error]="error" [isMe]="isMe" class="col-12" />
					<uc-user-manager [form]="form" cssClasses="gap-top" class="col-12" />
					<uc-user-mfa [form]="form" cssClasses="gap-top" class="col-12" />
					<uc-user-external-authentication [userInfo]="userInfo" [lockedConfig]="lockedConfig" [connectToExternal]="connectToExternal" [edited]="edited" cssClasses="gap-top" class="col-12" />
					<uc-user-hierarchy [form]="form" [lockedConfig]="lockedConfig" cssClasses="gap-top" class="col-12" />
					@if (tenant) {
						<uc-user-roles [lockedConfig]="lockedConfig" [form]="form" [name]="tenant.name" cssClasses="gap-top" class="col-12" />
					}
					<uc-user-system-roles [lockedConfig]="lockedConfig" [form]="form" cssClasses="gap-top" class="col-12" />
				</div>
			</div>
		}
	</uf-panel>
}

<ng-template #headerButtons>
	@if (!isNew && status === userStatus.Pending) {
		<button (click)="delete()" type="button" class="uf-button">Delete</button>
	}
</ng-template>
