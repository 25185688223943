import { Component, HostBinding, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';

export interface TypeSelectOption extends Record<string, any> {
	type: string;
	label: string;
	logo?: string;
	icon?: string;
}

export interface TypeSelectConfig {
	title: string;
	types: TypeSelectOption[];
}

/**
 * Modal component to make a general purpose selection of a 'type' element
 * The element is identified by a type and described by label and optional logo or icon
 */
@Component({
	selector: 'uc-type-select',
	templateUrl: './type-select.html',
	styleUrls: ['./type-select.less'],
	standalone: false,
})
export class TypeSelectComponent implements Modal<TypeSelectConfig, TypeSelectOption> {

	@HostBinding('class.uc-form-card') classes = true;
	data = inject<TypeSelectConfig>(ModalData);
	runtime = inject<ModalRuntime<TypeSelectConfig, TypeSelectOption>>(ModalRuntime);

	close(type?: TypeSelectOption) {
		this.runtime.close(type);
	}

}
