@if (ready) {
	<div class="uf-app-bar">
		<button (click)="close()" type="button" class="uf-action tertiary" title="Close">
			<uf-icon name="close" />
		</button>
		<h3>{{ isFormSettingsMode ? "Form Settings" : "Form Data Repository" }}</h3>
	</div>
	<div class="grow">
		<div [formGroup]="formGroup" class="uf-grid pad gaps">
			@if (isFormSettingsMode) {
				<uf-text [formControlName]="definitionKeys.Label" label="Title" class="col-12" />
				<uf-text [formControlName]="definitionKeys.ConsoleName" [label]="consoleNameLabel" class="col-12" />
				<uf-text [formControlName]="definitionKeys.Identifier" [maxLength]="identifierWarningLength" label="Identifier" class="col-12" />
				@if (showIdentifierWarning) {
					<uf-message icon="warningSolid" class="x-small warning col-12">
						<p>Identifier is too long</p>
					</uf-message>
				}
				<uf-text [formControlName]="definitionKeys.SequenceNumberFormat" label="Form Number Format" placeholder="ABC-######" class="col-12" />
			}
			<uf-autocomplete [formControlName]="definitionKeys.Bucket" [options]="bucketsResults" [allowCustom]="true" [debounce]="300" (searchChange)="searchBuckets($event)" label="Form Data Repository" class="col-12" />
			@if (showBucketWarning) {
				<uf-message icon="warningSolid" class="x-small warning col-12">
					<p>Form Data Repository is too long</p>
				</uf-message>
			}
			@if (showRollingVersion) {
				<uf-checkbox [formControlName]="definitionKeys.HasRollingVersion" label="Has Rolling Version" class="col-12" />
			}
		</div>
	</div>
	<div class="row space-children pad">
		<button (click)="close()" class="uf-button tertiary right" type="button">Cancel</button>
		<button (click)="submit()" class="uf-button primary" type="button">
			{{ isFormSettingsMode ? "Next" : "Save" }}
		</button>
	</div>
}
