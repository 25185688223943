"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapUserToUserContext = exports.getUserFullName = exports.mapClaimsToClaimsRecord = exports.getUserStatus = exports.getUserStatusInfoFlags = void 0;
const models_1 = require("../models");
const type_guard_functions_1 = require("./type-guard-functions");
/** Return the valorized user attributes that determine the user status provided in input */
const getUserStatusInfoFlags = status => {
  switch (status) {
    case models_1.UserStatus.Active:
      return {
        isActive: true
      };
    case models_1.UserStatus.Pending:
      return {
        isActive: false
      };
    case models_1.UserStatus.Inactive:
      return {
        isActive: false,
        lastActivationReason: ''
      };
  }
};
exports.getUserStatusInfoFlags = getUserStatusInfoFlags;
/**
 * Return the user status projection based on the user attributes provided in input
 */
exports.getUserStatus = userInfo => {
  if (!userInfo) {
    return undefined;
  }
  if (userInfo.isActive) {
    return models_1.UserStatus.Active;
  }
  return userInfo.lastActivationReason == null ? models_1.UserStatus.Pending : models_1.UserStatus.Inactive;
};
/** Map the input array of claims to its record format */
exports.mapClaimsToClaimsRecord = claims => claims?.reduce((claimDictionary, {
  type,
  value
}) => {
  let existing = claimDictionary[type];
  if (existing != null) {
    existing = Array.isArray(existing) ? existing : [existing];
    claimDictionary[type] = [...existing, value];
  } else {
    claimDictionary[type] = value;
  }
  return claimDictionary;
}, {});
/** Return the user fullName projection based on the user provided in input */
const getUserFullName = user => {
  const tokens = [user.firstName, user.lastName].filter(type_guard_functions_1.isStringTrimmedNotEmpty);
  return tokens.length ? tokens.join(' ') : null;
};
exports.getUserFullName = getUserFullName;
/** Return the UserContext, an extended version of UserInfo containing projection and transformation that make the entity compatible with the requirement for user expressions */
exports.mapUserToUserContext = user => {
  if (!user) {
    return;
  }
  return Object.assign({}, user, {
    claims: (0, exports.mapClaimsToClaimsRecord)(user.claims) ?? {},
    status: (0, exports.getUserStatus)(user),
    fullName: (0, exports.getUserFullName)(user)
  });
};
