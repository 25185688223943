@if (form && !error) {
	<div class="header-pane uf-app-bar transparent">
		<uc-builder-header>
			@if (form && !typeControl.disabled) {
				<button (click)="paste()" title="Paste" type="button" class="uf-button" header-buttons>Paste</button>
			}
		</uc-builder-header>
	</div>
}

@if (form && !error) {
	<uf-panel class="content-pane container">
		<div class="uf-container-lg">
			<div class="uf-grid row-gap-lg pad-top">
				@if (form) {
					<ng-container [formGroup]="form">
						<div class="col-12 uf-box">
							<div class="uf-app-bar accent">
								<div class="title">Details</div>
							</div>
							<div class="pad uf-grid">
								@if (!typeControl.disabled) {
									<uf-select [formControlName]="claimControlKeys.ValueType" [options]="valueTypeOptions" (valueChange)="valueTypeChange($event)" label="Type" valueProperty="identifier" class="col-12" />
									<uf-text [formControlName]="claimControlKeys.Label" (valueChange)="changeIdentifier($event)" label="Title" class="col-12" />
									<uf-text [formControlName]="claimControlKeys.Type" label="Identifier" class="col-12" />
								} @else {
									<uf-data-display-list class="col-12">
										<dt>Type</dt>
										<dd>{{ valueTypeControl.value }}</dd>
										<uf-text [formControlName]="claimControlKeys.Label" label="Title" class="col-12" />
										<dt>Identifier</dt>
										<dd>{{ typeControl.value }}</dd>
									</uf-data-display-list>
								}
								<uf-checkbox [formControlName]="claimControlKeys.IsRequired" label="Required" class="col-12" />
								@if (!searchableControl.disabled) {
									<uf-checkbox [formControlName]="claimControlKeys.Searchable" label="Searchable" class="col-12" />
								}
							</div>
						</div>
						@if (claim.valueType === fieldType.Choice || claim.valueType === fieldType.MultiChoice) {
							<div class="col-12 uf-box gap-top">
								<div class="uf-app-bar accent">
									<div class="title">Options</div>
								</div>
								@if (optionControls.length) {
									<uc-drag-list [items]="optionControls" canDrop="false" canReorder="true" class="pad">
										@for (option of optionControls; track option; let i = $index) {
											<div dragItem class="uf-box uf-list-fieldset flat draggable">
												<div [formGroup]="option" class="uf-grid pad">
													<uf-text [formControlName]="claimOptionControlKeys.Display" (valueChange)="optionLabelChange($event, option)" label="Label" class="col-6" />
													<uf-text [formControlName]="claimOptionControlKeys.Id" label="Identifier" class="col-6" />
												</div>
												<div class="uf-app-bar flat transparent">
													<button (click)="removeOption(i, option)" class="uf-action" type="button" title="Delete">
														<uf-icon name="delete" />
													</button>
												</div>
											</div>
										}
									</uc-drag-list>
								}
								<div class="row pad">
									<button (click)="addOption()" type="button" class="uf-button secondary right">Add Option</button>
								</div>
							</div>
						}
					</ng-container>
				}
			</div>
		</div>
	</uf-panel>
}

<uc-error-message [error]="error" />
