import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'uc-style-guide',
	templateUrl: './style-guide.html',
	styleUrls: ['./style-guide.less'],
	standalone: false,
})
export class StyleGuideComponent {

	@HostBinding('class.stretch-component') class = true;

	listItems: any = [
		{
			size: '--small',
		}, {
			size: '',
		}, {
			size: '--large',
		},
	];

	columns = Array.from({ length: 18 }, (v, i) => i);

	getColNumber(i: number) {

		if (i < 1) {
			return '1';
		}

		if (i < 3) {
			return '2';
		}

		if (i < 6) {
			return '3';
		}

		if (i < 10) {
			return '4';
		}

		return '8';
	}

}
