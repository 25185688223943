import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableContainerManager } from '@unifii/components';
import { DataPropertyInfoService, FormDefinitionMetadataIdentifiers, ModalService, ToastService, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { DefinitionPublishState, MessageColour, Schema } from '@unifii/sdk';

import { SchemaFieldSettings, SchemaSetting, SystemRole, UcFormBucketClient, UcSchema } from 'client';
import { EditData, SaveAndApprove, SaveAndClose, SaveOption, SaveOptionType } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { IdentifierMenuOption, SortIcons } from 'constant';
import { FormBucketService } from 'pages/form-data/bucket-service';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { isSortUp, sortByProperty } from 'utils';

import { BucketFieldSettingsComponent, BucketFieldSettingsData } from './bucket-field-settings.component';
import { BucketSettingsFieldInfo, createBucketSettingsTree, schemaFieldIterator } from './bucket-settings-functions';
import { BucketsTableManager } from './buckets-table-manager';

enum FormKeys {
	IsSearchable = 'isSearchable',
	IsReportable = 'isReportable',
}

const SortColumns: Record<keyof Pick<BucketSettingsFieldInfo, 'identifier' | 'label' | 'position'>, string> = {
	identifier: 'Identifier',
	label: 'Label',
	position: 'Definition Order',
};

@Component({
	selector: 'uc-bucket-settings',
	templateUrl: 'bucket-settings.html',
	styleUrls: ['./bucket-settings.less'],
	standalone: false,
})
export class BucketSettingsComponent extends TabsPage implements EditData, OnInit, OnDestroy {

	@HostBinding('class.stretch-component') stretchComponentClass = true;

	protected readonly formKeys = FormKeys;
	protected readonly publishStates = DefinitionPublishState;
	protected readonly menuOptions: IdentifierMenuOption<BucketSettingsFieldInfo>[] = [
		{ icon: SortIcons.sortDown, label: SortColumns.position, identifier: 'position' },
		{ label: SortColumns.identifier, identifier: 'identifier' },
		{ label: SortColumns.label, identifier: 'label' },
	];
	protected entries: BucketSettingsFieldInfo[];
	protected schema: UcSchema;
	protected schemaSetting: SchemaSetting;
	protected forms: string;
	protected stateColor: MessageColour | undefined;
	protected readOnly = true;
	protected isPublisher: boolean;
	protected error: any;
	protected formControl: UfControlGroup;

	private bucketService = inject(FormBucketService);
	private breadcrumbService = inject(BreadcrumbService);
	private builderHeaderService = inject(BuilderHeaderService);
	private toastService = inject(ToastService);
	private ucFormBucketClient = inject(UcFormBucketClient);
	private dataPropertyInfoService = inject(DataPropertyInfoService);
	private ufb = inject(UfFormBuilder);
	private modalService = inject(ModalService);
	private contextService = inject(ContextService);
	private translateService = inject(TranslateService);
	private tableManager = inject(TableContainerManager) as BucketsTableManager;
	private stateChanged: boolean;
	private publishedReportableFieldsMap: Map<string, boolean>;

	override ngOnInit() {
		this.schema = this.bucketService.schema;
		const bucketSettingsEntree = createBucketSettingsTree(Object.values(this.dataPropertyInfoService.formDefinitionReferences), this.schema, this.translateService);

		this.entries = this.mapFieldPosition(bucketSettingsEntree);
		this.publishedReportableFieldsMap = this.createPublishedReportableFieldsMap(this.bucketService.publishedSchema);
		this.schemaSetting = this.bucketService.schemaSetting;
		this.forms = this.schema.forms.map((f) => f.label).join(', ');
		this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => void this.save(saveOption)));
		this.readOnly = !this.contextService.checkRoles(SystemRole.FormBucketSettingsEditor);
		this.isPublisher = this.contextService.checkRoles(SystemRole.Publisher);
		this.buildHeaderConfig();
		this.formControl = this.getFormControl(this.schemaSetting);

		super.ngOnInit();
	}

	override ngOnDestroy() {

		if (this.stateChanged) {
			this.tableManager.reload.next();
		}

		super.ngOnDestroy();
	}

	set edited(v: boolean) {
		this.builderHeaderService.config.edited = v;
	}

	get edited() {
		return this.builderHeaderService.config.edited ?? false;
	}

	protected async updateSettingsPublishState(state: DefinitionPublishState) {
		await this.ucFormBucketClient.updateSettingsPublishState(this.schema.bucket, state);
		this.schemaSetting.publishState = state;
		this.stateChanged = true;
		this.buildHeaderConfig();
	}

	protected async edit(fieldInfo: BucketSettingsFieldInfo) {
		const control = this.formControl.get([fieldInfo.path]);
		const fieldSettings = (control?.getRawValue() as SchemaFieldSettings | undefined) ?? {};
		const settingsData: BucketFieldSettingsData = {
			isReportableReadonly: fieldSettings.isReportable === undefined || control?.get(FormKeys.IsReportable)?.disabled,
			isSearchableReadonly: fieldSettings.isSearchable === undefined || control?.get(FormKeys.IsSearchable)?.disabled,
			fieldInfo,
			fieldSettings,
		};

		const updated = await this.modalService.openMedium(BucketFieldSettingsComponent, settingsData);

		if (!updated) {
			return;
		}

		control?.patchValue(updated);
		this.edited = true;
	}

	protected onSort(option: IdentifierMenuOption<BucketSettingsFieldInfo>) {
		this.entries = [...this.entries.sort(sortByProperty<BucketSettingsFieldInfo>(isSortUp(option, this.menuOptions), option.identifier, 'fields').bind(this))];
	}

	private createPublishedReportableFieldsMap(schema?: Schema) {

		const map = new Map<string, boolean>();

		if (!schema) {
			return map;
		}

		for (const { path, schemaField } of schemaFieldIterator(schema.fields)) {
			const { isReportable } = schemaField;

			if (isReportable) {
				map.set(path, true);
			}

		}

		schema.reportableMetaFields.forEach((path) => {
			map.set(path, true);
		});

		return map;
	}

	private getFormControl(schemaSetting: SchemaSetting) {
		const form = this.ufb.group({});

		Object.entries(schemaSetting.fieldSettings).forEach(([identifier, fieldSetting]) => {

			const { isReportable, isSearchable } = fieldSetting;

			const field = this.ufb.group({});

			if (isSearchable !== undefined) {
				field.addControl(FormKeys.IsSearchable, this.ufb.control(isSearchable));
			}

			if (isReportable !== undefined) {
				const disabled = this.publishedReportableFieldsMap.get(identifier) && isReportable;

				field.addControl(FormKeys.IsReportable, this.ufb.control({ value: isReportable, disabled }));
			}

			if (identifier === `${FormDefinitionMetadataIdentifiers.Id}`) {
				field.addControl(FormKeys.IsReportable, this.ufb.control({ value: true, disabled: true }));
			}

			if (Object.entries(field.controls).length) {
				form.addControl(identifier, field);
			}

		});

		return form;
	}

	private async save(saveOption?: SaveOption) {

		if (this.formControl.invalid) {
			this.toastService.error('Unable to save. There are errors in Bucket Settings.');

			return;
		}

		const fieldSettings = this.formControl.getRawValue();

		try {
			this.schemaSetting = await this.ucFormBucketClient.updateSettings(this.schemaSetting.id, { fieldSettings });
			this.edited = false;
			this.toastService.success('Changes saved!');
			this.buildHeaderConfig();
			this.stateChanged = true;
		} catch (error) {
			this.error = error;
		}

		if (saveOption?.id === SaveOptionType.Approve) {
			await this.updateSettingsPublishState(DefinitionPublishState.Approved);
			void this.router.navigate(['../../'], { relativeTo: this.route });
		}

		if (saveOption?.id === SaveOptionType.Close) {
			void this.router.navigate(['../../'], { relativeTo: this.route });
		}

	}

	private buildHeaderConfig() {

		const saveOptions = this.isPublisher ? [SaveAndApprove, SaveAndClose] : [SaveAndClose];

		this.builderHeaderService.buildConfig({
			lastModifiedAt: this.schemaSetting.lastModifiedAt,
			lastModifiedBy: this.schemaSetting.lastModifiedBy,
			lastPublishedAt: this.schemaSetting.lastPublishedAt,
			lastPublishedBy: this.schemaSetting.lastPublishedBy,
			publishState: this.schemaSetting.publishState,
			hideSaveButton: this.readOnly,
			saveOptions,
			breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route),
			cancelRoute: ['..'],
		});
	}

	private mapFieldPosition(entries: BucketSettingsFieldInfo[]): BucketSettingsFieldInfo[] {
		return entries.map((entry, index) => ({ ...entry, position: index, fields: this.mapFieldPosition(entry.fields) }));
	}

}
