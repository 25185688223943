import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ExpressionParser, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';
import { Field, Transition } from '@unifii/sdk';

import { BuilderField, UcRoles } from 'client';
import { ArrayHelper, FieldDetailHelper } from 'helpers/helpers';

import { BuilderService } from '../compound-builder/builder.service';

import { FieldAttributeConfig, FieldDetailBasic } from './field-detail-basic';

interface InputConfig {
	role: FieldAttributeConfig;
	showIf: FieldAttributeConfig;
	showOn: FieldAttributeConfig;
	visibleTo: FieldAttributeConfig;
}

@Component({
	selector: 'uc-field-visibility',
	templateUrl: './field-visibility.html',
	standalone: false,
})
export class FieldVisibilityComponent extends FieldDetailBasic {

	protected config: InputConfig;
	protected actions: string[] = [];
	protected rolesResult: string[] = [];
	protected roles: string[] = [];
	protected visibleTos: string[] = [];

	private expressionParser = inject(ExpressionParser);
	private ucRoles = inject(UcRoles);

	constructor() {
		const builderService = inject(BuilderService);
		const ref = inject(ChangeDetectorRef);

		super(builderService, 'visibility', ref);
		this.generateControls();
	}

	private get parentTransitions(): Transition[] {
		const parent: Field = ArrayHelper.getParent(this.field, this.builderService.definition.fields);

		return parent?.transitions ?? [];
	}

	protected searchActions(query: string) {
		const filteredList = this.parentTransitions.filter((item) => {
			const value = item.action.toLowerCase();

			return !query || value.includes(query.toLowerCase());
		});

		this.actions = filteredList.map((item) => item.action).filter((v, i, self) => self.indexOf(v) === i);
	}

	protected async findRoles(query: string | null) {
		this.rolesResult = (await this.ucRoles.get(query ?? undefined)).map((r) => r.name);
	}

	protected mapRoles(values: string[], attrName: string) {
		(this.field[attrName as keyof BuilderField] as any) = (values && values.length) ? values.join(',') : undefined;
	}

	protected setup(field: BuilderField) {
		// Get metadata
		const parent = this.builderService.builder.getFieldPosition(this.field)?.parent as Field | undefined;
		const fm = FieldDetailHelper.getMetadata(field, this.builderService.builder.type, parent);

		// Compute show
		this.config.role.show = fm.role;
		this.config.showIf.show = fm.showIf && field.name == null;
		this.config.showOn.show = fm.showOn;
		this.config.visibleTo.show = fm.visibleTo && field.name == null;

		// Add/Remove controls from the form
		Object.keys(this.config).forEach((k) => {
			if (this.config[k as keyof InputConfig].show === true && this.form.controls[k] == null) {
				// Add control
				this.form.addControl(k, this.config[k as keyof InputConfig].control);
			}

			if (this.config[k as keyof InputConfig].show === false && this.form.controls[k] != null) {
				this.form.removeControl(k);
			}
		});

		// Maps CSV to role and visibleTo
		this.roles = field.role ? field.role.split(',').sort(stringsCaseInsensitiveLocalCompare ) : [];
		this.visibleTos = field.visibleTo ? field.visibleTo.split(',').sort(stringsCaseInsensitiveLocalCompare ) : [];

	}

	protected update() { return; }

	private generateControls() {
		this.config = {
			role: {
				show: false, control: new UfControl(),
			},
			showIf: {
				show: false, control: new UfControl(ValidatorFunctions.custom((v) => !v || this.expressionParser.validate(v), 'Invalid expression')),
			},
			showOn: {
				show: false, control: new UfControl(ValidatorFunctions.compose([
					ValidatorFunctions.required('Show on is required'),
					ValidatorFunctions.custom((v) => /^\S*$/.test(v), 'Can\'t contain white space'),
					ValidatorFunctions.custom((v) => this.isActionAvailable(v), 'Show on must match a workflow action'),
					/* ValidatorFunctions.custom(v => {
                        return !FieldDetailHelper.existActionGroupsForSameAction(v, ArrayHelper.getParent(this.field, this.builderService.compound.fields));
                    }, 'Value already used by another ActionGroup of this Section'),*/
					ValidatorFunctions.custom((v) => !FieldDetailHelper.existActionGroupForSameTransitions(v, ArrayHelper.getParent(this.field, this.builderService.definition.fields), this.builderService.definition.fields), 'Value already used by another ActionGroup of this Section'),
				])),
			},
			visibleTo: {
				show: false, control: new UfControl(ValidatorFunctions.custom((v) => /^\S*$/.test(v), 'Can\'t contain white space')),
			},
		};
	}

	private isActionAvailable(value: string): boolean {
		return this.parentTransitions.find((t) => t.action === value) != null;
	}

}
