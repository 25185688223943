import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { DataDisplayListItem, Modal, ModalData, ModalRuntime, UfControlGroup } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { DataSourceInputValueSource } from 'client';

import { DataSourceExternalInfo, DataSourceExternalInputControlKey } from './data-source-model';

@Component({
	selector: 'uc-data-source-external-input',
	templateUrl: './data-source-external-input-editor.html',
	standalone: false,
})
export class DataSourceExternalInputEditorComponent implements Modal<UfControlGroup, UfControlGroup>, OnDestroy, OnInit {

	@HostBinding('class.uf-form-card') cardClass = true;
	runtime = inject<ModalRuntime<UfControlGroup, UfControlGroup>>(ModalRuntime);
	control = inject<UfControlGroup>(ModalData);
	guard = false;

	protected readonly externalInputKeys = DataSourceExternalInputControlKey;
	protected info: DataSourceExternalInfo;
	protected label: string;
	protected infoDescriptors: DataDisplayListItem[];
	protected canEditValue = false;

	private subscription = new Subscription();

	ngOnInit() {
		const control = this.control;

		this.info = control.get(DataSourceExternalInputControlKey.Info)?.value;
		this.label = `${this.info.required ? '* ' : ''}Value or Expression`;

		this.infoDescriptors = [
			{ term: 'Parameter', data: this.info.parameter },
			{ term: 'Source', data: this.info.source },
			{ term: 'Type', data: this.info.type },
			// TODO Required field is gonna be added later on next releases
			// { term: 'Required', data: this.info.required ? 'Yes' : 'No' },
		];

		this.canEditValue = [DataSourceInputValueSource.Form, DataSourceInputValueSource.Default].includes(this.info.source);
		this.subscription.add(control.get(DataSourceExternalInputControlKey.Value)?.valueChanges.subscribe(() => { this.guard = true; } ));
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	close() {
		this.runtime.close();
	}

	submit() {
		this.control.setSubmitted();
		if (this.control.invalid) {
			return;
		}

		this.runtime.close(this.control);
	}

}
