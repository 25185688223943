<uf-panel class="content-pane container">
	@if (form) {
		<div class="uf-container-lg">
			<div [formGroup]="form" class="uf-grid row-gap-lg pad-top">
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Details</div>
					</div>
					<div class="uf-grid pad">
						@if (form.controls.name) {
							<uf-text [formControlName]="systemControlKeys.Name" label="Name" class="col-12" />
						}
						@if (form.controls.baseUrl) {
							<uf-url [formControlName]="systemControlKeys.BaseUrl" label="Base URL" class="col-12" />
						}
						@if (form.controls.privacyPolicyUrl) {
							<uf-url [formControlName]="systemControlKeys.PrivacyPolicyUrl" label="Privacy Policy URL" class="col-12" />
						}
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Contact Details</div>
					</div>
					<div class="uf-grid pad">
						@if (form.controls.contactName) {
							<uf-text [formControlName]="systemControlKeys.ContactName" label="Contact Name" class="col-12" />
						}
						@if (form.controls.contactEmail) {
							<uf-text [formControlName]="systemControlKeys.ContactEmail" label="Contact Email" class="col-12" />
						}
						@if (form.controls.contactPhone) {
							<uf-text [formControlName]="systemControlKeys.ContactPhone" label="Contact Phone" class="col-12" />
						}
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Logo</div>
						@if (this.logoControl.value) {
							<button (click)="deleteLogo()" class="uf-action tertiary" title="Remove" type="button">
								<uf-icon name="backspace" />
							</button>
						}
						@if (!this.logoControl.value) {
							<button (click)="findLogo()" class="uf-action tertiary" title="Edit" type="button">
								<uf-icon name="edit" />
							</button>
						}
					</div>
					<div class="uf-grid condensed">
						@if (!this.logoControl.value) {
							<uf-message icon="infoSolid" content="No logo added" class="info col-12 gap" />
						}
						@if (this.logoControl.value) {
							<div class="col-12 checkboard-bg">
								<a [routerLink]="['/assets/media', this.logoControl.value.id]" target="_blank">
									<img [src]="this.logoControl.value.url + '?h=120'" alt="item.title" style="max-height: 120px" class="thumb" />
								</a>
							</div>
						}
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">User Configuration</div>
					</div>
					<div class="uf-grid pad">
						<uf-blockquote icon="infoSolid" content="While selected, any user created or updated will require an email address" class="info col-12" />
						<uf-checkbox [formControlName]="systemControlKeys.EmailRequired" label="User Email Required" class="col-12" />
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Language</div>
					</div>
					<div class="uf-grid pad">
						<uf-autocomplete [formControlName]="systemControlKeys.PrimaryLanguage" [options]="langOptions" (searchChange)="searchLang($event)" placeholder="Select a language..." nameProperty="name" class="col-12" />
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Session Expiry</div>
					</div>
					<div class="uf-app-bar flat">
						<div class="title">Inactive Session Expiry</div>
					</div>
					<div [formGroupName]="systemControlKeys.TokenDuration" class="uf-grid pad">
						<div class="col-12 uf-grid condensed column-gap-lg">
							<uf-number [formControlName]="durationControlKeys.Days" label="Days" class="col-md-3 col-sm-12" />
							<uf-number [formControlName]="durationControlKeys.Hours" label="Hours" class="col-md-3 col-sm-12" />
							<uf-number [formControlName]="durationControlKeys.Minutes" label="Minutes" class="col-md-3 col-sm-12" />
							<uf-number [formControlName]="durationControlKeys.Seconds" label="Seconds" class="col-md-3 col-sm-12" />
							<uf-error [control]="tokenDurationControl" class="col-12" />
						</div>
					</div>
					<div class="uf-app-bar flat">
						<div class="title">Total Session Timeout</div>
					</div>
					<div class="uf-grid pad">
						@if (this.sessionDurationControl.enabled) {
							<div [formGroupName]="systemControlKeys.SessionDuration" class="col-12 uf-grid condensed column-gap-lg">
								<uf-number [formControlName]="durationControlKeys.Days" label="Days" class="col-md-3 col-sm-12" />
								<uf-number [formControlName]="durationControlKeys.Hours" label="Hours" class="col-md-3 col-sm-12" />
								<uf-number [formControlName]="durationControlKeys.Minutes" label="Minutes" class="col-md-3 col-sm-12" />
								<uf-number [formControlName]="durationControlKeys.Seconds" label="Seconds" class="col-md-3 col-sm-12" />
								<uf-error [control]="sessionDurationControl" class="col-12" />
							</div>
						}
						<uf-checkbox [formControlName]="systemControlKeys.DisabledSessionTimeout" label="Total Session Timeout Disabled" class="col-12" />
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Allowed File Types</div>
					</div>
					<div class="uf-app-bar flat">
						<div class="title">Media Asset File Types</div>
					</div>
					<div class="uf-grid pad">
						@if (!this.bypassAssetFileTypesWhitelistControl.value) {
							<uf-chips [options]="fileTypeOptions" [formControlName]="systemControlKeys.AllowedAssetFileTypes" [allowDuplicates]="false" (searchChange)="searchAssetFileTypes($event)" placeholder="Add file types" nameProperty="id" class="col-12" />
						}
						<uf-checkbox [formControlName]="systemControlKeys.BypassAssetFileTypesWhitelist" label="Allow any asset file types" class="col-12" />
					</div>
					<div class="uf-app-bar flat">
						<div class="title">Form Attachment File Types</div>
					</div>
					<div class="uf-grid pad">
						@if (!this.bypassAttachmentFileTypesWhitelistControl.value) {
							<uf-chips [options]="fileTypeOptions" [formControlName]="systemControlKeys.AllowedAttachmentFileTypes" [allowCustom]="false" [allowDuplicates]="false" (searchChange)="searchAttachmentFileTypes($event)" placeholder="Add file types" nameProperty="id" class="col-12" />
						}
						<uf-checkbox [formControlName]="systemControlKeys.BypassAttachmentFileTypesWhitelist" label="Allow any attachment file types" class="col-12" />
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Google Maps Configuration</div>
					</div>
					<div class="uf-grid pad">
						<uc-secret-input [formControlName]="systemControlKeys.GoogleMapsApiKey" label="API Key" class="col-12" />
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">User Login</div>
					</div>
					<div class="uf-grid pad-sides pad-bottom pad-top">
						<uf-checkbox [formControlName]="systemControlKeys.IsPasswordAuthSupported" label="Allow Sign In with Username & Password" class="col-12" />
						<uf-checkbox [formControlName]="systemControlKeys.IsMfaEnforced" label="Enforce MFA" class="col-12" />
						<uf-checkbox [formControlName]="systemControlKeys.RememberMe" label="Remember Users" class="col-12">
							<uf-help content="When enabled, users can opt in to be remembered in the App" />
						</uf-checkbox>
						@if (this.rememberMeExpiryDaysControl.enabled) {
							<div class="col-12">
								<uf-number [formControlName]="systemControlKeys.RememberMeExpiryDays" label="Forget users after (days)" class="col-12" />
							</div>
						}
						<uf-text [formControlName]="systemControlKeys.UsernameLoginLabel" label="Username Login Label" class="col-12" />
					</div>
				</div>
				@if (error) {
					<uf-blockquote [content]="error.message" icon="errorSolid" class="error col-12" />
				}
			</div>
		</div>
	}
</uf-panel>
