@if (!error) {
	<uf-panel class="container">
		<div class="header-pane uf-app-bar transparent">
			<uc-builder-header />
		</div>
		<div class="content-pane scrollable">
			<uf-tabs (selectedChange)="updateTitle()">
				<uf-tab label="Table" path="" />
				@if (!!info.table.id) {
					<uf-tab label="Table Details" path="details-page" />
				}
			</uf-tabs>
		</div>
	</uf-panel>
}

<uc-error-message [error]="error" />
