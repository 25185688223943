import { Injectable, InjectionToken, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { ActionMultiplicity, DataDisplayService, FilterEntries, FilterEntry, FilterValue, HierarchyUnitProvider, ModalService, TableConfig, TableConfigColumn, ToastService, getDefaultTableConfig } from '@unifii/library/common';
import { DataType, Dictionary, ErrorType, ensureUfRequestError } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';

import { ActivityType, DataForwarderActivityInfo, FormDataActivityInfo, UcWorkflow, WorkflowActivityInfo, WorkflowEventType, WorkflowNotification, WorkflowNotificationConditionClaim, WorkflowNotificationConditionHierarchy, WorkflowNotificationConditionRole, WorkflowNotificationConditionType, WorkflowNotificationConditionUserHierarchy, WorkflowNotificationRecipient, WorkflowNotificationRecipientClaim, WorkflowNotificationRecipientCombo, WorkflowNotificationRecipientEmail, WorkflowNotificationRecipientFormData, WorkflowNotificationRecipientRole, WorkflowNotificationRecipientType, WorkflowNotificationRecipientUser, WorkflowNotificationRecipientUserHierarchy } from 'client';
import { ConsoleNameLabel, ConsoleNameProperty, TABLE_SEARCH_MIN_LENGTH } from 'constant';
import { DialogsService } from 'services/dialogs.service';

import { ActivityTableDataSource } from './activity-table-data-source';
import { WorkflowActivityLabel, WorkflowSourceTypeLabel } from './constants';
import { enumToName } from './workflow-utils';

export const ActivityTypeToken = new InjectionToken<ActivityType>('ActivityType');

export const recipientEnumDescription = {
	[WorkflowNotificationRecipientType.CreatedBy]: 'Created By',
	[WorkflowNotificationRecipientType.LastModifiedBy]: 'Last Modified By',
	[WorkflowNotificationRecipientType.CreatedByManager]: `Created By's Manager`,
	[WorkflowNotificationRecipientType.LastModifiedByManager]: `Last Modified By's Manager`,
	[WorkflowNotificationRecipientType.User]: 'User',
	[WorkflowNotificationRecipientType.Role]: 'Role',
	[WorkflowNotificationRecipientType.Claim]: 'Claim',
	[WorkflowNotificationRecipientType.ClaimDatasource]: 'Claim',
	[WorkflowNotificationRecipientType.Email]: 'Email',
	[WorkflowNotificationRecipientType.Combo]: 'Combination',
	[WorkflowNotificationRecipientType.UserHierarchy]: 'User Hierarchy',
	[WorkflowNotificationRecipientType.Company]: 'Company',
	[WorkflowNotificationRecipientType.FormData]: 'Form Data',
	[WorkflowNotificationRecipientType.EmailField]: 'Form Data',
	[WorkflowNotificationRecipientType.UserDatasource]: 'Form Data',
	[WorkflowNotificationRecipientType.UserDatasourceManager]: 'Form Data',
	[WorkflowNotificationRecipientType.UserModified]: 'User Modified',
	[WorkflowNotificationRecipientType.UserModifiedManager]: 'User Modified By Manager',
};

@Injectable()
export class WorkflowActivityTableManager implements TableContainerManager<WorkflowActivityInfo, FilterValue, FilterEntry>, OnDestroy {

	tableConfig: TableConfig<WorkflowActivityInfo>;
	showSearch = true;
	searchMinLength = TABLE_SEARCH_MIN_LENGTH;
	addActionConfig = true;
	defaultSort = ConsoleNameProperty;
	reload = new Subject<void>();
	update = new Subject<TableInputs<FilterValue>>();
	updateItem = new Subject<WorkflowActivityInfo | { item: WorkflowActivityInfo; trackBy: keyof WorkflowActivityInfo }>();
	inputManager: TableInputManager<FilterValue, FilterEntry>;

	private ucWorkflow = inject(UcWorkflow);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private activityType = inject(ActivityTypeToken);
	private dataDisplayService = inject(DataDisplayService);
	private toastService = inject(ToastService);
	private modalService = inject(ModalService);
	private dialogs = inject(DialogsService);
	private connection: Subscription | undefined;
	private items: WorkflowActivityInfo[];

	constructor() {
		const entries = inject(FilterEntries);

		this.inputManager = new TableInputManager(entries, inject(HierarchyUnitProvider), null, null);

		const id = `table_workflow_${this.activityType}`;
		let columns: TableConfigColumn<WorkflowActivityInfo>[];

		switch (this.activityType) {
			case ActivityType.DataForwarder:
				columns = this.buildDataForwarderColumns() as unknown as TableConfigColumn<WorkflowActivityInfo>[];
				break;
			case ActivityType.FormData:
				columns = this.buildFormDataColumns() as unknown as TableConfigColumn<WorkflowActivityInfo>[];
				break;
			default:
				columns = this.buildActivityColumns();
		}

		const tableConfig = getDefaultTableConfig(columns, id);

		tableConfig.actions = [{
			label: 'Duplicate',
			multiplicity: ActionMultiplicity.Single,
			action: (row) => this.duplicate(row.$implicit.id),
		}, {
			label: 'Delete',
			multiplicity: ActionMultiplicity.Single,
			action: (row) => this.delete(row.$implicit),
		}];
		tableConfig.row = { link: (activity: WorkflowActivityInfo) => [activity.id] };

		this.tableConfig = tableConfig;
	}

	ngOnDestroy() {
		this.connection?.unsubscribe();
	}

	createDataSource(inputs?: TableInputs<FilterValue>) {
		let params: Dictionary<any> | undefined;

		if (inputs != null) {
			params = this.inputManager.serializeInputs(inputs);
		}

		const dataSource = new ActivityTableDataSource(this.ucWorkflow, this.activityType, params, params?.q, params?.sort);

		this.connection?.unsubscribe();
		this.items = [];
		this.connection = dataSource.connect().subscribe((items) => this.items.push(...(items.data ?? [])));

		return dataSource;
	}

	getNextItem(id?: string): WorkflowActivityInfo | undefined {
		const itemIndex = this.items.findIndex((item) => item.id === id);

		if (itemIndex < 0) {
			return;
		}

		return this.items[itemIndex + 1];
	}

	private buildActivityColumns(): TableConfigColumn<WorkflowActivityInfo>[] {
		const columns: TableConfigColumn<WorkflowActivityInfo>[] = [
			{
				name: ConsoleNameProperty,
				label: ConsoleNameLabel,
				sortable: true,
			}, {
				name: 'bucket',
				label: 'Form Data Repository',
				sortable: true,
			},
		];

		if (this.activityType === ActivityType.Notification) {
			columns.push({
				name: 'recipients',
				label: 'Recipients',
				value: (item) => {
					return (item as WorkflowNotification).recipients.map(this.mapRecipientItem.bind(this)).join(', ');
				},
			});
		}

		columns.push(
			{
				name: 'lastModifiedAt',
				label: 'Last Modified At',
				sortable: true,
				value: (item) => this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
			}, {
				name: 'lastModifiedBy',
				label: 'Last Modified by',
				value: (item) => item.lastModifiedBy.username,
			},
		);

		return columns;
	}

	private mapRecipientItem(recipient: WorkflowNotificationRecipient): string {
		const buildRecipientValue = this.buildRecipientColumnDescription(recipient.type);

		switch (recipient.type) {
			case WorkflowNotificationRecipientType.Role: {
				const roleRecipient = recipient as WorkflowNotificationRecipientRole;

				return buildRecipientValue(roleRecipient.role);
			}
			case WorkflowNotificationRecipientType.User: {
				const userRecipient = recipient as WorkflowNotificationRecipientUser;

				return buildRecipientValue(userRecipient.user.username);
			}
			case WorkflowNotificationRecipientType.Email: {
				const emailRecipient = recipient as WorkflowNotificationRecipientEmail;

				return buildRecipientValue(emailRecipient.email);
			}
			case WorkflowNotificationRecipientType.FormData: {
				const { value, type } = (recipient as WorkflowNotificationRecipientFormData).formData;

				return buildRecipientValue(value, enumToName(type));
			}
			case WorkflowNotificationRecipientType.UserHierarchy: {
				const { matchType, value } = (recipient as WorkflowNotificationRecipientUserHierarchy).userHierarchy;

				return buildRecipientValue(value, enumToName(matchType));
			}
			case WorkflowNotificationRecipientType.Claim: {
				const { type } = (recipient as WorkflowNotificationRecipientClaim).claim;

				return buildRecipientValue(type);
			}
			case WorkflowNotificationRecipientType.Combo: {
				const comboRecipient = recipient as WorkflowNotificationRecipientCombo;

				const comboDescription = comboRecipient.conditions.map((condition) => {
					if ((condition as WorkflowNotificationConditionRole).role) {
						return this.buildRecipientColumnDescription(WorkflowNotificationRecipientType.Role)((condition as WorkflowNotificationConditionRole).role);
						// eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
					} else if ((condition as WorkflowNotificationConditionClaim).claim) {
						const { type } = (condition as WorkflowNotificationConditionClaim).claim;

						return this.buildRecipientColumnDescription(WorkflowNotificationRecipientType.Claim)(type);
						// eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
					} else if ((condition as WorkflowNotificationConditionUserHierarchy).userHierarchy) {
						const { matchType, value } = (condition as WorkflowNotificationConditionUserHierarchy).userHierarchy;
						const recipientComboDescription = this.buildRecipientColumnDescription(WorkflowNotificationRecipientType.UserHierarchy);

						return recipientComboDescription(value, enumToName(matchType));
						// eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
					} else if ((condition as WorkflowNotificationConditionHierarchy).hierarchy) {
						const { formData, value } = (condition as WorkflowNotificationConditionHierarchy).hierarchy;

						return `${enumToName(WorkflowNotificationConditionType.Hierarchy)}: (${enumToName(formData)}:${value})`;
					}

					return enumToName(recipient.type);

				}).join(', ');

				return `${recipientEnumDescription[recipient.type]}: (${comboDescription})`;

			}
		}

		return buildRecipientValue();
	}

	private buildDataForwarderColumns(): TableConfigColumn<DataForwarderActivityInfo>[] {
		return [{
			name: ConsoleNameProperty,
			label: ConsoleNameLabel,
			sortable: true,
		}, {
			name: 'sourceType',
			label: 'Source Type',
			value: (item) => WorkflowSourceTypeLabel[item.sourceType],
			sortable: true,
		}, {
			name: 'source',
			label: 'Source',
			value: (item) =>
				item.sourceType === WorkflowEventType.FormSubmitted ? item.bucket : undefined,
			sortable: true,
		}, {
			name: 'target',
			label: 'Target',
			value: (item) => item.integrationName,
			sortable: true,
		}, {
			name: 'lastModifiedAt',
			label: 'Last Modified At',
			sortable: true,
			value: (item) => this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
		}, {
			name: 'lastModifiedBy',
			label: 'Last Modified by',
			value: (item) => item.lastModifiedBy.username,
		}];
	}

	private buildFormDataColumns(): TableConfigColumn<FormDataActivityInfo>[] {
		return [{
			name: ConsoleNameProperty,
			label: ConsoleNameLabel,
			sortable: true,
		}, {
			name: 'sourceType',
			label: 'Source Type',
			value: (item) => WorkflowSourceTypeLabel[item.sourceType],
			sortable: true,
		}, {
			name: 'source',
			label: 'Source',
			value: (item) =>
				item.sourceType === WorkflowEventType.FormSubmitted ? item.bucket : undefined,
			sortable: true,
		}, {
			name: 'target',
			label: 'Target Form',
			value: (item) => item.targetFormName,
			sortable: true,
		}, {
			name: 'lastModifiedAt',
			label: 'Last Modified At',
			sortable: true,
			value: (item) => this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
		}, {
			name: 'lastModifiedBy',
			label: 'Last Modified by',
			value: (item) => item.lastModifiedBy.username,
		}];
	}

	private duplicate(id: string) {
		void this.router.navigate([id, { duplicate: 'true' }], { relativeTo: this.route });
	}

	private async delete(activity: WorkflowActivityInfo, force?: boolean) {
		try {

			if (!force && !await this.dialogs.confirmDelete()) {
				return;
			}

			await this.ucWorkflow.deleteActivity(activity.id, force);
			this.reload.next();
			this.toastService.success(`${WorkflowActivityLabel[activity.type]} deleted`);
		} catch (e) {
			const error = ensureUfRequestError(e, `Could not delete ${WorkflowActivityLabel[activity.type]}`);

			if (error.type === ErrorType.Conflict) {
				void this.modalService.openAlert({
					title: `Delete ${WorkflowActivityLabel[activity.type]}`,
					message: 'This activity is linked to a rule, please unlink it before deleting.',
				});

				return;
			}

			this.toastService.error(error.message);
		}
	}

	private buildRecipientColumnDescription(recipientType: WorkflowNotificationRecipientType) {
		const description = recipientEnumDescription[recipientType];

		return (value?: string, secondType?: string) => secondType ? `${description}: (${secondType}:${value})`: value ? `${description}: ${value}` : description;
	}

}
