import { Injectable, inject } from '@angular/core';
import { UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';

import { ActivityType, WorkflowActivityTimer, WorkflowRelatedActivityType } from 'client';

import { reduceExpressionToSingleLine } from './workflow-functions';

export interface WorkflowTimerModel {
	id: string;
	consoleName: string;
	bucket: { id: string };
	expression: string;
	replacePreviousJob?: boolean;
	relatedWorkflowActivities?: string[];
}

export enum ControlKeys {
	Id = 'id',
	ConsoleName = 'consoleName',
	Bucket = 'bucket',
	Expression = 'expression',
	ReplacePreviousJob = 'replacePreviousJob',
	RelatedWorkflowActivities = 'relatedWorkflowActivities',
}

@Injectable()
export class WorkflowTimerFormController {

	private readonly requiredMessage = 'Field is Required';

	private ufb = inject(UfFormBuilder);

	buildRoot(wfTimerModel?: WorkflowTimerModel): UfControlGroup {

		return this.ufb.group({
			[ControlKeys.Id]: [{ value: wfTimerModel?.id, disabled: true }],
			[ControlKeys.ConsoleName]: [wfTimerModel?.consoleName, ValidatorFunctions.required(this.requiredMessage)],
			[ControlKeys.Bucket]: wfTimerModel?.bucket,
			[ControlKeys.Expression]: [wfTimerModel?.expression, ValidatorFunctions.required(this.requiredMessage)],
			[ControlKeys.ReplacePreviousJob]: wfTimerModel?.replacePreviousJob,
			[ControlKeys.RelatedWorkflowActivities]: [wfTimerModel?.relatedWorkflowActivities ?? []],
		});
	}

	toModel({ id, consoleName, expression, bucket: bucketId, replacePreviousJob, relatedWorkflowActivities }: WorkflowActivityTimer): WorkflowTimerModel {
		return {
			id,
			consoleName,
			expression,
			bucket: { id: bucketId },
			replacePreviousJob,
			relatedWorkflowActivities: relatedWorkflowActivities?.map(({ relatedActivityId }) => relatedActivityId) ?? [],
		} as WorkflowTimerModel;
	}

	toData({ id, consoleName, expression, bucket, replacePreviousJob, relatedWorkflowActivities }: WorkflowTimerModel): WorkflowActivityTimer {
		expression = reduceExpressionToSingleLine(expression);

		return {
			id,
			consoleName,
			expression,
			bucket: bucket.id,
			type: ActivityType.Timer,
			replacePreviousJob,
			relatedWorkflowActivities: relatedWorkflowActivities?.map((relatedActivityId) => ({ relatedActivityId, relationshipType: WorkflowRelatedActivityType.ReplaceOnExecution })),
		} as WorkflowActivityTimer;
	}

}
