import { DataFeatureConfigurationComponent, DataFeatureDataMappingComponent, DataFeatureDisplayComponent, DataFeatureParametersComponent, DataFeatureSelectorComponent } from './common';
import { DataSourcesTableComponent } from './data-sources';
import { DataTransactionsTableComponent } from './data-transactions';
import { ExternalCallDetailsComponent } from './external-call-details.component';

export * from './common';
export * from './data-sources';
export * from './data-transactions';
export * from './external-call-details.component';

export const PROJECT_SETTINGS_EXTERNAL_CALL_COMPONENTS = [
	DataFeatureSelectorComponent,
	DataFeatureConfigurationComponent,
	DataFeatureDataMappingComponent,
	DataFeatureParametersComponent,
	DataFeatureDisplayComponent,
	DataSourcesTableComponent,
	ExternalCallDetailsComponent,
	DataTransactionsTableComponent,
];
