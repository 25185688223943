import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UfControl, UfControlGroup } from '@unifii/library/common';
import { Dictionary, Field } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { EditMode, ItemPickerGroup } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';

import { FormEditorFunctions } from './form-editor-functions';
import { FormEditorDefinition, IdentifiersMaxLength } from './form-editor-model';

@Injectable()
export class FormEditorStatus implements OnDestroy {

	/** Root control, also used for data access entry point */
	root: UfControlGroup;

	/** root control valueChanges subscription */
	valueChangesSub: Subscription | null;

	/** Definition */
	get definition(): FormEditorDefinition {
		return this.root.getRawValue() as FormEditorDefinition;
	}

	/** Retrieve field control via its uuid - performance lookup */
	fieldByUuid = new Map<string, UfControlGroup>();

	/** React to any field identifier change */
	fieldsIdentifier: UfControl;

	/** The component route */
	route: ActivatedRoute;

	/** The editMode based on the route.params */
	editMode: EditMode;

	/** Data edited status */
	get edited(): boolean {
		return this.headerService?.config.edited ?? false;
	}

	set edited(v: boolean) {
		if (!this.headerService) {
			return;
		}

		this.headerService.config.edited = v;
	}

	/** Selected node in the Form Editor UI */
	selected: UfControlGroup | null;

	/** Legacy forms allow a max length for field.identifier of 150 meanwhile latest format allow for 60 */
	identifiersMaxLength: IdentifiersMaxLength = FormEditorFunctions.detectIdentifiersMaxLength();

	/** True if definition.lastPublishedAt is valorized
     * exists because access to this flag is needed before the root control is set
     */
	hasBeenPublished: boolean;

	/** Dictionary of tags for autocomplete */
	tags = new Set<string>();

	/** Dictionary of transition statuses for autocomplete */
	statuses = new Set<string>();

	/** input groups for the left side menu picker */
	itemPickerGroups: ItemPickerGroup<Field>[];

	/** Cached look up for isContainer check, performance improvement  */
	containersByFieldType: Dictionary<boolean>;

	headerService: BuilderHeaderService;

	ngOnDestroy() {
		this.valueChangesSub?.unsubscribe();
	}

}

