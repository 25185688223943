import { Injectable, inject } from '@angular/core';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { DataDisplayService, FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig } from '@unifii/library/common';
import { DataType } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { UcClient, UcProjectInfo } from 'client';

import { ProjectsDataSource } from './projects-data-source';

@Injectable()
export class ProjectTableManager implements TableContainerManager<UcProjectInfo, FilterValue, FilterEntry> {

	tableConfig: TableConfig<UcProjectInfo>;
	showSearch = true;
	addActionConfig = true;
	reload = new Subject<void>();
	inputManager = new TableInputManager([], inject(HierarchyUnitProvider), null, null);
	searchMinLength = 1;
	update = new Subject<TableInputs<FilterValue>>();

	private ucClient = inject(UcClient);
	private dataDisplayService = inject(DataDisplayService);
	private projects: UcProjectInfo[] | null;

	constructor() {
		this.tableConfig = {
			columns: [{
				name: 'version',
				label: 'Version',
			},
			{
				name: 'publishedAt',
				label: 'Published',
				value: (p) => this.dataDisplayService.displayAsString(p.publishedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
			}],
			row: {
				image: (p) => {
					if (p.logo?.url) {
						return p.logo.url + '?w=205&h=96&mode=inside';
					}

					return '/assets/svg/new-icons/projects.svg';
				},
				label: (p) => p.name,
				link: (p) => p.id,
			},
			pageSize: -1,
		};
	}

	createDataSource({ q }: TableInputs<FilterValue> = {}) {
		this.projects = null;

		return new ProjectsDataSource(this.getProjects.bind(this), q);
	}

	refreshList() {
		this.reload.next();
	}

	async getProjects() {
		if (this.projects == null) {
			this.projects = await this.ucClient.getProjects();
		}

		return this.projects;
	}

}
