import { Component, Input, inject } from '@angular/core';
import { UfControlGroup } from '@unifii/library/common';
import { StructureNodeVariation } from '@unifii/sdk';

import { UcRoles } from 'client';

import { StructureNodeControlKeys } from '../structure-control-keys';
import { StructureEditorNodeOrRoot } from '../structure-model';

@Component({
	selector: 'uc-node-roles',
	templateUrl: './node-roles.html',
	standalone: false,
})
export class NodeRolesComponent {

	@Input({ required: true }) control: UfControlGroup;

	protected readonly formKeys = StructureNodeControlKeys;
	protected rolesResult: string[] = [];

	private roles = inject(UcRoles);

	protected async findRoles(query: string | null) {
		const roles = (await this.roles.get(query ?? undefined)).map((r) => r.name);

		this.rolesResult = roles.filter((r) => (this.node.roles ?? []).find((nr) => nr === r) == null);
	}

	private get node(): StructureEditorNodeOrRoot | StructureNodeVariation {
		return this.control.getRawValue() as StructureEditorNodeOrRoot | StructureNodeVariation;
	}

}
