<div class="uf-box">
	<div class="uf-app-bar accent">
		<div class="title">
			{{ pageText.title }}
		</div>
		<a [title]="pageText.iconTitle" class="uf-action tertiary">
			<uf-icon [name]="pageText.icon" (click)="switchFilter()" />
		</a>
	</div>
	<div class="uf-grid gap">
		@if (isBasicFilter) {
			@if (basicFiltersControl) {
				<uc-filter-editor [dataProperties]="availableHiddenFilters" [parentControl]="form" [filter]="basicFiltersControl.value" [style.marginTop]="0" (filterChange)="basicFiltersControl.setValue($event)" class="col-12" />
			}
		} @else {
			@if (advancedFiltersControl) {
				<uf-textarea [control]="advancedFiltersControl" [value]="advancedFiltersControl.value" (valueChange)="advancedFiltersControl.setValue($event)" class="col-12" />
			}
		}
	</div>
</div>
