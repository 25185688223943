"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REGEXP_TELEPHONE = exports.REGEXP_WEBSITE = exports.REGEXP_EMAIL = void 0;
// eslint-disable-next-line sonarjs/slow-regex
exports.REGEXP_EMAIL = /.+@.+$/;
exports.REGEXP_WEBSITE = /^[a-zA-Z0-9:/]+.((\.[a-zA-Z0-9]+)+)$/;
exports.REGEXP_TELEPHONE = /^[+]?[(]?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4,6}$/;
