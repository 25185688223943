;
export const ImageToken = 'unifii-image';
export const VideoToken = 'unifii-video';
export function imageTokenizer(text, Token) {
  /**
   * Regex contains 8 groups used later in the
   * string.replace function, by creating goups it ensures that
   * information can be extracted easily
   */
  const regex = /!img(\[(.*?)\]|)(\((.*?\s)(\"(.*?)\" |)(.*?)\))/g;
  if (regex.test(text)) {
    return tokenize(Token, text, ImageToken, regex);
  }
  return null;
}
export function videoTokenizer(text, Token) {
  /**
   * Regex includes unsued group [(.*?)] so tokenize function can be reused
   */
  const regex = /!vid(\[(.*?)\]|)(\((.*?\s)(\"(.*?)\" |)(.*?)\))/g;
  if (regex.test(text)) {
    return tokenize(Token, text, VideoToken, regex);
  }
  return null;
}
function tokenize(Token, str, tokenName, regex) {
  const nodes = [];
  let last_pos = 0;
  /**
   * Regex expected to have 7 matched groups to ensure names are mapped easily
   */
  str.replace(regex, (match, p1, alt, p3, src, p5, title, tags, offset) => {
    if (offset > last_pos) {
      // Insert text token between matches
      const textToken = new Token('text', '', 0);
      textToken.content = str.slice(last_pos, offset);
      nodes.push(textToken);
    }
    src = (src || '').trim();
    const customToken = new Token(tokenName, '', 0);
    customToken.attrs = {
      alt,
      title,
      src
    };
    if (tags) {
      const meta = getMetadata(tags);
      if (!src.includes('http')) {
        meta.id = src;
        delete customToken.attrs.src;
      }
      customToken.meta = meta;
    }
    nodes.push(customToken);
    last_pos = offset + match.length;
    return '';
  });
  if (last_pos < str.length) {
    // Add text after matches 
    const token = new Token('text', '', 0);
    token.content = str.slice(last_pos);
    nodes.push(token);
  }
  return nodes;
}
function getMetadata(content) {
  const strRegex = RegExp('\".*\"|\'.*\'', 'g'); // match quoted inputs
  let match;
  while ((match = strRegex.exec(content)) !== null) {
    const str = match[0];
    content = content.replace(str, encodeURI(str));
  }
  const parts = content.split(' ').map(v => v.trim()).filter(v => v.length > 0) || [];
  const metadata = {};
  for (const part of parts) {
    let [key, value] = part.split('=').map(v => v.trim());
    if (value == null) {
      continue;
    }
    try {
      value = decodeURI(value);
    } catch (e) {}
    metadata[key] = value;
  }
  return metadata;
}
