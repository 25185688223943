<div class="uf-app-bar flat">
	<div class="title">Data Source Display</div>
</div>

<div class="uf-grid pad">
	<uf-blockquote icon="infoSolid" content="This determines how your Data Source options will display in the form. This data will be saved in the form for the selected Data Source option." class="col-12 info" />
	@if (featureDisplayControl.length) {
		<div class="col-12">
			<table class="uf-table inputs accent">
				<thead>
					<tr>
						<th>Parameter</th>
						<th>Value</th>
					</tr>
				</thead>
				<tbody>
					@for (featureDisplay of featureDisplayControl.controls; track featureDisplay; let i = $index) {
						<tr>
							<ng-container
								*ngTemplateOutlet="
									row;
									context: {
										control: featureDisplay,
										index: i,
									}
								" />
						</tr>
					}
				</tbody>
			</table>
		</div>
	}
</div>

<ng-template #row let-control="control" let-index="index">
	<ng-container [formGroup]="control">
		<td class="input-cell">
			<div class="row center-all">
				<div class="suffix pad-left">*</div>
				<uf-text [formControlName]="argControlKeys.Key" [disabled]="true" class="table-cell grow" />
			</div>
		</td>
		<td class="input-cell">
			<uf-select [options]="dataSourceDisplayOptions" [formControlName]="argControlKeys.Value" class="table-cell" />
		</td>
	</ng-container>
</ng-template>
