"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fromZonedTime = void 0;
const index_js_1 = require("../toDate/index.js");
const index_js_2 = require("../_lib/tzPattern/index.js");
const index_js_3 = require("../_lib/tzParseTimezone/index.js");
const index_js_4 = require("../_lib/newDateUTC/index.js");
/**
 * @name fromZonedTime
 * @category Time Zone Helpers
 * @summary Get the UTC date/time from a date representing local time in a given time zone
 *
 * @description
 * Returns a date instance with the UTC time of the provided date of which the values
 * represented the local time in the time zone specified. In other words, if the input
 * date represented local time in time zone, the timestamp of the output date will
 * give the equivalent UTC of that local time regardless of the current system time zone.
 *
 * @param date the date with values representing the local time
 * @param timeZone the time zone of this local time, can be an offset or IANA time zone
 * @param options the object with options. See [Options]{@link https://date-fns.org/docs/Options}
 * @param {0|1|2} [options.additionalDigits=2] - passed to `toDate`. See [toDate]{@link https://date-fns.org/docs/toDate}
 * @throws {TypeError} 2 arguments required
 * @throws {RangeError} `options.additionalDigits` must be 0, 1 or 2
 *
 * @example
 * // In June 10am in Los Angeles is 5pm UTC
 * const result = fromZonedTime(new Date(2014, 5, 25, 10, 0, 0), 'America/Los_Angeles')
 * //=> 2014-06-25T17:00:00.000Z
 */
function fromZonedTime(date, timeZone, options) {
  if (typeof date === 'string' && !date.match(index_js_2.tzPattern)) {
    return (0, index_js_1.toDate)(date, Object.assign(Object.assign({}, options), {
      timeZone
    }));
  }
  date = (0, index_js_1.toDate)(date, options);
  const utc = (0, index_js_4.newDateUTC)(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()).getTime();
  const offsetMilliseconds = (0, index_js_3.tzParseTimezone)(timeZone, new Date(utc));
  return new Date(utc + offsetMilliseconds);
}
exports.fromZonedTime = fromZonedTime;