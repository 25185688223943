import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, CompanyIdentifiers, DataDescriptorService, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup, UfMarkdownEditorComponent, UfTextareaComponent } from '@unifii/library/common';
import { DataSourceType, FieldType, Option, Schema, UserReference, ensureUfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ActivityType, UcClaimConfig, UcFormBucketClient, UcRoles, UcUserClaims, UcUsers, UcWorkflow, WorkflowNotification, WorkflowNotificationConditionType, WorkflowNotificationDeliveryMethod, WorkflowNotificationRecipientClaimMatchType, WorkflowNotificationRecipientFormData, WorkflowNotificationRecipientFormDataType, WorkflowNotificationRecipientType } from 'client';
import { EditData, GlossaryComponent, GlossaryEntry, SaveOption, SaveOptionType, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { ConsoleNameLabel } from 'constant';
import { appendSuffixCopy, reloadCurrentRoute } from 'pages/utils';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';
import { TitleService } from 'services/title.service';

import { WorkflowActivityTableManager } from '../workflow-activity-table-manager';
import { buildHeaderConfig } from '../workflow-utils';

import { WorkflowNotificationClaimMatchTypes, WorkflowNotificationConditions, WorkflowNotificationFormDataOptions, WorkflowNotificationRecipientOptions, WorkflowNotificationUserManagementClaimMatchTypes, WorkflowNotificationUserManagementConditions, WorkflowNotificationUserManagementRecipientOptions } from './workflow-notification-constants';
import { ControlKeys, WorkflowNotificationFormController, WorkflowNotificationModel, WorkflowNotificationRecipientClaimModel, WorkflowNotificationRecipientsModel } from './workflow-notification.controller';

interface MessageGroup {
	title: UfControl;
	body: UfControl;
	attachFormAsPdf: UfControl;
	replyTo: UfControl;
}

@Component({
	selector: 'uc-workflow-notification',
	templateUrl: 'workflow-notification.html',
	providers: [WorkflowNotificationFormController],
	standalone: false,
})
export class WorkflowNotificationComponent implements EditData, OnInit, OnDestroy {

	@ViewChild('emailMarkdownEditor', { static: false }) emailMarkdownEditor: UfMarkdownEditorComponent;
	@ViewChild('pushTextArea', { static: false }) pushTextArea: UfTextareaComponent;

	protected readonly consoleNameLabel = ConsoleNameLabel;
	protected readonly arePushNotificationsEnabled = inject(ContextService).tenantSettings?.arePushNotificationsEnabled ?? false;
	protected readonly controlKeys = ControlKeys;
	protected readonly workflowNotificationDeliveryMethod = WorkflowNotificationDeliveryMethod;
	protected readonly workflowNotificationRecipientType = WorkflowNotificationRecipientType;
	protected readonly workflowNotificationRecipientFormDataType = WorkflowNotificationRecipientFormDataType;
	protected readonly workflowNotificationRecipientClaimMatchType = WorkflowNotificationRecipientClaimMatchType;
	protected readonly workflowNotificationConditionType = WorkflowNotificationConditionType;
	protected readonly formDataTypes: Option[] = WorkflowNotificationFormDataOptions;
	protected readonly glossaryTitle = 'Glossary of available data';
	protected readonly glossaryEntries: GlossaryEntry[] = [{
		key: '{{id}}', value: 'Unique form identifier (eg. edb645a3-7dab-41ac-a6b0-21cb049bba40)',
	}, {
		key: '{{formNumber}}', value: 'Custom form number (eg. ABC-00001)',
	}, {
		key: '{{formLabel}}', value: 'The form name',
	}, {
		key: '{{previousState}}', value: 'Start Status (eg. Start)',
	}, {
		key: '{{action}}', value: 'Action performed (eg. Submit)',
	}, {
		key: '{{currentState}}', value: 'Target Status (eg. Submitted)',
	}, {
		key: '{{lastModifiedBy}}', value: 'The username of the user who last modified the form',
	}, {
		key: '{{lastModifiedAt}}', value: 'The Date/Time when the form was last modified',
	}, {
		key: '{{link}}', value: 'The URL that takes the notification recipient to the form',
	}, {
		key: '{{route}}', value: 'The unique URL path that takes the notification recipient to the form. It requires a hardcoded base URL to work. (eg. https://discover.unifiiplatform.com/{{route}})',
	}, {
		key: '{{result}}', value: 'The result of the workflow change (eg. Approved)',
	}, {
		key: '{{data.}}', value: 'Add form data using the field identifier after the full stop',
	}];

	protected schema?: Schema;
	protected root: UfControlGroup;
	protected bucketsResult: string[];
	protected error: Error | null = null;
	protected breadcrumbs: Breadcrumb[];
	protected users: UserReference[];
	protected workflowNotification: WorkflowNotification;
	protected filteredRoles: string[];
	protected filteredUserClaims: string[];
	protected filteredEmailFields: Option[];
	protected filteredHierarchyFields: Option[];
	protected filteredUserDataSources: string[];
	protected filteredClaimDataSources: string[];
	protected filteredFormDataClaimDataSources: string[];
	protected recipientTypes: Option[] = [];
	protected isUserManagementNotification = false;
	protected conditionTypes: string[] = [];
	protected claimMatchOptions: Option[] = [];
	protected hierarchyFields: Option[];
	protected filteredCompanyDataSources: string[];

	private emailFields: Option[];
	private userDataSources: string[];
	private subscriptions = new Subscription();
	private claimDataSources: string[];
	private hasSaveAndNextButton: boolean;
	private userClaims?: UcClaimConfig[];
	private companyDataSources: string[];
	private isCompanyBucket: boolean;
	private router = inject(Router);
	private ucUserClaims = inject(UcUserClaims);
	private ucRoles = inject(UcRoles);
	private route = inject(ActivatedRoute);
	private ucFormBucketClient = inject(UcFormBucketClient);
	private ucUsers = inject(UcUsers);
	private toastService = inject(ToastService);
	private modalService = inject(ModalService);
	private ucWorkflow = inject(UcWorkflow);
	private dialogs = inject(DialogsService);
	private formController = inject(WorkflowNotificationFormController);
	private breadcrumbService = inject(BreadcrumbService);
	private builderHeaderService = inject(BuilderHeaderService);
	private tableManager = inject<WorkflowActivityTableManager>(TableContainerManager);
	private dataDescriptorService = inject(DataDescriptorService);
	private context = inject(ContextService);
	private titleService = inject(TitleService);

	get edited() {
		return !!this.builderHeaderService.config.edited;
	}

	set edited(v: boolean) {
		this.builderHeaderService.config.edited = v;
	}

	ngOnInit() {
		this.subscriptions.add(this.route.paramMap.subscribe((params) => {
			void this.setup(params.get('type') === 'user-management');
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected get hasEmail(): UfControl {
		return (this.messages.get(ControlKeys.Email) as UfControlGroup).get(ControlKeys.HasContent) as UfControl;
	}

	protected get hasPushNotification(): UfControl {
		return (this.messages.get(ControlKeys.Push) as UfControlGroup).get(ControlKeys.HasContent) as UfControl;
	}

	protected get email(): MessageGroup {
		const emailGroup = this.messages.get(ControlKeys.Email) as UfControlGroup;

		return {
			title: emailGroup.get(ControlKeys.MessageTitle) as UfControl,
			body: emailGroup.get(ControlKeys.MessageBody) as UfControl,
			attachFormAsPdf: emailGroup.get(ControlKeys.MessageAttachFormAsPdf) as UfControl,
			replyTo: emailGroup.get(ControlKeys.ReplyTo) as UfControl,
		};
	}

	protected get pushNotification(): MessageGroup {
		const pushNotificationGroup = this.messages.get(ControlKeys.Push) as UfControlGroup;

		return {
			title: pushNotificationGroup.get(ControlKeys.MessageTitle) as UfControl,
			body: pushNotificationGroup.get(ControlKeys.MessageBody) as UfControl,
			attachFormAsPdf: pushNotificationGroup.get(ControlKeys.MessageAttachFormAsPdf) as UfControl,
			replyTo: pushNotificationGroup.get(ControlKeys.ReplyTo) as UfControl,
		};
	}

	protected get recipients(): UfControlArray {
		return this.root.get(ControlKeys.Recipients) as UfControlArray;
	}

	protected get canAddRecipient() {
		return this.isUserManagementNotification || !!this.schema;
	}

	protected recipientControl(index: number): UfControlGroup {
		return this.recipients.at(index) as UfControlGroup;
	}

	protected recipientLabel(index: number): string {
		const recipientModel = this.recipientControl(index).getRawValue() as WorkflowNotificationRecipientsModel | undefined;

		if (recipientModel?.type === WorkflowNotificationRecipientType.FormData) {
			return this.formDataTypes.find((option) =>
				(option.identifier as WorkflowNotificationRecipientFormDataType) === (recipientModel as WorkflowNotificationRecipientFormData | undefined)?.formData.type)?.name ?? '';
		}

		return this.recipientTypes.find((o) => (o.identifier as WorkflowNotificationRecipientType) === recipientModel?.type)?.name ?? '';
	}

	protected recipientTypeControl(index: number): UfControl {
		return (this.recipients.at(index) as UfControlGroup).get(ControlKeys.Type) as UfControl;
	}

	protected recipientEmailControl(index: number): UfControl {
		return (this.recipients.at(index) as UfControlGroup).get(ControlKeys.Email) as UfControl;
	}

	protected recipientClaimControl(index: number): UfControlGroup {
		return (this.recipients.at(index) as UfControlGroup).get(ControlKeys.Claim) as UfControlGroup;
	}

	protected recipientConditionsControls(index: number): UfControlArray {
		return (this.recipients.at(index) as UfControlGroup).get(ControlKeys.Conditions) as UfControlArray;
	}

	protected async removeRecipient(i: number) {
		if (!await this.dialogs.confirmDelete()) {
			return;
		}
		this.recipients.removeAt(i);
	}

	protected addRecipient(option: Option) {
		const type = option.identifier as WorkflowNotificationRecipientType;
		let formDataType: WorkflowNotificationRecipientFormDataType;

		switch (type) {
			case WorkflowNotificationRecipientType.EmailField:
				formDataType = WorkflowNotificationRecipientFormDataType.Email; break;
			case WorkflowNotificationRecipientType.UserDatasource:
				formDataType = WorkflowNotificationRecipientFormDataType.User; break;
			case WorkflowNotificationRecipientType.UserDatasourceManager:
				formDataType = WorkflowNotificationRecipientFormDataType.UserManager; break;
			default:
				this.recipients.push(this.formController.buildReceiptControl({ type } as any, this.isUserManagementNotification));

				return;
		}

		this.recipients.push(
			this.formController.buildReceiptControl({
				type: WorkflowNotificationRecipientType.FormData,
				formData: {
					type: formDataType,
					value: '',
				}, liveOnly: false,
			}, this.isUserManagementNotification),
		);
	}

	protected addCondition(type: WorkflowNotificationRecipientType, conditions: UfControlArray) {
		conditions.push(this.formController.addConditionControl({
			type,
		} as any, this.isUserManagementNotification, this.isCompanyBucket));
	}

	protected isFormDataUserDataSource(recipientGroupControl: UfControlGroup): boolean {
		const type = recipientGroupControl.get(ControlKeys.FormData)?.get(ControlKeys.Type)?.getRawValue() as WorkflowNotificationRecipientFormDataType | undefined;

		return !!type && [
			WorkflowNotificationRecipientFormDataType.User,
			WorkflowNotificationRecipientFormDataType.UserManager,
		].includes(type);
	}

	protected isValueClaim(claimControlGroup: UfControlGroup) {
		const claimModel = claimControlGroup.getRawValue() as WorkflowNotificationRecipientClaimModel | undefined;

		return claimModel?.claim.matchType != null && claimModel.claim.matchType === WorkflowNotificationRecipientClaimMatchType.Value;
	}

	protected isMatchAgainst(claimControlGroup: UfControlGroup) {
		const claimModel = claimControlGroup.getRawValue() as WorkflowNotificationRecipientClaimModel | undefined;

		return claimModel?.claim.matchType != null &&
            [
            	WorkflowNotificationRecipientClaimMatchType.CreatedBy,
            	WorkflowNotificationRecipientClaimMatchType.LastModifiedBy,

            ].includes(claimModel.claim.matchType);
	}

	protected async changeClaim(claimType: string, controlGroup: UfControlGroup) {
		if (!this.userClaims) {
			this.userClaims = await this.loadAndMapClaims();
		}

		const claimConfig = this.userClaims.find((userClaim) => userClaim.type === claimType);

		controlGroup.get(ControlKeys.ClaimConfig)?.setValue(claimConfig);
	}

	protected searchEmailFields(query: string) {
		this.filteredEmailFields = this.emailFields.filter((field) => !query || field.name.toLowerCase().includes(query.toLowerCase()));
	}

	protected searchHierarchyField(query: string) {
		this.filteredHierarchyFields = this.hierarchyFields.filter((field) => !query || field.name.toLowerCase().includes(query.toLowerCase()));
	}

	protected searchCompanyDataSource(query: string) {
		this.filteredCompanyDataSources = this.companyDataSources.filter((company) => !query || company.toLowerCase().includes(query.toLowerCase()));
	}

	protected glossarySelected(target: WorkflowNotificationDeliveryMethod, entry: GlossaryEntry) {

		if ((target === WorkflowNotificationDeliveryMethod.Email && !this.emailMarkdownEditor) ||
            (target === WorkflowNotificationDeliveryMethod.Push && !this.pushTextArea)) {
			return;
		}

		if (target === WorkflowNotificationDeliveryMethod.Email) {
			this.emailMarkdownEditor.insertCustom(entry.key + ' ');

			return;
		}

		const index = this.pushTextArea.caretPosition;
		const value = this.pushTextArea.value as string || '';

		if (this.pushTextArea.focused && index) {
			this.pushTextArea.value = value.substr(0, index) + entry.key + ' ' + value.substr(index);
		} else {
			this.pushTextArea.value = value + entry.key + ' ';
		}
	}

	protected async searchUserClaims(q: string) {
		this.filteredUserClaims = (await this.ucUserClaims.list({ params: { q } })).map(({ type }) => type);
	}

	protected previewMessage(type: WorkflowNotificationDeliveryMethod) {
		let title;
		let message;

		switch (type) {
			case WorkflowNotificationDeliveryMethod.Email:
				title = this.email.title?.value;
				message = this.email.body?.value;
				break;
			case WorkflowNotificationDeliveryMethod.Push:
				title = this.pushNotification.title?.value;
				message = this.pushNotification.body?.value;
				break;

		}

		if (!title || !message) {
			return;
		}

		void this.modalService.openAlert({ title, message });
	}

	protected async searchBuckets(q: string) {
		const formBuckets = await this.ucFormBucketClient.list({ params: { q } });

		this.bucketsResult = formBuckets.map(({ id }) => id.toString());
	}

	protected async bucketChange(bucket: string) {
		this.schema = bucket ? await this.ucFormBucketClient.get(bucket) : undefined;

		void this.loadFormDataFields();
	}

	private get messages(): UfControlGroup {
		return this.root.get(ControlKeys.Messages) as UfControlGroup;
	}

	protected async searchUsers(query?: string) {
		this.users = (await this.ucUsers.get(query, 'username'))
			.map((user) => ({ id: user.id ?? '', username: user.username }));
	}

	protected searchClaim(query?: string) {
		this.filteredClaimDataSources = this.claimDataSources.filter((item) => !query || item.toLowerCase().includes(query.toLowerCase()));
	}

	protected searchFormDataClaim(query?: string) {
		this.filteredFormDataClaimDataSources = this.claimDataSources.filter((item) => !query || item.toLowerCase().includes(query.toLowerCase())).map((item) => `${item}._id`);
	}

	protected async searchRole(query?: string) {
		this.filteredRoles = (await this.ucRoles.get(query)).map((r) => r.name);
	}

	protected searchUserDataSource(query?: string) {
		this.filteredUserDataSources = this.userDataSources.filter((datasource) => !query || datasource.toLowerCase().includes(query.toLowerCase()));
	}

	protected async showGlossary(target: WorkflowNotificationDeliveryMethod) {
		const entry = await this.modalService.openMedium(
			GlossaryComponent, {
				title: this.glossaryTitle,
				glossary: this.glossaryEntries,
			});

		if (!entry) {
			return;
		}

		this.glossarySelected(target, entry);
	}

	private async save(saveOption?: SaveOption) {
		this.error = null;
		this.root.setSubmitted();

		if (this.root.invalid) {
			return;
		}

		try {
			const notification = this.root.getRawValue();
			const notificationData = this.formController.toDataModel(notification);

			notificationData.type = ActivityType.Notification;

			let updatedNotification;

			if (notification.id) {
				updatedNotification = await this.ucWorkflow.updateActivity(notificationData);
				this.toastService.success('Workflow notification updated successfully');
				this.tableManager.updateItem.next(updatedNotification);
			} else {
				updatedNotification = await this.ucWorkflow.addActivity(notificationData);
				this.toastService.success('Workflow notification added successfully');
				this.tableManager.reload.next();
			}

			this.edited = false;

			if (!saveOption) {
				if (!notification.id) {
					void this.router.navigate(['..', updatedNotification.id], { relativeTo: this.route });
				} else {
					this.builderHeaderService.updateConfig({
						breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [updatedNotification.consoleName]),
						lastModifiedAt: updatedNotification.lastModifiedAt,
						lastModifiedBy: updatedNotification.lastModifiedBy,
					});
				}

				this.titleService.updateTitle(updatedNotification.consoleName);

				return;
			}

			switch (saveOption.id) {
				case SaveOptionType.New:
					if (this.router.url.endsWith('/new')) {
						reloadCurrentRoute(this.router);

						return;
					} else {
						void this.router.navigate(['../', 'new'], { relativeTo: this.route });

						return;
					}
				case SaveOptionType.Next:
				{
					const nextId = this.tableManager.getNextItem(updatedNotification.id)?.id;

					if (nextId) {
						void this.router.navigate(['..', nextId], { relativeTo: this.route });

						return;
					}
					break;
				}
			}

			void this.router.navigate(['..'], { relativeTo: this.route });
		} catch (e) {
			this.error = ensureUfError(e, 'Error trying to save Workflow Notification');
		}
	}

	private loadAndMapClaims() {
		return this.ucUserClaims.list();
	}

	private async loadFormDataFields() {
		this.userDataSources = [];
		this.emailFields = [];
		this.hierarchyFields = [];
		this.companyDataSources = [];
		this.claimDataSources = [];

		if (!this.schema) {
			return;
		}

		const descriptor = await this.dataDescriptorService.getBucketDataDescriptor(this.schema.bucket);

		if (!descriptor) {
			return;
		}

		this.isCompanyBucket = !!this.schema.bucket && !!this.context.project && this.schema.bucket === this.context.project.companyBucket;

		if (this.isCompanyBucket) {
			this.companyDataSources.push(CompanyIdentifiers.Id);
		}

		for (const property of descriptor.propertyDescriptors) {
			if (property.type === FieldType.Lookup && property.sourceConfig?.type) {
				if (property.sourceConfig.type === DataSourceType.Users) {
					this.userDataSources.push(property.identifier);
				} else if (property.sourceConfig.type === DataSourceType.UserClaims) {
					this.claimDataSources.push(property.identifier);
				} else if (property.sourceConfig.type === DataSourceType.Bucket && this.context.project?.companyBucket === property.sourceConfig.id) {
					this.companyDataSources.push(property.identifier);
				}
			} else if (property.type === FieldType.Email) {
				this.emailFields.push({ identifier: property.identifier, name: property.display ?? property.label });
			} else if (property.type === FieldType.Hierarchy) {
				this.hierarchyFields.push({ identifier: property.identifier, name: property.display ?? property.label });
			}
		}

	}

	private async setup(isUserManagement: boolean) {
		this.builderHeaderService.init();

		const { id, duplicate } = this.route.snapshot.params;

		this.hasSaveAndNextButton = id !== 'new' && !duplicate;
		let workflowNotification: WorkflowNotification;

		try {
			workflowNotification = await this.getNotification(id);
		} catch (e) {
			this.error = useDefaultErrorMessage(e);

			return;
		}

		let formModel: WorkflowNotificationModel | null = null;

		try {
			if (id !== 'new') {
				formModel = await this.formController.toFormModel(workflowNotification);

				this.isUserManagementNotification = !formModel.bucket;

				if (duplicate) {
					formModel.id = undefined;
					formModel.consoleName = appendSuffixCopy({ label: formModel.consoleName });
				}

				if (workflowNotification.bucket) {
					this.schema = await this.ucFormBucketClient.get(workflowNotification.bucket);
				}
			} else {
				this.isUserManagementNotification = isUserManagement;
			}

			this.titleService.updateTitle(workflowNotification.consoleName);

			await this.loadFormDataFields();

			this.root = this.formController.buildRoot(formModel, this.isUserManagementNotification);

			this.recipientTypes = this.isUserManagementNotification ? WorkflowNotificationUserManagementRecipientOptions : WorkflowNotificationRecipientOptions;
			this.conditionTypes = this.isUserManagementNotification ? WorkflowNotificationUserManagementConditions : WorkflowNotificationConditions;
			this.claimMatchOptions = this.isUserManagementNotification ? WorkflowNotificationUserManagementClaimMatchTypes : WorkflowNotificationClaimMatchTypes;

			this.subscriptions.add(this.root.valueChanges.subscribe(() => { this.edited = true; }));
		} catch (e) {
			this.error = useDefaultErrorMessage(e);

			return;
		}

		// Set breadcrumbs
		this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => void this.save(saveOption)));
		this.buildHeaderConfig(workflowNotification);
	}

	private getNotification(id: string) {
		if (id === 'new') {
			return {
				consoleName: 'New',
			} as any as WorkflowNotification;
		}

		return this.ucWorkflow.getActivity<WorkflowNotification>(id);
	}

	private buildHeaderConfig(notification: WorkflowNotification) {
		const headerConfig = buildHeaderConfig(notification, this.hasSaveAndNextButton);

		headerConfig.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [headerConfig.title]);
		this.builderHeaderService.buildConfig(headerConfig);
	}

}
