"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormStyle = exports.HierarchyUnitState = void 0;
var HierarchyUnitState;
(function (HierarchyUnitState) {
  HierarchyUnitState["Active"] = "Active";
  HierarchyUnitState["Inactive"] = "Inactive";
})(HierarchyUnitState || (exports.HierarchyUnitState = HierarchyUnitState = {}));
var FormStyle;
(function (FormStyle) {
  FormStyle["FullForm"] = "fullForm";
  FormStyle["Summary"] = "summary";
})(FormStyle || (exports.FormStyle = FormStyle = {}));
