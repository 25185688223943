import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { DataDescriptor, DataPropertyDescriptor, UfControl, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { AstNode, NodeType, QueryOperators, TableDetailModule, TableDetailTemplate, TableSourceType, objectKeys } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcProject, UcRoles, UcTable } from 'client';

import { TableEditorService } from '../table-editor.service';

export enum TableModuleControlKeys {
	Title = 'title',
	Limit = 'limit',
	Roles = 'roles',
	CanAdd = 'canAdd',
	CanAddError = 'canAddError',
}

@Component({
	selector: 'uc-table-module-table',
	templateUrl: 'table-module-table.html',
	standalone: false,
})
export class TableModuleTableComponent implements OnInit, OnDestroy {

	@Input({ required: true }) parentControl: UfControlGroup;
	@Input({ required: true }) module: TableDetailModule;
	@Input({ required: true }) set template(v: TableDetailTemplate) {
		this._template = v;

		this.isPageViewTemplate = v !== TableDetailTemplate.MenuView;

		if (this.parentControl) {
			this.enableDisableControlsByTemplate(v);
		}
	}

	@Output() moduleChange = new EventEmitter<TableDetailModule>();

	protected readonly controlKeys = TableModuleControlKeys;

	protected error: boolean;
	protected ready: boolean;
	protected isPageViewTemplate: boolean;
	protected table: UcTable;
	protected tableLink: any[];
	protected showCanAdd: boolean;
	protected rolesResult: string[];
	protected staticFilters: DataPropertyDescriptor[] = [];
	protected canAddControl: UfControl;

	private subscriptions = new Subscription();
	private _template: TableDetailTemplate;

	private fb = inject(UfFormBuilder);
	private ucProject = inject(UcProject);
	private ucRoles = inject(UcRoles);
	private tableEditorService = inject(TableEditorService);

	async ngOnInit() {

		try {
			this.table = await this.ucProject.getTable(this.module.identifier);
			this.tableLink = ['..', '..', this.table.id];
			const dataDescriptor = await this.getTableEditorConfig(this.table);

			if (dataDescriptor) {
				this.staticFilters = dataDescriptor.propertyDescriptors.filter((pd) => pd.asStaticFilter);
			}

			if (!this.module.limit && this._template === TableDetailTemplate.PageView) {
				this.module.limit = 5;
			}

			this.showCanAdd = this.table.sourceType === TableSourceType.Bucket;

			this.buildFormGroupControl();

			this.ready = true;
		} catch (e) {
			console.error(e);
			this.parentControl.addControl('TableModuleDetailError', this.fb.control(null, ValidatorFunctions.custom(() => false, 'Table not found')));
			this.error = true;
		}

		this.subscriptions.add(this.parentControl.valueChanges.subscribe(() => {
			this.module = Object.assign(this.module, this.parentControl.getRawValue());
			delete (this.module as any).expanded;
			for (const key of objectKeys(this.module)) {
				if (this.module[key] == null) {
					// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
					delete this.module[key];
				}
			}
			this.moduleChange.emit(this.module);
		}));

		this.enableDisableControlsByTemplate(this._template);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected async findRoles(query: string | null) {
		this.rolesResult = (await this.ucRoles.get(query ?? undefined)).map((r) => r.name);
	}

	protected onFilterChange() {
		const addControl = this.parentControl.get(TableModuleControlKeys.CanAdd);

		if (!addControl) {
			return;
		}
		addControl.updateValueAndValidity();
		addControl.markAsTouched();
	}

	private hasEqualToDetailId(filter?: AstNode): boolean {
		if (!filter?.args) {
			return false;
		}

		const isOperator = filter.type === NodeType.Operator;
		const isEqual = filter.op === QueryOperators.Equal;
		const hasDetailIdValue = filter.args.find((arg) => arg.type === NodeType.Expression && arg.value === '$detail.id') != null;

		if (isOperator && isEqual && hasDetailIdValue) {
			return true;
		}

		return filter.args.some((arg) => this.hasEqualToDetailId(arg));
	}

	private getTableEditorConfig(table: UcTable): Promise<DataDescriptor | undefined> {
		switch (table.sourceType) {
			case TableSourceType.Bucket:
				return this.tableEditorService.getBucketDataDescriptor(table.source as string);
			case TableSourceType.Users:
				return this.tableEditorService.getUserDataDescriptor();
			case TableSourceType.Company:
				return this.tableEditorService.getCompanyDataDescriptor();
		}
	}

	private enableDisableControlsByTemplate(template: TableDetailTemplate) {
		if (template === TableDetailTemplate.MenuView) {
			this.parentControl.get(TableModuleControlKeys.Limit)?.disable();
			this.parentControl.get(TableModuleControlKeys.CanAdd)?.setValue(true);
		} else {
			this.parentControl.get(TableModuleControlKeys.Limit)?.enable();
		}
	}

	private buildFormGroupControl() {

		this.canAddControl = this.fb.control(this.module.canAdd,
			ValidatorFunctions.custom((v) => !v || this.hasEqualToDetailId(this.module.filter), 'The filter is missing the $detail.id condition'),
		);

		this.parentControl.addControl(TableModuleControlKeys.Title, this.fb.control(this.module.title));
		this.parentControl.addControl(TableModuleControlKeys.Limit, this.fb.control(this.module.limit, ValidatorFunctions.custom((v) => v >= 1 && v <= 100, 'Between 1 and 100 rows')));
		this.parentControl.addControl(TableModuleControlKeys.Roles, this.fb.control(this.module.roles));
		this.parentControl.addControl(TableModuleControlKeys.CanAdd, this.fb.control(this.module.canAdd));
		this.parentControl.addControl(TableModuleControlKeys.CanAddError, this.canAddControl);

		this.subscriptions.add(this.parentControl.get(TableModuleControlKeys.CanAdd)?.valueChanges.subscribe((v) => this.canAddControl.setValue(v)));

		this.parentControl.setSubmitted(true);
	}

}
