import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { HierarchyUnitProvider, Modal, ModalData, ModalRuntime, ModalService, UfControl, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { ErrorType, HierarchyUnitInfo, ensureUfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { kebabize } from 'helpers/field-identifier-helper';

enum UnitKey {
	Label = 'label',
	Id = 'id',
}

@Component({
	templateUrl: './hierarchy-finder-unit-input.html',
	standalone: false,
})
export class HierarchyFinderUnitInputComponent implements Modal<HierarchyUnitInfo, HierarchyUnitInfo>, OnDestroy, OnInit {

	runtime = inject<ModalRuntime<HierarchyUnitInfo, HierarchyUnitInfo>>(ModalRuntime);
	data = inject<HierarchyUnitInfo>(ModalData);
	nameControl: UfControl;
	labelControl: UfControl;
	form: UfControlGroup;
	subscriptions: Subscription = new Subscription();

	protected readonly unitControlKeys = UnitKey;
	protected readonly identifierMaxLength = 32;

	private ufb = inject(UfFormBuilder);
	private modalService = inject(ModalService);
	private hierarchyUnitProvider = inject(HierarchyUnitProvider);

	ngOnInit() {
		const labelControl = this.ufb.control(this.data.label, ValidatorFunctions.required('Label is required'));
		const identifierControl = this.ufb.control({ value: this.data.id, disabled: !this.data.id },
			ValidatorFunctions.compose([
				ValidatorFunctions.required('Identifier is required'),
				ValidatorFunctions.pattern(/^[A-Za-z0-9_-]+$/, 'Identifier contains invalid characters'),
				ValidatorFunctions.maxLength(this.identifierMaxLength, `Identifier can't be longer than ${this.identifierMaxLength}`),
			]),
		);

		this.form = this.ufb.group({
			[UnitKey.Label]: labelControl,
			[UnitKey.Id]: identifierControl,
		});

		if (!this.data.id) {
			this.subscriptions.add(labelControl.valueChanges.subscribe((value) => {
				if (!identifierControl.touched) {
					identifierControl.setValue(kebabize(value));
				}
			}));
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	protected async add() {
		this.form.setSubmitted();
		if (this.form.invalid) {
			return;
		}

		const { label, id } = this.form.getRawValue();

		if (this.form.get(UnitKey.Id)?.enabled && !await this.isNewUnit(id)) {
			return;
		}

		this.runtime.close({ ...this.data, label, id });
	}

	private async isNewUnit(id: string): Promise<boolean> {

		try {
			await this.hierarchyUnitProvider.getUnit(id);
			throw new Error('Identifier needs to be unique');
		} catch (e: unknown) {

			const error = ensureUfError(e, 'Something went wrong saving unit');

			if (error.type === ErrorType.NotFound) {
				return true;
			}

			void this.modalService.openAlert({
				title: 'Error Saving Unit',
				message: error.message,
			});
		}

		return false;
	}

}
