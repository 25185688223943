<uc-user-access-box [tenantSettings]="undefined">
	<ng-container content>
		<form (ngSubmit)="changePassword()" class="uf-grid">
			<uf-create-password [control]="changePasswordControl" [config]="changePasswordConfig" class="col-12" />

			<div class="col-12 col center-all">
				<button [disabled]="inProgress" type="submit" class="uf-button primary">Submit</button>
				<button [class.disabled]="inProgress" (click)="logout()" type="button" class="uf-button tertiary gap-top">Back to login</button>
			</div>

			@if (error) {
				<uf-message [content]="error" icon="errorSolid" class="error col-12" />
			}
		</form>
	</ng-container>
</uc-user-access-box>
