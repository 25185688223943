<uf-panel class="container">
	<div class="grid--fixed">
		<div class="content-pane">
			<div class="col-1of1 body-copy">
				<div class="section-label">Typography</div>
				<h1>H1 Display Title totam rem</h1>
				<h2>H2 Page Title totam rem et quasi architecto beatae vitae dicta sunt explicabo.</h2>
				<h3>H3 Section Title totam rem et quasi architecto beatae vitae dicta sunt explicabo.</h3>
				<h4>H4 Sub-Section Title totam rem et quasi architecto vitae dicta sunt explicabo.</h4>
				<h5>H5 Small Title totam rem et quasi architecto beatae vitae dicta sunt explicabo.</h5>
				<h6>H6 Small Title totam rem et quasi architecto beatae vitae dicta sunt explicabo.</h6>
				<p class="large">Maecenas faucibus mollis interdum. Duis mollis, est non commodo luctus, nisi erat porttitor.</p>
				<p>Sed posuere consectetur est at lobortis. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vestibulum id ligula porta felis euismod semper. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel <a>augue laoreet</a> rutrum faucibus dolor auctor.</p>
				<ul>
					<li>
						Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Inline Text Link Nulla vitae elit libero, a pharetra augue.
						<ul>
							<li>Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehicula ut id elit.</li>
						</ul>
					</li>
				</ul>

				<div class="fieldset--readonly">
					<div class="fieldset-item">
						<div class="fieldset-label">Fieldset label</div>
						<div class="fieldset-value">My value</div>
					</div>
					<div class="fieldset-item--small">
						<div class="fieldset-label">Fieldset label</div>
						<div class="fieldset-value">My value</div>
					</div>
				</div>

				<div class="section-label">Buttons</div>
				<div class="row padded--vertical">
					<button type="button" class="uf-button">Default</button>
					<button type="button" class="uf-button primary">Primary</button>
					<button type="button" class="uf-button tertiary">Tertiary</button>
				</div>

				<div class="col padded--vertical">
					<div class="shrink row">
						<button type="button" class="uf-button">Small</button>
						<a class="uf-button">Small</a>
					</div>
					<div class="shrink row">
						<button type="button" class="uf-button">Regular</button>
						<a class="uf-button">Regular</a>
					</div>

					<div class="shrink row">
						<button type="button" class="uf-button small--large">Large</button>
						<a class="uf-button small--large">Large</a>
					</div>
				</div>
				<div class="section-label">Action buttons</div>
				<div class="row padded--vertical">
					<button type="button" class="uf-action" title="Click Me">
						<uf-icon name="add" />
					</button>
					<button type="button" class="uf-action primary" title="Click Me">
						<uf-icon name="add" />
					</button>
					<button type="button" class="uf-action tertiary" title="Click Me">
						<uf-icon name="add" />
					</button>
				</div>

				<div class="section-label">Button Groups</div>
				<div class="row padded--vertical">
					<div class="uf-button-group--small">
						<button type="button">Revert</button>
						<button type="button">Remove</button>
						<button type="button">Save</button>
					</div>
				</div>
				<div class="row padded--vertical">
					<div class="uf-button-group--small">
						<button type="button">Revert</button>
						<button type="button">Remove</button>
						<button type="button">Save</button>
					</div>
				</div>
				<div class="row padded--vertical">
					<div class="uf-button-group--small">
						<button type="button">Revert<uf-icon name="close" /></button>
						<button type="button">Remove<uf-icon name="delete" /></button>
						<button type="button" class="uf-button primary">Save<uf-icon name="file" /></button>
					</div>
				</div>

				<div class="section-label">Iconography</div>
				<div class="row padded--vertical">
					<uf-icon name="address" class="big" />
					<uf-icon name="eye" class="big" />
					<uf-icon name="back" class="big" />
					<uf-icon name="warning" class="big" />
				</div>
				<div class="row padded--vertical">
					<uf-icon name="address" />
					<uf-icon name="eye" />
					<uf-icon name="back" />
					<uf-icon name="warning" />
				</div>
				<div class="row padded--vertical">
					<uf-icon name="address" />
					<uf-icon name="eye" />
					<uf-icon name="back" />
					<uf-icon name="warning" />
				</div>
				<div class="section-label">Loading Spinners</div>
				<div class="row padded--vertical">
					<uf-spinner />
					<uf-spinner type="dots" />
				</div>
				<div class="section-label">Form Inputs</div>
				<div class="form-inputs grid">
					<uf-text class="col-1of1" label="Basic input" />
					<uf-search class="col-1of1" label="Input with icon" />
					<uf-textarea class="col-1of1" label="Text Area" />
				</div>

				<div class="section-label">Draggable Lists</div>
				<ul class="uc-list">
					@for (listItem of listItems; track listItem) {
						<li class="uc-list-item{{ listItem.size }}--draggable padded--horizontal">Draggable</li>
					}
				</ul>

				<br />

				<div class="section-label">Draggable No-Shrink Lists</div>
				<ul class="uc-list">
					@for (listItem of listItems; track listItem) {
						<li class="uc-list-item{{ listItem.size }}--draggable--no-shrink padded--horizontal">Draggable No Shrink</li>
					}
				</ul>
			</div>
		</div>
		<div class="grid--fixed">
			<div class="col-1of1">
				<div class="section-label">Fixed Grid</div>
			</div>
		</div>
		<div class="grid--fixed grid-demo">
			@for (i of columns; track i) {
				<div [ngClass]="'col-1of' + getColNumber(i)">
					<div>Col 1/{{ getColNumber(i) }}</div>
				</div>
			}
		</div>
		<div class="grid--fixed">
			<div class="col-1of1">
				<div class="section-label">Fluid Grid</div>
			</div>
		</div>
		<div class="grid grid-demo">
			@for (i of columns; track i) {
				<div [ngClass]="'col-1of' + getColNumber(i)">
					<div>Col 1/{{ getColNumber(i) }}</div>
				</div>
			}
		</div>
	</div>
</uf-panel>
