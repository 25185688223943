import { Directive, EventEmitter, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, CanDeactivateFn, Params, Router } from '@angular/router';
import { ModalService } from '@unifii/library/common';
import { CompoundType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Config } from 'app-config';
import { CompoundInfo } from 'client';
import { EditMode, SaveAndApprove, SaveAndClose, SaveAndNew, SaveAndNext, SaveOption, SaveOptionType, getEditMode } from 'components';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { FieldReference } from 'helpers/helpers';
import { reloadCurrentRoute } from 'pages/utils';
import { DialogsService } from 'services/dialogs.service';
import { UcTableManager } from 'services/table/models';

/* eslint-disable @typescript-eslint/member-ordering */
@Directive()
export abstract class BuilderBasic implements OnDestroy {

	abstract type: CompoundType;
	abstract subject: BuilderCompoundSubjects;

	// The data edit mode for this builder instance
	editMode: EditMode;
	// List of available save option for this builder instance
	saveOptions: SaveOption[] = [];
	// Track all subscriptions, to be unsubscribed on ngDestroy life-cycle
	protected subscriptions = new Subscription();
	protected router?: Router;
	protected approveClicked = new EventEmitter<number>();
	protected resourceNotFoundMessage = 'Requested resource not found';

	// Child class methods
	removeField?(i?: BuilderEventInfo): void;
	selectField?(i?: BuilderEventInfo): void;

	constructor(
		public builderService: BuilderService,
		public modalService: ModalService,
		protected route: ActivatedRoute,
		protected tableManager: UcTableManager<CompoundInfo> | null,
	) {
		this.init(route.snapshot.params);
	}

	init(params: Params) {
		// Detect data mode
		if (params) {
			this.editMode = getEditMode(params);
		}

		// Default save options based on the edit mode
		this.saveOptions = this.editMode === EditMode.Existing ?
			[SaveAndClose, SaveAndApprove, SaveAndNext, SaveAndNew] :
			[SaveAndClose, SaveAndApprove, SaveAndNew];

		// Initialize memento service
		if (this.builderService.memento) {
			this.builderService.memento.reset();
		}

		// Field remove
		if (this.removeField != null) {
			this.subscriptions.add(this.builderService.fieldRemove.subscribe((i) => {
				if (this.removeField) {
					this.removeField(i);
				}
			}));
		}

		// Field removed
		this.subscriptions.add(this.builderService.fieldRemoved.subscribe((i) => {
			this.builderService.removeErrors(i.subject);
			this.builderService.fieldRefreshed.next({ subject: i.subject });
		}));

		// Field select
		if (this.selectField) {
			this.subscriptions.add(this.builderService.fieldSelect.subscribe((i) => {
				if (this.selectField) {
					this.selectField(i ?? undefined);
				}
			}));
		}
	}

	saved(item: any, saveOption?: SaveOption) {
		// Reset edited status
		this.builderService.memento.edited = false;

		// Update parent table
		if (this.editMode === EditMode.Existing) {
			// Mark this existing item as updated
			this.tableManager?.updateItem.next(item);
		} else {
			// Add this new item to the table
			this.tableManager?.reload.next();
		}

		if (this.editMode === EditMode.New || this.editMode === EditMode.Duplicate) {
			this.editMode = EditMode.Existing;
		}

		// Nothing to do on save
		if (!saveOption) {
			this.builderService.ready.next();

			return;
		}

		if (!this.router) {
			return;
		}

		switch (saveOption.id) {
			case SaveOptionType.New:
				if (this.router.url.endsWith('/new')) {
					reloadCurrentRoute(this.router);

					return;
				} else {
					void this.router.navigate(['..', 'new'], { relativeTo: this.route });

					return;
				}
			case SaveOptionType.Close:
				this.back();

				return;
			case SaveOptionType.Next: {
				const nextId = this.tableManager?.getNextItem(item.id)?.id;

				if (nextId) {
					void this.router.navigate(['..', nextId], { relativeTo: this.route });
				}

				return;
			}
			case SaveOptionType.Approve:
				this.approveClicked.emit(item.id);

				return;
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	getFieldRef(field?: any): FieldReference {
		return this.builderService.getFieldRef(field);
	}

	getFieldPosition(field: any, parent?: any, depth = 0): { index: number; parent: any; depth: number } | undefined {

		parent = parent || this.builderService.definition;
		if (!parent[this.builderService.childrenProperty] || parent[this.builderService.childrenProperty].length === 0) {
			return;
		}

		const idx = (parent[this.builderService.childrenProperty] as any[]).indexOf(field);

		if (idx >= 0) {
			return { index: idx, parent, depth: (depth + 1) };
		}

		let found: { index: number; parent: any; depth: number } | undefined;

		(parent[this.builderService.childrenProperty] as any[]).forEach((f) => {
			const res = this.getFieldPosition(field, f, (depth + 1));

			if (res) {
				found = res;

				return;
			}
		});

		return found;
	}

	back() {
		if (this.router) {
			void this.router.navigate(['../'], { relativeTo: this.route });
		}
	}

	mapContentItemName(consoleName: string | undefined) {
		return this.editMode === EditMode.New ? 'New' : consoleName;
	}

}

export const canDeactivateBuilder: CanDeactivateFn<BuilderBasic> = (component) => {
	if (inject(Config).debug || !component.builderService.memento.edited) {
		return true;
	}

	return inject(DialogsService).confirmLeaveWithoutSaving();
};
