import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { ErrorType, UfRequestError, ensureUfRequestError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { SmsIntegration, SmtpInfo, UcClient, UcIntegrations, UcSmtp } from 'client';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { EditData } from 'components/common/edit-data';
import { reloadCurrentRoute } from 'pages/utils';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';
import { TitleService } from 'services/title.service';

import { SystemControlKeys, SystemGeneralController } from './system-general-controller';
import { SystemSmtpTestModalComponent } from './system-smtp-test-modal.component';
import { TenantSettingsComponent } from './tenant-settings.component';

enum SmtpFormControlKeys {
	SmtpHost = 'smtpHost',
	SmtpPort = 'smtpPort',
	SmtpSystemName = 'smtpSystemName',
	SmtpSystemEmail = 'smtpSystemEmail',
	SmtpUsername = 'smtpUsername',
	SmtpPassword = 'smtpPassword',
}

@Component({
	selector: 'uc-system-messaging',
	providers: [SystemGeneralController],
	templateUrl: './system-messaging.html',
	styleUrls: ['./system-messaging.less'],
	standalone: false,
})
export class SystemMessagingComponent implements OnInit, OnDestroy, EditData {

	@ViewChild('headerButtons', { static: true }) protected headerButtonsTemplate: TemplateRef<any>;

	protected readonly systemControlKeys = SystemControlKeys;
	protected readonly smtpFormControlKeys = SmtpFormControlKeys;
	protected error: UfRequestError | null = null;
	protected loading = true;
	protected smtpForm: UfControlGroup;
	protected smtpEnabled: boolean;
	protected generalForm: UfControlGroup;
	protected valueChangesSubscription: Subscription | null;
	protected filteredSmsIntegrations: SmsIntegration[] = [];

	private subscriptions = new Subscription();
	private smtpInfo: SmtpInfo | undefined;

	private router = inject(Router);
	private ucSmtp = inject(UcSmtp);
	private client = inject(UcClient);
	private toast = inject(ToastService);
	private modalService = inject(ModalService);
	private dialogs = inject(DialogsService);
	private titleService = inject(TitleService);
	private ucIntegrations = inject(UcIntegrations);
	private parent = inject(TenantSettingsComponent);
	private context = inject(ContextService);
	private generalFormController = inject(SystemGeneralController);
	private builderHeaderService = inject(BuilderHeaderService);

	set edited(v: boolean) {
		this.builderHeaderService.config.edited = v;
	}

	get edited() {
		return this.builderHeaderService.config.edited ?? false;
	}

	async ngOnInit() {

		if (!this.context.tenantSettings) {
			throw new Error('No tenant settings found');
		}

		this.titleService.updateTitle('Messaging');

		this.smtpForm = new UfControlGroup({
			[SmtpFormControlKeys.SmtpHost]: new UfControl(ValidatorFunctions.required('Host is required')),
			[SmtpFormControlKeys.SmtpPort]: new UfControl(),
			[SmtpFormControlKeys.SmtpSystemName]: new UfControl(),
			[SmtpFormControlKeys.SmtpSystemEmail]: new UfControl(
				ValidatorFunctions.compose([
					ValidatorFunctions.email('Invalid email'),
					ValidatorFunctions.required('System Email is required'),
				]),
			),
			[SmtpFormControlKeys.SmtpUsername]: new UfControl(ValidatorFunctions.required('Username is required')),
			[SmtpFormControlKeys.SmtpPassword]: new UfControl(ValidatorFunctions.required('Password is required')),
		});

		this.generalForm = this.generalFormController.buildRoot(this.generalFormController.toFormModel(this.context.tenantSettings));

		this.parent.templateRef = this.headerButtonsTemplate;
		await this.load();
		this.buildHeaderConfig();
	}

	ngOnDestroy() {
		this.valueChangesSubscription?.unsubscribe();
		this.subscriptions.unsubscribe();
	}

	protected async save() {
		this.error = null;

		this.smtpForm.setSubmitted();
		this.generalForm.setSubmitted();

		if (this.generalForm.invalid || (this.smtpEnabled && this.smtpForm.invalid)) {
			return;
		}

		const generalData = this.generalFormController.toDataModel(this.generalForm.getRawValue());

		// TODO check uf-password component and why it removes value from root control
		if (!this.smtpForm.value.smtpPassword && this.smtpForm.controls.smtpPassword?.value) {
			this.smtpForm.value.smtpPassword = this.smtpForm.controls.smtpPassword.value;
		}

		try {

			if (this.smtpEnabled && this.smtpForm.valid) {
				await this.ucSmtp.save(this.smtpForm.getRawValue() as SmtpInfo);
			}

			this.context.tenantSettings = await this.client.updateSettings(generalData);
			this.refreshPageAndShowMessage('Saved successfully');
		} catch (e) {
			this.error = ensureUfRequestError(e);
			this.toast.error('Error saving');
			console.error('SystemMessagingComponent save error:', e);
		}
	}

	protected enableSmtp() {
		this.smtpEnabled = true;
	}

	protected async deleteSmtp() {
		this.error = null;

		if (!await this.dialogs.confirmDelete()) {
			return;
		}

		try {
			await this.ucSmtp.delete();
		} catch (e) {

			const error = ensureUfRequestError(e);

			if (error.type === ErrorType.NotFound) {
				this.smtpEnabled = false;
				this.smtpForm.reset();
				this.refreshPageAndShowMessage('Deleted successfully');

				return;
			}

			this.error = error;
			this.toast.error('Error deleting');
			console.error('SystemMessagingComponent delete error:', e);
		}
	}

	protected async sendTest() {
		this.error = null;

		this.smtpForm.markAllAsTouched();

		const email = await this.modalService.openFit(SystemSmtpTestModalComponent, null);

		if (!this.smtpForm.valid || !email) {
			return;
		}

		// TODO check uf-password component and why it removes value from root control
		if (!this.smtpForm.value.smtpPassword && this.smtpForm.controls.smtpPassword?.value) {
			this.smtpForm.value.smtpPassword = this.smtpForm.controls.smtpPassword.value;
		}

		try {
			await this.ucSmtp.test(this.smtpForm.getRawValue() as SmtpInfo, email);
			this.toast.success('Test email sent');
		} catch (e) {
			this.error = ensureUfRequestError(e);
			this.toast.error('Error sending test');
			console.error('SystemMessagingComponent test error:', e);
		}
	}

	protected async filterSmsIntegrations(query: string) {
		this.filteredSmsIntegrations = (await this.ucIntegrations.list({ params: { q: query, canSendSms: true } })).map((integration) => ({ smsProviderId: `${integration.id}`, smsProviderName: integration.name }));
	}

	private buildHeaderConfig() {
		this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(() => { void this.save(); }));
		this.builderHeaderService.updateConfig({
			lastModifiedAt: this.smtpInfo?.lastModifiedAt,
			lastModifiedBy: this.smtpInfo?.lastModifiedBy,
			hideSaveButton: false,
			cancelRoute: ['..'],
		});
		this.subscriptions.add(this.smtpForm.statusChanges.subscribe(() => { this.builderHeaderService.config.edited = !this.smtpForm.pristine; }));
	}

	private async load() {
		this.error = null;
		this.edited = false;
		this.loading = true;

		this.valueChangesSubscription?.unsubscribe();

		try {
			this.smtpInfo = await this.ucSmtp.get();
			this.smtpForm.patchValue(this.smtpInfo);
			this.smtpEnabled = this.smtpForm.valid;
		} catch (e) {
			const error = ensureUfRequestError(e);

			if (error.type === ErrorType.NotFound) { // 404 means SMTP is just not set up yet
				this.smtpEnabled = false;
			} else {
				this.error = error;
				this.toast.error('Loading error');
				console.error('SystemMessagingComponent load error:', e);
			}
		}
		this.loading = false;
		this.valueChangesSubscription = this.smtpForm.valueChanges.subscribe(() => { this.edited = true; });
	}

	private refreshPageAndShowMessage(message: string) {
		this.edited = false;
		this.toast.success(message);
		reloadCurrentRoute(this.router);
	}

}
