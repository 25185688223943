import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';

import { AppDevicesTableManager } from 'pages/apps/app-devices-table-manager';

@Component({
	selector: 'uc-app-devices',
	providers: [
		{ provide: TableContainerManager, useClass: AppDevicesTableManager },
	],
	template: `
        <uf-table-container (addItem)="addItem()" ufMasterDetail class="accent default pad-none list-md"/>
        <router-outlet/>
    `,
	standalone: false,
})
export class AppDevicesComponent {

	private route = inject(ActivatedRoute);
	private router = inject(Router);

	protected addItem() {
		void this.router.navigate(['./new'], { relativeTo: this.route });
	}

}
