import { Injectable, inject } from '@angular/core';
import { ClientGetOptions, amendOptionsParams, mergeParams } from '@unifii/sdk';

import { resourceIterator, resourceWithParentIterator } from 'components/permissions';
import { DefaultPaginationParams } from 'constant';

import { Resource, ResourceElement, ResourcePath, ResourceSegment } from './models';
import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class UcResources {

	private client = inject(UcClient);

	async get(options?: ClientGetOptions): Promise<Resource> {
		const root = await this.client.get(this.url(), options) as Resource;

		root.path = ResourcePath.Resources;
		for (const { resource, parent } of resourceWithParentIterator(root)) {
			resource.path = ((parent?.path ?? '') + '/' + resource.segment) as ResourcePath;
		}

		const paths: string[] = Object.values(ResourcePath);
		let count = 0;

		for (const resource of resourceIterator(root)) {
			count++;
			if (!paths.includes(resource.path)) {
				console.warn('Unexpected path ', resource.path);
			}
		}

		if (paths.length !== count) {
			throw new Error('UcResources.get - tree contains unknown resources or expected resources are missing');
		}

		return root;
	}

	getUsers(q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Users), amendOptionsParams(defaultedParams, options));
	}

	getUser(id: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Users, id), options);
	}

	getProjects(q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Projects), amendOptionsParams(defaultedParams, options));
	}

	getProject(projectId: number): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Projects, projectId));
	}

	getCollections(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Collections), amendOptionsParams(defaultedParams, options));
	}

	getCollection(projectId: number, identifier: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Collections, identifier), options);
	}

	getCollectionItems(projectId: number, collectionIdentifier: string, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Collections, collectionIdentifier, ResourceSegment.CollectionItems), amendOptionsParams(defaultedParams, options));
	}

	getCollectionItem(projectId: number, collectionIdentifier: string, identifier: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Collections, collectionIdentifier, ResourceSegment.CollectionItems, identifier), options);
	}

	getViews(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Views), amendOptionsParams(defaultedParams, options));
	}

	getView(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Views, id), options);
	}

	getPages(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Pages), amendOptionsParams(defaultedParams, options));
	}

	getPage(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Pages, id), options);
	}

	getForms(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Forms), amendOptionsParams(defaultedParams, options));
	}

	getForm(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Forms, id), options);
	}

	getBuckets(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Buckets), amendOptionsParams(defaultedParams, options));
	}

	getBucket(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Buckets, id), options);
	}

	getTables(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Tables), amendOptionsParams(defaultedParams, options));
	}

	getTable(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Tables, id), options);
	}

	getExternalDataSources(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.ExternalDataSources), amendOptionsParams(defaultedParams, options));
	}

	getExternalDataSource(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.ExternalDataSources, id), options);
	}

	getExternalDataTransactions(projectId: number, q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.ExternalDataTransactions), amendOptionsParams(defaultedParams, options));
	}

	getExternalDataTransaction(projectId: number, id: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.ExternalDataTransactions, id), options);
	}

	getCompanies(q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.Companies), amendOptionsParams(defaultedParams, options));
	}

	getCompany(id: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.Companies, id), options);
	}

	getUserClaims(q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.UserClaims), amendOptionsParams(defaultedParams, options));
	}

	getUserClaim(id: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.UserClaims, id), options);
	}

	getAuthProviders(q?: string, options?: ClientGetOptions): Promise<ResourceElement[]> {
		const defaultedParams = mergeParams(DefaultPaginationParams, options?.params, { q });

		return this.client.get(this.url(ResourceSegment.AuthProviders), amendOptionsParams(defaultedParams, options));
	}

	getAuthProvider(id: string, options?: ClientGetOptions): Promise<ResourceElement> {
		return this.client.get(this.url(ResourceSegment.AuthProviders, id), options);
	}

	getMeFields(options?: ClientGetOptions): Promise<string[]> {
		return this.client.get(this.url(ResourceSegment.Me, 'fields'), options);
	}

	getUsersFields(options?: ClientGetOptions): Promise<string[]> {
		return this.client.get(this.url(ResourceSegment.Users, 'fields'), options);
	}

	getCompaniesFields(options?: ClientGetOptions): Promise<string[]> {
		return this.client.get(this.url(ResourceSegment.Companies, 'fields'), options);
	}

	getBucketFields(projectId: number, id: string, options?: ClientGetOptions): Promise<string[]> {
		return this.client.get(this.url(ResourceSegment.Projects, projectId, ResourceSegment.Buckets, id, 'fields'), options);
	}

	private url(...extra: (string | number)[]): string {
		const urlParts = ['resources', ...extra];

		return this.client.buildUrl(urlParts);
	}

}
