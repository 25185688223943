@if (control) {
	<uf-expander class="uf-box flat">
		<div expanderHeader class="uf-app-bar flat">
			<div class="title primary">{{ contentTypeLabel }}</div>
			@if (unpublishedWarningMessage) {
				<uf-icon [title]="unpublishedWarningMessage" name="unpublished" class="icon-warning" />
			}
			@if (canCopy) {
				<a (click)="copy()" class="uf-action tertiary" title="Copy">
					<uf-icon name="copy" />
				</a>
			}
			@if (contentLink) {
				<a [routerLink]="contentLink" target="_blank" class="uf-action tertiary" title="Open">
					<uf-icon name="open" />
				</a>
			}
		</div>
		<div [formGroup]="control" expanderBody>
			<!-- TODO add loading skeleton -->
			@if (ready) {
				@if (unpublishedWarningMessage) {
					<uf-blockquote [content]="unpublishedWarningMessage" icon="unpublished" class="warning" />
				}
				<uf-data-display-list [items]="contentInfo" class="small-label pad" />
			}
		</div>
	</uf-expander>
}
