<div class="header">
	<button (click)="close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>{{ data.consoleName }}, {{ data.width }} x {{ data.height }} px</h3>
</div>

<uf-panel class="container">
	<div class="grid checkboard-bg">
		<div class="col-1of1 center">
			<div class="uc-image--no-padding">
				<img src="{{ data.url }}" />
			</div>
		</div>
	</div>
</uf-panel>
