import { ClientGetOptions, amendOptionsParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { DataForwarder, DataForwarderInfo } from './models';
import { UcClient } from './uc-client';

/**
 * @Deprecated moving to workflow activities
 */
export class UcDataForwarders {

	constructor(
		private client: UcClient,
		private projectId: number,
		private bucket: string,
	) { }

	list(options?: ClientGetOptions): Promise<DataForwarderInfo[]> {
		return this.client.get(this.url(), amendOptionsParams(DefaultPaginationParams, options));
	}

	get(id: string, options?: ClientGetOptions): Promise<DataForwarder> {
		return this.client.get(this.url(id), options);
	}

	save(dataForwarder: DataForwarder): Promise<DataForwarder> {
		if (dataForwarder.id) {
			return this.client.put(this.url(dataForwarder.id.toString()), dataForwarder);
		} else {
			return this.client.post(this.url(), { body: dataForwarder });
		}
	}

	private url(...extra: string[]): string {
		const urlParts = ['projects', this.projectId, 'form-buckets', this.bucket, 'data-forwarders', ...extra];

		return this.client.buildUrl(urlParts);
	}

}
