import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { RuntimeDefinition, RuntimeDefinitionAdapter, ToastService, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { FormSettings, removeMetadataFields } from '@unifii/library/smart-forms';
import { FormComponentRegistry, InputFormSettings, UfFormComponent } from '@unifii/library/smart-forms/input';
import { Dictionary, ErrorType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Integration, IntegrationFeature, IntegrationProvider, UcClient, UcIntegrations } from 'client';
import { EditData, SaveAndClose, SaveOption, SaveOptionType } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { TitleService } from 'services/title.service';

import { SystemIntegrationsTableManager } from '../system-integrations-table-manager';

import { IntegrationComponent } from './integration.component';
import { SystemIntegrationRegistry } from './system-integration-registry';

export enum IntegrationControlKeys {
	Id = 'id',
	Name = 'name',
	IdExp = 'idExp',
	DisplayExp = 'displayExp',
	InputArgs = 'inputArgs',
	OutputArgs = 'outputArgs',
	EndpointPath = 'endpointPath',
	SecondaryEndpointPath = 'secondaryEndpointPath',
	Identifier = 'identifier',
	TypeKey = 'type',
	// TODO Required field will be added later on, when expressions are updated to accept it
	// IsRequired = 'isRequired',
}

const Messages = {
	FormError: 'There are errors with your form',
	ConnectionSuccess: 'Connection test was successful',
	ConnectionFailure: 'Connection test failed',
	LoadError: 'Failed to load integration',
	SaveError: 'Failed to save integration',
	UnknownError: 'Unhandled error',
	Saved: 'Integration saved',
};

@Component({
	selector: 'uc-integration-detail',
	templateUrl: './integration-detail.html',
	styleUrls: ['./integration-detail.less'],
	providers: [
		{ provide: FormComponentRegistry, useClass: SystemIntegrationRegistry },
		{ provide: FormSettings, useClass: InputFormSettings },
	],
	standalone: false,
})
export class IntegrationDetailComponent implements OnInit, EditData, OnDestroy {

	protected integration: Integration;
	protected definition: RuntimeDefinition;
	protected hasIntegrationFeatures: boolean;
	protected nameControl = new UfControl(ValidatorFunctions.required('Required, please complete'));
	protected form?: UfFormComponent;

	private subscriptions = new Subscription();

	private router = inject(Router);
	private toast = inject(ToastService);
	private route = inject(ActivatedRoute);
	private parent = inject(IntegrationComponent);
	private breadcrumbService = inject(BreadcrumbService);
	private builderHeaderService = inject(BuilderHeaderService);
	private runtimeDefinitionAdapter = inject(RuntimeDefinitionAdapter);
	private ucIntegrations = inject(UcIntegrations);
	private manager = inject(TableContainerManager) as SystemIntegrationsTableManager;
	private ucClient = inject(UcClient);
	private titleService = inject(TitleService);

	ngOnInit() {
		this.integration = this.parent.integration;
		this.titleService.updateTitle(`Integrations | ${this.integration.name}`, true);
		void this.setup();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.parent.buildHeaderConfig();
	}

	get edited() {
		return !!this.builderHeaderService.config.edited;
	}

	set edited(edited: boolean) {
		this.builderHeaderService.config.edited = edited;
	}

	protected testConnection() {
		if (!this.integration || !this.valid) {
			this.setSubmitted();
			this.toast.error(Messages.FormError);

			return;
		}

		const providerId = this.integration.provider.id;

		this.ucClient.testAvailableIntegration(providerId, this.integration.config)
			.then(() => {
				this.toast.success(Messages.ConnectionSuccess);
			}, () => {
				this.toast.error(Messages.ConnectionFailure);
			});
	}

	private get valid(): boolean {
		if (this.integration.provider.configForm && this.form) {
			return this.form.rootControl?.valid === true && this.nameControl.valid;
		}

		return this.nameControl.valid;
	}

	private async save(saveOption?: SaveOption) {
		this.setSubmitted();

		if (!this.integration || !this.valid) {
			return;
		}

		const integration = Object.assign({}, this.integration);

		// Provider can't be edited, on PUT call only id should be supplied
		integration.provider = { id: this.integration.provider.id } as IntegrationProvider;

		this.integration.config = removeMetadataFields(integration.config);

		try {
			this.integration = await this.ucIntegrations.save(integration);

			this.toast.success(Messages.Saved);

			this.manager.reload.next();
			this.edited = false;
			this.parent.integration = this.integration;

			if (saveOption?.id === SaveOptionType.Close) {
				this.router.navigate(['../'], { relativeTo: this.route });

				return;
			}

			if (this.integration.id) {
				this.buildHeaderConfig();

				return;
			}

			this.router.navigate(['..', this.integration.id], { relativeTo: this.route });
		} catch (err) {
			const error = this.getError(err, Messages.SaveError);

			this.toast.error(error.message as string);
		}
	}

	private setSubmitted() {
		if (!this.integration) {
			return;
		}

		this.nameControl.setSubmitted();

		if (this.integration.provider.configForm && this.form) {
			this.form.rootControl?.setSubmitted();
		}
	}

	private createFeatureConfig(features: IntegrationFeature[]) {

		return features.reduce<Dictionary<{ disabled: boolean }>>((result, feature) => {

			if (feature.configurable) {
				result[feature.id] = { disabled: feature.disabled };
			}

			return result;
		}, {});
	}

	private async setup() {
		this.builderHeaderService.init();

		const defaultFeatureConfig = this.createFeatureConfig(this.integration.provider.features);

		this.integration.featureConfig = Object.assign(defaultFeatureConfig, this.integration.featureConfig ?? {});

		this.hasIntegrationFeatures = this.integration.provider.id === 'http';

		const provider = this.integration.provider;

		if (!provider?.configForm) {
			return;
		}

		this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => this.save(saveOption)));
		this.buildHeaderConfig();
		this.definition = await this.runtimeDefinitionAdapter.transform(provider.configForm);

		this.subscriptions.add(this.nameControl.valueChanges.subscribe(() => (this.edited = true)));
	}

	private buildHeaderConfig() {
		const title = this.integration.name.length ? this.integration.name : 'New';

		this.builderHeaderService.buildConfig({
			title,
			cancelRoute: ['..'],
			saveOptions: [SaveAndClose],
			lastModifiedAt: this.integration.lastModifiedAt,
			lastModifiedBy: this.integration.lastModifiedBy,
			breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [title]),
		});
	}

	private getError(error: Error | any = {}, message: string = Messages.UnknownError): Error {
		if (!error.message) {
			delete error.message;
		}

		return Object.assign({ message, type: ErrorType.Unknown }, error);
	}

}
