import { Component, Input, OnInit } from '@angular/core';
import { UfControlArray } from '@unifii/library/common';

import { IntegrationFeature, IntegrationFeatureArgRoot, IntegrationFeatureArgType } from 'client';

import { DataSourceArgControlKeys } from '../../model';

@Component({
	selector: 'uc-data-feature-display',
	templateUrl: './data-feature-display.html',
	standalone: false,
})
export class DataFeatureDisplayComponent implements OnInit {

	@Input({ required: true }) featureDisplayControl: UfControlArray;
	@Input({ required: true }) feature: IntegrationFeature;

	protected readonly argControlKeys = DataSourceArgControlKeys;

	protected dataSourceDisplayOptions: string[] = [];

	ngOnInit() {
		this.dataSourceDisplayOptions = this.buildOutputOptions(this.feature.output);
	}

	private buildOutputOptions(output?: IntegrationFeatureArgRoot): string[] {
		if (!output?.attributes) {
			return [];
		}

		const attributes = output.attributes;

		return Object.keys(output.attributes)
			.filter((key) => {
				const attribute = attributes[key];
                
				return attribute && ![IntegrationFeatureArgType.Object, IntegrationFeatureArgType.List].includes(attribute.kind);
			});
	}

}
