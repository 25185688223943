import { Component, HostBinding, inject } from '@angular/core';
import { ClipboardService, Modal, ModalData, ModalRuntime } from '@unifii/library/common';

import { SchemaFieldSettings } from 'client';

import { BucketSettingsFieldInfo } from './bucket-settings-functions';

export interface BucketFieldSettingsData {
	fieldSettings: SchemaFieldSettings;
	isSearchableReadonly?: boolean;
	isReportableReadonly?: boolean;
	fieldInfo: BucketSettingsFieldInfo;
}

/**
 * @description
 * BucketFieldSettingsComponent is a modal class that is responsible
 * for updating settings for each field schema inside bucket settings
 */
@Component({
	selector: 'uc-bucket-field-settings',
	templateUrl: './bucket-field-settings.html',
	standalone: false,
})
export class BucketFieldSettingsComponent implements Modal<BucketFieldSettingsData, SchemaFieldSettings | undefined> {

	@HostBinding('class.uf-form-card') cardClass = true;

	data: BucketFieldSettingsData = inject(ModalData);
	runtime: ModalRuntime<BucketFieldSettingsData, SchemaFieldSettings | undefined> = inject(ModalRuntime);

	private clipboardService = inject(ClipboardService);

	close() {
		this.runtime.close();
	}

	protected submit() {
		this.runtime.close(this.data.fieldSettings);
	}

	protected async copyAttribute(identifier: string) {
		await this.clipboardService.setText(identifier);
	}

}
