<div class="header">
	<button (click)="runtime.close()" title="Close" type="button" class="uf-action tertiary">
		<uf-icon name="close" />
	</button>
	<h3>{{ modalTitle }}</h3>
</div>
@if (form && typesControl) {
	<div [formGroup]="form" class="uf-grid pad">
		<uf-text [formControlName]="controlKeys.Identifier" ufAutofocus label="Identifier" class="col-12" />
		@for (value of typesControl.value; track typeTrackBy(i, value); let i = $index) {
			<uf-select [options]="integrationFeatureArgTypes" [value]="value" (valueChange)="updateValue($event, i)" label="Type" class="col-12" />
		}
		<!--TODO Required field will be added later on, when expressions are updated to accept it-->
		<!-- <uf-select label="Is Required" [options]="isRequiredOptions" nameProperty="name" valueProperty="identifier"
    [formControlName]="controlKeys.IsRequired" class="col-12" /> -->
	</div>
	<uf-error [control]="typesControl" class="row gap-sides" />
}
<div class="row space-children pad">
	<button (click)="runtime.close()" type="button" class="uf-button tertiary right">Cancel</button>
	<button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>
