import { Directive, OnDestroy, OnInit, inject } from '@angular/core';
import { MessageLevel, ToastService } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { BuilderNotificationInfo, BuilderService } from '../../compound-builder/builder.service';

import { BuilderHeaderService } from './builder-header.service';

@Directive({
	selector: '[uc-header-warning]',
	standalone: false,
})
export class BuilderHeaderWarningDirective implements OnInit, OnDestroy {

	private subscriptions = new Subscription();
	private builderService = inject(BuilderService, { optional: true });
	private toastService = inject(ToastService);
	private builderHeaderService = inject(BuilderHeaderService);

	ngOnInit() {
		if (this.builderService) {
			this.subscriptions.add(this.builderService.ready.subscribe(() => {
				this.subscriptions.add(this.builderService?.notify.subscribe((notification) => this.displayMessage(notification)));
			}));
		} else {
			this.subscriptions.add(this.builderHeaderService.notify.subscribe((notification) => this.displayMessage(notification)));
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private displayMessage({ message, level }: BuilderNotificationInfo) {
		switch (level) {
			case MessageLevel.Error:
				this.toastService.error(message);
				break;
			case MessageLevel.Info:
				this.toastService.info(message);
				break;
			case MessageLevel.Warning:
				this.toastService.warning(message);
				break;
			case MessageLevel.Success:
				this.toastService.success(message);
				break;
		}
	}

}
