@if (control) {
	<uf-expander class="uf-box flat">
		<div expanderHeader class="uf-app-bar flat">
			<div class="title primary">Settings</div>
			@if (isInvalid) {
				<uf-icon name="error" class="error" />
			}
			@if (canCopy) {
				<a (click)="copy()" class="uf-action tertiary" title="Copy">
					<uf-icon name="copy" />
				</a>
			}
			@if (canHide) {
				<a [title]="isHidden ? 'Hidden' : 'Visible'" (click)="toggleHidden($event)" restrict="SuperUser" class="uf-action">
					<uf-icon [name]="isHidden ? 'viewHidden' : 'view'" />
				</a>
			}
		</div>
		<div [formGroup]="control" expanderBody class="uc-group-content">
			@if (ready && isHidden) {
				<uf-blockquote content="This node is hidden" icon="viewHidden" class="warning" />
			}
			<div class="grid">
				@if (!ready) {
					<uf-spinner class="col-1of1" />
				}
				@if (ready) {
					<uf-text [formControlName]="formKeys.Name" label="Menu Name" class="col-1of1" />
					@if (showDefinitionIdentifier) {
						<uf-text [formControlName]="formKeys.DefinitionIdentifier" label="Identifier" class="col-1of1 small" />
					}
					@if (showURL) {
						<uf-text [formControlName]="formKeys.Url" label="URL" class="col-1of1" />
					}
					@if (showTags) {
						<uf-chips [formControlName]="formKeys.Tags" [allowCustom]="true" [options]="tagOptions" (searchChange)="searchTags()" label="Tags" class="col-1of1" />
					}
					@if (appUrl) {
						<div class="grid center-all col-1of1">
							<uf-data-display-list [items]="[appUrl]" class="small-label" />
							<a (click)="copyAppURL()" class="uf-action tertiary right" title="Copy App URL">
								<uf-icon name="copy" />
							</a>
						</div>
					}
					@if (showTemplate) {
						<uf-select [options]="templateOptions" [formControlName]="formKeys.Template" label="Template" nameProperty="name" valueProperty="identifier" class="col-1of1" />
					}
					@if (showTemplate && control.get(formKeys.EmptyMessage)?.enabled) {
						<uf-text [formControlName]="formKeys.EmptyMessage" label="Empty message" class="col-1of1" />
					}
				}
			</div>
		</div>
	</uf-expander>
}
