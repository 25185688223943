"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormDataOptions = exports.ProjectContentOptions = exports.ConnectionOptions = void 0;
/** InjectionToken like */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class ConnectionOptions {}
exports.ConnectionOptions = ConnectionOptions;
/** InjectionToken like */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class ProjectContentOptions {}
exports.ProjectContentOptions = ProjectContentOptions;
/** InjectionToken like */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class FormDataOptions {}
exports.FormDataOptions = FormDataOptions;
