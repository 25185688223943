<div uc-header-warning class="header-pane uf-app-bar transparent">
	<uf-breadcrumbs [breadcrumbs]="config.breadcrumbs" class="large">
		@if (edited) {
			<span>*</span>
		}
	</uf-breadcrumbs>
	<ng-content select="[header]" />

	<uf-data-display-list class="x-small small-label row-gap-sm left gap-sm-right desktop-only">
		@if (config.lastModifiedAt) {
			<dt>Last Modified</dt>
			<dd class="value">{{ lastModified }}</dd>
		}
		@if (config.lastPublishedAt) {
			<dt>Last Published</dt>
			<dd class="value">{{ lastPublished }}</dd>
		}
	</uf-data-display-list>

	@if (showInfo) {
		<button (click)="openInfoModal()" type="button" class="uf-action small mobile-only">
			<uf-icon name="info" />
		</button>
	}

	@if (publishState) {
		<div [ngClass]="publishState | publishedStatusColour" class="uf-lozenge state-lozenge gap-sm-left x-small-md">
			{{ publishState }}
		</div>
	}

	<div class="row header-buttons right">
		@if (config.cancelRoute) {
			<a (click)="cancel()" class="uf-button small tertiary">Cancel</a>
		}

		<ng-content select="[header-buttons]" />

		@if (templateRef) {
			<ng-container *ngTemplateOutlet="templateRef" />
		}

		@if (!showSaveOptions && !config.hideSaveButton) {
			<button [disabled]="!!config.disabledSave || builderHeaderService.busy" [restrict]="config.restrictSave" (click)="onSave()" type="button" class="uf-button primary small">Save</button>
		}
		@if (showSaveOptions) {
			<uc-save-options [restrict]="config.restrictSave" [options]="saveOptions" [disabled]="!!config.disabledSave || builderHeaderService.busy" (onSave)="onSave($event)" />
		}
	</div>
</div>
