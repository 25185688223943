import { ValidatorFunctions } from '@unifii/library/common';
import { Field, FieldType, Option } from '@unifii/sdk';

import { FORM_EDITOR_CONSTANTS } from 'pages/form-editor/form-editor-constants';

import { ArrayHelper } from './array-helper';

interface FieldMinimal {
	type: FieldType;
	identifier?: string;
	fields?: FieldMinimal[];
}

export const IDENTIFIER_MAX_LENGTH_OLD_LIMIT = 150;
export const IDENTIFIER_MAX_LENGTH = 60;
export const WARNING_IDENTIFIER_MAX_LENGTH = 32;

export const camelize = (value: string) =>
	tokenize(value).map((token, index) => (index === 0) ? token.toLowerCase() : token[0]?.toUpperCase() + token.substring(1).toLowerCase()).join('');

export const camelizeNoLowerCase = (value: string) =>
	tokenize(value).map((token, index) => (index === 0) ? token : token[0]?.toUpperCase() + token.substring(1)).join('');

export const pascalize = (value: string) =>
	tokenize(value).map((token) => token[0]?.toUpperCase() + token.substring(1).toLowerCase()).join('');

export const kebabize = (value: string) =>
	tokenize(value).map((token) => token.toLowerCase()).join('-');

export const generateDisplayLabel = (value: string) =>
	((value.charAt(0).toUpperCase() + value.slice(1)).match(/[A-Z][a-z]+|[0-9]+/g) as RegExpMatchArray).join(' ');

export const isInConflict = (value: string, field: FieldMinimal, fields: FieldMinimal[]): boolean => {
	// Guard - a null value is not in conflict with another null value
	if (!value) {
		return false;
	}

	// console.log('Checking conflicts...');
	const scopeFields = sameScopeFields(field, fields);

	return scopeFields.some((f) => f !== field && f.identifier?.toLowerCase() === value.toLowerCase());
};

export const IdentifierValidCharactersErrorMessage = 'Identifier contains invalid characters';

export const FieldIdentifierValidCharactersValidator = ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_REGEX, IdentifierValidCharactersErrorMessage);

export const DefinitionIdentifierValidCharactersValidator = ValidatorFunctions.pattern(FORM_EDITOR_CONSTANTS.DEFINITION_IDENTIFIER_REGEX, IdentifierValidCharactersErrorMessage);

export const IdentifierNoEmptySpacesValidator = ValidatorFunctions.noWhiteSpaces(`Identifier can't contain white spaces`);

export const ContentDefinitionIdentifierValidators = [
	ValidatorFunctions.required('Identifier is required'),
	ValidatorFunctions.maxLength(WARNING_IDENTIFIER_MAX_LENGTH, `Identifier must be ${WARNING_IDENTIFIER_MAX_LENGTH} or less`),
	DefinitionIdentifierValidCharactersValidator,
	IdentifierNoEmptySpacesValidator,
	ValidatorFunctions.custom((v) => !/^toDate$|^add$|^toTime$/.test(v), `Identifier can't be a date expression`),
];

/** Conflict safe identifier for the target field within the fields tree
 *  Field identifier is camelCase as it is an 'identifier' value
 */
export const safeFieldIdentifier = (field: FieldMinimal, fields: FieldMinimal[] = [], identifier: string): string => {
	const base = camelize(identifier);

	let result = base;
	let suffix = 1;

	while (isInConflict(result, field, fields)) {
		result = base.substring(0, IDENTIFIER_MAX_LENGTH - 2) + suffix;
		suffix = suffix + 1;
	}

	return result.substring(0, IDENTIFIER_MAX_LENGTH);
};

/** Conflict safe identifier for the target option within the available options
 * Option identifier is pascalCase as it is a 'constant' value
 */
export const safeOptionIdentifier = (target: Option, available: Option[], identifier: string): string => {
	const base = pascalize(identifier);

	let result = base;
	let suffix = 1;

	while (available.some((o) => target !== o && o.identifier === result)) {
		result = base.substring(0, IDENTIFIER_MAX_LENGTH - 2) + suffix;
		suffix = suffix + 1;
	}

	return result.substring(0, IDENTIFIER_MAX_LENGTH);
};

/** Flatten list of fields with the same scope of the reference field */
export const sameScopeFields = (field: FieldMinimal, fields: FieldMinimal[]): Field[] => {

	// console.log('fieldGroup field', field);
	let parent = ArrayHelper.getParent(field, fields);

	while (parent != null && !uniqueChildrenFieldTypes.includes(parent.type)) {
		parent = ArrayHelper.getParent(parent, fields);
		// console.log('loop parent', parent);
	}

	const root = { fields: parent ? parent.fields : fields, type: FieldType.Group };
	const group: Field[] = [];
	// console.log('root', root);

	const deep = (item: Field, result: Field[]) => {
		group.push(item);
		if (!uniqueChildrenFieldTypes.includes(item.type)) {
			if (item.fields) {
				for (const f of item.fields) {
					deep(f, result);
				}
			}
		}
	};

	deep(root, group);

	// console.log('result', group);
	return group;
};

/** ########################################### PRIVATE ################################### */

const uniqueChildrenFieldTypes = [FieldType.Repeat];

const tokenize = (value: string): string[] => {

	if (!value) {
		return [];
	}

	return value.split(/[^A-Za-z0-9]+/).filter((p) => !!p);
};
