import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { PermissionPrincipalType } from 'client';

export enum PermissionChangeAction {
	Added = 'Added',
	Deleted = 'Deleted',
	Edited = 'Edited',
}

export interface PermissionChangeInfo {
	action: PermissionChangeAction;
	principalType: PermissionPrincipalType;
	principalId: string;
}

@Injectable({ providedIn: 'root' })
export class PermissionsManagerService {

	notify = new Subject<PermissionChangeInfo>();

}
