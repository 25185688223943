<uf-panel class="content-pane">
	@if (form) {
		<div class="uf-container-lg">
			<div class="uf-grid row-gap-lg pad-top">
				@if (error && error.message) {
					<uf-blockquote [content]="error.message" class="col-12 error" icon="errorSolid" />
				}
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Email Settings</div>
					</div>
					<div class="uf-grid pad">
						<uf-text [control]="replyToControl" class="col-12" label="Reply to">
							<uf-help content="Comma separate multiple email addresses." />
						</uf-text>
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Theming</div>
					</div>
					<div class="uf-grid pad">
						<uc-color [control]="emailColourControl" label="Button Colour" class="col-12" />
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar accent">
						<div class="title">Invite Email</div>
					</div>
					<div class="uf-app-bar">
						<div class="title">Invite Email Expiry</div>
					</div>
					<div class="uf-grid pad">
						<uf-number [value]="emailTokenDuration.days" (valueChange)="inviteEmailExpirationChange($event, 'days')" class="col-md-3 col-sm-12" label="Days" />
						<uf-number [value]="emailTokenDuration.hours" (valueChange)="inviteEmailExpirationChange($event, 'hours')" class="col-md-3 col-sm-12" label="Hours" />
						<uf-number [value]="emailTokenDuration.minutes" (valueChange)="inviteEmailExpirationChange($event, 'minutes')" class="col-md-3 col-sm-12" label="Minutes" />
						<uf-number [value]="emailTokenDuration.seconds" (valueChange)="inviteEmailExpirationChange($event, 'seconds')" class="col-md-3 col-sm-12" label="Seconds" />
						<uf-error [control]="inviteEmailExpiryControl | asUfControl" class="col-12" />
					</div>
					<div class="uf-app-bar">
						<div class="title">Templates</div>
					</div>
					<div class="uf-grid pad">
						<uc-email-templates [defaultTemplateControl]="inviteEmailDefaultTemplateControl" [templatesControl]="inviteEmailTemplatesControl" [emailColour]="emailColourControl.value" [default]="defaultTemplates.invite" class="col-12" />
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar accent">
						<div class="title">Reset Password Email</div>
					</div>
					<div class="uf-app-bar">
						<div class="title">Templates</div>
					</div>
					<div class="uf-grid pad">
						<uc-email-templates [defaultTemplateControl]="resetPasswordEmailDefaultTemplateControl" [templatesControl]="resetPasswordEmailTemplatesControl" [default]="defaultTemplates.resetPassword" class="col-12" />
					</div>
				</div>
			</div>
		</div>
	}
</uf-panel>
