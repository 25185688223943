import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UfError } from '@unifii/sdk';

import { UcAuthProviders } from 'client';
import { useDefaultErrorMessage } from 'components';

import { getUrlSegmentAuthProvider } from '../auth-provider-functions';

import { AuthProviderResolverData } from './automatic-auth-provider-creation-resolver';

export const manualAuthProviderResolver = async(route: ActivatedRouteSnapshot): Promise<AuthProviderResolverData | UfError> => {

	const ucAuthProviders = inject(UcAuthProviders);
	const id = route.params.id;

	if (id === 'new') {

		const type = getUrlSegmentAuthProvider(route.params.type);

		if (!type) {
			return new UfError(`Invalid auth provider type: ${route.params.type}`);
		}

		return {
			authProvider: {
				type,
				isActive: false,
				manual: true,
				extras: {
					manualRegistration: true,
				},
			},
		};
	}

	try {
		const authProvider = await ucAuthProviders.get(id);

		return { authProvider };
	} catch (e) {
		return useDefaultErrorMessage(e);
	}
};
