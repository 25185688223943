import { ImageToken, VideoToken } from './token';
export function createImageRender(md) {
  var defaultRender = md.renderer.rules.image;
  return function (tokens, idx, options, env, self) {
    const token = tokens[idx];
    if (token.type !== ImageToken) {
      return defaultRender(tokens, idx, options, env, self);
    }
    const parsedHTMLAttributes = getHTMLElementAttributes(token.attrs);
    const dataAttributes = getDataAttributes(token.meta);
    return `<img ${dataAttributes.join(' ')} ${parsedHTMLAttributes.join(' ')} />`;
  };
}
;
export function createVideoRender(md) {
  var defaultRender = md.renderer.rules.image;
  return function (tokens, idx, options, env, self) {
    const token = tokens[idx];
    if (token.type !== VideoToken) {
      return defaultRender(tokens, idx, options, env, self);
    }
    const parsedHTMLAttributes = getHTMLElementAttributes(token.attrs);
    const dataAttributes = getDataAttributes(token.meta);
    return `<video controls ${dataAttributes.join(' ')} ${parsedHTMLAttributes.join(' ')}>
                    <source>
                    Sorry, your browser doesn't support embedded videos.
                </video>`.replace(/\s\s/g, ' ');
  };
}
;
export function getHTMLElementAttributes(attrs) {
  const result = [];
  for (let attr of Object.keys(attrs)) {
    const value = attrs[attr];
    if (value != null) {
      result.push(`${attr}="${removeWrappingCommas(value)}"`);
    }
  }
  return result;
}
export function getDataAttributes(metadata) {
  const result = [];
  for (let key of Object.keys(metadata)) {
    const value = metadata[key];
    if (value != null) {
      result.push(`data-${kebabize(key)}="${removeWrappingCommas(value)}"`);
    }
  }
  return result;
}
export function kebabize(str) {
  return str.split('').map((letter, i) => {
    if (/[A-Z]/.test(letter)) {
      return `${i === 0 ? '' : '-'}${letter.toLowerCase()}`;
    }
    return letter;
  }).join('');
}
export function removeWrappingCommas(str) {
  return str.replace(/^"|^'|\"$|\'$/g, '');
}
