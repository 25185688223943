import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { Resource, ResourceElement, UcResources } from 'client';

@Injectable({ providedIn: 'root' })
export class ResourceCacheService {

	// cached Resource tree
	resource: Resource;
    
	// cached ResourceElement(s) for ResourceType(s) with static ones
	projectResources: ResourceElement[];
	userClaimResources: ResourceElement[];

}

export const resourceGuard: CanActivateFn = async() => {
    
	const resourceService = inject(ResourceCacheService);

	if ((resourceService.resource as Resource | undefined) != null) {
		return true;
	}
    
	const resourceClient = inject(UcResources);    

	resourceService.resource = await resourceClient.get();
	resourceService.projectResources = await resourceClient.getProjects();
	resourceService.userClaimResources = await resourceClient.getUserClaims();
    
	return true;
};
