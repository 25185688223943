"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UfMissingAccessTokenError = exports.UfRequestError = exports.UfError = void 0;
const client_models_1 = require("./client-models");
/** Represents an Unifii error */
class UfError extends Error {
  constructor(message, type, data) {
    super(message);
    this.name = new.target.prototype.constructor.name;
    this.type = type ?? client_models_1.ErrorType.Unknown;
    this.data = data;
  }
}
exports.UfError = UfError;
/** Represents an Unifii Request error */
class UfRequestError extends UfError {
  constructor(message, type, data, code) {
    super(message, type, data);
    this.code = code;
  }
}
exports.UfRequestError = UfRequestError;
class UfMissingAccessTokenError extends UfError {
  constructor(message) {
    super(message ?? 'Access token is missing', client_models_1.ErrorType.Unauthorized);
  }
}
exports.UfMissingAccessTokenError = UfMissingAccessTokenError;
