import { Component, Injector, OnDestroy, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { ModalService, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { AstNode, Device, Q, hasLengthAtLeast, isStringTrimmedNotEmpty, objectKeys } from '@unifii/sdk';

import { PushNotification, UcApps } from 'client';

import { AppDetailsComponent } from './app-details.component';
import { SelectDevicesComponent } from './select-devices.component';
import { getUserQueryBuilderEmptyAstNode, isUserQueryBuilderEmptyAstNode } from './users-query-builder-functions';
import { QueryBuilderData, UsersQueryBuilderComponent } from './users-query-builder.component';

@Component({
	selector: 'uc-app-send-notification',
	templateUrl: 'app-send-notification.html',
	standalone: false,
})
export class AppSendNotificationComponent implements OnInit, OnDestroy {

	@ViewChild('headerButtons', { static: true }) headerButtonsTemplate: TemplateRef<any>;

	protected messageControl: UfControlGroup;
	protected routingControl: UfControlGroup;
	protected selectedDevices: Device[] = [];
	protected userAstNode: AstNode;
	protected notification: PushNotification = { title: '', message: '', route: {}, devices: [] };

	private injector = inject(Injector);
	private modalService = inject(ModalService);
	private ucApps = inject(UcApps);
	private toastService = inject(ToastService);
	private parent = inject(AppDetailsComponent);
	private form: UfControlGroup;

	ngOnInit() {
		this.messageControl = new UfControlGroup({
			title: new UfControl(ValidatorFunctions.required('Title is required')),
			message: new UfControl(ValidatorFunctions.required('Message is required')),
		});

		this.routingControl = new UfControlGroup({
			tenant: new UfControl(),
			projectId: new UfControl(),
			nodeId: new UfControl(),
			bucket: new UfControl(),
			id: new UfControl(),
		});

		this.form = new UfControlGroup({
			messageDetails: this.messageControl,
			routing: this.routingControl,
		});

		this.resetForm();

		this.parent.templateRef = this.headerButtonsTemplate;
	}

	ngOnDestroy() {
		this.parent.buildHeaderConfig();
	}

	get id() {
		return this.parent.id;
	}

	get roles(): string[] | undefined {
		if (
			this.userAstNode.args &&
            hasLengthAtLeast(this.userAstNode.args, 1) &&
            this.userAstNode.args[0].args &&
            hasLengthAtLeast(this.userAstNode.args[0].args, 2)
		) {
			return this.userAstNode.args[0].args[1].value as string[];
		}

		return undefined;
	}

	protected async openUserQueryBuilder() {

		const data: QueryBuilderData = {
			fields: [],
			// deep clone to avoid to modify the original data
			filter: JSON.parse(JSON.stringify(this.userAstNode)),
		};

		const result = await this.modalService.openMedium(
			UsersQueryBuilderComponent, data, undefined, undefined, this.injector);

		if (result?.edited) {
			this.userAstNode = result.filter;
		}
	}

	protected async addDevices() {
		const selected = await this.modalService.openMedium(
			SelectDevicesComponent,
			{ appId: this.id, selected: this.selectedDevices },
		);

		if (!selected) {
			return;
		}

		for (const device of selected) {
			if (!this.selectedDevices.find((d) => d.id === device.id)) {
				this.selectedDevices.push(device);
			}
		}
	}

	protected resetForm() {

		this.resetUsers();
		this.selectedDevices = [];

		this.form.reset();
		this.form.setSubmitted(false);
	}

	protected resetUsers() {
		this.userAstNode = getUserQueryBuilderEmptyAstNode();
	}

	protected resetDevices() {
		this.selectedDevices = [];
	}

	protected send() {

		this.form.setSubmitted();

		if (!this.form.valid) {
			return;
		}

		if (!this.selectedDevices.length && !this.roles?.length && !this.claims.length) {
			this.toastService.error('At least one device or user must be targeted');

			return;
		}

		const info = this.cleanNotification();

		void this.ucApps.sendNotifications(this.id, info).then(() =>
			this.toastService.success('Notifications sent'),
		);

	}

	private get claims(): AstNode[] {
		// Remove Roles
		return (this.userAstNode.args ?? []).slice(1);
	}

	private cleanNotification(): PushNotification {

		const info = Object.assign({}, this.notification);
		const userFilter: AstNode = JSON.parse(JSON.stringify(this.userAstNode));

		info.devices = this.selectedDevices.map((device) => device.id);

		// Remove empty devices list
		if (info.devices.length === 0) {
			delete info.devices;
		}

		// Remove route info if not needed
		const route = info.route;

		if (route && objectKeys(route).find((key) => !isStringTrimmedNotEmpty(route[key]))) {
			delete info.route;
		}

		// Apply user query
		if (!isUserQueryBuilderEmptyAstNode(userFilter)) {

			// Remove roles if empty
			if (!(userFilter as any).args[0].args[1].value.length) {
				(userFilter.args as AstNode[]).shift();
			}

			const filter = Q.fromAst(userFilter);

			if (filter) {
				info.userQuery = filter.stringify();
			}

		} else {
			delete info.userQuery;
		}

		return info;
	}

}
