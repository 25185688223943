<div class="uf-app-bar">
	<button (click)="close()" title="Close" type="button" class="uf-action tertiary">
		<uf-icon name="close" />
	</button>
	<h3>Data Mapping</h3>
</div>
<div [formGroup]="formGroup" class="uf-grid pad gaps">
	@if (sourceField) {
		<uf-data-display-list class="col-12">
			<dt>{{ sourceFieldLabel }}</dt>
			<dd class="value">
				<uf-icon [name]="getFieldTypeIcon(sourceField.type)" />
				{{ namePropertyFunc(sourceField) }}
			</dd>
		</uf-data-display-list>
	}

	@if (targetField) {
		<uf-data-display-list class="col-12">
			<dt>{{ targetFieldLabel }}</dt>
			<dd class="value">
				<uf-icon [name]="getFieldTypeIcon(targetField.type)" />
				{{ namePropertyFunc(targetField) }}
			</dd>
		</uf-data-display-list>
	}

	@if (sourceExpressionKey && formGroup.get(sourceExpressionKey)) {
		<uf-checkbox [(value)]="useSourceExpression" (valueChange)="sourceChange($event)" label="Use Source Expression" class="col-12" />
	}

	@if (!sourceField) {
		@if (sourceExpressionKey && useSourceExpression) {
			<uf-text [formControlName]="sourceExpressionKey" [label]="sourceExpressionLabel" class="col-12" />
		}
		@if (!useSourceExpression) {
			<uf-autocomplete [formControlName]="sourceKey" [options]="sourceFields" [label]="sourceFieldLabel" [nameProperty]="namePropertyFunc" (searchChange)="sourceSearch($event)" class="col-12" />
		}
	}

	@if (targetExpressionKey && formGroup.get(targetExpressionKey)) {
		<uf-checkbox [(value)]="useTargetExpression" (valueChange)="targetChange($event)" label="Use Target Expression" class="col-12" />
	}

	@if (!targetField) {
		@if (targetExpressionKey && useTargetExpression) {
			<uf-text [formControlName]="targetExpressionKey" [label]="targetExpressionLabel" class="col-12" />
		}
		@if (!useTargetExpression) {
			<uf-autocomplete [formControlName]="targetKey" [options]="targetFields" [label]="targetFieldLabel" [nameProperty]="namePropertyFunc" (searchChange)="targetSearch($event)" class="col-12" />
		}
	}
</div>
<div class="row space-children pad">
	<button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
	<button (click)="submit()" type="button" class="uf-button primary">Save</button>
</div>
