import { Component, Input, OnInit, inject } from '@angular/core';
import { UfControlArray } from '@unifii/library/common';
import { FieldType, Option, ValidatorType } from '@unifii/sdk';

import { ToDisplayNamePipe } from 'pipes';
import { DialogsService } from 'services/dialogs.service';

import { ValidatorControlKeys } from '../form-editor-control-keys';
import { FormEditorFormCtrl } from '../form-editor-form-ctrl';
import { FormEditorFunctions } from '../form-editor-functions';
import { ValidatorInfo } from '../form-editor-model';
import { FormEditorService } from '../form-editor.service';

@Component({
	selector: 'uc-form-field-validators',
	templateUrl: './form-field-validators.html',
	standalone: false,
})
export class FormFieldValidatorsComponent implements OnInit {

	@Input({ required: true }) validators: UfControlArray;
	@Input({ required: true }) type: FieldType;

	protected readonly validatorKeys = ValidatorControlKeys;
	protected readonly validatorTypes = ValidatorType;

	protected expandeds: boolean[];
	protected validatorOptions: Option[];
	protected validatorsInfo: ValidatorInfo[] = [];

	private fb = inject(FormEditorFormCtrl);
	private service = inject(FormEditorService);
	private dialogs = inject(DialogsService);
	private displayPipe = inject(ToDisplayNamePipe);

	ngOnInit() {
		this.validatorOptions = this.service.fieldValidatorOptions(this.type);
		this.expandeds = Array(this.validators.length).fill(false);

		for (const validatorControlGroup of this.validators.controls) {
			this.validatorsInfo.push(this.buildValidatorInfo(validatorControlGroup.get(ValidatorControlKeys.Type)?.value as ValidatorType));
		}
	}

	protected addValidator(option: Option) {
		this.expandeds.push(true);
		const validator = FormEditorFunctions.emptyValidatorByType(option.identifier as ValidatorType);

		const controlGroup = this.fb.buildValidatorControl(validator);

		this.validators.push(controlGroup);
		this.validatorsInfo.push(this.buildValidatorInfo(controlGroup.get(ValidatorControlKeys.Type)?.value as ValidatorType));
	}

	protected async removeValidator(index: number) {
		if (!await this.dialogs.confirmDelete()) {
			return;
		}

		this.expandeds.splice(index, 1);
		this.validators.removeAt(index);
		this.validatorsInfo.splice(index, 1);
	}

	private buildValidatorInfo(type: ValidatorType): ValidatorInfo {
		return FormEditorFunctions.validatorsInfoByType(type, this.displayPipe);
	}

}
