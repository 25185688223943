@if (ready && field) {
	<li [class.selected]="selected" (click)="select($event)" class="uc-list-item--draggable--no-shrink--small row center-all">
		<uf-icon [name]="iconName" />
		<div class="node-label gap-left grow">{{ label }}</div>
		@if (!valid) {
			<uf-icon name="warning" class="icon-warning" />
		}
		<a (click)="remove($event)" title="Delete" class="uf-action tertiary">
			<uf-icon name="delete" />
		</a>
	</li>
}
