@if (label) {
	<label>
		{{ label }}
		<ng-content />
	</label>
}

@if (supported) {
	<div class="row">
		<input #input [formControl]="control" [attr.placeholder]="placeholder" [readonly]="disabled" (focus)="focused = true" (blur)="focused = false" class="colour-picker" type="color" />
		<uf-text [value]="control.value" (valueChange)="digitColor($event)" placeholder="#FFFFFF" class="grow" />
		@if (control.value) {
			<uf-icon (click)="delete()" name="backspace" title="Clear" />
		}
	</div>
}

@if (!supported) {
	<div>
		<input [attr.placeholder]="placeholder" [readonly]="disabled" (focus)="focused = true" (blur)="focused = false" type="text" />
	</div>
}
<uf-error [control]="control" />
