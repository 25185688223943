import { Injectable, inject } from '@angular/core';
import { TableContainerManager, TableInputs } from '@unifii/components';
import { DataDisplayService, FilterEntry, FilterValue, TableAction, TableConfig, TableConfigColumn, ToastService } from '@unifii/library/common';
import { DataType } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { ApiKey, UcAPIKeys } from 'client';
import { ConsoleNameLabel, ConsoleNameProperty } from 'constant';

import { APIKeysDataSource } from './api-keys-datasource';

@Injectable()
export class APIKeysTableManager implements TableContainerManager<ApiKey, FilterValue, FilterEntry> {

	tableConfig: TableConfig<ApiKey>;
	addActionConfig = true;
	reload = new Subject<void>();
	update = new Subject<TableInputs<FilterValue>>();

	private ucAPIKeys = inject(UcAPIKeys);
	private toastService = inject(ToastService);
	private dataDisplayService = inject(DataDisplayService);

	constructor() {
		this.tableConfig = {
			id: 'api-keys',
			columns: this.columns,
			actions: this.actions,
			pageSize: 50,
			selectable: true,
			columnToggles: true,
			rowLink: (role) => role.key ?? [],
		};
	}

	createDataSource() {
		return new APIKeysDataSource(this.ucAPIKeys);
	}

	private get columns(): TableConfigColumn<ApiKey>[] {
		return [{
			name: ConsoleNameProperty,
			label: ConsoleNameLabel,
		}, {
			name: 'key',
			label: 'Key',
		}, {
			name: 'createdBy',
			label: 'Created By',
			value: (item) => item.createdBy.username,
		}, {
			name: 'createdAt',
			label: 'Created At',
			value: (item) => this.dataDisplayService.displayAsString(item.createdAt, { type: DataType.OffsetDateTime }),
		}];
	}

	private get actions(): TableAction<ApiKey>[] {
		return [{
			label: 'Revoke',
			icon: 'delete',
			action: (rows) => this.delete(rows.map((row) => row.$implicit)),
		}];
	}

	private async delete(apiKeys: ApiKey[]) {
		// Create new reference so array can be modified for printing a meaningful error message
		const remainingApiKeys: ApiKey[] = [...apiKeys];

		try {
			for (let i = (remainingApiKeys.length - 1); i >= 0; i--) {
				const apiKey = remainingApiKeys[i];

				if (!apiKey?.key) {
					continue;
				}

				await this.ucAPIKeys.delete(apiKey.key);
				remainingApiKeys.splice(i, 1);
			}
			this.toastService.success('API Keys revoke');
		} catch (e) {
			this.toastService.error(`Failed to revoke API Keys: ${remainingApiKeys.map((k) => k.consoleName).join(', ')}`);
		} finally {
			this.reload.next();
		}
	}

}
