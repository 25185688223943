"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HeaderKeys = exports.CompanyStatus = exports.AuthProvider = exports.AuthenticationSource = exports.UserStatus = void 0;
var UserStatus;
(function (UserStatus) {
  UserStatus["Pending"] = "Pending";
  UserStatus["Active"] = "Active";
  UserStatus["Inactive"] = "Inactive";
})(UserStatus || (exports.UserStatus = UserStatus = {}));
var AuthenticationSource;
(function (AuthenticationSource) {
  AuthenticationSource["Internal"] = "Internal";
  AuthenticationSource["External"] = "External";
})(AuthenticationSource || (exports.AuthenticationSource = AuthenticationSource = {}));
var AuthProvider;
(function (AuthProvider) {
  AuthProvider["Azure"] = "Azure";
  AuthProvider["Auth0"] = "Auth0";
  AuthProvider["Okta"] = "Okta";
  AuthProvider["UnifiiIdentity"] = "UnifiiIdentity";
  AuthProvider["SyncOnly"] = "SyncOnly";
})(AuthProvider || (exports.AuthProvider = AuthProvider = {}));
var CompanyStatus;
(function (CompanyStatus) {
  CompanyStatus["Pending"] = "Pending";
  CompanyStatus["Active"] = "Active";
  CompanyStatus["Inactive"] = "Inactive";
})(CompanyStatus || (exports.CompanyStatus = CompanyStatus = {}));
var HeaderKeys;
(function (HeaderKeys) {
  HeaderKeys["Authorization"] = "Authorization";
  HeaderKeys["ContentType"] = "Content-Type";
  HeaderKeys["AcceptLanguage"] = "Accept-Language";
  HeaderKeys["Etag"] = "etag";
  HeaderKeys["IfMatch"] = "If-Match";
  HeaderKeys["XUnifiiApp"] = "X-Unifii-App";
  HeaderKeys["XUnifiiDate"] = "X-Unifii-Date";
  HeaderKeys["XUnifiiDevice"] = "X-Unifii-Device";
  HeaderKeys["XUnifiiAnalytics"] = "X-Unifii-Analytics";
  HeaderKeys["XUnifiiContentVersion"] = "X-Unifii-Content-Version";
})(HeaderKeys || (exports.HeaderKeys = HeaderKeys = {}));
