import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterEntries, ModalService } from '@unifii/library/common';

import { ActivityType } from 'client';
import { TypeSelectComponent } from 'components';
import { ActivityTypeToken, WorkflowActivityTableManager } from 'pages/workflows/workflow-activity-table-manager';

import { activityFilterFactory } from '../workflows-filters';

@Component({
	selector: 'uc-workflow-notifications',
	template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="add()" ufSyncRoute class="accent pad-none list-md"/>
        </div>
        <router-outlet/>
    `,
	providers: [
		{ provide: FilterEntries, useFactory: activityFilterFactory },
		{ provide: ActivityTypeToken, useValue: ActivityType.Notification },
		{ provide: TableContainerManager, useClass: WorkflowActivityTableManager },
	],
	standalone: false,
})
export class WorkflowNotificationsComponent {

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private modalService = inject(ModalService);

	protected async add() {

		const choice = await this.modalService.openMedium(
			TypeSelectComponent, {
				title: 'Select a notification type',
				types: [{
					type: '',
					label: 'Workflow',
				},
				{
					type: 'user-management',
					label: 'User Management',
				}],
			});

		if (!choice) {
			return;
		}

		void this.router.navigate(['new', { type: choice.type }], { relativeTo: this.route });
	}

}
