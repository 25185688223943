import { Injectable, inject } from '@angular/core';
import { Option } from '@unifii/sdk';

import { Resource, ResourcePath, UcPermissionActionInfo, UcResources } from 'client';

import { ResourceCacheService } from '../resource-cache-service';

import { EditActions, PermissionPathSegmentEnd, PermissionPathSegmentWildcard, ReadActions } from './permission-editor-constants';
import { PermissionControlKeys } from './permission-editor-control-keys';
import { FieldsFlagsEditableStatus, PermissionEditorAction, PermissionEditorStep } from './permission-editor-model';
import { PermissionEditorStepLoader } from './permission-editor-step-loader';

@Injectable({ providedIn: 'root' })
export class PermissionEditorService {

	private resourceService = inject(ResourceCacheService);
	private resourcesClient = inject(UcResources);

	private userFields?: Promise<string[]>;
	private meFields?: Promise<string[]>;
	private companyFields?: Promise<string[]>;

	async getStep(previousSteps: PermissionEditorStep[], resource: Resource, segmentIdentifier?: string): Promise<PermissionEditorStep> {
        
		const loader = new PermissionEditorStepLoader(this.resourcesClient, previousSteps, resource, segmentIdentifier);
        
		// segmentIdentifier != null cause '' is a valid segmentIdentifier
		const segment = segmentIdentifier != null ? await loader.get(segmentIdentifier) : undefined;
        
		return { resource, loader, segment };
	}

	getNextResource(steps: PermissionEditorStep[]): Resource | undefined {
        
		if (!steps.length) {
			return this.resourceService.resource;
		}

		const lastStep = steps[steps.length - 1];

		if (!lastStep) {
			return;
		}

		if (!lastStep.segment || lastStep.segment.identifier === PermissionPathSegmentWildcard) {
			return undefined;
		}

		const match = lastStep.resource.children.find((c) => c.segment === lastStep.segment?.identifier);

		if (match) {
			return match;
		}
        
		return lastStep.resource.children.length ? lastStep.resource.children[0] : undefined;
	}

	getActions(steps: PermissionEditorStep[], permissionActions?: UcPermissionActionInfo[]): PermissionEditorAction[] {
        
		const lastStep = steps.length ? steps[steps.length - 1] : undefined;
		const allowsCondition = !!lastStep?.segment &&
            lastStep.segment.identifier !== PermissionPathSegmentWildcard &&
            lastStep.resource.allowsCondition;
        
		// Need a copy of the actions to modify and avoid changes to the original ones stored in the step loader
		const actions = JSON.parse(JSON.stringify( lastStep?.loader.actions ?? [])) as PermissionEditorAction[];

		for (const action of actions) {
			const savedAction = permissionActions?.find((pa) => pa.name === action.name);

			action.allowsCondition = allowsCondition;
			action.selected = !!savedAction;
			action.condition = savedAction?.condition;
		}

		return actions;
	}

	getUrlPath(steps: PermissionEditorStep[]): string {
		return '/' + (steps.map((step) => step.segment)
			.filter((segment) => !!segment?.identifier && segment.identifier !== PermissionPathSegmentEnd) as Option[])
			.map((segment) => segment.identifier)
			.join('/');
	}

	getFieldsFlagsEditableStatus(actions: PermissionEditorAction[]): FieldsFlagsEditableStatus {
		const allowsEditFields = actions.some((action) =>
			action.selected
            && action.allowsEditFields
            && EditActions.includes(action.name),
		);
		const allowsReadFields = actions.some((action) =>
			action.selected
            && action.allowsReadFields
            && ReadActions.includes(action.name),
		);

		return {
			[PermissionControlKeys.EditFields]: allowsEditFields,
			[PermissionControlKeys.LockedFields]: /* TODO allowsEditFields, temporarily disable field blacklists */ false,
			[PermissionControlKeys.ReadFields]: allowsReadFields,
			[PermissionControlKeys.DeniedFields]: /* TODO allowsReadFields, temporarily disable field blacklists */ false,
		};
	}

	async findFields(steps: PermissionEditorStep[], q?: string): Promise<string[]> {
		const lastStep = steps[steps.length - 1];
		let fields: string[] = [];

		if (!lastStep) {
			return fields;
		}
        
		switch (lastStep.resource.path) {
			case ResourcePath.Me:
				if (!this.meFields) {
					this.meFields = this.resourcesClient.getMeFields();
				}
				fields = await this.meFields;
				break;
            
			case ResourcePath.Users:
			case ResourcePath.User:
				if (!this.userFields) {
					this.userFields = this.resourcesClient.getUsersFields();
				}
				fields = await this.userFields;
				break;
            
			case ResourcePath.Companies:
			case ResourcePath.Company:
				if (!this.companyFields) {
					this.companyFields = this.resourcesClient.getCompaniesFields();
				}
				fields = await this.companyFields;
				break;
            
			case ResourcePath.FormDataRepositoryDocument: {
				const projectId = this.getProjectId(steps);
				const buckedId = this.getBucketId(steps);

				if (projectId && buckedId) {
					fields = await this.resourcesClient.getBucketFields(projectId, buckedId);

				}
				break;
			}
		}

		const lowerQ = q?.trim().toLowerCase() ?? '';
        
		return lowerQ ?
			fields.filter((f) => f.toLowerCase().includes(lowerQ)) :
			[...fields];
	}

	private getProjectId(steps: PermissionEditorStep[]): number | undefined {
		const projectIdValue = steps.find((step) => step.resource.path === ResourcePath.Projects)?.segment?.identifier;
        
		return projectIdValue ? parseInt(projectIdValue) : undefined;
	}

	private getBucketId(steps: PermissionEditorStep[]): string | undefined {
		return steps.find((step) => step.resource.path === ResourcePath.FormDataRepositories)?.segment?.identifier;
	}

}
