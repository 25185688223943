import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { UfControl, ValidatorFunctions } from '@unifii/library/common';
import { Dictionary, Field, FieldType } from '@unifii/sdk';

import { BuilderField } from 'client';
import { BuilderService } from 'components/compound-builder/builder.service';
import { FieldDetailHelper, FieldDetailMetadata } from 'helpers/helpers';

import { FieldDetailBasic } from './field-detail-basic';

// TODO region to become part of project configuration
export const Region = 'AU';

const AddressAutocompleteKey = 'autocomplete';

const LatLng = 'latlng';

export const AddressFields: Dictionary<string[]> = {
	AU: ['address1', 'address2', 'suburb', 'state', 'postcode', 'country', 'map'],
	NZ: ['address1', 'address2', 'city', 'state', 'postcode', 'country', 'map'],
};

export interface NestedValues {
	readOnly: Dictionary<boolean>;
	required: Dictionary<boolean>;
	visible: Dictionary<boolean>;
}

@Component({
	selector: 'uc-field-nested',
	templateUrl: './field-nested.html',
	styleUrls: ['./field-nested.less'],
	standalone: false,
})
export class FieldNestedComponent extends FieldDetailBasic {

	readonly fieldType = FieldType;

	fields = {
		Address: AddressFields[Region],
		GeoLocation: ['lat', 'lng', 'map', LatLng],
	};

	values: NestedValues;
	autocompleteEnabled: boolean;

	protected fm: FieldDetailMetadata;

	constructor() {
		const builderService = inject(BuilderService);
		const ref = inject(ChangeDetectorRef);

		super(builderService, 'nested', ref);
	}

	protected update() { return; }

	protected setup(field: BuilderField) {

		const fields = this.fields[field.type as 'Address' | 'GeoLocation'];

		if (!fields) {
			console.warn(`FieldNestedComponent.setup FieldType should be 'Address' | 'GeoLocation' instead is ${field.type}`);

			return;
		}

		this.values = this.emptyValues;
		// Get metadata
		const parent = this.builderService.builder.getFieldPosition(this.field)?.parent as Field | undefined;

		this.fm = FieldDetailHelper.getMetadata(field, this.builderService.builder.type, parent);

		this.autocompleteEnabled = (field.visibleFields ?? []).includes(AddressAutocompleteKey);

		// Existing list, fallback to empty array
		field.readOnlyFields = field.readOnlyFields ?? [];
		// Existing list, fallback to empty array
		field.requiredFields = field.requiredFields ?? [];
		// Existing list or full list, default to empty array
		field.visibleFields = this.getVisibleFields(field, fields);

		for (const name of fields) {
			this.values.readOnly[name] = field.readOnlyFields.includes(name);
			this.values.required[name] = field.requiredFields.includes(name);
			this.values.visible[name] = field.visibleFields.includes(name);
		}

		if (field.readOnlyFields.includes('lat') || field.readOnlyFields.includes('lng')) {
			this.values.readOnly[LatLng] = true;
		}

		if (field.requiredFields.includes('lat') || field.requiredFields.includes('lng')) {
			this.values.required[LatLng] = true;
		}

		if (field.visibleFields.includes('lat') || field.visibleFields.includes('lng')) {
			this.values.visible[LatLng] = true;
		}

		// Set controls
		fields.forEach((name: string) => {
			this.form.addControl(name, new UfControl(ValidatorFunctions.custom(
				(v) => this.checkField(v),
				'A required field must be visible too')));
			this.updateControlValue(name);
		});

	}

	protected autocompleteEnabledChange(v: boolean) {

		if (v) {
			this.field.visibleFields = [AddressAutocompleteKey, ...(this.field.visibleFields ?? [])];
		} else {

			const index = (this.field.visibleFields ?? []).indexOf(AddressAutocompleteKey);

			if (index >= 0) {
				(this.field.visibleFields as string[]).splice(index, 1);
			}
		}
	}

	protected updateValues(name: string) {
		if (name === LatLng) {
			this.updateLatLngValue();
			this.updateValues('lat');
			this.updateValues('lng');
		}
		this.field.readOnlyFields = Object.keys(this.values.readOnly).filter((k) => this.values.readOnly[k] === true);
		this.field.requiredFields = Object.keys(this.values.required).filter((k) => this.values.required[k] === true);
		this.field.visibleFields = Object.keys(this.values.visible).filter((k) => this.values.visible[k] === true);

		const namedControl = this.form.get(name);

		if (namedControl) {
			namedControl.setValue({
				readOnly: this.values.readOnly[name],
				required: this.values.required[name],
				visible: this.values.visible[name],
			});

			namedControl.markAsTouched();
		}
	}

	protected getLabel(fieldName: string): string {
		switch (fieldName) {
			case 'lat': return 'latitude';
			case 'lng': return 'longitude';
			case LatLng: return 'latitude & longitude';
			default: return fieldName;
		}
	}

	private getVisibleFields(field: BuilderField, allFields: string[]): string[] {

		if (field.visibleFields == null && field.isNew) {
			return [...allFields];
		}

		// Not sure if this required? but ensure fields are match possible fields? must have been to protect about bad data
		return (field.visibleFields ?? []).filter((f) => allFields.includes(f) || f === AddressAutocompleteKey);
	}

	private get emptyValues(): NestedValues {
		return { readOnly: {}, required: {}, visible: {} };
	}

	private updateControlValue(fieldName: string) {
		const namedControl = this.form.get(fieldName);

		if (!namedControl) {
			return;
		}

		// Update corresponding form control
		namedControl.setValue({
			visible: (this.field.visibleFields ?? []).includes(fieldName),
			required: (this.field.requiredFields ?? []).includes(fieldName),
			readOnly: (this.field.readOnlyFields ?? []).includes(fieldName),
		});
	}

	private checkField(value: any): boolean {
		if (!value) {
			return true;
		}

		return !(!value.visible && value.required);
	}

	private updateLatLngValue() {
		this.values.readOnly.lat = !!this.values.readOnly[LatLng];
		this.values.required.lat = !!this.values.required[LatLng];
		this.values.visible.lat = !!this.values.visible[LatLng];

		this.values.readOnly.lng = !!this.values.readOnly[LatLng];
		this.values.required.lng = !!this.values.required[LatLng];
		this.values.visible.lng = !!this.values.visible[LatLng];
	}

}
