<uf-panel class="content-pane container">
	@if (project) {
		<div class="uf-container-lg">
			<div class="uf-grid row-gap-lg pad-top">
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">App Theme</div>
						<button (click)="applyTheme('green')" type="button" class="uf-button x-small primary">Apply Green Theme</button>
						<button (click)="applyTheme('blue')" type="button" class="uf-button x-small primary">Apply Blue Theme</button>
					</div>
					<uf-expander>
						<div expanderHeader class="uf-app-bar flat">
							<div class="title">Brand Colours</div>
						</div>
						<div expanderBody class="grid">
							@if (form.controls.brand) {
								<uc-color [control]="form.controls.brand | asUfControl" label="Brand" class="col-1of2" />
							}
							@if (form.controls.brandAccent) {
								<uc-color [control]="form.controls.brandAccent | asUfControl" label="Brand Accent" class="col-1of2" />
							}
						</div>
					</uf-expander>
					<uf-expander>
						<div expanderHeader class="uf-app-bar flat">
							<div class="title">Button/Action Colours</div>
						</div>
						<div expanderBody class="grid">
							@if (form.controls.primaryAction) {
								<uc-color [control]="form.controls.primaryAction | asUfControl" label="Primary Action" class="col-1of2" />
							}
							@if (form.controls.primaryActionDisabled) {
								<uc-color [control]="form.controls.primaryActionDisabled | asUfControl" label="Primary Action Disabled" class="col-1of2" />
							}
							@if (form.controls.primaryActionHover) {
								<uc-color [control]="form.controls.primaryActionHover | asUfControl" label="Primary Action Hover" class="col-1of2" />
							}
							@if (form.controls.primaryActionPressed) {
								<uc-color [control]="form.controls.primaryActionPressed | asUfControl" label="Primary Action Active" class="col-1of2" />
							}
							@if (form.controls.secondaryAction) {
								<uc-color [control]="form.controls.secondaryAction | asUfControl" label="Secondary Action" class="col-1of2" />
							}
							@if (form.controls.secondaryActionDisabled) {
								<uc-color [control]="form.controls.secondaryActionDisabled | asUfControl" label="Secondary Action Disabled" class="col-1of2" />
							}
							@if (form.controls.secondaryActionHover) {
								<uc-color [control]="form.controls.secondaryActionHover | asUfControl" label="Secondary Action Hover" class="col-1of2" />
							}
							@if (form.controls.secondaryActionPressed) {
								<uc-color [control]="form.controls.secondaryActionPressed | asUfControl" label="Secondary Action Active" class="col-1of2" />
							}
						</div>
					</uf-expander>
					<uf-expander>
						<div expanderHeader class="uf-app-bar flat">
							<div class="title">Form</div>
						</div>
						<div expanderBody class="grid">
							@if (form.controls.formStyle) {
								<uf-select [control]="form.controls.formStyle | asUfControl" [options]="formStyleOptions" label="Form Style" nameProperty="name" valueProperty="identifier" class="col-1of1" />
							}
							<div class="col-1of1">
								<h3>Colours</h3>
							</div>
							@if (form.controls.group) {
								<uc-color [control]="form.controls.group | asUfControl" label="Group first level colour" class="col-1of2" />
							}
							@if (form.controls.groupSecondLevel) {
								<uc-color [control]="form.controls.groupSecondLevel | asUfControl" label="Group second level colour" class="col-1of2" />
							}
							@if (form.controls.groupThirdLevel) {
								<uc-color [control]="form.controls.groupThirdLevel | asUfControl" label="Group third level colour" class="col-1of2" />
							}
							@if (form.controls.groupFourthLevel) {
								<uc-color [control]="form.controls.groupFourthLevel | asUfControl" label="Group fourth level colour" class="col-1of2" />
							}
							@if (form.controls.groupChild) {
								<uc-color [control]="form.controls.groupChild | asUfControl" label="Group children" class="col-1of2" />
							}
							@if (form.controls.inputLabel) {
								<uc-color [control]="form.controls.inputLabel | asUfControl" label="Input label colour" class="col-1of2" />
							}
						</div>
					</uf-expander>
					<uf-expander>
						<div expanderHeader class="uf-app-bar flat">
							<div class="title">Messaging Colours</div>
						</div>
						<div expanderBody class="grid">
							@if (form.controls.info) {
								<uc-color [control]="form.controls.info | asUfControl" label="Info" class="col-1of2" />
							}
							@if (form.controls.success) {
								<uc-color [control]="form.controls.success | asUfControl" label="Success" class="col-1of2" />
							}
							@if (form.controls.warning) {
								<uc-color [control]="form.controls.warning | asUfControl" label="Warning" class="col-1of2" />
							}
							@if (form.controls.error) {
								<uc-color [control]="form.controls.error | asUfControl" label="Error" class="col-1of2" />
							}
						</div>
					</uf-expander>
					<uf-expander>
						<div expanderHeader class="uf-app-bar flat">
							<div class="title">Other</div>
						</div>
						<div expanderBody class="grid">
							<uf-select [options]="inputStyleOptions" [(value)]="inputStyle" label="Input style" nameProperty="name" valueProperty="identifier" class="col-1of2" />
							@if (form.controls.borderRadius) {
								<uf-text [control]="form.controls.borderRadius | asUfControl" label="Border Radius" class="col-1of2" />
							}
						</div>
					</uf-expander>
				</div>
			</div>
		</div>
	}
</uf-panel>
