import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { DataDisplayListItem, DataDisplayService, Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { DataType, TenantSettings } from '@unifii/sdk';

import { Config } from 'app-config';

@Component({
	selector: 'uc-contact-info-modal',
	templateUrl: './contact-info-modal.html',
	standalone: false,
})
export class ContactInfoModalComponent implements Modal<TenantSettings, void>, OnInit {

	@HostBinding('class.uc-card') class = true;
	@HostBinding('class.grid') class1 = true;

	runtime = inject<ModalRuntime<TenantSettings, void>>(ModalRuntime);
	data = inject<TenantSettings>(ModalData);
	config = inject(Config);
	contactDetails: DataDisplayListItem[] = [];

	private dataDisplayService = inject(DataDisplayService);

	ngOnInit() {

		if (this.data.contactName != null) {
			this.contactDetails.push({ term: 'Name', data: this.data.contactName });
		}

		if (this.data.contactEmail != null) {
			this.contactDetails.push({ term: 'Email', data: this.dataDisplayService.displayAsDataDisplayValue(this.data.contactEmail, { type: DataType.Email }) });
		}

		if (this.data.contactPhone != null) {
			this.contactDetails.push({ term: 'Phone', data: this.dataDisplayService.displayAsDataDisplayValue(this.data.contactPhone, { type: DataType.Phone }) });
		}
	}

	close() {
		this.runtime.close();
	}

}
