<table class="uf-table inputs clickable small fixed">
	<thead>
		<tr>
			<th colspan="2" class="table-header">
				<div class="uf-app-bar flat accent">
					<div class="title">Data Mapping</div>
					<uf-drop-menu [options]="menuOptions" (selected)="onSort($event)" title="Sort By" class="uf-action" icon="sort" />
				</div>
			</th>
		</tr>
		<tr>
			<th>Source Data</th>
			<th>Target Data</th>
		</tr>
	</thead>
	<tbody>
		@for (targetField of targetFields; track targetFieldTrackBy($index, targetField)) {
			<tr>
				@if (getMappableControl(targetField.identifier); as control) {
					<td (click)="inputArgClick(control)">
						<div class="row space-children--small wrap">
							<ng-container
								*ngTemplateOutlet="
									field;
									context: {
										icon: getSourceFieldIcon(control.value.field),
										label: getSourceFieldLabel(control.value.field),
										field: control.value.field,
										expression: control.value.expression,
									}
								" />
							@if (control.showError) {
								<span class="uf-lozenge error">{{ control.errors?.message }}</span>
							}
						</div>
					</td>
					<td (click)="inputArgClick(control)">
						<div class="row space-children--small wrap">
							<ng-container
								*ngTemplateOutlet="
									field;
									context: {
										icon: getTargetFieldIcon(control.value.identifier),
										label: getTargetFieldLabel(control.value.identifier),
										field: control.value.identifier,
									}
								" />
							@if (control.value?.isRequired) {
								<span class="suffix">*</span>
							}
						</div>
					</td>
				}
			</tr>
		}
	</tbody>
</table>

@if (!control || control.controls.length === 0) {
	<div class="col center-all pad-top pad-sides">
		<uf-icon name="alert" class="x-large accent" />
		<h3 class="gap-none-bottom gap-sm-top">No Mappable Fields</h3>
		<p class="gap-sm-top">No Mappable fields for target</p>
	</div>
}

<ng-template #field let-icon="icon" let-field="field" let-label="label" let-expression="expression">
	@if (icon) {
		<uf-icon [name]="icon" />
	}
	@if (label) {
		<span>{{ label }}</span>
	}
	@if (label) {
		<span
			>(<code>{{ field }}</code
			>)</span
		>
	}
	@if (!label) {
		<span>{{ field }}</span>
	}
	@if (expression) {
		<span
			><code>{{ expression }}</code></span
		>
	}
</ng-template>
