import { UserDetailsComponent } from './components/user-details.component';
import { UserExternalAuthenticationComponent } from './components/user-external-authentication.component';
import { UserHierarchyComponent } from './components/user-hierarchy.component';
import { UserManagerComponent } from './components/user-manager.component';
import { UserMfaComponent } from './components/user-mfa.component';
import { UserRolesComponent } from './components/user-roles.component';
import { UserSystemRolesComponent } from './components/user-system-roles.component';
import { MyAccountComponent } from './my-account.component';

export * from './components/user-details.component';
export * from './components/user-hierarchy.component';
export * from './components/user-roles.component';
export * from './components/user-system-roles.component';
export * from './components/user-external-authentication.component';
export * from './components/user-manager.component';
export * from './components/user-mfa.component';
export * from './my-account.component';
export * from './user-provisioning';
export * from './user-form-provider';

export const USER_COMPONENTS = [
	MyAccountComponent, UserDetailsComponent, UserManagerComponent, UserExternalAuthenticationComponent, UserHierarchyComponent, UserSystemRolesComponent, UserRolesComponent, UserMfaComponent,
];
