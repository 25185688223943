import { Component, Input, OnInit, inject } from '@angular/core';
import { DataPropertyDescriptor, ModalService, UfControl, UfControlGroup } from '@unifii/library/common';

import { TableConfigurationKeys } from '../table-configuration.controller';

interface PageText {
	title: string;
	iconTitle: string;
	icon: 'custom' | 'filter';
}

@Component({
	selector: 'uc-table-configuration-hidden-filters',
	templateUrl: 'table-configuration-hidden-filters.html',
	standalone: false,
})
export class TableConfigurationHiddenFiltersComponent implements OnInit {

	@Input({ required: true }) form: UfControlGroup;
	@Input({ required: true }) availableHiddenFilters: DataPropertyDescriptor[];

	protected basicFiltersControl: UfControl | null;
	protected advancedFiltersControl: UfControl | null;
	protected isBasicFilter: boolean;
	protected pageText: PageText;

	private readonly basicPageText: PageText = {
		title: 'Hidden Filters',
		icon: 'custom',
		iconTitle: 'Switch to advanced',
	};
	private readonly advancedPageText: PageText = {
		title: 'Advanced Hidden Filters',
		icon: 'filter',
		iconTitle: 'Switch to basic',
	};
	private modalService = inject(ModalService);

	ngOnInit() {
		this.basicFiltersControl = this.form.get(TableConfigurationKeys.Filter) as UfControl | null;
		this.advancedFiltersControl = this.form.get(TableConfigurationKeys.AdvancedFilter) as UfControl | null;

		this.isBasicFilter = !!this.advancedFiltersControl && !this.advancedFiltersControl.value;
		this.pageText = this.isBasicFilter ? this.basicPageText : this.advancedPageText;
	}

	protected async switchFilter() {
		if (!this.advancedFiltersControl || !this.basicFiltersControl) {
			return;
		}

		const isBasicFilter = !this.isBasicFilter;

		const prompt = (isBasicFilter && this.advancedFiltersControl.value != null) || (!isBasicFilter && this.basicFiltersControl.value != null);

		if (prompt && !await this.modalService.openConfirm({
			title: 'Switch Filter Mode',
			message: 'Configuration for the current filter will be lost.',
			confirmLabel: 'Switch',
			cancelLabel: `Don't Switch`,
		})) {
			return;
		}

		if (isBasicFilter) {
			this.basicFiltersControl.setValue(null);
		} else {
			this.advancedFiltersControl.setValue(null);
		}

		this.isBasicFilter = isBasicFilter;
		this.pageText = this.isBasicFilter ? this.basicPageText : this.advancedPageText;
	}

}
