import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { HierarchyUnitState, HierarchyUnitWithChildCount } from '@unifii/sdk';

import { ToolTipPosition } from 'directives/tooltip.directive';

import { HierarchyFinderComponent } from './hierarchy-finder.component';

export interface FinderUnitChangeData {
	unit: HierarchyUnitWithChildCount;
	action: FinderContextMenu;
}

export enum FinderContextMenu {
	Update,
	Delete,
	Activate,
	Deactivate,
}

interface ContextOption {
	label: string;
	action: FinderContextMenu;
	predicate?: (unit: HierarchyUnitWithChildCount) => boolean;
}

@Component({
	selector: 'uc-hierarchy-finder-column',
	templateUrl: './hierarchy-finder-column.html',
	styleUrls: ['./hierarchy-finder-column.less'],
	standalone: false,
})
export class HierarchyFinderColumnComponent {

	@Input({ required: true }) label: string | undefined;
	@Input({ required: true }) parentId: string | undefined;
	@Input({ required: true }) selected: HierarchyUnitWithChildCount | undefined;
	@Input({ required: true }) hideEditButton: boolean;
	@Input({ required: true }) units: HierarchyUnitWithChildCount[];
	@Input() disabled: boolean | null;

	@Output() selectUnit = new EventEmitter<HierarchyUnitWithChildCount>();
	@Output() updateGroupLabel = new EventEmitter<void>();
	@Output() finderUnitChange = new EventEmitter<FinderUnitChangeData>();
	@Output() add = new EventEmitter<void>();

	protected readonly tooltipPosition = ToolTipPosition.Bottom;
	protected readonly contextOptions: ContextOption[] = [
		{ label: 'Edit', action: FinderContextMenu.Update },
		{ label: 'Delete', action: FinderContextMenu.Delete, predicate: (unit: HierarchyUnitWithChildCount) => this.hierarchyFinder.showDeleteContextMenu(unit) },
		{ label: 'Activate', action: FinderContextMenu.Activate, predicate: (unit: HierarchyUnitWithChildCount) => this.hierarchyFinder.showActivateContextMenu(unit) },
		{ label: 'Deactivate', action: FinderContextMenu.Deactivate, predicate: (unit: HierarchyUnitWithChildCount) => unit.state !== HierarchyUnitState.Inactive },
	];

	private hierarchyFinder = inject(HierarchyFinderComponent);

	protected get showEditColumnButton() {
		return !this.disabled && !this.hideEditButton;
	}

	protected isInactive(unit: HierarchyUnitWithChildCount): boolean {
		return unit.state === HierarchyUnitState.Inactive;
	}

	protected mapLabel(unit: HierarchyUnitWithChildCount) {
		return unit.id ? `${unit.label} (${unit.id})` : unit.label;
	}

	protected executeContextOption(unit: HierarchyUnitWithChildCount, contextOption?: ContextOption) {
		if (!contextOption) {
			return;
		}
		const { action } = contextOption;

		this.finderUnitChange.emit({
			action,
			unit,
		});
	}

}
