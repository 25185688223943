<uf-expander class="uf-box flat">
	<div expanderHeader class="uf-app-bar flat">
		<div class="title primary">Variations</div>
		@if (variations.invalid) {
			<uf-icon name="error" class="error" />
		}
		@if (variations.length) {
			<uc-expander-controls [container]="variationsWrap" />
		}
	</div>

	<div #variationsWrap expanderBody class="pad-sm">
		<uc-drag-list [items]="variations.controls" canDrop="false" canReorder="true">
			@for (variation of variations.controls | asUfControlsGroups; track variation; let i = $index) {
				<uf-expander [dragDisabled]="variations.disabled" [isExpanded]="expandeds[i]" dragItem class="uf-box flat draggable gap-sm-bottom">
					<div expanderHeader dragHandle class="uf-app-bar flat">
						<div class="title primary">
							{{ variation.get(variationKeys.Name)?.value }}
						</div>
						@if (variation.invalid) {
							<uf-icon name="error" class="error" />
						}
						@if (!variation.disabled) {
							<button (click)="removeVariation(i)" type="button" class="uf-action" title="Delete">
								<uf-icon name="delete" />
							</button>
						}
					</div>
					<div [formGroup]="variation" expanderBody class="uf-grid pad">
						@if (showVariationError(variation)) {
							<uf-message icon="errorSolid" class="x-small col-12 error">
								<p>This variation has an error - please select it for more information.</p>
							</uf-message>
						}
						<uf-text [formControlName]="variationKeys.Name" label="Name" class="col-12" />
						<uf-textarea [formControlName]="variationKeys.Condition" label="Condition" class="col-12" />
					</div>
				</uf-expander>
			}
		</uc-drag-list>

		@if (!control.disabled) {
			<button (click)="addVariation()" type="button" class="uf-button x-small right gap-sm-top">Add Variation</button>
		}
	</div>
</uf-expander>
