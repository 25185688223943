@if (ready && notifyEnabled) {
	<form class="grid">
		@if (config.role.show && form.controls.role) {
			<uf-chips [value]="roles" [options]="rolesResult" [control]="form.controls.role | asUfControl" (valueChange)="mapRoles($event, 'role')" (searchChange)="findRoles($event)" label="Roles" class="col-1of1" />
		}
		@if (config.showIf.show && form.controls.showIf) {
			<uf-textarea [control]="form.controls.showIf | asUfControl" [(value)]="field.showIf" label="Show If" class="col-1of1" />
		}
		@if (config.visibleTo.show && form.controls.visibleTo) {
			<uf-chips [value]="visibleTos" [options]="rolesResult" [control]="form.controls.visibleTo | asUfControl" (valueChange)="mapRoles($event, 'visibleTo')" (searchChange)="findRoles($event)" label="Visible to" class="col-1of1" />
		}
		@if (config.showOn.show && form.controls.showOn) {
			<uf-autocomplete [options]="actions" [control]="form.controls.showOn | asUfControl" [(value)]="field.showOn" (searchChange)="searchActions($event)" label="Show On" class="col-1of1" />
		}
	</form>
}
