import { EventEmitter, Injectable } from '@angular/core';

export interface MementoStatus {
	enabled: boolean;
	size: number;
	heap: number;
	current: any;
	canSave: boolean;
	canUndo: boolean;
	canRedo: boolean;
}

@Injectable({ providedIn: 'root' })
export class MementoService {

	statusChanged = new EventEmitter<MementoStatus>();
	edited = false;

	private states: any[];
	private current: any;
	private _enabled: boolean;
	// max quantity of states save
	private maxLength = 100;
	// max heap size occupied, at least one state is stored if size exceed max
	private maxHeapSize = 1000000;

	set enabled(v: boolean) {
		this._enabled = v;
		this.emitStatus();
	}

	get enabled(): boolean {
		return this._enabled;
	}

	reset() {
		this.states = [];
		this.current = null;
		this.enabled = true; // trigger a status emit
	}

	save(data: any, atomic = true) {
		if (this.canSave) {
			// console.log('MementoService save - atomic', atomic);

			const state = { data: JSON.parse(JSON.stringify(data)), atomic };

			// Clean up all saved state after the current one as no Redo action is supported after a new state is edited
			this.states.length = (this.current != null) ? (this.current + 1) : 0;

			// Save requested state
			if (this.states.length && !this.states[this.states.length - 1].atomic && !atomic) {
				// override last one
				this.states[this.states.length - 1] = state;
			} else {
				// add on top
				this.states.push(state);
			}

			// Limit states quantity (remove oldest saved state)
			while (this.exceedLimits()) {
				this.states.splice(0, 1);
			}

			// Update current index to the newly added state
			this.current = this.states.length - 1;

			// Notify memento change
			this.emitStatus();
		}
	}

	undo() {
		if (this.canUndo) {
			// After an undo operation the last state is no more mergeable
			this.states[this.states.length - 1].atomic = true;
			this.current = this.current - 1; // move to previous state
			this.emitStatus();

			// return previous state
			return JSON.parse(JSON.stringify(this.states[this.current].data));
		} else {
			return null;
		}
	}

	redo() {
		if (this.canRedo) {
			// A redo operation the last state is no more mergeable
			this.states[this.states.length - 1].atomic = true;
			this.current = this.current + 1; // move to previous state
			this.emitStatus();

			return JSON.parse(JSON.stringify(this.states[this.current].data));
		} else {
			return null;
		}
	}

	setLastAtomic() {
		if (this.states.length) {
			this.states[this.states.length - 1].atomic = true;
		}
	}

	get canSave() {
		return this.enabled;
	}

	get undos(): number {
		return Math.max(this.current, 0);
	}

	get canUndo() {
		if (!this.enabled) {
			return false;
		} else {
			return this.undos > 0;
		}
	}

	get redos(): number {
		return Math.max((this.states.length - (this.current + 1)), 0);
	}

	get canRedo() {
		if (!this.enabled) {
			return false;
		} else {
			return this.redos > 0;
		}
	}

	private exceedLimits(): boolean {
		// Can always store one state at least
		if (this.states.length < 2) {
			return false;
		}
		// Check states quantity limit
		if (this.maxLength && this.states.length > this.maxLength) {
			return true;
		} // Check heap size limit
		if (this.maxHeapSize && JSON.stringify(this.states).length > this.maxHeapSize) {
			return true;
		}

		// Status inside configured limits
		return false;
	}

	private getStatus(): MementoStatus {
		return {
			enabled: this.enabled,
			size: this.states.length,
			heap: JSON.stringify(this.states).length,
			current: this.current,
			canSave: this.canSave,
			canUndo: this.canUndo,
			canRedo: this.canRedo,
		};
	}

	private emitStatus(): void {
		this.statusChanged.emit(this.getStatus());
	}

}
