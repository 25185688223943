@if (ready) {
	<uf-expander class="uf-box flat pad-sm-sides uf-grid row-gap-sm pad-sm-top pad-sm-bottom">
		<div expanderHeader class="uf-app-bar flat">
			<div class="title primary">Settings</div>
			@if (isInvalid) {
				<uf-icon name="error" class="error" />
			}
		</div>
		<div [formGroup]="control" expanderBody class="uc-group-content grid">
			<uf-text [formControlName]="variationKeys.Label" label="Label" class="col-1of1" />
			<uc-markdown [formControlName]="variationKeys.Help" [showAlignmentOptions]="true" label="Help text" class="col-1of1" />
			<uf-text [formControlName]="variationKeys.Placeholder" label="Placeholder" class="col-1of1" />
		</div>
	</uf-expander>
	<uc-form-field-options [type]="type" [meta]="meta" [options]="options" [dataSourceConfig]="dataSourceConfig" [template]="template" [id]="id" [identifier]="identifier" [dataCaptures]="dataCaptures" class="col-1of1" />
	<uc-form-field-validators [validators]="validators" [type]="type" />
}
