import { COMPONENTS_COMMON } from './common';
import { COMPONENTS_CONFLICT_MODAL } from './conflict-modal';
import { COMPONENTS_CONTENT } from './content';
import { FILTER_COMPONENTS } from './filters';
import { INPUT_FILTERS } from './input-filters';

export const COMPONENTS = [
	COMPONENTS_COMMON,
	FILTER_COMPONENTS,
	INPUT_FILTERS,
	COMPONENTS_CONTENT,
	COMPONENTS_CONFLICT_MODAL,
];

export * from './common';
export * from './conflict-modal';
export * from './content';
export * from './filters';
export * from './input-filters';
