import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ModalAnchoredPosition, ModalPositionAlignment, ModalService } from '@unifii/library/common';

import { Config } from 'app-config';
import { AppUpdateService } from 'app-update/app-update.service';
import { SystemRole, UcClient, MyAccount as UcMyAccount, UcProjectInfo } from 'client';
import { AuthenticationService } from 'services/authentication.service';
import { ContextService } from 'services/context.service';
import { ProjectService } from 'services/project.service';

import { NavContextMenuComponent } from './nav-context-menu.component';
import { MenuConfig } from './nav-models';

@Component({
	selector: 'uc-tenant',
	templateUrl: './tenant.html',
	styleUrls: ['./tenant.less'],
	standalone: false,
})
export class TenantComponent implements OnInit {

	@HostBinding('class.stretch-component') stretchComponentClass = true;

	protected menuConfigs: MenuConfig[] = [];
	protected projectsContextMenu: MenuConfig[] = [];
	protected config = inject(Config);

	private client = inject(UcClient);
	private modalService = inject(ModalService);
	private context = inject(ContextService);
	private appUpdate= inject(AppUpdateService);
	private projectService = inject(ProjectService);
	private authService = inject(AuthenticationService);

	get account(): UcMyAccount {
		return this.context.account as UcMyAccount;
	}

	get project(): UcProjectInfo | null {
		return this.context.project;
	}

	get projectMenuConfig(): MenuConfig {
		return {
			label: 'Projects',
			contextMenuConfig: this.context.project ?
				this.projectsContextMenu.filter((config) => !config.link?.includes(this.context.project?.id ?? '')) :
				this.projectsContextMenu,
		};
	}

	ngOnInit() {
		this.appUpdate.init();
		this.initMenuConfig();
		void this.loadProjectOptions();
	}

	protected async logout() {
		await this.authService.logout(true);
	}

	protected openContextMenu(event: Event, menuConfig: MenuConfig) {
		this.modalService.closeLatest();
		void this.modalService.openAnchor(NavContextMenuComponent, event, this.getAnchorPosition(event.target as HTMLElement), menuConfig);
	}

	private initMenuConfig() {

		if (this.config.env === 'localhost' || this.config.env === 'dev') {
			this.menuConfigs.push({
				label: 'Lab',
				icon: 'labs',
				link: ['/lab'],
			});
		}

		if (this.context.checkRoles(SystemRole.AssetManager, SystemRole.ContentEditor, SystemRole.Publisher, SystemRole.ProjectManager)) {
			this.menuConfigs.push({
				label: 'Asset',
				icon: 'assets',
				link: ['/assets'],
				restrict: [SystemRole.AssetManager, SystemRole.ContentEditor, SystemRole.Publisher, SystemRole.ProjectManager].join(','),
			});
		}

		if (this.context.checkRoles(SystemRole.UserManager)) {
			this.menuConfigs.push({
				label: 'User Management',
				icon: 'user-settings',
				link: ['/user-management'],
				contextMenuConfig: [{
					label: 'Users',
					restrict: SystemRole.UserManager,
					link: ['/user-management', 'users'],
				}, {
					label: 'Roles',
					restrict: SystemRole.UserManager,
					link: ['/user-management', 'roles'],
				}, {
					label: 'User Claims',
					restrict: SystemRole.UserManager,
					link: ['/user-management', 'user-claims'],
				}, {
					label: 'Hierarchy',
					restrict: SystemRole.UserManager,
					link: ['/user-management', 'hierarchy'],
				}, {
					label: 'API Keys',
					restrict: SystemRole.UserManager,
					link: ['/user-management', 'api-keys'],
				}],
			});
		}

		if (this.context.checkRoles(SystemRole.SystemManager) || this.context.checkRoles(SystemRole.SuperUser, SystemRole.AppManager)) {
			this.menuConfigs.push({
				label: 'System Settings',
				icon: 'tenant-settings',
				link: ['/system-settings'],
				contextMenuConfig: [{
					label: 'General',
					restrict: SystemRole.SystemManager,
					link: ['/system-settings', 'general'],
				}, {
					label: 'Identity',
					restrict: SystemRole.SystemManager,
					link: ['/system-settings', 'sso'],
				}, {
					label: 'Email Settings',
					restrict: SystemRole.SystemManager,
					link: ['/system-settings', 'email'],
				}, {
					label: 'Messaging',
					restrict: SystemRole.SystemManager,
					link: ['/system-settings', 'messaging'],
				}, {
					label: 'Integrations',
					restrict: SystemRole.SystemManager,
					link: ['/system-settings', 'integrations'],
				}, {
					label: 'Apps',
					restrict: [SystemRole.SuperUser, SystemRole.AppManager].join(','),
					link: ['/system-settings', 'apps'],
				}],
			});
		}

		this.menuConfigs.push({
			label: 'My Account',
			icon: 'users-and-security',
			link: ['/my-account'],
			contextMenuConfig: [{
				label: 'My Account',
				link: ['/my-account'],
			}, {
				label: 'Log Out',
				action: () => void this.logout(),
			}],
		});
	}

	// TODO make a util function
	private getAnchorPosition(target: HTMLElement): ModalAnchoredPosition {
		return {
			target,
			originX: ModalPositionAlignment.Right,
			originY: ModalPositionAlignment.Top,
			alignmentX: ModalPositionAlignment.Right,
			alignmentY: ModalPositionAlignment.Bottom,
		};
	}

	private async loadProjectOptions() {
		if (!this.projectService.checkProjectRoles()) {
			return;
		}

		const projects = await this.client.getProjects();

		this.projectsContextMenu = projects.map((project) => ({
			label: project.name,
			link: ['/projects', project.id],
		}));
	}

}
