import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { Option, UfError, ensureUfRequestError } from '@unifii/sdk';

import { PermissionPrincipalType, UcAPIKeys, UcClient, UcPermission, UcPermissionsClient, UcRoles, UcUsers } from 'client';

import { PermissionChangeAction, PermissionsManagerService } from './permissions-manager.service';

@Component({
	selector: 'uc-permissions-cloner',
	templateUrl: './permissions-cloner.html',
	standalone: false,
})
export class PermissionsClonerComponent implements Modal<UcPermission[], void>, OnInit {

	@HostBinding('class.uc-form-card') class = true;

	runtime: ModalRuntime<UcPermission[], void> = inject(ModalRuntime);
	data: UcPermission[] = inject(ModalData);

	protected readonly typeControlKey = 'type';
	protected readonly targetsControlKey = 'targets';
	protected readonly types: Option[] = [{
		identifier: PermissionPrincipalType.User,
		name: 'User',
	}, {
		identifier: PermissionPrincipalType.Role,
		name: 'Role',
	}, {
		identifier: PermissionPrincipalType.ApiKey,
		name: 'ApiKey',
	}];

	protected targets: Option[] = [];
	protected items: { permission: UcPermission; pathLabel: string; cloned?: boolean; error?: UfError }[] = [];
	protected busy: boolean;
	protected cloned: boolean;
	protected form: UfControlGroup;

	private uf = inject(UfFormBuilder);
	private ucClient = inject(UcClient);
	private ucUsers = inject(UcUsers);
	private ucAPIKeys = inject(UcAPIKeys);
	private ucRoles = inject(UcRoles);
	private permissionsManager = inject(PermissionsManagerService);

	ngOnInit() {
		this.items = this.data.map((permission) => ({ permission, pathLabel: (permission.path as string[]).join('/') }));

		this.form = this.uf.group({
			[this.typeControlKey]: [null, ValidatorFunctions.required('A type is required')],
			[this.targetsControlKey]: [null, ValidatorFunctions.required('A target is required')],
		});
	}

	close() {
		this.runtime.close();
	}

	protected get typeControl() {
		return this.form.get(this.typeControlKey) as UfControl;
	}

	protected get targetsControl() {
		return this.form.get(this.targetsControlKey) as UfControl;
	}

	protected changeType() {
		this.targetsControl.setValue(null);
	}

	protected async filterTarget(query: string) {

		const type = this.typeControl.value as Option | null;

		if (!type) {
			return;
		}

		switch (type.identifier) {

			case PermissionPrincipalType.User:
				this.targets = (await this.ucUsers.get(query, 'username'))
					.map((user) => ({ identifier: user.id ?? '', name: user.username }));
				break;

			case PermissionPrincipalType.Role:
				this.targets = (await this.ucRoles.get(query, 'name'))
					.map((role) => ({ identifier: role.id ?? '', name: role.name }));
				break;

			case PermissionPrincipalType.ApiKey:
				this.targets = (await this.ucAPIKeys.get())
					.map((apiKey) => ({ identifier: apiKey.key ?? '', name: apiKey.consoleName ?? '' }));
				break;
		}
	}

	protected reset() {
		this.cloned = false;
		for (const item of this.items) {
			item.error = undefined;
			item.cloned = false;
		}
	}

	protected async clone() {

		this.form.setSubmitted();

		if (this.form.invalid) {
			return;
		}

		const principalType = this.typeControl.value?.identifier as PermissionPrincipalType;
		const principalId = this.targetsControl.value?.identifier as string;

		this.busy = true;

		for (const item of this.items) {
			delete item.cloned;
			delete item.error;
		}

		const permissionsClient = new UcPermissionsClient(this.ucClient, principalType, principalId);

		let clonedCount = 0;

		for (const item of this.items) {
			const permission = Object.assign({}, item.permission);

			delete permission.id;
			try {
				await permissionsClient.save(permission);
				item.cloned = true;
				clonedCount++;
			} catch (e) {
				console.error(e);
				item.error = ensureUfRequestError(e);
			}
		}

		if (clonedCount > 0) {
			this.permissionsManager.notify.next({
				action: PermissionChangeAction.Added,
				principalType,
				principalId,
			});
		}

		this.cloned = true;
		this.busy = false;
	}

}
