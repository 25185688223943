<ul class="uf-list uf-box">
	@for (config of data.contextMenuConfig; track config) {
		<a [routerLink]="config.link" [restrict]="config.restrict" (click)="config.action ? config.action() : undefined" routerLinkActive="router-link-active">
			<!-- keep li wrapper instead of adding class to anchor, to make sure active link style works -->
			<li class="uf-list-button">
				@if (config.icon) {
					<uf-icon [name]="config.icon" />
				}
				<span>{{ config.label }}</span>
			</li>
		</a>
	}
</ul>
