@if (user && !isNew) {
	<uf-panel class="container locked">
		<div class="header-pane uf-app-bar transparent">
			<uc-builder-header [templateRef]="templateRef" />
		</div>
		<div class="content-pane">
			<uf-tabs>
				<uf-tab label="Details" path="" />
				<uf-tab label="Devices" path="devices" />
				<uf-tab label="Permissions" path="permissions" restrict="SuperUser" />
				<uf-tab label="Inherited Permissions" path="inherited-permissions" restrict="SuperUser" />
			</uf-tabs>
		</div>
	</uf-panel>
}

@if (isNew && user) {
	<router-outlet />
}

<uc-error-message [error]="error" />
