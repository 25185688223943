@if (form) {
	<div class="uf-app-bar default flat transparent pad-sides">
		<uc-builder-header />
	</div>
	<div [class.can-edit]="!form.disabled" class="builder-main">
		@if (!form.disabled) {
			<uf-panel class="scrollable">
				<uc-item-picker [groups]="itemGroups" />
			</uf-panel>
		}
		<uf-panel class="dropzone uf-box flat">
			<div #nodeList>
				<uc-node-field [control]="form" (expandAll)="toggleExpanders($event, nodeList)" class="top-node" />
				<uc-drag-list [parent]="children" [items]="children.controls" [canDrop]="service.canDrop" [convertAdded]="service.addConverter" [drop]="service.drop" [entries]="children.controls" [current]="status.selected" [filter]="filterStructureNodeControls" (next)="service.selectNode($event)" keySelect class="pad-sm-left pad-xs-top">
					@if (children.length === 0) {
						<div class="pad col center-all">
							<img src="/assets/svg/icon-structure.svg" alt="" />
							<p class="small text-center">Your structure is empty, drag an item from the left to start creating it!</p>
						</div>
					}
					@for (child of children.controls | asUfControlsGroups; track child) {
						<uc-node-field [control]="child" [dragDisabled]="child.disabled" [nestable]="true" dragItem />
					}
				</uc-drag-list>
			</div>
		</uf-panel>
		<uf-panel class="scrollable">
			@if (status.selected) {
				<uc-node-details [control]="status.selected" />
			}
		</uf-panel>
	</div>
}
