<header>
	<h1>Drag Test</h1>
</header>
<main>
	<article class="padded">
		<uc-drag-list [items]="items">
			<ul class="fields">
				@for (item of items; track item) {
					<li [nestable]="true" dragItem>Test Node</li>
				}
			</ul>
		</uc-drag-list>
	</article>
</main>
