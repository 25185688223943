@if (!isNew) {
	<uf-panel class="container">
		<div class="header-pane uf-app-bar transparent">
			<uc-builder-header [templateRef]="templateRef" />
		</div>
		@if (id) {
			<div class="content-pane scrollable">
				<uf-tabs>
					<uf-tab label="Settings" path="" />
					@if (arePushNotificationsEnabled) {
						<uf-tab label="Devices" path="devices" />
						<uf-tab label="Send Notification" path="send-notification" />
					}
				</uf-tabs>
			</div>
		}
	</uf-panel>
}

@if (isNew) {
	<router-outlet />
}
