"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientHeaders = void 0;
const functions_1 = require("./functions");
const models_1 = require("./models");
/**
 * ClientHeaders is responsible for handling all logic
 * related to SDK request headers
 * methods:
 * - get > returns headers for all requests
 * options:
 * - skip unifii headers set via excludeUnifiiRequestHeaders, useful when client is extended and does not require unifii headers
 */
class ClientHeaders {
  constructor(options) {
    this.options = options;
    this._deviceId = null;
  }
  getSigned({
    method,
    url,
    headers,
    body,
    contentType,
    multipart,
    accessToken,
    analytics
  }) {
    const baseHeaders = this.base;
    if (accessToken) {
      baseHeaders.set(models_1.HeaderKeys.Authorization, 'Bearer ' + accessToken);
    }
    const mergedHeaders = this.merge(baseHeaders, headers);
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment*/
    return this.sign({
      headers: mergedHeaders,
      method,
      url,
      body,
      contentType,
      multipart,
      analytics
    });
  }
  set deviceId(v) {
    this._deviceId = v;
  }
  get deviceId() {
    return this._deviceId;
  }
  get base() {
    const headers = new Headers({
      [models_1.HeaderKeys.AcceptLanguage]: 'source'
    });
    if (this.options.excludeUnifiiRequestHeaders) {
      return headers;
    }
    if (this.options.appId != null) {
      headers.set(models_1.HeaderKeys.XUnifiiApp, [this.options.appId, this.options.appVersion, this.options.platform].join('|'));
    }
    if (this.deviceId != null) {
      headers.set(models_1.HeaderKeys.XUnifiiDevice, this.deviceId);
    }
    return headers;
  }
  merge(headers, extraHeaders) {
    if (extraHeaders != null) {
      extraHeaders.forEach((value, key) => {
        if (value && key) {
          headers.set(key, value);
        }
      });
    }
    return headers;
  }
  async sign({
    headers,
    method,
    url,
    body,
    contentType,
    multipart,
    analytics
  }) {
    if (!contentType) {
      contentType = models_1.ClientContentType.ApplicationJson;
    }
    if (!multipart) {
      headers.set(models_1.HeaderKeys.ContentType, contentType);
    }
    if (analytics) {
      const analyticsCSV = (0, functions_1.objectKeys)(analytics).map(k => ({
        key: k,
        value: analytics[k]
      })).filter(info => (0, functions_1.isNotNull)(info.value)).map(info => `${info.key}=${info.value}`).join(';');
      if (analyticsCSV.trim().length > 0) {
        headers.set(models_1.HeaderKeys.XUnifiiAnalytics, analyticsCSV);
      }
    }
    // Authorization in Header, no need for sign
    if (headers.get(models_1.HeaderKeys.Authorization) || this.signatureInfo == null) {
      return headers;
    }
    const stamp = new Date().toISOString();
    headers.set(models_1.HeaderKeys.XUnifiiDate, stamp);
    const parsedURL = new URL(url);
    const acceptLanguage = headers.get(models_1.HeaderKeys.AcceptLanguage) ?? '';
    const sts = [method.toUpperCase(), parsedURL.pathname, parsedURL.search ? parsedURL.search.substring(1) : '', `host:${parsedURL.hostname}`, `content-type:${contentType}`, `accept-language:${acceptLanguage}`, stamp, await (0, functions_1.hashBody)(body || '')].join('\n');
    const signature = await (0, functions_1.hmac)(sts, this.signatureInfo.secret);
    headers.set(models_1.HeaderKeys.Authorization, `UNIFII ${this.signatureInfo.key}:${signature}`);
    return headers;
  }
  get signatureInfo() {
    if (this.options.apiKey && this.options.apiSecret) {
      return {
        key: this.options.apiKey,
        secret: this.options.apiSecret
      };
    }
    if (this.options.appId && this.options.appSecret) {
      return {
        key: this.options.appId,
        secret: this.options.appSecret
      };
    }
    return undefined;
  }
}
exports.ClientHeaders = ClientHeaders;
