import { DestroyRef, Directive, EventEmitter, Input, Output, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DOMEventHandler, UfControlGroup } from '@unifii/library/common';

import { flattenControls } from 'helpers/controls-helper';
import { ArrayHelper } from 'helpers/helpers';

@Directive({
	selector: '[keySelect]',
	standalone: false,
})
export class KeySelectDirective {

	@Input() entries: any[];
	@Input() current: any;
	@Input() filter: ((c: UfControlGroup) => boolean) | null | undefined;
	@Output() next = new EventEmitter<any>();

	/** List of HTML element to not navigate away from by KeySelectDirective */
	readonly tagsToKeepFocused = ['input', 'select', 'option', 'textarea', 'label', 'button'];

	private destroy = inject(DestroyRef);
	private domEventHandler = inject(DOMEventHandler);

	constructor() {

		this.domEventHandler.register({
			event: 'keydown',
			destroy: this.destroy,
			listener: (keyboardEvent) => {

				// Only react to ArrowUp and ArrowDown keys
				if (!['ArrowDown', 'ArrowUp'].includes(keyboardEvent.key)) {
					return;
				}

				// Chek for element to keep focused
				if (!this.isBlurAwayAllowedFrom(document.activeElement)) {
					return;
				}

				// Try to select next one
				this.updateView(keyboardEvent.key === 'ArrowDown');
			},
		});
	}

	updateView(next: boolean) {

		// Controls
		if (this.current instanceof AbstractControl) {

			let controls = flattenControls(this.current.root)
				.map((c) => c.control)
				.filter((c) => c instanceof UfControlGroup) as UfControlGroup[];

			if (this.filter) {
				controls = controls.filter((c) => (this.filter as (c: UfControlGroup) => boolean)(c));
			}

			const currentIndex = controls.findIndex((c) => c === this.current);

			if (currentIndex < 0) {
				return;
			}

			const selectedIndex = currentIndex + (next ? 1 : -1);

			if (selectedIndex < 0 || selectedIndex >= controls.length) {
				return;
			}

			const selectedControl = controls[selectedIndex];

			if (!selectedControl) {
				return;
			}

			this.next.emit(selectedControl);

			return;
		}

		// Array
		if (this.entries) {
			const found = ArrayHelper.findNext(next, this.current, this.entries, null) || this.current;

			this.next.emit(found);
		}

	}

	/** Detect if it is allowed to blur away (aka remove focus) from the element */
	private isBlurAwayAllowedFrom(element: Element | null) {
		if (!element) {
			return true;
		}

		return !!this.tagsToKeepFocused.includes(element.tagName.toLowerCase());
	}

}
