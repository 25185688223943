@if (!error) {
	<uf-panel class="container">
		<div class="header-pane uf-app-bar transparent">
			<uc-builder-header />
		</div>
		<div class="content-pane">
			<uf-tabs (selectedChange)="updateTitle()">
				<uf-tab label="Settings" path="settings" />
				<uf-tab label="Live Data" path="live" />
				<uf-tab label="Preview Data" path="preview" />
			</uf-tabs>
		</div>
	</uf-panel>
}

<uc-error-message [error]="error" />
