import { Component, HostBinding, HostListener, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { UcClient } from 'client';
import { DragItemDirective } from 'components/dragdrop/drag-item.directive';
import { SubscriptionsTrackerService } from 'services/subscription-tracker.service';

@Component({
	selector: 'uc-app',
	templateUrl: './app.html',
	styleUrls: ['./app.less'],
	providers: [DragItemDirective],
	standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {

	@HostBinding('class.stretch-component') class = true;
	@HostBinding('class.small') small = true;

	protected progressCount = 0;

	private subscriptions = new Subscription();
	private client = inject(UcClient);
	private subscriptionsTracker = inject(SubscriptionsTrackerService);

	@HostListener('window:unload', ['$event']) unloadHandler() {
		this.subscriptionsTracker.destroyAppModuleAndTrackObservableUnhandledSubscriptions(true);
	}

	@HostListener('window:beforeunload', ['$event']) beforeUnloadHandler() {
		this.subscriptionsTracker.destroyAppModuleAndTrackObservableUnhandledSubscriptions(true);
	}

	ngOnInit() {

		this.subscriptions.add(this.client.inProgress.pipe(delay(0)).subscribe((inProgress) => {
			if (inProgress) {
				this.progressCount++;
			} else if (this.progressCount > 0) {
				this.progressCount--;
			}
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}
