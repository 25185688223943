<uf-panel class="container">
	<div class="header-pane uf-app-bar transparent">
		<uf-breadcrumbs [breadcrumbs]="[{ name: 'Lab' }]" class="large" />
	</div>

	<div class="content-pane">
		<uf-tabs>
			<uf-tab path="style-guide" label="Style Guide" />
			<uf-tab path="empty" label="Empty" />
			<uf-tab path="icons" label="Icons" />
			<uf-tab path="lists" label="Lists" />
			<uf-tab path="drag-list" label="Drag List" />
			<uf-tab path="reactive-forms" label="Reactive Forms" />
			<uf-tab path="template-form" label="Template Form" />
			<uf-tab path="template-builder" label="Template Builder" />
			<uf-tab path="template-table" label="Template Table" />
			<uf-tab path="template-tabs" label="Template Tabs" />
		</uf-tabs>
	</div>
</uf-panel>
