import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { amendOptionsParams } from '@unifii/sdk';

import { IntegrationInfo, UcIntegrations } from 'client';

export class SystemIntegrationsDataSource extends TableDataSource<IntegrationInfo> {

	filtered: boolean;
	sorted: boolean;

	constructor(
		private ucIntegrations: UcIntegrations,
		private search?: string,
	) {
		super();
	}

	load(options?: TableDataSourceQueryOptions) {
		this.ucIntegrations.list(amendOptionsParams(
			{ q: this.search, offset: options?.offset, limit: options?.limit },
		)).then((items) => {
			this.stream.next({ data: items });
		}, (error) => {
			this.stream.next({ error });
		});
	}

}
