@if (ready) {
	<div class="header">
		<button (click)="close()" type="button" class="uf-action tertiary" title="Close">
			<uf-icon name="close" />
		</button>
		<uc-content-description [control]="descriptionControl" mode="icon" class="header-icon" />
		<h3>{{ label }}</h3>
	</div>
	<uf-panel class="container">
		<div [formGroup]="control" class="uf-grid pad-sides pad-bottom">
			<uc-content-description [control]="descriptionControl" mode="view" class="col-12" />
			<uf-autocomplete [formControlName]="transitionKeys.Source" [options]="startStatusesResult" [allowCustom]="true" (searchChange)="findStartStatuses($event)" label="Start Status" placeholder="Start" class="col-6" />
			<uf-autocomplete [formControlName]="transitionKeys.Target" [options]="endStatusesResult" [allowCustom]="true" (searchChange)="findEndStatuses($event)" label="Target Status" class="col-6" />
			<uf-text [formControlName]="transitionKeys.ActionLabel" label="Button Label" placeholder="Request" class="col-6" />
			<uf-text [formControlName]="transitionKeys.Action" label="Action" placeholder="Request" class="col-6" />
			<uf-chips [formControlName]="transitionKeys.Roles" [options]="rolesResult" (searchChange)="findRoles($event)" label="Roles" class="col-6" />
			<uf-text [formControlName]="transitionKeys.Result" label="Result" class="col-6" />
			<uf-checkbox [formControlName]="transitionKeys.Validate" label="Validate Required Fields" class="col-12" />
			<uf-checkbox [formControlName]="transitionKeys.HasPersistentVisibility" label="Always visible" class="col-12" />
			<uf-checkbox [formControlName]="transitionKeys.KeepOpen" label="Keep form open after transition" class="col-12" />
			<uf-chips [formControlName]="transitionKeys.Tags" [options]="tagsResult" [allowCustom]="true" (searchChange)="findTags($event)" label="Tags" class="col-12" />
			<uf-textarea [formControlName]="transitionKeys.ShowIf" label="Show if" class="col-12" />
		</div>
	</uf-panel>
	<uf-error [control]="control" class="col-12 center-all gap-left" />
	<div class="row space-children pad">
		<button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
		<button (click)="save()" type="button" class="uf-button primary">Save</button>
	</div>
}
