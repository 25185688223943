import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FeatureFlagService, ModalService, WindowWrapper } from '@unifii/library/common';
import { AuthProvider, TenantClient, isValueOfStringEnumType } from '@unifii/sdk';

import { UcAuthProviders } from 'client';
import { TypeSelectComponent, TypeSelectConfig } from 'components';

import { getAuthProviderImagePath, getAuthProviderLabel, getAuthProviderUrlSegment } from './auth-provider-functions';
import { AuthProvidersTableManager } from './auth-providers-table-manager';

@Component({
	selector: 'uc-auth-providers',
	template: `
    <uf-table-container (addItem)="selectProvider()" ufSyncRoute ufMasterDetail class="cards container"/>
    <router-outlet/>
`,
	providers: [
		{ provide: TableContainerManager, useClass: AuthProvidersTableManager },
	],
	standalone: false,
})
export class AuthProvidersComponent {

	private readonly azureBaseUrl = 'https://login.microsoftonline.com/';
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private modalService = inject(ModalService);
	private tenantClient = inject(TenantClient);
	private window = inject<Window>(WindowWrapper);
	private ucAuthProviders = inject(UcAuthProviders);
	private featureFlagService = inject(FeatureFlagService);
	private manager = inject<AuthProvidersTableManager>(TableContainerManager);

	refresh() {
		this.manager.reload.next();
	}

	protected async selectProvider() {

		const config: TypeSelectConfig = {
			title: 'Select SSO Provider',
			types: [
				{
					type: AuthProvider.Azure,
					label: getAuthProviderLabel(AuthProvider.Azure),
					logo: getAuthProviderImagePath(AuthProvider.Azure),
					manualRegistration: false,
				},
				{
					type: AuthProvider.Azure,
					label: `${getAuthProviderLabel(AuthProvider.Azure)} - Manual Registration`,
					logo: getAuthProviderImagePath(AuthProvider.Azure),
				},
				{
					type: AuthProvider.Auth0,
					label: getAuthProviderLabel(AuthProvider.Auth0),
					logo: getAuthProviderImagePath(AuthProvider.Auth0),
				},
				{
					type: AuthProvider.Okta,
					label: getAuthProviderLabel(AuthProvider.Okta),
					logo: getAuthProviderImagePath(AuthProvider.Okta),
				},
			],
		};

		const unifiiIdentityEnabled = await this.featureFlagService.isEnabled('unifiiIdentity');

		if (unifiiIdentityEnabled) {
			config.types.push({
				type: AuthProvider.UnifiiIdentity,
				label: getAuthProviderLabel(AuthProvider.UnifiiIdentity),
				logo: getAuthProviderImagePath(AuthProvider.UnifiiIdentity),
			});
		}

		const provider = await this.modalService.openMedium(TypeSelectComponent, config);

		if (!provider) {
			return;
		}

		// if it's automatic registration
		if (provider.manualRegistration === false && isValueOfStringEnumType(AuthProvider)(provider.type) && provider.type === AuthProvider.Azure) {
			void this.redirectToAzure();

			return;
		}

		void this.router.navigate([getAuthProviderUrlSegment(provider.type as AuthProvider), 'new'], { relativeTo: this.route });
	}

	private async redirectToAzure() {
		const microsoftConfig = await this.ucAuthProviders.getMicrosoftConfig();
		const redirectUrl = await this.getRedirectUrl();
		const url = `${this.azureBaseUrl}common/adminconsent?response_mode=form_post&client_id=${microsoftConfig.clientId}&redirect_uri=${redirectUrl}`;

		this.window.location.assign(url);
	}

	private async getRedirectUrl(): Promise<string> {
		const baseUrl = `${this.window.location.origin}/system-settings/sso/azure/automatic-registration`;
		const oidcState = await this.tenantClient.getOIDCState(baseUrl, {});

		return `https://directory.unifii.net/azure/callback&state=${oidcState.state}`;
	}

}
