import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb } from '@unifii/library/common';
import { FileData, FileType, isNotNull } from '@unifii/sdk';

import { UcMedia } from 'client';
import { EditData } from 'components';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { TenantSettingsService } from 'services/tenant-settings.service';

import { MediaTableManager } from './media-table-manager';

@Component({
	selector: 'uc-media-new',
	templateUrl: './media-new.html',
	styleUrls: ['./media-new.less'],
	standalone: false,
})
export class MediaNewComponent implements OnInit, EditData {

	@HostBinding('class.stretch-component')
	@HostBinding('class.media-new') class = true;

	edited: boolean;

	protected assets: { id: number }[] = [];
	protected allowedAssets: FileType[] | undefined;
	protected breadcrumbs: Breadcrumb[] = [];

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private ucMedia = inject(UcMedia);
	private context = inject(ContextService);
	private tenantSettings = inject(TenantSettingsService);
	private breadcrumbService = inject(BreadcrumbService);
	private tableManager = inject<MediaTableManager>(TableContainerManager);

	async ngOnInit() {
		const settings = await this.tenantSettings.sync();

		this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, ['New']);

		this.allowedAssets = settings.allowedAssetFileTypes ?? undefined;
	}

	async onLeavePage(): Promise<void> {
		this.edited = false;

		await Promise.all(this.assets.map((asset) => this.ucMedia.delete(asset.id)));
	}

	protected onContentChange(value: FileData[]) {
		this.assets = value
			.map<{ id: number }>((asset) => {
				return { id: Number.parseInt(`${asset.id}`) };
			})
			.filter(isNotNull);

		this.edited = !!value.length;
	}

	protected back() {
		void this.router.navigate(['../'], { relativeTo: this.route });
	}

	protected continue() {
		this.edited = false;
		void this.router.navigate(['..', { pending: true }], { relativeTo: this.route });
		this.tableManager.reload.next();
	}

	protected get disabled(): boolean {
		return this.context.tenantSettings?.allowedAssetFileTypes != null &&
            this.context.tenantSettings.allowedAssetFileTypes.length === 0;
	}

}
