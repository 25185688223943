@if (mode === "view") {
	@if (description) {
		<uf-message [content]="description" [actions]="actions" icon="infoSolid" class="col-12 info" />
	}
} @else {
	@if (!description) {
		<a (click)="edit()" title="Add description" class="uf-action tertiary">
			<uf-icon name="notes" />
		</a>
	}
}
<ng-template #icon>
	@if (!description) {
		<a (click)="edit()" title="Add description" class="uf-action tertiary">
			<uf-icon name="notes" />
		</a>
	}
</ng-template>
