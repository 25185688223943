import { Injectable, Type } from '@angular/core';
import { FilterEntry, FilterValue, IFilterComponent, UfFilterComponentRegistry } from '@unifii/library/common';

import { CustomChoiceFilterComponent } from 'components';

@Injectable()
export class InfoFilterRegistry extends UfFilterComponentRegistry {

	override get(entry: FilterEntry): Type<IFilterComponent<FilterValue>> | undefined {
		if (entry.identifier === 'state') {
			return CustomChoiceFilterComponent as Type<IFilterComponent<FilterValue>>;
		}

		return super.get(entry);
	}

}
