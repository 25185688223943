import { Injectable, InjectionToken, inject } from '@angular/core';
import { HierarchyUnitProvider } from '@unifii/library/common';
import { HierarchyUnitExtended, Role, Schema, SchemaField } from '@unifii/sdk';

import { ConsoleDataSource, DefinitionInfo, Integration, UcClaimConfig, UcDataSources, UcDefinition, UcFormBucketClient, UcIntegrations, UcProject, UcRoles, UcUserClaims } from 'client';
import { DataSourceEditorCache } from 'components/field-builder/data-source-editor/data-source-model';

import { FormEditorFunctions } from './form-editor-functions';

export interface FormEditorCache {
	getSchema(bucket?: string | null): Promise<Schema | null>;
	getSchemaFieldLookup(path: string[]): Promise<SchemaField | undefined>;
	getCollectionDefinition(identifier: string): Promise<UcDefinition | null>;
	getExternalDataSource(identifier: string): Promise<ConsoleDataSource | null>;
	getHierarchyUnit(id: string): Promise<HierarchyUnitExtended | undefined>;
	listUserClaimConfig(): Promise<UcClaimConfig[]>;
	getRoles(): Promise<Role[]>;
	getFormsInfo(): Promise<DefinitionInfo[]>;
	reset(): void;
	bucketIdentifier: string;
}
export const FormEditorCache = new InjectionToken<FormEditorCache>('FormEditorCache');

@Injectable()
export class FormEditorCacheService implements FormEditorCache, DataSourceEditorCache {

	private ucProject = inject(UcProject);
	private ucRoles = inject(UcRoles);
	private ucDataSources = inject(UcDataSources);
	private ucFormBucketClient = inject(UcFormBucketClient);
	private userClaimsClient = inject(UcUserClaims);
	private ucIntegrations = inject(UcIntegrations);
	private hierarchyUnitProvider = inject(HierarchyUnitProvider);
	private schemas = new Map<string, Promise<Schema | null>>();
	private schemaFields = new Map<string[], Promise<SchemaField | undefined>>();
	private collectionDefinitions = new Map<string, Promise<UcDefinition | null>>();
	private externalDataSources = new Map<string, Promise<ConsoleDataSource | null>>();
	private integrations = new Map<string, Promise<Integration | null>>();
	private hierarchyUnits = new Map<string, Promise<HierarchyUnitExtended | undefined>>();
	private formsInfo: Promise<DefinitionInfo[]> | undefined;
	private userClaimConfig: Promise<UcClaimConfig[]> | undefined;
	private roles: Promise<Role[]> | undefined;
	private _bucketIdentifier: string;

	set bucketIdentifier(bucket: string) {

		if (bucket === this._bucketIdentifier) {
			return;
		}

		this.schemaFields = new Map();
		this._bucketIdentifier = bucket;
	}

	reset() {
		this.schemas = new Map();
		this.schemaFields = new Map();
		this.collectionDefinitions = new Map();
		this.externalDataSources = new Map();
		this.integrations = new Map();
		this.hierarchyUnits = new Map();
		delete this.formsInfo;
		delete this.userClaimConfig;
		delete this.roles;
	}

	getSchema(bucket?: string | null): Promise<Schema | null> {

		return new Promise((resolve) => {

			if (!bucket) {
				resolve(null);

				return;
			}

			const stored = this.schemas.get(bucket);

			if (stored !== undefined) {
				resolve(stored);

				return;
			}

			try {
				const loaded = this.ucFormBucketClient.get(bucket).catch(() => null);

				this.schemas.set(bucket, loaded);
			} catch (e) {
				console.warn('FormEditorCache schema not found for bucket', bucket, e);
				this.schemas.set(bucket, Promise.resolve(null));
			} finally {
				resolve(this.schemas.get(bucket) ?? null);
			}

			resolve(null);
		});
	}

	getSchemaFieldLookup(path: string[]): Promise<SchemaField | undefined> {

		if (!this._bucketIdentifier) {
			console.warn('FormEditorCache no bucket identifier set');

			return Promise.resolve(undefined);
		}

		return new Promise((resolve) => {

			const stored = this.schemaFields.get(path);

			if (stored !== undefined) {
				resolve(stored);

				return;
			}

			try {
				const loaded = this.getSchema(this._bucketIdentifier).then((schema) => schema ? FormEditorFunctions.schemaFieldLookup(path, schema.fields) : undefined);

				this.schemaFields.set(path, loaded);
			} catch (e) {
				console.warn('FormEditorCache schemaField not found for path', path, e);
				this.schemaFields.set(path, Promise.resolve(undefined));
			} finally {
				resolve(this.schemaFields.get(path));
			}

			resolve(undefined);
		});

	}

	getCollectionDefinition(identifier: string): Promise<UcDefinition | null> {

		return new Promise((resolve) => {

			if (!identifier) {
				resolve(null);

				return;
			}

			const stored = this.collectionDefinitions.get(identifier);

			if (stored !== undefined) {
				resolve(stored);

				return;
			}

			try {
				const loaded = this.ucProject.collection(identifier).getDefinition().catch(() => null);

				this.collectionDefinitions.set(identifier, loaded);
			} catch (e) {
				console.warn('FormEditorCache collection definition not found for', identifier, e);
				this.collectionDefinitions.set(identifier, Promise.resolve(null));
			} finally {
				resolve(this.collectionDefinitions.get(identifier) ?? null);
			}

			resolve(null);
		});
	}

	getExternalDataSource(identifier: string): Promise<ConsoleDataSource | null> {

		return new Promise((resolve) => {

			if (!identifier) {
				resolve(null);

				return;
			}

			const stored = this.externalDataSources.get(identifier);

			if (stored !== undefined) {
				resolve(stored);

				return;
			}

			try {
				const loaded = this.ucDataSources.get(identifier).catch(() => null);

				this.externalDataSources.set(identifier, loaded);
			} catch (e) {
				console.warn('FormEditorCache dataSource not found for', identifier, e);
				this.externalDataSources.set(identifier, Promise.resolve(null));
			} finally {
				resolve(this.externalDataSources.get(identifier) ?? null);
			}

			resolve(null);
		});
	}

	getIntegration(id: string): Promise<Integration | null> {

		return new Promise((resolve) => {

			if (!id) {
				resolve(null);

				return;
			}

			const stored = this.integrations.get(id);

			if (stored !== undefined) {
				resolve(stored);

				return;
			}

			try {
				const loaded = this.ucIntegrations.get(id).catch(() => null);

				this.integrations.set(id, loaded);
			} catch (e) {
				console.warn('FormEditorCache integration not found for', id, e);
				this.integrations.set(id, Promise.resolve(null));
			} finally {
				resolve(this.integrations.get(id) ?? null);
			}

			resolve(null);
		});
	}

	getHierarchyUnit(id: string): Promise<HierarchyUnitExtended | undefined> {

		return new Promise((resolve) => {

			if (!id) {
				resolve(undefined);

				return;
			}

			const stored = this.hierarchyUnits.get(id);

			if (stored !== undefined) {
				resolve(stored);

				return;
			}

			try {
				const loaded = this.hierarchyUnitProvider.getUnit(id).catch(() => undefined);

				this.hierarchyUnits.set(id, loaded);
			} catch (e) {
				console.warn('FormEditorCache hierarchy unit not found for', id, e);
				this.hierarchyUnits.set(id, Promise.resolve(undefined));
			} finally {
				resolve(this.hierarchyUnits.get(id) ?? undefined);
			}

			resolve(undefined);
		});
	}

	listUserClaimConfig(): Promise<UcClaimConfig[]> {

		if (this.userClaimConfig == null) {
			this.userClaimConfig = this.userClaimsClient.list({ params: { limit: 1000 } });
		}

		return this.userClaimConfig;
	}

	getRoles(): Promise<Role[]> {

		if (!this.roles) {
			this.roles = this.ucRoles.get(undefined, undefined, { params: { limit: 1000 } });
		}

		return this.roles;
	}

	getFormsInfo(): Promise<DefinitionInfo[]> {

		if (this.formsInfo) {
			return this.formsInfo;
		}

		this.formsInfo = this.ucProject.getForms({ params: { limit: 1000 } });

		return this.formsInfo;
	}

	async getUserClaimConfig(id: string): Promise<UcClaimConfig | null> {
		const claims = await this.listUserClaimConfig();

		return claims.find((c) => c.id === id) ?? null;
	}

}
