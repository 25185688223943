import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { Device, Dictionary } from '@unifii/sdk';

import { UcApps } from 'client';

export interface SelectDevicesData {
	appId: string;
	selected?: Device[];
}

@Component({
	selector: 'uc-select-devices',
	templateUrl: './select-devices.html',
	styleUrls: ['./select-devices.less'],
	standalone: false,
})
export class SelectDevicesComponent implements Modal<SelectDevicesData, Device[]>, OnInit {

	@HostBinding('class.uc-form-card') classes = true;

	runtime = inject<ModalRuntime<SelectDevicesData, Device[]>>(ModalRuntime);
	data = inject<SelectDevicesData>(ModalData);

	protected filteredDevices: Device[] = [];
	protected selectedMap: Dictionary<boolean> = {};

	private ucApps = inject(UcApps);

	ngOnInit() {
		void this.filter();
	}

	close() {
		this.runtime.close();
	}

	protected async filter(q?: string | null) {
		const devices = await this.ucApps.getDevices(this.data.appId, { params: { q } });

		this.filteredDevices = this.filterSelected(devices, this.data.selected ?? []);
	}

	protected select(id: string) {

		if (this.selectedMap[id]) {
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete this.selectedMap[id];

			return;
		}

		this.selectedMap[id] = true;
	}

	protected save() {
		const selected = this.filteredDevices.filter((device) => this.selectedMap[device.id]);

		this.runtime.close(selected);
	}

	private filterSelected(devices: Device[], selected: Device[]): Device[] {

		const selectedIds = selected.map((d) => d.id);

		return devices.filter((d) => !selectedIds.includes(d.id));
	}

}
