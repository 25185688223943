"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLocalTimeZone = exports.safeParseISO = exports.parseFallbackISO = exports.isValidDateFormat = void 0;
const date_fns_1 = require("date-fns");
/**
 * Verify if the date/time/date-time/etc value match the provided format
 * @param dateValue
 * @param formatString
 * @returns validation result
 */
const isValidDateFormat = (dateValue, formatString) => {
  try {
    if (!dateValue) {
      return false;
    }
    const parsedDate = (0, date_fns_1.parse)(dateValue, formatString, new Date());
    const isValidDate = (0, date_fns_1.isValid)(parsedDate);
    if (!isValidDate) {
      return false;
    }
    // `dateValue` has TZ and `formatString` has TZ
    // standard logic of compare parse=>format will fails here cause `parsedDate` is in local TZ, unlikely matching the `formatString` TZ
    // TODO skip the parse=>format compare expose risk to validate a `formatString` against a non-strict format `dataValue`
    if (/[+-]\d\d:\d\d+$/.test(dateValue) && formatString.endsWith('xxx')) {
      return isValidDate;
    }
    // Convert back to string format to exclude falsy result of non strict `parse` function
    const formattedDate = (0, date_fns_1.format)(parsedDate, formatString);
    return formattedDate === dateValue;
  } catch {
    return false;
  }
};
exports.isValidDateFormat = isValidDateFormat;
/**
 * Parse the value with the expected formatString
 * If value result as an invalid format, fallback to ISO format
 * Needed fallback to accept ISO format in any Time, Date or DateTime value
 * See https://unifii.atlassian.net/browse/UNIFII-3131
 */
const parseFallbackISO = (value, formatString) => {
  const date = formatString ? (0, date_fns_1.parse)(value, formatString, new Date()) : (0, date_fns_1.parseISO)(value);
  if ((0, date_fns_1.isValid)(date)) {
    return date;
  }
  return (0, exports.safeParseISO)(value);
};
exports.parseFallbackISO = parseFallbackISO;
/** Parse the value with ISO format and return the Date or undefined if the value is an invalid date */
const safeParseISO = value => {
  const date = (0, date_fns_1.parseISO)(value);
  if ((0, date_fns_1.isValid)(date)) {
    return date;
  }
  return undefined;
};
exports.safeParseISO = safeParseISO;
const getLocalTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
exports.getLocalTimeZone = getLocalTimeZone;
