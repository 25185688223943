import { AfterViewInit, Component } from '@angular/core';

import { ImageCrop } from '../image-cropper/imageCropper';

import { ContentList, ModalSearchType } from './content-list';
import { ImageCropComponent, ImageCropData, MediaSearchComponent } from './modals';
import { LinkSearchConfig, LinkSearchType } from './modals/link-search.component';

@Component({
	selector: 'uc-image-list',
	templateUrl: './image-list.html',
	styleUrls: ['./group-input.less'],
	providers: [{ provide: ModalSearchType, useValue: MediaSearchComponent }],
	standalone: false,
})
export class ImageListComponent extends ContentList implements AfterViewInit {

	protected get canCrop() {
		return !!this.canEditField && (this.configuredField.minHeight ?? 0) > 0 && (this.configuredField.minWidth ?? 0) > 0;
	}

	protected async crop(item: any, i: number) {

		if (!this.canCrop) {
			void this.modalService.openAlert({
				title: 'Error',
				message: 'Image needs a minimum height and width to be cropped.',
			});

			return;
		}

		const options = {
			minWidth: this.configuredField.minWidth,
			minHeight: this.configuredField.minHeight,
		};

		const data = await this.modalService.openLarge<ImageCropData, ImageCrop>(
			ImageCropComponent,
			{ image: Object.assign({}, item), options },
		);

		(this.content as any[])[i].crop = data;

		if (this.editorField) {
			this.builderService.fieldEdit.next({ subject: this.editorField });
		}
	}

	protected getSearchConfig(): LinkSearchConfig {
		return {
			type: LinkSearchType.Image,
			title: 'Add Image(s)',
			multiSelect: true,
		};
	}

}
