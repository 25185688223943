import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { Config } from 'app-config';
import { SystemRole } from 'client';
import { ContextService } from 'services/context.service';
import { ProjectService } from 'services/project.service';

import { MenuConfig } from './nav-models';

@Component({
	selector: 'uc-project-nav',
	templateUrl: './project-nav.html',
	styleUrls: ['./project-nav.less'],
	standalone: false,
})
export class ProjectNavComponent implements OnInit, OnDestroy {

	protected menuConfigs: MenuConfig[] = [];
	protected context = inject(ContextService);
	protected config = inject(Config);
	protected projectId: string | undefined;

	private subscriptions = new Subscription();
	private projectService = inject(ProjectService);

	ngOnInit() {
		this.updateMenu();
		this.subscriptions.add(this.projectService.projectChange.subscribe(() => this.updateMenu()));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private updateMenu() {
		this.projectId = this.context.project?.id;

		if (!this.projectId) {
			this.menuConfigs = [];

			return;
		}

		this.menuConfigs = [{
			restrict: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.Publisher].join(','),
			label: 'Structure',
			icon: 'structure',
			link: ['/projects', this.projectId, 'structure'],
		}, {
			restrict: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.FormDesigner, SystemRole.Publisher].join(','),
			label: 'Content',
			icon: 'content',
			link: ['/projects', this.projectId, 'content'],
		}, {
			restrict: [SystemRole.ProjectManager, SystemRole.FormDesigner].join(','),
			label: 'Tables',
			icon: 'table',
			link: ['/projects', this.projectId, 'tables'],
		}, {
			restrict: [SystemRole.ProjectManager, SystemRole.FormDesigner, SystemRole.Publisher].join(','),
			label: 'Forms',
			icon: 'forms',
			link: ['/projects', this.projectId, 'forms'],
		}, {
			restrict: [SystemRole.ProjectManager, SystemRole.WorkflowDesigner].join(','),
			label: 'Workflows',
			icon: 'workflows',
			link: ['/projects', this.projectId, 'workflows'],
		}, {
			restrict: SystemRole.FormDataViewer,
			label: 'Form Data',
			icon: 'form-data-repo',
			link: ['/projects', this.projectId, 'form-data'],
		}, {
			restrict: SystemRole.ProjectManager,
			label: 'Settings',
			icon: 'project-settings',
			link: ['/projects', this.projectId, 'settings'],
		}, {
			restrict: [SystemRole.ProjectManager, SystemRole.ContentEditor, SystemRole.FormDesigner, SystemRole.Publisher].join(','),
			label: 'Publish',
			icon: 'publish',
			link: ['/projects', this.projectId, 'publish'],
		}];
	}

}
