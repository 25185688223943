import { Injectable, inject } from '@angular/core';
import { DefinitionPublishState, UserReference, ensureError } from '@unifii/sdk';
import { ReplaySubject } from 'rxjs';

import { ConsoleInfo, PublishStatus, UcProject, VersionInfo } from 'client';

/**
 * Pending info contains only version and preview
 */
export type PendingVersionInfo = Pick<VersionInfo, 'version' | 'preview'>;

/**
 * Real time information on publish events
 */
export interface PublishInfo extends PublishStatus {
	pending?: PendingVersionInfo;
	failure?: {
		version?: PendingVersionInfo;
		error: Error;
	};
}

export enum PublishableType {
	Collection = 'Collection',
	CollectionItem = 'CollectionItem',
	View = 'View',
	ViewDefinition = 'ViewDefinition',
	Form = 'Form',
	Page = 'Page',
	Table = 'Table',
	FormBucket = 'FormBucket',
	Structure = 'Structure',
}

export interface PublishItem extends ConsoleInfo {
	id: string;
	identifier?: string; // for Collection and CollectionItem
	publishableType: PublishableType;
	publishState: DefinitionPublishState;
	lastModifiedAt: string;
	lastModifiedBy?: UserReference;
}

const PublishStatusError = 'An error occurred while retrieving publish status';
const PreviewPublishError = 'An error occurred while publishing preview';
const StablePublishError = 'An error occurred while publishing stable';

@Injectable()
export class ProjectPublisher {

	event = new ReplaySubject<PublishInfo>(1);

	private _status: PublishStatus = {};
	private project = inject(UcProject);

	get status(): PublishInfo {
		return this._status;
	}

	/**
     * Update the Publish status and items
     * @param discardCurrentStatus - clear current pending and error infos
    */
	async update(discardCurrentStatus?: boolean) {
		/**
         * Merge latest published state with any errors or pending publishes
         */
		try {
			const status = await this.project.getPublishStatus();
			const items = await this.project.getPublishItems();

			this._status = Object.assign(
				discardCurrentStatus ? {} : this._status,
				status,
				{ items },
			);

			this.event.next(this.status);
		} catch (error) {
			this._status = {};
			this.failure(new Error(this.getErrorMessage(error, PublishStatusError)));
		}
	}

	async publish(preview?: boolean, items?: PublishItem[]) {

		this.start(preview);

		try {
			if (preview) {
				await this.project.publishPreview(items);
			} else {
				await this.project.publishStable(items);
			}

			void this.update(true);

		} catch (error) {
			const message = preview ? PreviewPublishError : StablePublishError;

			this.failure(new Error(this.getErrorMessage(error, message)));
			// We're re-throwing the error to POST it to Sentry
			throw error;
		}

	}

	getNextVersion(preview?: boolean): PendingVersionInfo {

		const versionInfo: PendingVersionInfo = { version: 0 };

		if (!preview) {
			Object.assign(versionInfo, this.status.stable);

			return { version: versionInfo.version + 1 };
		}

		Object.assign(versionInfo, { preview: 0 }, this.status.preview ?? {});

		if (versionInfo.preview) {
			return { version: versionInfo.version, preview: versionInfo.preview + 1 };
		}

		return { version: versionInfo.version + 1, preview: 1 };
	}

	private failure(error: Error) {
		this.status.failure = { error };

		if (this.status.pending) {
			this.status.failure.version = this.status.pending;
		}

		delete this.status.pending;

		this.event.next(this.status);
	}

	private start(preview?: boolean) {
		this.status.pending = this.getNextVersion(preview);
		delete this.status.failure;
		this.event.next(this.status);
	}

	private getErrorMessage(error: any, fallback: string): string {
		return ensureError(error, fallback).message;
	}

}
