@if (systemRolesControl?.enabled || superUserControl?.enabled) {
	<div [ngClass]="cssClasses" class="uf-box">
		<div class="uf-app-bar flat accent">
			<div class="title">System Roles</div>
		</div>
		<div class="uc-group-content uf-grid pad">
			@if (superUserControl && superUserControl.enabled) {
				<uf-checkbox [control]="superUserControl" (valueChange)="toggleSuperUser($event)" label="SuperUser" class="col-12" />
			}
			@if (systemRolesControl && systemRoleOptions.length) {
				<uf-multi-choice [control]="systemRolesControl" [options]="systemRoleOptions" label="System Roles" columns="4" class="col-12" />
			}
		</div>
	</div>
}
