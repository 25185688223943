<div class="header">
	<button (click)="runtime.close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>New API Key</h3>
</div>

<uf-panel class="container">
	<div [formGroup]="form" class="uf-grid pad-sides">
		<uf-text [formControlName]="controlKeys.ConsoleName" [label]="consoleNameLabel" class="col-12" />
		<uf-checkbox [formControlName]="controlKeys.EnableOAuth" label="Enable for OAuth 2 Client Credentials Flow" class="col-12" />
	</div>
</uf-panel>

<div class="row space-children pad">
	<button (click)="runtime.close()" type="button" class="uf-button tertiary right">Cancel</button>
	<button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>
