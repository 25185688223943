import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UfControl, ValidatorFunctions } from '@unifii/library/common';
import { Subscription } from 'rxjs';

@Component({
	selector: 'uc-color',
	templateUrl: './uc-color.html',
	styleUrls: ['./uc-color.less'],
	standalone: false,
})
export class UcColorComponent implements OnInit, OnChanges, OnDestroy {

	@Input() control = new UfControl();
	@Input() label: string;
	@Input() placeholder: string;
	@Output() valueChange = new EventEmitter();

	focused: boolean;
	supported: boolean;

	private _disabled: boolean;
	private subscriptions = new Subscription();

	ngOnInit() {

		// Append hex format validator
		const hexValidator = ValidatorFunctions.pattern(/#[0-9A-Fa-f]{6}\b/, 'Use #rrggbb format');

		if (this.control.validator) {
			this.control.setValidators([this.control.validator, hexValidator]);
		} else {
			this.control.setValidators([hexValidator]);
		}

		this.subscriptions.add(this.control.valueChanges.subscribe((v) => {
			this.valueChange.emit(v);
		}));

		// Check color type availability
		const input = document.createElement('input');

		input.setAttribute('type', 'color');
		this.supported = input.type !== 'text';
		input.remove();
	}

	ngOnChanges(changes: SimpleChanges) {

		if (changes.value) {
			if (changes.value.currentValue !== this.control.value) {
				this.control.setValue(changes.value.currentValue, { emitEvent: false });
			}
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	@Input() set disabled(v: boolean) {
		this._disabled = v;
	}

	get disabled(): boolean {
		return this._disabled || this.control.disabled;
	}

	@Input() set value(_v) {
		// Logic moved inside ngOnChanges
	}

	get value() {
		return this.control.value;
	}

	digitColor(v: string) {
		this.control.setSubmitted(true);
		this.control.setValue(v);
	}

	delete() {
		this.control.setValue(null);
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

}
