import { Injectable, inject } from '@angular/core';
import { DateTimeFunctions } from '@unifii/library/common';
import { ClientDeleteOptions, ClientGetOptions, Device, ErrorType, UfRequestError, UserAuthProvider, UserInfo, appendParamsToUrl, mergeParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { PermissionPrincipalType, UcUserInfo } from './models';
import { UcPermissionsClient } from './permissions-client';
import { UcClient } from './uc-client';

export interface UserQueryParams {
	isActive?: boolean;
	hasLastActivationReason?: boolean;
	isExternal?: boolean;
	roles?: string | string[];
	systemRoles?: string | string[];
	authProvider?: string;
}

export interface UcUserAuthProvider extends UserAuthProvider {
	id: string;
	lockedSystemRoles: string[];
}

@Injectable({ providedIn: 'root' })
export class UcUsers {

	private ucClient = inject(UcClient);

	get(q?: string, sort?: string, params?: UserQueryParams, options?: ClientGetOptions): Promise<UserInfo[]> {

		const mergedParams = mergeParams(
			DefaultPaginationParams,
			options?.params,
			params as Record<string, unknown>,
			{ q, sort },
		);

		return this.ucClient.get(this.url(), { ...options, params: mergedParams }) as Promise<UserInfo[]>;
	}

	getDetails(id: string, options?: ClientGetOptions): Promise<UserInfo> {
		return this.ucClient.get(this.url(id), options) as Promise<UserInfo>;
	}

	getByUsername(username: string, options?: ClientGetOptions): Promise<UserInfo> {
		return this.ucClient.get(this.url('username', username), options) as Promise<UserInfo>;
	}

	/** Head email to check its existence in the entity system */
	async checkEmail(email: string, options?: ClientGetOptions): Promise<boolean> {
		try {
			await this.ucClient.head(this.url('emails', email), options);

			return true;
		} catch (e) {
			if ((e as UfRequestError).type !== ErrorType.NotFound) {
				throw e;
			}
		}

		return false;
	}

	getDevices(id: string, options?: ClientGetOptions): Promise<Device[]> {
		const params = mergeParams(DefaultPaginationParams, options?.params);

		return this.ucClient.get(this.url(id, 'devices'), { ...options, params }) as Promise<Device[]>;
	}

	getAuthProviders(id: string, options?: ClientGetOptions): Promise<UcUserAuthProvider[]> {
		return this.ucClient.get(this.url(id, 'auth-providers'), options) as Promise<UcUserAuthProvider[]>;
	}

	save(user: UserInfo): Promise<UcUserInfo> {
		if (user.id) {
			return this.ucClient.put(this.url(user.id), user) as Promise<UcUserInfo>;
		}

		return this.ucClient.post(this.url(), { body: user }) as Promise<UcUserInfo>;
	}

	delete(id: string, options?: ClientDeleteOptions): Promise<void> {
		return this.ucClient.delete(this.url(id), options) as Promise<void>;
	}

	permissions(id: string): UcPermissionsClient {
		return new UcPermissionsClient(this.ucClient, PermissionPrincipalType.User, id);
	}

	getDownloadUrl(q?: string, sort?: string, params?: UserQueryParams, offset = 0, limit = 10000) {

		params = { ...params };
		params.roles = this.toString(params.roles);
		params.systemRoles = this.toString(params.systemRoles);

		const mergedParams = mergeParams(params as Record<string, unknown>, { q, sort, _format: 'csv', offset, limit });

		return appendParamsToUrl(this.url(), mergedParams);
	}

	async hasConflict(info: Pick<UserInfo, 'id' | 'lastModifiedAt'>): Promise<boolean> {
		if (!info.id || !info.lastModifiedAt) {
			return false;
		}
		const lastModifiedAt = await this.getUserLastModifiedAt(info.id);

		if (!lastModifiedAt) {
			return false;
		}

		// userInfo lastModified is sent as date with no removing of trailing zeros, but header is sent as string which removed trailing zeros.
		return DateTimeFunctions.safeParseISO(lastModifiedAt)?.getTime() !== DateTimeFunctions.safeParseISO(info.lastModifiedAt)?.getTime();
	}

	private getUserLastModifiedAt(id: string): Promise<string | undefined> {
		return this.ucClient.getRevisionHeader(this.url(id));
	}

	private url(...extra: string[]): string {
		const urlParts = ['users', ...extra];

		return this.ucClient.buildUrl(urlParts);
	}

	private toString(param?: string | string[]): string | undefined {
		if (Array.isArray(param)) {
			return param.join(', ');
		}

		return param;
	}

}
