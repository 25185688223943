import { DataSourceLoader, SourceConfig } from '@unifii/library/common';
import { DataSeed } from '@unifii/sdk';

import { DefinitionInfo, UcProject } from 'client';

export class FormLoader implements DataSourceLoader {

	// TODO: Remove this after UNIFII-7371 - as this is not the ideal solution
	sourceConfig: SourceConfig = {} as unknown as SourceConfig;

	constructor(private ucProject: UcProject) { }

	async search(q?: string): Promise<DataSeed[]> {
		return (await this.ucProject.getForms({ params: { q } })).map(this.mapToSeed);
	}

	findAllBy(match: string): Promise<DataSeed[]> {
		console.warn('FormLoader datasource does not does not support findByAll, falling back on search');

		return this.search(match);
	}

	getOptions(): Promise<any> {
		throw new Error('FormLoader.getOptions - method not implemented');
	}

	async get(id: string): Promise<DataSeed> {
		const { id: formId, label } = await this.ucProject.getForm(id);

		return { _id: `${formId}`, _display: label };
	}

	mapToSeed = ({ id, name }: DefinitionInfo): DataSeed => ({ _id: id, _display: name });

}
