import { Injectable, inject } from '@angular/core';
import { APIPath, ClientDeleteOptions, ClientGetOptions, amendOptionsParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { UcClaimConfig } from './models';
import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class UcUserClaims {

	private client = inject(UcClient);

	/** q, sort, offset = 0, limit = 100, analytics?: RequestAnalytics */
	list(options?: ClientGetOptions): Promise<UcClaimConfig[]> {
		return this.client.get(this.url(), amendOptionsParams(DefaultPaginationParams, options));
	}

	get(id: string, options?: ClientGetOptions): Promise<UcClaimConfig> {
		return this.client.get(this.url(id), options);
	}

	add(claim: UcClaimConfig): Promise<UcClaimConfig> {
		return this.client.post(this.url(), { body: claim });
	}

	update(claim: UcClaimConfig): Promise<UcClaimConfig> {
		return this.client.put(this.url(claim.id), claim);
	}

	delete(id: string, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url(id), options);
	}

	private url(...extra: string[]): string {
		const urlParts = [APIPath.UserClaims, ...extra];

		return this.client.buildUrl(urlParts);
	}

}
