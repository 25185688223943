import { Injectable, inject } from '@angular/core';
import { Compound, ContentClient, PublishedContent, Query } from '@unifii/sdk';

import { UcContentClient } from 'services/console-content.service';

/**
 * Implementation of PublishedContent for FormPreview.
 * Extends UcContentClient with overrides necessary to reflect the SmartForm access to published data (v0)
 */
@Injectable()
export class FormPreviewContentClient extends UcContentClient implements PublishedContent {

	private contentClient = inject(ContentClient);

	/** Collection DataSource need access to the published Collections' items */
	override queryCollection(identifier: string, query?: Query): Promise<Compound[]> {

		return this.contentClient.queryCollection(identifier, query);

	}

}

