import { Injectable, OnDestroy, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isStringNotEmpty } from '@unifii/sdk';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Config } from 'app-config';

import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class TitleService implements OnDestroy {

	private subscriptions = new Subscription();
	private context = inject(ContextService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private title = inject(Title);
	private config = inject(Config);

	private pageTitle: string;

	init() {
		this.subscriptions.add(this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
		).subscribe(() => this.updateName()));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	updateTitle(title?: string, removePageTitle?: boolean): void {
		if (isStringNotEmpty(title)) {
			const updatedTitle = removePageTitle ? title : `${this.pageTitle} | ${title}`;

			this.title.setTitle(updatedTitle);
		}
	}

	private updateName(): void {
		let title = '';
		let child = this.route.firstChild;
		let result = this.context.tenantSettings?.name ?? this.config.title;

		while (child) {

			if (child.snapshot.data.ignoreTitle) {
				return;
			}
			// Store title
			if (child.snapshot.data.title) {
				title = child.snapshot.data.title;
			}
			// Look for deeper route title (more specific)
			if (child.firstChild) {
				child = child.firstChild;
			} else {
				child = null;
			}
		}

		if (title) {
			result = title;
		}

		this.pageTitle = result;
		this.title.setTitle(result);
	}

}
