@if (actions) {
	@for (action of actions.controls | asUfControlsGroups; track action; let i = $index) {
		<div>
			@if (action) {
				<div [formGroup]="action" class="uf-box flat">
					<uf-expander [dragDisabled]="true" [isExpanded]="true" dragItem>
						<div expanderHeader class="uf-app-bar flat">
							<div class="title">Assign {{ mapActionTypeDescription(action.get(controlKeys.Type)?.value) }}</div>
							<button (click)="deleteAction(i)" type="button" title="Delete" class="uf-action">
								<uf-icon name="delete" />
							</button>
						</div>
						<div expanderBody class="uf-grid pad">
							@switch (action.get(controlKeys.Type)?.value) {
								@case (types.AssignClaim) {
									<uf-autocomplete [formControlName]="controlKeys.Claim" [options]="filteredSourceClaims" [allowCustom]="true" (searchChange)="findClaims($event)" (valueChange)="changeClaimValue(action, $event)" label="Claim" placeholder="Select a claim" valueProperty="type" nameProperty="type" class="col-6" />
									@if ($any(action.get(controlKeys.Claim))?.value) {
										<uf-claim-field [control]="$any(action.get(controlKeys.Value))" [claimConfig]="action.get(controlKeys.Claim)?.value" class="col-6" />
									}
								}
								@case (types.AssignClaimFrom) {
									<uf-autocomplete [formControlName]="controlKeys.ClaimTo" [options]="filteredSourceClaims" (searchChange)="findClaims($event)" (valueChange)="claimToValueChange(i, $event)" label="Claim" nameProperty="type" placeholder="Select a claim" class="col-6 gap-botton" />
									<uf-autocomplete [formControlName]="controlKeys.Claim" [options]="filteredSourceClaims" [allowCustom]="true" (searchChange)="findSourceClaims($event)" (valueChange)="claimFromValueChange(i, $event)" label="From Provider Claim" nameProperty="type" placeholder="Select a claim" class="col-6" />
								}
								@case (types.AssignUnit) {
									<uf-hierarchy-multi-select [formControlName]="controlKeys.Units" label="Hierarchy Unit" class="col-12" />
								}
								@case (types.AssignSystemRole) {
									<uf-chips [formControlName]="controlKeys.Roles" [options]="filteredSystemRoles" (searchChange)="findSystemRoles($event)" placeholder="Select a System Role" label="System Role" class="col-12" />
								}
								@case (types.AssignRole) {
									<uf-role-input [formControlName]="controlKeys.Roles" class="col-12" />
								}
							}
						</div>
					</uf-expander>
				</div>
			}
		</div>
	}
	<div class="uf-form-actions">
		<button [options]="actionOptions" (optionSelected)="addAction($event)" type="button" class="uf-button x-small" listBox nameProperty="name">
			Add
			<uf-icon name="more" class="gap-none" />
		</button>
	</div>
}
