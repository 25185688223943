import { inject } from '@angular/core';
import { FilterEntry, FilterType } from '@unifii/library/common';

import { ActivityType, UcFormBucketClient, UcIntegrations, UcProject, WorkflowEventType, WorkflowState } from 'client';

import { BucketFilterLoader } from './bucket-filter-loader';
import { WorkflowActivityLabel, WorkflowEventLabel, WorkflowSourceTypeLabel } from './constants';
import { FormLoader } from './form-loader';
import { IntegrationLoader } from './integration-loader';

export const activityFilterFactory = (): FilterEntry[] => {
	const ucFormBucketClient = inject(UcFormBucketClient);

	return [
		{
			identifier: 'bucket',
			label: 'Form Data Repository',
			type: FilterType.DataSeed,
			loader: new BucketFilterLoader(ucFormBucketClient),
		},
	];
};

export const formDataFilterFactory = (): FilterEntry[] => {
	const ucProject = inject(UcProject);
	const ucFormBucketClient = inject(UcFormBucketClient);

	return [
		{
			identifier: 'sourceType',
			label: 'Source Type',
			type: FilterType.Choice,
			options: [
				{ identifier: WorkflowEventType.FormSubmitted, name: WorkflowSourceTypeLabel[WorkflowEventType.FormSubmitted] },
				// TODO - Add Support for User & Integration Source
				// { identifier: WorkflowEventType.ApiEvent, name: WorkflowSourceTypeLabel[WorkflowEventType.ApiEvent] },
				// { identifier: WorkflowEventType.RoleAdded, name: WorkflowSourceTypeLabel[WorkflowEventType.RoleAdded] },
			],
		},
		{
			identifier: 'bucket',
			label: 'Form Data Repository',
			type: FilterType.DataSeed,
			loader: new BucketFilterLoader(ucFormBucketClient),
		},
		{
			identifier: 'targetFormId',
			label: 'Target Form',
			type: FilterType.DataSeed,
			loader: new FormLoader(ucProject),
		},
	];
};

export const dataForwarderFilterFactory = (): FilterEntry[] => {
	const ucIntegration = inject(UcIntegrations);
	const ucFormBucketClient = inject(UcFormBucketClient);

	return [
		{
			identifier: 'sourceType',
			label: 'Source Type',
			type: FilterType.Choice,
			options: [
				{ identifier: WorkflowEventType.FormSubmitted, name: WorkflowSourceTypeLabel[WorkflowEventType.FormSubmitted] },
				{ identifier: WorkflowEventType.ApiEvent, name: WorkflowSourceTypeLabel[WorkflowEventType.ApiEvent] },
				{ identifier: WorkflowEventType.RoleAdded, name: WorkflowSourceTypeLabel[WorkflowEventType.RoleAdded] },
			],
		},
		{
			identifier: 'bucket',
			label: 'Form Data Repository',
			type: FilterType.DataSeed,
			loader: new BucketFilterLoader(ucFormBucketClient),
		},
		{
			identifier: 'integrationId',
			label: 'Source Integration',
			type: FilterType.DataSeed,
			loader: new IntegrationLoader(ucIntegration),
		},
	];
};

export const workflowRuleFilterFactory = (): FilterEntry[] => {
	const ucFormBucketClient = inject(UcFormBucketClient);

	return [
		{
			identifier: 'eventType',
			label: 'Event',
			type: FilterType.Choice,
			options: [
				{ identifier: WorkflowEventType.FormSubmitted, name: WorkflowEventLabel[WorkflowEventType.FormSubmitted] },
				{ identifier: WorkflowEventType.Timer, name: WorkflowEventLabel[WorkflowEventType.Timer] },
				{ identifier: WorkflowEventType.ApiEvent, name: WorkflowEventLabel[WorkflowEventType.ApiEvent] },
				{ identifier: WorkflowEventType.RoleAdded, name: WorkflowEventLabel[WorkflowEventType.RoleAdded] },
			],
		},
		{
			identifier: 'state',
			label: 'State',
			type: FilterType.Choice,
			options: [
				{ identifier: WorkflowState.Active, name: 'Active' },
				{ identifier: WorkflowState.Inactive, name: 'Inactive' },
			],
		}, {
			identifier: 'activityType',
			label: 'Activity',
			type: FilterType.Choice,
			options: [
				{ identifier: ActivityType.DataForwarder, name: WorkflowActivityLabel[ActivityType.DataForwarder] },
				{ identifier: ActivityType.Notification, name: WorkflowActivityLabel[ActivityType.Notification] },
				{ identifier: ActivityType.Timer, name: WorkflowActivityLabel[ActivityType.Timer] },
				{ identifier: ActivityType.FormData, name: WorkflowActivityLabel[ActivityType.FormData] },
			],
		}, {
			identifier: 'bucket',
			label: 'Form Data Repository',
			type: FilterType.DataSeed,
			loader: new BucketFilterLoader(ucFormBucketClient),
		},
	];
};
