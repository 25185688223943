import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlGroup } from '@unifii/library/common';
import { Schema, Transition } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { DefinitionInfo } from 'client';
import { ArrayHelper } from 'helpers/helpers';
import { sortGroupControlValue } from 'pages/utils';

import { FormEditorCache } from '../form-editor-cache';
import { TransitionControlKeys } from '../form-editor-control-keys';
import { FormEditorStatus } from '../form-editor-status';
import { FormEditorService } from '../form-editor.service';

export interface FormFieldTransitionEditorData {
	bucket: string;
	transition: UfControlGroup;
	roles: Set<string>;
}

@Component({
	selector: 'uc-form-field-transition-editor',
	templateUrl: './form-field-transition-editor.html',
	styleUrls: ['./form-field-transition-editor.less'],
	standalone: false,
})
export class FormFieldTransitionEditorComponent implements Modal<FormFieldTransitionEditorData, UfControlGroup>, OnInit, OnDestroy {

	@HostBinding('class.uc-form-card') classes = true;

	runtime = inject<ModalRuntime<FormFieldTransitionEditorData, UfControlGroup>>(ModalRuntime);
	data = inject<FormFieldTransitionEditorData>(ModalData);
	edited = false;

	protected readonly transitionKeys = TransitionControlKeys;
	protected ready = false;
	protected control: UfControlGroup;
	protected descriptionControl: UfControl;
	protected formDefinitionInfos: DefinitionInfo[];
	protected schema: Schema | null;
	protected startStatusesResult: string[] = [];
	protected endStatusesResult: string[] = [];
	protected rolesResult: string[] = [];
	protected tagsResult: string[] = [];

	private cache = inject(FormEditorCache);
	private service = inject(FormEditorService);
	private status = inject(FormEditorStatus);
	private isNew = false;
	private bucket: string | undefined;
	private roles: Set<string>;
	private subscriptions = new Subscription();

	protected get label(): string {
		if (this.isNew) {
			return 'New Workflow';
		}

		return `${this.transition.source ?? ''} > ${this.transition.target ?? ''} [${this.transition.actionLabel ?? ''}] ${this.edited ? '*' : ''}`;
	}

	private get transition(): Transition {
		return this.control.getRawValue() as Transition;
	}

	async ngOnInit() {
		this.control = this.data.transition;
		this.descriptionControl = this.control.get(TransitionControlKeys.Description) as UfControl;
		this.bucket = this.data.bucket;
		this.roles = this.data.roles;
		this.isNew = this.transition.source == null || this.transition.target == null || this.transition.actionLabel == null;
		this.subscriptions.add(this.control.valueChanges.subscribe(() => (this.edited = true)));
		this.subscriptions.add((this.control.get(TransitionControlKeys.Source) as UfControl).valueChanges.subscribe(this.service.refreshTransitionStatuses.bind(this)));
		this.subscriptions.add((this.control.get(TransitionControlKeys.Target) as UfControl).valueChanges.subscribe(this.service.refreshTransitionStatuses.bind(this)));
		this.formDefinitionInfos = await this.cache.getFormsInfo();
		this.schema = await this.cache.getSchema(this.bucket);

		sortGroupControlValue(this.control, TransitionControlKeys.Roles);

		this.ready = true;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	close() {
		this.runtime.close();
	}

	protected save() {

		this.control.setSubmitted();

		if (this.control.invalid) {
			return;
		}

		// Save
		this.edited = false;

		this.runtime.close(this.control);
	}

	protected findStartStatuses(query: string) {
		this.startStatusesResult = ArrayHelper.filterList([...this.status.statuses], query);
	}

	protected findEndStatuses(query: string) {
		this.endStatusesResult = ArrayHelper.filterList([...this.status.statuses], query);
	}

	protected findTags(query: string | null) {
		this.tagsResult = ArrayHelper.filterList([...this.status.tags], query ?? undefined);
	}

	protected findRoles(query: string | null) {
		this.rolesResult = ArrayHelper.filterList([...this.roles], query ?? undefined);
	}

}
