"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.APIPath = void 0;
var APIPath;
(function (APIPath) {
  APIPath["Oauth"] = "oauth";
  APIPath["Devices"] = "devices";
  APIPath["DownloadTokens"] = "download-tokens";
  APIPath["Companies"] = "companies";
  APIPath["ExternalDataSources"] = "external-data-sources";
  APIPath["ExternalDataTransactions"] = "external-data-transactions";
  APIPath["FormData"] = "form-data";
  APIPath["ResourcesCount"] = "_count";
  APIPath["FormDataFiles"] = "_files";
  APIPath["FormDataRevisions"] = "revisions";
  APIPath["HierarchyUnits"] = "units";
  APIPath["HierarchyChildren"] = "children";
  APIPath["Me"] = "me";
  APIPath["MeVirtualMfaCode"] = "virtual-mfa-code";
  APIPath["MeRecoveryCodes"] = "recovery-codes";
  APIPath["MeSmsMfaEnabled"] = "sms-mfa-enabled";
  APIPath["MeSmsChallenges"] = "sms-challenges";
  APIPath["MeSetupDeviceMfa"] = "setup-device-mfa";
  APIPath["MeCompleteDeviceMfaSetup"] = "complete-device-mfa-setup";
  APIPath["MeDeviceMfaChallenge"] = "device-mfa-challenge";
  APIPath["MePermissions"] = "permissions";
  APIPath["Settings"] = "settings";
  APIPath["SettingsAllowedFileTypes"] = "allowed-file-types";
  APIPath["Oidc"] = "oidc";
  APIPath["OidcStates"] = "states";
  APIPath["Assets"] = "assets";
  APIPath["Roles"] = "roles";
  APIPath["UserClaims"] = "user-claims";
  APIPath["Users"] = "users";
  APIPath["UsersBulk"] = "bulk";
  APIPath["UsersBulkInvite"] = "bulk-invite";
  APIPath["UsersCsv"] = "csv";
  APIPath["UsersAuthProviders"] = "auth-providers";
  APIPath["UsersFiles"] = "files";
  APIPath["UsersUsername"] = "username";
  APIPath["ResetPassword"] = "reset-password";
  APIPath["UsersEmails"] = "emails";
  APIPath["Projects"] = "projects";
  APIPath["Preview"] = "preview";
  APIPath["Versions"] = "versions";
  APIPath["Latest"] = "latest";
  APIPath["Artifacts"] = "artifacts";
  APIPath["Diff"] = "diff";
  APIPath["GeoRegions"] = "geo-regions";
  APIPath["BeaconRegions"] = "beacon-regions";
  APIPath["Views"] = "views";
  APIPath["ViewsDefinition"] = "definition";
  APIPath["Pages"] = "pages";
  APIPath["Collections"] = "collections";
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  APIPath["CollectionsDefinition"] = "definition";
  APIPath["Buckets"] = "buckets";
  APIPath["Forms"] = "forms";
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  APIPath["FormsVersions"] = "versions";
  APIPath["Tables"] = "tables";
  APIPath["Structure"] = "structure";
  APIPath["Identifiers"] = "identifiers";
  APIPath["Attachments"] = "attachments";
})(APIPath || (exports.APIPath = APIPath = {}));
