<div class="bg-img"></div>

<uf-panel class="container">
	<uf-progress [inProgress]="inProgress" class="large" />

	<div class="box">
		@if (tenantSettings) {
			<div class="contact-info">
				<button (click)="showContactInfo()" type="button" class="uf-action" title="Contact Info">
					<uf-icon name="query" />
				</button>
			</div>
		}

		<div class="logo-wrapper">
			@if (tenantSettings?.logo?.url) {
				<img [src]="tenantSettings?.logo?.url + '?h=100'" class="logo" alt="Unifii" />
			} @else {
				<img class="logo" src="assets/svg/product-logo.svg" alt="Unifii" />
			}
		</div>

		<div class="content">
			<ng-content select="[content]" />
		</div>

		<div class="footer body-copy">
			<div class="row">
				<div class="grow">
					<p class="small">
						<a href="https://www.unifii.com.au/privacy-policy" target="_blank"> Privacy Policy </a>
					</p>
					<uf-data-display-list [items]="{ version: config.version }" />
				</div>
				<div class="right row">
					<a class="col" href="https://www.unifii.com.au" target="_blank">
						<img class="supplier-logo" src="assets/svg/product-logo-secondary.svg" alt="Powered by Unifii" />
					</a>
				</div>
			</div>
		</div>
	</div>
</uf-panel>
