@if (ready) {
	<div class="uf-box flat pad-sm-sides uf-grid row-gap-sm pad-sm-top pad-sm-bottom">
		@if (control.showError && control.errors?.message) {
			<uf-message [content]="control.errors?.message" class="error col-12" icon="error" />
		}
		<!-- <span class="col-12">Meta: <uf-help [content]="meta | json"></uf-help></span> -->
		@if (meta.variations && variations.length) {
			<uf-select [options]="variationOptions" [(value)]="selectedVariationOption" (valueChange)="selectVariation($event)" label="Variation" placeholder="Default" class="col-12 pad-sm-sides" />
		}
		@if (!variation) {
			@if (visibles.details) {
				<uc-form-field-details [control]="control" class="col-12" />
			}
			@if (visibles.transitions) {
				<uc-form-field-transitions [control]="control" [transitions]="transitions" [meta]="meta" class="col-12" />
			}
			@if (visibles.settings) {
				<uc-form-field-settings [control]="control" class="col-12" />
			}
			@if (visibles.options) {
				<uc-form-field-options [type]="field.type" [meta]="meta" [options]="options" [dataSourceConfig]="dataSourceConfig" [template]="template" [id]="id" [identifier]="identifier" [avoidDuplicates]="avoidDuplicates" [dataCaptures]="dataCaptures" class="col-12" />
			}
			@if (visibles.nestedFields) {
				<uc-form-field-nested-fields [control]="control" class="col-12" />
			}
			@if (visibles.display) {
				<uc-form-field-display [control]="control" class="col-12" />
			}
			@if (visibles.variations) {
				<uc-form-field-variations [control]="control" [meta]="meta" class="col-12" />
			}
			@if (visibles.validators) {
				<uc-form-field-validators [validators]="validators" [type]="field.type" class="col-12" />
			}
			@if (visibles.advanced) {
				<uc-form-field-advanced [control]="control" class="col-12" />
			}
		}
		@if (variation) {
			<uc-form-field-variation [control]="variation" [type]="field.type" [template]="template" [id]="id" [identifier]="identifier" [meta]="meta" class="col-12" />
		}
	</div>
}
