<uf-panel class="container">
	<div class="header-pane uf-app-bar transparent">
		<uc-builder-header [templateRef]="templateRef" />
	</div>

	<div class="content-pane scrollable">
		<uf-tabs (selectedChange)="updateTitle()">
			@for (tab of tabs; track tab) {
				<uf-tab [label]="tab.label" [path]="tab.path" />
			}
		</uf-tabs>
	</div>
</uf-panel>
