import { SortStatus, TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { UcClaimConfig, UcUserClaims } from 'client';

export class ClaimDataSource extends TableDataSource<UcClaimConfig> {

	constructor(
		private claimsClient: UcUserClaims,
		private q?: string,
		private sort?: SortStatus,
	) {
		super();
	}

	get filtered(): boolean {
		return this.q != null && this.q.trim().length > 0;
	}

	get sorted(): boolean {
		return this.sort != null;
	}

	load(options?: TableDataSourceQueryOptions) {
		const sort = this.sort?.toString() ?? null;

		this.claimsClient.list({
			params: { q: this.q, sort, offset: options?.offset, limit: options?.limit },
			analytics: { origin: RequestAnalyticsOrigin.Table },
		}).then((roles) => {
			this.stream.next({ data: roles });
		}, (error) => {
			this.stream.next({ error });
		});
	}

}
