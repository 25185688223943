import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { isStringNotEmpty } from '@unifii/sdk';

import { CompoundInfo, DataSourceInfo, DefinitionInfo, Media, SchemaInfo, TableInfo, UcCollection, UcDataSources, UcFormBucketClient, UcMedia, UcProject } from 'client';
import { ConsoleNameContentProperty, ConsoleNameProperty } from 'constant';

export enum ContentSelectType {
	View = 'View', // CompoundInfo
	Page = 'Page', // CompoundInfo
	Collection = 'Collection', // DefinitionInfo
	CollectionItem = 'CollectionItem', // CompoundInfo
	Form = 'Form', // DefinitionInfo
	Schema = 'Schema', // SchemaInfo
	Table = 'Table', // TableInfo
	DataSource = 'DataSource', // DataSourceInfo
	PdfViewer = 'PdfViewer', // PdfViewerInfo
}

export type ContentType = TableInfo | SchemaInfo | CompoundInfo | DefinitionInfo | DataSourceInfo | Media;

export interface ContentSelectConfig {
	type: ContentSelectType;
	title: string;
	searchLabel: string;
	searchPlaceHolder: string;
	searchNameProperty: string;
	searchError: string;
	searchSecondLabel?: string;
	searchSecondPlaceHolder?: string;
	searchSecondNameProperty?: string;
	searchSecondError?: string;
	ucProject: UcProject;
	ucFormBucketClient: UcFormBucketClient;
	ucDataSources?: UcDataSources;
}

export const getContentSelectConfig = (type: ContentSelectType, ucProject: UcProject, ucFormBucketClient: UcFormBucketClient, ucDataSources?: UcDataSources): ContentSelectConfig => {
	switch (type) {
		case ContentSelectType.Table:
			return {
				type,
				title: 'Table',
				searchLabel: 'Select Table',
				searchPlaceHolder: 'Search a table',
				searchError: 'Select a table',
				searchNameProperty: 'titleWithIdentifier',
				ucProject,
				ucFormBucketClient,
			};
		case ContentSelectType.Schema:
			return {
				type,
				title: 'Form Data Repository',
				searchLabel: 'Select Form Data Repository',
				searchPlaceHolder: 'Search a form data repository',
				searchError: 'Select a form data reposirory',
				searchNameProperty: 'id',
				ucProject,
				ucFormBucketClient,
			};
		case ContentSelectType.View:
			return {
				type,
				title: 'View',
				searchLabel: 'Select View',
				searchPlaceHolder: 'Search a view',
				searchError: 'Select a view',
				searchNameProperty: ConsoleNameContentProperty,
				ucProject,
				ucFormBucketClient,
			};
		case ContentSelectType.Page:
			return {
				type,
				title: 'Page',
				searchLabel: 'Select Page',
				searchPlaceHolder: 'Search a page',
				searchError: 'Select a page',
				searchNameProperty: ConsoleNameContentProperty,
				ucProject,
				ucFormBucketClient,
			};
		case ContentSelectType.PdfViewer:
			return {
				type,
				title: 'PDF',
				searchLabel: 'Select PDF',
				searchPlaceHolder: 'Search a PDF',
				searchError: 'Select a PDF',
				searchNameProperty: 'title',
				ucProject,
				ucFormBucketClient,
			};
		case ContentSelectType.Form:
			return {
				type,
				title: 'Form',
				searchLabel: 'Select Form',
				searchPlaceHolder: 'Search a form',
				searchError: 'Select a form',
				searchNameProperty: ConsoleNameProperty,
				ucProject,
				ucFormBucketClient,
			};
		case ContentSelectType.Collection:
			return {
				type,
				title: 'Collection',
				searchLabel: 'Select Collection',
				searchPlaceHolder: 'Search a collection',
				searchError: 'Select a collection',
				searchNameProperty: ConsoleNameProperty,
				ucProject,
				ucFormBucketClient,
			};
		case ContentSelectType.CollectionItem:
			return {
				type,
				title: 'Collection Record',
				searchLabel: 'Select Collection',
				searchPlaceHolder: 'Search a collection',
				searchError: 'Select a collection',
				searchNameProperty: ConsoleNameProperty,
				searchSecondLabel: 'Select Record Name',
				searchSecondPlaceHolder: 'Search a record name',
				searchSecondError: 'Select a record name',
				searchSecondNameProperty: ConsoleNameContentProperty,
				ucProject,
				ucFormBucketClient,
			};
		case ContentSelectType.DataSource:
			return {
				type,
				title: 'Data Source',
				searchLabel: 'Select Data Source',
				searchPlaceHolder: 'Search a data source',
				searchError: 'Select a data source',
				searchNameProperty: 'name',
				ucProject,
				ucFormBucketClient,
				ucDataSources,
			};
	}
};

@Component({
	selector: 'uc-content-select',
	templateUrl: './content-select.html',
	standalone: false,
})
export class ContentSelectComponent implements Modal<ContentSelectConfig, ContentType>, OnInit {

	@HostBinding('class.uc-form-card') classes = true;
	data = inject<ContentSelectConfig>(ModalData);
	runtime = inject<ModalRuntime<ContentSelectConfig, ContentType>>(ModalRuntime);

	ucProject: UcProject;
	ucFormBucketClient: UcFormBucketClient;
	ucDataSources?: UcDataSources;
	ucCollection: UcCollection | null;

	contentsResult: ContentType[];
	content: ContentType;

	contentsSecondResult: ContentType[];
	contentSecond: ContentType;

	root: UfControlGroup;
	searchControl: UfControl;
	searchSecondControl: UfControl;

	// TODO Remove once GET Collection API accept query
	allCollections: DefinitionInfo[];

	protected readonly searchControlKey = 'search';
	protected readonly searchSecondControlKey = 'searchSecond';

	private ucMedia = inject(UcMedia);

	ngOnInit() {
		this.ucProject = this.data.ucProject;
		this.ucFormBucketClient = this.data.ucFormBucketClient;
		this.ucDataSources = this.data.ucDataSources;

		this.root = new UfControlGroup({
			[this.searchControlKey]: new UfControl(ValidatorFunctions.required(this.data.searchError)),
		});
	}

	async search(query: string) {

		switch (this.data.type) {
			case ContentSelectType.Table:
				this.contentsResult = (await this.ucProject.getTables({ params: { q: query, sort: 'title' } })).map((table) => ({
					...table,
					titleWithIdentifier: `${table.consoleName ?? table.title} (${table.identifier})`,
				}));
				break;
			case ContentSelectType.Schema:
				this.contentsResult = await this.ucFormBucketClient.list({ params: { q: query, sort: 'id' } });
				break;
			case ContentSelectType.View:
				this.contentsResult = await this.ucProject.getViews({ params: { qx: query, sort: 'name' } });
				break;
			case ContentSelectType.Page:
				this.contentsResult = await this.ucProject.getPages({ params: { q: query, sort: 'name' } });
				break;
			case ContentSelectType.PdfViewer:
				this.contentsResult = await this.ucMedia.get({ q: query, types: 'File' });
				break;
			case ContentSelectType.Form:
				this.contentsResult = await this.ucProject.getForms({ params: { q: query, sort: 'name' } });
				break;
			case ContentSelectType.Collection:
				if (!this.allCollections) {
					this.allCollections = await this.ucProject.getCollections();
				}
				this.contentsResult = query ? this.allCollections.filter((c) => c.name.toLowerCase().includes(query.toLowerCase())) : this.allCollections;
				break;
			case ContentSelectType.DataSource:
				this.contentsResult = this.ucDataSources ? await this.ucDataSources.list(query) : [];
				break;
			case ContentSelectType.CollectionItem:
				if (!this.ucCollection) {
					if (!this.allCollections) {
						this.allCollections = await this.ucProject.getCollections();
					}
					this.contentsResult = query ? this.allCollections.filter((c) => c.name.toLowerCase().includes(query.toLowerCase())) : this.allCollections;
				} else {
					this.contentsSecondResult = await this.ucCollection.get({ q: query, sort: 'name' });
				}
				break;
		}
	}

	selected(content: ContentType) {

		this.content = content;

		if (this.data.type === ContentSelectType.CollectionItem) {
			if (this.content) {
				this.root.addControl(this.searchSecondControlKey, new UfControl(ValidatorFunctions.required(this.data.searchSecondError as string)));
				this.ucCollection = this.ucProject.collection((this.content as DefinitionInfo).identifier);
			} else {
				this.ucCollection = null;
				this.root.removeControl(this.searchSecondControlKey);
			}
		}
	}

	selectedSecond(content: ContentType) {
		this.contentSecond = content;
	}

	confirm() {
		this.root.setSubmitted();

		if (this.root.valid) {
			this.runtime.close(this.ucCollection ? this.contentSecond : this.content);
		}
	}

	close() {
		this.runtime.close();
	}

	protected mapDescription = (item: ContentType): string => {
		const searchNamePropertyValue = item[this.data.searchNameProperty as keyof ContentType];

		if (this.data.type === ContentSelectType.Table && !isStringNotEmpty(searchNamePropertyValue)) {
			const { title, consoleName, identifier } = item as TableInfo;

			return `${consoleName ?? title} (${identifier})`;
		}

		return searchNamePropertyValue != null ? `${searchNamePropertyValue}` : '';
	};

}
