@if (ready) {
	<uf-expander class="uf-box flat">
		<div expanderHeader class="uf-app-bar flat">
			<div class="title primary">Settings</div>
			@if (isInvalid) {
				<uf-icon name="error" class="error" />
			}
		</div>
		<div [formGroup]="control" expanderBody class="uf-grid pad-sides pad-sm-top pad-bottom">
			@if (meta.scrollTime) {
				<uf-select [options]="scrollTimes" [formControlName]="fieldKeys.ScrollTime" label="Scroll Time" valueProperty="value" nameProperty="name" class="col-12" />
			}
			@if (meta.isRequired) {
				<uf-checkbox [formControlName]="fieldKeys.IsRequired" label="Required" class="col-6" />
			}
			@if (meta.isReadOnly) {
				<uf-checkbox [formControlName]="fieldKeys.IsReadOnly" label="Read only" class="col-6" />
			}
			@if (meta.autoDetect) {
				<uf-checkbox [formControlName]="fieldKeys.AutoDetect" label="Autodetect" class="col-12" />
			}
			@if (meta.currency) {
				<uf-select [formControlName]="fieldKeys.Currency" [options]="currencies" label="Currency" valueProperty="identifier" nameProperty="name" class="col-12" />
			}
			@if (meta.autofill) {
				<uf-text [formControlName]="fieldKeys.Autofill" label="Autofill" class="col-12" />
			}
			@if (meta.bindTo) {
				<uf-text [formControlName]="fieldKeys.BindTo" label="Bind to" class="col-12" />
			}
			@if (meta.showIf) {
				<uf-textarea [formControlName]="fieldKeys.ShowIf" label="Show if" class="col-12" />
			}
			@if (meta.showOn) {
				<uf-autocomplete [options]="actionsResult" [formControlName]="fieldKeys.ShowOn" (searchChange)="searchActions($event)" label="Show on" class="col-12" />
			}
			@if (meta.precision) {
				<uf-number [formControlName]="fieldKeys.Precision" label="Precision" class="col-12" />
			}
			@if (meta.maxLength) {
				<uf-number [formControlName]="fieldKeys.MaxLength" label="Maximum length" class="col-12" />
			}
			@if (meta.step) {
				<uf-select [formControlName]="fieldKeys.Step" [options]="intervals" label="Interval" valueProperty="identifier" nameProperty="name" class="col-12" />
			}
			@if (meta.format) {
				<uf-autocomplete [formControlName]="fieldKeys.Format" [allowCustom]="true" [options]="formatOptions" [placeholder]="formatPlaceholder" label="Format" class="col-12" />
			}
			@if (meta.hierarchy) {
				<ng-container [formGroupName]="fieldKeys.HierarchyConfig">
					<uf-hierarchy-unit [formControlName]="hierarchyConfigKeys.Ceiling" [activesOnly]="true" label="Starting Unit" placeholder="Root" class="col-12" />
					<uf-select [formControlName]="hierarchyConfigKeys.SelectionMode" [options]="selectionModeOptions" label="Limit selection to" valueProperty="identifier" nameProperty="name" class="col-12" />
				</ng-container>
			}
			@if (showSort) {
				<uc-sort-property-input [formControlName]="fieldKeys.Sort" [properties]="sortProperties" label="Sort" class="col-12" />
			}
		</div>
	</uf-expander>
}
