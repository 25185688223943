@if (show) {
	<uc-drag-list [items]="elements" (moved)="moved()" canDrop="false" canReorder="true">
		@for (option of elements; track option; let i = $index) {
			@let control = formList.at(i);
			@if (control) {
				<uc-field-option [option]="option" [control]="control | asUfControlGroup" [isExpanded]="!!expanded['' + i]" [field]="field" (remove)="remove(i)" dragItem class="gap-sm-bottom" />
			}
		}
	</uc-drag-list>
}
