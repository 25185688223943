@if (show) {
	<form class="wrap">
		<uc-drag-list [items]="elements" (moved)="moved()" canDrop="false" canReorder="true" class="uc-list">
			@for (e of elements; track e; let i = $index) {
				<uf-expander [isExpanded]="expanded['' + i]" dragItem class="col-1of1--no-margin--no-padding uc-list-item--small--draggable--no-shrink">
					<div expanderHeader dragHandle class="uf-app-bar flat">
						<div class="title primary">
							@if (e.type) {
								<span>{{ e.type }}</span>
							}
						</div>
						@if ($any(form.controls.list).at(i).invalid) {
							<uf-icon name="warning" class="icon-warning" />
						}
						<button (click)="remove(i)" type="button" title="Delete" class="uf-action tertiary">
							<uf-icon name="delete" />
						</button>
					</div>
					<div expanderBody class="uc-group-content grid">
						@if (configs[i]?.type?.show) {
							<uf-select [control]="$any(form.controls.list).at(i).controls.type" [options]="validatorOptions" [(value)]="e.type" (valueChange)="changeType($event, i)" label="Type" placeholder="Select type" nameProperty="label" valueProperty="type" class="col-1of1" />
						}
						@if (configs[i]?.expression?.show) {
							<uf-text [control]="$any(form.controls.list).at(i).controls.expression" [placeholder]="validatorDictionary[e.type]?.placeholders?.value" [(value)]="e.value" label="Expression" class="col-1of1" />
						}
						@if (configs[i]?.itemExpression?.show) {
							<uf-text [control]="$any(form.controls.list).at(i).controls.itemExpression" [placeholder]="validatorDictionary[e.type]?.placeholders?.value" [(value)]="e.value" label="Item Expression" class="col-1of1" />
						}
						@if (configs[i]?.pattern?.show) {
							<uf-text [control]="$any(form.controls.list).at(i).controls.pattern" [placeholder]="validatorDictionary[e.type]?.placeholders?.value" [(value)]="e.value" label="Pattern" class="col-1of1" />
						}
						@if (configs[i]?.minLength?.show) {
							<uf-number [control]="$any(form.controls.list).at(i).controls.minLength" [placeholder]="validatorDictionary[e.type]?.placeholders?.value" [(value)]="e.value" label="Min Length" class="col-1of1" />
						}
						@if (configs[i]?.min?.show) {
							<uf-number [control]="$any(form.controls.list).at(i).controls.min" [placeholder]="validatorDictionary[e.type]?.placeholders?.value" [(value)]="e.value" label="Min" class="col-1of1" />
						}
						@if (configs[i]?.max?.show) {
							<uf-number [control]="$any(form.controls.list).at(i).controls.max" [placeholder]="validatorDictionary[e.type]?.placeholders?.value" [(value)]="e.value" label="Max" class="col-1of1" />
						}
						@if (configs[i]?.message?.show) {
							<uf-text [control]="$any(form.controls.list).at(i).controls.message" [placeholder]="validatorDictionary[e.type]?.placeholders?.message" [(value)]="e.message" label="Message" class="col-1of1" />
						}
					</div>
				</uf-expander>
			}
		</uc-drag-list>
	</form>
}
@if (form.controls.list) {
	<uf-error [control]="form.controls.list | asUfControl" style="display: block; padding-left: 8px" />
}
