import { DefinitionPublishState } from '@unifii/sdk';

export const PUBLISH_STATES: { name: string; value: DefinitionPublishState }[] = [
	{ name: 'Approved', value: DefinitionPublishState.Approved },
	{ name: 'Draft', value: DefinitionPublishState.Draft },
	{ name: 'Published', value: DefinitionPublishState.Published },
	{ name: 'Archive Pending', value: DefinitionPublishState.ArchivePending },
	{ name: 'Archived', value: DefinitionPublishState.Archived },
];

export interface Publishable {
	publishState: string;
	lastPublishedAt?: string;
}

export class PublishStateHelper {

	static canApprove(p: Publishable) {
		return p.publishState === DefinitionPublishState.Draft;
	}

	static canUnapprove(p: Publishable) {
		return p.publishState === DefinitionPublishState.Approved;
	}

	static canArchive(p: Publishable) {
		if (p.lastPublishedAt == null) {
			return false;
		}

		return !([DefinitionPublishState.Archived, DefinitionPublishState.ArchivePending] as string[]).includes(p.publishState);
	}

	static canUnarchive(p: Publishable) {
		return ([DefinitionPublishState.Archived, DefinitionPublishState.ArchivePending] as string[]).includes(p.publishState);
	}

	static canDelete(p: Publishable) {
		return p.lastPublishedAt == null;
	}

}
