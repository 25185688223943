export function createParser(md, tokenizer) {
  const arrayReplaceAt = md.utils.arrayReplaceAt;
  return function (state) {
    let i;
    let j;
    let l;
    let tokens;
    let token;
    let autolinkLevel = 0;
    const blockTokens = state.tokens;
    for (j = 0, l = blockTokens.length; j < l; j++) {
      if (blockTokens[j].type !== 'inline') {
        continue;
      }
      tokens = blockTokens[j].children;
      // We scan from the end, to keep position when new tags added.
      // Use reversed logic in links start/end match
      for (i = tokens.length - 1; i >= 0; i--) {
        token = tokens[i];
        if (token.type === 'link_open' || token.type === 'link_close') {
          if (token.info === 'auto') {
            autolinkLevel -= token.nesting;
          }
        }
        if (token.type === 'text' && autolinkLevel === 0) {
          const assetTokens = tokenizer(token.content, state.Token);
          if (assetTokens != null && assetTokens.length) {
            blockTokens[j].children = tokens = arrayReplaceAt(tokens, i, assetTokens);
          }
        }
      }
    }
  };
}
