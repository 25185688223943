import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import { UfError } from '@unifii/sdk';
import { filter } from 'rxjs/operators';

import { UcPermissionsClient, UcUserInfo, UcUsers } from 'client';
import { useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { EditData } from 'components/common/edit-data';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

@Component({
	selector: 'uc-user',
	templateUrl: './user.html',
	styleUrls: ['./user.less'],
	providers: [
		{
			provide: UcPermissionsClient,
			useFactory: (users: UcUsers, route: ActivatedRoute) => users.permissions(route.snapshot.params.id),
			deps: [UcUsers, ActivatedRoute],
		},
	],
	standalone: false,
})
export class UserComponent extends TabsPage implements OnInit, EditData {

	override edited: boolean;
	id: string;
	user: UcUserInfo;
	isNew: boolean;
	templateRef?: TemplateRef<Element>;

	protected error?: UfError;

	private context = inject(ContextService);
	private ucUsers = inject(UcUsers);
	private ucPermissions = inject(UcPermissionsClient);
	private breadcrumbService = inject(BreadcrumbService);
	private builderHeaderService = inject(BuilderHeaderService);

	get isMyAccount() {
		return this.route.snapshot.params.id == null;
	}

	override async ngOnInit() {
		this.ucPermissions.inherited = this.router.url.endsWith('inherited-permissions');
		this.subscriptions.add(this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((e) => {
			this.ucPermissions.inherited = (e as NavigationStart).url.endsWith('inherited-permissions');
		}));

		const params = this.route.snapshot.params;

		this.id = params.id as string | undefined ?? this.context.account?.id ?? '';
		this.isNew = this.id === 'new';

		try {
			this.user = await this.getUser();
		} catch (e) {
			this.error = useDefaultErrorMessage(e);

			return;
		}

		this.buildHeaderConfig();

		super.ngOnInit();
	}

	async reload() {
		try {
			this.user = await this.getUser();
		} catch (e) {
			this.error = useDefaultErrorMessage(e);

			return;
		}
	}

	/**
     * @description
     * This method is public because it will be called by children components
     * when they're destroyed, so we can update the header
     */
	buildHeaderConfig() {
		this.builderHeaderService.init();
		this.builderHeaderService.buildConfig({
			breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route),
			hideSaveButton: true,
		});
	}

	private async getUser(): Promise<UcUserInfo> {

		let user: UcUserInfo;

		if (this.isNew) {
			user = {
				username: null as any as string,
				hasPassword: null as any as boolean,
				isExternal: null as any as boolean,
				isActive: null as any as boolean,
				isMfaOptional: null as any as boolean,
				isMfaEnabled: null as any as boolean,
			};
		} else {
			user = await this.ucUsers.getDetails(this.id);
		}

		return Object.assign({ roles: [], claims: [], systemRoles: [] }, user);
	}

}
