import { Component, HostBinding, inject } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { FilterComponentRegistry, FilterEntries } from '@unifii/library/common';

import { UcProject } from 'client';
import { InfoFilterFactory } from 'services/table/info-filter-factory';
import { InfoFilterRegistry } from 'services/table/info-filter-registry';
import { PageInfoLoader } from 'services/table/info-loaders';
import { InfoTableManager } from 'services/table/info-table-manager';
import { InfoLoader, InfoType } from 'services/table/models';

const createLoader = (ucProject: UcProject) => new PageInfoLoader(ucProject);

const createFilters = (filterFactory: InfoFilterFactory) => filterFactory.create(InfoType.Page);

@Component({
	selector: 'uc-pages',
	template: `
        <div ufMasterDetail>
        <uf-table-container (addItem)="addItem()" ufSyncRoute class="accent list-md pad-none" />
        </div>
        <router-outlet />
    `, providers: [
		{ provide: FilterComponentRegistry, useClass: InfoFilterRegistry },
		{ provide: FilterEntries, useFactory: createFilters, deps: [InfoFilterFactory] },
		{ provide: InfoLoader, useFactory: createLoader, deps: [UcProject] },
		{ provide: TableContainerManager, useClass: InfoTableManager },
	],
	standalone: false,
})
export class PagesComponent {

	@HostBinding('class.stretch-component') class = true;

	private manager = inject<InfoTableManager>(TableContainerManager);

	addItem() {
		void this.manager.addActionCallback();
	}

}
