@if (ready) {
	<uf-panel class="content-pane container">
		<div class="uf-container-lg">
			<div class="uf-grid row-gap-lg gap-top">
				<!-- Type block -->
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Details Page</div>
					</div>
					<div class="uc-group-content grid">
						<!-- // TODO UNIFII-4422 - Remove conditional disabled -->
						<uf-select [options]="tableDetailTypeOptions" [(value)]="tableDetailType" (valueChange)="toggleTableDetailType()" label="Type" valueProperty="value" class="col-1of1" />
						@if (table.sourceType === sourceTypes.Bucket) {
							<uf-data-display-list [items]="[{ term: 'Form Data Repository', data: table.source }]" class="col-1of1" />
						}
						@if (tableDetail && cRoot.controls.label) {
							<uf-text [control]="cRoot.controls.label | asUfControl" [(value)]="tableDetail.title" label="Title" class="col-1of1" />
						}
						@if (tableDetail && cRoot.controls.detailTemplate) {
							<uf-select [control]="cRoot.controls.detailTemplate | asUfControl" [options]="templateOptions" (valueChange)="tableDetail.emptyMessage = undefined" label="Template" valueProperty="identifier" class="col-1of1" />
						}
						@if (tableDetail && cRoot.controls.emptyMessage && cRoot.controls.emptyMessage.enabled) {
							<uf-text [control]="cRoot.controls.emptyMessage | asUfControl" [(value)]="tableDetail.emptyMessage" label="Empty message" class="col-1of1" />
						}
					</div>
				</div>
				@if (tableDetail) {
					<!-- Data block -->
					<uf-expander class="uf-box col-12 gap-top-sm">
						<div expanderHeader class="uf-app-bar flat accent">
							@if (table.sourceType === sourceTypes.Bucket) {
								<div class="title">Form Data</div>
							}
							@if (table.sourceType === sourceTypes.Users) {
								<div class="title">User Data</div>
							}
							@if (table.sourceType === sourceTypes.Company) {
								<div class="title">Company Data</div>
							}
						</div>
						<div expanderBody class="uf-grid pad">
							@if (fields.length) {
								<uc-drag-list [items]="fields" (moved)="edited = true" canDrop="false" canReorder="true" class="col-12">
									@for (field of fields; track field; let i = $index) {
										<uf-expander [isExpanded]="false" dragItem class="uf-box draggable flat gap-sm-top">
											<div expanderHeader dragHandle class="uf-app-bar">
												<uf-icon [name]="field._icon" />
												<div class="grow title primary">
													{{ field._label }}
												</div>
												@if (!field._exists) {
													<span class="uf-lozenge error">Missing</span>
												}
												<button (click)="removeField(i)" type="button" title="Delete" class="uf-action tertiary">
													<uf-icon name="delete" />
												</button>
											</div>
											<ng-container expanderBody>
												<div class="pad uf-grid">
													@if (field.descriptor.type === "Field") {
														<uf-text [(value)]="field.descriptor.label" (valueChange)="onFieldChange()" label="Label" placeholder="Override default label" class="col-12" />
													}
													@if (field._itemTemplate && field.descriptor.type === "Field") {
														<uf-textarea [(value)]="field.descriptor.itemTemplate" (valueChange)="onFieldChange()" label="Item Template" class="col-12" />
													}
													@if (field.descriptor.type === "Heading") {
														<uf-text [(value)]="field.descriptor.value" (valueChange)="onFieldChange()" label="Value" class="col-12" />
													}
												</div>
											</ng-container>
										</uf-expander>
									}
								</uc-drag-list>
							}
							<uf-search-result [options]="visibleFields" (valueChange)="addField($event.identifier)" (searchChange)="filterFields($event)" label="Fields" placeholder="Search field" nameProperty="display" class="col-12" />
							<div class="col-12 gap-top">
								<button (click)="addField()" type="button" class="uf-button right">Add Heading</button>
							</div>
						</div>
					</uf-expander>
					<div class="uf-box col-12">
						<div class="uf-app-bar flat accent">
							<div class="title">Modules</div>
							@if (tableDetail.modules.length) {
								<uc-expander-controls [container]="modulesWrap" />
							}
						</div>
						<div #modulesWrap class="uc-group-content pad-sides pad-bottom pad-sm-top">
							<uc-drag-list [items]="tableDetail.modules" (moved)="moveModule($event)" canDrop="false" canReorder="true">
								@for (module of tableDetail.modules; track module; let i = $index) {
									<uf-expander [isExpanded]="!!(cModules.at(i) | asUfControlGroup).controls.expanded?.value" dragItem class="uf-box draggable flat gap-sm-top">
										<div expanderHeader dragHandle class="uf-app-bar">
											<div class="grow title primary">
												{{ module.type }} - {{ module.identifier }}
												@if (cModules.at(i).invalid) {
													<uf-icon title="This module is invalid" name="error" class="error" />
												}
											</div>
											<button (click)="removeModule(i)" title="Delete" type="button" class="uf-action tertiary">
												<uf-icon name="delete" />
											</button>
										</div>
										<ng-container expanderBody>
											<div class="pad">
												@if (module.type === moduleTypes.Table) {
													<uc-table-module-table [template]="cRoot.controls.detailTemplate?.value" [module]="module" [parentControl]="cModules.at(i) | asUfControlGroup" />
												}
											</div>
										</ng-container>
									</uf-expander>
								}
							</uc-drag-list>
							<div class="col-12 gap-top">
								<button (click)="addModule()" type="button" class="uf-button right">Add Module</button>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	</uf-panel>
}
