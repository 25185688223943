<div [formGroup]="filters">
	<!-- CONDITION FILTERS -->

	<div class="uf-box flat gap-top gap-sides">
		<div class="uf-app-bar accent flat">
			<div class="title">Condition Filters</div>
		</div>

		<div class="uf-grid pad-sides gap-top gap-bottom">
			<!-- Group Filter -->
			@if (groupControl) {
				<uf-autocomplete [formControlName]="controlKeys.Group" [allowCustom]="true" [options]="filteredSourceGroups" (searchChange)="findSourceGroups($event)" (valueChange)="changeGroup(groupControl, $event)" placeholder="Select a source" label="Group" nameProperty="name" class="col-12" />
			}

			<!-- Claim Value -->
			<uf-expander [isExpanded]="false" class="col-12">
				<div expanderHeader class="uf-app-bar flat">
					<div class="title">Has Claim</div>
				</div>
				<div expanderBody class="uf-grid uf-box pad">
					<uf-autocomplete [formControlName]="controlKeys.ConditionClaimIdentifier" [options]="filteredSourceClaims" [allowCustom]="true" (searchChange)="findSourceClaims($event)" label="Claim" placeholder="Select a source" class="col-6" />
					<uf-text [formControlName]="controlKeys.ConditionClaimValue" label="Claim Value" class="col-6" />
				</div>
			</uf-expander>

			<!-- Role Assigned -->
			@if (type === authProviderTypes.Auth0) {
				<uf-role-input [formControlName]="controlKeys.RolesAssigned" label="Roles" class="col-12" />
			}
		</div>
	</div>

	<div class="uf-box flat gap-top gap-sides">
		<div class="uf-app-bar accent flat">
			<div class="title">Action Filters</div>
		</div>

		<!-- ACTION FILTERS -->
		<div class="uf-grid pad">
			<!-- Hierarchy Filter -->
			<uf-expander [isExpanded]="false" class="col-12">
				<div expanderHeader class="uf-app-bar flat">
					<div class="title">Hierarchy</div>
				</div>
				<div expanderBody class="uf-box pad">
					<uf-hierarchy-unit-selector [control]="hierarchyControl" [(value)]="hierarchyValue" class="col-12" />
				</div>
			</uf-expander>

			<!-- System Role -->
			<uf-chips [formControlName]="controlKeys.ActionSystemRoles" [options]="filteredSystemRoles" (searchChange)="findSystemRoles($event)" placeholder="Select a System Role" label="System Role" class="col-6" />

			<!-- Role -->
			<uf-role-input [formControlName]="controlKeys.ActionRoles" label="Roles" class="col-6" />

			<!-- AssignClaimFrom -->
			<uf-expander [isExpanded]="false" class="col-12">
				<div expanderHeader class="uf-app-bar flat">
					<div class="title">Assign Claim From</div>
				</div>
				<div expanderBody class="uf-grid uf-box pad">
					<uf-autocomplete [formControlName]="controlKeys.ActionClaimTo" [options]="filteredSourceClaimsTo" [allowCustom]="true" (searchChange)="findSourceClaimsTo($event)" label="Claim To" nameProperty="type" placeholder="Select a claim" class="col-6" />
					<uf-autocomplete [formControlName]="controlKeys.ActionClaimFrom" [options]="filteredSourceClaims" [allowCustom]="true" (searchChange)="findSourceClaims($event)" label="Claim From" nameProperty="type" placeholder="Select a claim" class="col-6" />
				</div>
			</uf-expander>

			<!-- Claim Value -->
			<uf-expander [isExpanded]="false" class="col-12">
				<div expanderHeader class="uf-app-bar flat">
					<div class="title">Assign Claim Value</div>
				</div>
				<div expanderBody class="uf-grid uf-box pad">
					<uf-autocomplete [formControlName]="controlKeys.ActionClaimIdentifier" [options]="filteredSourceClaimsTo" [allowCustom]="true" (searchChange)="findSourceClaimsTo($event)" label="Claim" placeholder="Select a source" class="col-6" />
					<uf-text [formControlName]="controlKeys.ActionClaimValue" label="Claim Value" class="col-6" />
				</div>
			</uf-expander>
		</div>
	</div>

	<div class="uf-form-actions">
		<button (click)="clearFilter()" type="button" class="uf-button x-small right">
			Clear
			<uf-icon name="delete" />
		</button>
	</div>
</div>
