import { Component, HostBinding, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ModalService, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { ErrorType, ImageMode, UfError, ensureUfRequestError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Media, MediaType, UcMedia } from 'client';
import { EditData, SaveAndClose, SaveAndNext, SaveOption, SaveOptionType, useDefaultErrorMessage } from 'components';
import { ImagePreviewComponent } from 'components/content/modals/image-preview.component';
import { ConsoleNameLabel, ConsoleNameRequiredMessage } from 'constant';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { TitleService } from 'services/title.service';

import { FileConflictError, FileConflictModalComponent } from './file-conflict-modal.component';
import { MediaTableManager } from './media-table-manager';

@Component({
	selector: 'uc-media-detail',
	templateUrl: './media-detail.html',
	styleUrls: ['./media-detail.less'],
	standalone: false,
})
export class MediaDetailComponent implements EditData, OnDestroy {

	@HostBinding('class.media-detail') class = true;

	edited: boolean;

	protected readonly mediaType = MediaType;
	protected readonly consoleNameLabel = ConsoleNameLabel;

	protected media: Media;
	protected loading = true;
	protected error?: UfError;
	protected detailsForm: UfControlGroup;
	protected breadcrumbs: Breadcrumb[];
	protected saveOptions = [SaveAndClose, SaveAndNext];

	private isPending = false;
	private subscriptions = new Subscription();
	private breadcrumbService = inject(BreadcrumbService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private ucMedia = inject(UcMedia);
	private modalService = inject(ModalService);
	private toastService = inject(ToastService);
	private titleService = inject(TitleService);
	private tableManager = inject<MediaTableManager>(TableContainerManager);

	constructor() {

		this.detailsForm = new UfControlGroup({
			titleControl: new UfControl(ValidatorFunctions.required('Title is required')),
			consoleNameControl: new UfControl(ValidatorFunctions.required(ConsoleNameRequiredMessage)),
			descriptionControl: new UfControl(),
		});

		this.subscriptions.add(this.detailsForm.statusChanges.subscribe(() => { this.edited = true; }));

		this.subscriptions.add(this.route.params.subscribe((v) => {
			if (v.id === 'new') {
				return;
			}
			void this.init(+v.id);
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected getUrl(width: number, height: number): string | undefined {
		return this.ucMedia.buildImageUrl(this.media, { width, height, mode: ImageMode.Contain });
	}

	protected async delete() {
		if (this.media.id == null || !await this.modalService.openConfirm({
			title: 'Delete',
			message: `Media ${this.media.consoleName ? this.media.consoleName : this.media.filename} deletion can't be undone.`,
			confirmLabel: 'Delete',
			cancelLabel: `Don't Delete`,
		})) {
			return;
		}

		try {
			await this.ucMedia.delete(this.media.id);
			this.toastService.success('Media deleted');
			this.tableManager.reload.next();
			this.back();
		} catch (error) {
			const requestError = ensureUfRequestError(error);

			if (requestError.type === ErrorType.Conflict) {
				void this.modalService.openMedium(FileConflictModalComponent, requestError.data as FileConflictError[]);
			} else {
				this.error = requestError;
			}
		}
	}

	protected async save(saveOption?: SaveOption) {
		this.detailsForm.setSubmitted();
		if (!this.detailsForm.valid) {
			return;
		}

		let updated: Media | undefined;

		try {
			updated = await this.ucMedia.save(this.media);

			this.edited = false;

			this.toastService.success('Media Saved');

			switch (saveOption?.id) {
				case SaveOptionType.Close:
					this.back();

					return;
				case SaveOptionType.Next: {
					const nextMedia = this.tableManager.getNextItem(updated.id);

					if (!nextMedia) {
						this.back();

						return;
					}

					void this.router.navigate(['..', nextMedia.id], { relativeTo: this.route });

				}
			}

			this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [updated.consoleName]);
			this.titleService.updateTitle(updated.consoleName);

		} catch (error) {
			this.error = ensureUfRequestError(error);
		} finally {
			// Moved it to the final of the code, because if we reload the records, we don't have access to them to get the next (SaveOptionType.Next).
			if (updated) {
				if (this.isPending) {
					this.tableManager.reload.next();
				} else {
					this.tableManager.updateItem.next(updated);
				}
			}
		}
	}

	protected download() {
		window.open(this.media.url);
	}

	protected viewFullSizeImage() {
		void this.modalService.openLarge(ImagePreviewComponent, this.media);
	}

	private async init(id: number) {

		try {
			this.media = await this.ucMedia.getDetail(id);
			this.isPending = !this.media.consoleName;
			this.loading = false;

			if (this.isPending) {
				this.media.title = this.media.filename;
				this.media.consoleName = this.media.filename;
			}

			this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.media.consoleName]);

			this.titleService.updateTitle(this.media.consoleName);

		} catch (e) {
			this.error = useDefaultErrorMessage(e);
		}
	}

	private back() {
		void this.router.navigate(['../'], { relativeTo: this.route });
	}

}
