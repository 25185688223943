import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ToastService, UfControl, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { DataSeed, DataSourceType, Dictionary, UfError, ensureUfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ConsoleDataSource, DataSourceInput, DataSourceInputValueSource, Integration, IntegrationFeature, IntegrationFeatureArgRoot, IntegrationFeatureArgType, IntegrationInfo, IntegrationProvider, IntegrationProviderFeatureType, UcDataSources, UcDataTransactions, UcIntegrations } from 'client';
import { useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { EditData } from 'components/common/edit-data';
import { SaveAndClose, SaveAndNew, SaveAndNext, SaveOption, SaveOptionType } from 'components/common/save-options/save-options.component';
import { reloadCurrentRoute } from 'pages/utils';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { TitleService } from 'services/title.service';

import { TabLabels } from '../constants';
import { DataSeedIdentifiers, DataSourceArgControlKeys, DataSourceControlKeys } from '../model';
import { SettingsComponent } from '../settings.component';

import { DataSourcesTableManager } from './data-sources';
import { DataTransactionsTableManager } from './data-transactions';

interface OutputArg {
	key: string;
	value: any;
	type?: IntegrationFeatureArgType;
	isRequired: boolean;
}

interface InputArg extends OutputArg {
	source: DataSourceInputValueSource;
}

interface BuildOptionsInput {
	output?: IntegrationFeatureArgRoot;
	allowAllTypes?: boolean;
}

const Messages = {
	IntegrationError: 'Failed to get integration',
	InvalidForm: 'Required fields must be completed',
	UnknownError: 'Unknown error',
	Saved: 'Integration saved',
};

const DataSource: ConsoleDataSource = {
	type: DataSourceType.External,
	consoleName: 'New',
	description: '',
	integrationId: null as any,
	featureId: null as any,
	inputMap: {} as Dictionary<DataSourceInput>,
	outputMap: {},
};

const fieldRequiredMessage = 'This field is required';

@Component({
	selector: 'uc-external-call-details',
	templateUrl: './external-call-details.html',
	standalone: false,
})
export class ExternalCallDetailsComponent implements OnInit, OnDestroy, EditData {

	protected readonly sourceTypesInfo: Dictionary<string> = {};
	protected readonly dataSeedOutputLookup: DataSeed = {
		_id: DataSeedIdentifiers.Id,
		_display: DataSeedIdentifiers.Display,
	};
	protected readonly sourceTypes = [DataSourceInputValueSource.Form, DataSourceInputValueSource.Constant, DataSourceInputValueSource.Default, DataSourceInputValueSource.Unset];
	protected readonly filteredSourceTypes = this.sourceTypes.filter((type) => type !== DataSourceInputValueSource.Unset);
	protected readonly integrationFeatureTypes = IntegrationFeatureArgType;
	protected readonly controlKeys = DataSourceControlKeys;

	protected form = new UfControlGroup({
		[DataSourceControlKeys.Integration]: new UfControl(ValidatorFunctions.required(fieldRequiredMessage)),
		[DataSourceControlKeys.Feature]: new UfControl(ValidatorFunctions.required(fieldRequiredMessage)),
		[DataSourceControlKeys.Description]: new UfControl(),
		[DataSourceControlKeys.ConsoleName]: new UfControl(ValidatorFunctions.required(fieldRequiredMessage)),
	});

	protected integrations: IntegrationInfo[];
	protected dataSource: ConsoleDataSource;
	protected integration: Integration;
	protected provider: IntegrationProvider;
	protected features: IntegrationFeature[];
	protected error?: UfError;
	protected breadcrumbs: Breadcrumb[];
	protected isNew: boolean;
	protected outputOptions: string[] = [];
	protected dataSourceDisplayOptions: string[] = [];
	protected isDataTransaction: boolean;

	private dataSourceId: string;
	private subscriptions = new Subscription();
	private parent = inject(SettingsComponent);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private ucIntegrations = inject(UcIntegrations);
	private toast = inject(ToastService);
	private ufb = inject(UfFormBuilder);
	private ucDataSources = inject(UcDataSources);
	private ucDataTransactions = inject(UcDataTransactions);
	private breadcrumbService = inject(BreadcrumbService);
	private builderHeaderService = inject(BuilderHeaderService);
	private titleService = inject(TitleService);
	private tableManager = inject<DataSourcesTableManager | DataTransactionsTableManager>(TableContainerManager);

	get edited() {
		return !!this.builderHeaderService.config?.edited;
	}

	set edited(v: boolean) {
		this.builderHeaderService.config.edited = v;
	}

	protected get feature(): IntegrationFeature | undefined {
		return this.form.get(DataSourceControlKeys.Feature)?.getRawValue() as IntegrationFeature | undefined;
	}

	async ngOnInit() {

		const { isDataTransaction } = this.route.snapshot.data;

		this.isDataTransaction = !!isDataTransaction;

		this.sourceTypesInfo[DataSourceInputValueSource.Form] = 'Configured in Form';
		this.sourceTypesInfo[DataSourceInputValueSource.Unset] = 'No value needed';

		this.dataSourceId = this.route.snapshot.params.id;
		this.isNew = this.dataSourceId === 'new';

		this.builderHeaderService.init();

		try {

			this.integrations = await this.ucIntegrations.list();

			if (this.isNew) {
				this.dataSource = Object.assign({}, DataSource);
			} else {
				await this.loadDataSource();
			}

			this.titleService.updateTitle(`${isDataTransaction? TabLabels.DataTransactions : TabLabels.DataSources} | ${this.dataSource.consoleName}`, true);

			this.subscriptions.add(this.form.statusChanges.subscribe(() => (this.edited = true)));

		} catch (e) {
			this.error = useDefaultErrorMessage(e);

			return;
		}

		// Set breadcrumbs
		this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => void this.save(saveOption)));
		this.buildHeaderConfig(this.dataSource);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();

		this.parent.buildHeaderConfig();
	}

	protected integrationChanged(availableIntegration: IntegrationInfo) {

		this.dataSource.integrationId = availableIntegration.id as string;
		this.form.get(DataSourceControlKeys.Feature)?.setValue(undefined);

		this.ucIntegrations.get(availableIntegration.id as string).then((integration) => {
			this.integration = integration;
			this.provider = integration.provider as IntegrationProvider;
			this.features = this.provider.features
				.filter((feature) =>
					this.filterDisabledInFeatureConfig(
						feature,
						integration,
					),
				);
		}, (error) => {
			this.error = ensureUfError(error);
		});
	}

	protected featureChanged(feature?: IntegrationFeature) {
		if (!feature) {
			return;
		}

		this.dataSource.featureId = feature.id;
		this.updateInputs(feature);
	}

	private async save(saveOption?: SaveOption) {

		if (this.form.invalid) {
			this.form.setSubmitted();
			this.toast.error(Messages.InvalidForm);

			return;
		}

		// disable extra button click
		this.builderHeaderService.busy = true;

		const consoleName = this.form.get(DataSourceControlKeys.ConsoleName)?.value as string;
		const description = this.form.get(DataSourceControlKeys.Description)?.value as string;
		const inputArgs = this.form.get(DataSourceControlKeys.InputArgs)?.getRawValue() as InputArg[];
		const outputArgs = this.form.get(DataSourceControlKeys.OutputArgs)?.getRawValue() as OutputArg[];
		const dataSeedOutputArgs = this.form.get(DataSourceControlKeys.DataSeedOutputArgs)?.getRawValue() as OutputArg[];

		this.dataSource = Object.assign(this.dataSource, { consoleName, description });

		this.dataSource.inputMap = inputArgs.reduce<Dictionary<DataSourceInput>>((map, inputArg) => {
			const res = { value: inputArg.value, source: inputArg.source };

			if (res.source === DataSourceInputValueSource.Form || res.source === DataSourceInputValueSource.Unset) {
				res.value = null as any as string;
			}

			map[inputArg.key] = res;

			return map;
		}, {});

		if (!this.isDataTransaction && dataSeedOutputArgs) {
			outputArgs.push(...dataSeedOutputArgs);
		}

		this.dataSource.outputMap = outputArgs.reduce<Dictionary<string>>((map, outputArg) => ({
			[outputArg.key]: outputArg.value,
			...map,
		}), {});

		try {
			let updatedDataSource;

			if (this.isDataTransaction) {
				updatedDataSource = await this.ucDataTransactions.save(this.dataSource);
			} else {
				updatedDataSource = await this.ucDataSources.save(this.dataSource);
			}

			this.toast.success(Messages.Saved);
			this.edited = false;

			if (this.isNew) {
				this.tableManager.reload.next();
			} else {
				if (!updatedDataSource.id) {
					return;
				}

				const dataSourceInfo = this.tableManager.getItem(updatedDataSource.id);

				if (dataSourceInfo) {
					const {
						lastModifiedAt,
						lastModifiedBy,
					} = updatedDataSource;

					this.tableManager.updateItem.next(Object.assign({}, dataSourceInfo, { consoleName, lastModifiedAt, lastModifiedBy, description }));
				}
			}

			if (!saveOption) {
				if (this.isNew) {
					void this.router.navigate(['..', updatedDataSource.id], { relativeTo: this.route });
				} else {
					this.buildHeaderConfig(updatedDataSource);
				}

				this.titleService.updateTitle(updatedDataSource.consoleName);

				return;
			}

			switch (saveOption.id) {
				case SaveOptionType.New:
					if (this.isNew) {
						reloadCurrentRoute(this.router);

						return;
					} else {
						void this.router.navigate(['../', 'new'], { relativeTo: this.route });

						return;
					}
				case SaveOptionType.Next: {
					const nextId = this.tableManager.getNextItem(updatedDataSource.id)?.id;

					if (nextId) {
						void this.router.navigate(['..', nextId], { relativeTo: this.route });

						return;
					}
					break;
				}
			}

			void this.router.navigate(['..'], { relativeTo: this.route });
		} catch (err) {
			const error = ensureUfError(err);

			this.toast.error(error.message);
		} finally {
			this.builderHeaderService.busy = false;
		}

	}

	private async loadDataSource() {
		try {
			const dataSource: ConsoleDataSource = this.isDataTransaction ?
				await this.ucDataTransactions.get(this.dataSourceId) :
				await this.ucDataSources.get(this.dataSourceId);
			const integration = await this.ucIntegrations.get(dataSource.integrationId);

			this.setup(dataSource, integration);
		} catch (e) {
			this.error = useDefaultErrorMessage(e);

			return;
		}
	}

	private filterDisabledInFeatureConfig(feature: IntegrationFeature, integration: Integration): boolean {
		// filter type type
		const type = this.isDataTransaction ? IntegrationProviderFeatureType.FeatureTypeDataTransaction : IntegrationProviderFeatureType.FeatureTypeLookup;

		if (feature.type !== type) {
			return false;
		}
		// filter enabled (disabled === false)
		if (integration.featureConfig?.[feature.id]?.disabled != null) {
			return integration.featureConfig[feature.id]?.disabled === false;
		}

		// fallback on feature configuration
		return integration.provider.features.find((f) => f.id === feature.id)?.disabled === false;
	}

	private setup(dataSource: ConsoleDataSource, integration: Integration) {

		this.dataSource = dataSource;
		this.integration = integration;
		this.provider = integration.provider as IntegrationProvider;

		if (this.isDataTransaction) {
			this.features = this.provider.features.filter((feature) => feature.type === IntegrationProviderFeatureType.FeatureTypeDataTransaction);
		} else {
			this.features = this.provider.features.filter((feature) => feature.type === IntegrationProviderFeatureType.FeatureTypeLookup);
		}

		const feature = this.features.find((f) => f.id === this.dataSource.featureId);

		this.form.get(DataSourceControlKeys.Integration)?.setValue(this.integration);
		this.form.get(DataSourceControlKeys.Feature)?.setValue(feature);

		if (!feature) {
			throw new UfError(`DataSource feature wasn't found`);
		}

		/**
         * Load existing data-source
         */
		const inputArgs = this.buildInputArgs(feature.input).map((arg) => {

			const inputMap = this.dataSource.inputMap[arg.key];

			if (inputMap) {
				arg.value = inputMap.value;
				arg.source = inputMap.source;
			}

			return arg;
		});

		const dataSeedOutputArgs = this.getOutputArgs(this.dataSource)
		// Remove optional
			.filter((arg) => this.dataSeedOutputLookup[arg.key] != null);

		const outputArgs = this.getOutputArgs(this.dataSource)
		// Remove required
			.filter((arg) => this.dataSeedOutputLookup[arg.key] == null);

		this.outputOptions = this.buildOutputOptions({ output: feature.output });
		this.dataSourceDisplayOptions = this.buildOutputOptions({ output: feature.output, allowAllTypes: false });

		this.updateForm(inputArgs, dataSeedOutputArgs, outputArgs);
	}

	private updateInputs(feature: IntegrationFeature) {

		this.dataSource.consoleName = feature.name;

		this.outputOptions = this.buildOutputOptions({ output: feature.output });
		this.dataSourceDisplayOptions = this.buildOutputOptions({ output: feature.output, allowAllTypes: false });

		const inputArgs: InputArg[] = this.buildInputArgs(feature.input);

		const outputArgs = this.buildOutputArgs(feature.output);

		const dataSeedOutputArgs: OutputArg[] = Object.keys(this.dataSeedOutputLookup)
			.map((key) => {
				const value = feature[this.dataSeedOutputLookup[key] as keyof IntegrationFeature] as string;
				const isRequired = true;

				return { key, value, isRequired };
			});

		this.updateForm(inputArgs, dataSeedOutputArgs, outputArgs);
	}

	private updateForm(inputArgs: InputArg[], dataSeedOutputArgs: OutputArg[], outputArgs: OutputArg[]) {
		this.form.get(DataSourceControlKeys.ConsoleName)?.setValue(this.dataSource.consoleName);
		this.form.get(DataSourceControlKeys.Description)?.setValue(this.dataSource.description);

		this.form.setControl(DataSourceControlKeys.InputArgs, new UfControlArray(inputArgs.map((input) => this.buildInputControlGroup(input))));

		if (this.isDataTransaction) {
			this.form.removeControl(DataSourceControlKeys.DataSeedOutputArgs);
		} else {
			this.form.setControl(DataSourceControlKeys.DataSeedOutputArgs, new UfControlArray(dataSeedOutputArgs.map((output: OutputArg) => this.createResultParamControl(output))));
		}

		this.form.setControl(DataSourceControlKeys.OutputArgs, new UfControlArray(outputArgs.map((output: OutputArg) => this.createResultParamControl(output))));
	}

	private buildInputArgs(input?: IntegrationFeatureArgRoot): InputArg[] {
		if (!input?.attributes) {
			return [];
		}

		const attributes = input.attributes;

		return Object.keys(input.attributes).reduce<InputArg[]>((inputArgs, key) => {
			const inputArg = attributes[key];

			if (inputArg && ![IntegrationFeatureArgType.Object, IntegrationFeatureArgType.List].includes(inputArg.kind) || this.isDataTransaction) {
				inputArgs.push({
					key,
					type: inputArg?.kind,
					isRequired: false,
					value: null as any as string,
					source: DataSourceInputValueSource.Form,
				});
			}

			return inputArgs;
		}, []);
	}

	private buildOutputArgs(output?: IntegrationFeatureArgRoot): OutputArg[] {
		if (!output?.attributes) {
			return [];
		}

		const attributes = output.attributes;

		return Object.keys(output.attributes).reduce<OutputArg[]>((outputArgs, key) => {
			const outputArg = attributes[key];

			if (outputArg && ![IntegrationFeatureArgType.Object, IntegrationFeatureArgType.List].includes(outputArg.kind) || this.isDataTransaction) {
				const value = this.dataSource.outputMap[key] ?? key;

				outputArgs.push({
					key,
					value,
					isRequired: false,
					type: outputArg?.kind,
				});
			}

			return outputArgs;
		}, []);
	}

	private buildOutputOptions({ output, allowAllTypes: allowAllTypes = true }: BuildOptionsInput): string[] {
		const outputOptions = [];

		if (!output?.attributes) {
			return [];
		}

		const attributes = output.attributes;

		for (const key of Object.keys(output.attributes)) {
			const outputArg = attributes[key];

			if (outputArg && [IntegrationFeatureArgType.Object, IntegrationFeatureArgType.List].includes(outputArg.kind) || allowAllTypes) {
				outputOptions.push(key);
			}
		}

		return outputOptions;
	}

	private getOutputArgs(dataSource: ConsoleDataSource): OutputArg[] {

		return Object.keys(dataSource.outputMap)
			.map((key) => {
				const value = this.dataSource.outputMap[key];

				return { key, value, isRequired: false };
			});
	}

	private buildInputControlGroup(input: InputArg): UfControlGroup {

		const valueControl = this.ufb.control(input.value, ValidatorFunctions.custom((v) =>
			input.source !== DataSourceInputValueSource.Constant || !ValidatorFunctions.isEmpty(v), 'A value is required.'));

		const sourceControl = this.ufb.control(input.source, ValidatorFunctions.required('A type is required.'), undefined, { deps: [valueControl] });

		this.subscriptions.add(sourceControl.valueChanges.subscribe((type: DataSourceInputValueSource) =>
			[DataSourceInputValueSource.Constant, DataSourceInputValueSource.Default].includes(type) ?
				valueControl.enable() : valueControl.disable()));

		return this.ufb.group({
			[DataSourceArgControlKeys.Source]: sourceControl,
			[DataSourceArgControlKeys.Value]: valueControl,
			[DataSourceArgControlKeys.Type]: this.ufb.control(input.type ?? IntegrationFeatureArgType.String),
			[DataSourceArgControlKeys.Key]: this.ufb.control({ value: input.key, disabled: true }),
		});
	}

	private createResultParamControl(output?: OutputArg): UfControlGroup {
		return this.ufb.group({
			[DataSourceArgControlKeys.Key]: this.ufb.control(output?.key, ValidatorFunctions.required('A value is required.')),
			[DataSourceArgControlKeys.Value]: this.ufb.control(output?.value, ValidatorFunctions.required('A value is required.')),
		});
	}

	private buildHeaderConfig(dataSource: ConsoleDataSource) {
		if (!dataSource) { return; }

		const saveOptions = [SaveAndClose];

		if (!this.isNew) {
			saveOptions.push(SaveAndNext);
		}
		saveOptions.push(SaveAndNew);

		this.builderHeaderService.buildConfig({
			saveOptions,
			title: dataSource.consoleName,
			lastModifiedAt: dataSource.lastModifiedAt,
			lastModifiedBy: dataSource.lastModifiedBy,
			cancelRoute: ['../'],
			breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [dataSource.consoleName ?? 'New']),
		});
	}

}
