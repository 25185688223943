import { Injectable } from '@angular/core';
import { UfControlGroup } from '@unifii/library/common';

@Injectable()
export class StructureStatus {

	/* Data edited status */
	edited: boolean;

	/* Selected node in the Structure Editor UI */
	selected: UfControlGroup | null;

	/* Root control, also used for data access entry point */
	root: UfControlGroup;

	/* Visibility of hidden nodes in the Structure Editor UI */
	showHiddenNodes = true;

}
