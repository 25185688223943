@if (form) {
	<uf-panel class="content-pane container">
		<div [formGroup]="form" class="uf-container-lg">
			<div class="uf-grid row-gap-lg gap-top">
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Details</div>
					</div>
					<div class="content uf-grid">
						<uf-text [formControlName]="settingsControlKeys.Name" label="Name" class="col-12 pad" />
						<uf-url [formControlName]="settingsControlKeys.PrivacyPolicyUrl" label="Privacy Policy URL" class="col-12 pad" />
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Logo</div>
						@if (form.get(settingsControlKeys.Logo)?.value) {
							<button (click)="deleteLogo()" title="Remove" type="button" class="uf-action tertiary">
								<uf-icon name="backspace" />
							</button>
						}
						@if (!form.get(settingsControlKeys.Logo)?.value) {
							<button (click)="findLogo()" title="Edit" type="button" class="uf-action tertiary">
								<uf-icon name="edit" />
							</button>
						}
					</div>
					<div>
						@if (!form.get(settingsControlKeys.Logo)?.value) {
							<uf-message icon="infoSolid" content="No logo added" class="info col-12 gap" />
						}
						@if (form.get(settingsControlKeys.Logo)?.value) {
							<div class="col-12 checkboard-bg">
								<a [routerLink]="['/assets/media', form.get(settingsControlKeys.Logo)?.value?.id]" target="_blank">
									<img [src]="form.get(settingsControlKeys.Logo)?.value?.url + '?h=120'" alt="project logo" class="thumb" style="max-height: 120px" />
								</a>
							</div>
						}
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Features</div>
					</div>
					<div class="uf-grid">
						<uf-checkbox [formControlName]="settingsControlKeys.Offline" label="Offline" class="col-12 pad" />
						@if (showCompanyProfile) {
							<uf-autocomplete [formControlName]="settingsControlKeys.CompanyBucket" [options]="companyResults" (searchChange)="searchBucket($event)" label="Company Profile Form Data Repository" placeholder="Select a Company Profile Form Data Repository..." class="col-12 pad" />
						}
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Primary Language</div>
					</div>
					<div class="content uf-grid">
						<uf-autocomplete [formControlName]="settingsControlKeys.PrimaryLanguage" [options]="langResults" (searchChange)="searchLang($event)" placeholder="Select a language..." nameProperty="name" class="col-12 pad" />
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Additional Languages</div>
					</div>
					<ul class="uf-list">
						<li>
							<div class="uf-grid grow pad">
								<uf-search-result [options]="langResults" (valueChange)="addLang($event)" (searchChange)="searchLang($event, true)" placeholder="Add a language..." nameProperty="name" class="col-12" />
							</div>
						</li>
						@for (lang of form.get(settingsControlKeys.Languages)?.value; track lang; let i = $index) {
							<li class="row">
								<div class="uf-grid grow pad">
									<uf-text [value]="lang.name" [disabled]="true" label="Name" class="col-6" />
									<uf-text [value]="lang.code" [disabled]="true" label="Code" class="col-6" />
								</div>
								<div class="col center pad-right">
									<button (click)="removeLang(i)" type="button" title="Delete" class="uf-action tertiary">
										<uf-icon name="delete" />
									</button>
								</div>
							</li>
						}
					</ul>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Default Apps</div>
					</div>
					<ul class="uf-list">
						<li class="row">
							<div class="uf-grid grow pad">
								<uf-select [formControlName]="settingsControlKeys.PreviewApp" [options]="allApps" label="Preview App" trackBy="id" class="col-6" />
								<uf-url [formControlName]="settingsControlKeys.PreviewWebUrl" label="Preview Web Url" class="col-6" />
							</div>
						</li>
						<li class="row">
							<div class="uf-grid grow pad">
								<uf-select [formControlName]="settingsControlKeys.LiveApp" [options]="allApps" label="Live App" trackBy="id" class="col-6" />
								<uf-url [formControlName]="settingsControlKeys.LiveWebUrl" label="Live Web Url" class="col-6" />
							</div>
						</li>
					</ul>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar flat accent">
						<div class="title">Tags</div>
						<button (click)="addTag()" title="Add Tag" type="button" class="uf-action tertiary">
							<uf-icon name="add" />
						</button>
					</div>
					@if (form.get(settingsControlKeys.Tags); as tagsControl) {
						<ul class="uf-list">
							@for (tagControl of (tagsControl | asUfControlArray).controls; track tagControl; let i = $index) {
								<li class="row">
									<div class="uf-grid grow pad">
										<uf-text [control]="tagControl | asUfControl" label="Name" class="col-12" />
									</div>
									<div class="col center pad-right">
										<button (click)="removeTag(i)" type="button" title="Delete" class="uf-action tertiary">
											<uf-icon name="delete" />
										</button>
									</div>
								</li>
							}
						</ul>
					}
				</div>
			</div>
		</div>
	</uf-panel>
}
