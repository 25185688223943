import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';
import { hasLengthAtLeast } from '@unifii/sdk';

import { Resource, ResourceElement, ResourcePath, UcResources } from 'client';

import { resourceIterator } from '../resource-functions';

import { PermissionPathSegmentAny, PermissionPathSegmentWildcard, PermissionStepOptionAllDescendants, PermissionStepOptionEndOfPath, ResourcePathsWithDynamicOptions } from './permission-editor-constants';
import { PermissionEditorAction, PermissionEditorSegmentOption, PermissionEditorStep } from './permission-editor-model';

/** Provide support to load/search the step segments options and actions */
export class PermissionEditorStepLoader {

	actions: PermissionEditorAction[] = [];
	// Valorized when the Step doesn't need to load dynamic options from the Resource API
	options: PermissionEditorSegmentOption[] | undefined;
	// Storage for the result of the dynamic options search
	resultOptions: PermissionEditorSegmentOption[] | undefined;

	private pathContainsSomeSegmentWithValueAny: boolean;
	private staticOptions: PermissionEditorSegmentOption[];
	// selected project number in the path so far
	private projectId: number | undefined;
	// selected collection identifier in  the path so far
	private collectionIdentifier: string | undefined;

	constructor(
		private resourcesClient: UcResources,
		private previousSteps: PermissionEditorStep[],
		private resource: Resource,
		public segmentIdentifier: string | undefined,
	) {
		this.calculateStaticOptions();
		this.updateActions();
	}

	updateActions() {

		let resources: Resource[];

		if (this.segmentIdentifier === PermissionPathSegmentWildcard) {
			// Grab children resources
			resources = [...resourceIterator(this.resource)].filter(((r) => r.name !== this.resource.name));
		} else {
			// Grab self
			resources = [this.resource];
		}

		const actions: PermissionEditorAction[] = [];

		for (const res of resources) {
			for (const action of res.actions) {
				let existing = actions.find((a) => a.name === action.name);

				if (!existing) {
					existing = {
						name: action.name,
						descriptions: [],
						selected: false,
						allowsCondition: false,
						allowsReadFields: action.allowsReadFields,
						allowsEditFields: action.allowsEditFields,
					};
					actions.push(existing);
				}
				existing.descriptions.push(action.description);
			}
		}

		this.actions = actions.sort((a, b) => stringsCaseInsensitiveLocalCompare(a.name, b.name));
	}

	async get(id: string): Promise<PermissionEditorSegmentOption | undefined> {
		if (this.options) {
			return this.options.find((option) => option.identifier === id );
		}

		const staticOption = this.staticOptions.find((so) => so.identifier === id);
        
		if (staticOption) {
			return staticOption;
		}

		let element: ResourceElement | undefined;

		try {
			switch (this.resource.path) {
				case ResourcePath.Projects:
					element = await this.resourcesClient.getProject(+id);
					break;
				case ResourcePath.Collections:
					element = this.projectId ? await this.resourcesClient.getCollection(this.projectId, id) : undefined;
					break;
				case ResourcePath.CollectionItems:
					element = this.projectId && this.collectionIdentifier ? await this.resourcesClient.getCollectionItem(this.projectId, this.collectionIdentifier, id) : undefined;
					break;
				case ResourcePath.Views:
					element = this.projectId ? await this.resourcesClient.getView(this.projectId, id) : undefined;
					break;
				case ResourcePath.Pages:
					element = this.projectId ? await this.resourcesClient.getPage(this.projectId, id) : undefined;
					break;
				case ResourcePath.Forms:
					element = this.projectId ? await this.resourcesClient.getForm(this.projectId, id) : undefined;
					break;
				case ResourcePath.FormDataRepositories:
					element = this.projectId ? await this.resourcesClient.getBucket(this.projectId, id) : undefined;
					break;
				case ResourcePath.Tables:
					element = this.projectId ? await this.resourcesClient.getTable(this.projectId, id) : undefined;
					break;
				case ResourcePath.ExternalDataSources:
					element = this.projectId ? await this.resourcesClient.getExternalDataSource(this.projectId, id) : undefined;
					break;
				case ResourcePath.ExternalDataTransactions:
					element = this.projectId ? await this.resourcesClient.getExternalDataTransaction(this.projectId, id) : undefined;
					break;
				case ResourcePath.Companies:
					element = await this.resourcesClient.getCompany(id);
					break;
				case ResourcePath.UserClaims:
					element = await this.resourcesClient.getUserClaim(id);
					break;
				case ResourcePath.Users:
					element = await this.resourcesClient.getUser(id);
					break;
				case ResourcePath.AuthProviders:
					element = await this.resourcesClient.getAuthProvider(id);
					break;
			}
		} catch (e) {
			console.warn(`PermissionEditorStepLoader.get resource type '${this.resource.name}' id '${id}' not found`);
		}

		return element ?
			this.mapToPermissionEditorSegmentOption(element) :
			{ identifier: id, name: id, _notFound: true } as PermissionEditorSegmentOption;
	}

	async load(query: string) {
		if (this.options) {
			return this.options;
		}

		let dynamicResourceElements: ResourceElement[] = [];

		switch (this.resource.path) {
			case ResourcePath.Projects:
				dynamicResourceElements = await this.resourcesClient.getProjects(query);
				break;
			case ResourcePath.Collections:
				dynamicResourceElements = this.projectId ? await this.resourcesClient.getCollections(this.projectId, query) : [];
				break;
			case ResourcePath.CollectionItems:
				dynamicResourceElements = this.projectId && this.collectionIdentifier ? await this.resourcesClient.getCollectionItems(this.projectId, this.collectionIdentifier, query) : [];
				break;
			case ResourcePath.Views:
				dynamicResourceElements = this.projectId ? await this.resourcesClient.getViews(this.projectId, query) : [];
				break;
			case ResourcePath.Pages:
				dynamicResourceElements = this.projectId ? await this.resourcesClient.getPages(this.projectId, query) : [];
				break;
			case ResourcePath.Forms:
				dynamicResourceElements = this.projectId ? await this.resourcesClient.getForms(this.projectId, query) : [];
				break;
			case ResourcePath.FormDataRepositories:
				dynamicResourceElements = this.projectId ? await this.resourcesClient.getBuckets(this.projectId, query) : [];
				break;
			case ResourcePath.ExternalDataSources:
				dynamicResourceElements = this.projectId ? await this.resourcesClient.getExternalDataSources(this.projectId, query) : [];
				break;
			case ResourcePath.ExternalDataTransactions:
				dynamicResourceElements = this.projectId ? await this.resourcesClient.getExternalDataTransactions(this.projectId, query) : [];
				break;
			case ResourcePath.Tables:
				dynamicResourceElements = this.projectId ? await this.resourcesClient.getTables(this.projectId, query) : [];
				break;
			case ResourcePath.Users:
				dynamicResourceElements = await this.resourcesClient.getUsers(query);
				break;
			case ResourcePath.Companies:
				dynamicResourceElements = await this.resourcesClient.getCompanies(query);
				break;
			case ResourcePath.UserClaims:
				dynamicResourceElements = await this.resourcesClient.getUserClaims(query);
				break;
			case ResourcePath.AuthProviders:
				dynamicResourceElements = await this.resourcesClient.getAuthProviders(query);
				break;
		}

		this.resultOptions = [...this.staticOptions, ...dynamicResourceElements.map((o) => this.mapToPermissionEditorSegmentOption(o))];
        
		return this.resultOptions;
	}

	/**
     * Use previous steps values' and this step Resource to calculate the staticOptions
     * For static ResourceType, mirrored the staticOptions to the options, to override the loader
     */
	private calculateStaticOptions() {
        
		this.pathContainsSomeSegmentWithValueAny = this.previousSteps.some((step) => step.segment?.identifier === PermissionPathSegmentAny);

		const terminatorOptions = [PermissionStepOptionEndOfPath, PermissionStepOptionAllDescendants];

		if (
			!this.resource.children.length ||
            (
            	this.resource.children.length === 1 &&
                hasLengthAtLeast(this.resource.children, 1) &&
                this.resource.children[0].segment === PermissionPathSegmentAny &&
                !this.resource.children[0].children.length
            )
		) {
			// Remove option 'All Descendants' when the resource is a leaf
			terminatorOptions.pop();
		}

		const childrenOptions: PermissionEditorSegmentOption[] = this.resource.children.map((child) => {
			if (child.segment === PermissionPathSegmentAny) {
				return { identifier: PermissionPathSegmentAny, name: `Any ${child.name}` };
			}

			return { identifier: child.segment, name: child.name };
		}).sort((a, b) => a.name > b.name ? 1 : -1);

		// Root resource has no terminators
		this.staticOptions = this.resource.path === ResourcePath.Resources ?
			[...childrenOptions] :
			[...terminatorOptions, ...childrenOptions];

		// Static options resources and step with previous segments 'any' will work with static options only
		if (this.pathContainsSomeSegmentWithValueAny || !ResourcePathsWithDynamicOptions.includes(this.resource.path)) {
			this.options = this.staticOptions;

			return;
		}

		// Retrieve previous steps context for dynamic options load
		const projectIdStringValue = this.previousSteps.find((step) => step.resource.path === ResourcePath.Projects)?.segment?.identifier;
        
		this.projectId = projectIdStringValue ? +projectIdStringValue : undefined;
		this.collectionIdentifier = this.previousSteps.find((step) => step.resource.path === ResourcePath.Collections)?.segment?.identifier;
	}

	private mapToPermissionEditorSegmentOption(resource: ResourceElement) {
		return { identifier: resource.id, name: resource.name } as PermissionEditorSegmentOption;
	}

}
