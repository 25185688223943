"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isPlatformBrowser = exports.objectKeys = void 0;
const objectKeys = obj => Object.keys(obj);
exports.objectKeys = objectKeys;
/** Return weather platform is a Browser  */
const isPlatformBrowser = () => typeof window !== 'undefined';
exports.isPlatformBrowser = isPlatformBrowser;
