import { Injectable, inject } from '@angular/core';
import { StorageWrapper, TablePreferences, TablePreferencesProvider } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { USER_PREFERENCES_STORAGE_KEY } from 'constant';

import { ConfigService } from './config.service';
import { ContextService } from './context.service';

export interface UserPreferences {
	tables: Dictionary<TablePreferences>;
}

@Injectable({ providedIn: 'root' })
export class UserPreferencesService implements TablePreferencesProvider {

	private context = inject(ContextService);
	private configService = inject(ConfigService);
	private storage = inject(StorageWrapper);

	get userKey(): string {
		return `${USER_PREFERENCES_STORAGE_KEY}-${this.configService.tenant}-${this.context.account?.id}`;
	}

	get userPreferences(): UserPreferences {

		const raw: string = this.storage.getItem(this.userKey);

		if (!raw) {
			return {
				tables: {},
			} as UserPreferences;
		}

		return JSON.parse(raw);
	}

	set userPreferences(v: UserPreferences) {

		if (v == null) {
			this.storage.removeItem(this.userKey);

			return;
		}

		this.storage.setItem(this.userKey, JSON.stringify(v));
	}

	loadTablePreferences(id: string): TablePreferences | null {
		return this.userPreferences.tables[id] ?? null;
	}

	saveTablePreferences(id: string, v?: TablePreferences) {

		const preferences = this.userPreferences;

		if (v != null) {
			preferences.tables[id] = v;
		} else {
			delete preferences.tables[id];
		}

		this.userPreferences = preferences;
	}

}
