import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterEntries } from '@unifii/library/common';

import { ActivityType } from 'client';
import { ActivityTypeToken, WorkflowActivityTableManager } from 'pages/workflows/workflow-activity-table-manager';
import { formDataFilterFactory } from 'pages/workflows/workflows-filters';

@Component({
	selector: 'uc-workflow-form-data',
	providers: [
		{ provide: FilterEntries, useFactory: formDataFilterFactory },
		{ provide: ActivityTypeToken, useValue: ActivityType.FormData },
		{ provide: TableContainerManager, useClass: WorkflowActivityTableManager },
	],
	template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="add()" ufSyncRoute class="accent pad-none list-md" />
        </div>
        <router-outlet />
    `,
	standalone: false,
})
export class WorkflowFormDataComponent {

	private router = inject(Router);
	private route = inject(ActivatedRoute);

	add() {
		void this.router.navigate(['new'], { relativeTo: this.route });
	}

}
