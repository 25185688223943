import { Component, OnInit, inject } from '@angular/core';
import { DataDisplayListItem, Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { DefinitionPublishState } from '@unifii/sdk';

export interface PublishStateModal {
	title?: string;
	publishState?: DefinitionPublishState;
	lastModified?: string | null;
	lastPublished?: string | null;
}

@Component({
	selector: 'uc-publish-info',
	templateUrl: './publish-info.html',
	standalone: false,
})
export class PublishInfoComponent implements Modal<PublishStateModal, void>, OnInit {

	runtime = inject<ModalRuntime<PublishStateModal, void>>(ModalRuntime);
	data = inject<PublishStateModal>(ModalData);
	modifiedPublishedDescriptionList: DataDisplayListItem[] = [];

	ngOnInit() {
		const data = this.data;

		this.modifiedPublishedDescriptionList = [];
		if (data.lastModified) {
			this.modifiedPublishedDescriptionList.push({ term: 'Last Modified', data: data.lastModified });
		}

		if (data.lastPublished) {
			this.modifiedPublishedDescriptionList.push({ term: 'Last Published', data: data.lastPublished });
		}
	}

}
