import { Injectable, inject } from '@angular/core';
import { ClientDeleteOptions, ClientGetOptions, ClientPostOptions, ClientPutOptions, Device, amendOptionsParams } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { DefaultPaginationParams } from 'constant';

import { App, AppInfo, PushNotification } from './models';
import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class UcApps {

	appAdded = new Subject<App>();
	appModified = new Subject<App>();
	appDeleted = new Subject<App>();

	private client = inject(UcClient);

	get(options?: ClientGetOptions): Promise<AppInfo[]> {
		return this.client.get(this.url(), options) as Promise<AppInfo[]>;
	}

	getDetail(id: string, options?: ClientGetOptions): Promise<App> {
		return this.client.get(this.url(id), options) as Promise<App>;
	}

	getDevices(appId: string, options?: ClientGetOptions): Promise<Device[]> {
		return this.client.get(this.url(appId, 'devices'), amendOptionsParams(DefaultPaginationParams, options)) as Promise<Device[]>;
	}

	save(app: App, options?: ClientPutOptions): Promise<App> {
		return this.client.put(this.url(app.id), app, options) as Promise<App>;
	}

	delete(id: string, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url(id), options) as Promise<void>;
	}

	sendNotifications(id: string, notification: PushNotification, options?: ClientPostOptions): Promise<void> {
		return this.client.post(this.url(id, 'notifications'), { ...options, body: notification }) as Promise<void>;
	}

	private url(...extra: string[]): string {
		const urlParts = ['apps'].concat(extra);

		return this.client.buildUrl(urlParts);
	}

}
