import { Injectable, inject } from '@angular/core';
import { FeatureFlagProvider } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { Config, ConsoleOptions } from 'app-config';

@Injectable()
export class UcFeatureFlagProvider implements FeatureFlagProvider {

	private config = inject<ConsoleOptions>(Config);

	get(): Promise<Dictionary<boolean>> {
		return Promise.resolve(this.config.flags);
	}

}
