import { Injectable, OnDestroy, inject } from '@angular/core';
import { UfControlGroup, UfFormBuilder, ValidatorFunctions, isIdentifiersPathExpression } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { ActivityType, DataForwarder, Integration, IntegrationFeature, UcIntegrations, WorkflowEventType } from 'client';

import { FieldMappingService } from './field-mapping.service';
import { WorkflowDataForwardersModel, WorkflowInputMap } from './workflow-types';

export enum ControlKeys {
	Id = 'id',
	ConsoleName = 'consoleName',
	SourceType = 'sourceType',
	Bucket = 'bucket',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	Integration = 'integration',
	Feature = 'feature',
	ForwardAllData = 'forwardAllData',
	InputMap = 'inputMap',
}

@Injectable({ providedIn: 'root' })
export class WorkflowDataForwardersFormController implements OnDestroy {

	private readonly requiredMessage = 'Field is Required';
	private ufb = inject(UfFormBuilder);
	private ucIntegrations = inject(UcIntegrations);
	private fieldMappingService = inject(FieldMappingService);
	private subscriptions = new Subscription();

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	buildRoot(wfDataForwardersModel?: WorkflowDataForwardersModel): UfControlGroup {

		const groupControl = this.ufb.group({
			[ControlKeys.Id]: [{ value: wfDataForwardersModel?.id, disabled: true }],
			[ControlKeys.ConsoleName]: [wfDataForwardersModel?.consoleName, ValidatorFunctions.required(this.requiredMessage)],
			[ControlKeys.SourceType]: [wfDataForwardersModel?.sourceType, ValidatorFunctions.required(this.requiredMessage)],
			[ControlKeys.Bucket]: [wfDataForwardersModel?.bucket, ValidatorFunctions.required(this.requiredMessage)],
			[ControlKeys.Integration]: [wfDataForwardersModel?.integration, ValidatorFunctions.required(this.requiredMessage)],
			[ControlKeys.Feature]: [{ value: wfDataForwardersModel?.feature, disabled: !wfDataForwardersModel?.integration }, ValidatorFunctions.required(this.requiredMessage)],
			[ControlKeys.ForwardAllData]: { value: !wfDataForwardersModel?.inputMap, disabled: true },
		});

		if (wfDataForwardersModel?.feature && wfDataForwardersModel?.bucket && wfDataForwardersModel?.inputMap) {
			const sourceFields = this.fieldMappingService.getIntegrationFeatureMappableFields(wfDataForwardersModel.feature);

			groupControl.addControl(ControlKeys.InputMap, this.fieldMappingService.buildInputMapControl(sourceFields, wfDataForwardersModel.inputMap));
		}

		this.setSourceType(groupControl, wfDataForwardersModel?.sourceType);

		this.subscriptions.add(groupControl.get(ControlKeys.ForwardAllData)?.valueChanges.subscribe((v) => {
			if (v) {
				groupControl.removeControl(ControlKeys.InputMap);
			} else {
				const feature = groupControl.get(ControlKeys.Feature)?.value as IntegrationFeature | undefined;

				if (!feature) {
					return;
				}

				const targetFields = this.fieldMappingService.getIntegrationFeatureMappableFields(feature);

				groupControl.addControl(ControlKeys.InputMap, this.fieldMappingService.buildInputMapControl(targetFields));
			}
		}));

		this.subscriptions.add(groupControl.get(ControlKeys.Integration)?.valueChanges.subscribe((v) => {
			const featureControl = groupControl.get(ControlKeys.Feature);

			if (!v) {
				featureControl?.disable();
			} else {
				featureControl?.enable();
			}
		}));

		this.subscriptions.add(groupControl.get(ControlKeys.SourceType)?.valueChanges.subscribe((v) => {
			this.setSourceType(groupControl, v);
		}));

		return groupControl;
	}

	async toModel(data: DataForwarder): Promise<WorkflowDataForwardersModel | undefined> {

		if (!data.id) {
			return;
		}

		const { id, bucket, featureId, inputMap, integrationId, consoleName } = data;

		let integration: Integration | undefined;

		if (integrationId) {
			integration = await this.ucIntegrations.get(integrationId);

		}

		let feature: IntegrationFeature | undefined;

		if (featureId && integration) {
			feature = integration.provider.features.find((f) => f.id === featureId);
		}

		if (!feature) {
			throw Error('Integration feature not found');
		}

		let sourceType = data.sourceType;

		// upgrade legacy workflows to show sourceType
		if (!data.sourceType && bucket) {
			sourceType = WorkflowEventType.FormSubmitted;
		}

		let inputMapInfo: WorkflowInputMap[] | undefined;

		if (inputMap && feature.input?.attributes) {

			inputMapInfo = [];
			for (const key of Object.keys(feature.input.attributes)) {
				let expression: string | undefined;
				let field: string | undefined;
				const source = inputMap[key];

				if (!isIdentifiersPathExpression(source)) {
					expression = source;
				} else {
					field = source;
				}

				inputMapInfo.push({
					identifier: key,
					isRequired: false,
					field,
					expression,
				});
			}
		}

		return { id, consoleName, sourceType, bucket: { id: bucket }, integration, feature, inputMap: inputMapInfo } as WorkflowDataForwardersModel;
	}

	toData(model: WorkflowDataForwardersModel): DataForwarder {
		const { id, consoleName, sourceType, integration, feature, inputMap: inputMapInfo } = model;

		let bucketId;

		if (model.bucket) {
			bucketId = model.bucket.id;
		}

		let inputMap;

		if (inputMapInfo) {
			inputMap = (inputMapInfo ?? []).reduce((acc, ip) => ({ ...acc, [ip.identifier]: ip.expression ?? ip.field }), {});
		}

		return {
			id,
			consoleName,
			bucket: bucketId,
			sourceType,
			integrationId: integration.id,
			featureId: feature.id,
			inputMap,
			type: ActivityType.DataForwarder,
		} as DataForwarder;
	}

	private setSourceType(groupControl: UfControlGroup, type?: WorkflowEventType) {
		switch (type) {
			case WorkflowEventType.FormSubmitted:
				groupControl.get(ControlKeys.Bucket)?.enable();
				break;
			default:
				groupControl.get(ControlKeys.Bucket)?.disable();
				groupControl.get(ControlKeys.Bucket)?.reset();
		}
	}

}
