import { Component, EventEmitter, Input, OnInit, inject } from '@angular/core';
import { UfControl } from '@unifii/library/common';
import { FormField } from '@unifii/library/smart-forms';
import { CompoundType } from '@unifii/sdk';

import { BuilderService } from 'components/compound-builder/builder.service';

@Component({
	selector: 'uc-markdown-wrapper',
	templateUrl: './uc-markdown-wrapper.html',
	standalone: false,
})
export class UcMarkdownWrapperComponent implements FormField, OnInit {

	@Input() field: any;
	@Input() control: UfControl;
	@Input() disabled: boolean;

	content: any;
	showAlignmentOptions: boolean;
	contentChange?: EventEmitter<any>;
    
	private builderService = inject(BuilderService);

	ngOnInit() {
		this.showAlignmentOptions = this.builderService.builder.type === CompoundType.Page;
	}

	protected valueChanged(value: string) {
		this.contentChange?.emit(value);
	}

}
