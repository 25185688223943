import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Breadcrumb, ModalService, ToastService, UfControl, UfControlGroup, UfFormBuilder, WindowWrapper } from '@unifii/library/common';
import { ErrorType, PermissionAction, UfError, ensureUfRequestError } from '@unifii/sdk';
import { LockedConfig, UserAuthProviderInfo, UserFormContext, UserFormResourceType, UserKeys, UserUpdateMeFormControl, UserValidatorsService } from '@unifii/user-provisioning';
import { Subscription, filter, interval, switchMap } from 'rxjs';

import { SystemRole, TenantSettings, UcClient, UcUserInfo, UcUsers } from 'client';
import { ConflictModalComponent, EditData } from 'components';
import { ConflictDetectionInterval } from 'constant';
import { reloadCurrentRoute, sortGroupControlValue } from 'pages/utils';
import { ContextService } from 'services/context.service';

import { IsSuperUserControl, buildUserDataForm } from './user-utils';

@Component({
	selector: 'uc-my-account',
	templateUrl: './my-account.html',
	styleUrls: ['./my-account.less'],
	standalone: false,
})
export class MyAccountComponent implements OnInit, EditData, OnDestroy {

	@HostBinding('class.stretch-component') stretch = true;

	edited: boolean;

	protected error?: UfError;
	protected form: UfControlGroup;
	protected breadcrumbs: Breadcrumb[];
	protected userInfo: UcUserInfo;
	protected lockedConfig?: LockedConfig;
	protected userAuthProviders?: UserAuthProviderInfo[];
	protected connectToExternal: boolean;
	protected tenant: TenantSettings;

	private superUserControl: UfControl;
	private subscriptions = new Subscription();
	private revisionSubscription: Subscription | undefined;
	private router = inject(Router);
	private ufb = inject(UfFormBuilder);
	private ucUsers = inject(UcUsers);
	private window = inject(WindowWrapper);
	private ucClient = inject(UcClient);
	private toast = inject(ToastService);
	private context = inject(ContextService);
	private userFormContext = inject(UserFormContext);
	private controller = inject(UserUpdateMeFormControl);
	private userValidatorService = inject(UserValidatorsService);
	private modalService = inject(ModalService);
	private isContextUserManager = this.context.checkRoles(SystemRole.UserManager);
	private isContextSuperUser = this.context.checkRoles(SystemRole.SuperUser);

	async ngOnInit() {
		this.userFormContext.set(UserFormResourceType.Me, PermissionAction.Update);
		this.tenant = this.context.tenantSettings as TenantSettings;

		if (!this.context.account?.id) {
			return;
		}

		this.userInfo = await this.ucUsers.getDetails(this.context.account.id);
		const userDataForm = await buildUserDataForm(this.ucUsers, this.userInfo);

		this.lockedConfig = userDataForm.lockedConfig;
		this.userAuthProviders = userDataForm.userAuthProviders;

		this.initForm(this.userInfo);

		this.breadcrumbs = [{ name: 'My Account' }];

		this.initConflictDetection();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.revisionSubscription?.unsubscribe();
	}

	protected async save() {
		this.form.setSubmitted();
		this.superUserControl.setSubmitted();

		if (this.form.invalid
            || this.superUserControl.enabled && this.superUserControl.invalid) {
			this.toast.error('There are errors in this form');

			return;
		}

		const user: UcUserInfo = this.controller.toDataModel(this.form, this.userInfo);

		if (this.superUserControl.value) {
			user.systemRoles?.push(SystemRole.SuperUser);
		}

		try {
			await this.ucUsers.save(user);
			this.context.account = await this.ucClient.getMyAccount();
			this.toast.success('User details saved!');
			this.edited = false;

			// Reload page
			reloadCurrentRoute(this.router);

		} catch (e) {
			const error = ensureUfRequestError(e, 'Failed to save');

			if (error.type === ErrorType.Conflict) {
				void this.onConflictDetected();

				return;
			}

			this.error = error;

			if (this.error.type === ErrorType.Validation && this.error.message === 'Invalid password') {
				this.error.message = 'Incorrect current password';
			}

			this.toast.error(this.error.message);
		}
	}

	private initForm(user: UcUserInfo) {

		const form = this.controller.buildRoot({ user, lockedConfig: this.lockedConfig, userAuthProvidersInfo: this.userAuthProviders });
		const systemRolesControl = form.get(UserKeys.SystemRoles) as UfControl | undefined;

		form.get(UserKeys.Roles)?.removeValidators(this.userValidatorService.rolesAtLeastOne);

		if (!this.isContextUserManager) {
			form.get(UserKeys.Username)?.disable();
			form.get(UserKeys.Email)?.disable();
			form.get(UserKeys.FirstName)?.disable();
			form.get(UserKeys.LastName)?.disable();
			form.get(UserKeys.Phone)?.disable();
			form.get(UserKeys.Manager)?.disable();
			form.get(UserKeys.Company)?.disable();
			form.get(UserKeys.Claims)?.disable();
			form.get(UserKeys.Roles)?.disable();
			form.removeControl(UserKeys.Password);
			form.removeControl(UserKeys.OldPassword);
			form.removeControl(UserKeys.ChangePasswordOnNextLogin);
			form.removeControl(UserKeys.LastActivationReason);
		}

		const isSuperUser = !!(systemRolesControl?.value as SystemRole[] | undefined)?.includes(SystemRole.SuperUser);

		// system roles logic
		if (this.isContextUserManager && !isSuperUser) {
			systemRolesControl?.enable();
		} else {
			systemRolesControl?.disable();
		}

		if (isSuperUser) {
			systemRolesControl?.setValue(systemRolesControl.value.filter((r: SystemRole) => r !== SystemRole.SuperUser));
		}

		this.superUserControl = this.ufb.control({
			value: isSuperUser,
			disable: !this.isContextSuperUser,
		});

		form.addControl(IsSuperUserControl, this.superUserControl);

		this.subscriptions.add(
			form.statusChanges.pipe(filter(() => !form.pristine)).subscribe(() => (this.edited = true)),
		);

		this.form = form;

		sortGroupControlValue(this.form, UserKeys.Roles);
	}

	private initConflictDetection() {
		this.revisionSubscription?.unsubscribe();
		this.revisionSubscription = interval(ConflictDetectionInterval).pipe(
			switchMap(async() => await this.ucUsers.hasConflict(this.userInfo)),
			filter((conflict) => conflict),
		).subscribe(() => void this.onConflictDetected());
	}

	private async onConflictDetected() {
		this.revisionSubscription?.unsubscribe();

		const result = await this.modalService.openMedium(ConflictModalComponent, { edited: this.edited }, { guard: true });

		if (!result) {
			return;
		}

		switch (result) {
			case 'Discard':
				reloadCurrentRoute(this.router);
				break;
			case 'OpenNewTab': {
				const openFn = this.window.open.bind(this.window);

				openFn(location.href);
				break;
			}
		}
	}

}
