import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { UfControlArray, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { CompoundType, Field, FieldType, Option } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ItemPickerInfo } from 'components';
import { FieldReferenceHelper } from 'helpers/field-reference-helper';
import { truncateStringWithEllipsis } from 'pages/utils';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { FORM_EDITOR_CONSTANTS } from './form-editor-constants';
import { FieldControlKeys } from './form-editor-control-keys';
import { FormEditorFunctions } from './form-editor-functions';
import { FormEditorField, FormFieldMetadata } from './form-editor-model';
import { FormEditorStatus } from './form-editor-status';
import { FormEditorService } from './form-editor.service';

@Component({
	selector: 'uc-form-field',
	templateUrl: './form-field.html',
	styles: [`:host { display: block; content-visibility: auto; }`],
	standalone: false,
})
export class FormFieldComponent implements OnInit, OnDestroy {

	@Input() control?: UfControlGroup;
	@Output() expandAll = new EventEmitter<boolean>();

	protected status = inject(FormEditorStatus);
	protected service = inject(FormEditorService);
	protected fields: UfControlArray;
	protected icon: string;
	protected label: string;
	protected meta: FormFieldMetadata;
	protected isStepper = false;
	protected isRoot = false;

	private templateOptions: Option[];
	private ufFormBuilder = inject(UfFormBuilder);
	private context = inject(ContextService);
	private dialogs = inject(DialogsService);
	private field: FormEditorField;
	private subscriptions = new Subscription();

	protected get isSelected(): boolean {

		// check selected root
		if (this.isRoot) {
			return !this.status.selected;
		}

		return this.control === this.status.selected;
	}

	ngOnInit() {
		if (this.control) {
			this.meta = FormEditorFunctions.controlMetadata(this.control, this.context);
			this.fields = this.control.get(FieldControlKeys.Fields) as UfControlArray;
			this.onFieldChange(this.control.value);
			this.subscriptions.add(this.control.valueChanges.subscribe(this.onFieldChange.bind(this)));

			return;
		}

		this.isRoot = true;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected select(event: Event) {
		event.stopPropagation();
		this.status.selected = this.control ?? null;
	}

	protected async remove(event: Event) {
		event.stopPropagation();

		if (!this.control) {
			return;
		}

		if (!await this.dialogs.confirmDelete()) {
			return;
		}

		this.ufFormBuilder.detach(this.control);
		this.service.fieldRemoved(this.control);
	}

	protected expand(event: Event) {
		event.stopPropagation();
		this.expandAll.next(true);
	}

	protected collapse(event: Event) {
		event.stopPropagation();
		this.expandAll.next(false);
	}

	protected async addStepperPage() {
		if (!this.control) {
			return;
		}
		const element: ItemPickerInfo<Field>= {
			id: 'Step',
			label: 'Stepper Page',
			icon: 'stepper',
			marker: 'FormEditorItemPickerInfo',
		};

		const control = await this.service.addConverter(element, this.fields);

		this.fields.push(control);
	}

	private onFieldChange(value: FormEditorField) {
		this.field = value;
		this.icon = FieldReferenceHelper.getFieldReference(this.field, CompoundType.Form).icon;
		this.templateOptions = FormEditorFunctions.fieldTemplateOptions(this.field);
		this.isStepper = this.field.type === FieldType.Stepper;
		this.label = this.getLabel();

	}

	private getLabel(): string {

		let label: string;

		switch (this.field.type) {
			case FieldType.Content:
				label = this.field.help ?? this.field.type;
				break;
			case FieldType.Separator:
				label = `Separator (${this.templateOptions.find((o) => o.identifier === this.field.template as string | undefined)?.name})`;
				break;
			case FieldType.Carousel:
				label = `Content Carousel`;
				break;
			default:
				label = this.field.label ?? '';
				break;
		}

		return truncateStringWithEllipsis(label, FORM_EDITOR_CONSTANTS.FIELD_RENDERED_LABEL_MAX_LENGTH);
	}

}
