import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RuntimeDefinition, RuntimeDefinitionAdapter } from '@unifii/library/common';
import { FormRevisionStorage } from '@unifii/library/smart-forms';
import { Client, ContentClient, FormData, FormDataClient, TenantClient, UfError, UserContext, mapUserToUserContext } from '@unifii/sdk';

import { MyAccount, UcRoles } from 'client';
import { useDefaultErrorMessage } from 'components';
import { ContextService } from 'services/context.service';

export interface FormDataAndRuntimeDefinitionAndPdfUrl {
	formData: FormData;
	runtimeDefinition: RuntimeDefinition;
	printPdfUrl: string;
}

// formDataComponentResolver interface
export interface FormDataComponentData {
	user: UserContext;
	revisionsEntries: FormDataAndRuntimeDefinitionAndPdfUrl[];
	preview: boolean;
}

// Route data interface
export interface FormDataComponentRouteData {
	title: string;
	preview?: boolean;
}

export const formDataComponentResolver = async(route: ActivatedRouteSnapshot): Promise<FormDataComponentData | UfError> => {
	const contextService = inject(ContextService);
	const ucRoles = inject(UcRoles);
	const adapter = inject(RuntimeDefinitionAdapter);
	const client = inject(Client);
	const tenantClient = inject(TenantClient);

	const projectId = contextService.project?.id as string;
	const bucket = contextService.bucketId as string;
	const preview = !!(route.data as FormDataComponentRouteData).preview;

	const revisionStorage = new FormRevisionStorage(client, { projectId, preview });
	const content = new ContentClient(client, tenantClient, { projectId, preview });
	const formDataClient = new FormDataClient(client, { projectId, preview, bucket });

	try {
		const user = await getUserContext(contextService, ucRoles);
		const revisionsEntries = await getRevisionsEntries(route, formDataClient, content, revisionStorage, adapter);

		return { user, revisionsEntries, preview };
	} catch (e) {
		return useDefaultErrorMessage(e);
	}
};

const getRevisionsEntries = async(
	route: ActivatedRouteSnapshot,
	formDataClient: FormDataClient,
	content: ContentClient,
	revisionStorage: FormRevisionStorage,
	adapter: RuntimeDefinitionAdapter,
) => {

	const formDataId = route.params.id as string;
	const formData = await formDataClient.get(formDataId);
	const definitionIdentifier = formData._definitionIdentifier as string;

	await revisionStorage.loadRevisionsByFormData(formData);

	const revisions = revisionStorage.revisions ?? [];
	const definitionByVersion = new Map<number, RuntimeDefinition>();
	const entries: FormDataAndRuntimeDefinitionAndPdfUrl[] = [];

	for (const revision of revisions) {
		const revisionVersion = revision._definitionVersion as number;

		if (!definitionByVersion.get(revisionVersion)) {
			definitionByVersion.set(
				revisionVersion,
				await adapter.transform(await content.getForm(definitionIdentifier, revisionVersion)),
			);
		}

		entries.push({
			formData: revision,
			runtimeDefinition: definitionByVersion.get(revisionVersion) as RuntimeDefinition,
			printPdfUrl: formDataClient.getPrintPDFUrl(formDataId, revision._rev),
		});
	}

	return entries;
};

const getUserContext = async(contextService: ContextService, ucRoles: UcRoles) => {

	const accountCopy: MyAccount =
        JSON.parse(JSON.stringify(contextService.account)) as MyAccount ??
        { id: '', username: 'user', roles: [] };

	const roles = await ucRoles.get(undefined, undefined, { params: { limit: 1000 } });
	const rolesName = roles.map((role) => role.name);

	return mapUserToUserContext(Object.assign({}, accountCopy, {
		hasPassword: false,
		isExternal: false,
		isActive: false,
		roles: rolesName,
	})) as UserContext;
};
