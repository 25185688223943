@if (isMfaOptionalControl || mfaControl) {
	<div [ngClass]="cssClasses" class="uf-box">
		<div class="uf-app-bar flat accent">
			<div class="title">
				{{ commonTK.MfaLabel | translate }}
			</div>
			@if (mfaControl?.enabled) {
				<button (click)="clearMfa()" type="button" class="uf-button">Clear Mfa</button>
			}
		</div>
		<div class="uf-grid pad">
			@if (isMfaOptionalControl) {
				<uf-checkbox [label]="commonTK.MfaOptionalLabel | translate" [control]="isMfaOptionalControl" class="col-12" />
			}
			@if (mfaControl) {
				<ul [formGroup]="mfaControl" class="uf-list uf-box flat col-12">
					@if (mfaControl.get(userMfaInfoKeys.VirtualCode)) {
						<li class="pad-left pad">
							<uf-virtual-mfa-code-input [formControlName]="userMfaInfoKeys.VirtualCode" [selfSetup]="selfSetup" (setupVirtualMfa)="setupVirtualMfa()" (valueChange)="refresh()" class="col-12" />
						</li>
					}
					@if (mfaControl.get(userMfaInfoKeys.IsSmsEnabled)) {
						<li class="pad-left pad">
							<uf-sms-input [formControlName]="userMfaInfoKeys.IsSmsEnabled" [selfSetup]="selfSetup" (setupSms)="setupSms()" (valueChange)="refresh()" class="col-12" />
						</li>
					}
					@if (mfaControl.get(userMfaInfoKeys.VirtualCode)) {
						<li class="pad-left pad">
							<uf-recovery-codes-input [formControlName]="userMfaInfoKeys.HasRecoveryCodes" [selfSetup]="selfSetup" (setupRecoverCodes)="setupRecoverCodes()" (valueChange)="refresh()" class="col-12" />
						</li>
					}
					@if (mfaControl.get(userMfaInfoKeys.Devices); as devicesControl) {
						@for (deviceControl of (devicesControl | asUfControlArray).controls; track deviceControl) {
							<li class="pad-left pad">
								<uf-device-input [control]="deviceControl | asUfControlGroup" (removeDevice)="removeDevice($event)" (valueChange)="refresh()" class="col-12" />
							</li>
						}
						@if (selfSetup) {
							<li class="pad-left pad">
								<uf-device-mfa-add (setupDevice)="setupDevice()" />
							</li>
						}
					}
				</ul>
			}
		</div>
	</div>
}
