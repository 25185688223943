<form [formGroup]="form" (ngSubmit)="add()" class="uf-form-card">
	<div class="uf-app-bar">
		<button (click)="runtime.close()" type="button" class="uf-action tertiary">
			<uf-icon name="close" />
		</button>
		<h3>{{ data.id ? "Edit" : "Add" }}</h3>
	</div>
	<div class="uf-grid pad gaps">
		<uf-text [formControlName]="unitControlKeys.Label" ufAutofocus label="Title" class="col-12" />
		<uf-text [formControlName]="unitControlKeys.Id" [maxLength]="identifierMaxLength" label="Identifier" class="col-12" />
	</div>
	<div class="uf-form-actions small">
		<button (click)="runtime.close()" type="button" class="uf-button tertiary right">Cancel</button>
		<button type="submit" class="uf-button primary">Save</button>
	</div>
</form>
