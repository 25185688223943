import { TableDataSource } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { DataSourceInfo, UcDataTransactions } from 'client';

export class DataTransactionsTableDataSource extends TableDataSource<DataSourceInfo> {

	sorted: boolean;

	constructor(
		private service: UcDataTransactions,
		private search?: string,
		private status?: string,
	) {
		super();
	}

	get filtered(): boolean {
		return (this.search && this.search.trim().length > 0) || this.status != null;
	}

	load() {
		this.service.list(this.search, { analytics: { origin: RequestAnalyticsOrigin.Table } })
			.then((data) => {
				this.stream.next({ data });
			}, (error) => {
				this.stream.next({ error });
			});
	}

}
