@if (ready) {
	<uf-expander class="uf-box flat gap-sm-bottom">
		<div expanderHeader class="uf-app-bar flat">
			<div class="title primary">Tables</div>
			@if (control.invalid) {
				<uf-icon name="error" class="error" />
			}
			@if (control.length) {
				<uc-expander-controls [container]="tablesWrap" />
			}
		</div>
		<div #tablesWrap expanderBody class="pad small">
			@if (!control.disabled) {
				<uf-search-result [options]="searchResults" [nameProperty]="mapDescription" (valueChange)="add($event)" (searchChange)="search($event)" label="Tables" placeholder="Search Tables" class="col-12 pad-bottom" />
			}
			@if (control.length) {
				<uc-drag-list [items]="control.controls" canDrop="false" canReorder="true">
					@for (table of control.controls | asUfControlsGroups; track table; let i = $index) {
						@let tableDescription = tableDescriptions[getIdentifier(table) ?? ""];
						<uf-expander [dragDisabled]="table.disabled" dragItem class="uf-box flat draggable gap-sm-bottom">
							<div expanderHeader dragHandle class="uf-app-bar flat">
								<div class="title primary">
									{{ tableDescription?.title }}
								</div>
								@if (table.invalid) {
									<uf-icon name="error" class="error" />
								}
								@if (tableDescription?.routerLink) {
									<a [routerLink]="tableDescription?.routerLink" target="_blank" class="uf-action tertiary" title="Open">
										<uf-icon name="open" />
									</a>
								}
								@if (!table.disabled) {
									<button (click)="remove(i)" type="button" title="Delete" class="uf-action tertiary">
										<uf-icon name="delete" />
									</button>
								}
							</div>
							<div [formGroup]="table" expanderBody class="uf-grid pad">
								@if (tableDescriptions[getIdentifier(table) ?? ""]; as tableDescription) {
									<div class="uf-grid col-12">
										<uf-data-display-list class="col-12">
											<dt>Identifier</dt>
											<dd>{{ tableDescription.identifier }}</dd>
										</uf-data-display-list>
										<uf-number [formControlName]="formKeys.PageSize" label="Rows" precision="0" class="col-12" />
										<!-- TODO: Replace this to formControlName when UNIFII-7632 is fixed -->
										@let roleControl = table.get(formKeys.Roles);
										@if (roleControl) {
											<uf-chips [control]="roleControl | asUfControl" [options]="rolesResults" (searchChange)="findRoles($event)" label="Restrict to Roles" placeholder="Search Roles" class="col-12" />
										}
									</div>
								} @else {
									<uf-message [content]="'Table \'' + getIdentifier(table) + '\' not found'" class="error col-12" />
								}
							</div>
						</uf-expander>
					}
				</uc-drag-list>
			}
		</div>
	</uf-expander>
}
