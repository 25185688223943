import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ModalService, ToastService } from '@unifii/library/common';
import { ensureUfError } from '@unifii/sdk';

import { UcClient } from 'client';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { LimitService } from 'services/limit.service';

import { NewProjectModalComponent } from './new-project-modal.component';
import { ProjectTableManager } from './project-table-manager';

@Component({
	selector: 'uc-projects',
	template: `
        <uf-table-container (addItem)="addProject()" ufSyncRoute class="cards">
            <uf-breadcrumbs [breadcrumbs]="breadcrumbs" title class="large" />
        </uf-table-container>
    `,
	providers: [
		{ provide: TableContainerManager, useClass: ProjectTableManager },
	],
	standalone: false,
})
export class ProjectsComponent implements OnInit {

	@HostBinding('class.stretch-component') class = true;

	protected breadcrumbs = inject(BreadcrumbService).getBreadcrumbs(inject(ActivatedRoute));

	private modalService = inject(ModalService);
	private toastService = inject(ToastService);
	private ucClient = inject(UcClient);
	private limitService = inject(LimitService);
	private context = inject(ContextService);
	private tableManager = inject<ProjectTableManager>(TableContainerManager);

	ngOnInit() {
		this.context.project = null;
	}

	async addProject() {
		try {
			const projects = await this.tableManager.getProjects();

			if (!this.limitService.canAddProject(projects)) {
				this.toastService.warning('Project limit reached');

				return;
			}

			const projectName = await this.modalService.openMedium(NewProjectModalComponent);

			if (!projectName) {
				return;
			}

			await this.ucClient.addProject(projectName);
			this.toastService.success('Project added');
			this.tableManager.refreshList();
		} catch (e) {
			const error = ensureUfError(e);

			this.toastService.error(error.message);
		}
	}

}
