import { Injectable } from '@angular/core';
import { Interceptor, isAbortError } from '@unifii/sdk';

@Injectable()
export class ConsoleInterceptor implements Interceptor {

	intercept<T>(response: Promise<T>): Promise<T> {

		return response.catch((err) => {

			console.warn('ConsoleInterceptor.catch', err);

			if (isAbortError(err)) {
				console.warn('ConsoleInterceptor.catch - User aborted operation', err);

				return Promise.resolve(null as T);
			}

			throw err;
		});
	}

}
