<div class="header">
	<button (click)="close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>{{ data.title }}</h3>
</div>

<uf-panel class="container">
	<div class="pad-sides">
		<ul class="uf-list">
			@for (option of data.types; track option) {
				<li (click)="close(option)" class="uf-list-button">
					@if (option.logo) {
						<div class="uc-image gap-right">
							<img [src]="option.logo | bypassSanitizeUrl" />
						</div>
					}
					@if (option.icon) {
						<uf-icon [name]="option.icon" />
					}
					<div>{{ option.label }}</div>
				</li>
			}
		</ul>
	</div>
</uf-panel>
