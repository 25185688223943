@if (!errorMessage) {
	<uf-table-container [class.pad-none]="stretchedContainer" (addItem)="addPermission()" class="accent list-md">
		@if (!readonly) {
			<ng-container actions>
				<button (click)="paste()" title="Paste" type="button" class="uf-action tertiary">
					<uf-icon name="paste" />
				</button>
			</ng-container>
		}
	</uf-table-container>
}
@if (errorMessage) {
	<uf-message [content]="errorMessage" icon="errorSolid" class="error gap-top" />
}
