import { Injectable, Type } from '@angular/core';
import { ComponentRegistry } from '@unifii/library/smart-forms';
import { UfFormComponentRegistry } from '@unifii/library/smart-forms/input';
import { FieldType } from '@unifii/sdk';

import { FileListComponent, GroupInputComponent, ImageListComponent, LinkComponent, LinkListComponent, SoundListComponent, VideoListComponent } from './content';
import { UcMarkdownWrapperComponent } from './form/_index';

// Used by CollectionBuilder and CollectionItemBuilder
@Injectable()
export class MarkdownEditorRegistry extends UfFormComponentRegistry implements ComponentRegistry {

	override get(name: string): Type<any> {

		switch (name) {
			case FieldType.MultiText:
				return UcMarkdownWrapperComponent;
			case FieldType.ImageList:
				return ImageListComponent;
			case FieldType.SoundList:
				return SoundListComponent;
			case FieldType.VideoList:
				return VideoListComponent;
			case FieldType.FileList:
				return FileListComponent;
			case FieldType.LinkList:
				return LinkListComponent;
			case FieldType.Link:
			case FieldType.DefinitionLink:
				return LinkComponent;
			case FieldType.Address:
			case FieldType.GeoLocation:
				return GroupInputComponent;
			default:
				return super.get(name);
		}
	}

}
