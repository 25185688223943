import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterEntries } from '@unifii/library/common';

import { ActivityType } from 'client';

import { ActivityTypeToken, WorkflowActivityTableManager } from './workflow-activity-table-manager';
import { dataForwarderFilterFactory } from './workflows-filters';

@Component({
	selector: 'uc-data-forwarders',
	template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="add()" ufSyncRoute class="accent pad-none list-md" />
        </div>
        <router-outlet />
    `,
	providers: [
		{ provide: FilterEntries, useFactory: dataForwarderFilterFactory },
		{ provide: ActivityTypeToken, useValue: ActivityType.DataForwarder },
		{ provide: TableContainerManager, useClass: WorkflowActivityTableManager },
	],
	standalone: false,
})
export class DataForwardersComponent {

	private router = inject(Router);
	private route = inject(ActivatedRoute);

	add() {
		void this.router.navigate(['new'], { relativeTo: this.route });
	}

}
