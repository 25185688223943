@if (ready) {
	<div class="pad uf-grid">
		@if (form.get(controlKeys.Label); as control) {
			<uf-text [control]="control | asUfControl" label="Label" placeholder="Override default label" class="col-12" />
		}
		@if (form.get(controlKeys.Format); as control) {
			<uf-select [control]="control | asUfControl" [options]="formatOptions" label="Format" placeholder="Override default format" valueProperty="_id" nameProperty="_display" class="col-12" />
		}
		@if (form.get(controlKeys.InputType); as control) {
			<uf-select [control]="control | asUfControl" [placeholder]="dateTimePlaceholder" [options]="dataTimeOptions" label="Input type" valueProperty="_id" nameProperty="_display" class="col-12" />
		}
		@if (form.get(controlKeys.Sort); as control) {
			<uc-sort-property-input [control]="control | asUfControl" [properties]="sortProperties" label="Sort" class="col-12" />
		}
		@if (form.get(controlKeys.Roles); as control) {
			<uf-chips [control]="control | asUfControl" [options]="roleResults" (searchChange)="findRoles($event)" label="Restrict to" placeholder="Search roles" class="col-12" />
		}
	</div>
}
