<div class="uf-form-card">
	<div class="uf-app-bar">
		<button (click)="runtime.close()" type="button" class="uf-action tertiary" title="Close">
			<uf-icon name="close" />
		</button>
		<h3 style="text-align: center">{{ data.title }}</h3>
	</div>
	<div class="grow">
		<div class="uf-grid pad gaps">
			@if (data.publishState) {
				<div [ngClass]="data.publishState | publishedStatusColour" class="col-12 uf-lozenge state-lozenge left right">
					{{ data.publishState }}
				</div>
			}
			@if (modifiedPublishedDescriptionList) {
				<uf-data-display-list [items]="modifiedPublishedDescriptionList" class="col-12 stacked-center left right" />
			}
		</div>
	</div>
</div>
