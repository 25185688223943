import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'uc-unauthorized',
	templateUrl: './unauthorized.html',
	standalone: false,
})
export class UnauthorizedComponent {

	@HostBinding('class.stretch-component') class = true;

}
