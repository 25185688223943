import { Pipe, PipeTransform } from '@angular/core';
import { DataSourceType } from '@unifii/sdk';

@Pipe({
	name: 'dataSourceTypeLabel',
	standalone: false,
})
export class DataSourceTypeLabelPipe implements PipeTransform {

	transform(value?: DataSourceType | null): string {

		if (!value) {
			return '';
		}

		switch (value) {
			case DataSourceType.Named: return 'Custom';
			case DataSourceType.Bucket: return 'Form Data';
			case DataSourceType.UserClaims: return 'User Claims';
			default: return value;
		}

	}

}
