<div class="header">
	<button (click)="close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>Image crop</h3>
</div>

<div #container class="uf-grid grow checkboard-bg">
	@if (!ready) {
		<uf-spinner class="col-12" type="dots" colour="#177e89" />
	}
	<div class="col-12">
		@if (ready) {
			<uc-image-cropper [src]="image.url" [minWidth]="minWidth" [minHeight]="minHeight" [enabled]="enabled" [size]="canvasSize" [(value)]="value" class="col center-all justify-center" />
		}
	</div>
</div>

<div class="row space-children pad">
	<div class="row grow center-all">
		@if (!enabled) {
			<p class="warning-text left">This image is too small for cropping</p>
		}
		@if (ready) {
			<span>Original size {{ image.width }}x{{ image.height }}</span>
		}
		@if (ready && value) {
			<span> Crop [ X:{{ value.x }}, Y:{{ value.y }}, W:{{ value.width }}, H:{{ value.height }}] </span>
		}
	</div>
	@if (enabled) {
		<button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
	}
	@if (ready && value) {
		<button (click)="remove()" type="button" class="uf-button">Clear</button>
	}
	@if (enabled) {
		<button (click)="save()" type="button" class="uf-button primary">Save</button>
	}
</div>
