import { EventEmitter, Injectable, inject } from '@angular/core';
import { ModalService, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { CompoundType, StructureNode, StructureNodeType, TableDetailTemplate } from '@unifii/sdk';

import { CompoundInfo, DefinitionInfo, Media, TableInfo, UcFormBucketClient, UcProject, UcStructure, UcStructureNode } from 'client';
import { TypeSelectComponent } from 'components';
import { ContentSelectComponent, ContentSelectType, getContentSelectConfig } from 'components/content/modals/content-select.component';
import { DropPosition } from 'components/dragdrop/drag-list.component';
import { PageViewEmptyTablesMessage } from 'constant';
import { FieldReferenceHelper } from 'helpers/field-reference-helper';
import { appendSuffixCopy } from 'pages/utils';

import { StructureNodeControlKeys } from './structure-control-keys';
import { StructureFormCtrl } from './structure-form-ctrl';
import { getNextAvailableNodeId, isStructureNodeRoot } from './structure-functions';
import { StructureEditorNodeOrRoot, StructureEditorStructureNode } from './structure-model';
import { StructureStatus } from './structure-status';

@Injectable()
export class StructureEditorService {

	fieldDeselected = new EventEmitter<void>();
	fieldSelected = new EventEmitter<void>();

	private modalService = inject(ModalService);
	private ucProject = inject(UcProject);
	private ucFormBucketClient = inject(UcFormBucketClient);
	private sfb = inject(StructureFormCtrl);
	private status = inject(StructureStatus);

	async selectNode(control?: UfControlGroup) {

		if (!control) {
			this.status.selected = null;
			this.fieldDeselected.next();

			return;
		}

		if (control === this.status.selected) {
			return;
		}

		const node = control.getRawValue() as StructureEditorNodeOrRoot;
		const isHomeEmpty = isStructureNodeRoot(node) && node.type === StructureNodeType.Empty;

		if (!isHomeEmpty) {
			this.status.selected = null;
			this.fieldDeselected.next();
			this.status.selected = control;
			this.fieldSelected.next();

			return;
		}

		// Select new HomePage
		const homeNode = await this.chooseHomePage();

		if (!homeNode) {
			this.status.selected = null;
			this.fieldDeselected.next();

			return;
		}

		homeNode.name = 'Home Page';

		control.patchValue(homeNode, { emitEvent: true });
		this.status.selected = null;
		this.fieldDeselected.next();
		this.status.selected = control;
		this.fieldSelected.next();
	}

	async chooseHomePage(): Promise<StructureNode | null> {

		const nodeType = await this.chooseHomeNodeType();

		if (!nodeType) {
			return null;
		}

		return this.chooseNodeContent(nodeType);
	}

	async chooseNodeContent(type: StructureNodeType): Promise<StructureNode | null> {

		const node: StructureNode = { type, children: [] };

		if (node.type === StructureNodeType.Dashboard) {
			(node as unknown as StructureEditorStructureNode).template = TableDetailTemplate.PageViewHideEmptyTables;
			node.emptyMessage = PageViewEmptyTablesMessage;
		}

		const contentSelectType = this.mapNodeTypeToContentType(type);

		if (!contentSelectType) {
			node.name = type;

			return node;
		}

		const content = await this.modalService.openMedium(
			ContentSelectComponent, getContentSelectConfig(contentSelectType, this.ucProject, this.ucFormBucketClient),
		);

		if (!content) {
			return null;
		}

		switch (type) {
			case StructureNodeType.Collection:
			{
				const collection = content as DefinitionInfo;

				node.id = collection.id as unknown as number;
				node.definitionIdentifier = collection.identifier;
				node.publishState = collection.publishState;
				node.name = collection.name;
				break;
			}

			case StructureNodeType.CollectionItem:
			{
				const collectionItem = content as CompoundInfo;

				node.id = collectionItem.id as unknown as number;
				node.definitionIdentifier = collectionItem.definitionIdentifier;
				node.definitionLabel = collectionItem.definitionLabel;
				node.publishState = collectionItem.publishState;
				node.name = collectionItem._title;
				break;
			}

			case StructureNodeType.Form:
			{
				const form = content as DefinitionInfo;

				node.id = form.id as unknown as number;
				node.definitionIdentifier = form.identifier;
				node.publishState = form.publishState;
				node.definitionLabel = form.name;
				node.name = form.name;
				break;
			}
			case StructureNodeType.Page:
			{
				const page = content as CompoundInfo;

				node.id = page.id as unknown as number;
				node.definitionIdentifier = page.definitionIdentifier;
				node.definitionLabel = page.definitionLabel;
				node.publishState = page.publishState;
				node.name = page._title;
				break;
			}
			case StructureNodeType.PdfViewer:
			{
				const pdf = content as Media;

				node.id = pdf.id as unknown as number;
				node.name = pdf.title ?? pdf.consoleName;
				break;
			}

			case StructureNodeType.View:
			{
				const view = content as CompoundInfo;

				node.id = view.id as unknown as number;
				node.definitionIdentifier = view.definitionIdentifier;
				node.definitionLabel = view.definitionLabel;
				node.publishState = view.publishState;
				node.name = view._title;
				break;
			}

			case StructureNodeType.FormBucket:
			{
				const table = content as TableInfo;

				node.id = table.id as any as number;
				node.definitionIdentifier = table.identifier;
				node.definitionLabel = table.title;
				node.name = table.title;
				break;
			}
			default:
				return null;
		}

		return node;
	}

	canDrop = async(element: any): Promise<boolean> => {

		if (element instanceof UfControlGroup && element.get(StructureNodeControlKeys.NodeId)?.value) {
			// An existing node control (excluded the HomePage with .nodeId == '0') => allow move action
			return true;
		}

		if (!element.id) {
			// Not an ItemPicker => block move action
			return false;
		}

		// User pick a new node to be added
		const node = await this.chooseNodeContent(element.id);

		if (!node) {
			// New node not selected => block move action
			return false;
		}

		/* The node need to be inserted in the element,
        *  that will be chained as input to the next drag-list callback 'addConverter'
        *  allow move action */
		element._node = node;

		return true;
	};

	addConverter = (element: { _node?: UcStructureNode }): UfControlGroup | null => {

		if (element._node) {
			const node = element._node;

			delete element._node; // clean up the field reference object

			node.nodeId = this.getStructureNextId(true);

			return this.sfb.buildNodeControl(this.sfb.mapDataNodeToControlNode(node));
		}

		return null;
	};

	drop = (element: any, parent: any, _position: DropPosition, index?: number): void => {

		let childrenControl: UfControlArray | null;

		if (parent instanceof UfControlGroup) {
			childrenControl = parent.get(StructureNodeControlKeys.Children) as UfControlArray | null;
			if (!childrenControl) {
				childrenControl = this.sfb.buildNodeChildrenControl([]);
				parent.addControl(StructureNodeControlKeys.Children, childrenControl);
			}
		} else {
			childrenControl = parent;
		}

		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
		childrenControl?.insert(index == null ? parent.controls.length : index, element);
		void this.selectNode(element);
	};

	copyAndInsert(node: StructureEditorNodeOrRoot, nodeControl: UfControlGroup) {
		const parent = nodeControl.parent as UfControlArray | UfControlGroup;

		if (parent instanceof UfControlGroup) {
			return;
		}

		node = Object.assign({}, node);
		node.nodeId = this.getStructureNextId(true);
		node.name = appendSuffixCopy({ label: node.name });

		const control = this.sfb.buildNodeControl(node as StructureEditorStructureNode);
		const index = parent.controls.findIndex((c) => c === nodeControl);

		if (index >= 0) {
			parent.insert(index, control);
		} else {
			parent.push(control);
		}
	}

	private getStructureNextId(updateLastNodeId?: boolean): string {

		const structure = this.status.root.value as UcStructure;
		const nextNodeId = getNextAvailableNodeId(structure);

		if (updateLastNodeId) {
			this.status.root.patchValue({ lastNodeId: nextNodeId });
		}

		return `${nextNodeId}`;
	}

	private mapNodeTypeToContentType(nodeType: StructureNodeType): ContentSelectType | null {
		switch (nodeType) {
			case StructureNodeType.CollectionItem:
				return ContentSelectType.CollectionItem;
			case StructureNodeType.Collection:
				return ContentSelectType.Collection;
			case StructureNodeType.PdfViewer:
				return ContentSelectType.PdfViewer;
			case StructureNodeType.View:
				return ContentSelectType.View;
			case StructureNodeType.Page:
				return ContentSelectType.Page;
			case StructureNodeType.Form:
				return ContentSelectType.Form;
			case StructureNodeType.FormBucket:
				return ContentSelectType.Table;
			default:
				return null;
		}
	}

	private async chooseHomeNodeType(): Promise<StructureNodeType | undefined> {

		return (await this.modalService.openMedium(
			TypeSelectComponent,
			{
				title: 'Select Type', types: [
					StructureNodeType.View,
					StructureNodeType.PdfViewer,
					StructureNodeType.Page,
					StructureNodeType.Dashboard,
					StructureNodeType.Custom,
				].map((type) => FieldReferenceHelper.getFieldReference({ type }, CompoundType.Structure)),
			},
		))?.type as StructureNodeType | undefined;
	}

}
