@if (defaultOption) {
	<div [class.multi]="dropOptions.length">
		<button [disabled]="disabled" (click)="onClick.emit(defaultOption)" class="uf-button primary small" type="button">
			{{ defaultOption.label }}
		</button>
		<button [options]="dropOptions" [disabled]="disabled" (optionSelected)="onClick.emit($event)" title="More Options" listBox cssClass="small" nameProperty="label" class="uf-button primary small" type="button">
			<uf-icon name="more" />
		</button>
	</div>
}
