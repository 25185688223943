import { AfterViewInit, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { DataPropertyDescriptor, FilterEntry, FilterValue, ToastService, UfControl, UfControlGroup } from '@unifii/library/common';
import { ensureUfError, isNumber, isStringNotEmpty } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { TableInfo, UcProject, UcTable } from 'client';
import { SaveOption, SaveOptionType } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { TableComponent } from 'pages/tables/table.component';
import { TitleService } from 'services/title.service';

import { TableConfigurationController, TableConfigurationKeys } from './table-configuration.controller';

@Component({
	selector: 'uc-table-configuration',
	templateUrl: 'table-configuration.html',
	providers: [TableConfigurationController],
	standalone: false,
})
export class TableConfigurationComponent implements OnInit, OnDestroy, AfterViewInit {

	protected readonly controlKeys = TableConfigurationKeys;
	protected form: UfControlGroup;
	protected sortControl: UfControl | null;
	protected columnsControl: UfControl | null;
	protected visibleFiltersControl: UfControl | null;
	protected availableColumns: DataPropertyDescriptor[];
	protected sortableColumns: DataPropertyDescriptor[];
	protected availableVisibleFilters: DataPropertyDescriptor[];
	protected availableHiddenFilters: DataPropertyDescriptor[];

	private subscriptions = new Subscription();

	private controller = inject(TableConfigurationController);
	private tableComponent = inject(TableComponent);
	private builderHeaderService = inject(BuilderHeaderService);
	private project = inject(UcProject);
	private toastService = inject(ToastService);
	private tableManager = inject<TableContainerManager<TableInfo, FilterValue, FilterEntry>>(TableContainerManager);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private titleService = inject(TitleService);

	get edited() {
		return this.tableComponent.edited;
	}

	set edited(v: boolean) {
		this.tableComponent.edited = v;
	}

	ngOnInit() {
		this.setupFormAndControls();

		this.loadDescriptors();

		this.setTitlePage(this.tableComponent.info.table);
	}

	ngAfterViewInit() {
		this.subscribeToEvents();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.tableComponent.edited = false;
	}

	protected async save(saveOption?: SaveOption) {
		this.form.setSubmitted();

		if (this.form.invalid) {
			return;
		}

		try {
			let updatedTable = await this.project.saveTable(this.controller.fromControlGroupToData(this.form));

			if (!updatedTable.id) {
				return;
			}

			if (this.tableComponent.info.duplicateTableDetails) {
				// Duplicating an existing Table with TableDetail, save also the tableDetail
				await this.project.saveTableDetail(updatedTable.id, this.tableComponent.info.duplicateTableDetails);
			}

			if (saveOption?.id === SaveOptionType.Approve && isNumber(updatedTable.id)) {
				updatedTable = await this.project.approveTable(updatedTable.id);
			}

			this.updateTable(updatedTable);
			this.setTitlePage(updatedTable);
			this.edited = false;
			this.toastService.success('Table saved!');

			if (saveOption && this.builderHeaderService.config.cancelRoute) {
				void this.router.navigate(this.builderHeaderService.config.cancelRoute, { relativeTo: this.route });
			}

			if (!saveOption) {
				void this.router.navigate(['..', updatedTable.id], { relativeTo: this.route });

				return;
			}
		} catch (error) {
			this.toastService.error(ensureUfError(error).message);
		}
	}

	private setupFormAndControls() {
		this.form = this.controller.buildRoot(this.tableComponent.info.table);
		this.sortControl = this.form.get(TableConfigurationKeys.DefaultSort) as UfControl | null;
		this.columnsControl = this.form.get(TableConfigurationKeys.Columns) as UfControl | null;
		this.visibleFiltersControl = this.form.get(TableConfigurationKeys.VisibleFilters) as UfControl | null;
	}

	private loadDescriptors() {
		const descriptor = this.tableComponent.info.dataDescriptor;

		this.availableColumns = descriptor.propertyDescriptors.filter((pd) => pd.asDisplay);
		this.sortableColumns = descriptor.propertyDescriptors.filter((pd) => pd.asSort);
		this.availableVisibleFilters = descriptor.propertyDescriptors.filter((pd) => pd.asInputFilter);
		this.availableHiddenFilters = descriptor.propertyDescriptors.filter((pd) => pd.asStaticFilter);
	}

	private subscribeToEvents() {
		this.subscriptions.add(this.form.statusChanges.subscribe(() => (this.edited = true)));
		this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => void this.save(saveOption)));
	}

	private updateTable(table: UcTable) {
		const isNewTable = table.id == null;

		if (isNewTable) {
			this.tableManager.reload?.next();
		} else {
			this.tableManager.updateItem?.next(table as TableInfo);
		}

		this.tableComponent.updateTable(table);
	}

	private setTitlePage(table: UcTable) {
		this.titleService.updateTitle(isStringNotEmpty(table.consoleName) ? table.consoleName : 'New');
	}

}
