export enum StructureControlKeys {
	Rev = 'rev',
	LastNodeId = 'lastNodeId',
	Variations = 'variations',
	StructurePublishState = 'structurePublishState',
	Revision = 'revision',
	IsHidden = 'isHidden',
}

export enum StructureNodeControlKeys {
	Type = 'type',
	Id = 'id',
	NodeId = 'nodeId',
	Tags = 'tags',
	Name = 'name',
	DefinitionIdentifier = 'definitionIdentifier',
	DefinitionLabel = 'definitionLabel',
	PublishState = 'publishState',
	LastPublishedAt = 'lastPublishedAt',
	LastModifiedAt = 'lastModifiedAt',
	LastModifiedBy = 'lastModifiedBy',
	LastPublishedBy = 'lastPublishedBy',
	Url = 'url',
	Roles = 'roles',
	IsHidden = 'isHidden',
	BucketOptions = 'bucketOptions',
	Args = 'args',
	Children = 'children',
	Template = 'template',
	EmptyMessage = 'emptyMessage',
}

export enum StructureNodeVariationControlKeys {
	Type = 'type',
	Id = 'id',
	Name = 'name',
	PublishState = 'publishState',
	DefinitionIdentifier = 'definitionIdentifier',
	DefinitionLabel = 'definitionLabel',
	Roles = 'roles',
	BucketOptions = 'bucketOptions',
	Args = 'args',
	Tags = 'tags',
	Children = 'children',
	Template = 'template',
	EmptyMessage = 'emptyMessage',
}

export enum StructureNodeBucketOptionsControlKeys {
	Identifier = 'identifier',
	PageSize = 'pageSize',
	Roles = 'roles',
}

export enum StructureNodeArgControlKeys {
	Key = 'key',
	Value = 'value',
	IsExpression = 'isExpression',
}

export enum UserReferenceControlKeys {
	Id = 'id',
	Username = 'username',
}
