@if (form && !error) {
	<div class="uf-app-bar default flat transparent pad-sides">
		<uc-builder-header>
			<a (click)="openPreview()" header-buttons class="uf-button small">Preview</a>
		</uc-builder-header>
	</div>
	<div [class.can-edit]="!form.disabled" class="builder-main">
		@if (!form.disabled) {
			<uf-panel class="scrollable">
				@if (bucket) {
					<uc-item-picker [groups]="status.itemPickerGroups" (delete)="service.deleteTemplateField($event)" />
				}
			</uf-panel>
		}
		<uf-panel class="dropzone uf-box flat">
			<uc-form-field (expandAll)="toggleExpanders($event, fieldlist)" class="fields-header" />
			<uc-drag-list [parent]="fields" [items]="fields.controls" [canDrop]="service.canDrop" [convertAdded]="service.addConverter" [entries]="fields.controls" [current]="status.selected" [filter]="filterFieldControls" (next)="selectField($event)" (moved)="service.fieldMoved($event)" (insert)="service.fieldAdded($event)" childrenProperty="fields" keySelect class="pad-sm-left pad-xs-top">
				@if (!fields.length) {
					<div class="pad col center-all">
						<img src="/assets/svg/icon-structure.svg" alt="" />
						<p class="small text-center">Your form is empty, drag an item from the left to start creating it!</p>
					</div>
				}
				<div #fieldlist>
					@for (field of fields.controls | asUfControlsGroups; track field) {
						<uc-form-field [control]="field" [dragDisabled]="field.disabled" [nestable]="!!status.containersByFieldType[field.value?.type]" dragItem class="gap-sm-bottom" />
					}
				</div>
			</uc-drag-list>
		</uf-panel>
		<uf-panel class="scrollable">
			@if (!status.selected) {
				<uc-form-metadata [control]="form" />
			}
			@if (status.selected) {
				<uc-form-field-configuration [control]="status.selected" />
			}
		</uf-panel>
	</div>
}

<uc-error-message [error]="error" />
