<div class="uf-box col-12">
	<div class="uf-app-bar flat accent">
		<div class="title">Feature Configuration</div>
	</div>
	<div [formGroup]="control" class="uf-grid pad">
		<uf-text [formControlName]="controlKeys.ConsoleName" [label]="consoleNameLabel" class="col-12">
			<span class="suffix">*</span>
		</uf-text>

		<uf-textarea [formControlName]="controlKeys.Description" label="Description" class="col-12" />

		<!-- TODO Restore once the wording has been decided
    <uf-blockquote icon="infoSolid" content="Default values have been configured for you." class="info col-12" /> -->
	</div>

	@if (control.get(controlKeys.InputArgs); as inputArgsControl) {
		<uc-data-feature-parameters [inputArgsControl]="inputArgsControl | asUfControlArray" />
	}

	@if (!hideDataSource && control.get(controlKeys.DataSeedOutputArgs); as featureDisplayControl) {
		<uc-data-feature-display [feature]="feature" [featureDisplayControl]="featureDisplayControl | asUfControlArray" />
	}

	@if (control.get(controlKeys.OutputArgs); as outputArgsControl) {
		<uc-data-feature-data-mapping [feature]="feature" [dataMappingControl]="outputArgsControl | asUfControlArray" />
	}
</div>
