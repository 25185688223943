@if (definition) {
	<uf-panel class="container">
		<div class="header-pane uf-app-bar transparent">
			<uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
				@if (edited) {
					<span class="gap-sm-left">*</span>
				}
			</uf-breadcrumbs>
		</div>
		<div class="content-pane">
			<uf-tabs>
				<uf-tab label="Data" path="" />
				@if (context.checkRoles("ProjectManager")) {
					<uf-tab label="Definition" path="definition" />
				}
			</uf-tabs>
		</div>
	</uf-panel>
}
