import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';
import { UfError, isUfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Integration } from 'client';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BreadcrumbService } from 'services/breadcrumb.service';

@Component({
	selector: 'uc-integration',
	templateUrl: './integration.html',
	styleUrls: ['./integration.less'],
	standalone: false,
})
export class IntegrationComponent implements OnInit, OnDestroy {

	@HostBinding('class.stretch-component') protected stretchComponentClass = true;

	// it needs to be public - as it's used by children components
	integration: Integration;

	protected breadcrumbs: Breadcrumb[];
	protected error?: UfError;
	protected showFeaturesTab: boolean;

	private route = inject(ActivatedRoute);
	private breadcrumbService = inject(BreadcrumbService);
	private builderHeaderService = inject(BuilderHeaderService);

	private subscriptions = new Subscription();

	ngOnInit() {
		this.subscriptions.add(this.route.data.subscribe((data) => {

			if (isUfError(data.integration)) {
				this.error = data.integration;

				return;
			}

			this.integration = data.integration;
			this.showFeaturesTab = !!this.integration?.id;
		}));

		if (this.error) { return; }
		this.buildHeaderConfig();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	buildHeaderConfig() {
		this.builderHeaderService.buildConfig({
			breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route),
			hideSaveButton: true,
			cancelRoute: ['../'],
		});
	}

}
