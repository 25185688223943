<ng-template [ngIf]="field">
	<!-- Value based on a DataSource -->
	<ng-template [ngIf]="dataSourceLoader">
		<ng-template [ngIf]="operator === 'in' || operator === 'contains'" [ngIfElse]="single">
			<uf-chips [control]="control" [options]="results" [ngClass]="cssClass" (searchChange)="query($event)" label="Value" nameProperty="_display" />
		</ng-template>
		<ng-template #single>
			<uf-autocomplete [control]="control" [options]="results" [ngClass]="cssClass" (searchChange)="query($event)" label="Value" nameProperty="_display" />
		</ng-template>
	</ng-template>

	<!-- Value based on static Options -->
	<ng-template [ngIf]="options">
		<ng-template [ngIf]="operator === 'in' || operator === 'contains'" [ngIfElse]="single">
			<!-- UNIFII-5457 Removed the uf-multi-choice as it does not allow to deselect value that are not present anymore in the corresponding static options -->
			<!-- <ng-template [ngIf]="options.length > 5" [ngIfElse]="fewOptions"> -->
			<uf-chips [control]="control" [options]="results" [ngClass]="cssClass" (searchChange)="query($event)" label="Value" valueProperty="_id" nameProperty="_display" />
			<!-- </ng-template> -->
			<!-- <ng-template #fewOptions>
                <uf-multi-choice label="Value" [(value)]="multipleValue" [ngClass]="cssClass" columns="1"
                    [control]="control" [options]="options" valueProperty="_id" nameProperty="_display">
                </uf-multi-choice>
            </ng-template> -->
		</ng-template>
		<ng-template #single>
			<uf-select [control]="control" [options]="options" [ngClass]="cssClass" label="Value" valueProperty="_id" nameProperty="_display" />
		</ng-template>
	</ng-template>

	<!-- Free input value based on the FieldType -->
	<ng-template [ngIf]="!dataSourceLoader && !options">
		<ng-container [ngSwitch]="field.type">
			<uf-datetime *ngSwitchCase="types.DateTime" [control]="control" [ngClass]="cssClass" label="Value" />

			<uf-time *ngSwitchCase="types.Time" [control]="control" [ngClass]="cssClass" label="Value" />

			<uf-date *ngSwitchCase="types.Date" [control]="control" [ngClass]="cssClass" label="Value" />

			<uf-datetime-tz *ngSwitchCase="types.ZonedDateTime" [control]="control" [ngClass]="cssClass" label="Value" class="stacked" />

			<uf-number *ngSwitchCase="types.Number" [control]="control" [ngClass]="cssClass" label="Value" />

			<uf-checkbox *ngSwitchCase="types.Bool" [control]="control" [ngClass]="cssClass" label="Value" />

			<uf-hierarchy-unit *ngSwitchCase="types.Hierarchy" [control]="control" [ceiling]="field.hierarchyConfig?.ceiling" label="Value" template="input" />

			<uf-chips *ngSwitchCase="types.TextArray" [control]="control" [allowCustom]="true" [ngClass]="cssClass" label="Value" />

			<uf-text *ngSwitchDefault [control]="control" [ngClass]="cssClass" label="Value" />
		</ng-container>
	</ng-template>
</ng-template>
