import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreatePasswordConfig, CreatePasswordValue, UfControl, UfFormBuilder } from '@unifii/library/common';
import { MfaStatus, ensureError, isDictionary, isString } from '@unifii/sdk';

import { UcClient } from 'client';
import { AuthenticationService } from 'services/authentication.service';
import { ContextService } from 'services/context.service';

import { MfaComponentNavigationState } from './mfa.component';

export interface PasswordChangeComponentNavigationState {
	oldPassword: string;
}

export const isPasswordChangeComponentNavigationState = (data: unknown): data is PasswordChangeComponentNavigationState =>
	isDictionary(data) &&
    isString(data.oldPassword);

@Component({
	selector: 'uc-password-change',
	templateUrl: './password-change.html',
	standalone: false,
})
export class PasswordChangeComponent implements OnInit {

	@HostBinding('class.stretch-component') protected stretchComponentClass = true;

	protected readonly changePasswordConfig: CreatePasswordConfig = {
		isRequired: true,
		showStrengthIndicator: true,
		canGenerate: true,
		labels: {
			message: 'You are required to update your password',
		},
	};

	protected inProgress = false;
	protected error: string | null;
	protected changePasswordControl: UfControl;

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private client = inject(UcClient);
	private context = inject(ContextService);
	private authService = inject(AuthenticationService);
	private ufb = inject(UfFormBuilder);
	private state: PasswordChangeComponentNavigationState = history.state; // ype assumed by change-password guard

	ngOnInit() {
		this.changePasswordControl = this.ufb.control({ value: {} satisfies CreatePasswordValue });
	}

	protected async changePassword() {

		if (this.inProgress) {
			return;
		}

		this.error = null;

		if (this.changePasswordControl.invalid) {
			this.changePasswordControl.setSubmitted();

			return;
		}

		const password = (this.changePasswordControl.value as CreatePasswordValue).password;

		if (!password) {
			this.error ='Password is required';

			return;
		}

		this.inProgress = true;

		try {
			const account = await this.client.updateMyPassword({
				oldPassword: this.state.oldPassword,
				password,
			});

			this.context.account = account;

			if (await this.authService.isMfaSetupRequired(account)) {
				const params = this.route.snapshot.params.next ? { next: this.route.snapshot.params.next } : {};

				void this.router.navigate(['/', 'mfa', params], { state: { mfaStatus: MfaStatus.MfaSetupRequired } satisfies MfaComponentNavigationState });

				return;
			}

			void this.router.navigateByUrl(this.route.snapshot.params.next || '/');
		} catch (error) {
			this.error = ensureError(error, 'Update password failed').message;
		} finally {
			this.inProgress = false;
		}
	}

	protected logout() {

		if (this.inProgress) {
			return;
		}

		void this.authService.logout();
	}

}

