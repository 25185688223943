import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { TableConfig } from '@unifii/library/common';
import { Device } from '@unifii/sdk';

import { UcUsers } from 'client';
import { UserDevicesDataSource } from 'pages/users/user-devices-datasource';
import { UserComponent } from 'pages/users/user.component';

@Component({
	selector: 'uc-user-devices',
	templateUrl: 'user-devices.html',
	styleUrls: ['user-devices.less', './../../styles/pages/table.less'],
	standalone: false,
})
export class UserDevicesComponent implements OnInit {

	@HostBinding('class.stretch-component') class = true;
	config: TableConfig<Device>;
	datasource: UserDevicesDataSource;

	private ucUsers = inject(UcUsers);
	private parent = inject(UserComponent);

	ngOnInit() {
		this.config = {
			id: 'user-devices',
			columnToggles: true,
			pageSize: 50,
			columns: [{
				name: 'id',
				label: 'Id',
			}, {
				name: 'name',
				label: 'Name',
			}, {
				name: 'os',
				label: 'OS',
			}, {
				name: 'osVersion',
				label: 'OS Version',
			}, {
				name: 'manufacturer',
				label: 'Manufacturer',
			}, {
				name: 'model',
				label: 'Model',
			}],
		};

		this.datasource = new UserDevicesDataSource(this.ucUsers, this.parent.id);
	}

}
