import { Injectable, inject } from '@angular/core';
import { ClientDeleteOptions, ClientGetOptions, ClientPatchOptions, ImageOptions, ImageOptionsWithMode, amendOptionsParams, buildImageUrl, mergeParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { Media } from './models';
import { UcClient } from './uc-client';

export interface MediaQueryParams {
	q?: string;
	sort?: string;
	types?: string | string[];
	tags?: string | string[];
	pending?: boolean;
	offset?: number;
	limit?: number;
}

@Injectable({ providedIn: 'root' })
export class UcMedia {

	private client = inject(UcClient);

	get(params?: MediaQueryParams, options?: ClientGetOptions): Promise<Media[]> {
		let url = this.url();

		if (params?.pending) {
			url = this.url('pending');
			delete params.pending;
		}

		const defaultedParams = mergeParams(DefaultPaginationParams, params as Record<string, unknown>);

		return this.client.get(url, amendOptionsParams(defaultedParams, options)) as Promise<Media[]>;
	}

	getDetail(id: number, options?: ClientGetOptions): Promise<Media> {
		return this.client.get(this.url(id + ''), options) as Promise<Media>;
	}

	save(media: Media, options?: ClientPatchOptions): Promise<Media> {
		return this.client.patch(this.url(media.id + ''), media, options) as Promise<Media>;
	}

	delete(id: number, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url(id + ''), options) as Promise<void>;
	}

	buildImageUrl(media: Media, options?: ImageOptions | ImageOptionsWithMode): string | undefined {
		return buildImageUrl({
			url: media.thumbUrl ?? media.url,
			id: media.id?.toString() ?? '',
			width: media.width ?? 0,
			height: media.height ?? 0,
		}, options);
	}

	private url(...extra: string[]): string {
		const urlParts = ['assets'].concat(extra);

		return this.client.buildUrl(urlParts);
	}

}
