import { Injectable } from '@angular/core';

import { DragListComponent } from './drag-list.component';

@Injectable({ providedIn: 'root' })
export class DragService {

	sourceList: DragListComponent | null = null;
	dragIndex: number | null = null;
	data: any;

}
