import { ClientDeleteOptions, ClientGetOptions, ClientPatchOptions, amendOptionsParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { WorkflowNotification, WorkflowNotificationState } from './models';
import { UcClient } from './uc-client';

export class UcWorkflowNotifications {

	constructor(
		private client: UcClient,
		private projectId: number,
		private bucket: string,
	) { }

	/** q?: string, sort?: string, offset?: number, limit?: number, full?: boolean */
	get(options?: ClientGetOptions): Promise<WorkflowNotification[]> {
		return this.client.get(this.url(), amendOptionsParams(DefaultPaginationParams, options));
	}

	getNotification(id: number, options?: ClientGetOptions): Promise<WorkflowNotification> {
		return this.client.get(this.url(`${id}`), options);
	}

	saveNotification(notification: WorkflowNotification): Promise<WorkflowNotification> {
		if (notification.id) {
			return this.client.put(this.url(notification.id), notification);
		}

		return this.client.post(this.url(), { body: notification });
	}

	deleteNotification(id: number, options?: ClientDeleteOptions): Promise<void> {
		return this.client.delete(this.url(`${id}`), options);
	}

	updateNotificationState(notification: WorkflowNotification, state: WorkflowNotificationState, options?: ClientPatchOptions): Promise<WorkflowNotification> {
		return this.client.patch(this.url(`${notification.id}`), { state }, options);
	}

	isValidStateChange(from: WorkflowNotificationState, to: WorkflowNotificationState): boolean {
		switch (from) {
			case WorkflowNotificationState.Inactive:
				return [WorkflowNotificationState.Preview, WorkflowNotificationState.Live].includes(to);
			case WorkflowNotificationState.Preview:
				return [WorkflowNotificationState.Inactive, WorkflowNotificationState.Live].includes(to);
			case WorkflowNotificationState.Live:
				return WorkflowNotificationState.Inactive === to;
		}
	}

	private url(...extra: string[]): string {
		const urlParts = ['projects', this.projectId, 'form-buckets', this.bucket, 'notifications'].concat(extra);

		return this.client.buildUrl(urlParts);
	}

}
