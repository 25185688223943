import { Component, HostBinding, inject } from '@angular/core';
import { ModalService } from '@unifii/library/common';
import { ProjectContentOptions } from '@unifii/sdk';

import { UcFormBucketClient, UcProject } from 'client';
import { ContentSelectComponent, ContentSelectType, ContentType, getContentSelectConfig } from 'components/content/modals/content-select.component';

@Component({
	selector: 'uc-empty',
	templateUrl: './empty.html',
	providers: [
		{ provide: ProjectContentOptions, useValue: { projectId: '53', preview: false } },
		UcProject,
	],
	standalone: false,
})
export class EmptyComponent {

	@HostBinding('class.stretch-component') class = true;

	readonly contentTypes = Object.keys(ContentSelectType);

	contentType: ContentSelectType | null;
	content?: ContentType;

	private modalService = inject(ModalService);
	private ucFormBucketClient = inject(UcFormBucketClient);
	private ucProject = inject(UcProject);

	async show(type: ContentSelectType) {
		this.content = await this.modalService.openMedium(
			ContentSelectComponent, getContentSelectConfig(type, this.ucProject, this.ucFormBucketClient),
		);
	}

}
