import { Component, HostBinding, inject } from '@angular/core';
import { TableConfig } from '@unifii/library/common';
import { Device } from '@unifii/sdk';

import { UcApps } from 'client';
import { PUBLISH_STATES } from 'helpers/helpers';
import { AppDevicesDataSource } from 'pages/apps/app-devices-datasource';

@Component({
	selector: 'uc-template-table',
	templateUrl: './template-table.html',
	styleUrls: ['../../styles/pages/table.less'],
	standalone: false,
})
export class TemplateTableComponent {

	@HostBinding('class.stretch-component') stretchComponentClass = true;

	query: string;
	publishStates = PUBLISH_STATES;
	status: string;
	datasource: any = [];
	config: TableConfig<Device>;

	private ucApps = inject(UcApps);

	constructor() {
		this.init();
	}

	private init() {

		this.config = {
			columns: [{
				name: 'id',
				label: 'Id',
			}, {
				name: 'name',
				label: 'Name',
			}, {
				name: 'os',
				label: 'Os',
			}, {
				name: 'osVersion',
				label: 'Os Version',
			}, {
				name: 'manufacturer',
				label: 'Manufacturer',
			}, {
				name: 'model',
				label: 'Model',
			}],
			actions: [{
				label: 'Notify',
			}],
			columnToggles: true,
			selectable: true,
			pageSize: 50,
		};

		this.datasource = new AppDevicesDataSource(this.ucApps, 'au.com.unifii.unifii.dev');
	}

}
