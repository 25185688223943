<div class="header">
	<button (click)="runtime.close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>Add Description</h3>
</div>

<uf-panel class="container">
	<div [formGroup]="form" class="uf-grid pad-sides">
		<uf-textarea [formControlName]="controlKeys.Description" class="col-12" />
	</div>
</uf-panel>

<div class="row space-children pad">
	<button (click)="runtime.close()" type="button" class="uf-button tertiary right">Don't Save</button>
	<button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>
