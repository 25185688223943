import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ModalService } from '@unifii/library/common';
import { TenantSettings } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Config } from 'app-config';
import { UcClient } from 'client';

import { ContactInfoModalComponent } from './contact-info-modal.component';

@Component({
	selector: 'uc-user-access-box',
	templateUrl: './user-access-box.html',
	styleUrls: ['./user-access-box.less'],
	standalone: false,
})
export class UserAccessBoxComponent implements OnDestroy, OnInit {

	@Input({ required: true }) tenantSettings: TenantSettings | undefined;

	config = inject(Config);

	protected inProgress: boolean;

	private modalService = inject(ModalService);
	private client = inject(UcClient);
	private subscriptions = new Subscription();

	ngOnInit() {
		this.subscriptions.add(this.client.inProgress.subscribe((inProgress) => { this.inProgress = inProgress; }));
	}

	showContactInfo() {
		if (!this.tenantSettings) {
			return;
		}

		void this.modalService.openFit(ContactInfoModalComponent, this.tenantSettings);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}
