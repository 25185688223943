<uc-user-access-box [tenantSettings]="settings">
	<ng-container content>
		@if (!settings && error) {
			<uf-message [content]="error" icon="errorSolid" class="error" />
		} @else {
			<form [formGroup]="form" (ngSubmit)="login()" class="uf-grid">
				<div class="col-12">
					<h4>{{ settings?.name }}</h4>
				</div>
				@if (isUsernamePasswordAuthenticationEnabled) {
					<uf-text [formControlName]="controlKeys.Username" name="username" label="Username" autocomplete="username" validators class="col-12" />
					<uf-password [formControlName]="controlKeys.Password" [showReveal]="true" name="password" label="Password" autocomplete="password" validators class="col-12" />
					<div class="col-12 col center-all gap-sm-top">
						<button [disabled]="inProgress" type="submit" class="uf-button primary">Sign In</button>
					</div>
				}
				@if (authProviders.length) {
					@if (isUsernamePasswordAuthenticationEnabled) {
						<div class="col-12 col center-all">
							<span>or</span>
						</div>
					}
					<div class="col-12 auth-providers col space-children">
						@for (provider of authProviders; track provider) {
							<div class="col">
								@if (provider.providerLoginLabel) {
									<label class="small">{{ provider.providerLoginLabel }}</label>
								}
								<button [disabled]="inProgress" (click)="providerSignIn(provider)" type="button" class="authentication-provider grow">
									<img [src]="provider.loginIcon" />
									<p class="small">{{ provider.loginLabel }}</p>
								</button>
							</div>
						}
					</div>
				}
				@if (error) {
					<uf-message [content]="error" icon="errorSolid" class="error col-12" />
				}
			</form>
		}
	</ng-container>
</uc-user-access-box>
