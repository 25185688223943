<!-- Form header -->
@if (isRoot) {
	<div [class.accent]="isSelected" (click)="select($event)" class="uf-app-bar flat gap-sm-bottom">
		<div class="title primary">{{ status.definition.label }}</div>
		@if (status.root.invalid) {
			<uf-icon [class.error]="!isSelected" title="Form configuration is invalid" name="error" />
		}
		@if (status.definition.description) {
			<uf-icon [class.primary]="!isSelected" [class.white]="isSelected" title="description" name="notes" />
		}
		<button [class.tertiary]="!isSelected" [class.primary]="isSelected" (click)="expand($event)" type="button" title="Expand All" class="uf-action">
			<uf-icon name="arrowDown" />
		</button>
		<button [class.tertiary]="!isSelected" [class.primary]="isSelected" (click)="collapse($event)" type="button" title="Collapse All" class="uf-action">
			<uf-icon name="arrowUp" />
		</button>
	</div>
} @else {
	@if (control) {
		<!-- Leaf node -->
		@if (!meta.isContainer) {
			<div [class.uf-box]="!isSelected" [class.accent]="isSelected" (click)="select($event)" class="uf-app-bar flat draggable">
				<uf-icon [name]="icon" class="gap-sm-left" />
				<!-- Content field show help instead of label  -->
				<div class="title primary">{{ label }}</div>
				<uc-form-field-icons [control]="control" [selected]="isSelected" />
				@if (!control.disabled) {
					<a (click)="remove($event)" title="Delete" class="uf-action">
						<uf-icon name="delete" />
					</a>
				}
			</div>
		} @else {
			<uf-expander class="uf-box flat draggable gap-sm-bottom">
				<div [class.accent]="isSelected" (click)="select($event)" expanderHeader class="uf-app-bar flat draggable">
					<uf-icon [name]="icon" />
					<div class="title primary">{{ label }}</div>
					<uc-form-field-icons [control]="control" [selected]="isSelected" />
					@if (!control.disabled) {
						<a (click)="remove($event)" title="Delete" class="uf-action">
							<uf-icon name="delete" />
						</a>
					}
				</div>
				<div expanderBody>
					<uc-drag-list [parent]="fields" [items]="fields.controls" [canDrop]="service.canDrop" [convertAdded]="service.addConverter" (moved)="service.fieldMoved($event)" (insert)="service.fieldAdded($event)" childrenProperty="fields" class="gap-left gap-sm-top">
						@for (field of fields.controls | asUfControlsGroups; track field) {
							<uc-form-field [dragDisabled]="field.disabled" [nestable]="!!status.containersByFieldType[field.value?.type]" [control]="field" dragItem class="gap-sm-bottom" />
						}
						@if (!fields.controls.length && !isStepper) {
							<div>
								<p class="body-copy small">Drag your items here</p>
							</div>
						}
						@if (isStepper) {
							<div class="row padded">
								<button (click)="addStepperPage()" type="button" class="uf-button x-small right">Add Page</button>
							</div>
						}
						<uf-error [control]="fields" />
					</uc-drag-list>
				</div>
			</uf-expander>
		}
		<!-- Parent node -->
		<ng-template #parent>
			<uf-expander class="uf-box flat draggable gap-sm-bottom">
				<div [class.accent]="isSelected" (click)="select($event)" expanderHeader class="uf-app-bar flat draggable">
					<uf-icon [name]="icon" />
					<div class="title primary">{{ label }}</div>
					<uc-form-field-icons [control]="control" [selected]="isSelected" />
					@if (!control.disabled) {
						<a (click)="remove($event)" title="Delete" class="uf-action">
							<uf-icon name="delete" />
						</a>
					}
				</div>
				<div expanderBody>
					<uc-drag-list [parent]="fields" [items]="fields.controls" [canDrop]="service.canDrop" [convertAdded]="service.addConverter" (moved)="service.fieldMoved($event)" (insert)="service.fieldAdded($event)" childrenProperty="fields" class="gap-left gap-sm-top">
						@for (field of fields.controls | asUfControlsGroups; track field) {
							<uc-form-field [dragDisabled]="field.disabled" [nestable]="!!status.containersByFieldType[field.value?.type]" [control]="field" dragItem class="gap-sm-bottom" />
						}
						@if (!fields.controls.length && !isStepper) {
							<div>
								<p class="body-copy small">Drag your items here</p>
							</div>
						}
						@if (isStepper) {
							<div class="row padded">
								<button (click)="addStepperPage()" type="button" class="uf-button x-small right">Add Page</button>
							</div>
						}
						<uf-error [control]="fields" />
					</uc-drag-list>
				</div>
			</uf-expander>
		</ng-template>
	}
}
