import { Component, Input } from '@angular/core';

@Component({
	selector: 'uc-menu-item',
	templateUrl: './menu-item.html',
	styleUrls: ['./menu-item.less'],
	standalone: false,
})
export class MenuItemComponent {

	@Input() label: string;
	@Input() icon?: string | null;
	@Input() link?: string | string[] | null;
	@Input() showLabel = false;

}
