import { APIPath, Claim, ClaimConfig, DataSourceType, Definition, Dictionary, FileType, FormData, LogoDto, Permission, PermissionAction, PermissionActionInfo, ProjectInfo, RouteInfo, AuthProvider as SDKAuthProvider, Role as SDKRole, UserInfo, UserMfaInfo, UserReference, TenantSettings as sdkTenantSettings } from '@unifii/sdk';

import { PublishItem } from 'services/project-publisher';

export enum PermissionPrincipalType {
	Role = 'roles',
	User = 'users',
	ApiKey = 'api-keys',
}

export enum ResourceSegment {
	Roles = APIPath.Roles,
	Me = APIPath.Me,
	Users = APIPath.Users,
	Permissions = 'permissions',
	UserClaims = APIPath.UserClaims,
	// TODO replace for APIPath.UserClaim once BE resource tree is update
	DefaultClaims = 'default-claims',
	Companies = APIPath.Companies,
	Units = APIPath.HierarchyUnits,
	Projects = APIPath.Projects,
	Structure = APIPath.Structure,
	Collections = APIPath.Collections,
	CollectionItems = 'items',
	Pages = APIPath.Pages,
	Views = APIPath.Views,
	Forms = APIPath.Forms,
	Buckets = APIPath.Buckets,
	BucketDocs = 'docs',
	BucketLog = 'bucketlog',
	Tables = APIPath.Tables,
	ExternalDataSources = APIPath.ExternalDataSources,
	ExternalDataTransactions = 'external-data-transactions',
	TicketProviders = 'ticket-providers',
	AuthProviders = 'auth-providers',
}

export enum ResourcePath {
	Resources = '/',
	Roles = `/${ResourceSegment.Roles}`,
	Projects = `/${ResourceSegment.Projects}`,
	Project = `/${ResourceSegment.Projects}/?`,
	Structure = `/${ResourceSegment.Projects}/?/${ResourceSegment.Structure}`,
	Collections = `/${ResourceSegment.Projects}/?/${ResourceSegment.Collections}`,
	Collection = `/${ResourceSegment.Projects}/?/${ResourceSegment.Collections}/?`,
	CollectionItems = `/${ResourceSegment.Projects}/?/${ResourceSegment.Collections}/?/${ResourceSegment.CollectionItems}`,
	CollectionItem = `/${ResourceSegment.Projects}/?/${ResourceSegment.Collections}/?/${ResourceSegment.CollectionItems}/?`,
	Views = `/${ResourceSegment.Projects}/?/${ResourceSegment.Views}`,
	View = `/${ResourceSegment.Projects}/?/${ResourceSegment.Views}/?`,
	Pages = `/${ResourceSegment.Projects}/?/${ResourceSegment.Pages}`,
	Page = `/${ResourceSegment.Projects}/?/${ResourceSegment.Pages}/?`,
	Forms = `/${ResourceSegment.Projects}/?/${ResourceSegment.Forms}`,
	Form = `/${ResourceSegment.Projects}/?/${ResourceSegment.Forms}/?`,
	FormDataRepositories = `/${ResourceSegment.Projects}/?/${ResourceSegment.Buckets}`,
	FormDataRepository = `/${ResourceSegment.Projects}/?/${ResourceSegment.Buckets}/?`,
	FormDataRepositoryDocuments = `/${ResourceSegment.Projects}/?/${ResourceSegment.Buckets}/?/${ResourceSegment.BucketDocs}`,
	FormDataRepositoryDocument = `/${ResourceSegment.Projects}/?/${ResourceSegment.Buckets}/?/${ResourceSegment.BucketDocs}/?`,
	FormDataTransactionLog = `/${ResourceSegment.Projects}/?/${ResourceSegment.BucketLog}`,
	FormDataTransactionLogDocument = `/${ResourceSegment.Projects}/?/${ResourceSegment.BucketLog}/?`,
	ExternalDataSources = `/${ResourceSegment.Projects}/?/${ResourceSegment.ExternalDataSources}`,
	ExternalDataSource = `/${ResourceSegment.Projects}/?/${ResourceSegment.ExternalDataSources}/?`,
	ExternalDataTransactions = `/${ResourceSegment.Projects}/?/${ResourceSegment.ExternalDataTransactions}`,
	ExternalDataTransaction = `/${ResourceSegment.Projects}/?/${ResourceSegment.ExternalDataTransactions}/?`,
	Tables = `/${ResourceSegment.Projects}/?/${ResourceSegment.Tables}`,
	Table = `/${ResourceSegment.Projects}/?/${ResourceSegment.Tables}/?`,
	Users = `/${ResourceSegment.Users}`,
	User = `/${ResourceSegment.Users}/?`,
	UserPermissions = `/${ResourceSegment.Users}/?/${ResourceSegment.Permissions}`,
	TicketProviders = `/${ResourceSegment.TicketProviders}`,
	TicketProviderIntegration = `/${ResourceSegment.TicketProviders}/?`,
	TicketProvider = `/${ResourceSegment.TicketProviders}/?/?`,
	Companies = `/${ResourceSegment.Companies}`,
	Company = `/${ResourceSegment.Companies}/?`,
	UserClaims = `/${ResourceSegment.DefaultClaims}`,
	UserClaim = `/${ResourceSegment.DefaultClaims}/?`,
	Me = `/${ResourceSegment.Me}`,
	Units = `/${ResourceSegment.Units}`,
	Unit = `/${ResourceSegment.Units}/?`,
	AuthProviders = `/${ResourceSegment.AuthProviders}`,
	AuthProvider = `/${ResourceSegment.AuthProviders}/?`,
	ExternalUsers = `/${ResourceSegment.AuthProviders}/?/${ResourceSegment.Users}`,
	ExternalUser = `/${ResourceSegment.AuthProviders}/?/${ResourceSegment.Users}/?`,
}

export enum SystemRole {
	SuperUser = 'SuperUser',
	SystemManager = 'SystemManager',
	UserManager = 'UserManager',
	AssetManager = 'AssetManager',
	ProjectManager = 'ProjectManager',
	ContentEditor = 'ContentEditor',
	FormDesigner = 'FormDesigner',
	Publisher = 'Publisher',
	FormDataViewer = 'FormDataViewer',
	FormBucketSettingsEditor = 'FormBucketSettingsEditor',
	WorkflowDesigner = 'WorkflowDesigner',
	Auditor = 'Auditor',
	Translator = 'Translator',
	AppManager = 'AppManager',
}

export enum WorkflowNotificationState {
	Inactive = 'Inactive',
	Preview = 'Preview',
	Live = 'Live',
}

export enum WorkflowNotificationRecipientType {
	User = 'User',
	Role = 'Role',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	Claim = 'Claim',
	CreatedBy = 'CreatedBy',
	LastModifiedBy = 'LastModifiedBy',
	CreatedByManager = 'CreatedByManager',
	LastModifiedByManager = 'LastModifiedByManager',
	Email = 'Email',
	Combo = 'Combo',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	FormData = 'FormData',
	UserHierarchy = 'UserHierarchy',
	Company = 'Company',
	EmailField = 'EmailField',
	UserDatasource = 'UserDatasource',
	UserDatasourceManager = 'UserDatasourceManager',
	ClaimDatasource = 'ClaimDatasource',
	UserModified = 'UserModified',
	UserModifiedManager = 'UserModifiedManager',
}

export enum WorkflowNotificationDeliveryMethod {
	Email = 'email',
	Push = 'push',
	Sms = 'sms',
}

export enum DataSourceInputValueSource {
	Form = 'Form', // Comes from a data-source config
	Constant = 'Constant', // Enforced value
	Default = 'Default', // Uses default value if not overridden by front end
	Unset = 'Unset', // Never has a value
}

export enum AuthProviderMappingActionType {
	AssignUnit = 'AssignUnit',
	AssignClaim = 'AssignClaim',
	AssignRole = 'AssignRole',
	AssignSystemRole = 'AssignSystemRole',
	AssignClaimFrom = 'AssignClaimFrom',
}

export interface Limits {
	projectsLimit?: number;
	formsLimit?: number;
	sectionsLimit?: number;
	pagesLimit?: number;
	viewsLimit?: number;
}

export interface Media extends ConsoleInfo {
	id?: number;
	type: MediaType;
	title?: string;
	description?: string;
	author?: string;
	credits?: string;
	filename: string;
	uploadedAt: string;
	url: string;
	filesize: number;
	width?: number;
	height?: number;
	length?: string;
	thumbUrl?: string;
	tags: number[];
}

export enum MediaType {
	Image = 'Image',
	Video = 'Video',
	Audio = 'Audio',
	File = 'File',
}

export interface SmsIntegration {
	smsProviderId: string;
	smsProviderName: string;
}

export interface TenantSettings extends sdkTenantSettings {
	id: string;
	baseUrl?: string;
	logo?: UcLogoDto;
	allowedAssetFileTypes: FileType[] | null;
	allowedAttachmentFileTypes: FileType[] | null;
	features: Dictionary<boolean>;
	language?: {
		code: string;
		name: string;
	};
	refreshTokenExpiry?: number;
	maxSessionLength?: number;
	googleMapsApiKey?: string;
	rememberMe?: boolean;
	rememberMeExpiryDays?: number;
	limits?: Limits;
	isPasswordAuthSupported?: boolean;
	lastModifiedAt?: string;
	lastModifiedBy?: UserReference;
	integration?: SmsIntegration;
}

export interface MyAccount {
	id: string;
	username: string;
	roles: string[];
	changePasswordOnNextLogin?: boolean;
	isMfaOptional: boolean;
	isMfaEnabled: boolean;
	mfa?: UserMfaInfo;
}

export interface ApiKey extends ConsoleInfo {
	key?: string;
	secret?: string;
	createdAt: string;
	enableOAuth?: boolean;
	createdBy: { id: string; username: string };
	lastUsedAt?: string;
}

export interface VersionInfo {
	version: number;
	preview?: number;
	publishedAt: string;
	publishedBy?: UserReference; // added in 2021, so could be undefined in old projects
}

export interface PublishStatus {
	stable?: VersionInfo;
	preview?: VersionInfo;
	items?: PublishItem[];
}

export interface AppInfo {
	id: string;
	name: string;
	createdAt?: string;
	createdBy?: UserReference;
	lastModifiedAt?: string;
	lastModifiedBy?: UserReference;
}

export interface App extends AppInfo {
	secret?: string;
	googleKey?: string;
	appleCert?: string;
	appleCertName?: string;
	appleCertNotAfter?: string;
	applePassword?: string;
	corsOrigins?: string[];
	appleIsSandbox?: boolean;
}

export interface PushNotification {
	title: string;
	message: string;
	route?: RouteInfo;
	devices?: string[];
	userQuery?: string;
}

export interface AuthProviderExtras extends Dictionary<any> {
	audience?: string;
	manualRegistration?: boolean;
	useDirectory?: boolean;
	authorizationServer?: string;
	sswsSecret?: string; // Secret field to load groups for Okta providers
}

export interface AuthProviderListDetails {
	id: string;
	tenant: string;
	type: SDKAuthProvider;
	isActive: boolean;
	manual: boolean;
	providerLoginLabel: string;
	scimEnabled: boolean;
}

export interface AuthProviderDetails {
	type: SDKAuthProvider;
	id: string;
	clientId?: string;
	clientSecret?: string;
	extras?: AuthProviderExtras;
	tenant?: string;
	manual?: boolean;
	isActive: boolean;
	systemRolesMapping?: { source: string; targets: string[] }[];
	tenantRolesMapping?: { source: string; targets: string[] }[];
	claimsMapping?: AuthProviderClaimMapping[];
	lastModifiedAt?: string;
	lastModifiedBy?: UserReference;
	featureConfig?: IntegrationFeatureConfig;
	userFieldsMapping?: FieldMappingData;
	mappings?: AuthProviderMapping[];
	scimToken?: string;
	allowInvitations?: boolean;
	allowEditing?: boolean;
	allowDeletePending?: boolean;
	providerLoginLabel?: string;
	disableSsoMapping?: boolean;
	// Read-only from backend
	supportsInvitations?: boolean;
	supportsEditing?: boolean;
	supportsDeletePending?: boolean;
	isSupportProvider?: boolean;
}

export type NewAuthProviderDetails = Omit<AuthProviderDetails, 'id'>;

export interface AuthProviderClaimMapping {
	source: string;
	target: string;
	valueMap: Dictionary<string>;
}

export type FieldMappingData = Record<string, FieldMapping>;

export interface FieldMapping {
	source: string;
	label?: string;
}

export interface AuthProviderMapping {
	condition?: AuthProviderMappingCondition;
	actions: AuthProviderMappingAction[];
}

export interface AuthProviderMappingCondition {
	type: AuthProviderMappingConditionType;
	identifier?: string;
	value?: string;
	children?: AuthProviderMappingCondition[];
}

export interface AuthProviderMappingAction {
	type: AuthProviderMappingActionType;
	identifier: string;
	value?: string;
}

export interface AuthProviderSourceGroup {
	id: string;
	name: string;
}

export interface ExternalLogin {
	authProvider?: SDKAuthProvider;
	tenant?: string;
	key?: string;
}

export interface UcUserInfo extends UserInfo {
	/**
     * property to list identity providers of an user
     */
	logins?: ExternalLogin[];
	/**
     * last time that a user has accessed Discover, it's not present in case of not having the data
     */
	lastLoginAt?: string;
}

export interface UcLogoDto extends LogoDto {
	id: number;
}

export interface SmtpInfo {
	smtpHost: string;
	smtpPort?: number;
	smtpSystemName?: string;
	smtpSystemEmail: string;
	smtpUsername: string;
	smtpPassword: string;
	lastModifiedAt?: string;
	lastModifiedBy?: UserReference;
}
export interface EmailInfo {
	inviteEmailExpiry: number;
	inviteEmailTemplates: EmailTemplate[];
	resetPasswordEmailTemplates: EmailTemplate[];
	emailColour?: string;
	replyTo?: string;
	createdAt?: string;
	createdBy?: UserReference;
	lastModifiedAt?: string;
	lastModifiedBy?: UserReference;
}

export interface EmailTemplate {
	title: string;
	subject: string;
	introMessage: string;
	bodyMessage: string;
	buttonLabel: string;
	conditions: EmailTemplateCondition[];
	baseUrl?: string | null;
	index?: number;
	replyTo?: string;
}

export interface EmailTemplateCondition {
	role?: string;
	claim?: Claim;
}

export interface UcProjectInfo extends ProjectInfo {
	logo?: UcLogoDto;
	version?: number;
	publishedAt: string;
	previewApp?: AppInfo;
	liveApp?: AppInfo;
	lastModifiedAt?: string;
	lastModifiedBy?: UserReference;
	tags?: string[];
}

export interface DefaultClaim {
	id?: string;
	type: string;
	createdAt?: string;
	createdBy?: UserReference;
}

export interface UcClaimConfig extends ClaimConfig {
	createdAt?: string; // standard RFC3339 UTC timestamp
	createdBy?: UserReference;
	// readonly, assigned by server on mutation
	lastModifiedAt?: string; // standard RFC3339 UTC timestamp
	lastModifiedBy?: UserReference;
	// Enables search on field types Text, TextArray, Phone, Website, Email
	isSearchable?: boolean;
}

export interface UcRole extends SDKRole {
	id?: string;
	description?: string;
	permissionsCount?: number;
}

export type RoleUpdatableFields = Pick<UcRole, 'label' | 'description'>;

export interface UcPermissionActionInfo extends Omit<PermissionActionInfo, 'condition'> {
	condition?: string;
}

export interface UcPermission extends Omit<Permission, 'actions'> {
	actions: UcPermissionActionInfo[];
	principalId: string;
	principalType: `${PermissionPrincipalType}`;
	id?: string;
	description?: string;
	source?: {
		type: `${PermissionPrincipalType.Role}`;
		id: string;
		name: string;
	};
}

/* Permission projection for Permissions table custom columns */
export interface UcPermissionTableEntry extends UcPermission {
	hasConditionalActions?: boolean;
	hasConfiguredFields?: boolean;
}

/** This interface is an extension of the /api/resources output */
export interface Resource {
	name: string;
	segment: string;
	actions: ResourceAction[];
	allowsCondition: boolean;
	children: Resource[];
	path: ResourcePath; // computed attribute in UcResources
}

export interface ResourceAction {
	name: PermissionAction;
	description: string;
	allowsReadFields: boolean;
	allowsEditFields: boolean;
}

export interface ResourceElement {
	id: string;
	name: string;
}

export interface WorkflowNotificationMessage {
	title?: string;
	body?: string;
	replyTo?: string;
	attachFormAsPdf?: boolean;
}

export enum WorkflowNotificationConditionType {
	Role = 'Role',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	Claim = 'Claim',
	Hierarchy = 'Hierarchy',
	UserHierarchy = 'UserHierarchy',
	Company = 'Company',
}

export enum WorkflowNotificationRecipientClaimMatchType {
	Value = 'Value',
	CreatedBy = 'CreatedBy',
	LastModifiedBy = 'LastModifiedBy',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	FormData = 'FormData',
	UserModified = 'UserModified',
}

export enum WorkflowNotificationRecipientFormDataType {
	User = 'User',
	UserManager = 'UserManager',
	Email = 'Email',
}

export interface WorkflowNotificationMessageInfo {
	sms?: WorkflowNotificationMessage;
	push?: Omit<WorkflowNotificationMessage, 'attachFormAsPdf'>;
	email?: WorkflowNotificationMessage;
}

export interface WorkflowNotificationRecipient {
	liveOnly: boolean;
	type: WorkflowNotificationRecipientType;
}

export interface WorkflowNotificationRecipientEmail extends WorkflowNotificationRecipient {
	type: WorkflowNotificationRecipientType.Email;
	email: string;
}

export interface WorkflowNotificationRecipientRole extends WorkflowNotificationRecipient {
	type: WorkflowNotificationRecipientType.Role;
	role: string;
}
export interface WorkflowNotificationRecipientClaimData {
	type: string;
	value: string;
	matchType?: WorkflowNotificationRecipientClaimMatchType;
	matchAgainst?: string;
}
export interface WorkflowNotificationRecipientClaim extends WorkflowNotificationRecipient {
	type: WorkflowNotificationRecipientType.Claim;
	claim: WorkflowNotificationRecipientClaimData;
}

export type WorkflowNotificationConditionRole = Omit<WorkflowNotificationRecipientRole, 'liveOnly' | 'type'>;
export type WorkflowNotificationConditionClaim = Omit<WorkflowNotificationRecipientClaim, 'liveOnly' | 'type'>;

export interface WorkflowNotificationConditionHierarchy {
	hierarchy: {
		formData: string;
		value: string;
	};
}

export interface WorkflowNotificationConditionUserHierarchy {
	userHierarchy: UserHierarchyData;
}

export interface WorkflowNotificationConditionCompany {
	company: string;
}

export type WorkflowNotificationRecipientComboTypes =
    WorkflowNotificationConditionRole |
    WorkflowNotificationConditionClaim |
    WorkflowNotificationConditionCompany |
    WorkflowNotificationConditionHierarchy |
    WorkflowNotificationConditionUserHierarchy;

export interface WorkflowNotificationRecipientComboCompany extends WorkflowNotificationRecipient {
	company: string;
}

export interface WorkflowNotificationRecipientCombo extends WorkflowNotificationRecipient {
	conditions: WorkflowNotificationRecipientComboTypes[];
}

export type WorkflowNotificationRecipients =
    WorkflowNotificationRecipientFormData |
    WorkflowNotificationRecipientUser |
    WorkflowNotificationRecipient |
    WorkflowNotificationRecipientEmail |
    WorkflowNotificationRecipientRole |
    WorkflowNotificationRecipientClaim |
    WorkflowNotificationRecipientCombo |
    WorkflowNotificationRecipientUserHierarchy;

export interface WorkflowNotificationRecipientFormData extends WorkflowNotificationRecipient {
	type: WorkflowNotificationRecipientType.FormData;
	formData: {
		value: string;
		type: WorkflowNotificationRecipientFormDataType;
	};
}

export enum WorkflowNotificationUserHierarchyMatchTypes {
	Value = 'Value',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	FormData = 'FormData',
}

export interface UserHierarchyData {
	value: string;
	matchType: WorkflowNotificationUserHierarchyMatchTypes;
}

export interface WorkflowNotificationRecipientUserHierarchy extends WorkflowNotificationRecipient {
	type: WorkflowNotificationRecipientType.UserHierarchy;
	userHierarchy: UserHierarchyData;
}

export interface WorkflowNotificationRecipientUser extends WorkflowNotificationRecipient {
	type: WorkflowNotificationRecipientType.User;
	user: {
		id: string;
		username: string;
	};
}

export interface WorkflowNotification extends WorkflowActivityInfo {
	messages: WorkflowNotificationMessageInfo;
	recipients: WorkflowNotificationRecipient[];
	createdAt: string;
	createdBy: UserReference;
	lastModifiedAt: string;
	lastModifiedBy: UserReference;
}

export interface FormDataActivity extends WorkflowActivityInfo {
	type: ActivityType.FormData;
	sourceType: WorkflowEventType;
	targetFormId: number;
	targetBucket: string;
	targetAction: string;
	targetState: string;
	inputMap: Dictionary<string>;
}

export interface DataForwarder extends WorkflowActivityInfo {
	type: ActivityType.DataForwarder;
	sourceType: WorkflowEventType;
	integrationId?: string;
	featureId?: string;
	inputMap?: Dictionary<string>;
}

export interface DataForwarderActivityInfo extends DataForwarder {
	integrationName?: string;
}

export interface FormDataActivityInfo extends FormDataActivity {
	targetFormName?: string;
}

export interface WorkflowBasicCondition {
	expression?: string;
}

export interface FormSubmittedCondition extends WorkflowBasicCondition {
	bucket?: string;
	transitions?: {
		source: string;
		action: string;
	}[];
	forms?: string[]; // form identifiers (within the bucket)
}

export interface RoleAddedCondition extends WorkflowBasicCondition {
	roles?: string[];
	includeNewUser?: boolean;
	includeExternal?: boolean;
}

export type WorkflowCondition = FormSubmittedCondition | WorkflowBasicCondition | RoleAddedCondition;

// --------------------------------------------------
// Activities

export enum ActivityType {
	Timer = 'Timer',
	DataForwarder = 'DataForwarder',
	Notification = 'Notification',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	FormData = 'FormData',
}

export interface WorkflowActivityInfo extends ConsoleInfo {
	id: string;
	createdAt: string;
	createdBy: UserReference;
	lastModifiedAt: string;
	lastModifiedBy: UserReference;
	type: ActivityType;
	bucket?: string; // source bucket
}

export enum WorkflowValueTypes {
	Value = 'value',
	Expression = 'expression',
}

export enum WorkflowRelatedActivityType {
	ReplaceOnExecution = 'ReplaceOnExecution',
}

export interface WorkflowRelatedActivity {
	relatedActivityId: string;
	relationshipType: WorkflowRelatedActivityType.ReplaceOnExecution;
}

export interface WorkflowActivityTimer extends WorkflowActivityInfo {
	type: ActivityType.Timer;
	expression: string; // will result to data-time value
	replacePreviousJob?: boolean;
	relatedWorkflowActivities?: WorkflowRelatedActivity[];
}

// --------------------------------------------------
// Workflow rule

export enum WorkflowEventType {
	Timer = 'Timer',
	FormSubmitted = 'FormSubmitted',
	ApiEvent = 'Api',
	RoleAdded = 'RoleAdded',
}

export type WorkflowEvent = FormSubmittedEvent | TimerEvent | APIEvent | RoleAddedEvent;

export interface TimerEvent {
	type: WorkflowEventType.Timer;
	timerId?: string;
}

export interface RoleAddedEvent {
	type: WorkflowEventType.RoleAdded;
}

export interface FormSubmittedEvent {
	type: WorkflowEventType.FormSubmitted;
}

export interface APIEvent {
	type: WorkflowEventType.ApiEvent;
	integrationId?: string;
	featureId?: string;
}

export enum WorkflowState {
	Active = 'Active',
	Inactive = 'Inactive',
	Preview = 'Preview',
	DryRun = 'DryRun',
}

export interface WorkflowRuleActivity extends ConsoleInfo {
	id: string;
	label: string;
	type: ActivityType;
}

export interface WorkflowRule extends ConsoleInfo {
	id: string;
	createdAt: string;
	createdBy: UserReference;
	lastModifiedAt: string;
	lastModifiedBy: UserReference;
	event: WorkflowEvent;
	condition?: WorkflowCondition;
	activities: WorkflowRuleActivity[];
	state: WorkflowState; // ?? can we remove preview
}

export type NewWorkflowRule = Omit<WorkflowRule, 'id' | 'state' | 'createdAt' | 'createdBy' | 'lastModifiedAt' | 'lastModifiedBy'>;

export interface IntegrationProviderInfo {
	id: string;
	name: string;
	description: string;
	logo: string; // base64, maybe full DATA URI
}

export interface IntegrationProvider extends IntegrationProviderInfo {
	configForm?: Definition;
	features: IntegrationFeature[];
}

// Used by integration list
export interface IntegrationInfo {
	id?: string;
	name: string;
	createdAt?: string;
	createdBy?: UserReference;
	lastModifiedAt?: string;
	lastModifiedBy?: UserReference;
	provider: IntegrationProviderInfo;
}

export type IntegrationFeatureConfig = Record<string, {
	disabled: boolean;
	label?: string;
}>;

export interface Integration {
	id?: string;
	name: string;
	provider: IntegrationProvider;
	config: FormData;
	createdAt?: string;
	createdBy?: UserReference;
	lastModifiedAt?: string;
	lastModifiedBy?: UserReference;
	featureConfig?: IntegrationFeatureConfig;
	integrationFeatures?: IntegrationFeature[];
}

export type ConsoleDataTransaction = ConsoleDataSource;

export type DataTransactionInfo = DataSourceInfo;

export interface DataSourceInfo {
	id: string;
	name: string;
	description: string;
	integrationName: string;
	integrartionTypeLogo: string; // base64
	integrationProviderName: string;
	lastModifiedAt: string;
	lastModifiedBy?: UserReference;
}

export interface ConsoleDataSource extends ConsoleInfo {
	id?: string;
	type: DataSourceType;
	description?: string;
	integrationId: string;
	featureId: string;
	inputMap: Dictionary<DataSourceInput>; // refers to provider.inputArgs[].identifier
	outputMap: Dictionary<string>;
	lastModifiedAt?: string;
	lastModifiedBy?: UserReference;
}

export interface DataSourceInput {
	source: DataSourceInputValueSource;
	value: string;
}
/**
 * @Deprecated moving to workflow activities
 */
export interface DataForwarderInfo {
	id: string;
	name: string;
	integrationName: string;
	integrationProviderName: string;
	integrationProviderLogo: string; // base64
	featureId: string;
	createdAt: string;
	createdBy: UserReference;
	lastModifiedAt: string;
	lastModifiedBy: UserReference;
}

export enum AuthProviderMappingConditionType {
	GroupMembership = 'GroupMembership',
	RoleAssignment = 'RoleAssignment',
	ClaimValue = 'ClaimValue',
	ClaimFrom = 'ClaimFrom',
	And = 'And',
	Or = 'Or',
}

export enum IntegrationProviderFeatureType {
	FeatureTypeLookup = 'Lookup',
	FeatureTypeSink = 'Sink',
	FeatureTypeWebhook = 'Webhook',
	FeatureTypeDataTransaction = 'Transaction',
}

export enum IntegrationFeatureArgType {
	Bool = 'Bool',
	// eslint-disable-next-line id-denylist
	Number = 'Number',
	// eslint-disable-next-line id-denylist
	String = 'String',
	Duration = 'Duration',
	Date = 'Date',
	Time = 'Time',
	DateTime = 'DateTime',
	OffsetDateTime = 'OffsetDateTime',
	// Object value is added to attributes
	Object = 'Object',
	// List value is added to valueType
	List = 'List',
}

// when isRequired is added to IntegrationFeatureArg we need to omit it here as well
export interface IntegrationFeatureArgRoot extends Omit<IntegrationFeatureArg, 'kind'> {
	kind: IntegrationFeatureArgType.Object;
}

export interface IntegrationFeatureArg {
	kind: IntegrationFeatureArgType;
	attributes?: Dictionary<IntegrationFeatureArg>;
	valueType?: IntegrationFeatureArg;
	// TODO Required field will be added later on, when expressions are updated to accept it
	// isRequired: boolean;
}

export interface IntegrationFeature {
	id: string;
	name: string;
	type: IntegrationProviderFeatureType;
	idExp: string;
	displayExp: string;
	disabled: boolean;
	// TODO create a union type when the different type of inputs/outputs - similar to what we have
	// in workflow - (rule/notification/...)
	input?: IntegrationFeatureArgRoot;
	output?: IntegrationFeatureArgRoot;
	endpointPath?: string;
	secondaryEndpointPath?: string;
	correlationExp?: string;
	correlationId?: string;
	previewEndpointPath?: string;
	configurable?: boolean;
	lastModifiedAt?: string;
	lastModifiedBy?: UserReference;
}

export type ConsoleInfo = {
	consoleName?: string;
};

export type ConsoleInfoContent = {
	_consoleName?: string;
};
