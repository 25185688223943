import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { DataPropertyDescriptor, UfControl, UfControlGroup, UfFormControl } from '@unifii/library/common';
import { FieldTemplate, FieldType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ContextService } from 'services/context.service';

import { FORM_EDITOR_CONSTANTS } from '../form-editor-constants';
import { FieldControlKeys, HierarchyConfigControlKeys } from '../form-editor-control-keys';
import { FormEditorFunctions } from '../form-editor-functions';
import { FormEditorField, FormFieldMetadata } from '../form-editor-model';

@Component({
	selector: 'uc-form-field-settings',
	templateUrl: './form-field-settings.html',
	standalone: false,
})
export class FormFieldSettingsComponent implements OnInit, OnDestroy {

	@Input({ required: true }) control: UfControlGroup;

	protected readonly fieldTypes = FieldType;
	protected readonly fieldKeys = FieldControlKeys;
	protected readonly hierarchyConfigKeys = HierarchyConfigControlKeys;
	protected readonly currencies = FORM_EDITOR_CONSTANTS.CURRENCIES;
	protected readonly scrollTimes = FORM_EDITOR_CONSTANTS.SCROLL_TIMES;
	protected readonly intervals = FORM_EDITOR_CONSTANTS.TIME_STEP_VALUES;
	protected readonly selectionModeOptions = FORM_EDITOR_CONSTANTS.HIERARCHY_SELECTION_MODE_OPTIONS;
	protected meta: FormFieldMetadata;
	protected ready: boolean;
	protected controls: UfFormControl[];
	protected actionsResult: string[] = [];
	protected formatOptions: string[] = [];
	protected formatPlaceholder: string | undefined;
	protected sortProperties: DataPropertyDescriptor[] = [];

	private context = inject(ContextService);
	private subscriptions = new Subscription();

	get field(): FormEditorField {
		return this.control.getRawValue() as FormEditorField;
	}

	get isInvalid(): boolean {
		return this.controls.find((c) => c.invalid) != null;
	}

	get showSort(): boolean {
		const template = this.field.template;

		return this.meta.sort && !!template && [FieldTemplate.HorizontalTable, FieldTemplate.HorizontalTableMobile].includes(template);
	}

	ngOnInit() {
		this.meta = FormEditorFunctions.controlMetadata(this.control, this.context);
		this.controls = FORM_EDITOR_CONSTANTS.SECTION_SETTINGS_FIELDS.map((k) => this.control.get(k) as UfFormControl).filter((c) => c != null);

		switch (this.field.type) {
			case FieldType.Date:
				this.formatOptions = [...FORM_EDITOR_CONSTANTS.DATE_FORMATS];
				break;
			case FieldType.Time:
				this.formatOptions = [...FORM_EDITOR_CONSTANTS.TIME_FORMATS];
				break;
			case FieldType.DateTime:
			case FieldType.ZonedDateTime:
				for (const d of FORM_EDITOR_CONSTANTS.DATE_FORMATS) {
					for (const t of FORM_EDITOR_CONSTANTS.TIME_FORMATS) {
						this.formatOptions.push(`${d} ${t}`);
					}
				}
				break;
			case FieldType.Repeat: {
				const repeatSortablePropertiesCtrl = this.control.get(FieldControlKeys.RepeatSortableProperties) as UfControl;

				this.sortProperties = repeatSortablePropertiesCtrl.getRawValue() as DataPropertyDescriptor[];
				this.subscriptions.add(repeatSortablePropertiesCtrl.valueChanges
					.subscribe((v: DataPropertyDescriptor[]) => (this.sortProperties = v)));
			}
		}

		this.formatPlaceholder = this.formatOptions[0];
		this.ready = true;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected searchActions(query: string) {
		const parentField = this.control.parent?.parent?.getRawValue() as FormEditorField | undefined;

		if (parentField?.transitions == null) {
			this.actionsResult = [];

			return;
		}

		const filteredList = parentField.transitions.filter((item) => {
			const value = item.action.toLowerCase();

			return !query || value.includes(query.toLowerCase());
		});

		this.actionsResult = filteredList.map((item) => item.action).filter((v, i, self) => self.indexOf(v) === i);
	}

}
