<uf-panel class="container">
	<div class="grid--fixed">
		<div class="content-pane">
			<div class="uf-grid col-12">
				@for (type of contentTypes; track type) {
					<div [innerHTML]="type" (click)="show($any(type))" class="uf-lozenge info col-1of12"></div>
				}
			</div>
			@if (content) {
				<div class="uf-grid col-12">
					<pre class="col-12">{{ content | json }}</pre>
				</div>
			}
		</div>
	</div>
</uf-panel>
