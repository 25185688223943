@if (ready && notifyEnabled) {
	<form class="uf-grid pad">
		<!-- Details  -->
		@if (config.label.show && form.controls.label) {
			<uf-text [control]="form.controls.label | asUfControl" [(value)]="field.label" label="Label" class="col-12" />
		}
		@if (config.shortLabel.show && form.controls.shortLabel) {
			<uf-text [control]="form.controls.shortLabel | asUfControl" [(value)]="field.shortLabel" label="Short label" class="col-12" />
		}
		@if (config.identifier.show && form.controls.identifier) {
			<uf-text [control]="form.controls.identifier | asUfControl" [maxLength]="identifierMaxLength" [(value)]="field.identifier" label="Identifier" class="col-12" />
		}
		@if (showWarningIdentifier) {
			<uf-message icon="warningSolid" class="col-12 x-small warning">
				<p>Identifier is too long</p>
			</uf-message>
		}
		<!-- Link list for collections only -->
		@if (config.collection.show && form.controls.collection) {
			<uf-select [control]="form.controls.collection | asUfControl" [options]="builderService.collections" [(value)]="field.definitionIdentifier" nameProperty="name" valueProperty="identifier" disabled="true" label="Collection" class="col-12" />
		}
		@if (config.types.show && form.controls.types) {
			<uf-multi-choice [control]="form.controls.types | asUfControl" [options]="builderService.collections" [(value)]="field.types" nameProperty="name" valueProperty="identifier" disabled="false" label="Types" class="col-12" />
		}
		@if (config.helpText.show && field.type !== fieldType.Content && form.controls.helpText) {
			<uc-markdown [control]="form.controls.helpText | asUfControl" [showAlignmentOptions]="false" [(value)]="field.help" label="Help text" class="col-12" />
		}
		@if (config.helpText.show && field.type === fieldType.Content && form.controls.helpText) {
			<uc-markdown [control]="form.controls.helpText | asUfControl" [showAlignmentOptions]="showAlignmentOptions" [(value)]="field.help" label="Content" class="col-12" />
		}
		<!-- currency -->
		@if (config.currency.show && form.controls.currency) {
			<uf-select [control]="form.controls.currency | asUfControl" [options]="currencies" [(value)]="field.currency" valueProperty="value" nameProperty="name" label="Currency" class="col-12" />
		}
		@if (config.placeholder.show && form.controls.placeholder) {
			<uf-text [control]="form.controls.placeholder | asUfControl" [(value)]="field.placeholder" label="Placeholder" class="col-12" />
		}
		@if (config.step.show && form.controls.step) {
			<uc-step [control]="form.controls.step | asUfControl" [(value)]="field.step" class="col-12" />
		}
		@if (config.format.show && form.controls.format) {
			<uf-text [control]="form.controls.format | asUfControl" [(value)]="field.format" label="Format" class="col-12" />
		}
		@if (config.autofill.show && form.controls.autofill) {
			<uf-text [control]="form.controls.autofill | asUfControl" [(value)]="field.autofill" label="Autofill" class="col-12" />
		}
		@if (config.bindTo.show && form.controls.bindTo) {
			<uf-text [control]="form.controls.bindTo | asUfControl" [(value)]="field.bindTo" label="Bind to" class="col-12" />
		}
		@if (config.tags.show && form.controls.tags) {
			<uf-chips [control]="form.controls.tags | asUfControl" [allowCustom]="true" [options]="filteredTags" [(value)]="field.tags" (searchChange)="filterTags($event)" (valueChange)="builderService.refreshTags()" label="Tags" class="col-12" />
		}
		<!-- Attributes -->
		@if (config.isReadOnly.show && form.controls.isReadOnly) {
			<uf-checkbox [control]="form.controls.isReadOnly | asUfControl" [(value)]="field.isReadOnly" label="Read only" class="col-12" />
		}
		@if (config.isRequired.show && form.controls.isRequired) {
			<uf-checkbox [control]="form.controls.isRequired | asUfControl" [(value)]="field.isRequired" label="Required" class="col-12" />
		}
		@if (config.isOneToMany.show && form.controls.isOneToMany) {
			<uf-checkbox [control]="form.controls.isOneToMany | asUfControl" [(value)]="field.isOneToMany" label="One to many" class="col-12" />
		}
		@if (config.autoDetect.show && form.controls.autoDetect) {
			<uf-checkbox [control]="form.controls.autoDetect | asUfControl" [(value)]="field.autoDetect" label="AutoDetect" class="col-12" />
		}
		@if (config.maxLength.show && form.controls.maxLength) {
			<uf-number [control]="form.controls.maxLength | asUfControl" [(value)]="field.maxLength" label="Maximum length" class="col-12" />
		}
		@if (config.precision.show && form.controls.precision) {
			<uf-number [control]="form.controls.precision | asUfControl" [(value)]="field.precision" label="Precision" class="col-12" />
		}
		@if (config.translatable.show && form.controls.translatable) {
			<uf-checkbox [control]="form.controls.translatable | asUfControl" [(value)]="field.isTranslatable" label="Translatable" class="col-12" />
		}
		@if (config.ceiling.show && form.controls.ceiling) {
			<uf-hierarchy-unit [control]="form.controls.ceiling | asUfControl" [(value)]="hierarchyCeiling" (valueChange)="updateFieldCeiling($event)" placeholder="Root" label="Starting Unit" class="col-12" />
		}
		@if (config.selectionMode.show && field.hierarchyConfig) {
			<uf-select [options]="selectionModeOptions" [(value)]="$any(field.hierarchyConfig).selectionMode" valueProperty="identifier" nameProperty="name" label="Limit selection to" class="col-12" />
		}
	</form>
}
