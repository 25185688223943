import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { Device, RequestAnalyticsOrigin } from '@unifii/sdk';

import { UcUsers } from 'client';

export class UserDevicesDataSource extends TableDataSource<Device> {

	filtered: boolean;
	sorted: boolean;

	constructor(
		private ucUser: UcUsers,
		private userId: string,
	) {
		super();
	}

	load(options?: TableDataSourceQueryOptions) {
		this.ucUser.getDevices(this.userId, {
			params: { offset: options?.offset, limit: options?.limit },
			analytics: { origin: RequestAnalyticsOrigin.Table },
		}).then(
			(devices) => this.stream.next({ data: devices.slice(options?.offset, (options?.offset ?? 0) + (options?.limit ?? 100)) }),
			(error) => this.stream.next({ error }),
		);
	}

}
