import { Component, HostBinding, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';

export type ConflictModalAction = 'Discard' | 'OpenNewTab';

export interface ConflictModalInfo {
	edited: boolean;
}

@Component({
	selector: 'uc-conflict-modal',
	templateUrl: './conflict-modal.html',
	standalone: false,
})
export class ConflictModalComponent implements Modal<ConflictModalInfo, ConflictModalAction> {

	@HostBinding('class.uf-form-card') cardClass = true;

	data = inject<ConflictModalInfo>(ModalData);
	runtime = inject<ModalRuntime<ConflictModalInfo, ConflictModalAction>>(ModalRuntime);

	protected apply(action: ConflictModalAction) {
		this.runtime.close(action);
	}

}
