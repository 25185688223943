import { AuthProvider, ClaimConfig, HierarchyUnitWithPath, Option } from '@unifii/sdk';

import { AuthProviderMapping, AuthProviderMappingAction, AuthProviderMappingActionType, AuthProviderMappingConditionType, AuthProviderSourceGroup,
	FieldMapping, FieldMappingData } from 'client';

// Types
interface FieldMappingDysplay { display: string }

export type FieldMappingOption = FieldMapping & FieldMappingDysplay;

export interface MappingConfig {
	sourceRoles: boolean;
	sourceGroups: boolean;
	sourceClaims: boolean;
	hasManager: boolean;
	isUserEmailRequired: boolean;
}

// Models

export interface FieldMappingModel extends FieldMapping {
	display?: string;
}

export interface AuthProviderMappingConditionFormModel {
	id?: string;
	type: AuthProviderMappingConditionType;
	identifier?: string;
	value?: string;
	children?: AuthProviderMappingConditionFormModel[];
	group?: AuthProviderSourceGroup;
}

export interface AuthProviderMappingActionModel extends Omit<AuthProviderMappingAction, 'value'> {
	claim?: ClaimConfig;
	units?: HierarchyUnitWithPath[];
	roles?: string[];
	claimTo?: ClaimConfig;
	value?: string | string[];
}

export interface AuthProviderMappingModel {
	id: string;
	condition: AuthProviderMappingConditionFormModel[];
	actions: AuthProviderMappingActionModel[];
}

export interface AuthProviderMappingsModel {
	authProviderId: string;
	authProviderType: AuthProvider;
	userFieldsMapping?: FieldMappingData;
	mappings: AuthProviderMappingModel[];
	customGroups?: AuthProviderSourceGroup[];
}

export interface AuthProviderMappings {
	userFieldsMapping?: FieldMappingData;
	mappings?: AuthProviderMapping[];
}

export interface AuthProviderMappingFilter {
	group?: AuthProviderSourceGroup;
	authProviderId: string;
	conditionClaimValue?: string;
	conditionClaimIdentifier?: string;
	rolesAssigned?: string[];
	hierarchyUnit?: HierarchyUnitWithPath;
	actionClaimValue?: string;
	actionClaimIdentifier?: string;
	actionClaimFrom?: string;
	actionClaimTo?: string;
	actionRoles?: string[];
	actionSystemRoles?: string[];
}

// Control Keys

export enum MappingsControlKeys {
	Condition = 'condition',
	Actions = 'actions',
	Id = 'id',
	Type = 'type',
	Identifier = 'identifier',
	Value = 'value',
	Children = 'children',
	Group = 'group',
	Mappings = 'mappings',
	Units = 'units',
	AuthProviderId = 'authProviderId',
	UserFieldsMapping = 'userFieldsMapping',
	AllowCustom = 'allowCustom',
	Label = 'label',
	Source = 'source',
	Roles = 'roles',
	Claim = 'claim',
	CustomGroups = 'customGroups',
	ClaimTo = 'claimTo',
}

export enum DetailsControlKeys {
	Id = 'id',
	Tenant = 'tenant',
	ClientId = 'clientId',
	ClientSecret = 'clientSecret',
	SswsSecret = 'sswsSecret',
	ScimToken = 'scimToken',
	AllowInvitations = 'allowInvitations',
	AllowEditing = 'allowEditing',
	AllowDeletePending = 'allowDeletePending',
	ProviderLoginLabel = 'providerLoginLabel',
	IsActive = 'isActive',
	Manual = 'manual',
	Type = 'type',
	Extras = 'extras',
	Mappings = 'mappings',
	Audience = 'audience',
	ManualRegistration = 'manualRegistration',
	UseDirectory = 'useDirectory',
	AuthorizationServer = 'authorizationServer',
	RegistrationType = 'registrationType',
	SupportProvider = 'isSupportProvider',
}

export enum FilterControlKeys {
	Group = 'group',
	AuthProviderId = 'authProviderId',
	ConditionClaimIdentifier = 'conditionClaimIdentifier',
	ConditionClaimValue = 'conditionClaimValue',
	RolesAssigned = 'rolesAssigned',
	HierarchyUnit = 'hierarchyUnit',
	ActionClaimIdentifier = 'actionClaimIdentifier',
	ActionClaimValue = 'actionClaimValue',
	ActionClaimFrom = 'actionClaimFrom',
	ActionClaimTo = 'actionClaimTo',
	ActionRoles = 'actionRoles',
	ActionSystemRoles = 'actionSystemRoles',
}

// Descriptions

export const ConditionTypesDescription = {
	[AuthProviderMappingConditionType.And]: 'Combination',
	[AuthProviderMappingConditionType.ClaimValue]: 'Claim Value',
	[AuthProviderMappingConditionType.GroupMembership]: 'Group',
	[AuthProviderMappingConditionType.Or]: 'Or',
	[AuthProviderMappingConditionType.RoleAssignment]: 'Role',
	[AuthProviderMappingConditionType.ClaimFrom]: 'Has Claim',
};

export const ActionsTypesDescription = {
	[AuthProviderMappingActionType.AssignUnit]: 'Hierarchy Unit',
	[AuthProviderMappingActionType.AssignClaim]: 'Claim Value',
	[AuthProviderMappingActionType.AssignSystemRole]: 'System Role',
	[AuthProviderMappingActionType.AssignRole]: 'Role',
	[AuthProviderMappingActionType.AssignClaimFrom]: 'Claim From',
};

// Options
export const RoleAssignedConditionOption = { identifier: AuthProviderMappingConditionType.RoleAssignment, name: ConditionTypesDescription[AuthProviderMappingConditionType.RoleAssignment] };
export const GroupConditionOption = { identifier: AuthProviderMappingConditionType.GroupMembership, name: ConditionTypesDescription[AuthProviderMappingConditionType.GroupMembership] };
export const OrConditionOption = { identifier: AuthProviderMappingConditionType.Or, name: ConditionTypesDescription[AuthProviderMappingConditionType.Or] };

export const ConditionOptions: Option[] = [
	{ identifier: AuthProviderMappingConditionType.ClaimValue, name: ConditionTypesDescription[AuthProviderMappingConditionType.ClaimValue] },
	{ identifier: AuthProviderMappingConditionType.ClaimFrom, name: ConditionTypesDescription[AuthProviderMappingConditionType.ClaimFrom] },
	{ identifier: AuthProviderMappingConditionType.And, name: ConditionTypesDescription[AuthProviderMappingConditionType.And] },
];

export const ActionOptions: Option[] = [
	{ identifier: AuthProviderMappingActionType.AssignUnit, name: 'Hierarchy Unit' },
	{ identifier: AuthProviderMappingActionType.AssignClaim, name: 'Claim Value' },
	{ identifier: AuthProviderMappingActionType.AssignClaimFrom, name: 'Claim From' },
	{ identifier: AuthProviderMappingActionType.AssignRole, name: 'Role' },
	{ identifier: AuthProviderMappingActionType.AssignSystemRole, name: 'System Role' },
];

// Constants

export const ManagerFieldMapping = 'manager';
export const EmailFieldMapping = 'email';
export const IgnoreFieldMappingValue = 'Ignore';
export const CustomFieldMapping = 'custom';
