import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager, TableInputManager } from '@unifii/components';
import { ActionMultiplicity, DataDisplayService, FilterEntry, FilterValue, TableConfig } from '@unifii/library/common';
import { DataType } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { AppInfo, UcApps } from 'client';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { AppsDatasource } from './apps-datasource';

@Injectable()
export class AppsTableManager implements TableContainerManager<AppInfo, FilterValue, FilterEntry> {

	tableConfig: TableConfig<AppInfo>;
	addActionConfig = true;
	reload = new Subject<void>();
	updateItem = new Subject<AppInfo | { item: AppInfo; trackBy: keyof AppInfo }>();
	inputManager: TableInputManager<FilterValue, FilterEntry>;

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private ucApps = inject(UcApps);
	private dataDisplayService = inject(DataDisplayService);
	private dialogs = inject(DialogsService);
	private context = inject(ContextService);

	constructor() {
		this.tableConfig = {
			columns: [{
				name: 'name',
				label: 'Title',
			}, {
				name: 'lastModifiedAt',
				label: 'Last Modified',
				value: (item) => this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
			}, {
				name: 'lastModifiedBy',
				label: 'Modified By',
				hidden: true,
				value: (item) => item.lastModifiedBy?.username,
			}, {
				name: 'publishState',
				label: 'State',
			}],
			row: {
				link: (item) => [item.id],
			},
			actions: [{
				label: 'Delete',
				action: (item) => this.deleteItem(item.$implicit),
				multiplicity: ActionMultiplicity.Single,
				predicate: () => this.context.checkRoles('AppManager'),
			}],
			pageSize: 1000, // TODO remove when we support pagination
			columnToggles: true,
		};
	}

	createDataSource() {
		return new AppsDatasource(this.ucApps);
	}

	addItem() {
		this.editItem('new');
	}

	async deleteItem(item: AppInfo) {
		if (!await this.dialogs.confirmDelete()) {
			return;
		}

		await this.ucApps.delete(item.id);
		this.ucApps.appDeleted.next(item);
	}

	private editItem(identifier: string) {
		void this.router.navigate([identifier], { relativeTo: this.route });
	}

}
