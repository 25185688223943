import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { FormData, FormDataClient, Query, RequestAnalyticsOrigin } from '@unifii/sdk';

export class BucketDataSource extends TableDataSource<FormData> {

	filtered: boolean;

	constructor(
		// Use SDK v0 endpoint that is ACL exceptions mitigated for Console users
		private formDataClient: FormDataClient,
		private query: Query,
		public sorted: boolean,
	) {
		super();
	}

	load(options?: TableDataSourceQueryOptions) {

		this.query.limit(options?.limit, options?.offset);

		this.formDataClient.query(this.query, { analytics: { origin: RequestAnalyticsOrigin.Table } }).then(
			(data) => this.stream.next({ data }),
			(error) => this.stream.next({ error }),
		);
	}

	override getDownloadUrl(options?: TableDataSourceQueryOptions): string | undefined {
		this.query.limit(options?.limit ?? 10000, options?.offset);

		return this.formDataClient.getDownloadUrl(this.query || undefined);
	}

}
