import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { DataDescriptorService, DataPropertyDescriptor, SourceConfig, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { DataSeed, DataSourceType, FieldType, VisibleFilterDescriptor, generateUUID, objectKeys } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcRoles } from 'client';
import { FilterInfo } from 'components';
import { hasSort } from 'components/field-builder/data-source-editor/data-source-editor-functions';

enum VisibleFilterKeys {
	Label = 'label',
	InputType = 'type',
	Roles = 'roles',
	Sort = 'sort',
	Format = 'format',
}

@Component({
	selector: 'uc-visible-filter',
	templateUrl: 'visible-filter.html',
	standalone: false,
})
export class VisibleFilterComponent implements OnInit, OnDestroy {

	@Input() parentControl: UfControlGroup;
	@Input() filterInfo: FilterInfo;
	@Output() filterChange = new EventEmitter<VisibleFilterDescriptor>();

	protected readonly form = new UfControlGroup({});
	protected readonly controlKeys = VisibleFilterKeys;
	protected readonly dateTimePlaceholder = 'Date & Time';
	protected readonly dataTimeOptions: DataSeed[] = [{ _id: FieldType.Date, _display: 'Date' }];
	protected readonly formatOptions: DataSeed[] = [];
	protected sortProperties: DataPropertyDescriptor[] = [];
	protected roleResults: string[] = [];
	protected ready: boolean;

	private readonly controlIdentifier = generateUUID();
	private subscriptions = new Subscription();

	private ucRoles = inject(UcRoles);
	private formBuilder = inject(UfFormBuilder);
	private dataDescriptionService = inject(DataDescriptorService);

	async ngOnInit() {

		if (!this.filterInfo.dataPropertyDescriptor) {
			return;
		}

		this.form.setControl(this.controlKeys.Label, this.formBuilder.control(this.filterInfo.filter.label));

		if (this.filterInfo.dataPropertyDescriptor.type === FieldType.DateTime) {
			this.form.setControl(this.controlKeys.InputType, this.formBuilder.control(this.filterInfo.filter.inputType));
		}

		if (this.filterInfo.dataPropertyDescriptor.type === FieldType.Hierarchy) {
			this.form.setControl(this.controlKeys.Format, this.formBuilder.control(this.filterInfo.filter.format));
			this.formatOptions.push({ _id: 'leaf', _display: 'Show leaf unit only' });
		}

		if (this.filterInfo.dataPropertyDescriptor.type === FieldType.Lookup && hasSort(this.filterInfo.dataPropertyDescriptor.sourceConfig?.type)) {
			try {
				this.sortProperties = await this.getSortProperties(this.filterInfo.dataPropertyDescriptor.sourceConfig);
				this.form.setControl(this.controlKeys.Sort, this.formBuilder.control(this.filterInfo.filter.sort));
			} catch (e) {
				console.error(e);
			}
		}

		if (this.filterInfo.dataPropertyDescriptor.icon === 'user') {
			this.form.setControl(this.controlKeys.Roles, this.formBuilder.control(this.filterInfo.filter.roles));
		}

		this.subscriptions.add(this.form.valueChanges.subscribe(() => this.onValueChanges()));
		this.parentControl.setControl(this.controlIdentifier, this.form, { emitEvent: false });
		this.ready = true;
	}

	ngOnDestroy() {
		this.parentControl.removeControl(this.controlIdentifier);
		this.subscriptions.unsubscribe();
	}

	protected async findRoles(query: string | null) {
		this.roleResults = (await this.ucRoles.get(query ?? undefined)).map((r) => r.name);
	}

	private onValueChanges() {

		const roleControl = this.form.get(this.controlKeys.Roles);
		const inputTypeControl = this.form.get(this.controlKeys.InputType);
		const labelControl = this.form.get(this.controlKeys.Label);
		const sortControl = this.form.get(this.controlKeys.Sort);
		const formatControlKey = this.form.get(this.controlKeys.Format);

		if (roleControl) {
			this.filterInfo.filter.roles = roleControl.value;
		}

		if (inputTypeControl) {
			this.filterInfo.filter.inputType = inputTypeControl.value;
		}

		if (labelControl) {
			this.filterInfo.filter.label = labelControl.value;
		}

		if (sortControl) {
			this.filterInfo.filter.sort = sortControl.value;
		}

		if (formatControlKey) {
			this.filterInfo.filter.format = formatControlKey.value;
		}

		this.filterChange.emit(this.purgeEmptyValues(this.filterInfo.filter));
	}

	private purgeEmptyValues(data: VisibleFilterDescriptor) {

		for (const key of objectKeys(data)) {
			const value = data[key];

			if (!value || (Array.isArray(value) && !value.length)) {
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete data[key];
			}
		}

		return data;
	}

	private async getSortProperties(sourceConfig?: SourceConfig): Promise<DataPropertyDescriptor[]> {

		if (!sourceConfig) {
			return [];
		}

		switch (sourceConfig.type) {
			case DataSourceType.Users:
				return (await this.dataDescriptionService.getUserDataDescriptor())?.propertyDescriptors ?? [];
			case DataSourceType.Company:
				return (await this.dataDescriptionService.getCompanyDataDescriptor())?.propertyDescriptors ?? [];
			case DataSourceType.Bucket:
				return (await this.dataDescriptionService.getBucketDataDescriptor(sourceConfig.id))?.propertyDescriptors ?? [];
			case DataSourceType.Collection:
				return (await this.dataDescriptionService.getCollectionDataDescriptor(sourceConfig.id))?.propertyDescriptors ?? [];
			default:
				return [];
		}

	}

}
