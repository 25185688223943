import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { SchemaInfo, UcFormBucketClient } from 'client';

export class BucketsDataSource extends TableDataSource<SchemaInfo> {

	filtered: boolean;
	sorted: boolean;

	constructor(private ucFormBucketClient: UcFormBucketClient, private search?: string) {
		super();
	}

	load(options?: TableDataSourceQueryOptions) {
		this.ucFormBucketClient.list({
			params: { q: this.search, offset: options?.offset, limit: options?.limit },
			analytics: { origin: RequestAnalyticsOrigin.Table },
		}).then(
			(schemas) => this.stream.next({ data: schemas }),
			(error) => this.stream.next({ error }),
		);
	}

}
