"use strict";

exports.LocalWeekParser = void 0;
var _index = require("../../../setWeek.cjs");
var _index2 = require("../../../startOfWeek.cjs");
var _constants = require("../constants.cjs");
var _Parser = require("../Parser.cjs");
var _utils = require("../utils.cjs");

// Local week of year
class LocalWeekParser extends _Parser.Parser {
  priority = 100;
  parse(dateString, token, match) {
    switch (token) {
      case "w":
        return (0, _utils.parseNumericPattern)(_constants.numericPatterns.week, dateString);
      case "wo":
        return match.ordinalNumber(dateString, {
          unit: "week"
        });
      default:
        return (0, _utils.parseNDigits)(token.length, dateString);
    }
  }
  validate(_date, value) {
    return value >= 1 && value <= 53;
  }
  set(date, _flags, value, options) {
    return (0, _index2.startOfWeek)((0, _index.setWeek)(date, value, options), options);
  }
  incompatibleTokens = ["y", "R", "u", "q", "Q", "M", "L", "I", "d", "D", "i", "t", "T"];
}
exports.LocalWeekParser = LocalWeekParser;