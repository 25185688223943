@if (!error) {
	<div class="overlay-container">
		<div class="header-pane">
			<uc-builder-header />
		</div>
		<div class="content-pane">
			@if (form) {
				<uf-panel class="container">
					<uc-auth-provider-detail [form]="form" (deleteClicked)="delete()" (activateClicked)="activate()" (deactivateClicked)="deactivate()" />
					@if (mappingsControl) {
						<uc-auth-provider-mappings [form]="mappingsControl" [config]="config" />
					}
				</uf-panel>
			}
		</div>
	</div>
}

<uc-error-message [error]="error" />
