<div class="uf-app-bar">
	<button (click)="runtime.close()" type="button" class="uf-action tertiary" title="Close">
		<uf-icon name="close" />
	</button>
	<h3>Send Test</h3>
</div>
<div class="uf-grid pad">
	<uf-email [control]="email" class="col-12" label="Email" />
</div>
<div class="row space-children pad">
	<button (click)="runtime.close()" type="button" class="uf-button tertiary right">Cancel</button>
	<button (click)="sendTest()" type="button" class="uf-button primary">Send Test</button>
</div>
