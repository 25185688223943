@if (ready) {
	<div class="header">
		<button (click)="close()" type="button" class="uf-action tertiary" title="Close">
			<uf-icon name="close" />
		</button>
		<h3>{{ searchConfig.title }}</h3>
	</div>
	<div class="content">
		<uf-search [search]="query" [autofocus]="true" (searchChange)="filter($event)" />
	</div>
	<uf-panel [bottomThreshold]="50" (scrollBottom)="onScrolledToEnd()">
		<ul class="media-select">
			@if (!busy && query?.length && !items.length) {
				<li>Sorry, there was no result for your search.</li>
			}
			@for (item of items; track item; let i = $index) {
				<li>
					<a [ngClass]="{ active: item.selected }" (click)="select(i)">
						<!-- For Collection Item, Page or View - it's _consoleName - for anything else it's consoleName -->
						<p>
							{{ item._consoleName ?? item.consoleName }} |
							<span class="font-size-sm">
								{{ item.definitionLabel ?? searchConfig.type }}
							</span>
						</p>
					</a>
				</li>
			}
			@if (busy) {
				<li>
					<uf-spinner class="padded" />
				</li>
			}
		</ul>
	</uf-panel>
	@if (error) {
		<div class="content">
			<div class="error-text">{{ error }}</div>
		</div>
	}
	<div class="row space-children pad">
		<button (click)="close()" type="button" class="uf-button tertiary right">Close</button>
		@if (searchConfig.multiSelect) {
			<button (click)="updateAll(!selectAll)" type="button" class="uf-button">
				{{ selectAll ? "Unselect All" : "Select All" }}
			</button>
		}
		<button (click)="save()" type="button" class="uf-button primary">
			{{ searchConfig.multiSelect ? "Add" : "Select" }}
		</button>
	</div>
}
