import { SortStatus, TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { UcRole, UcRoles } from 'client';

export class RolesDataSource extends TableDataSource<UcRole> {

	constructor(
		private ucRoles: UcRoles,
		private search?: string,
		private sort?: SortStatus,
	) {
		super();
	}

	get filtered(): boolean {
		return this.search != null && this.search.trim().length > 0;
	}

	get sorted(): boolean {
		return this.sort != null;
	}

	load(options?: TableDataSourceQueryOptions) {
		this.ucRoles.get(this.search, this.sort?.toString(), {
			params: { offset: options?.offset, limit: options?.limit },
			analytics: { origin: RequestAnalyticsOrigin.Table },
		}).then((roles) => {
			this.stream.next({ data: roles });
		}, (error) => {
			this.stream.next({ error });
		});
	}

}
