import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { Modal, ModalRuntime, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { Option } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { IntegrationFeature, IntegrationProviderFeatureType } from 'client';
import { kebabize } from 'helpers/field-identifier-helper';

import { IntegrationFeatureControlKeys } from './models';

type ModalType = IntegrationFeature | undefined;

@Component({
	selector: 'uc-integration-feature-modal',
	templateUrl: './integration-feature-modal.html',
	standalone: false,
})
export class IntegrationFeatureModalComponent implements Modal<void, ModalType>, OnDestroy, OnInit {

	@HostBinding('class.uf-form-card') cardClass = true;
	runtime = inject<ModalRuntime<void, ModalType>>(ModalRuntime);

	protected readonly integrationFeatureTypes: Option[] = [
		{ identifier: IntegrationProviderFeatureType.FeatureTypeLookup, name: 'Lookup' },
		{ identifier: IntegrationProviderFeatureType.FeatureTypeSink, name: 'Sink' },
		{ identifier: IntegrationProviderFeatureType.FeatureTypeWebhook, name: 'Webhook' },
		{ identifier: IntegrationProviderFeatureType.FeatureTypeDataTransaction, name: 'Transaction' },
	];
	protected readonly integrationFeatureControlKeys = IntegrationFeatureControlKeys;
	protected form: UfControlGroup;

	private ufb = inject(UfFormBuilder);
	private subscriptions = new Subscription();

	ngOnInit() {

		const identifierControl = this.ufb.control(undefined, ValidatorFunctions.required('Required, please complete'));
		const nameControl = this.ufb.control(undefined, ValidatorFunctions.required('Required, please complete'));

		this.subscriptions.add(nameControl.valueChanges.subscribe((name) => {
			identifierControl.setValue(kebabize(name), { emitEvent: true });
		}));

		this.form = this.ufb.group({
			[IntegrationFeatureControlKeys.Name]: nameControl,
			[IntegrationFeatureControlKeys.Id]: identifierControl,
			[IntegrationFeatureControlKeys.TypeKey]: [undefined, ValidatorFunctions.required('Required, please complete')],
		});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	save() {
		this.form.setSubmitted();

		if (!this.form.valid) {
			return;
		}

		this.runtime.close(this.form.getRawValue());
	}

}
