import { ChangeDetectorRef, Component, Input, OnChanges, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { ExpandersService } from '@unifii/library/common';
import { Dictionary, Field, FieldType } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { Config, ConsoleOptions } from 'app-config';
import { BuilderField } from 'client';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { FieldDisplayComponent } from 'components/field-builder/field-display.component';
import { FieldOptionsComponent } from 'components/field-builder/field-options.component';
import { FieldValidatorsComponent } from 'components/field-builder/field-validators.component';
import { FieldDetailHelper, FieldDetailMetadata } from 'helpers/helpers';

@Component({
	selector: 'uc-field-builder',
	templateUrl: './field-builder.html',
	styleUrls: ['./field-builder.less'],
	providers: [ExpandersService],
	standalone: false,
})
export class FieldBuilderComponent implements OnChanges {

	@Input({ required: true }) field: BuilderField;
	@Input() submitted: boolean | string | null;
	@Output() valueChange = new Subject<BuilderEventInfo>(); /** used to notify view build that nested fields and settings have changed so field can rerender */
	@ViewChild(FieldDisplayComponent, { static: false }) fieldDisplayComponent: FieldDisplayComponent;
	@ViewChild(FieldOptionsComponent, { static: false }) fieldOptionsComponent: FieldOptionsComponent;
	@ViewChild(FieldValidatorsComponent, { static: false }) fieldValidatorsComponent: FieldValidatorsComponent;

	expandersService = inject(ExpandersService);

	protected readonly fieldType = FieldType;
	protected fm: FieldDetailMetadata;
	protected isSubmitted = false;
	protected invalids: Dictionary<boolean> = {};
	protected visibleGroups: Dictionary<boolean> = {};
	protected expanded = true;
	protected customFields = false;
	protected variation: BuilderField | null;
	protected validatorsExpanded = false;
	protected variationsExpanded = false;

	private builderService = inject(BuilderService);
	private cdr = inject(ChangeDetectorRef);
	private config = inject<ConsoleOptions>(Config);
	private errors: Dictionary<string[]> = {};

	ngOnChanges(changes: SimpleChanges) {
		if (changes.field) {

			if (changes.field.previousValue === changes.field.currentValue && this.fm) {
				// Only set metadata once
				return;
			}

			this.resetTemplate();

			// Get metadata
			const parent = this.builderService.builder.getFieldPosition(this.field)?.parent as Field | undefined;

			this.fm = FieldDetailHelper.getMetadata(this.field, this.builderService.builder.type, parent);

			this.updateVisibleGroups();
			this.customFields = this.fm.customFields && !!this.config.flags.customFields;

			this.cdr.detectChanges();
		}
	}

	variationChange(v: BuilderField) {

		// Set field variation
		this.variation = v;

		if (this.variation && !this.variation.type) {
			this.variation.type = this.field.type;
		}

		this.updateVisibleGroups();
	}

	edited(i: BuilderEventInfo) {
		this.builderService.fieldEdit.next({ subject: i.subject, atomic: i.atomic });
		this.valueChange.next(i);
	}

	refresh(i: BuilderEventInfo) {
		const errors = (i.errors ?? []).map((m) => m.message);

		this.errors[i.source as string] = errors;
		this.invalids[i.source as string] = errors.length > 0;
		this.builderService.setErrors(this.field, errors, i.source as string);
		this.builderService.fieldRefreshed.next({ subject: i.subject });
	}

	addOption() {
		if (this.fieldOptionsComponent) {
			this.fieldOptionsComponent.addOption();
		}
	}

	addValidator() {
		if (this.fieldValidatorsComponent) {
			this.fieldValidatorsComponent.addValidator();
		}
	}

	private updateVisibleGroups() {
		// Check restrictions on for what is available under parent
		const result: Dictionary<boolean> = {};
		const field = this.variation ?? this.field;
		const isVariation = field.name != null;

		result.display = this.fm.template || this.fm.columnCount || this.fm.layoutDirection || this.fm.minHeight || this.fm.width && !isVariation;
		result.visibility = !isVariation && (this.fm.role || this.fm.showIf || this.fm.showOn || this.fm.visibleTo);
		result.nested = this.fm.hasNestedFields;
		result.variations = this.fm.variations && !isVariation;
		result.options = (!isVariation && this.fm.options) || (isVariation && this.field.type !== FieldType.Bool);
		result.transitions = this.fm.transitions;
		result.validators = this.fm.validators;
		this.visibleGroups = result;
	}

	private resetTemplate() {
		this.variation = null;
		this.errors = {};
	}

}
