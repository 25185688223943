"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const native_js_1 = require("./native.js");
const rng_js_1 = require("./rng.js");
const stringify_js_1 = require("./stringify.js");
function v4(options, buf, offset) {
  if (native_js_1.default.randomUUID && !buf && !options) {
    return native_js_1.default.randomUUID();
  }
  options = options || {};
  const rnds = options.random ?? options.rng?.() ?? (0, rng_js_1.default)();
  if (rnds.length < 16) {
    throw new Error('Random bytes length must be >= 16');
  }
  rnds[6] = rnds[6] & 0x0f | 0x40;
  rnds[8] = rnds[8] & 0x3f | 0x80;
  if (buf) {
    offset = offset || 0;
    if (offset < 0 || offset + 16 > buf.length) {
      throw new RangeError(`UUID byte range ${offset}:${offset + 15} is out of buffer bounds`);
    }
    for (let i = 0; i < 16; ++i) {
      buf[offset + i] = rnds[i];
    }
    return buf;
  }
  return (0, stringify_js_1.unsafeStringify)(rnds);
}
exports.default = v4;