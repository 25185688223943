<div #formWrapper>
	<ng-container #formOutlet />
</div>

<ng-template #formTemplate>
	<uf-panel [class.panelWidth]="showSettings" class="container">
		@if (formData && definition) {
			<uf-form [config]="formConfig" [definition]="definition" [(formData)]="formData" (submit)="submit($event)" (cancel)="close()" (edited)="edited = $event">
				<div header class="uf-app-bar flat">
					<div class="title">
						Form Preview <small>v{{ config.version }}</small>
						@if (edited) {
							<span>*</span>
						}
					</div>
					<uf-data-display-list class="small stacked">
						<dt>Current state</dt>
						<dd>{{ formData._state }}</dd>
						@if (formData._result) {
							<dt>Result</dt>
							<dd>{{ formData._result }}</dd>
						}
						@if (formDebugger.roles.length) {
							<dt>Roles</dt>
							<dd>{{ formDebugger.roles | dataDisplay }}</dd>
						}
					</uf-data-display-list>
					<button (click)="refresh()" type="button" title="Refresh" class="uf-action">
						<uf-icon name="refreshApp" />
					</button>
					<button (click)="showSettings = !showSettings" type="button" title="Toggle Settings" class="uf-action">
						<uf-icon name="settings" />
					</button>
				</div>
			</uf-form>
		}
	</uf-panel>

	@if (definition && showSettings) {
		<uf-panel class="settings container">
			<div class="settings-shadow"></div>
			<div class="uf-app-bar flat">
				<div class="title">Settings</div>
				<button (click)="showSettings = false" type="button" class="uf-action tertiary">
					<uf-icon name="close" />
				</button>
			</div>
			<div class="grid">
				<div class="pad-sides">
					<h4>Form Info</h4>
					<uf-data-display-list>
						<dt>Name</dt>
						<dd>{{ definition.label }}</dd>
						<dt>Identifier</dt>
						<dd>{{ definition.identifier }}</dd>
						<dt>Bucket</dt>
						<dd>{{ definition.bucket }}</dd>
					</uf-data-display-list>
					<uf-select [options]="formDataOptions" [(value)]="formDataSource" (valueChange)="toggleFormDataSource()" nameProperty="name" valueProperty="identifier" label="Form Data" class="gap-top" />
					<h4>Workflow</h4>
					<uf-checkbox [(value)]="formDebugger.enableWorkflow" label="Enabled" />
					@if (formDebugger.enableWorkflow) {
						<uf-select [options]="formDebugger.formStates" [(value)]="formDebugger.state" label="State" class="gap-sm-top" />
					}
					<h4>Validation</h4>
					<uf-select [options]="validationOptions" [(value)]="formDebugger.validation" nameProperty="label" valueProperty="value" label="Level" />
					<h4>User</h4>
					@if (roles.length) {
						<uf-multi-choice [value]="formDebugger.roles" [options]="roles" [disabled]="!formDebugger.enableWorkflow" (valueChange)="initialize($event, true)" label="Roles" columns="1" />
					}
					@if (!formDebugger.enableWorkflow) {
						<div class="error-text">Unavailable while workflow is disabled</div>
					}
					<h4>Actions</h4>
					<button (click)="refresh()" type="button" class="uf-button">Reset</button>
					@if (formData) {
						<button (click)="showData()" type="button" class="uf-button gap-top">Show Data</button>
					}
				</div>
			</div>
		</uf-panel>
	}
</ng-template>

@if (showError) {
	<uf-panel class="container bg-solid">
		<div class="uf-container-md">
			<div class="uf-grid pad">
				<uf-message class="error" icon="errorSolid" heading="Configuration missing or corrupted!" content="Please close and restart the FormBuilder">
					<div class="col-12 row space-children">
						<button (click)="close()" type="button" class="uf-button tertiary right">Close</button>
					</div>
				</uf-message>
			</div>
		</div>
	</uf-panel>
}
