@if (ready && notifyEnabled) {
	<div class="grid">
		@if (config.itemLabel.show && form.controls.itemLabel) {
			<uf-text [control]="form.controls.itemLabel | asUfControl" [(value)]="field.itemLabel" label="Item Label" class="col-1of1" />
		}
		@if (config.addButtonLabel.show && form.controls.addButtonLabel) {
			<uf-text [control]="form.controls.addButtonLabel | asUfControl" [(value)]="field.addButtonLabel" label="Add Button Label" class="col-1of1" />
		}
		@if (config.width.show && form.controls.width) {
			<uf-select [control]="form.controls.width | asUfControl" [disabled]="field.template === fieldTemplate.RadioWithContent" [options]="widthOptions" [(value)]="field.width" label="Field Width" valueProperty="value" nameProperty="name" class="col-1of1" />
		}
		@if (config.breakAfter.show && form.controls.breakAfter) {
			<uf-checkbox [control]="form.controls.breakAfter | asUfControl" [disabled]="!field.width || field.width === fieldWidth.Stretch" [(value)]="field.breakAfter" label="Break after" class="col-1of1" />
		}
		@if (config.template.show && form.controls.template) {
			<uf-select [options]="templateOptions" [control]="form.controls.template | asUfControl" [(value)]="field.template" (valueChange)="templateChange($event)" label="Template" valueProperty="value" nameProperty="name" class="col-1of1" />
		}
		@if (config.columnVisibility.show) {
			<div class="col-1of1">
				<label class="x-small">Column Visibility</label>
				<div class="pad-sm-top">
					<ul class="uf-list uf-box flat column-toggles-list">
						@for (child of field.fields; track child) {
							@if (child.identifier) {
								<li class="row center-all space-children pad-sm-sides">
									<div class="grow">
										<p>{{ child.label }}</p>
									</div>
									@if (columnVisibility?.hideFromColumnsOnMobile) {
										<uf-checkbox [value]="!columnVisibility?.hideFromColumnsOnMobile?.includes(child.identifier)" (valueChange)="columnVisibilityChange($event, child.identifier, displayType.Mobile)" label="Mobile" />
									}
									<uf-checkbox [value]="!columnVisibility?.hideFromColumnsOnDesktop?.includes(child.identifier)" (valueChange)="columnVisibilityChange($event, child.identifier, displayType.Desktop)" label="Desktop" />
								</li>
							}
						}
					</ul>
				</div>
			</div>
		}
		@if (config.position.show && form.controls.position) {
			<uf-select [control]="form.controls.position | asUfControl" [options]="positionOptions" [(value)]="field.template" label="Position" valueProperty="value" nameProperty="name" class="col-1of1" />
		}
		@if (config.columnCount.show && form.controls.columnCount) {
			<uf-select [control]="form.controls.columnCount | asUfControl" [options]="columnCountOptions" [(value)]="field.columnCount" label="Columns" valueProperty="value" nameProperty="name" class="col-1of1" />
		}
		@if (config.layoutDirection.show && form.controls.layoutDirection) {
			<uf-select [control]="form.controls.layoutDirection | asUfControl" [options]="directionOptions" [(value)]="field.layoutDirection" label="Direction" valueProperty="value" nameProperty="name" class="col-1of1" />
		}
		@if (config.minWidth.show && form.controls.minWidth) {
			<uf-number [control]="form.controls.minWidth | asUfControl" [(value)]="field.minWidth" label="Min Width" class="col-1of1" />
		}
		@if (config.minHeight.show && form.controls.minHeight) {
			<uf-number [control]="form.controls.minHeight | asUfControl" [(value)]="field.minHeight" label="Min Height" class="col-1of1" />
		}
		@if (field.template === "table" && config.columns.show) {
			<div class="uc-group col-1of1--no-padding">
				<div class="uf-app-bar flat">
					<div class="title primary">Columns</div>
				</div>
				<div class="uc-group-content grid">
					@if (form.controls.columns) {
						<uf-search-result [control]="form.controls.columns | asUfControl" [options]="fieldColumns" (valueChange)="addColumn($event)" (searchChange)="filterColumns($event)" label="Add Columns" placeholder="Search for..." nameProperty="label" class="col-1of1" />
					}
					@if (field.columns && field.columns.length) {
						<uc-drag-list [items]="field.columns" (moved)="notifyEdited()" canDrop="false" canReorder="true" class="col-1of1">
							<ul class="uc-list">
								@for (column of field.columns; track column; let i = $index) {
									<li class="uc-list-item--draggable--small row center-all" dragItem>
										<div class="list-content">
											<div class="list-context">
												<button (click)="removeColumn(column)" type="button" title="Delete" class="uf-action tertiary">
													<uf-icon name="delete" />
												</button>
											</div>
											<div class="grow">{{ column.label }}</div>
										</div>
									</li>
								}
							</ul>
						</uc-drag-list>
					}
				</div>
			</div>
		}
	</div>
}
