<div class="uf-app-bar flat">
	<div class="title">Data mapping</div>
</div>
<div class="uf-grid pad">
	<!-- TODO Restore with changed text once the wording has been decided
  <uf-blockquote icon="infoSolid"
    content="This determines what additional data will be saved in the form for the selected Data Source option."
    class="info col-12" /> -->

	<div class="col-12">
		<table class="uf-table inputs accent">
			<thead>
				<tr>
					<th>Parameter</th>
					<th>Value</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				@for (dataMapping of dataMappingControl.controls; track dataMapping; let i = $index) {
					<tr [formGroup]="dataMapping | asUfControlGroup">
						<ng-container
							*ngTemplateOutlet="
								row;
								context: {
									control: dataMapping,
									index: i,
								}
							" />
					</tr>
				}
			</tbody>
		</table>
	</div>

	<div class="col-12">
		<button (click)="add()" type="button" class="uf-button right">Add Parameter</button>
	</div>
</div>

<ng-template #row let-control="control" let-index="index">
	<ng-container [formGroup]="control">
		<td class="input-cell">
			<div class="row">
				<uf-text [formControlName]="argControlKeys.Key" class="table-cell grow" />
			</div>
		</td>
		<td class="input-cell">
			<uf-select [options]="outputOptions" [formControlName]="argControlKeys.Value" class="table-cell" />
		</td>
		<td class="input-cell">
			<div class="row">
				<button (click)="deleteOutput(index)" title="Delete" type="button" class="uf-action tertiary right">
					<uf-icon name="delete" />
				</button>
			</div>
		</td>
	</ng-container>
</ng-template>
