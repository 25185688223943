import { Pipe, PipeTransform } from '@angular/core';
import { DataDisplayColour } from '@unifii/library/common';
import { DefinitionPublishState } from '@unifii/sdk';

@Pipe({
	name: 'publishedStatusColour',
	standalone: false,
})
export class PublishedStatusColour implements PipeTransform {

	transform(value: DefinitionPublishState | null | undefined): DataDisplayColour {
		switch (value) {
			case DefinitionPublishState.Published:
				return 'success';
			case DefinitionPublishState.Approved:
				return 'warning';
			case DefinitionPublishState.Draft:
				return 'info';
			default:
				return 'default';
		}
	}

}
