<div [formGroup]="form" class="uf-box">
	<div class="uf-app-bar flat accent">
		<div class="title">Details</div>
	</div>
	<div class="uf-grid pad-sides pad-top">
		@if (error) {
			<uf-message [content]="error.message" icon="errorSolid" class="error col-12" />
		}

		@if (!isNew) {
			<uf-user-description [userInfo]="user" [showOnly]="userDescriptionFields" class="col-12" />
		}

		@if (lastLoginAtItems) {
			<uf-data-display-list [items]="lastLoginAtItems" class="col-12 responsive" />
		}

		@if (usernameControl && usernameControl.enabled) {
			<uf-text [label]="commonTK.UsernameLabel | translate" [control]="usernameControl" class="col-12 col-md-6">
				@if (usernameRequired) {
					<span class="suffix">*</span>
				}
			</uf-text>
		}
		@if (firstNameControl && firstNameControl.enabled) {
			<uf-text [label]="commonTK.FirstNameLabel | translate" [control]="firstNameControl" class="col-12 col-md-6">
				@if (firstNameRequired) {
					<span class="suffix">*</span>
				}
			</uf-text>
		}
		@if (lastNameControl && lastNameControl.enabled) {
			<uf-text [label]="commonTK.LastNameLabel | translate" [control]="lastNameControl" class="col-12 col-md-6">
				@if (lastNameRequired) {
					<span class="suffix">*</span>
				}
			</uf-text>
		}
		@if (emailControl && emailControl.enabled) {
			<uf-text [label]="commonTK.EmailLabel | translate" [control]="emailControl" class="col-12 col-md-6">
				@if (emailRequired) {
					<span class="suffix">*</span>
				}
			</uf-text>
		}
		@if (phoneControl && phoneControl.enabled) {
			<uf-text [label]="commonTK.PhoneLabel | translate" [control]="phoneControl" class="col-12 col-md-6">
				@if (phoneRequired) {
					<span class="suffix">*</span>
				}
			</uf-text>
		}
		@if (displayPhoneWarning) {
			<uf-message [content]="(isMe ? commonTK.MfaSmsMyAccountWarningMessage : commonTK.MfaSmsUserAdminWarningMessage) | translate" icon="warningSolid" class="warning gap col-12" />
		}
		@if (companyControl && companyControl.enabled) {
			<uf-company-input [control]="companyControl" [label]="companyLabel" class="col-12 col-md-6" />
		}
		@if (isTesterControl && isTesterControl.enabled) {
			<uf-checkbox [control]="isTesterControl" [label]="commonTK.IsTesterLabel | translate" class="col-12 col-md-6" />
		}
	</div>

	@if (!isNew && claimDescriptionFields.length && claimsControl?.disabled) {
		<uf-user-description [userInfo]="user" [showOnly]="claimDescriptionFields" class="pad border-bottom" />
	}

	@if (claimsControl && claimsControl.enabled) {
		<uf-claim-editor [control]="claimsControl" [width]="fieldWidth.Half" />
	}

	<div class="uf-grid pad-bottom">
		@if (passwordControl) {
			<uf-password-change [user]="user" [control]="form" [hideToggle]="isNew" class="col-12" />
		}

		@if (lastActivationReasonControl && isActiveControl) {
			<uf-status-change [lastActivationReasonControl]="lastActivationReasonControl" [isActiveControl]="isActiveControl" [user]="user" class="col-12" />
		}
	</div>
</div>
