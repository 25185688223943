"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QueryParams = void 0;
var QueryParams;
(function (QueryParams) {
  QueryParams["ImageCrop"] = "crop";
  QueryParams["ImageWidth"] = "w";
  QueryParams["ImageHeight"] = "h";
  QueryParams["ImageMode"] = "mode";
  QueryParams["AccessToken"] = "access_token";
  QueryParams["ClientId"] = "client_id";
  QueryParams["Form"] = "from";
  QueryParams["Format"] = "_format";
})(QueryParams || (exports.QueryParams = QueryParams = {}));
