"use strict";

exports.FractionOfSecondParser = void 0;
var _Parser = require("../Parser.cjs");
var _utils = require("../utils.cjs");
class FractionOfSecondParser extends _Parser.Parser {
  priority = 30;
  parse(dateString, token) {
    const valueCallback = value => Math.trunc(value * Math.pow(10, -token.length + 3));
    return (0, _utils.mapValue)((0, _utils.parseNDigits)(token.length, dateString), valueCallback);
  }
  set(date, _flags, value) {
    date.setMilliseconds(value);
    return date;
  }
  incompatibleTokens = ["t", "T"];
}
exports.FractionOfSecondParser = FractionOfSecondParser;