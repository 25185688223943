export * from './bypass-sanitize-url.pipe';
export * from './data-source-type-label.pipe';
export * from './file-type.pipe';
export * from './published-status-colour.pipe';
export * from './table-source-type-label.pipe';
export * from './to-display-name.pipe';

import { BypassSanitizeUrlPipe } from './bypass-sanitize-url.pipe';
import { DataSourceTypeLabelPipe } from './data-source-type-label.pipe';
import { FileTypePipe } from './file-type.pipe';
import { PublishedStatusColour } from './published-status-colour.pipe';
import { TableSourceTypeLabelPipe } from './table-source-type-label.pipe';
import { ToDisplayNamePipe } from './to-display-name.pipe';

export const PIPES = [
	BypassSanitizeUrlPipe,
	DataSourceTypeLabelPipe,
	FileTypePipe,
	PublishedStatusColour,
	TableSourceTypeLabelPipe,
	ToDisplayNamePipe,
];
